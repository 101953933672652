import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import Button, { ButtonSize, ButtonType } from "../../components/common/Button";
import CreditsRemainingText from "../../components/CreditsRemainingText";
import CustomWrapper from "../../components/UserAccount/CustomWrapper";
import { Colors } from "../../constants/colors";
import InfoIcon from "../../images/info.svg";
import { useSessionStore } from "../../stores/Session";
import { isNil } from "lodash-es";
import { usePurchaseStore } from "../../stores/purchase";

export default function Credits() {
  const history = useHistory();
  const location = useLocation();

  const { data: user } = useSessionStore();
  const { setPurchaseJourneyStartPath } = usePurchaseStore();

  const hasOrg = !isNil(user?.current_organization);

  return (
    <CustomWrapper title="Contrax credits">
      <CreditsRemainingText mt="56px" />

      <Box
        bgcolor={Colors.BrandBackground3}
        boxSizing="border-box"
        p="16px"
        display="flex"
        flexDirection={"row"}
        maxWidth="480px"
        mt="24px"
      >
        <img src={InfoIcon} height="24px" alt="info" />

        <Typography variant="body1" style={{ marginLeft: "17px" }}>
          {hasOrg
            ? " Each credit is redeemable for one contract workspace. Credits do not expire, and you can use them or add more at any time."
            : "Each credit is redeemable for one contract. Credits never expire, and you can offset any remaining against your final conveyancing fee."}
        </Typography>
      </Box>
    </CustomWrapper>
  );
}
