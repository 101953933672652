import React from "react";
import { Box, Typography, Dialog } from "@material-ui/core";
import InfoMessageBar from "./InfoMessageBar";
import Button, { ButtonType, ButtonSize } from "./common/Button";
import Separator from "./common/Separator";
import { TeamMember, TeamMemberRole } from "../api/models";
import InviteModalUserItem from "./LegalReviewViewer/InviteModalUserItem";
import { useContractStore } from "../stores/contract";

export enum ShareWithDialogType {
  team,
  otherParty, // i.e. vendor's representatives
}

interface Props {
  type?: ShareWithDialogType;
  open: boolean;
  title: string;
  message: string;
  onClose: () => unknown;
  onNext: (membersCount: number) => unknown;
  // contractId: number | null;
}

export default function ShareWithDialog({
  type = ShareWithDialogType.team,
  open,
  title,
  message,
  onClose,
  onNext,
}: // contractId,
Props) {
  // TODO: Show agents as well when API ready, all users can see all agents
  const {
    teamMembers,
    otherPartyMembers,
    agentTeamMembers,
    userTeamMemberRole,
  } = useContractStore();

  const isBuyer =
    userTeamMemberRole === TeamMemberRole.buyer ||
    userTeamMemberRole === TeamMemberRole.buyerLawyer;

  return (
    <Dialog
      open={open}
      aria-labelledby="share-with-your-team-dialog"
      aria-describedby="share-with-your-team-description"
      fullWidth
    >
      <Box maxWidth="626px" display="flex" flexDirection="column">
        <Box
          height="62px"
          width="100%"
          pl="24px"
          alignItems="center"
          display="flex"
          flexDirection="row"
          boxSizing="border-box"
        >
          <Typography variant="h5" style={{ fontWeight: 700 }}>
            {title}
          </Typography>
        </Box>
        <InfoMessageBar message={message} />

        <Box minHeight="80px">
          {type === ShareWithDialogType.team &&
            teamMembers.map((member) => (
              <InviteModalUserItem member={member} />
            ))}
          {type === ShareWithDialogType.otherParty &&
            otherPartyMembers
              .concat(isBuyer ? agentTeamMembers : [])
              .map((member) => <InviteModalUserItem member={member} />)}
        </Box>

        <Separator />
        <Box
          display="flex"
          justifyContent="flex-end"
          pl="24px"
          pr="16px"
          height="74px"
          alignItems="center"
          boxSizing="border-box"
        >
          <Button
            title="CANCEL"
            size={ButtonSize.medium}
            type={ButtonType.secondary}
            onClick={onClose}
            width="114px"
          />
          <Box width="8px" />
          <Button
            title="NEXT"
            size={ButtonSize.medium}
            type={ButtonType.primary}
            onClick={() => onNext(teamMembers.length)}
            width="93px"
          />
        </Box>
      </Box>
    </Dialog>
  );
}
