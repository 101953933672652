import { Box, Typography } from "@material-ui/core";
import { isNil } from "lodash-es";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import {
  Activity,
  Contract,
  ContractPublishStatus,
  ContractSummary,
  ContractWorkflowStatus,
  Property,
  TeamMember,
  TeamMemberRole,
} from "../api/models";
import ActivityLog from "../components/ActivityLog/ActivityLog";
import AlertWrapper from "../components/AlertWrapper";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";
import StickyFooter from "../components/common/StickyFooter";
import HelpGuideModal from "../components/HelpGuide/HelpGuideModal";
import HelpGuideModalItem from "../components/HelpGuide/HelpGuideModalItem";
import LegalReviewProgressBar from "../components/LegalReviewProgressBar";
import InviteModal from "../components/LegalReviewViewer/InviteModal";
import InviteYourTeamMembersModal, {
  InviteYourTeamMembersModalType,
} from "../components/LegalReviewViewer/InviteYourTeamMembersModal";
import LegalReviewWhiteHeader from "../components/LegalReviewWhiteHeader";
import OnboardingWrapper from "../components/OnboardingWrapper";
import { useAlert } from "../contexts/Alert";
import { useMobile } from "../hooks/mobile";
import Help1 from "../images/builder-landing-help-1.svg";
import Help2 from "../images/builder-landing-help-2.svg";
import Help3 from "../images/builder-landing-help-3.svg";
import Hero from "../images/lawyer-hero.svg";
import { refreshContractTeamsAndRole } from "../stores/contract";

interface StartAddingItemsProps {
  onCreateLegalReview: () => unknown;
  onHelpGuide: () => unknown;
  numberOfDefaultReviews: number;
  contractId: number;
  recordId: number;
  legalReviewsPublished: boolean;
}

function StartAddingItems({
  onCreateLegalReview,
  onHelpGuide,
  numberOfDefaultReviews,
  contractId,
  recordId,
  legalReviewsPublished,
}: StartAddingItemsProps) {
  const isMobile = useMobile();

  return (
    <Box
      display="flex"
      flex={1}
      justifyContent="center"
      alignItems="center"
      height="80vh"
    >
      <Box
        width={isMobile ? "90%" : "385px"}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {!isMobile && (
          <LegalReviewProgressBar contractId={contractId} recordId={recordId} />
        )}

        <img
          src={Hero}
          style={{ marginTop: "16px", marginBottom: "10px" }}
          height={isMobile ? "200px" : undefined}
        />

        <Typography
          variant="h6"
          style={{ fontWeight: 700, textAlign: "center" }}
        >
          {legalReviewsPublished
            ? "Start adding review items"
            : "Contrax is preparing review items for this contract"}
        </Typography>
        <Typography
          variant="body2"
          style={{
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "27px",
          }}
        >
          {legalReviewsPublished
            ? `Contrax has prepared ${numberOfDefaultReviews} review items for you to
          begin your review. Edit, add or dismiss them to create your legal
          review. Then share it with your client when you're ready.`
            : "We'll notify you when they're ready. Then edit, add or dismiss them to create your legal review and share it with your client when you're ready."}
        </Typography>

        {!isMobile && (
          <Box width="301px">
            <Button
              type={ButtonType.primary}
              size={ButtonSize.large}
              title="CREATE LEGAL REVIEW"
              onClick={onCreateLegalReview}
              width="100%"
              disabled={!legalReviewsPublished}
            />
            <Box height="8px" />
            <Button
              type={ButtonType.secondary}
              size={ButtonSize.medium}
              title="HELP GUIDE"
              onClick={onHelpGuide}
              width="100%"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

interface MobileStickyFooterProps {
  onCreateLegalReview: () => unknown;
  onHelpGuide: () => unknown;
  legalReviewsPublished: boolean;
}

function MobileStickyFooter({
  onCreateLegalReview,
  onHelpGuide,
  legalReviewsPublished,
}: MobileStickyFooterProps) {
  return (
    <StickyFooter
      height="125px"
      bgcolor="white"
      boxShadow="0px -1px 0px #E0E0E0"
    >
      <Box m="16px" display="flex" flexDirection="column">
        <Button
          title="CREATE LEGAL REVIEW"
          type={ButtonType.primary}
          size={ButtonSize.medium}
          onClick={onCreateLegalReview}
          disabled={!legalReviewsPublished}
        />
        <Box height="8px" />
        <Button
          title="HELP GUIDE"
          type={ButtonType.secondary}
          size={ButtonSize.small}
          onClick={onHelpGuide}
        />
      </Box>
    </StickyFooter>
  );
}

interface Props {
  onLoggedIn: () => unknown;
  onSignedUp: () => unknown;
}

export default function ({ onLoggedIn, onSignedUp }: Props) {
  const isMobile = useMobile();

  const location = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(location.search);
  const _contractId = query.get("contractId");
  const contractId = !isNil(_contractId) ? parseInt(_contractId) : null;
  const _recordId = query.get("recordId");
  const recordId = !isNil(_recordId) ? parseInt(_recordId) : null;

  const { data, mutate: contractMutate } = useSWR(
    `/view-contracts/${contractId}/`
  );
  const contract = data ? (data as Contract) : null;

  const legalReviewsPublished =
    contract?.publish_status === ContractPublishStatus.Published &&
    contract?.workflow_status === ContractWorkflowStatus.Reviewed;

  const { data: propertyData } = useSWR(
    contract?.property_id ? `/view-properties/${contract.property_id}/` : null
  );
  const property = propertyData && (propertyData as Property);

  const { data: teamMembersData, mutate: teamMembersMutate } = useSWR(
    `/contract/${contractId}/record/${recordId}/buyer-team/`
  );
  const teamMembers = teamMembersData ? (teamMembersData as TeamMember[]) : [];

  const { data: activitiesData, mutate: activitiesMutate } = useSWR(
    !isNil(contractId)
      ? `/contract/${contractId}/record/${recordId}/activities/`
      : null
  );

  const activities = activitiesData ? (activitiesData as Activity[]) : [];

  const [inviteModalOpen, setInviteModalOpen] = React.useState(false);
  const [inviteYourTeamMembersOpen, setInviteYourTeamMembersOpen] =
    React.useState(false);
  const [
    inviteYourTeamMembersUserTeamRole,
    setInviteYourTeamMembersUserTeamRole,
  ] = React.useState<TeamMemberRole | null>(null);
  const [inviteYourTeamMembersType, setInviteYourTeamMembersType] =
    React.useState(InviteYourTeamMembersModalType.default);

  const [helpModalOpen, setHelpModalOpen] = React.useState(false);

  const { setErrorMessage, setSuccessMessage } = useAlert();

  const onCreateLegalReview = () => {
    history.push(
      `/legal-review-builder?contractId=${contractId}&recordId=${recordId}`
    );
  };

  const onHelpGuide = () => {
    setHelpModalOpen(true);
  };

  // Get Default legal review summaries for count
  const { data: defaultSummariesData } = useSWR(
    !isNil(contractId)
      ? `contract/${contractId}/record/${recordId}/contrax-summaries/`
      : null
  );
  const defaultSummaries = defaultSummariesData
    ? (defaultSummariesData as ContractSummary[])
    : [];

  return (
    <OnboardingWrapper onLoggedIn={onLoggedIn} onSignedUp={onSignedUp}>
      <AlertWrapper>
        <Box display="flex" flexDirection="column">
          <LegalReviewWhiteHeader
            property={property}
            onInviteClicked={() => {
              setInviteModalOpen(true);
            }}
          />

          {isMobile && contractId && recordId && (
            <Box>
              <StartAddingItems
                onCreateLegalReview={onCreateLegalReview}
                onHelpGuide={onHelpGuide}
                numberOfDefaultReviews={defaultSummaries.length}
                contractId={+contractId}
                recordId={+recordId}
                legalReviewsPublished={legalReviewsPublished}
              />

              <MobileStickyFooter
                onCreateLegalReview={onCreateLegalReview}
                onHelpGuide={onHelpGuide}
                legalReviewsPublished={legalReviewsPublished}
              />
            </Box>
          )}

          {!isMobile && contractId && recordId && (
            <Box display="flex" flexDirection="row">
              <StartAddingItems
                onCreateLegalReview={onCreateLegalReview}
                onHelpGuide={onHelpGuide}
                numberOfDefaultReviews={defaultSummaries.length}
                contractId={+contractId}
                recordId={+recordId}
                legalReviewsPublished={legalReviewsPublished}
              />
              <Box mt="40px" width="353px">
                <Typography variant="h6">Activity Log</Typography>
                <ActivityLog activities={activities} />
              </Box>
            </Box>
          )}
        </Box>
      </AlertWrapper>

      <InviteModal
        open={inviteModalOpen}
        onClose={() => setInviteModalOpen(false)}
        onInviteTeamMembersClicked={(userTeamMemberRole) => {
          setInviteModalOpen(false);
          setInviteYourTeamMembersType(InviteYourTeamMembersModalType.default);
          setInviteYourTeamMembersUserTeamRole(userTeamMemberRole);
          setInviteYourTeamMembersOpen(true);
        }}
        onInviteAgents={() => {
          setInviteModalOpen(false);
          setInviteYourTeamMembersType(InviteYourTeamMembersModalType.agents);
          setInviteYourTeamMembersUserTeamRole(null);
          setInviteYourTeamMembersOpen(true);
        }}
        contractId={contractId}
        recordId={recordId}
      />

      <InviteYourTeamMembersModal
        open={inviteYourTeamMembersOpen}
        onClose={() => setInviteYourTeamMembersOpen(false)}
        onInvited={(firstName, lastName) => {
          setInviteYourTeamMembersOpen(false);
          setSuccessMessage(
            `${firstName} ${lastName} has been invited to your team`
          );

          refreshContractTeamsAndRole();

          setInviteModalOpen(true);
        }}
        contractId={contractId}
        recordId={recordId}
        userTeamMemberRole={inviteYourTeamMembersUserTeamRole}
        type={inviteYourTeamMembersType}
      />

      <HelpGuideModal
        title="How to build your legal review"
        open={helpModalOpen}
        onClose={() => setHelpModalOpen(false)}
        items={
          <>
            <HelpGuideModalItem
              index={1}
              title="Create legal review to get started"
              image={Help1}
              imageMarginTop="56px"
            />
            <Box width="6px" />
            <HelpGuideModalItem
              index={2}
              title="Add, edit or dismiss legal review  items"
              image={Help2}
              imageMarginTop="32px"
            />
            <Box width="6px" />
            <HelpGuideModalItem
              index={3}
              title="Finalise your review and share it with your team"
              image={Help3}
              imageMarginTop="32px"
            />
          </>
        }
      />
    </OnboardingWrapper>
  );
}
