import React from "react";
import { Box, InputBase, IconButton } from "@material-ui/core";
import Icon from "../../images/search-field-icon.svg";
import { useMobile } from "../../hooks/mobile";
import { debounce } from "lodash-es";

interface Props {
  value: string;
  placeholder?: string;
  onTextChanged?: (text: string) => unknown;
}

export default function SearchField({
  value,
  placeholder = "Search",
  onTextChanged,
}: Props) {
  const isMobile = useMobile();

  const [text, setText] = React.useState("");

  React.useEffect(() => {
    setText(value);
  }, [value]);

  const textChanged = (text: string) => {
    onTextChanged && onTextChanged(text);
  };

  const debouncedTextChanged = React.useCallback(
    debounce(textChanged, 1200),
    []
  );

  const onText = (text) => {
    setText(text);

    debouncedTextChanged(text);
  };

  return (
    <Box
      width={isMobile ? "100%" : "433px"}
      height="50px"
      border="1px solid #ECF2FB"
      borderRadius="16px"
      display="flex"
      flexDirection="row"
      alignItems="center"
      pl="20px"
      boxSizing="border-box"
    >
      <InputBase
        id="search-field"
        placeholder={placeholder}
        style={{
          fontFamily: "GT Walsheim Pro",
          fontWeight: 400,
          fontSize: "16px",
          flex: 1,
        }}
        onChange={(event) => onText(event.target.value)}
        value={text}
      />
      <IconButton onClick={() => onTextChanged && onTextChanged(text)}>
        <img src={Icon} />
      </IconButton>
    </Box>
  );
}
