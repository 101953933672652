import React from "react";
import { Box, Typography } from "@material-ui/core";
import { addressForProperty, Contract, Property } from "../../api/models";
import { Colors } from "../../constants/colors";

interface Props {
  property: Property | null;
  inviterName?: string | null;
}

export default function PropertyInfoBar({ property, inviterName }: Props) {
  return (
    <Box
      minHeight="105px"
      pl="34px"
      pr="34px"
      display="flex"
      flexDirection="row"
      bgcolor={Colors.BrandBackground3}
      alignItems="center"
    >
      {property?.image && (
        <Box>
          <img
            src={property.image}
            width="95px"
            height="64px"
            style={{
              objectFit: "cover",
              borderRadius: "4px",
              marginRight: "16px",
            }}
          />
        </Box>
      )}
      <Box>
        <Typography variant="body1" style={{ fontWeight: 700 }}>
          {inviterName}
        </Typography>

        <Typography variant="body1">
          has invited you to the contract for
        </Typography>

        <Typography variant="body1" style={{ fontWeight: 700 }}>
          {property && addressForProperty(property)}
        </Typography>
      </Box>
    </Box>
  );
}
