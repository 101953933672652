import * as fs from "fs";
import {
  Document,
  Footer,
  ExternalHyperlink,
  UnderlineType,
  TabStopType,
  TabStopPosition,
  PageNumber,
  HeadingLevel,
  Packer,
  Paragraph,
  TextRun,
  Table,
  TableCell,
  TableRow,
  WidthType,
  BorderStyle,
  AlignmentType,
} from "docx";
import { saveAs } from "file-saver";
import { flattenDeep, isEmpty } from "lodash-es";
import moment from "moment";
import api from "../api/instance";
import {
  addressForProperty,
  Comment,
  ContractSummary,
  Enquiry,
  LegalReviewRequest,
  Property,
  TeamMemberRole,
  Contract,
  LegalReviewRequestStage,
  EnquiryStage,
} from "../api/models";

interface LegalReviewDocxProps {
  summaries: ContractSummary[];
  property: Property;
  contract: Contract;
  contractId: number;
  recordId: number;
  includeComments: boolean;
  legalReviewForName: string;
}

export async function generateLegalReviewDocxAndSave({
  summaries,
  property,
  contract,
  contractId,
  recordId,
  includeComments,
  legalReviewForName,
}: LegalReviewDocxProps) {
  const address = addressForProperty(property);

  const summariesPromises = await summaries.map(async (summary) => {
    const { data } = await api.get(
      `/contract/${contractId}/record/${recordId}/summary/${summary.pk}/comments/`
    );

    const comments = data ? (data as Comment[]) : [];

    return [
      new Paragraph({
        children: [],
      }),
      new Paragraph({
        text: summary.title,
        heading: HeadingLevel.HEADING_2,
      }),
      new Paragraph({
        children: [],
      }),
      new Paragraph({
        text: summary.content,
      }),
      new Paragraph({
        children: [],
      }),

      ...(includeComments && !isEmpty(comments)
        ? [
            new Paragraph({
              text: "Comments:",
              heading: HeadingLevel.HEADING_2,
            }),
            new Paragraph({
              children: [],
            }),
          ]
        : []),

      ...(includeComments ? comments : []).map((comment) => {
        return new Table({
          columnWidths: [4505, 4505],
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  borders: {
                    top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                    bottom: {
                      style: BorderStyle.NONE,
                      size: 0,
                      color: "FFFFFF",
                    },
                    left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                    right: {
                      style: BorderStyle.NONE,
                      size: 0,
                      color: "FFFFFF",
                    },
                  },
                  width: {
                    size: 4000,
                    type: WidthType.DXA,
                  },
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: `${comment.user.first_name} ${comment.user.last_name} `,
                          bold: true,
                        }),
                      ],
                    }),
                  ],
                }),
                new TableCell({
                  borders: {
                    top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                    bottom: {
                      style: BorderStyle.NONE,
                      size: 0,
                      color: "FFFFFF",
                    },
                    left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                    right: {
                      style: BorderStyle.NONE,
                      size: 0,
                      color: "FFFFFF",
                    },
                  },
                  width: {
                    size: 10000,
                    type: WidthType.DXA,
                  },
                  rowSpan: 2,
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: `${comment.content}`,
                        }),
                      ],
                    }),
                  ],
                }),
              ],
            }),
            new TableRow({
              children: [
                new TableCell({
                  borders: {
                    top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                    bottom: {
                      style: BorderStyle.NONE,
                      size: 0,
                      color: "FFFFFF",
                    },
                    left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                    right: {
                      style: BorderStyle.NONE,
                      size: 0,
                      color: "FFFFFF",
                    },
                  },
                  width: {
                    size: 4000,
                    type: WidthType.DXA,
                  },
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: moment(comment.created).format(
                            "MMM D [at] hh:mm A"
                          ),
                          bold: true,
                          size: 14,
                          color: "9F9F9F",
                        }),
                      ],
                    }),
                  ],
                }),
              ],
            }),

            new TableRow({
              children: [
                new TableCell({
                  borders: {
                    top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                    bottom: {
                      style: BorderStyle.NONE,
                      size: 0,
                      color: "FFFFFF",
                    },
                    left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                    right: {
                      style: BorderStyle.NONE,
                      size: 0,
                      color: "FFFFFF",
                    },
                  },

                  children: [],
                }),
              ],
            }),
          ],
        });
      }),
    ];
  });

  Promise.all(summariesPromises).then((result) => {
    const summariesContent = flattenDeep(result);

    const document = new Document({
      sections: [
        {
          children: [
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      borders: {
                        top: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        bottom: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        left: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        right: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                      },
                      width: {
                        size: 4000,
                        type: WidthType.DXA,
                      },
                      children: [
                        new Paragraph({
                          alignment: AlignmentType.LEFT,
                          children: [
                            new TextRun({
                              text: address,
                              size: 24,
                            }),
                          ],
                        }),
                      ],
                    }),
                  ],
                }),
              ],
            }),
            new Paragraph({
              spacing: {
                before: 160,
              },
              children: [],
              border: {
                top: {
                  color: "auto",
                  space: 0,
                  style: BorderStyle.SINGLE,
                  size: 0,
                },
              },
            }),

            new Paragraph({
              children: [],
            }),
            new Paragraph({
              children: [],
            }),
            new Paragraph({
              text: `Legal Review for ${legalReviewForName}`,
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
              children: [],
            }),

            ...summariesContent,
          ],
          footers: {
            default: new Footer({
              children: [
                new Paragraph({
                  spacing: {
                    after: 160,
                  },
                  children: [],
                  border: {
                    bottom: {
                      color: "auto",
                      space: 0,
                      style: BorderStyle.SINGLE,
                      size: 0,
                    },
                  },
                }),
                new Paragraph({
                  tabStops: [
                    {
                      type: TabStopType.RIGHT,
                      position: TabStopPosition.MAX,
                    },
                  ],
                  children: [
                    new TextRun({
                      text: `Generated with `,
                    }),
                    new ExternalHyperlink({
                      children: [
                        new TextRun({
                          text: "Contrax",
                          style: "Hyperlink",
                          underline: {
                            type: UnderlineType.SINGLE,
                          },
                        }),
                      ],
                      link: "https://contrax.com.au",
                    }),
                    new TextRun({
                      children: [
                        "\tPage ",
                        PageNumber.CURRENT,
                        " of ",
                        PageNumber.TOTAL_PAGES,
                      ],
                    }),
                  ],
                }),
              ],
            }),
          },
        },
      ],
    });

    const filename = `${addressForProperty(property).replace(/[\W_]+/g, "_")}`;

    Packer.toBlob(document).then((blob) => {
      saveAs(blob, `${filename}.docx`);
    });
  });
}

interface RequestsDocxProps {
  property: Property;
  requests: LegalReviewRequest[];
  enquiries: Enquiry[];
  contract: Contract;
  contractId: number;
  recordId: number;
  userTeamMemberRole: TeamMemberRole;
  requestsForName: string;
  includeComments: boolean;
}

export async function generateRequestsDocxAndSave({
  property,
  requests,
  enquiries,
  contract,
  contractId,
  recordId,
  userTeamMemberRole,
  requestsForName,
  includeComments,
}: RequestsDocxProps) {
  const isSeller =
    userTeamMemberRole === TeamMemberRole.seller ||
    userTeamMemberRole === TeamMemberRole.sellerLawyer;

  const isBuyer =
    userTeamMemberRole === TeamMemberRole.buyer ||
    userTeamMemberRole === TeamMemberRole.buyerLawyer;

  const address = addressForProperty(property);
  

  // Requests
  const requestsPromises = await requests.map(async (request, index) => {
    // Team comments
    const { data: teamCommentsData } = await api.get(
      `/contract/${contractId}/record/${recordId}/request/${
        isSeller ? request?.buyer_request_pk : request.pk
      }/${`comments/?is_buyer_flow=${isBuyer}`}`
    );

    const requestStatus =
      request.request_status === LegalReviewRequestStage.deleted;

    const teamComments = teamCommentsData
      ? (teamCommentsData as Comment[])
      : [];

    // Legal comments
    const { data: legalCommentsData } = await api.get(
      `/contract/${contractId}/record/${recordId}/request/${
        isSeller ? request?.buyer_request_pk : request.pk
      }/messages/`
    );

    const legalComments = legalCommentsData
      ? (legalCommentsData as Comment[])
      : [];

    return [
      ...(requestStatus
        ? []
        : [
            new Paragraph({
              text: `${index + 1}. ${request.title}`,
              heading: HeadingLevel.HEADING_2,
            }),
            new Paragraph({
              children: [],
            }),

            new Table({
              indent: {
                size: 250,
                type: WidthType.DXA,
              },
              columnWidths: [4505, 4505],
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      borders: {
                        top: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        bottom: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        left: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        right: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                      },
                      width: {
                        size: 4000,
                        type: WidthType.DXA,
                      },
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "Request",
                              bold: true,
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableCell({
                      borders: {
                        top: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        bottom: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        left: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        right: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                      },
                      width: {
                        size: 10000,
                        type: WidthType.DXA,
                      },

                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: request.description,
                            }),
                          ],
                        }),
                      ],
                    }),
                  ],
                }),

                new TableRow({
                  children: [
                    new TableCell({
                      borders: {
                        top: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        bottom: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        left: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        right: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                      },

                      children: [],
                    }),
                  ],
                }),
                new TableRow({
                  children: [
                    new TableCell({
                      borders: {
                        top: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        bottom: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        left: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        right: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                      },
                      width: {
                        size: 4000,
                        type: WidthType.DXA,
                      },
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "Proposed change to contract",
                              bold: true,
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableCell({
                      borders: {
                        top: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        bottom: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        left: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        right: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                      },
                      width: {
                        size: 10000,
                        type: WidthType.DXA,
                      },

                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text:
                                request.request_status ===
                                LegalReviewRequestStage.buyerAgreed
                                  ? request.alternate_change
                                  : request.propose,
                            }),
                          ],
                        }),
                      ],
                    }),
                  ],
                }),

                ...(!isEmpty(request.alternate_change) &&
                request.request_status ===
                  LegalReviewRequestStage.alternativeSuggested
                  ? [
                      new TableRow({
                        children: [
                          new TableCell({
                            borders: {
                              top: {
                                style: BorderStyle.NONE,
                                size: 0,
                                color: "FFFFFF",
                              },
                              bottom: {
                                style: BorderStyle.NONE,
                                size: 0,
                                color: "FFFFFF",
                              },
                              left: {
                                style: BorderStyle.NONE,
                                size: 0,
                                color: "FFFFFF",
                              },
                              right: {
                                style: BorderStyle.NONE,
                                size: 0,
                                color: "FFFFFF",
                              },
                            },

                            children: [],
                          }),
                        ],
                      }),
                      new TableRow({
                        children: [
                          new TableCell({
                            borders: {
                              top: {
                                style: BorderStyle.NONE,
                                size: 0,
                                color: "FFFFFF",
                              },
                              bottom: {
                                style: BorderStyle.NONE,
                                size: 0,
                                color: "FFFFFF",
                              },
                              left: {
                                style: BorderStyle.NONE,
                                size: 0,
                                color: "FFFFFF",
                              },
                              right: {
                                style: BorderStyle.NONE,
                                size: 0,
                                color: "FFFFFF",
                              },
                            },
                            width: {
                              size: 4000,
                              type: WidthType.DXA,
                            },
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: "Proposed alternative change to contract",
                                    bold: true,
                                  }),
                                ],
                              }),
                            ],
                          }),
                          new TableCell({
                            borders: {
                              top: {
                                style: BorderStyle.NONE,
                                size: 0,
                                color: "FFFFFF",
                              },
                              bottom: {
                                style: BorderStyle.NONE,
                                size: 0,
                                color: "FFFFFF",
                              },
                              left: {
                                style: BorderStyle.NONE,
                                size: 0,
                                color: "FFFFFF",
                              },
                              right: {
                                style: BorderStyle.NONE,
                                size: 0,
                                color: "FFFFFF",
                              },
                            },
                            width: {
                              size: 10000,
                              type: WidthType.DXA,
                            },

                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: request.alternate_change,
                                  }),
                                ],
                              }),
                            ],
                          }),
                        ],
                      }),
                    ]
                  : []),

                new TableRow({
                  children: [
                    new TableCell({
                      borders: {
                        top: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        bottom: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        left: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        right: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                      },

                      children: [],
                    }),
                  ],
                }),

                new TableRow({
                  children: [
                    new TableCell({
                      borders: {
                        top: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        bottom: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        left: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        right: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                      },
                      width: {
                        size: 4000,
                        type: WidthType.DXA,
                      },
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "Status",
                              bold: true,
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableCell({
                      borders: {
                        top: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        bottom: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        left: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        right: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                      },
                      width: {
                        size: 10000,
                        type: WidthType.DXA,
                      },

                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: request.request_status,
                            }),
                          ],
                        }),
                      ],
                    }),
                  ],
                }),

                new TableRow({
                  children: [
                    new TableCell({
                      borders: {
                        top: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        bottom: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        left: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        right: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                      },

                      children: [],
                    }),
                  ],
                }),
              ],
            }),

            ...(includeComments && !isEmpty(legalComments)
              ? [
                  new Paragraph({
                    text: "Legal comments:",
                    heading: HeadingLevel.HEADING_3,
                    indent: {
                      left: 250,
                    },
                  }),
                  new Paragraph({
                    children: [],
                  }),
                  ...legalComments.map((comment) => {
                    return new Table({
                      indent: {
                        size: 250,
                        type: WidthType.DXA,
                      },
                      columnWidths: [4505, 4505],
                      rows: [
                        new TableRow({
                          children: [
                            new TableCell({
                              borders: {
                                top: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                bottom: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                left: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                right: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                              },
                              width: {
                                size: 4000,
                                type: WidthType.DXA,
                              },
                              children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: `${comment.user.first_name} ${comment.user.last_name} `,
                                      bold: true,
                                    }),
                                  ],
                                }),
                              ],
                            }),
                            new TableCell({
                              borders: {
                                top: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                bottom: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                left: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                right: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                              },
                              width: {
                                size: 10000,
                                type: WidthType.DXA,
                              },
                              rowSpan: 2,
                              children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: `${comment.content}`,
                                    }),
                                  ],
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableRow({
                          children: [
                            new TableCell({
                              borders: {
                                top: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                bottom: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                left: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                right: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                              },
                              width: {
                                size: 4000,
                                type: WidthType.DXA,
                              },
                              children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: moment(comment.created).format(
                                        "MMM D [at] hh:mm A"
                                      ),
                                      bold: true,
                                      size: 14,
                                      color: "9F9F9F",
                                    }),
                                  ],
                                }),
                              ],
                            }),
                          ],
                        }),

                        new TableRow({
                          children: [
                            new TableCell({
                              borders: {
                                top: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                bottom: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                left: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                right: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                              },

                              children: [],
                            }),
                          ],
                        }),
                      ],
                    });
                  }),
                ]
              : []),

            ...(includeComments && !isEmpty(teamComments)
              ? [
                  new Paragraph({
                    text: "Team comments:",
                    heading: HeadingLevel.HEADING_3,
                    indent: {
                      left: 250,
                    },
                  }),
                  new Paragraph({
                    children: [],
                  }),
                  ...teamComments.map((comment) => {
                    return new Table({
                      indent: {
                        size: 250,
                        type: WidthType.DXA,
                      },
                      columnWidths: [4505, 4505],
                      rows: [
                        new TableRow({
                          children: [
                            new TableCell({
                              borders: {
                                top: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                bottom: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                left: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                right: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                              },
                              width: {
                                size: 4000,
                                type: WidthType.DXA,
                              },
                              children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: `${comment.user.first_name} ${comment.user.last_name} `,
                                      bold: true,
                                    }),
                                  ],
                                }),
                              ],
                            }),
                            new TableCell({
                              borders: {
                                top: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                bottom: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                left: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                right: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                              },
                              width: {
                                size: 10000,
                                type: WidthType.DXA,
                              },
                              rowSpan: 2,
                              children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: `${comment.content}`,
                                    }),
                                  ],
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableRow({
                          children: [
                            new TableCell({
                              borders: {
                                top: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                bottom: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                left: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                right: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                              },
                              width: {
                                size: 4000,
                                type: WidthType.DXA,
                              },
                              children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: moment(comment.created).format(
                                        "MMM D [at] hh:mm A"
                                      ),
                                      bold: true,
                                      size: 14,
                                      color: "9F9F9F",
                                    }),
                                  ],
                                }),
                              ],
                            }),
                          ],
                        }),

                        new TableRow({
                          children: [
                            new TableCell({
                              borders: {
                                top: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                bottom: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                left: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                right: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                              },

                              children: [],
                            }),
                          ],
                        }),
                      ],
                    });
                  }),

                  new Paragraph({
                    children: [],
                  }),
                ]
              : []),
          ]),
    ];
  });

  // Enquiries
  const enquiriesPromises = await enquiries.map(async (enquiry, index) => {
    // Team comments
    const { data: teamCommentsData } = await api.get(
      `/contract/${contractId}/record/${recordId}/enquiry/${
        isSeller ? enquiry?.buyer_enquiry_pk : enquiry.pk
      }/${`comments/?is_buyer_flow=${isBuyer}`}`
    );

    const enquiryStatus = enquiry.enquiry_status === EnquiryStage.Deleted;

    const teamComments = teamCommentsData
      ? (teamCommentsData as Comment[])
      : [];

    // Legal comments
    const { data: legalCommentsData } = await api.get(
      `/contract/${contractId}/record/${recordId}/enquiry/${
        isSeller ? enquiry?.buyer_enquiry_pk : enquiry.pk
      }/messages/`
    );

    const legalComments = legalCommentsData
      ? (legalCommentsData as Comment[])
      : [];

    return [
      ...(enquiryStatus
        ? []
        : [
            new Paragraph({
              text: `${index + 1}. ${enquiry.title}`,
              heading: HeadingLevel.HEADING_2,
            }),
            new Paragraph({
              children: [],
            }),

            new Table({
              indent: {
                size: 250,
                type: WidthType.DXA,
              },
              columnWidths: [4505, 4505],
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      borders: {
                        top: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        bottom: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        left: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        right: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                      },
                      width: {
                        size: 4000,
                        type: WidthType.DXA,
                      },
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "General enquiry",
                              bold: true,
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableCell({
                      borders: {
                        top: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        bottom: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        left: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        right: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                      },
                      width: {
                        size: 10000,
                        type: WidthType.DXA,
                      },

                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: enquiry.enquiry,
                            }),
                          ],
                        }),
                      ],
                    }),
                  ],
                }),

                new TableRow({
                  children: [
                    new TableCell({
                      borders: {
                        top: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        bottom: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        left: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        right: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                      },

                      children: [],
                    }),
                  ],
                }),
                new TableRow({
                  children: [
                    new TableCell({
                      borders: {
                        top: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        bottom: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        left: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        right: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                      },
                      width: {
                        size: 4000,
                        type: WidthType.DXA,
                      },
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "Status",
                              bold: true,
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableCell({
                      borders: {
                        top: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        bottom: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        left: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        right: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                      },
                      width: {
                        size: 10000,
                        type: WidthType.DXA,
                      },

                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: enquiry.enquiry_status,
                            }),
                          ],
                        }),
                      ],
                    }),
                  ],
                }),

                new TableRow({
                  children: [
                    new TableCell({
                      borders: {
                        top: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        bottom: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        left: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                        right: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: "FFFFFF",
                        },
                      },
                      children: [],
                    }),
                  ],
                }),

                ...(!isEmpty(enquiry.answer_to_enquiry)
                  ? [
                      new TableRow({
                        children: [
                          new TableCell({
                            borders: {
                              top: {
                                style: BorderStyle.NONE,
                                size: 0,
                                color: "FFFFFF",
                              },
                              bottom: {
                                style: BorderStyle.NONE,
                                size: 0,
                                color: "FFFFFF",
                              },
                              left: {
                                style: BorderStyle.NONE,
                                size: 0,
                                color: "FFFFFF",
                              },
                              right: {
                                style: BorderStyle.NONE,
                                size: 0,
                                color: "FFFFFF",
                              },
                            },
                            width: {
                              size: 4000,
                              type: WidthType.DXA,
                            },
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: "Answer",
                                    bold: true,
                                  }),
                                ],
                              }),
                            ],
                          }),
                          new TableCell({
                            borders: {
                              top: {
                                style: BorderStyle.NONE,
                                size: 0,
                                color: "FFFFFF",
                              },
                              bottom: {
                                style: BorderStyle.NONE,
                                size: 0,
                                color: "FFFFFF",
                              },
                              left: {
                                style: BorderStyle.NONE,
                                size: 0,
                                color: "FFFFFF",
                              },
                              right: {
                                style: BorderStyle.NONE,
                                size: 0,
                                color: "FFFFFF",
                              },
                            },
                            width: {
                              size: 10000,
                              type: WidthType.DXA,
                            },

                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: enquiry.answer_to_enquiry,
                                  }),
                                ],
                              }),
                            ],
                          }),
                        ],
                      }),

                      new TableRow({
                        children: [
                          new TableCell({
                            borders: {
                              top: {
                                style: BorderStyle.NONE,
                                size: 0,
                                color: "FFFFFF",
                              },
                              bottom: {
                                style: BorderStyle.NONE,
                                size: 0,
                                color: "FFFFFF",
                              },
                              left: {
                                style: BorderStyle.NONE,
                                size: 0,
                                color: "FFFFFF",
                              },
                              right: {
                                style: BorderStyle.NONE,
                                size: 0,
                                color: "FFFFFF",
                              },
                            },

                            children: [],
                          }),
                        ],
                      }),
                    ]
                  : []),
              ],
            }),

            ...(includeComments && !isEmpty(legalComments)
              ? [
                  new Paragraph({
                    text: "Legal comments:",
                    heading: HeadingLevel.HEADING_3,
                    indent: {
                      left: 250,
                    },
                  }),
                  new Paragraph({
                    children: [],
                  }),
                  ...legalComments.map((comment) => {
                    return new Table({
                      indent: {
                        size: 250,
                        type: WidthType.DXA,
                      },
                      columnWidths: [4505, 4505],
                      rows: [
                        new TableRow({
                          children: [
                            new TableCell({
                              borders: {
                                top: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                bottom: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                left: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                right: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                              },
                              width: {
                                size: 4000,
                                type: WidthType.DXA,
                              },
                              children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: `${comment.user.first_name} ${comment.user.last_name} `,
                                      bold: true,
                                    }),
                                  ],
                                }),
                              ],
                            }),
                            new TableCell({
                              borders: {
                                top: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                bottom: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                left: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                right: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                              },
                              width: {
                                size: 10000,
                                type: WidthType.DXA,
                              },
                              rowSpan: 2,
                              children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: `${comment.content}`,
                                    }),
                                  ],
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableRow({
                          children: [
                            new TableCell({
                              borders: {
                                top: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                bottom: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                left: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                right: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                              },
                              width: {
                                size: 4000,
                                type: WidthType.DXA,
                              },
                              children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: moment(comment.created).format(
                                        "MMM D [at] hh:mm A"
                                      ),
                                      bold: true,
                                      size: 14,
                                      color: "9F9F9F",
                                    }),
                                  ],
                                }),
                              ],
                            }),
                          ],
                        }),

                        new TableRow({
                          children: [
                            new TableCell({
                              borders: {
                                top: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                bottom: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                left: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                right: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                              },

                              children: [],
                            }),
                          ],
                        }),
                      ],
                    });
                  }),
                ]
              : []),

            ...(includeComments && !isEmpty(teamComments)
              ? [
                  new Paragraph({
                    text: "Team comments:",
                    heading: HeadingLevel.HEADING_3,
                    indent: {
                      left: 250,
                    },
                  }),
                  new Paragraph({
                    children: [],
                  }),
                  ...teamComments.map((comment) => {
                    return new Table({
                      indent: {
                        size: 250,
                        type: WidthType.DXA,
                      },
                      columnWidths: [4505, 4505],
                      rows: [
                        new TableRow({
                          children: [
                            new TableCell({
                              borders: {
                                top: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                bottom: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                left: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                right: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                              },
                              width: {
                                size: 4000,
                                type: WidthType.DXA,
                              },
                              children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: `${comment.user.first_name} ${comment.user.last_name} `,
                                      bold: true,
                                    }),
                                  ],
                                }),
                              ],
                            }),
                            new TableCell({
                              borders: {
                                top: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                bottom: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                left: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                right: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                              },
                              width: {
                                size: 10000,
                                type: WidthType.DXA,
                              },
                              rowSpan: 2,
                              children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: `${comment.content}`,
                                    }),
                                  ],
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableRow({
                          children: [
                            new TableCell({
                              borders: {
                                top: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                bottom: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                left: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                right: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                              },
                              width: {
                                size: 4000,
                                type: WidthType.DXA,
                              },
                              children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: moment(comment.created).format(
                                        "MMM D [at] hh:mm A"
                                      ),
                                      bold: true,
                                      size: 14,
                                      color: "9F9F9F",
                                    }),
                                  ],
                                }),
                              ],
                            }),
                          ],
                        }),

                        new TableRow({
                          children: [
                            new TableCell({
                              borders: {
                                top: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                bottom: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                left: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                                right: {
                                  style: BorderStyle.NONE,
                                  size: 0,
                                  color: "FFFFFF",
                                },
                              },

                              children: [],
                            }),
                          ],
                        }),
                      ],
                    });
                  }),
                  new Paragraph({
                    children: [],
                  }),
                ]
              : []),

            new Paragraph({
              children: [],
            }),
          ]),
    ];
  });

  Promise.all(requestsPromises).then((result) => {
    const requestsContent = flattenDeep(result);

    Promise.all(enquiriesPromises).then((result) => {
      const enquiriesContent = flattenDeep(result);

      const document = new Document({
        sections: [
          {
            children: [
              new Table({
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        borders: {
                          top: {
                            style: BorderStyle.NONE,
                            size: 0,
                            color: "FFFFFF",
                          },
                          bottom: {
                            style: BorderStyle.NONE,
                            size: 0,
                            color: "FFFFFF",
                          },
                          left: {
                            style: BorderStyle.NONE,
                            size: 0,
                            color: "FFFFFF",
                          },
                          right: {
                            style: BorderStyle.NONE,
                            size: 0,
                            color: "FFFFFF",
                          },
                        },
                        width: {
                          size: 4000,
                          type: WidthType.DXA,
                        },
                        children: [
                          new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                              new TextRun({
                                text: address,
                                size: 24,
                              }),
                            ],
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new Paragraph({
                spacing: {
                  before: 160,
                },
                children: [],
                border: {
                  top: {
                    color: "auto",
                    space: 0,
                    style: BorderStyle.SINGLE,
                    size: 0,
                  },
                },
              }),

              new Paragraph({
                children: [],
              }),
              new Paragraph({
                children: [],
              }),
              new Paragraph({
                text: `Requests for ${requestsForName}`,
                heading: HeadingLevel.HEADING_1,
              }),
              new Paragraph({
                children: [],
              }),
              new Paragraph({
                children: [],
              }),
              new Paragraph({
                text: "Amendment requests",
                heading: HeadingLevel.HEADING_1,
              }),
              new Paragraph({
                children: [],
              }),
              ...requestsContent,

              new Paragraph({
                text: "General enquiries",
                heading: HeadingLevel.HEADING_1,
              }),
              new Paragraph({
                children: [],
              }),

              ...enquiriesContent,
            ],
            footers: {
              default: new Footer({
                children: [
                  new Paragraph({
                    spacing: {
                      after: 160,
                    },
                    children: [],
                    border: {
                      bottom: {
                        color: "auto",
                        space: 0,
                        style: BorderStyle.SINGLE,
                        size: 0,
                      },
                    },
                  }),
                  new Paragraph({
                    tabStops: [
                      {
                        type: TabStopType.RIGHT,
                        position: TabStopPosition.MAX,
                      },
                    ],
                    children: [
                      new TextRun({
                        text: `Generated with `,
                      }),
                      new ExternalHyperlink({
                        children: [
                          new TextRun({
                            text: "Contrax",
                            style: "Hyperlink",
                            underline: {
                              type: UnderlineType.SINGLE,
                            },
                          }),
                        ],
                        link: "https://contrax.com.au",
                      }),

                      new TextRun({
                        children: [
                          "\tPage ",
                          PageNumber.CURRENT,
                          " of ",
                          PageNumber.TOTAL_PAGES,
                        ],
                      }),
                    ],
                  }),
                ],
              }),
            },
          },
        ],
      });

      const filename = `${addressForProperty(
        property
      )} ${requestsForName}`.replace(/[\W_]+/g, "_");

      Packer.toBlob(document).then((blob) => {
        saveAs(blob, `${filename}.docx`);
      });
    });
  });
}
