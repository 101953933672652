import { Box, IconButton, Typography } from "@material-ui/core";
import { debounce, isEmpty, isNil } from "lodash-es";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { LegalReviewRequest } from "../../api/models";
import { Colors } from "../../constants/colors";
import { debounceDelay } from "../../constants/common";
import { useMobile } from "../../hooks/mobile";
import ExpandIcon from "../../images/chevron-down.svg";
import Button, { ButtonSize, ButtonType } from "../common/Button";
import TextField from "../common/TextField";
import StatusPill, { statusPillTypeForRequest } from "../StatusPill";

export enum RequestEditorBoxType {
  default,
  dismissed,
}

interface Props {
  type?: RequestEditorBoxType;
  request: LegalReviewRequest;
  index: number;
  onDescriptionChanged?: (description: string) => unknown;
  onProposalChanged?: (proposal: string) => unknown;
  onDismiss?: () => unknown;
  onAdd?: () => unknown;
  onDelete?: () => unknown;
  onRestore?: () => unknown;
  onViewComments: () => unknown;
  onRevert?: () => unknown;
  onSave?: (description: string, proposal: string) => unknown;
  addingNewVersion?: boolean;
  onViewContract?: () => unknown;
}

interface HeaderProps {
  request: LegalReviewRequest;
  title: string;
}

function Header({ request, title }: HeaderProps) {
  const isMobile = useMobile();

  if (isMobile) {
    return (
      <Box
        mt="8px"
        height="55px"
        display="flex"
        flexDirection="row"
        alignItems="center"
        pl="16px"
        pr="16px"
      >
        <Box display="flex" flexDirection="row" alignItems="center" flex={1}>
          <Typography variant="body1" style={{ fontWeight: 700 }}>
            {title}
          </Typography>
        </Box>

        <StatusPill type={statusPillTypeForRequest(request)} />
      </Box>
    );
  }
  return (
    <Box
      height="77px"
      boxShadow="0px 1px 0px #E0E0E0"
      display="flex"
      flexDirection="row"
      alignItems="center"
      pl="24px"
      pr="24px"
    >
      <Typography variant="h6" style={{ fontWeight: 700, flex: 1 }}>
        {title}
      </Typography>
      <StatusPill type={statusPillTypeForRequest(request)} />
    </Box>
  );
}

interface TextFieldsProps {
  title: string;
  description: string;
  proposal: string;
  request: LegalReviewRequest;
  onDescription: (description: string) => unknown;
  onProposal: (proposal: string) => unknown;
}

function TextFields({
  title,
  description,
  proposal,
  request,
  onDescription,
  onProposal,
}: TextFieldsProps) {
  const isMobile = useMobile();

  return (
    <Box
      width={isMobile ? undefined : "515px"}
      bgcolor={Colors.Grey5}
      borderRadius="8px"
      ml={isMobile ? "8px" : undefined}
      mr={isMobile ? "8px" : undefined}
      mb={isMobile ? "8px" : undefined}
    >
      <Box m="16px">
        <TextField title="Title" value={title} disabled={!isNil(request)} />
        <TextField
          title="Request Description"
          value={description}
          onChange={onDescription}
          multiline
        />
        <TextField
          title="Propose change to contract"
          value={proposal}
          onChange={onProposal}
          multiline
        />
      </Box>
    </Box>
  );
}

interface SummaryProps {
  request: LegalReviewRequest;
  onViewContract?: () => unknown;
}

function Summary({ request, onViewContract }: SummaryProps) {
  const isMobile = useMobile();

  const [show, setShow] = React.useState(false);

  if (isMobile) {
    return (
      <Box display="flex" flexDirection="column" whiteSpace="pre-line">
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          mb="16px"
          pl="16px"
          pr="16px"
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="body2" style={{ fontWeight: 700, flex: 1 }}>
              Summary
            </Typography>
            <IconButton onClick={() => setShow(!show)}>
              <img src={ExpandIcon} alt="icon_expand" />
            </IconButton>
          </Box>

          {show && (
            <Typography variant="body2">
              {request.legal_review_summary?.content}
            </Typography>
          )}
        </Box>
      </Box>
    );
  }

  if (request.legal_review_summary) {
    return (
      <Box width="515px" ml="20px">
        <Typography variant="body2" style={{ fontWeight: 700 }}>
          Summary
        </Typography>
        <Typography
          variant="body2"
          style={{ marginTop: "16px", marginBottom: "16px" }}
        >
          {request.legal_review_summary?.content}
        </Typography>
        <Button
          title="VIEW IN CONTRACT"
          type={ButtonType.secondary}
          size={ButtonSize.small}
          onClick={onViewContract}
        />
      </Box>
    );
  }

  return <></>;
}

function _RequestEditorBox({
  request,
  index,
  onDescriptionChanged,
  onProposalChanged,
  onDismiss,
  onAdd,
  onDelete,
  onRestore,
  type = RequestEditorBoxType.default,
  onViewComments,
  onRevert,
  onSave,
  addingNewVersion,
  onViewContract,
}: Props) {
  const isMobile = useMobile();

  const [title, setTitle] = React.useState(request.title);
  const [description, setDescription] = React.useState(request.description);
  const [proposal, setProposal] = React.useState(request.propose);

  // console.debug("rendering index: ", index);

  // console.debug("index: ", index);
  // console.debug("request: ", request);
  // console.debug("title: ", title);
  React.useEffect(() => {
    if (request) {
      console.debug("setting with request: ", request);
      setTitle(request.title);
      setDescription(request.description);
      setProposal(request.propose);
    }
  }, [request]);

  const descriptionChanged = (text: string) => {
    onDescriptionChanged && onDescriptionChanged(text);
  };

  const debouncedDescriptionChanged = React.useCallback(
    debounce(descriptionChanged, debounceDelay),
    []
  );

  const onDescription = (text) => {
    setDescription(text);

    if (!request.p_addingNewVersion) {
      debouncedDescriptionChanged(text);
    }
  };

  const proposalChanged = (text: string) => {
    onProposalChanged && onProposalChanged(text);
  };

  const debouncedProposalChanged = React.useCallback(
    debounce(proposalChanged, debounceDelay),
    []
  );

  const onProposal = (text) => {
    setProposal(text);

    if (!request.p_addingNewVersion) {
      debouncedProposalChanged(text);
    }
  };

  const showRevertAndSave =
    request.p_addingNewVersion || !isEmpty(request.old_versions);

  const _commentsCount = () => {
    if (request.p_commentsCount > 0) {
      return request.p_commentsCount;
    }

    return request.comments_count || 0;
  };

  const _messagesCount = () => {
    if (request.p_messagesCount > 0) {
      return request.p_messagesCount;
    }

    return request.messages_count || 0;
  };

  const commentsCount = _commentsCount();
  const messagesCount = _messagesCount();

  return (
    <Draggable draggableId={`request-${request.pk}-${index}`} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Box
            border="1px solid #E1EAFF"
            bgcolor="white"
            borderRadius="16px"
            m="10px"
            display="flex"
            flexDirection="column"
            width={isMobile ? "95vw" : "1097px"}
          >
            <Header request={request} title={request.title} />

            {!isMobile && (
              <Box display="flex" flexDirection="row" p="24px">
                <TextFields
                  title={title}
                  description={description}
                  proposal={proposal}
                  onDescription={onDescription}
                  onProposal={onProposal}
                  request={request}
                />

                <Summary request={request} onViewContract={onViewContract} />
              </Box>
            )}

            {isMobile && (
              <Box display="flex" flexDirection="column">
                <Summary request={request} onViewContract={onViewContract} />

                <TextFields
                  title={title}
                  description={description}
                  proposal={proposal}
                  onDescription={onDescription}
                  onProposal={onProposal}
                  request={request}
                />
              </Box>
            )}

            <Box
              display="flex"
              flexDirection="row"
              boxShadow="0px -1px 0px #E0E0E0"
              height="74px"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <Button
                  title="COMMENTS"
                  type={ButtonType.transparent}
                  size={ButtonSize.medium}
                  onClick={onViewComments}
                  letterSpacing="2px"
                />

                {!isMobile && (
                  <Typography variant="body2" style={{ marginLeft: "44px" }}>
                    {commentsCount + messagesCount} Comments
                  </Typography>
                )}
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                pr="16px"
              >
                {/* TODO: Also for requests with old versions */}
                {showRevertAndSave && (
                  <>
                    <Button
                      type={ButtonType.secondary}
                      size={ButtonSize.small}
                      title="REVERT"
                      width="108px"
                      onClick={onRevert}
                    />
                    <Box width="8px" />
                    <Button
                      loading={addingNewVersion}
                      type={ButtonType.primary}
                      size={ButtonSize.small}
                      title="SAVE"
                      width="79px"
                      onClick={() => onSave && onSave(description, proposal)}
                    />
                  </>
                )}

                {!showRevertAndSave && type === RequestEditorBoxType.default && (
                  <>
                    <Button
                      type={ButtonType.destructive}
                      size={ButtonSize.small}
                      title="DISMISS"
                      width="93px"
                      onClick={onDismiss}
                    />
                    <Box width="8px" />
                    <Button
                      type={ButtonType.primary}
                      size={ButtonSize.small}
                      title="ADD"
                      width="58px"
                      onClick={onAdd}
                    />
                  </>
                )}

                {type === RequestEditorBoxType.dismissed && (
                  <>
                    <Button
                      type={ButtonType.destructive}
                      size={ButtonSize.small}
                      title="DELETE"
                      width="105px"
                      onClick={onDelete}
                    />
                    <Box width="8px" />
                    <Button
                      type={ButtonType.primary}
                      size={ButtonSize.small}
                      title="RESTORE"
                      width="120px"
                      onClick={onRestore}
                    />
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </div>
      )}
    </Draggable>
  );
}

const RequestEditorBox = React.memo(
  _RequestEditorBox,
  (prevProps, nextProps) => {
    return (
      prevProps.request.legal_review_summary ==
        nextProps.request.legal_review_summary &&
      prevProps.request.title === nextProps.request.title &&
      prevProps.request.description === nextProps.request.description &&
      prevProps.request.propose === nextProps.request.propose &&
      prevProps.request.status === nextProps.request.status &&
      prevProps.addingNewVersion === nextProps.addingNewVersion
    );
  }
);

export default RequestEditorBox;
