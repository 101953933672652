import { Box, Typography } from "@material-ui/core";
import produce from "immer";
import { isEmpty, isNil } from "lodash-es";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import api from "../api/instance";
import {
  BuyerSellerStatus,
  Contract,
  Enquiry,
  EnquiryStage,
  EnquiryStatus,
  LegalReviewRequest,
  LegalReviewRequestStage,
  LegalReviewRequestStatus,
  TeamMemberRole,
} from "../api/models";
import AlertWrapper from "../components/AlertWrapper";
import AuthenticatedWrapper from "../components/AuthenticatedWrapper";
import CommentsDialog, {
  CommentsDialogJourney,
  CommentsDialogType,
} from "../components/Comments/CommentsDialog";
import PageSection from "../components/common/PageSection";
import StickyHeader from "../components/common/StickyHeader";
import LegalReviewBlueHeader from "../components/LegalReviewBlueHeader";
import EnquiryItem from "../components/RequestResponder/EnquiryItem";
import RequestItem from "../components/RequestResponder/RequestItem";
import { Colors } from "../constants/colors";
import { useAlert } from "../contexts/Alert";
import { parseApiError } from "../helpers/error";
import { useMobile } from "../hooks/mobile";
import useInterval from "../hooks/useInterval";
import {
  refreshContractTeamsAndRole,
  useContractStore,
} from "../stores/contract";
import "../styles/podium.css";

interface SaveProps {
  savingClosing?: boolean;
}

export default function RequestResponder() {
  const location = useLocation();
  const history = useHistory();
  const { setErrorMessage } = useAlert();
  const isMobile = useMobile();

  const query = new URLSearchParams(location.search);
  const _contractId = query.get("contractId");
  const contractId = !isNil(_contractId) ? parseInt(_contractId) : null;
  const _recordId = query.get("recordId");
  const recordId = !isNil(_recordId) ? parseInt(_recordId) : null;

  const { data: contractData } = useSWR(
    contractId && recordId
      ? `/view-contracts/${contractId}/?recordId=${recordId}`
      : null
  );
  const contract = contractData ? (contractData as Contract) : null;

  const { userTeamMemberRole, setContractAndRecordId } = useContractStore();

  const [requests, setRequests] = React.useState<LegalReviewRequest[]>([]);
  const [enquiries, setEnquiries] = React.useState<Enquiry[]>([]);

  const [enquiryForCommentsDialog, setEnquiryForCommentsDialog] =
    React.useState<Enquiry | null>(null);
  const [enquiryCommentsDialogOpen, setEnquiryCommentsDialogOpen] =
    React.useState(false);

  const [requestForCommentsDialog, setRequestForCommentsDialog] =
    React.useState<LegalReviewRequest | null>(null);
  const [requestCommentsDialogOpen, setRequestCommentsDialogOpen] =
    React.useState(false);

  const [hasNewEdits, setHasNewEdits] = React.useState(false);
  const [lastSaved, setLastSaved] = React.useState<Date | null>(null);

  React.useEffect(() => {
    if (!isNil(contractId) && !isNil(recordId)) {
      setContractAndRecordId(+contractId, +recordId);
      refreshContractTeamsAndRole();
    }
  }, [contractId, recordId]);

  React.useEffect(() => {
    if (
      userTeamMemberRole === TeamMemberRole.buyer ||
      userTeamMemberRole === TeamMemberRole.seller ||
      userTeamMemberRole === TeamMemberRole.agent
    ) {
      // Redirect to dashboard

      history.push(`/dashboard`);
    }
  }, [userTeamMemberRole]);

  // const unsentItemsCount =
  //   requests.filter(
  //     (request) => request.seller_status !== BuyerSellerStatus.Sent
  //   ).length + enquiries.filter((enquiry) => !enquiry.is_sent).length; // TODO: seller_status !== "Sent", waiting for API

  // const allCompletedAndSent = unsentItemsCount === 0;

  const allCompleted =
    requests.filter((r) => r.seller_status !== BuyerSellerStatus.Completed)
      .length +
      enquiries.filter((e) => e.seller_status !== BuyerSellerStatus.Completed)
        .length ===
    0;

  const fetchRequests = () => {
    api
      .get(
        `contract/${contractId}/record/${recordId}/seller-legal-review-requests/`
      )
      .then((response) => {
        const requests = response.data as LegalReviewRequest[];

        requests.forEach((request) => {
          if (request.request_status === LegalReviewRequestStage.pending) {
            request.status = LegalReviewRequestStatus.editing;
            // } else if (
            //   request.request_status === LegalReviewRequestStage.deleted
            // ) {
            //   request.seller_responded = true; // TODO: Workaround
          } else {
            request.status = request.status;
          }
        });

        setRequests(
          requests.sort((a, b) =>
            b.request_status === LegalReviewRequestStage.deleted ? -1 : 0
          )
        );
      })
      .catch((error) => {
        setErrorMessage(parseApiError(error));
      });
  };

  const fetchEnquiries = () => {
    api
      .get(
        `contract/${contractId}/record/${recordId}/seller-legal-review-enquiries/`
      )
      .then((response) => {
        const enquiries = response.data as Enquiry[];
        enquiries.forEach((enquiry) => {
          if (enquiry.enquiry_status === EnquiryStage.Pending) {
            enquiry.status = EnquiryStatus.Editing;
            // } else if (enquiry.enquiry_status === EnquiryStage.Deleted) {
            //   enquiry.seller_responded = true; // TODO: Workaround
          } else {
            enquiry.status = enquiry.status;
          }
        });

        setEnquiries(
          enquiries
            .sort((a, b) =>
              b.enquiry_status === EnquiryStage.Pending ? 1 : -1
            )
            .sort((a, b) =>
              b.enquiry_status === EnquiryStage.Deleted ? -1 : 0
            )
        );
      })
      .catch((error) => {
        setErrorMessage(parseApiError(error));
      });
  };
  React.useEffect(() => {
    fetchRequests();
    fetchEnquiries();
  }, []);

  const save = ({ savingClosing = false }: SaveProps) => {
    // console.debug("saving ... ");
    // console.debug("requests: ", requests);
    // console.debug("enquiries: ", enquiries);

    if (allCompleted) {
      if (savingClosing) {
        history.push(
          `/request-workspace?contractId=${contractId}&recordId=${recordId}`
        );
      }

      return;
    }

    // Requests
    let data: any[] = requests
      .filter((request) => !isNil(request) && request.p_updated)
      .map((request, index) => {
        const {
          buyer_request_pk,
          alternate_change,
          seller_message,
          request_status,
          status,
          seller_responded,
        } = request;
        return {
          pk: buyer_request_pk,
          alternate_change,
          seller_message,
          seller_status: status,
          status: request_status,
          seller_responded: !isNil(seller_responded) ? seller_responded : false,
          index: index + 1,
          type: "Request",
        };
      });

    // Enquiries
    data = data.concat(
      enquiries
        .filter((enquiry) => !isNil(enquiry) && enquiry.p_updated)
        .map((enquiry, index) => {
          const {
            buyer_enquiry_pk,
            answer_to_enquiry,
            enquiry_status,
            status,
            seller_responded,
          } = enquiry;
          return {
            pk: buyer_enquiry_pk,
            answer_to_enquiry,
            seller_status: status,
            status: enquiry_status,
            seller_responded: !isNil(seller_responded)
              ? seller_responded
              : false,
            index: index + 1,
            type: "Enquiry",
          };
        })
    );

    // console.debug("data: ", data);

    api
      .post(`/contract/${contractId}/record/${recordId}/seller-response/`, {
        data,
      })
      .then((response) => {
        console.debug("response: ", response);

        setHasNewEdits(false);

        setLastSaved(new Date());

        if (savingClosing) {
          history.push(
            `/request-workspace?contractId=${contractId}&recordId=${recordId}`
          );
        }
      })
      .catch((error) => {
        console.debug("error: ", error);

        setErrorMessage(parseApiError(error));
      });
  };

  // Auto save
  useInterval(() => {
    save({ savingClosing: false });
  }, 10000); // 10 Seconds

  const outstandingEnquiriesCount = enquiries.filter(
    (enquiry) => enquiry.status === EnquiryStatus.Editing
  ).length;
  const outstandingRequestsCount = requests.filter(
    (request) => request.status === LegalReviewRequestStatus.editing
  ).length;

  // Comments count updates
  // REMINDER: Immer makes objects immutable hence we must use immer to update internal variables as well
  const onRequestCommentsCountUpdated = (
    request: LegalReviewRequest,
    count: number
  ) => {
    setRequests(
      produce((draft) => {
        const requestDraft = draft.find((r) => r.pk === request.pk);
        if (requestDraft) {
          requestDraft.p_commentsCount = count;
        }
      })
    );
  };
  const onEnquiryCommentsCountUpdated = (enquiry: Enquiry, count: number) => {
    setEnquiries(
      produce((draft) => {
        const enquiryDraft = draft.find((e) => e.pk === enquiry.pk);
        if (enquiryDraft) {
          enquiryDraft.p_commentsCount = count;
        }
      })
    );
  };

  const onRequestMessagesCountUpdated = (
    request: LegalReviewRequest,
    count: number
  ) => {
    setRequests(
      produce((draft) => {
        const requestDraft = draft.find((r) => r.pk === request.pk);
        if (requestDraft) {
          requestDraft.p_messagesCount = count;
        }
      })
    );
  };
  const onEnquiryMessagesCountUpdated = (enquiry: Enquiry, count: number) => {
    setEnquiries(
      produce((draft) => {
        const enquiryDraft = draft.find((e) => e.pk === enquiry.pk);
        if (enquiryDraft) {
          enquiryDraft.p_messagesCount = count;
        }
      })
    );
  };

  // Idle timer
  // const handleOnIdle = (event) => {
  //   console.log("***user is idle", event);
  //   console.log("***last active", getLastActiveTime());
  // };

  // const handleOnActive = (event) => {
  //   console.log("***user is active", event);
  //   console.log("***time remaining", getRemainingTime());
  // };

  // const handleOnAction = (event) => {
  //   console.log("***user did something", event);
  // };

  // const { getRemainingTime, getLastActiveTime } = useIdleTimer({
  //   timeout: (1000 * 60 * 1) / 4, // 15 seconds -- TESTING
  //   onIdle: handleOnIdle,
  //   onActive: handleOnActive,
  //   onAction: handleOnAction,
  //   debounce: 500,
  // });

  function SecondaryHeaderContent() {
    if (allCompleted) {
      return (
        <Box display="flex" flexDirection="row" whiteSpace="nowrap">
          <Typography
            variant="body2"
            style={{ fontWeight: 700, marginRight: "4px" }}
          >
            All requests completed and sent.
          </Typography>

          {!isMobile && (
            <>
              <Typography variant="body2">
                If you need to delete one of your responses contact
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: Colors.Link,
                  fontWeight: 700,
                  marginLeft: "4px",
                }}
              >
                Contrax support for assistance.
              </Typography>
            </>
          )}
        </Box>
      );
    }

    if (outstandingRequestsCount === 0 && outstandingEnquiriesCount === 0) {
      return (
        <Typography
          variant="body2"
          style={{ fontWeight: 700, color: Colors.UISuccess }}
        >
          No requests outstanding. You’re now free to send your responses to the
          buyer team.
        </Typography>
      );
    }

    return (
      <Typography variant="body2">
        {outstandingRequestsCount + outstandingEnquiriesCount} requests
        outstanding. Complete all before you can send your responses to the
        buyer team.
      </Typography>
    );
  }

  function SecondaryHeader() {
    const isMobile = useMobile();

    return (
      <StickyHeader
        height="52px"
        bgcolor="white"
        display="flex"
        flexDirection="row"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        boxShadow="0px 1px 0px #E0E0E0"
        top={72}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          height="100%"
          pl="24px"
        >
          <SecondaryHeaderContent />
        </Box>
      </StickyHeader>
    );
  }

  const requestsForName = !isNil(contract?.primary_buyer)
    ? `${contract?.primary_buyer.first_name} ${contract?.primary_buyer.last_name}`
    : "...";

  return (
    <>
      <AuthenticatedWrapper>
        <AlertWrapper>
          <Box
            minHeight="100vh"
            display="flex"
            flexDirection="column"
            bgcolor={Colors.BrandBackground3}
            pb={10}
          >
            <LegalReviewBlueHeader
              title={`Respond to requests for ${requestsForName}`}
              onViewContractClicked={() => {
                window.open(
                  `/app/sale-contract?contractId=${contractId}`,
                  "_blank"
                );
              }}
              onDoneClicked={() => {
                save({ savingClosing: true });
              }}
            />
            <SecondaryHeader />

            <Box display="flex" alignItems="center" flexDirection="column">
              {/* Requests */}
              <PageSection
                title="AMENDMENT REQUESTS"
                width={isMobile ? "95vw" : "1097px"}
              />
              {requests.map((request, index) => (
                <RequestItem
                  actionsEnabled={!allCompleted}
                  request={request}
                  index={index}
                  onMessageChanged={(message) => {
                    setRequests(
                      produce((draft) => {
                        draft[index].seller_message = message;
                      })
                    );

                    setHasNewEdits(true);
                  }}
                  onAlternativeChanged={(alternative) => {
                    setRequests(
                      produce((draft) => {
                        draft[index].alternate_change = alternative;
                      })
                    );

                    setHasNewEdits(true);
                  }}
                  onStage={(stage) => {
                    setRequests(
                      produce((draft) => {
                        draft[index].request_status = stage;
                        draft[index].seller_responded = true;
                        draft[index].p_updated = true;
                      })
                    );

                    setHasNewEdits(true);
                  }}
                  onEdit={() => {
                    setRequests(
                      produce((draft) => {
                        draft[index].status = LegalReviewRequestStatus.editing;
                        draft[index].seller_responded = false;
                        draft[index].p_updated = true;
                      })
                    );

                    setHasNewEdits(true);
                  }}
                  onSave={() => {
                    setRequests(
                      produce((draft) => {
                        draft[index].status =
                          LegalReviewRequestStatus.published;
                        draft[index].seller_responded = true;
                        draft[index].p_updated = true;
                      })
                    );

                    setHasNewEdits(true);
                  }}
                  onViewComments={() => {
                    setRequestForCommentsDialog(request);
                    setRequestCommentsDialogOpen(true);
                  }}
                />
              ))}

              {/* Enquiries */}
              <PageSection
                title="GENERAL ENQUIRIES"
                width={isMobile ? "95vw" : "1097px"}
              />
              {enquiries.map((enquiry, index) => (
                <EnquiryItem
                  actionsEnabled={!allCompleted}
                  index={index}
                  enquiry={enquiry}
                  onViewComments={() => {
                    setEnquiryForCommentsDialog(enquiry);
                    setEnquiryCommentsDialogOpen(true);
                  }}
                  onAnswerChanged={(answer) => {
                    setEnquiries(
                      produce((draft) => {
                        draft[index].answer_to_enquiry = answer;
                        draft[index].p_updated = true;
                      })
                    );

                    setHasNewEdits(true);
                  }}
                  onEdit={() => {
                    setEnquiries(
                      produce((draft) => {
                        draft[index].status = EnquiryStatus.Editing;
                        draft[index].enquiry_status = EnquiryStage.Pending;
                        draft[index].seller_responded = false;
                        draft[index].p_updated = true;
                      })
                    );

                    setHasNewEdits(true);
                  }}
                  onSave={(answer) => {
                    setEnquiries(
                      produce((draft) => {
                        if (!isEmpty(answer)) {
                          draft[index].answer_to_enquiry = answer;
                          draft[index].status = EnquiryStatus.Published;
                          draft[index].enquiry_status = EnquiryStage.Answered;
                          draft[index].seller_responded = true;
                        } else {
                          draft[index].enquiry_status = EnquiryStage.Pending;
                          setErrorMessage(
                            "Please enter your answer for this enquiry"
                          );
                        }

                        draft[index].p_updated = true;
                      })
                    );

                    setHasNewEdits(true);
                  }}
                />
              ))}
            </Box>
          </Box>
        </AlertWrapper>
      </AuthenticatedWrapper>

      <CommentsDialog
        type={CommentsDialogType.request}
        journey={CommentsDialogJourney.Request}
        contractId={contractId}
        recordId={recordId}
        request={requestForCommentsDialog}
        open={requestCommentsDialogOpen}
        onClose={() => {
          setRequestCommentsDialogOpen(false);
        }}
        onRequestCommentsCountUpdated={onRequestCommentsCountUpdated}
        onRequestMessagesCountUpdated={onRequestMessagesCountUpdated}
      />

      <CommentsDialog
        type={CommentsDialogType.enquiry}
        journey={CommentsDialogJourney.Request}
        contractId={contractId}
        recordId={recordId}
        enquiry={enquiryForCommentsDialog}
        open={enquiryCommentsDialogOpen}
        onClose={() => {
          setEnquiryCommentsDialogOpen(false);
        }}
        onEnquiryCommentsCountUpdated={onEnquiryCommentsCountUpdated}
        onEnquiryMessagesCountUpdated={onEnquiryMessagesCountUpdated}
      />
    </>
  );
}
