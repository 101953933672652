import React from "react";
import { Box, Dialog, Typography } from "@material-ui/core";
import ProceedDialogSuccessIcon from "../../images/proceed-dialog-success.svg";
import { useMobile } from "../../hooks/mobile";
import Separator from "../common/Separator";
import Button, { ButtonType, ButtonSize } from "../common/Button";

interface Props {
  open: boolean;
  onClose: () => unknown;
}

export default function ProceedSuccessDialog({ open, onClose }: Props) {
  const isMobile = useMobile();

  return (
    <Dialog open={open}>
      <Box
        maxWidth={isMobile ? "100%" : "514px"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Box
          mt="103px"
          mb="139px"
          pl="118px"
          pr="118px"
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <img src={ProceedDialogSuccessIcon} />
          <Typography
            variant="h6"
            style={{ marginTop: "20px", textAlign: "center" }}
          >
            You've sent your message to your lawyer or conveyancer.
          </Typography>
        </Box>
        <Box width="100%">
          <Separator />
          <Box p="16px">
            <Button
              title="CLOSE"
              type={ButtonType.primary}
              size={ButtonSize.medium}
              onClick={onClose}
              width="100%"
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
