import React from "react";
import { Box } from "@material-ui/core";
import Wrapper from "../../components/UserAccount/Wrapper";
import Dropdown from "../../components/common/Dropdown";
import { useMobile } from "../../hooks/mobile";
import InvoiceItem from "../../components/UserAccount/InvoiceItem";
import useSWR from "swr";
import { Order, OrderStatus } from "../../api/models";
import moment from "moment";

const options = [
  {
    title: "Most recent",
    value: "most_recent",
  },
];

export default function Invoices() {
  const isMobile = useMobile();

  const [sort, setSort] = React.useState("most_recent");

  const { data } = useSWR("/orders/");

  const sorter = (first: Order, second: Order) => {
    if (sort === "most_recent") {
      return moment(second.created_at).isAfter(moment(first.created_at))
        ? 1
        : -1;
    }
    return 0;
  };

  const orders = data
    ? (data as Order[])
        .filter((o) => o.status === OrderStatus.Successful)
        .sort(sorter)
    : [];

  return (
    <Wrapper title="Invoices">
      <Box
        display="flex"
        flexDirection={"column"}
        pt={isMobile ? undefined : "55px"}
        pb="55px"
      >
        <Dropdown
          width={isMobile ? undefined : "280px"}
          title="Sort by:"
          options={options}
          selectedOption={options.find((option) => option.value === sort)}
          onSelectedOption={(option) => setSort(option.value)}
        />

        <Box mt="24px">
          {orders.map((order) => (
            <InvoiceItem order={order} />
          ))}
        </Box>
      </Box>
    </Wrapper>
  );
}
