import { Box, Dialog, Typography } from "@material-ui/core";
import React from "react";
import api from "../../api/instance";
import { useAlert } from "../../contexts/Alert";
import { parseApiError } from "../../helpers/error";
import { useMobile } from "../../hooks/mobile";
import Button, { ButtonSize, ButtonType } from "../common/Button";
import Separator from "../common/Separator";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import {
  Contract,
  RecordStatus,
  addressForProperty,
  Property,
} from "../../api/models";

// export enum AddMyTeamMemberModal {
//   default, // Requires both contract and record
//   sellerTeam,
//   agents,
// }

interface Props {
  // type?: AddMyTeamMemberModal;
  open: boolean;
  onClose: () => unknown;
  onConfirmation: () => unknown;
  property?: Property | null;
}

export default function JoinWorkspaceModal({
  open,
  onClose,
  onConfirmation,
  property,
}: Props) {
  const isMobile = useMobile();
  const location = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(location.search);
  const contractId = query.get("contractId");
  const recordId = query.get("recordId");

  const { setErrorMessage } = useAlert();

  const [inviting, setInviting] = React.useState(false);

  const { data: contractData } = useSWR(
    `/view-contracts/${contractId}?recordId=${recordId}`
  );
  const contract = contractData as Contract;

  return (
    <Dialog
      open={open}
      aria-labelledby="invite-dialog"
      aria-describedby="invite-dialog-description"
      onClose={onClose}
      fullScreen={isMobile}
    >
      <Box
        width={isMobile ? "100%" : "524px"}
        display="flex"
        flexDirection="column"
      >
        <Box
          height="62px"
          pl="24px"
          alignItems="center"
          display="flex"
          flexDirection="row"
        >
          <Typography variant="h5" style={{ fontWeight: 700 }}>
            Join workspace
          </Typography>
        </Box>
        <Separator />

        <Box p="24px">
          <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
            <Typography variant="body1">
              You’re about to join <b>{contract?.title}</b> in the workspace for{" "}
              <b>{property && addressForProperty(property)}</b>. Click below to
              continue.
            </Typography>
          </Box>
        </Box>
        <Separator />
        <Box
          display="flex"
          justifyContent="flex-end"
          pl="24px"
          pr="16px"
          height="74px"
          alignItems="center"
        >
          <Button
            title="CANCEL"
            size={ButtonSize.medium}
            type={ButtonType.secondary}
            onClick={onClose}
            width="114px"
          />
          <Box width="8px" />
          <Button
            loading={inviting}
            title="CONFIRM"
            size={ButtonSize.medium}
            type={ButtonType.primary}
            onClick={() => {
              setInviting(true);

              api
                .post(
                  `/contract/${contractId}/record/${recordId}/join-workspace/`
                )
                .then((response) => {
                  setInviting(false);

                  onConfirmation();

                  if (
                    contract.amendment_request_status ===
                      RecordStatus.InProgress ||
                    contract.amendment_request_status === RecordStatus.Completed
                  ) {
                    history.push(
                      `/request-workspace?contractId=${contractId}&recordId=${recordId}`
                    );
                  } else if (
                    contract.legal_review_status === RecordStatus.InProgress ||
                    contract.legal_review_status === RecordStatus.Completed
                  ) {
                    history.push(
                      `/legal-review-viewer?contractId=${contractId}&recordId=${recordId}`
                    );
                  } else if (
                    contract.contract_status === RecordStatus.InProgress ||
                    contract.contract_status === RecordStatus.Completed
                  ) {
                    history.push(
                      `/legal-review-viewer?contractId=${contractId}&recordId=${recordId}`
                    );
                  }
                })
                .catch((error) => {
                  setInviting(false);
                  setErrorMessage(parseApiError(error));
                });
            }}
            width="118px"
          />
        </Box>
      </Box>
    </Dialog>
  );
}
