import React from "react";
import { Box, Typography, Link } from "@material-ui/core";
import useSWR from "swr";
import { useLocation, useHistory } from "react-router-dom";
import HeroLayout from "../components/HeroLayout";
import ConfirmationIcon from "../images/Confirmation.png";
import { Colors } from "../constants/colors";
import { useMobile } from "../hooks/mobile";
import { Property, addressForProperty } from "../api/models";

export default function ContractUploadSuccess(): JSX.Element {
  const location = useLocation();
  // const history = useHistory();
  const isMobile = useMobile();

  const query = new URLSearchParams(location.search);
  const propertyId = query.get("propertyId");
  // const mainContactEmail = query.get("email");

  const { data: propertyData } = useSWR(`/view-properties/${propertyId}/`);
  const property = propertyData && (propertyData as Property);

  const propertyAddress = property && addressForProperty(property);

  return (
    <HeroLayout>
      <Box
        mt={isMobile ? 5 : 25}
        ml={isMobile ? 3 : 22}
        mr={isMobile ? 3 : 22}
        mb={15}
      >
        <Box mb={4}>
          <img src={ConfirmationIcon} />
        </Box>
        <Typography
          variant={isMobile ? "h4" : "h3"}
          style={{ fontWeight: 700, marginBottom: "24px" }}
        >
          Contract of sale successfully uploaded
        </Typography>
        <Typography variant="body1">
          You have successfully uploaded the contract of sale for{" "}
          {propertyAddress}.
        </Typography>
        <br />
        <Typography variant="body1">What happens next?</Typography>
        <Typography variant="body1">
          - Contrax will now review the contract of sale (this can take up to 2
          business days).
        </Typography>

        <Typography variant="body1">
          - Once finalised we will send a notification and a shareable link of
          our legal review to you and anyone else you have assigned to this
          contract of sale. This link can be shared with buyers and their legal
          advisers to purchase and view.
        </Typography>

        <br />

        <Typography variant="body1">
          In order to provide an accurate legal review, it is vital that we have
          the most up to date version of the contract. Let us know if there are
          any changes by emailing{" "}
          <Link
            href="mailto:contract@contrax.com.au"
            style={{ fontWeight: 700 }}
          >
            contract@contrax.com.au
          </Link>
          .
        </Typography>
      </Box>
    </HeroLayout>
  );
}
