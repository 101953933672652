import { Box, ButtonBase, Drawer, Typography } from '@material-ui/core';
import Button, { ButtonSize, ButtonType } from '../common/Button';
import { User, UserType } from '../../api/models';
import { useHistory, useLocation } from 'react-router-dom';
import { useSessionStore, userCacheKey } from '../../stores/Session';

import { Colors } from '../../constants/colors';
import React from 'react';
import SideMenuClose from '../../images/side-menu-close.png';

interface Props {
  open: boolean;
  onClose: () => unknown;
}

const buttonStyle = {
  height: '40px',
};

export default function MobileSideMenu({ open, onClose }: Props): JSX.Element {
  const history = useHistory();
  const location = useLocation();

  const { refresh: refreshUserData, data: userData } = useSessionStore();
  const user = userData && (userData as User);

  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <Box width='286px'>
        <Box display='flex' justifyContent='flex-end'>
          <ButtonBase onClick={() => onClose()}>
            <Box
              bgcolor={Colors.Link}
              width='40px'
              height='40px'
              borderRadius='20px'
              marginRight='16px'
              marginTop='16px'
              alignItems='center'
              justifyContent='center'
              display='flex'
            >
              <img src={SideMenuClose} />
            </Box>
          </ButtonBase>
        </Box>
        <Box display='flex' flexDirection='column' mt={2.5} pl={2} pr={2}>
          {user && user.user_type === UserType.Agent && (
            <React.Fragment>
              <ButtonBase
                style={buttonStyle}
                onClick={() => history.push('/dashboard')}
              >
                <Box textAlign='left' display='flex' flex={1}>
                  <Typography variant='body1'>Dashboard</Typography>
                </Box>
              </ButtonBase>
              <ButtonBase
                style={buttonStyle}
                onClick={() => history.push('/agent/add-property')}
              >
                <Box textAlign='left' display='flex' flex={1}>
                  <Typography variant='body1'>Upload a contract</Typography>
                </Box>
              </ButtonBase>
              <ButtonBase
                style={buttonStyle}
                onClick={() => history.push('/my-properties')}
              >
                <Box textAlign='left' display='flex' flex={1}>
                  <Typography variant='body1'>My properties</Typography>
                </Box>
              </ButtonBase>
              <ButtonBase
                style={buttonStyle}
                onClick={() => history.push('/account-details')}
              >
                <Box textAlign='left' display='flex' flex={1}>
                  <Typography variant='body1'>Account details</Typography>
                </Box>
              </ButtonBase>
            </React.Fragment>
          )}
          {user &&
            (user.user_type === UserType.Buyer ||
              user.user_type === UserType.Solicitor) && (
              <React.Fragment>
                <ButtonBase
                  style={buttonStyle}
                  onClick={() => history.push('/dashboard')}
                >
                  <Box textAlign='left' display='flex' flex={1}>
                    <Typography variant='body1'>Dashboard</Typography>
                  </Box>
                </ButtonBase>

                <ButtonBase
                  style={buttonStyle}
                  onClick={() => history.push('/my-properties')}
                >
                  <Box textAlign='left' display='flex' flex={1}>
                    <Typography variant='body1'>My properties</Typography>
                  </Box>
                </ButtonBase>
                <ButtonBase
                  style={buttonStyle}
                  onClick={() => history.push('/my-invoices')}
                >
                  <Box textAlign='left' display='flex' flex={1}>
                    <Typography variant='body1'>My invoices</Typography>
                  </Box>
                </ButtonBase>
                <ButtonBase
                  style={buttonStyle}
                  onClick={() => history.push('/account-details')}
                >
                  <Box textAlign='left' display='flex' flex={1}>
                    <Typography variant='body1'>Account details</Typography>
                  </Box>
                </ButtonBase>
              </React.Fragment>
            )}
          {!user && (
            <React.Fragment>
              <ButtonBase
                style={buttonStyle}
                onClick={() =>
                  window.open(window.location.origin + '/buyers', '_self')
                }
              >
                <Box textAlign='left' display='flex' flex={1}>
                  <Typography variant='body1'>Buyers</Typography>
                </Box>
              </ButtonBase>

              <ButtonBase
                style={buttonStyle}
                onClick={() =>
                  window.open(window.location.origin + '/agents', '_self')
                }
              >
                <Box textAlign='left' display='flex' flex={1}>
                  <Typography variant='body1'>Agents</Typography>
                </Box>
              </ButtonBase>
              <ButtonBase
                style={buttonStyle}
                onClick={() =>
                  window.open(window.location.origin + '/lawyer', '_self')
                }
              >
                <Box textAlign='left' display='flex' flex={1}>
                  <Typography variant='body1'>
                    Lawyers & Conveyancers
                  </Typography>
                </Box>
              </ButtonBase>
              <ButtonBase
                style={buttonStyle}
                onClick={() =>
                  window.open(window.location.origin + '/blog', '_self')
                }
              >
                <Box textAlign='left' display='flex' flex={1}>
                  <Typography variant='body1'>Blog</Typography>
                </Box>
              </ButtonBase>
              <Button
              type={ButtonType.light}
              size={ButtonSize.medium}
              title='Talk to an expert'
              onClick={() =>                   
                window.open(window.location.origin + '/contact-us', '_self')
              }
            />
              <Box mt={2} />
              <Button
                type={ButtonType.primary}
                size={ButtonSize.medium}
                title='LOGIN'
                onClick={() => {
                  history.push('/login', {
                    next: `${location.pathname}${location.search}`,
                  });
                }}
              />
            </React.Fragment>
          )}
        </Box>
        {user && (
          <Box
            position='absolute'
            bottom={0}
            left={0}
            right={0}
            mt={2.5}
            pl={2}
            pr={2}
          >
            <ButtonBase
              style={buttonStyle}
              onClick={() => {
                localStorage.removeItem('token');
                localStorage.removeItem(userCacheKey);

                history.push('/login');
              }}
            >
              <Box textAlign='left' display='flex' flex={1}>
                <Typography variant='body1'>Logout</Typography>
              </Box>
            </ButtonBase>
          </Box>
        )}
      </Box>
    </Drawer>
  );
}
