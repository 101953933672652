import { Box, BoxProps } from "@material-ui/core";
import React from "react";

interface Props extends BoxProps {
  top?: number;
  height: string | number;
}

export default function ({ height, children, top = 0, ...props }: Props) {
  return (
    <Box position="relative">
      {/* Phantom view for margin purposes */}
      <Box height={height} width="100%" />
      <Box
        {...props}
        height={height}
        zIndex={999}
        position="fixed"
        left={0}
        right={0}
        top={top}
      >
        {children}
      </Box>
    </Box>
  );
}
