import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useMobile, useDesktop } from "../hooks/mobile";
import { useHistory, useLocation } from "react-router-dom";
import PropertyLayoutDesktop from "../components/PropertyLayoutDesktop";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import Button, { ButtonType, ButtonSize } from "../components/common/Button";
import api from "../api/instance";
import { useAlert } from "../contexts/Alert";
import AlertWrapper from "../components/AlertWrapper";
import AuthenticatedWrapper from "../components/AuthenticatedWrapper";
import { AxiosError } from "axios";

export default function RequestLawyer(): JSX.Element {
  const history = useHistory();
  const location = useLocation();
  const isMobile = useMobile();
  const isDesktop = useDesktop();

  const query = new URLSearchParams(location.search);
  const propertyId = query.get("propertyId");

  const [loading, setLoading] = React.useState(false);

  const { setErrorMessage } = useAlert();

  function Content() {
    return (
      <Box>
        <Typography variant="h5">
          We'd love to be your lawyer and help you buy this property
        </Typography>
        <Box mt={4} />
        <Typography variant="body1">
          Our conveyancing fees are $800 (plus GST, disbursements and any
          further legal reviews). We'll only charge these fees when your
          property settles.
        </Typography>
        <Box mt={2} />
        <Typography variant="body1">
          You'll be assigned a lawyer from our team, and we'll take care of
          everything. We'll guide you every step of the way, from contract
          negotiation through to settlement, when the property finally becomes
          yours.
        </Typography>
        <Box mt={2} />
        <Typography variant="body1">
          Click 'get in touch' below to begin an initial confidential
          conversation, and we'll reach out directly using your account details.
        </Typography>
      </Box>
    );
  }

  function requestLawyerClicked() {
    setLoading(true);

    api
      .get(`/request-lawyer/?property_id=${propertyId}`)
      .then((response) => {
        history.push(`/request-lawyer-success?propertyId=${propertyId}`);
      })
      .catch((error: AxiosError) => {
        setLoading(false);

        setErrorMessage(
          (error.response && error.response?.data) || error.message
        );
      });
  }

  if (!propertyId) {
    return <Box />;
  }

  return isDesktop ? (
    <AuthenticatedWrapper>
      <AlertWrapper>
        <PropertyLayoutDesktop propertyId={+propertyId}>
          <Box mt={5} mb={5}>
            <Content />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Button
              type={ButtonType.secondary}
              size={ButtonSize.medium}
              title="NO THANKS"
              width="143px"
              onClick={() => history.goBack()}
            />
            <Button
              type={ButtonType.primary}
              size={ButtonSize.medium}
              title="GET IN TOUCH"
              width="158px"
              loading={loading}
              onClick={() => requestLawyerClicked()}
            />
          </Box>
        </PropertyLayoutDesktop>
      </AlertWrapper>
    </AuthenticatedWrapper>
  ) : (
    <AuthenticatedWrapper>
      <AlertWrapper>
        <Box m={3}>
          <Content />
          <MobileStickyFooter
            buttonsLayout={FooterButtonsLayout.sideBySide}
            leftButtonTitle="NO THANKS"
            leftButtonOnClick={() => history.goBack()}
            rightButtonTitle="GET IN TOUCH"
            rightButtonLoading={loading}
            rightButtonOnClick={() => requestLawyerClicked()}
          />
        </Box>
      </AlertWrapper>
    </AuthenticatedWrapper>
  );
}
