import create from "zustand";
import api from "../api/instance";
import { Organisation, User } from "../api/models";

interface State {
  refreshCredits: () => Promise<number>;
  availableCredits: number;
}

export const useCreditStore = create<State>((set, get) => ({
  refreshCredits: async () => {
    return new Promise((resolve, reject) => {
      api.get("/me/").then((response) => {
        const user = response.data as User;

        if (user?.current_organization && user?.current_organization.uuid) {
          api
            .get(`/organization/${user?.current_organization.uuid}/`)
            .then((response) => {
              const organisation = response.data as Organisation;

              const { available_credits } = organisation;

              set({
                availableCredits: available_credits,
              });

              resolve(available_credits);
            });
        } else {
          const credits = user?.available_credits || 0;
          set({
            availableCredits: credits,
          });

          resolve(credits);
        }
      });
    });
  },
  availableCredits: 0,
}));
