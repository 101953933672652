import React from "react";
import { Box, Typography, Button as MUIButton } from "@material-ui/core";
import produce from "immer";
import api from "../api/instance";
import { isEmpty, isNil, omit } from "lodash-es";
import { useHistory, useLocation } from "react-router-dom";
import { useMobile } from "../hooks/mobile";
import AlertWrapper from "../components/AlertWrapper";
import HeroLayout from "../components/HeroLayout";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import { Colors } from "../constants/colors";
import Button, { ButtonType, ButtonSize } from "../components/common/Button";
import { useAlert } from "../contexts/Alert";
import Checkbox from "../components/common/Checkbox";
import Separator from "../components/common/Separator";
import TextField from "../components/common/TextField";
import { AxiosError } from "axios";
import { useSessionStore } from "../stores/Session";
import { User } from "../api/models";
import AuthenticatedWrapper from "../components/AuthenticatedWrapper";
import CloseIcon from "../images/x.png";

interface ContactDetails {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

const maximumContacts = 3;

interface ContactDetailsFormProps {
  isMobile: boolean;
  title: string;
  details: ContactDetails;
  onFirstNameChange: (firstName: string) => unknown;
  onLastNameChange: (lastName: string) => unknown;
  onEmailChange: (email: string) => unknown;
  onPhoneChange: (phone: string) => unknown;
  errorIfEmpty: boolean;
  onCloseClicked?: (() => unknown) | null;
}

function ContactDetailsForm({
  isMobile,
  title,
  details,
  onFirstNameChange,
  onLastNameChange,
  onEmailChange,
  onPhoneChange,
  errorIfEmpty,
  onCloseClicked,
}: ContactDetailsFormProps) {
  const firstNameError = errorIfEmpty && isEmpty(details.firstName);
  const lastNameError = errorIfEmpty && isEmpty(details.lastName);
  const emailError = errorIfEmpty && isEmpty(details.email);
  const phoneError = errorIfEmpty && isEmpty(details.phone);

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb={-1}
      >
        <Typography variant="overline">{title}</Typography>
        {onCloseClicked && (
          <MUIButton onClick={() => onCloseClicked()}>
            <img src={CloseIcon} />
          </MUIButton>
        )}
      </Box>

      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        maxWidth="768px"
      >
        <Box mt={2} width={isMobile ? undefined : "353px"}>
          <TextField
            title="First name"
            value={details.firstName}
            error={firstNameError}
            helperText={
              firstNameError ? "Please complete First name" : undefined
            }
            onChange={(value) => onFirstNameChange(value)}
          />
          <Box mt={1} />
          <TextField
            title="Last name"
            value={details.lastName}
            error={lastNameError}
            helperText={lastNameError ? "Please complete Last name" : undefined}
            onChange={(value) => onLastNameChange(value)}
          />
        </Box>
        <Box
          mt={isMobile ? 0 : 2}
          width={isMobile ? undefined : "353px"}
          ml={isMobile ? 0 : 2}
        >
          <TextField
            type="email"
            title="Email"
            error={emailError}
            helperText={emailError ? "Please complete Email" : undefined}
            value={details.email}
            onChange={(value) => onEmailChange(value)}
          />
          <Box mt={1} />
          <TextField
            type="phone"
            title="Phone number"
            error={phoneError}
            helperText={phoneError ? "Please complete Phone number" : undefined}
            value={details.phone}
            onChange={(value) => onPhoneChange(value)}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default function MainContacts(): JSX.Element {
  const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const contractId = query.get("contractId");
  const propertyId = query.get("propertyId");

  const [displayMyDetails, setDisplayMyDetails] = React.useState(true);
  const [contactDetails, setContactDetails] = React.useState<
    Array<ContactDetails>
  >([]);
  const [showEmptyFieldErrors, setShowEmptyFieldErrors] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const isMobile = useMobile();

  const { setErrorMessage } = useAlert();

  const { refresh: refreshUserData, data: userData } = useSessionStore();
  const user = userData && (userData as User);

  React.useEffect(() => {
    refreshUserData();
  }, []);

  function Header() {
    return (
      <Box
        width={isMobile ? undefined : "700px"}
        mt={isMobile ? 3 : 22}
        mb={1.5}
      >
        <Typography variant="overline">STEP 3 OF 3</Typography>
        <Typography
          variant={isMobile ? "h5" : "h4"}
          style={{ marginTop: "8px" }}
        >
          Are you the main contact agent for this property?
        </Typography>
      </Box>
    );
  }

  function OtherStaffHeader() {
    if (displayMyDetails) {
      return (
        <React.Fragment>
          <Typography variant="h6" style={{ fontWeight: 700 }}>
            Add other staff
          </Typography>
          <Typography variant={isMobile ? "body2" : "body1"}>
            Does anyone else need to receive notifications about this property?
          </Typography>
          <br />
          <Typography variant={isMobile ? "body2" : "body1"}>
            If so, please select ‘Add Other Staff’, and we will send a
            notification to them when the legal review is ready. The contract
            and legal review will also appear in their Contrax account.
          </Typography>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Typography variant="h6" style={{ fontWeight: 700 }}>
            Add contact agent & other staff
          </Typography>
          <Typography variant={isMobile ? "body2" : "body1"}>
            The contact agent will appear on the overview of the property for
            buyers, lawyers and conveyancers to see.
          </Typography>
          <br />
          <Typography variant={isMobile ? "body2" : "body1"}>
            You can add other staff members to this property so that they
            receive notifications when the legal review is ready, and the
            property listing will appear in their Contrax account.
          </Typography>
        </React.Fragment>
      );
    }
  }

  function nextClicked() {
    setShowEmptyFieldErrors(false);

    // Check for empty fields
    const checkForEmptyFields =
      (displayMyDetails && !isEmpty(contactDetails)) || !displayMyDetails;
    const emptyFields =
      checkForEmptyFields &&
      contactDetails.some(
        (detail) =>
          isEmpty(detail.firstName) ||
          isEmpty(detail.lastName) ||
          isEmpty(detail.phone) ||
          isEmpty(detail.email)
      );

    if (emptyFields) {
      setShowEmptyFieldErrors(true);
      return;
    }

    const contacts = contactDetails.map((detail) => {
      let agencyData;

      if (user?.agency_id) {
        agencyData = {
          agency_id: user.agency_id,
        };
      } else if (user?.agency_name) {
        agencyData = {
          agency_name: user.agency_name,
        };
      }

      return {
        first_name: detail.firstName,
        last_name: detail.lastName,
        email: detail.email,
        phone: detail.phone,
        role: "Contact role",
        ...agencyData,
      };
    });

    const data = displayMyDetails
      ? {
          contacts,
        }
      : {
          agent: omit(contacts[0], ["role"]),
          contacts: contacts.length > 1 ? contacts.slice(1) : [],
        };

    console.debug("data: ", data);

    const mainContactEmail = displayMyDetails ? user?.email : contacts[0].email;

    setLoading(true);

    api
      .post(`/contracts/${contractId}/contacts/`, data)
      .then((response) => {
        console.debug("response: ", response);
        history.push(`/my-property?propertyId=${propertyId}`);
      })
      .catch((error: AxiosError) => {
        setLoading(false);

        setErrorMessage(error.message);
      });
  }

  return (
    <AlertWrapper>
      <AuthenticatedWrapper>
        <HeroLayout>
          <Box
            marginLeft="auto"
            marginRight="auto"
            maxWidth="768px"
            pl={3.25}
            pr={3.25}
            pb={8}
          >
            <Header />
            <Typography variant={isMobile ? "body2" : "body1"}>
              We are asking this question, so we have a primary contact person,
              and we will also feature these details on the Contrax property
              listing.
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              mt={1.5}
            >
              <Checkbox
                style={{ color: Colors.Grey2 }}
                checked={displayMyDetails}
                onChange={(event) => {
                  setDisplayMyDetails(event.target.checked);
                }}
              />
              <Typography variant="body2">
                Yes, display my details on Contrax as the contact agent for this
                property
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Checkbox
                style={{ color: Colors.Grey2 }}
                checked={!displayMyDetails}
                onChange={(event) => {
                  setDisplayMyDetails(!event.target.checked);

                  if (isEmpty(contactDetails)) {
                    setContactDetails([{} as ContactDetails]);
                  }
                }}
              />
              <Typography variant="body2">
                No, don't display my details. Display the agent added below
              </Typography>
            </Box>
            <Separator
              mt={isMobile ? 2 : 4.25}
              mb={isMobile ? "18px" : "24px"}
            />

            <OtherStaffHeader />

            {contactDetails.map((detail, index) => {
              let title;

              if (displayMyDetails) {
                title = `Other staff ${index + 1}`;
              } else {
                title = index === 0 ? "Contact Agent" : `Other staff ${index}`;
              }

              const hideCloseButton =
                !displayMyDetails && contactDetails.length === 1;

              return (
                <Box mt={4}>
                  <ContactDetailsForm
                    isMobile={isMobile}
                    title={title}
                    details={detail}
                    onFirstNameChange={(firstName) => {
                      const nextState = produce(contactDetails, (draft) => {
                        draft[index].firstName = firstName;
                      });

                      setContactDetails(nextState);
                    }}
                    onLastNameChange={(lastName) => {
                      const nextState = produce(contactDetails, (draft) => {
                        draft[index].lastName = lastName;
                      });

                      setContactDetails(nextState);
                    }}
                    onEmailChange={(email) => {
                      const nextState = produce(contactDetails, (draft) => {
                        draft[index].email = email;
                      });

                      setContactDetails(nextState);
                    }}
                    onPhoneChange={(phone) => {
                      const nextState = produce(contactDetails, (draft) => {
                        draft[index].phone = phone;
                      });

                      setContactDetails(nextState);
                    }}
                    errorIfEmpty={showEmptyFieldErrors}
                    onCloseClicked={
                      hideCloseButton
                        ? undefined
                        : () => {
                            const nextState = produce(
                              contactDetails,
                              (draft) => {
                                draft.splice(index, 1);
                              }
                            );

                            setContactDetails(nextState);
                          }
                    }
                  />
                  <Separator mt={3} mb={3} />
                </Box>
              );
            })}

            {isEmpty(contactDetails) && <Box mt={3} />}

            {contactDetails.length < maximumContacts && (
              <Button
                type={ButtonType.primary}
                size={ButtonSize.medium}
                title="ADD OTHER STAFF"
                width="188px"
                onClick={() => {
                  const nextState = produce(contactDetails, (draft) => {
                    draft.push({} as ContactDetails);
                  });

                  setContactDetails(nextState);
                }}
              />
            )}
            {!isMobile && (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                mt={5}
                mb={10}
              >
                <Button
                  type={ButtonType.primary}
                  size={ButtonSize.large}
                  title="NEXT"
                  width={isMobile ? "100%" : "137px"}
                  loading={loading}
                  onClick={() => nextClicked()}
                />
              </Box>
            )}
          </Box>
          {isMobile && (
            <MobileStickyFooter
              buttonsLayout={FooterButtonsLayout.sideBySide}
              leftButtonTitle="BACK"
              leftButtonOnClick={() => history.goBack()}
              rightButtonTitle="NEXT"
              rightButtonLoading={loading}
              rightButtonOnClick={() => nextClicked()}
            />
          )}
        </HeroLayout>
      </AuthenticatedWrapper>
    </AlertWrapper>
  );
}
