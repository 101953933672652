import { Box, Dialog, Typography } from "@material-ui/core";
import React from "react";
import Button, { ButtonSize, ButtonType } from "../../components/common/Button";
import Separator from "../../components/common/Separator";
import { useMobile } from "../../hooks/mobile";
import SuccessIcon from "../../images/success-tick.svg";
import CreditsRemainingText from "../CreditsRemainingText";

interface Props {
  open: boolean;
  onCancel: () => unknown;
  onUseCredit: () => unknown;
  loading: boolean;
  paymentSuccessful?: boolean;
}

export default function UseCreditsDialog({
  open,
  onCancel,
  onUseCredit,
  loading,
  paymentSuccessful = false,
}: Props) {
  const isMobile = useMobile();

  return (
    <Box>
      <Dialog
        open={open}
        aria-labelledby="you-have-finalised-dialog"
        aria-describedby="you-have-finalised-description"
        fullScreen={isMobile}
      >
        {!paymentSuccessful && (
          <>
            <Box
              width={isMobile ? "100%" : "524px"}
              display="flex"
              flexDirection="column"
              boxSizing={"border-box"}
              pl="24px"
              pr="24px"
              pt="13px"
              pb="13px"
            >
              <Typography variant="h5">
                Use your credits to purchase this workspace
              </Typography>
            </Box>

            <Separator />
            <Box
              width={isMobile ? "100%" : "524px"}
              display="flex"
              flexDirection="column"
              boxSizing={"border-box"}
              pl="16px"
              pr="16px"
              pt="24px"
              pb="140px"
            >
              <CreditsRemainingText />

              <Typography style={{ marginTop: "22px", textAlign: "left" }}>
                You will be using 1 of your credits to purchase this workspace,
                this credit cannot be refunded, do you want to continue?
              </Typography>
            </Box>
          </>
        )}

        {paymentSuccessful && (
          <>
            <Box
              width={isMobile ? "100%" : "524px"}
              display="flex"
              flexDirection="column"
              alignItems="center"
              boxSizing={"border-box"}
              pl="16px"
              pr="16px"
              pt="40px"
              pb="40px"
            >
              <Typography variant="h5">Payment successful</Typography>

              <img
                src={SuccessIcon}
                width="80px"
                height="80px"
                style={{ marginTop: "40px" }}
              />

              <CreditsRemainingText mt="40px" />

              <Typography style={{ marginTop: "22px", textAlign: "center" }}>
                You will be using 1 of your credits to purchase this workspace,
                this credit cannot be refunded, do you want to continue?
              </Typography>
            </Box>
          </>
        )}

        <Separator />
        <Box
          display="flex"
          justifyContent="flex-end"
          pl="24px"
          pr="16px"
          height="74px"
          alignItems="center"
        >
          <Button
            title="CANCEL"
            size={ButtonSize.medium}
            type={ButtonType.secondary}
            onClick={onCancel}
            width="114px"
          />
          <Box width="8px" />
          <Button
            title="USE CREDIT"
            size={ButtonSize.medium}
            type={ButtonType.primary}
            onClick={onUseCredit}
            width="137px"
            loading={loading}
          />
        </Box>
      </Dialog>
    </Box>
  );
}
