import React from "react";
import { Box, ButtonBase, Typography } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import { useMobile, useDesktop } from "../../hooks/mobile";


export interface SegmentedControlOption {
  value: string;
  title: string;
  icon?: string;
}

interface Props {
  options: Array<SegmentedControlOption>;
  selectedValue?: string | null;
  onSelectedValue?: (value: string) => unknown;
  view?: string;
}

export default function SegmentedControl({
  options,
  selectedValue,
  onSelectedValue,
  view
}: Props): JSX.Element {


  const isDesktop = useDesktop();


  return (
    <Box height="48px" display="flex" flexDirection="row">
      {options.map((option) => (
        <ButtonBase
          style={{ width: isDesktop && view ? `${( option.value.length) * 100}%` : `${(1 / options.length) * 100}%` }}
          onClick={() => onSelectedValue && onSelectedValue(option.value)}
        >
          <Box width="100%" height="48px">
            <Box
              bgcolor={view ? selectedValue === option.value
                ? "white"
                : "rgb(244, 246, 248)" : 
                selectedValue === option.value
                  ? "white"
                  : "rgba(255,255,255,0.4)"
              }
              alignItems="center"
              border={view && 1}
              borderColor={view && "#E8E8E8"}
              justifyContent="center"
              display="flex"
              width="100%"
              height="46px"
            >
              <Typography
                variant="subtitle2"
                style={{ fontWeight: 700, color: Colors.Link }}
              >
              {isDesktop ? <><img src={option.icon}/>  {option.title}</> : option.icon ? <> <img src={option.icon}/>  {option.title === "All" && option.title} </> : option.title}
              </Typography>
            </Box>
            <Box bgcolor={Colors.BrandPrimary} height="2px" />
          </Box>
        </ButtonBase>
      ))}
    </Box>
  );
}
