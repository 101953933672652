import React from "react";
import { Box, Typography } from "@material-ui/core";
import Wrapper from "../../components/UserAccount/Wrapper";
import { useMobile } from "../../hooks/mobile";
import TextField from "../../components/common/TextField";
import Button, { ButtonType } from "../../components/common/Button";
import { useHistory } from "react-router-dom";

export default function EditBilling() {
  const isMobile = useMobile();
  const history = useHistory();

  const [address, setAddress] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [postcode, setPostcode] = React.useState("");
  const [abn, setAbn] = React.useState("");

  return (
    <Wrapper title="Billing details">
      <Box
        display="flex"
        flexDirection="column"
        maxWidth="557px"
        pt={isMobile ? undefined : "55px"}
        pb="55px"
      >
        <Typography
          variant="body1"
          style={{ fontWeight: 700, marginBottom: "16px" }}
        >
          Billing address
        </Typography>

        <TextField
          title="Address"
          value={address}
          onChange={(text) => setAddress(text)}
        />

        <TextField
          title="City"
          value={city}
          onChange={(text) => setCity(text)}
        />
        <Box display="flex" flexDirection={"row"}>
          <TextField
            title="State"
            value={state}
            onChange={(text) => setState(text)}
          />

          <Box width="16px" />

          <TextField
            title="Postcode"
            value={postcode}
            onChange={(text) => setPostcode(text)}
          />
        </Box>

        <TextField title="ABN" value={abn} onChange={(text) => setAbn(text)} />

        <Box
          display="flex"
          flexDirection={"row"}
          justifyContent="space-between"
          mt="30px"
        >
          <Button
            title="BACK"
            type={ButtonType.secondary}
            width="92px"
            onClick={() => history.goBack()}
          />
          <Button
            title="SAVE BILLING DETAILS"
            width="222px"
            onClick={() => {}}
          />
        </Box>
      </Box>
    </Wrapper>
  );
}
