import React from "react";
import {
  Box,
  Typography,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import PropertyLayoutDesktop from "../components/PropertyLayoutDesktop";
import PropertyLayoutMobile from "../components/PropertyLayoutMobile";
import { useHistory, useLocation } from "react-router-dom";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";
import useSWR from "swr";
import {
  Property,
  User,
  UserType,
  Contract,
  ContractWorkflowStatus,
} from "../api/models";
import { useSessionStore } from "../stores/Session";
import { useMobile, useDesktop } from "../hooks/mobile";
import DesktopFooter from "../components/common/DesktopFooter";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import { Colors } from "../constants/colors";
import { useAlert } from "../contexts/Alert";

import ShareLink from "./ShareLink";
import InviteBuyer from "./InviteBuyer";
import MyPropertyRowStats from "../components/MyPropertyRowStats";
import { isEmpty } from "lodash-es";
import SegmentedControl, {
  SegmentedControlOption,
} from "../components/common/SegmentedControl";
import NoProperties from "../images/no-properties.png";
import NoPropertiesStats from "../images/no-properties-stats.png";
import InviteImage from "../images/invite.svg";
import ViewedImage from "../images/view.svg";
import PurchaseImage from "../images/purchase.svg";
import ConfirmationIcon from "../images/Confirmation.png";

export default function MyProperty(): JSX.Element {
  const history = useHistory();
  const location = useLocation();
  const isMobile = useMobile();
  const isDesktop = useDesktop();

  const query = new URLSearchParams(location.search);
  const propertyId = query.get("propertyId");

  const { data: propertyData } = useSWR(`/view-properties/${propertyId}/`);
  const property = propertyData && (propertyData as Property);

  const isSold =
    property && property.status
      ? property.status.toLowerCase() === "sold"
      : true;

  const { data: contractData } = useSWR(
    property && `/view-contracts/${property.contract_id}/`
  );
  const contract = contractData && (contractData as Contract);

  const { data: userData, refresh: refreshUserData } = useSessionStore();
  const user = userData && (userData as User);

  const [selectedValue, setSelectedValue] = React.useState<null | string>(null);

  const [open, setOpen] = React.useState(false);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [openInvite, setOpenInvite] = React.useState(false);
  const [isSend, setIsSend] = React.useState(false);
  const [buyerName, setBuyerName] = React.useState<null | string>(null);

  const [successMsg, setsuccessMsg] = React.useState(false);

  const { setSuccessMessage, setErrorMessage } = useAlert();

  React.useEffect(() => {
    if (user && !selectedValue) {
      setSelectedValue(tabOptions()[0].value);
    }
  }, [user]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
    setsuccessMsg(true);
  };

  const handleInviteOpen = () => {
    setOpenInvite(true);
  };

  const handleInviteClose = () => {
    setOpenInvite(false);
    setIsSend(false);
  };

  React.useEffect(() => {
    refreshUserData();
  }, []);

  if (!propertyId) {
    return <Box />;
  }

  const handleInviteBuyer = () => {
    setIsSend(true);
  };

  function InviteConfirmation() {
    return (
      <Dialog
        open={confirmationOpen}
        maxWidth="sm"
        onClose={handleConfirmationClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <Box display="flex" alignItems="flex-start" width="100%">
            <img
              src={ConfirmationIcon}
              width={isMobile ? "64px" : "120px"}
              height={isMobile ? "64px" : "120px"}
              style={{ marginTop: "22px" }}
              alt="confirmation-icon"
            />
          </Box>
          <Box mt={isMobile ? 1.25 : 3.5} width="100%">
            <Typography
              variant={isMobile ? "h4" : "h3"}
              style={{ fontWeight: 700, marginBottom: "24px" }}
            >
              Thanks {user?.first_name}
            </Typography>
          </Box>
          <Box mt={isMobile ? 1.25 : 4.5}>
            <Typography variant="body1">
              We have send an email to {buyerName} with the link to the
              contract.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            type={ButtonType.primary}
            size={ButtonSize.medium}
            onClick={handleConfirmationClose}
            title="OK"
          />
        </DialogActions>
      </Dialog>
    );
  }

  function ShareLinkDialog() {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Share Contract"}</DialogTitle>
        <DialogContent>
          <ShareLink />
        </DialogContent>
        <DialogActions>
          <Button
            type={ButtonType.primary}
            size={ButtonSize.medium}
            onClick={handleClose}
            title="OK"
          />
        </DialogActions>
      </Dialog>
    );
  }

  function InviteBuyerDialog() {
    return (
      <Dialog
        open={openInvite}
        onClose={handleInviteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h5">Invite Buyer to contract</Typography>
          <Typography variant="subtitle2">
            Enter buyer details and click send to invite to view the contract.
          </Typography>
        </DialogTitle>

        <DialogContent>
          <InviteBuyer
            openConfirmation={setConfirmationOpen}
            name={setBuyerName}
            isSend={isSend}
            resetIsSend={setIsSend}
            property={property}
            onClose={handleInviteClose}
          />
        </DialogContent>
        <DialogActions>
          <Button
            type={ButtonType.secondary}
            size={ButtonSize.medium}
            onClick={handleInviteClose}
            title="CANCEL"
          />
          <Button
            type={ButtonType.primary}
            size={ButtonSize.medium}
            onClick={handleInviteBuyer}
            loading={confirmationOpen}
            title="SEND INVITATION"
          />
        </DialogActions>
      </Dialog>
    );
  }

  function Buttons() {
    if (user && user.user_type === UserType.Agent) {
      return (
        <Box width="305px">
          {contract && isDesktop && (
            <Button
              type={ButtonType.primary}
              size={ButtonSize.large}
              width="100%"
              title="INVITE BUYER"
              onClick={handleInviteOpen}
            />
          )}
          <Box mt={2} />
          {contract && (
            <Button
              type={ButtonType.secondary}
              size={ButtonSize.medium}
              width="100%"
              title="SHARE LINK"
              onClick={handleClickOpen}
            />
          )}
          <Box mt={2} />
          <Button
            type={ButtonType.secondary}
            size={ButtonSize.medium}
            width="100%"
            title="VIEW CONTRACT OF SALE"
            onClick={() => {
              const back = `${location.pathname}${location.search}`;

              history.push(
                `/sale-contract?contractId=${property.contract_id}`,
                {
                  back,
                }
              );
            }}
          />
          <Box mt={2} />
          {!isSold && (
            <React.Fragment>
              <Button
                type={ButtonType.secondary}
                size={ButtonSize.medium}
                width="100%"
                title="UPDATE PROPERTY"
                onClick={() =>
                  history.push(`/update-contract?propertyId=${property.id}`)
                }
              />
              <Box mt={2} />
            </React.Fragment>
          )}
        </Box>
      );
    }

    return <Box />;
  }

  function filteredProperties() {
    if (property && property.property_stats) {
      switch (selectedValue) {
        case "all":
          return property.property_stats.filter(
            (property: any) => property.status.toLowerCase() !== "delete"
          );
        case "viewed":
          return property.property_stats.filter(
            (property: any) => property.status === "Viewed"
          );
        case "invited":
          return property.property_stats.filter(
            (property: any) =>
              property.status === "Invite Sent" ||
              property.status === "Invite Accepted"
          );
        case "purchased":
          return property.property_stats.filter(
            (property: any) =>
              (property.status && property.status === "Purchased") ||
              (property.buyer_status && property.buyer_status === "Purchased")
          );
      }
    } else {
      return undefined;
    }

    return undefined;
  }

  function tabOptions() {
    if (user && user.user_type === UserType.Agent) {
      return [
        {
          value: "all",
          title: "All",
        },
        {
          value: "invited", // published
          title: "Invited",
          icon: InviteImage,
        },
        {
          value: "viewed",
          title: "Viewed Contract",
          icon: ViewedImage,
        },
        {
          value: "purchased",
          title: "Purchased Legal Review",
          icon: PurchaseImage,
        },
      ] as Array<SegmentedControlOption>;
    }

    if (
      user &&
      (user.user_type === UserType.Buyer ||
        user.user_type === UserType.Solicitor)
    ) {
      return [
        {
          value: "all",
          title: "All",
        },
        {
          value: "invited",
          title: "Invited",
        },
        {
          value: "purchased",
          title: "Purchased",
        },
      ] as Array<SegmentedControlOption>;
    }

    return [
      {
        value: "all",
        title: "All",
      },
    ];
  }

  function MySegmentedControl() {
    return (
      <SegmentedControl
        options={tabOptions()}
        selectedValue={selectedValue}
        onSelectedValue={(value) => {
          setSelectedValue(value);
        }}
        view="WithStats"
      />
    );
  }

  function EmptyPlaceholder() {
    return (
      <Box textAlign="center" mt={12}>
        <img src={NoPropertiesStats} height="167px" />
        <Typography variant="subtitle1" style={{ marginTop: "24px" }}>
          {user && user.user_type === UserType.Agent
            ? "No buyers details to show you in this view. Get buyers moving by inviting them directly."
            : "You haven't purchased any legal reviews"}
        </Typography>
        {user && user.user_type === UserType.Agent && (
          <Button
            type={ButtonType.white}
            size={ButtonSize.large}
            width="100%"
            title="Share with Buyer"
            onClick={handleInviteOpen}
          />
        )}
      </Box>
    );
  }

  const filtered = filteredProperties();

  function Content() {
    if (user && user.user_type === UserType.Agent) {
      return (
        <Box>
          <Box p={2} mt={3} mb={3}>
            <Typography variant="h5">Buyer details</Typography>
          </Box>
          {isDesktop ? (
            <Box>
              <MySegmentedControl />
            </Box>
          ) : (
            <Box>
              <MySegmentedControl />
            </Box>
          )}
          <Box
            display="flex"
            borderColor="#E8E8E8"
            border={1}
            p={1}
            flexDirection="column"
            flex={1}
          >
            {filtered && isEmpty(filtered) && <EmptyPlaceholder />}
            {filtered &&
              filtered.map((property) => (
                <MyPropertyRowStats property={property} />
              ))}
          </Box>
        </Box>
      );
    }

    return <Box />;
  }

  // REMINDER: For buyer/lawyer use LegalReviewPurchased.tsx
  return isDesktop ? (
    <PropertyLayoutDesktop
      propertyId={+propertyId}
      status={user && user.user_type}
      detailsChildren={<Buttons />}
      shareLinkDialog={ShareLinkDialog}
      inviteBuyerDialog={InviteBuyerDialog}
      inviteConfirmation={InviteConfirmation}
      backTitle="Back to my properties"
      backOnClick={() => {
        history.push("/my-properties");
      }}
    >
      <Content />
    </PropertyLayoutDesktop>
  ) : (
    <PropertyLayoutMobile
      shareLinkDialog={ShareLinkDialog}
      inviteBuyerDialog={InviteBuyerDialog}
      inviteConfirmation={InviteConfirmation}
      propertyId={+propertyId}
    >
      <Box
        maxWidth="485px"
        marginLeft="auto"
        marginRight="auto"
        marginTop="20px"
      >
        <Box
          pl={3}
          pr={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Buttons />
          <Content />
          <Box mt={8.5} />
        </Box>
      </Box>
      {user && user.user_type === UserType.Agent && (
        <MobileStickyFooter
          buttonsLayout={FooterButtonsLayout.centered}
          buttonTitle="INVITE BUYER"
          buttonOnClick={handleInviteOpen}
        />
      )}
    </PropertyLayoutMobile>
  );
}
