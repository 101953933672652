import React from "react";
import Snackbar, { SnackbarStyle } from "./common/Snackbar";
import { useAlert } from "../contexts/Alert";

interface Props extends React.PropsWithChildren<{}> {}

export default function AlertWrapper({ children }: Props): JSX.Element {
  const {
    errorMessage,
    setErrorMessage,
    successMessage,
    setSuccessMessage,
  } = useAlert();

  return (
    <React.Fragment>
      {children}
      <Snackbar
        style={SnackbarStyle.error}
        message={errorMessage}
        show={errorMessage !== null}
        onClose={() => setErrorMessage(null)}
      />
      <Snackbar
        style={SnackbarStyle.info}
        show={successMessage !== null}
        message={successMessage}
        onClose={() => setSuccessMessage(null)}
      />
    </React.Fragment>
  );
}
