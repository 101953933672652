import React from "react";
import { ButtonBase, Box, Typography } from "@material-ui/core";
import { Colors } from "../constants/colors";
import { useSessionStore } from "../stores/Session";
import { useAlert } from "../contexts/Alert";
import { User, UserType } from "../api/models";
import { useHistory } from "react-router-dom";

interface Props {
  propertyId?: number | null;
  width?: number | string | null;
  height?: number | string | null;
  authData?: object | null;
}

export default function PurchaseLegalReviewButton({
  propertyId,
  width,
  height,
  authData
}: Props): JSX.Element {
  const history = useHistory();

  const { data: userData, refresh: refreshUserData } = useSessionStore();
  const user = userData ? (userData as User) : (authData as User);

  const { setErrorMessage } = useAlert();

  return (
    <ButtonBase
      onClick={() => {
        if (user && user.user_type === UserType.Agent) {
          setErrorMessage(
            "We noticed you’re trying to buy a legal review from your agent account. You must create a personal account to purchase this review, to do this simply logout, search for a property and create a personal account"
          );
        } else if (user) {
          propertyId &&
            history.push(`/payment-preview?propertyId=${propertyId}`);
        } else {
          propertyId && history.push(`/signup?propertyId=${propertyId}`);
        }
      }}
      style={{ width: width || "auto" }}
    >
      <Box
        bgcolor={Colors.Link}
        color="white"
        width="100%"
        height={height || "66px"}
        borderRadius="8px"
        marginTop={2}
        justifyContent="center"
        alignItems="center"
        display="flex"
      >
        <Box>
          <Typography variant="body1" style={{ fontWeight: 700 }}>
          Get Legal Review
          </Typography>
          <Box display="flex" flexDirection="row" justifyContent="center">
            <Typography
              variant="body1"
              style={{ marginRight: "2px", marginTop: "2px" }}
            >
              $
            </Typography>
            <Typography variant="h5" style={{ marginRight: "5px" }}>
              74
            </Typography>
          </Box>
        </Box>
      </Box>
    </ButtonBase>
  );
}
