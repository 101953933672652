import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import { useCreditStore } from "../stores/credit";
import { useHistory, useLocation } from "react-router-dom";
import { usePurchaseStore } from "../stores/purchase";

export default function PaymentPreview() {
  const { refreshCredits } = useCreditStore();
  const history = useHistory();
  const location = useLocation();

  const { setPurchaseJourneyStartPath, setPurchaseJourneySuccessPath } =
    usePurchaseStore();

  React.useEffect(() => {
    async function fetchCredits() {
      const credits = await refreshCredits();

      if (credits === 0) {
        setPurchaseJourneyStartPath(`${location.pathname}${location.search}`);
        setPurchaseJourneySuccessPath(
          `/pay-for-property${location.search}&makePayment=true`
        );

        history.push("/plans");
      } else {
        history.push(`/pay-for-property${location.search}`);
      }
    }

    fetchCredits();
  }, []);

  return (
    <>
      <CircularProgress />
    </>
  );
}
