import {
  Box,
  ButtonBase,
  Link,
  Typography,
  Menu,
  Badge,
} from "@material-ui/core";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { User, UserType } from "../../api/models";
import { useSessionStore } from "../../stores/Session";
import BLUELOGO from "../../images/Blue.png";
import LoggedInUserDropdown from "../LoggedInUserDropdown";
import ImageButton from "../common/ImageButton";
import NotificationsIcon from "../../images/notifications-icon.svg";
import { Colors } from "../../constants/colors";
import { useMobile } from "../../hooks/mobile";
import SideMenuIcon from "../../images/sidemenu.svg";
import SideMenu from "./SideMenu";
import StickyHeader from "../common/StickyHeader";
import NotificationMenu from "../Notification/NotificationMenu";

interface LinkType {
  title: string;
  path: string;
  external?: boolean;
}

export default function () {
  const isMobile = useMobile();
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;

  const { refresh: refreshUserData, data: userData } = useSessionStore();
  const user = userData && (userData as User);

  // const [socketData, setSocketData] = React.useState("");

  React.useEffect(() => {
    const token = localStorage.getItem("token");

    const socket = new WebSocket(
      `wss://api-dev.contrax.com.au/connect/?token=${token}`
    );

    //Connection data
    socket.addEventListener("message", function (event) {
      const messageData = JSON.parse(event.data);

      // console.log("message count ", messageData.data.new_notification_count);
      if (user) {
        user.new_notification_count = messageData.data.new_notification_count;
      }
    });

    // Connection reconnect
    socket.addEventListener("close", function (event) {
      console.log("reconnecting...");
      setTimeout(function () {
        socket.addEventListener("message", function (event) {
          const messageData = JSON.parse(event.data);

          if (user) {
            user.new_notification_count =
              messageData.data.new_notification_count;
          }
        });
      }, 1000);
    });
  }, [user]);

  // Listen for messages

  const isBuyer = user?.user_type === UserType.Buyer;

  const isBuyerLawyer = user?.user_type === UserType.Solicitor;

  const isPartner = user?.user_type === UserType.Partner;

  const [sideMenuOpen, setSideMenuOpen] = React.useState(false);

  const [notificationMenu, setNotificationMenu] = React.useState(null);

  const handleNotificationMenu = (event) => {
    setNotificationMenu(event.currentTarget);
  };

  const handleNotificationMenuClose = () => {
    setNotificationMenu(null);
  };

  const links = (): Array<LinkType> => {
    return [
      {
        title: "My Dashboard",
        path: "/dashboard",
      },
      ...(isBuyer
        ? [
            {
              title: "Property Search",
              path: "/search-properties",
              external: true,
            },
          ]
        : [
            ...((isBuyerLawyer || isPartner) &&
            userData?.current_organization !== null
              ? [
                  {
                    title: "My Team",
                    path: "/my-team",
                  },
                  {
                    title: "Personalisation",
                    path: "/personalisation",
                  },
                ]
              : []),
          ]),
    ];
  };

  const handleClick = (event) => {
    // setUserMenu(event.currentTarget);

    history.push("/user");
  };

  React.useEffect(() => {
    refreshUserData(false);
  }, []);

  if (isMobile) {
    return (
      <>
        <StickyHeader height="72px">
          <Box
            width="100%"
            height="72px"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            pl="18px"
            pr="18px"
            boxSizing="border-box"
            bgcolor="white"
          >
            <ImageButton
              image={SideMenuIcon}
              onClick={() => setSideMenuOpen(true)}
            />
            <ButtonBase
              onClick={() => window.location.assign(window.location.origin)}
            >
              <img src={BLUELOGO} height="32px" alt="Preview of Contrax logo" />
            </ButtonBase>

            <Badge
              color="secondary"
              variant="standard"
              badgeContent={user?.new_notification_count}
              overlap="rectangle"
            >
              <ImageButton
                image={NotificationsIcon}
                onClick={handleNotificationMenu}
              />
            </Badge>
            <Menu
              id="notification-menu"
              open={Boolean(notificationMenu)}
              anchorEl={notificationMenu}
              onClose={handleNotificationMenuClose}
              keepMounted
              getContentAnchorEl={null}
              style={{ top: "40px", borderRadius: "8px" }}
            >
              <NotificationMenu handleClose={handleNotificationMenuClose} />
            </Menu>
          </Box>
        </StickyHeader>

        <SideMenu open={sideMenuOpen} onClose={() => setSideMenuOpen(false)} />
      </>
    );
  }

  return (
    <>
      <Box
        width="100%"
        height="76px"
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <ButtonBase
          onClick={() => window.location.assign(window.location.origin)}
        >
          <img
            src={BLUELOGO}
            height="32px"
            style={{ marginLeft: "24px", marginRight: "12px" }}
            alt="Preview of Contrax Logo"
          />
        </ButtonBase>
        <Box display="flex" flexDirection="row" flex={1}>
          {links().map((link: LinkType) => (
            <Link
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
                cursor: "pointer",
              }}
              onClick={() =>
                link.external
                  ? window.open(window.location.origin + link.path, "_self")
                  : history.push(link.path)
              }
            >
              <Typography
                style={{
                  fontSize: "18px",
                  lineHeight: "22px",
                  color:
                    link.path === pathname ? Colors.BrandPrimary : Colors.Grey2,
                }}
              >
                {link.title}
              </Typography>
            </Link>
          ))}
        </Box>
        <Badge
          color="secondary"
          variant="standard"
          badgeContent={user?.new_notification_count}
          overlap="rectangle"
        >
          <ImageButton
            image={NotificationsIcon}
            onClick={handleNotificationMenu}
          />
        </Badge>

        <Menu
          id="notification-menu"
          open={Boolean(notificationMenu)}
          anchorEl={notificationMenu}
          onClose={handleNotificationMenuClose}
          keepMounted
          getContentAnchorEl={null}
          style={{ top: "60px", borderRadius: "8px" }}
        >
          <NotificationMenu handleClose={handleNotificationMenuClose} />
        </Menu>

        <LoggedInUserDropdown onClick={handleClick} />
      </Box>
    </>
  );
}
