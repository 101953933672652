import React from "react";
import { Box, ButtonBase, Typography } from "@material-ui/core";
import { Colors } from "../constants/colors";

interface Props {
  isMobile: boolean;
  title: string;
  subtitle: string;
  onClick: () => unknown;
}

export default function DashboardButton({
  isMobile,
  title,
  subtitle,
  onClick,
}: Props): JSX.Element {
  if (isMobile) {
    return (
      <ButtonBase onClick={onClick}>
        <Box
          textAlign="left"
          bgcolor={Colors.BrandPrimary}
          color="white"
          borderRadius="8px"
          width="321px"
          height="72px"
          display="flex"
          alignItems="center"
        >
          <Box ml={3} mr={3}>
            <Typography variant="h6">{title}</Typography>
          </Box>
        </Box>
      </ButtonBase>
    );
  } else {
    return (
      <ButtonBase onClick={onClick}>
        <Box
          textAlign="left"
          bgcolor={Colors.BrandPrimary}
          color="white"
          borderRadius="8px"
          width="372px"
          height="120px"
        >
          <Box mt={2.5} mb={3} ml={3} mr={3}>
            <Typography variant="h6">{title}</Typography>
            <Typography variant="body2" style={{ marginTop: "8px" }}>
              {subtitle}
            </Typography>
          </Box>
        </Box>
      </ButtonBase>
    );
  }
}
