import { Box, Dialog, Typography } from "@material-ui/core";
import { isEmpty } from "lodash-es";
import React from "react";
import api from "../../api/instance";
import { useAlert } from "../../contexts/Alert";
import { parseApiError } from "../../helpers/error";
import { useMobile } from "../../hooks/mobile";
import Button, { ButtonSize, ButtonType } from "../common/Button";
import Separator from "../common/Separator";
import TextField from "../common/TextField";
import InfoMessageBar from "../InfoMessageBar";

// export enum AddMyTeamMemberModal {
//   default, // Requires both contract and record
//   sellerTeam,
//   agents,
// }

interface Props {
  // type?: AddMyTeamMemberModal;
  open: boolean;
  onClose: () => unknown;
  onInvited: (firstName: string, lastName: string) => unknown;
  isPartner: boolean;
  isSolicitor: boolean;
}

// interface InvitePeopleMessageProps {
//   userType?: UserType | null;
// }

function InvitePeopleMessage() {
  return (
    <InfoMessageBar
      message={"Team members can view and add properties to your dashboard."}
    />
  );
}

export default function ({
  open,
  onClose,
  onInvited,
  isPartner,
  isSolicitor,
}: Props) {
  const isMobile = useMobile();

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");

  const { setErrorMessage } = useAlert();

  const [inviting, setInviting] = React.useState(false);

  // console.log(isPartner, "PRT");
  // console.log(isSolicitor, "SOL");

  function resetFields() {
    setFirstName("");
    setLastName("");
    setEmail("");
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="invite-dialog"
      aria-describedby="invite-dialog-description"
      onClose={onClose}
      fullScreen={isMobile}
    >
      <Box
        width={isMobile ? "100%" : "524px"}
        display="flex"
        flexDirection="column"
      >
        <Box
          height="62px"
          pl="24px"
          alignItems="center"
          display="flex"
          flexDirection="row"
        >
          <Typography variant="h5" style={{ fontWeight: 700 }}>
            Invite your team members
          </Typography>
        </Box>
        <Separator />
        <InvitePeopleMessage />
        <Box p="24px">
          <TextField
            title="Email"
            value={email}
            onChange={(text) => setEmail(text)}
          />
          <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
            <TextField
              title="First Name"
              value={firstName}
              onChange={(text) => setFirstName(text)}
            />
            <Box width="12.5px" />
            <TextField
              title="Last Name"
              value={lastName}
              onChange={(text) => setLastName(text)}
            />
          </Box>
        </Box>
        <Separator />
        <Box
          display="flex"
          justifyContent="flex-end"
          pl="24px"
          pr="16px"
          height="74px"
          alignItems="center"
        >
          <Button
            title="CANCEL"
            size={ButtonSize.medium}
            type={ButtonType.secondary}
            onClick={onClose}
            width="114px"
          />
          <Box width="8px" />
          <Button
            loading={inviting}
            title="INVITE"
            size={ButtonSize.medium}
            type={ButtonType.primary}
            onClick={() => {
              if (isEmpty(email)) {
                setErrorMessage("Please enter their email");
                return;
              }

              if (isEmpty(firstName)) {
                setErrorMessage("Please enter their first name");
                return;
              }

              if (isEmpty(lastName)) {
                setErrorMessage("Please enter their last name");
                return;
              }

              setInviting(true);

              if (isSolicitor) {
                api
                  .post(`/invite-organization-member/`, {
                    first_name: firstName,
                    last_name: lastName,
                    email,
                  })
                  .then((response) => {
                    setInviting(false);
                    onInvited(firstName, lastName);
                    resetFields();
                  })
                  .catch((error) => {
                    setInviting(false);
                    setErrorMessage(parseApiError(error));
                  });
              }

              if (isPartner) {
                api
                  .post(`/invite-organization-member/?pip=pip`, {
                    first_name: firstName,
                    last_name: lastName,
                    email,
                  })
                  .then((response) => {
                    setInviting(false);
                    onInvited(firstName, lastName);
                    resetFields();
                  })
                  .catch((error) => {
                    setInviting(false);
                    setErrorMessage(parseApiError(error));
                  });
              }
            }}
            width="93px"
          />
        </Box>
      </Box>
    </Dialog>
  );
}
