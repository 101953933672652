import React from "react";
import { Box, Typography } from "@material-ui/core";
import AccountDetailsHeader from "../../components/Dashboard/AccountDetailsHeader";
import AuthenticatedWrapper from "../AuthenticatedWrapper";
import AlertWrapper from "../AlertWrapper";
import Sidebar from "./Sidebar";
import DesktopFooter from "../../components/common/DesktopFooter";
import { useMobile } from "../../hooks/mobile";
import { Colors } from "../../constants/colors";

interface Props extends React.PropsWithChildren<{}> {
  title: string;
  accessory?: React.ReactNode;
}

export default function Wrapper({ children, title, accessory }: Props) {
  const isMobile = useMobile();

  const vPadding = isMobile ? "32px" : "56px";
  const hPadding = isMobile ? "16px" : "80px";

  return (
    <AuthenticatedWrapper>
      <AlertWrapper>
        <Box display="flex" flexDirection={"column"} minHeight="100vh">
          <AccountDetailsHeader />

          <Box display="flex" flex={1}>
            <Box display="flex" flexDirection={"row"} flex={1}>
              {!isMobile && <Sidebar />}

              <Box display="flex" flexDirection={"column"} flex={1}>
                {isMobile && (
                  <>
                    <Box
                      bgcolor={Colors.BrandBackground2}
                      width="100vw"
                      height="140px"
                      paddingLeft="16px"
                      display="flex"
                      boxSizing={"border-box"}
                      flexDirection="column"
                      justifyContent={"center"}
                    >
                      <Typography variant="h5" style={{ fontWeight: 700 }}>
                        {title}
                      </Typography>
                    </Box>
                    <Box pt="18px" pl="16px" pr="16px">
                      {accessory}
                    </Box>
                  </>
                )}
                <Box
                  pt={hPadding}
                  pb={hPadding}
                  pl={vPadding}
                  pr={vPadding}
                  boxSizing="border-box"
                  flex={1}
                >
                  {!isMobile && (
                    <Box
                      display="flex"
                      flexDirection={"row"}
                      justifyContent="space-between"
                      flex={1}
                      alignItems="center"
                    >
                      <Typography variant="h4" style={{ fontWeight: 400 }}>
                        {title}
                      </Typography>

                      {accessory}
                    </Box>
                  )}
                  {children}
                </Box>
              </Box>
            </Box>
          </Box>

          <DesktopFooter />
        </Box>
      </AlertWrapper>
    </AuthenticatedWrapper>
  );
}
