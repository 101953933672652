import { Box, Dialog, Typography } from "@material-ui/core";
import React from "react";
import Button, { ButtonSize, ButtonType } from "../../components/common/Button";
import { useMobile } from "../../hooks/mobile";
import Icon from "../../images/check-your-email.svg";
import CloseButton, {
  CloseButtonColor,
} from "../../components/common/CloseButton";

interface Props {
  open: boolean;
  email?: string | null;
  onContactSupport: () => unknown;
  onClose: () => unknown;
}

export default function ({ open, email, onContactSupport, onClose }: Props) {
  const isMobile = useMobile();

  return (
    <Dialog open={open} fullScreen={isMobile} maxWidth="lg" onClose={onClose}>
      <Box width={isMobile ? "100%" : "514px"}>
        <Box
          display="flex"
          flexDirection="column"
          pt="22px"
          boxSizing="border-box"
          alignItems="center"
          pb="69px"
        >
          <Box
            justifyContent="flex-end"
            display="flex"
            flexDirection="row"
            width="92%"
            mb="14px"
          >
            <CloseButton color={CloseButtonColor.BLACK} onClick={onClose} />
          </Box>

          <img src={Icon} width="100px" height="100px" />

          <Typography
            variant="h6"
            style={{
              width: "279px",
              marginTop: "32px",
              marginBottom: "16px",
              textAlign: "center",
              lineHeight: "24px",
            }}
          >
            Check your email and verify your account
          </Typography>

          <Typography style={{ width: "279px", textAlign: "center" }}>
            We’ve sent an email to {email}. Open it and click to{" "}
            <strong>verify your account</strong> and go to your dashboard.
          </Typography>
        </Box>
        <Box
          height="74px"
          boxShadow="0px -1px 0px #E0E0E0"
          boxSizing="border-box"
          pt="16px"
          pl="16px"
          pr="16px"
        >
          <Button
            title="DIDN'T RECEIVE OUR EMAIL? CONTACT SUPPORT"
            type={ButtonType.secondary}
            size={ButtonSize.small}
            width="100%"
            onClick={onContactSupport}
          />
        </Box>
      </Box>
    </Dialog>
  );
}
