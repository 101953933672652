import React from "react";
import { Radio, RadioProps } from "@material-ui/core";
import { Colors } from "../../constants/colors";

export default function ({ disabled, ...props }: RadioProps) {
  return (
    <Radio
      style={{ color: disabled ? Colors.LightBackground : Colors.Grey2 }}
      disabled={disabled}
      {...props}
    />
  );
}
