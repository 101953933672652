import React from "react";
import { Box, Link, Typography } from "@material-ui/core";
import DashboardHeaderDesktop from "../components/DashboardHeaderDesktop";
import { useMobile, useDesktop } from "../hooks/mobile";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import DashboardHeaderMobile from "../components/DashboardHeaderMobile";
import AlertWrapper from "../components/AlertWrapper";
import { useLocation, useHistory } from "react-router-dom";
import NoContractAvailable from "../images/no-contract-available.png";
import TextField from "../components/common/TextField";
import Button, { ButtonType, ButtonSize } from "../components/common/Button";
import { placeDetails, search } from "../googleplaces/queries";


interface PlaceOption {
  value: string;
}

export default function PropertyNotReviewed(): JSX.Element {
  const isMobile = useMobile();
  const location = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(location.search);
  const placeId = query.get("placeId");
  const label = query.get("label") ;


  const [address, setAddress] = React.useState("");
  const [realEstateURL, setRealEstateURL] = React.useState("");
  const [realEstateAgentName, setRealEstateAgentName] = React.useState("");


  React.useEffect(() => {
    if(label){
        search(label).then((results:any) => {
          setAddress(results[0].description)
        }).catch((error) => {
          console.debug("error: ", error);
        });
    }
  }, []);

  if (!placeId) {
    return <Box />;
  }

  function nextClicked() {
    history.push("property-not-reviewed-confirm", {
      address: address,
      agent_name: realEstateAgentName,
      property_url: realEstateURL,
    });
  }

  return (
    <AlertWrapper>
      {isMobile && <DashboardHeaderMobile />}

      {!isMobile && <DashboardHeaderDesktop />}

      <Box
        maxWidth={isMobile ? "375px" : "450px"}
        marginLeft="auto"
        marginRight="auto"
        pt={isMobile ? "16px" : "38px"}
        pl={isMobile ? 3 : 0}
        pr={isMobile ? 3 : 0}
      >
        <img src={NoContractAvailable} height={isMobile ? "80px" : "167px"} />
        <Box mt={2.875} />
        <Typography variant="h4">
          We haven't reviewed this property yet
        </Typography>
        <Box mt={2} />
        <Typography variant="body1">
          We’re here to help! We would love to create a legal review for you,
          simply add the real estate URL and agent name and we’ll have a
          contract of sale and legal review ready within the next 24 business
          hours.
        </Typography>
        <Box mt={3.75} />
        <TextField title="Address" value={address} disabled />
        <Box mt={2} />
        <TextField
          title="Real Estate URL"
          value={realEstateURL}
          onChange={(value) => setRealEstateURL(value)}
        />
        <Box mt={2} />
        <TextField
          title="Real Estate Agent Name"
          value={realEstateAgentName}
          onChange={(value) => setRealEstateAgentName(value)}
        />
        <Box mt={2} />
        {!isMobile && (
          <Button
            type={ButtonType.primary}
            size={ButtonSize.large}
            title="NEXT"
            onClick={() => nextClicked()}
          />
        )}
        {isMobile && (
          <MobileStickyFooter
            buttonsLayout={FooterButtonsLayout.centered}
            buttonTitle="NEXT"
            buttonOnClick={() => nextClicked()}
          />
        )}
      </Box>
    </AlertWrapper>
  );
}
