import { Box, ButtonBase, Link, Typography } from '@material-ui/core';
import Button, { ButtonSize, ButtonType } from './common/Button';
import { User, UserType } from '../api/models';
import { useHistory, useLocation } from 'react-router-dom';
import { useSessionStore, userCacheKey } from '../stores/Session';

import BLUELOGO from '../images/BlueLogo.png';
import React from 'react';
import { isNil } from 'lodash';

interface LinkType {
  title: string;
  path: string;
  external?: boolean;
}

export default function DashboardHeaderDesktop(): JSX.Element {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;

  const { data: userData } = useSessionStore();
  const user = userData && (userData as User);

  const links = (): Array<LinkType> => {
    if (user && user.user_type === UserType.Agent) {
      return [
        {
          title: 'Dashboard',
          path: '/dashboard',
        },
        {
          title: 'My properties',
          path: '/my-properties',
        },
        {
          title: 'Account details',
          path: '/account-details',
        },
      ];
    }

    if (
      user &&
      (user.user_type === UserType.Buyer ||
        user.user_type === UserType.Solicitor)
    ) {
      return [
        {
          title: 'Dashboard',
          path: '/dashboard',
        },
        {
          title: 'My properties',
          path: '/my-properties',
        },
        {
          title: 'My invoices',
          path: '/my-invoices',
        },
        {
          title: 'Account details',
          path: '/account-details',
        },
      ];
    }

    return [
      {
        title: 'For Buyers',
        path: '/buyers',
        external: true,
      },
      {
        title: 'For Agents',
        path: '/agents',
        external: true,
      },
      {
        title: 'For Lawyers & Conveyancers',
        path: '/lawyer',
        external: true,
      },
      {
        title: 'Blog',
        path: '/blog',
        external: true,
      },
    ];
  };

  function UserNameLink() {
    if (user) {
      const initials = user.first_name.charAt(0) + user.last_name.charAt(0);

      // if (user.user_type === UserType.Agent) {
      return (
        <Box ml={3}>
          <Button
            type={ButtonType.secondary}
            size={ButtonSize.medium}
            title={initials}
            sidePadding={2.5}
            onClick={() => history.push('/account-details')}
          />
        </Box>
      );
      // } else {
      //   return (
      //     <Button
      //       type={ButtonType.primary}
      //       size={ButtonSize.medium}
      //       title={initials}
      //       sidePadding={2.5}
      //       onClick={() => history.push("/account-details")}
      //     />
      //   );
      // }
    }

    return <Box />;
  }

  return (
    <Box
      width='100%'
      height='76px'
      display='flex'
      flexDirection='row'
      alignItems='center'
    >
      <ButtonBase
        onClick={() => window.location.assign(window.location.origin)}
      >
        <img
          src={BLUELOGO}
          height='32px'
          style={{ marginLeft: '24px', marginRight: '12px' }}
        />
      </ButtonBase>
      <Box display='flex' flexDirection='row' flex={1}>
        {links().map((link: LinkType) => (
          <Link
            style={{
              paddingLeft: '16px',
              paddingRight: '16px',
              cursor: 'pointer',
            }}
            onClick={() =>
              link.external
                ? window.open(window.location.origin + link.path, '_self')
                : history.push(link.path)
            }
          >
            <Typography
              style={{
                fontSize: '18px',
                lineHeight: '22px',
                color: link.path === pathname ? '#0038E4' : '#313131',
              }}
            >
              {link.title}
            </Typography>
          </Link>
        ))}
      </Box>
      <Box display='flex' flexDirection='row' alignItems='center'>
        {user && user.user_type === UserType.Agent && (
          <Button
            type={ButtonType.primary}
            size={ButtonSize.medium}
            title='UPLOAD A CONTRACT'
            onClick={() => history.push('/agent/add-property')}
          />
        )}
        {user && (
          <React.Fragment>
            <UserNameLink />
            <Link
              href='#'
              style={{ marginLeft: '24px', marginRight: '24px' }}
              onClick={() => {
                localStorage.removeItem('token');
                localStorage.removeItem(userCacheKey);

                history.push('/login');
              }}
            >
              <Typography
                style={{
                  fontSize: '18px',
                  lineHeight: '22px',
                  color: '#313131',
                }}
              >
                Logout
              </Typography>
            </Link>
          </React.Fragment>
        )}

        {isNil(user) && (
          <React.Fragment>
            <Button
              type={ButtonType.light}
              size={ButtonSize.medium}
              title='Talk to an expert'
              onClick={() => window.open(window.location.origin + '/contact-us', '_self')}
            />
            <Box mr={2}/>
            <Button
              type={ButtonType.primary}
              size={ButtonSize.medium}
              title='LOGIN'
              onClick={() => {
                history.push('/login', {
                  next: `${location.pathname}${location.search}`,
                });
              }}
            />
            <Box ml={2}/>
          </React.Fragment>
        )}
      </Box>
    </Box>
  );
}
