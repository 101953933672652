import React from "react";
import { Box, Typography, Link } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import { useMobile } from "../../hooks/mobile";
import FooterIcon2 from "../../images/Footer-Icon2.png";
import linkedin from "../../images/linkedin.svg";
import facebook from "../../images/facebook.svg";
import instagram from "../../images/instagram.svg";

export default function PartnerLandingPageFooter(): JSX.Element {
  const isMobile = useMobile();

  const footerStyle = {
    backgroundColor: Colors.BrandBackground4,
    bottom: 0,
    height: isMobile ? "782px" : "400px",
    marginLeft: isMobile ? "27px" : "172px",
    marginRight: isMobile ? "27px" : "172px",
  };

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Box bgcolor={Colors.BrandBackground4}>
      {!isMobile && (
        <Box style={footerStyle}>
          <Box flexDirection="column" display="flex" flex={1} height="100%">
            <Box display="flex" flexDirection="row" mt={7}>
              <img src={FooterIcon2} height="38px" width="38px" />
            </Box>
            <Box display="flex" flexDirection="row" width="100%" mt={4}>
              <Box display="flex" flexDirection="column" width="40%">
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 400,
                    color: Colors.BrandBackground2,
                  }}
                >
                  Need support? <br /> We’re here to help
                </Typography>
                <Link
                  underline="none"
                  style={{ cursor: "pointer", marginTop: "16px" }}
                  onClick={() => {
                    window.open("https://contrax.com.au/contact-us", "_blank");
                  }}
                >
                  <Typography
                    variant="h4"
                    style={{ fontWeight: 700, color: Colors.White }}
                  >
                    Get in touch
                  </Typography>
                </Link>
              </Box>

              <Box display="flex" flexDirection="column" width="30%">
                <Link
                  underline="none"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.open("https://contrax.com.au/buyers", "_blank");
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: 700,
                      color: Colors.BrandBackground2,
                    }}
                  >
                    For Buyers
                  </Typography>
                </Link>
                <Link
                  underline="none"
                  style={{ cursor: "pointer", marginTop: "16px" }}
                  onClick={() => {
                    window.open("https://contrax.com.au/lawyer", "_blank");
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{ fontWeight: 700, color: Colors.White }}
                  >
                    For Lawyers & Conveyancers
                  </Typography>
                </Link>
                <Link
                  underline="none"
                  style={{ cursor: "pointer", marginTop: "16px" }}
                  onClick={() => {
                    window.open("https://contrax.com.au/agents", "_blank");
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{ fontWeight: 700, color: Colors.White }}
                  >
                    For Agents
                  </Typography>
                </Link>
                <Link
                  underline="none"
                  style={{ cursor: "pointer", marginTop: "16px" }}
                  onClick={() => {
                    window.open("https://contrax.com.au/pricing", "_blank");
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{ fontWeight: 700, color: Colors.White }}
                  >
                    Pricing
                  </Typography>
                </Link>
              </Box>

              <Box display="flex" flexDirection="column" width="20%">
                <Link
                  underline="none"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.open("https://contrax.com.au/about", "_blank");
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: 700,
                      color: Colors.BrandBackground2,
                    }}
                  >
                    About us
                  </Typography>
                </Link>
                <Link
                  underline="none"
                  style={{ cursor: "pointer", marginTop: "16px" }}
                  onClick={() => {
                    window.open("https://contrax.com.au/contact-us", "_blank");
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{ fontWeight: 700, color: Colors.White }}
                  >
                    Contact us
                  </Typography>
                </Link>
                <Link
                  underline="none"
                  style={{ cursor: "pointer", marginTop: "16px" }}
                  onClick={() => {
                    window.open("https://contrax.com.au/blog", "_blank");
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{ fontWeight: 700, color: Colors.White }}
                  >
                    Blog
                  </Typography>
                </Link>
                <Link
                  underline="none"
                  style={{ cursor: "pointer", marginTop: "16px" }}
                  onClick={() => {
                    window.open("https://contrax.com.au/faq", "_blank");
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{ fontWeight: 700, color: Colors.White }}
                  >
                    FAQ
                  </Typography>
                </Link>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                width="25%"
                justifyContent="end"
              >
                <Link
                  underline="none"
                  style={{ cursor: "pointer", marginRight: "16px" }}
                  onClick={() => {
                    window.open(
                      "https://www.instagram.com/contrax_australia/?hl=en",
                      "_blank"
                    );
                  }}
                >
                  <img src={instagram} height="24px" width="24px" />
                </Link>
                <Link
                  underline="none"
                  style={{ cursor: "pointer", marginRight: "16px" }}
                  onClick={() => {
                    window.open(
                      "https://www.linkedin.com/company/contrax-australia/",
                      "_blank"
                    );
                  }}
                >
                  <img src={linkedin} height="24px" width="24px" />
                </Link>
                <Link
                  underline="none"
                  style={{ cursor: "pointer", marginRight: "16px" }}
                  onClick={() => {
                    window.open(
                      "https://www.facebook.com/ContraxAustralia/",
                      "_blank"
                    );
                  }}
                >
                  <img src={facebook} height="24px" width="24px" />
                </Link>
              </Box>
            </Box>
            <Box
              flexDirection="row"
              mt={1}
              display="flex"
              flex={1}
              alignItems="center"
              justifyContent="space-between"
              height="100%"
            >
              {" "}
              <Typography
                variant="caption"
                style={{ fontWeight: 700, color: Colors.White }}
              >
                <Link
                  underline="none"
                  style={{ cursor: "pointer", color: Colors.White }}
                  onClick={() =>
                    window.open(
                      "https://contrax.com.au/privacy-policy",
                      "_blank"
                    )
                  }
                >
                  Privacy Policy
                </Link>{" "}
                <Link
                  underline="none"
                  style={{
                    cursor: "pointer",
                    marginLeft: "16px",
                    color: Colors.White,
                  }}
                  onClick={() =>
                    window.open("https://contrax.com.au/terms", "_blank")
                  }
                >
                  Terms and Conditions
                </Link>
              </Typography>
              <Box>
                <Typography
                  variant="caption"
                  style={{ fontWeight: 700, color: Colors.BrandBackground1 }}
                >
                  © Copyright {getCurrentYear()} Contrax Australia Pty. Ltd
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {isMobile && (
        <Box style={footerStyle}>
          <Box flexDirection="column" display="flex" flex={1} width="100%">
            <img
              src={FooterIcon2}
              height="38px"
              width="38px"
              style={{ marginTop: "52px" }}
            />

            <Box display="flex" flexDirection="column" width="100%" mt={4}>
              <Typography
                variant="h4"
                style={{
                  fontWeight: 400,
                  color: Colors.BrandBackground2,
                }}
              >
                Need support? <br /> We’re here to help
              </Typography>
              <Link
                underline="none"
                style={{ cursor: "pointer", marginTop: "8px" }}
                onClick={() => {
                  window.open("https://contrax.com.au/contact-us", "_blank");
                }}
              >
                <Typography
                  variant="h4"
                  style={{ fontWeight: 700, color: Colors.White }}
                >
                  Get in touch
                </Typography>
              </Link>

              <Box
                display="flex"
                flexDirection="row"
                justifyContent="start"
                mt={4}
                mb={4}
              >
                <Link
                  underline="none"
                  style={{ cursor: "pointer", marginRight: "16px" }}
                  onClick={() => {
                    window.open(
                      "https://www.instagram.com/contrax_australia/?hl=en",
                      "_blank"
                    );
                  }}
                >
                  <img src={instagram} height="24px" width="24px" />
                </Link>
                <Link
                  underline="none"
                  style={{ cursor: "pointer", marginRight: "16px" }}
                  onClick={() => {
                    window.open(
                      "https://www.linkedin.com/company/contrax-australia/",
                      "_blank"
                    );
                  }}
                >
                  <img src={linkedin} height="24px" width="24px" />
                </Link>
                <Link
                  underline="none"
                  style={{ cursor: "pointer", marginRight: "16px" }}
                  onClick={() => {
                    window.open(
                      "https://www.facebook.com/ContraxAustralia/",
                      "_blank"
                    );
                  }}
                >
                  <img src={facebook} height="24px" width="24px" />
                </Link>
              </Box>

              <Link
                underline="none"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open("https://contrax.com.au/buyers", "_blank");
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: 700,
                    color: Colors.BrandBackground2,
                  }}
                >
                  For Buyers
                </Typography>
              </Link>
              <Link
                underline="none"
                style={{ cursor: "pointer", marginTop: "16px" }}
                onClick={() => {
                  window.open("https://contrax.com.au/lawyer", "_blank");
                }}
              >
                <Typography
                  variant="body1"
                  style={{ fontWeight: 700, color: Colors.White }}
                >
                  For Lawyers & Conveyancers
                </Typography>
              </Link>
              <Link
                underline="none"
                style={{ cursor: "pointer", marginTop: "16px" }}
                onClick={() => {
                  window.open("https://contrax.com.au/agents", "_blank");
                }}
              >
                <Typography
                  variant="body1"
                  style={{ fontWeight: 700, color: Colors.White }}
                >
                  For Agents
                </Typography>
              </Link>
              <Link
                underline="none"
                style={{ cursor: "pointer", marginTop: "16px" }}
                onClick={() => {
                  window.open("https://contrax.com.au/pricing", "_blank");
                }}
              >
                <Typography
                  variant="body1"
                  style={{ fontWeight: 700, color: Colors.White }}
                >
                  Pricing
                </Typography>
              </Link>

              <Link
                underline="none"
                style={{ cursor: "pointer", marginTop: "16px" }}
                onClick={() => {
                  window.open("https://contrax.com.au/about", "_blank");
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: 700,
                    color: Colors.BrandBackground2,
                  }}
                >
                  About us
                </Typography>
              </Link>
              <Link
                underline="none"
                style={{ cursor: "pointer", marginTop: "16px" }}
                onClick={() => {
                  window.open("https://contrax.com.au/contact-us", "_blank");
                }}
              >
                <Typography
                  variant="body1"
                  style={{ fontWeight: 700, color: Colors.White }}
                >
                  Contact us
                </Typography>
              </Link>
              <Link
                underline="none"
                style={{ cursor: "pointer", marginTop: "16px" }}
                onClick={() => {
                  window.open("https://contrax.com.au/blog", "_blank");
                }}
              >
                <Typography
                  variant="body1"
                  style={{ fontWeight: 700, color: Colors.White }}
                >
                  Blog
                </Typography>
              </Link>
              <Link
                underline="none"
                style={{ cursor: "pointer", marginTop: "16px" }}
                onClick={() => {
                  window.open("https://contrax.com.au/faq", "_blank");
                }}
              >
                <Typography
                  variant="body1"
                  style={{ fontWeight: 700, color: Colors.White }}
                >
                  FAQ
                </Typography>
              </Link>
            </Box>
            <Box
              flexDirection="row"
              mt={7}
              display="flex"
              flex={1}
              alignItems="center"
              justifyContent="start"
            >
              {" "}
              <Typography
                variant="caption"
                style={{ fontWeight: 700, color: Colors.White }}
              >
                <Link
                  underline="none"
                  style={{ cursor: "pointer", color: Colors.White }}
                  onClick={() =>
                    window.open(
                      "https://contrax.com.au/privacy-policy",
                      "_blank"
                    )
                  }
                >
                  Privacy Policy
                </Link>{" "}
                <Link
                  underline="none"
                  style={{
                    cursor: "pointer",
                    marginLeft: "16px",
                    color: Colors.White,
                  }}
                  onClick={() =>
                    window.open("https://contrax.com.au/terms", "_blank")
                  }
                >
                  Terms and Conditions
                </Link>
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="start" mt={2}>
              <Typography
                variant="caption"
                style={{ fontWeight: 700, color: Colors.BrandBackground1 }}
              >
                © Copyright {getCurrentYear()} Contrax Australia Pty. Ltd
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
