import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import FooterIcon from "../../images/footer-icon.png";
import { useMobile } from "../../hooks/mobile";

const height = "79px";

const phantomStyle = {
  height,
};

const footerStyle = {
  height,
  backgroundColor: Colors.Grey5,
  bottom: 0,
  boxShadow: "0px -1px 0px #E0E0E0",
};

export default function DesktopFooter(): JSX.Element {
  const isMobile = useMobile();

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };
  return (
    <Box>
      {/* Phantom view so views on top of footer knows "it's there" and respects margins */}
      {/* <Box style={phantomStyle} width="100%" /> */}

      <Box
        style={footerStyle}
        // zIndex={1}
        // position="fixed"
        // left={0}
        // right={0}

        width="100%"
      >
        <Box
          flexDirection="row"
          display="flex"
          flex={1}
          alignItems="center"
          justifyContent="space-between"
          ml={isMobile ? 5 : 21.25}
          mr={isMobile ? 5 : 21.25}
          height="100%"
        >
          <img src={FooterIcon} />
          <Typography variant="caption">
            © Copyright {getCurrentYear()} Contrax Australia Pty Ltd
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
