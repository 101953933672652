import { Box, Link, Typography, Divider } from '@material-ui/core';
import Button, { ButtonSize, ButtonType } from '../components/common/Button';
import axios, { AxiosError } from 'axios';
import { useHistory, useLocation } from 'react-router-dom';

import AlertWrapper from '../components/AlertWrapper';
import { Colors } from '../constants/colors';
import HeroLayout from '../components/HeroLayout';
import React from 'react';
import Separator from '../components/common/Separator';
import TextField from '../components/common/TextField';
import api from '../api/instance';
import { get } from 'lodash';
import { isEmpty } from 'lodash';
import { useAlert } from '../contexts/Alert';
import { useMobile } from '../hooks/mobile';
import { normalisedPlaceId, placeDetails } from '../googleplaces/queries';
import { addressForProperty, Contract, Property, User } from '../api/models';
import useSWR from 'swr';
import { useSessionStore } from '../stores/Session';


interface PlaceDetails {
  street: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}

export default function AddSearchedLegalReview(): JSX.Element {
  const history = useHistory();
  const location = useLocation();
  const isMobile = useMobile();
  const { setErrorMessage, setSuccessMessage } = useAlert();
  const query = new URLSearchParams(location.search);

  const [loading, setLoading] = React.useState(false);

  

  const { refresh: refreshUserData, data: userData } = useSessionStore();
  const user = userData && (userData as User);


  const selectedPlaceId = query.get("placeId");
  const selectedLabel = query.get("label");
  const propertyId = query.get("propertyId");

  const { data: propertyData } = useSWR(`/view-properties/${propertyId}/`);
  const property = propertyData && (propertyData as Property);



  const [
    selectedPlaceDetails,
    setSelectedPlaceDetails,
  ] = React.useState<PlaceDetails | null>(null);

  React.useEffect(() => {
    if (selectedPlaceId) {
      placeDetails(selectedPlaceId).then((result) => {
        console.debug("view result: ", result);
        let place = result as google.maps.places.PlaceResult;
        let components = place.address_components;
        if (components) {
          let subPremise =
            components.find((component) =>
              component.types.includes("subpremise")
            )?.long_name || "";

          let streetNumber =
            components.find((component) =>
              component.types.includes("street_number")
            )?.long_name || "";
          let streetName =
            components.find((component) => component.types.includes("route"))
              ?.short_name || "";
          let city =
            components.find((component) => component.types.includes("locality"))
              ?.long_name || "";
          let state =
            components.find((component) =>
              component.types.includes("administrative_area_level_1")
            )?.short_name || "";
          let postalCode =
            components.find((component) =>
              component.types.includes("postal_code")
            )?.long_name || "";
          let country =
            components.find((component) => component.types.includes("country"))
              ?.long_name || "";

          setSelectedPlaceDetails({
            street: `${subPremise && subPremise + '/'}${streetNumber} ${streetName}`.trim(),
            city,
            state,
            postalCode,
            country,
          });
        }
      });
    }
  }, [selectedPlaceId]);

  React.useEffect(() => {
    if (selectedPlaceDetails) {
      const {
        street,
        city,
        state,
        postalCode: postal_code,
        country,
      } = selectedPlaceDetails;

      const form = new FormData();
      selectedPlaceId && form.append("place_id", selectedPlaceId);
      form.append("street", street);
      form.append("city", city);
      form.append("state", state);
      form.append("postal_code", postal_code);
      form.append("country", country);

      api
        .post(`/public-properties/?public=true&label=${selectedLabel}&street_name=${selectedPlaceDetails && selectedPlaceDetails.street}`, form)
        .then((response) => {
          const property = response.data as Property;

          setLoading(true);

          history.replace("/search/add-searched-property?propertyId=" + property.id);
        })
        .catch((error: AxiosError) => {
          setLoading(false);

          setErrorMessage(error.message);
        });
    }
  }, [selectedPlaceDetails])


  const handleCheckout = () => {
    api
    .post("/dummy-contracts/", {property_id: propertyId})
    .then((response) => {
      if(user){
        history.push(`/payment-preview?propertyId=${propertyId}&cross=false`)
      } else {
        history.push(
          `/signup?propertyId=${propertyId}`
         );
      }
    })
    .catch((error: AxiosError) => {
      setLoading(false);
      setErrorMessage(error.message);
    });
  }


  return (
    <AlertWrapper>
      <HeroLayout>
        <Box mt={isMobile ? 4 : 10}
        ml={isMobile? 2: undefined}
        mr={isMobile? 2: undefined}
        >
          <Button
            type={ButtonType.white}
            size={ButtonSize.small}
            title='Back to property'
            sidePadding={1}
            onClick={() => history.push(`/property-info?propertyId=${propertyId}`)}
          />
          <Typography variant="h4">
           {(selectedPlaceDetails && selectedPlaceDetails.street + ", " +selectedPlaceDetails.city + ", " + selectedPlaceDetails.state +", "+ selectedPlaceDetails.postalCode)
           || (property && addressForProperty(property))
           }
          </Typography>
        </Box>
        <Box
          mt={isMobile ? 4 : 5}
          ml={isMobile? 2: undefined}
          mr={isMobile? 2: undefined}
          alignItems={isMobile ? 'center' : undefined}
        >
          <Button
            type={ButtonType.primary}
            size={ButtonSize.medium}
            title='UPLOAD CONTRACT OF SALE'
            onClick={() => history.push(`/search/upload-contract?propertyId=${propertyId}`)}
          />
          <Box mt={3} />
          <Button
            type={ButtonType.primary}
            size={ButtonSize.medium}
            title='REQUEST FROM AGENT'
            sidePadding={7}
            onClick={() => history.push(`/add/agent/details?propertyId=${propertyId}`)}
          />
        </Box>
        <Box mt={3} />
        <Divider />
        <Box mt={2} />
        <Box 
        ml={isMobile? 2: undefined}
        >
            <Button
          type={ButtonType.white}
          size={ButtonSize.small}
          sidePadding={1}
          title='OR Skip and head straight to checkout'
          onClick={() => handleCheckout()}
        />
        </Box>
      
      </HeroLayout>
    </AlertWrapper>
  );
}
