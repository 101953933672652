import React from "react";
import { Box, Link, Typography, ButtonBase } from "@material-ui/core";
import { MyProperty, addressForProperty } from "../api/models";
import { Colors } from "../constants/colors";
import { useHistory } from "react-router-dom";
import NoPropertyImage from "../images/no-property-image.svg";

interface Props {
  property: MyProperty;
}

export default function MyInvoiceRow({ property }: Props): JSX.Element {
  const history = useHistory();

  if (!property) {
    return <Box />;
  }

  function viewInvoice() {
    property.invoice && window.open(property.invoice);
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      bgcolor={Colors.Grey5}
      pl={1}
      pr={1}
      pt={1.5}
      pb={1.5}
      mt={0.5}
      mb={0.5}
      flex={1}
      onClick={() => viewInvoice()}
      style={{ cursor: "pointer" }}
    >
      <Box width="117px" height="88px" borderRadius="4px" overflow="hidden">
        {property.image && (
          <img
            src={property.image}
            width="100%"
            height="100%"
            style={{ objectFit: "cover" }}
          />
        )}
        {!property.image && <img src={NoPropertyImage} />}
      </Box>
      <Box ml={2} display="flex" flexDirection="column">
        <Typography variant="body2">{property.address}</Typography>
        <Link style={{ cursor: "pointer" }} onClick={() => viewInvoice()}>
          <Typography
            variant="body2"
            style={{
              fontWeight: 700,
              color: Colors.BrandPrimary,
              marginTop: "5px",
            }}
          >
            View Invoice
          </Typography>
        </Link>
      </Box>
    </Box>
  );
}
