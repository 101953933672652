import React from "react";
import { Box, Typography, Dialog } from "@material-ui/core";
import Button, { ButtonType, ButtonSize } from "./common/Button";
import Separator from "./common/Separator";
import { useMobile } from "../hooks/mobile";
import {
  Activity,
  ActivityAction,
  Contract,
  TeamMemberRole,
  User,
} from "../api/models";
import Switch from "./common/Switch";
import { useContractStore } from "../stores/contract";
import api from "../api/instance";
import { useLocation } from "react-router-dom";
import useSWR from "swr";
import { isNil, result } from "lodash-es";

export enum AddAMessageDialogForScreen {
  legalreviewviewer,
  requestworkspace,
}

interface Props {
  open: boolean;
  sendTitle?: string;
  onSend: (message: string, pdf: boolean) => unknown;
  sending: boolean;
  onCancel: () => unknown;
  contract?: Contract | null;
  forScreen?: AddAMessageDialogForScreen;
  userTeamMemberRole?: TeamMemberRole | null;
  userData?: User | null;
}

export default function AddAMessageDialog({
  open,
  sendTitle,
  onSend,
  sending,
  onCancel,
  contract,
  forScreen,
  userData,
}: Props) {
  const { userTeamMemberRole } = useContractStore();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const recordId = query.get("recordId");
  
  

  const isSellerLawyer = userTeamMemberRole === TeamMemberRole.sellerLawyer;

  const isBuyerLawyer = userTeamMemberRole === TeamMemberRole.buyerLawyer;

  const blplaceholderText = `Dear ${contract?.seller_lawyer?.first_name} ${contract?.seller_lawyer?.last_name},\n\nCC: ${contract?.agent?.first_name} ${contract?.agent?.last_name}\n\nI act for ${contract?.title} who is interested in purchasing this property. We are using Contrax, a more secure way to request amendments to the contract.\n\nYou can view the requests and respond directly on the platform. Anything agreed will form part of an appendix that the selling agent can attach to the contract if my client is successful.\n\nPlease create a password or log in for security, and invite your client or colleagues to the workspace as required.\n\nKind regards,\n${contract?.primary_buyer_lawyer?.first_name} ${contract?.primary_buyer_lawyer?.last_name}`;

  const legalplaceholderText = `Dear ${contract?.primary_buyer?.first_name} ${contract?.primary_buyer?.last_name},\n\nWe've now reviewed the contract. Please read and comment on each item (as applicable) directly on the platform.\n\nFeel free to invite other buyers or advisers into the workspace to view the summary.\n\nOnce you've had a chance to read and comment, the next step is for us to reach out to the vendor's lawyer or conveyancer with our requests and any general questions you may have.\n\nLet us know when you're ready for us to do so and if you have any questions.\n\nBest regards,\n${contract?.primary_buyer_lawyer?.first_name} ${contract?.primary_buyer_lawyer?.last_name}`;

  const slplaceholderText = `Dear ${contract?.primary_buyer_lawyer?.first_name} ${contract?.primary_buyer_lawyer?.last_name},\n\nThank you for sending through your requests for ${contract?.title}. I've now responded.\n\nKind regards,\n${userData?.first_name} ${userData?.last_name}`;

  const [message, setMessage] = React.useState("");
  const [receiverList, setReceiverList] = React.useState([]);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const title = sendTitle ?? (isSellerLawyer ? "SEND" : "EMAIL");
  
  // const [finalising, setFinalising] = React.useState(false);
 

  const { data: activitiesData } = useSWR(
    !isNil(contract?.id)
      ? `/contract/${contract?.id}/record/${recordId}/activities/`
      : null
  );

  const activities = activitiesData ? (activitiesData as Activity[]) : [];

  const requestsSent = !isNil(
    activities.find((activity) => activity.action === ActivityAction.Request)
  );

  React.useEffect(() => {
    if (
      isBuyerLawyer &&
      forScreen === AddAMessageDialogForScreen.requestworkspace
    ) {
      setMessage(blplaceholderText);
    } else if (
      isSellerLawyer &&
      forScreen === AddAMessageDialogForScreen.requestworkspace
    ) {
      setMessage(slplaceholderText);
    } else if (
      isBuyerLawyer &&
      forScreen === AddAMessageDialogForScreen.legalreviewviewer
    ) {
      setMessage(legalplaceholderText);
    } else {
      setMessage("Hi");
    }
  }, [isSellerLawyer, legalplaceholderText, isBuyerLawyer, open, contract]);

  const [pdf, setPdf] = React.useState(false);

  const isMobile = useMobile();

  const FetchReceiversList = async () => {
    try {
      const result = await api.get(
        `/contract/${contract?.id}/record/${recordId}/request-recipients/`
      );

      const propertyAddress = result.data?.property_address;
      const recipients = result.data.recipients.filter(recipient => recipient.user_type === "SOL");
      const CCrecipients = result.data.recipients.filter(recipient => recipient.user_type !== "SOL");
      const CC= CCrecipients.map((item) =>item.email)
      const emailList = recipients.map((item) => item.email);
      console.log(result.data.recipients);
      
      return { propertyAddress, emailList, CC }; // Return both propertyAddress and emailList
    } catch (error) {
      console.log(error);
      return { propertyAddress: null, emailList: [] }; // Return propertyAddress as null in case of error
    }
  };
 
  

  const FetchLegalReviews = async () => {
    try {
      const result = await api.get(
        `/contract/${contract?.id}/record/${recordId}/legal-review-requests/`
      );

      const PendingReviews = result.data.filter(
        (item) => item.request_status === "Pending"
      );

      return PendingReviews;
    } catch (error) {
      console.log(error);
    }
  };

  const FetchGeneralEnquires = async () => {
    try {
      const result = await api.get(
        `/contract/${contract?.id}/record/${recordId}/legal-review-enquiries/`
      );
      const PendingEnquiries = result.data.filter(
        (item) => item.enquiry_status === "Pending"
      );

      return PendingEnquiries;
    } catch (error) {
      console.log(error, "error fetching the general enquiries");
    }
  };

  const openMail = async (message) => {
    try {
      const pendingReviews = await FetchLegalReviews();
      const pendingEnquiries = await FetchGeneralEnquires();
      const { propertyAddress, emailList, CC } = await FetchReceiversList(); // Destructure propertyAddress and emailList

      if (emailList && emailList.length > 0) {
        const emailString = emailList.join(", ");
        const ccString = CC.join(", ");
        const subject = `${propertyAddress} - Contract Requests/Inquiries`; // Use propertyAddress in the subject

        let index = 1; // Initialize the index

        // Format the pending reviews into a readable string with numbering and bold titles
        const pendingReviewsText = pendingReviews
          .map(
            (review) =>
              `${index++}. ${review.title}: ${review.description}`
          )
          .join("\n");

        // Format the pending enquiries into a readable string with numbering and bold titles
        const pendingEnquiriesText = pendingEnquiries
          .map(
            (enquiry) =>
              `${index++}. ${enquiry.title}: ${enquiry.enquiry}`
          )
          .join("\n");

        const customMessage = `Good afternoon,\n\nI act for ${contract?.title} who are interested in purchasing the above property, subject to the following contract requests/inquiries:`;

        const fullMessage = `${customMessage}\n\n${pendingReviewsText}\n${pendingEnquiriesText}\n\nThank you and regards,\n\n${contract?.primary_buyer_lawyer?.first_name} ${contract?.primary_buyer_lawyer?.last_name}`;

        const mailToLink = `mailto:${emailString}?subject=${encodeURIComponent(
          subject
        )}&body=${encodeURIComponent(fullMessage)}&cc=${encodeURIComponent(ccString)}`;
        window.open(mailToLink);
      } else {
        console.error("No receivers found");
      }
    } catch (error) {
      console.error("Error opening mail:", error);
    }
  };

  const markSend = async () => {
    try {
      const result =
        await api.post(`/contract/${contract?.id}/record/${recordId}/request-sent-seller-team/
    `);
      if (result?.data?.message === "Message sent") {
        setIsDisabled(true);
        onCancel();
      }
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const UpdatedRequest = async () => {
    try {
      const result = await api.post(
        `/contract/${contract?.id}/record/${recordId}/request-updates-sent-seller-team/`
      );
      if (result?.data?.message === "Message sent") {
        onCancel();
      }
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const handleButtonClick = (message, pdf, sendTitle) => {
    if (title === "EMAIL") {
      openMail(message);
    } else if (title === "FINALISE") {
      onSend(message, pdf);
    } else if (title === "SEND" && isSellerLawyer) {
      console.log("hrllosend");
      onSend(message, pdf); // Uncomment this line if you want to call onSend here
    }
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="add-a-message-dialog"
      aria-describedby="add-a-message-description"
      fullScreen={isMobile}
    >
      <Box
        width={isMobile ? "100%" : "514px"}
        minHeight="384px"
        display="flex"
        flexDirection="column"
      >
        <Box
          height="62px"
          display="flex"
          flexDirection="row"
          alignItems="center"
          pl="24px"
        >
          <Typography variant="h5" style={{ fontWeight: 700 }}>
            Add a message (optional)
          </Typography>
        </Box>

        <Separator />

        <Box p="18px" display="flex" flexDirection="column">
          <Typography
            variant="body2"
            style={{ fontWeight: 700, marginBottom: "4px" }}
          >
            Message
          </Typography>

          <textarea
            style={{
              minHeight: "271px",
              fontFamily: "GT Walsheim Pro",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              border: "1px solid #E8E8E8",
              padding: "16px",
              flex: 1,
            }}
            value={message}
            onChange={(event) => setMessage(event.target.value)}
          />

          <Typography variant="caption" style={{ marginTop: "4px" }}>
            Add an optional message to appear in the email notification.
          </Typography>
          {forScreen === AddAMessageDialogForScreen.requestworkspace &&
            isBuyerLawyer && (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                mt={4}
              >
                <Typography variant="body2" style={{ fontWeight: 700 }}>
                  Include requests as a PDF attachment?
                </Typography>

                <Switch
                  checked={pdf}
                  // disabled={emailAlreadyFirmMember}
                  onChange={(event) => setPdf(event.target.checked)}
                  color="primary"
                />
              </Box>
            )}
        </Box>

        <Separator />

        <Box
          display={
            userTeamMemberRole === TeamMemberRole.buyerLawyer && title !=="FINALISE" 
              ? "flex"
              : undefined
          }
          // width={
          //   userTeamMemberRole === TeamMemberRole.buyerLawyer&&  title !=="FINALISE" 
          //     ? "100%"
          //     : undefined
          // }
          alignItems={
            userTeamMemberRole === TeamMemberRole.buyerLawyer &&  title !=="FINALISE" 
              ? "center"
              : undefined
          }
          justifyContent={
            userTeamMemberRole === TeamMemberRole.buyerLawyer && title !=="FINALISE" 
              ? "space-between"
              : undefined
          }
          paddingLeft={
            userTeamMemberRole === TeamMemberRole.buyerLawyer && title !=="FINALISE" 
              ? "15px"
              : undefined
          }
          paddingRight={
            userTeamMemberRole === TeamMemberRole.buyerLawyer && title !=="FINALISE" 
              ? "15px"
              : undefined
          }
          
         
       
            
          
        >
          

          <Box
            display="flex"
            justifyContent="flex-end"
            // pl="24px"
            // pr="16px"
            height="74px"
            alignItems="center"
            paddingX="10px"
          >
            <Button
              title="CANCEL"
              size={ButtonSize.medium}
              type={ButtonType.secondary}
              onClick={onCancel}
              width="114px"
            />
            <Box width="8px" />
            <Button
              loading={sending}
              title={title}
              size={ButtonSize.medium}
              type={ButtonType.primary}
              // onClick={() => onSend(message, pdf)}
              onClick={() => handleButtonClick(message, pdf, sendTitle)}
              width="112px"
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
