import React from "react";
import { useMobile } from "../../hooks/mobile";
import { Organisation } from "../../api/models";
import useSWR from "swr";
import {
  Box,
  Drawer,
  Button as MUIButton,
  Typography,
  ButtonBase,
} from "@material-ui/core";
import DropDown from "../../images/menu.png";

import BLUELOGO from "../../images/BlueLogo.png";
import { Colors } from "../../constants/colors";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

interface Props {
  uuid?: string | null;
}

const useStyles = makeStyles({
  paper: {
    background: "#E1EAFF",
  },
});

export default function PartnerPageHeader({ uuid }: Props) {
  const isMobile = useMobile();

  const classes = useStyles();

  const sidePadding = isMobile ? "27px" : "172px";

  const { data: orgData, error: orgError } = useSWR(`/organization/${uuid}/`);

  const organisation = orgData ? (orgData as Organisation) : undefined;

  if (organisation?.redirect === true) {
    // history.replace(`/app/org/${organisation.current_url}`);
    const customurl = `/app/partner/${organisation?.current_url}`;
    window.location.replace(customurl);
  }
  if (orgError) {
    window.location.replace(`/`);
  }

  const [openDrawer, setOpenDrawer] = React.useState(false);

  const Button1 = withStyles({
    root: {
      "&:hover": {
        backgroundColor: Colors.BrandPrimary,
        color: Colors.White,
      },
    },
  })(Button);

  return (
    <>
      {!isMobile && (
        <Box>
          <Box
            width="100%"
            height="76px"
            display="flex"
            flexDirection="row"
            alignItems="center"
            bgcolor="#E1EAFF"
            pt="2px"
          >
            <Box>
              <ButtonBase
                onClick={() => window.location.assign(`/app/partner/${uuid}`)}
              >
                <img
                  src={BLUELOGO}
                  height="32px"
                  style={{ marginLeft: "24px" }}
                />
              </ButtonBase>
            </Box>
            <Box display="flex" flexDirection="row" flex={1} />
            <Box
              textAlign="left"
              mr={3}
              bgcolor={Colors.BrandBackground4}
              borderRadius="6px"
            >
              <Button1
                onClick={() => {
                  window.open("https://contrax.com.au/contact-us", "_blank");
                }}
                style={{
                  color: Colors.BrandBackground4,
                  borderRadius: "6px",
                  width: "170px",
                  height: "34px",
                }}
              >
                <Typography
                  style={{
                    fontSize: "12px",
                    lineHeight: "16px",
                    letterSpacing: "2px",
                    fontWeight: 700,
                    color: Colors.White,
                    textTransform: "uppercase",
                  }}
                >
                  TALK TO AN EXPERT
                </Typography>
              </Button1>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            bgcolor="#FFFFFF"
            pl={sidePadding}
            pr={sidePadding}
            justifyContent="center"
            alignItems="center"
            height="78px"
          >
            <Box
              minHeight="42px"
              minWidth="42px"
              alignItems="center"
              display="flex"
              justifyContent="center"
            >
              {organisation?.logo && (
                <img
                  src={organisation.logo}
                  height="40px"
                  style={{
                    marginLeft: "24px",
                    marginRight: "12px",
                    maxWidth: "80%",
                    objectFit: "contain",
                  }}
                  alt={"Preview of Contrax Logo"}
                />
              )}
            </Box>
            <Box
              width="440px"
              height="58px"
              display="flex"
              alignItems="center"
              justifyContent={organisation?.logo ? "flex-start" : "center"}
            >
              <Typography
                variant="body1"
                style={{ fontWeight: 700, color: Colors.BrandPrimary }}
              >
                {organisation?.name} has invited you to use Contrax
              </Typography>
            </Box>
          </Box>
        </Box>
      )}

      {isMobile && (
        <Box>
          <Box
            width="100%"
            height="72px"
            display="flex"
            flexDirection="row"
            alignItems="center"
            bgcolor="#E1EAFF"
            pt="2px"
          >
            <Box>
              <ButtonBase
                onClick={() => window.location.assign(`/app/partner/${uuid}`)}
              >
                <img
                  src={BLUELOGO}
                  height="32px"
                  style={{ marginLeft: "24px" }}
                />
              </ButtonBase>
            </Box>
            <Box display="flex" flexDirection="column" flex={1} />

            <MUIButton onClick={() => setOpenDrawer(!openDrawer)}>
              <img src={DropDown} />
            </MUIButton>
          </Box>

          <Drawer
            anchor="top"
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            classes={{ paper: classes.paper }}
          >
            <Box m={2} display="flex" justifyContent="center">
              <Box
                textAlign="center"
                bgcolor={Colors.BrandBackground4}
                borderRadius="6px"
                width="170px"
              >
                <Button1
                  onClick={() => {
                    window.open("https://contrax.com.au/contact-us", "_blank");
                  }}
                  style={{
                    color: Colors.BrandBackground4,
                    borderRadius: "6px",
                    width: "170px",
                    height: "34px",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "12px",
                      lineHeight: "16px",
                      letterSpacing: "2px",
                      fontWeight: 700,
                      color: Colors.White,
                      textTransform: "uppercase",
                    }}
                  >
                    TALK TO AN EXPERT
                  </Typography>
                </Button1>
              </Box>
            </Box>
          </Drawer>

          <Box
            display="flex"
            flexDirection="row"
            bgcolor="#FFFFFF"
            pl={sidePadding}
            pr={sidePadding}
            justifyContent="center"
            alignItems="center"
            height="78px"
          >
            <Box
              minHeight="42px"
              minWidth="42px"
              alignItems="center"
              display="flex"
              justifyContent="center"
            >
              {organisation?.logo && (
                <img
                  src={organisation.logo}
                  height="40px"
                  style={{
                    marginLeft: "24px",
                    marginRight: "12px",
                    maxWidth: "80%",
                    objectFit: "contain",
                  }}
                  alt={"Preview of Contrax Logo"}
                />
              )}
            </Box>
            <Box
              width="440px"
              height="58px"
              display="flex"
              alignItems="center"
              justifyContent={organisation?.logo ? "flex-start" : "center"}
              ml={1}
            >
              <Typography
                variant="body1"
                style={{ fontWeight: 700, color: Colors.BrandPrimary }}
              >
                {organisation?.name} has invited you to use Contrax
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
