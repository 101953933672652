export enum Colors {
  Grey1 = "#111111",
  Grey2 = "#313131",
  Grey3 = "#9F9F9F",
  Grey4 = "#E8E8E8",
  Grey5 = "#F4F6F8",
  Grey7 = "#222222",
  Link = "#0038E4",
  Error = "#F95555",
  ErrorBackground = "#FBDBDB",
  BrandPrimary = "#0038E4",
  BrandBackground1 = "#91A8EF",
  BrandBackground = "#1F2D45",
  BrandBackground2 = "#E1EAFF",
  BrandBackground3 = "#ECF2FB",
  BrandBackground4 = "#1F2D45",
  BrandBackground5 = "#F1FFFB",
  WarningBackground = "#FFE4D7",
  LightBackground = "#0235d114",
  SuccessBackground = "#C9EBE2",
  UISuccess = "#1AB183",
  UIError = "#F95555",
  Warning = "#EB9367",
  Success = "#1AB183",
  Pressed = "#002BB0",
  White = "#FFFFFF",
}
