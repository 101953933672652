import React from "react";
import { ButtonBase } from "@material-ui/core";
import WhiteCloseIcon from "../../images/x-white.png";
import BlueCloseIcon from "../../images/x-blue.png";
import CircleCloseIcon from "../../images/x-circle.png";
import BlackCloseIcon from "../../images/x.svg";

export enum CloseButtonColor {
  WHITE,
  BLUE,
  CIRCLE,
  BLACK,
}

interface Props {
  color?: CloseButtonColor;
  onClick: () => unknown;
}
export default function CloseButton({
  color = CloseButtonColor.BLACK,
  onClick,
}: Props): JSX.Element {
  return (
    <ButtonBase
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
    >
      {color === CloseButtonColor.WHITE && <img src={WhiteCloseIcon} />}
      {color === CloseButtonColor.BLUE && <img src={BlueCloseIcon} />}
      {color === CloseButtonColor.CIRCLE && <img src={CircleCloseIcon} />}
      {color === CloseButtonColor.BLACK && <img src={BlackCloseIcon} />}
    </ButtonBase>
  );
}
