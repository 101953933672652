import React from "react";
import { Box, Dialog, Typography, ButtonBase } from "@material-ui/core";
import { useMobile } from "../../hooks/mobile";
import Button, { ButtonType, ButtonSize } from "../common/Button";
import { Colors } from "../../constants/colors";
import CloseButton, { CloseButtonColor } from "../common/CloseButton";

interface Props {
  open: boolean;
  onClose: () => unknown;
  onBuyer: () => unknown;
  onSeller: () => unknown;
}

interface ItemProps {
  title: string;
  description: string;
  actionComponent: React.ReactNode;
}

function Item({ title, description, actionComponent }: ItemProps) {
  return (
    <Box
      borderRadius="8px"
      border="1px solid #91A8EF"
      p="16px"
      width="296px"
      height="270px"
      textAlign="center"
      display="flex"
      flexDirection="column"
      boxSizing="border-box"
    >
      <Typography
        variant="h6"
        style={{
          marginLeft: "16px",
          marginRight: "16px",
          fontWeight: 700,
          height: "24px",
          marginBottom: "30px",
        }}
      >
        {title}
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        flex={1}
      >
        <Typography
          variant="body1"
          style={{ flex: 1, marginLeft: "16px", marginRight: "16px" }}
        >
          {description}
        </Typography>
      </Box>

      {actionComponent}
    </Box>
  );
}

export default function ({ open, onClose, onSeller, onBuyer }: Props) {
  const isMobile = useMobile();

  return (
    <Dialog
      open={open}
      aria-labelledby="help-guide-dialog"
      aria-describedby="help-guide-dialog-description"
      onClose={onClose}
      maxWidth="xl"
      fullScreen={isMobile}
    >
      <Box>
        <Box
          width={isMobile ? "100%" : "664px"}
          height="62px"
          display="flex"
          flexDirection="row"
          pl="24px"
          pr="24px"
          alignItems="center"
          boxShadow="0px 1px 0px #E0E0E0"
          boxSizing="border-box"
          justifyContent="space-between"
        >
          <Typography variant="h5" style={{ fontWeight: 700 }}>
            Who do you represent?
          </Typography>

          <CloseButton color={CloseButtonColor.BLACK} onClick={onClose} />
        </Box>
        <Box
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          p="24px"
          alignItems="center"
        >
          <Item
            title="The Buyer"
            description="Create workspace for legal review and requests management."
            actionComponent={
              <Button
                title="CREATE WORKSPACE"
                type={ButtonType.primary}
                size={ButtonSize.large}
                width="264px"
                height={66}
                onClick={onBuyer}
              />
            }
          />

          <Box width="24px" height="24px" />

          <Item
            title="The Seller"
            description="Upload the contract to share it with the agent and interested buyers."
            actionComponent={
              <Button
                title="UPLOAD CONTRACT"
                type={ButtonType.primary}
                size={ButtonSize.large}
                width="264px"
                height={66}
                onClick={onSeller}
              />
            }
          />
        </Box>
      </Box>
    </Dialog>
  );
}

// <ButtonBase onClick={onBuyer} style={{ width: "264px" }}>
//   <Box
//     bgcolor={Colors.Link}
//     color="white"
//     width="100%"
//     height={"66px"}
//     borderRadius="8px"
//     justifyContent="center"
//     alignItems="center"
//     display="flex"
//   >
//     <Box>
//       <Typography
//         variant="body1"
//         style={{ fontWeight: 700, height: "20px" }}
//       >
//         Purchase Contract Review
//       </Typography>
//       <Box
//         display="flex"
//         flexDirection="row"
//         justifyContent="center"
//         alignItems="center"
//       >
//         <Typography
//           variant="body1"
//           style={{ marginRight: "2px", marginTop: "2px" }}
//         >
//           $
//         </Typography>
//         <Typography variant="h5" style={{ marginRight: "4px" }}>
//           79
//         </Typography>
//         <Typography variant="body2" style={{ fontWeight: 700 }}>
//           Inc. GST
//         </Typography>
//       </Box>
//     </Box>
//   </Box>
// </ButtonBase>
