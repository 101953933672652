import React from "react";
import { Box, ButtonBase } from "@material-ui/core";
import DownloadIcon from "../images/download.svg";

interface Props {
  onClick: () => unknown;
}

export default function DownloadButton({ onClick }: Props) {
  return (
    <ButtonBase onClick={onClick}>
      <img src={DownloadIcon} />
    </ButtonBase>
  );
}
