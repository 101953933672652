import React from "react";
import { Box, Typography, Link } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import AlertWrapper from "../components/AlertWrapper";
import HeroLayout from "../components/HeroLayout";
import TextField from "../components/common/TextField";
import { useAlert } from "../contexts/Alert";
import { useMobile } from "../hooks/mobile";
import Separator from "../components/common/Separator";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import Button, { ButtonType, ButtonSize } from "../components/common/Button";
import { isEmpty } from "lodash-es";
import axios, { AxiosError } from "axios";
import api from "../api/instance";

const noAuthApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export default function ResetPasswordRequest(): JSX.Element {
  const location = useLocation();
  const history = useHistory();
  const isMobile = useMobile();

  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState<string | null>(null);

  const [loading, setLoading] = React.useState(false);

  const { setErrorMessage, setSuccessMessage } = useAlert();

  function saveClicked() {
    if (loading) {
      return;
    }

    let fieldsValid = true;

    if (isEmpty(email)) {
      setEmailError("Please enter your email");
      fieldsValid = false;
    }

    if (fieldsValid) {
      setLoading(true);

      noAuthApi
        .post("/request-reset/", {
          email,
        })
        .then((response) => {
          history.push("/login?resetPassword=true");
        })
        .catch((error: AxiosError) => {
          setLoading(false);

          setErrorMessage(
            "We're sorry. We weren't able to identify you given the information provided"
          );
        });
    }
  }

  return (
    <AlertWrapper>
      <HeroLayout>
        <Box
          mt={isMobile ? 4 : 25}
          ml={3.25}
          mr={3.25}
          width={isMobile ? "323px" : "353px"}
          marginLeft="auto"
          marginRight="auto"
          alignItems={isMobile ? "center" : undefined}
        >
          <Typography variant={isMobile ? "h5" : "h4"}>
            Reset your password
          </Typography>
          <Box mt={4} />
          <TextField
            title="Please enter your email"
            type="email"
            value={email}
            error={emailError !== null}
            helperText={emailError}
            onChange={(value) => setEmail(value)}
          />
          <Box mt={3} />
          {isMobile && <Separator mb={2} />}
        </Box>
        {!isMobile && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            mt={25}
          >
            <Button
              type={ButtonType.primary}
              size={ButtonSize.large}
              title="SEND RECOVERY LINK"
              width={"250px"}
              loading={loading}
              onClick={() => saveClicked()}
            />
          </Box>
        )}
      </HeroLayout>
      {isMobile && (
        <MobileStickyFooter
          buttonsLayout={FooterButtonsLayout.sideBySide}
          leftButtonTitle="CANCEL"
          leftButtonOnClick={() => history.goBack()}
          rightButtonTitle="SEND RECOVERY LINK"
          rightButtonLoading={loading}
          rightButtonOnClick={() => saveClicked()}
        />
      )}
    </AlertWrapper>
  );
}
