import React from "react";
import { Colors } from "../../constants/colors";
import Radio from "../common/Radio";
import { Box, Typography } from "@material-ui/core";

interface RadioWithTitleProps {
  title: string;
  selected: boolean;
  onSelected: () => unknown;
  onUnselected?: () => unknown;
  disabled?: boolean;
}

export default function RadioWithTitle({
  title,
  selected,
  onSelected,
  onUnselected,
  disabled = false,
}: RadioWithTitleProps) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      color={Colors.Grey2}
      alignItems="center"
    >
      <Radio
        checked={selected}
        onClick={(event) => {
          if (selected) {
            onUnselected && onUnselected();
          } else {
            onSelected();
          }
        }}
        value={selected}
        disabled={disabled}
      />
      <Typography variant="body1">{title}</Typography>
    </Box>
  );
}
