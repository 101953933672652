import { Box, Dialog, Typography } from "@material-ui/core";
import { isEmpty, isNil } from "lodash-es";
import moment from "moment";
import React from "react";
import {
  BuyerSellerStatus,
  Enquiry,
  EnquiryStatus,
  EnquiryStage,
  isRequestBuyerRespondedAndPublished,
} from "../../api/models";
import { Colors } from "../../constants/colors";
import { useAlert } from "../../contexts/Alert";
import { useMobile } from "../../hooks/mobile";
import Button, { ButtonSize, ButtonType } from "../common/Button";
import Separator from "../common/Separator";
import StatusPill, { statusPillTypeForEnquiry } from "../StatusPill";
import api from "../../api/instance";
import { parseApiError } from "../../helpers/error";
import YouHaveUnsavedChangesDialog from "./YouHaveUnsavedChangesDialog";

interface Props {
  open: boolean;
  contractId: number | null;
  recordId: number | null;
  enquiry: Enquiry | null;
  onClose: () => unknown;
  onSaved: () => unknown;
}

export default function RespondRequestDialog({
  open,
  contractId,
  recordId,
  enquiry,
  onClose,
  onSaved,
}: Props) {
  const isMobile = useMobile();

  const [answer, setAnswer] = React.useState("");

  const [saving, setSaving] = React.useState(false);
  const [unsavedDialogOpen, setUnsavedDialogOpen] = React.useState(false);
  const [edited, setEdited] = React.useState(false);

  const isSent = !isNil(enquiry?.sent_on);
  const isPublished = enquiry?.status === EnquiryStatus.Published;

  const { setErrorMessage } = useAlert();

  React.useEffect(() => {
    resetFields();
  }, [enquiry]);

  const resetFields = () => {
    if (enquiry) {
      setAnswer(enquiry.answer_to_enquiry);
      setEdited(false);
    }
  };

  const sentOrCompleted =
    enquiry?.seller_status === BuyerSellerStatus.Sent ||
    enquiry?.seller_status === BuyerSellerStatus.Completed;

  const onSave = () => {
    if (enquiry) {
      setSaving(true);

      const { buyer_enquiry_pk, answer_to_enquiry, seller_status, index } =
        enquiry;

      const responded = !isEmpty(answer);

      const data = [
        {
          pk: buyer_enquiry_pk,
          answer_to_enquiry: answer,
          seller_status: responded
            ? EnquiryStatus.Published
            : EnquiryStatus.Editing,
          status: responded ? EnquiryStage.Answered : EnquiryStage.Pending,
          seller_responded: responded,
          index,
          type: "Enquiry",
        },
      ];

      api
        .post(`/contract/${contractId}/record/${recordId}/seller-response/`, {
          data,
        })
        .then((response) => {
          setSaving(false);

          onSaved();
        })
        .catch((error) => {
          setSaving(false);

          setErrorMessage(parseApiError(error));
        });
    }
  };

  const p_onClose = () => {
    if (sentOrCompleted || !edited) {
      resetFields();
      onClose();
    } else {
      setUnsavedDialogOpen(true);
    }
  };

  return (
    enquiry && (
      <>
        <Dialog
          open={open}
          aria-labelledby="add-a-message-dialog"
          aria-describedby="add-a-message-description"
          fullScreen={isMobile}
          onClose={p_onClose}
        >
          <Box
            width={isMobile ? "100%" : "524px"}
            display="flex"
            flexDirection="column"
          >
            <Box
              height="62px"
              display="flex"
              flexDirection="row"
              alignItems="center"
              pl="24px"
              pr="24px"
            >
              <Typography variant="h5" style={{ fontWeight: 700, flex: 1 }}>
                {enquiry.title}
              </Typography>

              <StatusPill type={statusPillTypeForEnquiry(enquiry)} />
            </Box>

            <Separator />

            <Box p="16px">
              <Box p="8px" mb="16px">
                <Typography
                  variant="body1"
                  style={{ fontWeight: 700, marginBottom: "8px" }}
                >
                  General Enquiry
                </Typography>
                <Typography variant="body2">{enquiry.enquiry}</Typography>
              </Box>

              <Box
                marginTop={isMobile ? "8px" : "24px"}
                marginBottom="16px"
                display="flex"
                flexDirection="column"
                flex={1}
              >
                <Typography
                  variant="body1"
                  style={{ fontWeight: 700, marginBottom: "8px" }}
                >
                  Response to enquiry
                </Typography>
                <Typography
                  variant="body2"
                  style={{ fontWeight: 700, marginBottom: "4px" }}
                >
                  Answer to enquiry
                </Typography>

                <textarea
                  style={{
                    minHeight: "74px",
                    fontFamily: "GT Walsheim Pro",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                    border: "1px solid #E8E8E8",
                    padding: "16px",
                    flex: 1,
                  }}
                  value={answer}
                  onChange={(event) => {
                    setAnswer(event.target.value);
                    setEdited(true);
                  }}
                  disabled={sentOrCompleted}
                />
              </Box>
            </Box>

            <Separator />

            <Box display="flex" flexDirection="row" alignItems="center">
              <Box flex={1} pl="24px">
                {isSent && enquiry && (
                  <Typography
                    variant="body2"
                    style={{ color: Colors.Grey3, marginRight: "16px" }}
                  >
                    Sent on{" "}
                    {moment(enquiry.sent_on).format("D MMM [at] h:mm A")}
                  </Typography>
                )}
                {!isSent && isPublished && (
                  <Typography
                    variant="body2"
                    style={{ color: Colors.Grey3, marginRight: "16px" }}
                  >
                    Not sent
                  </Typography>
                )}
                {!isSent && !isPublished && (
                  <Typography
                    variant="body2"
                    style={{ color: Colors.Grey3, marginRight: "16px" }}
                  >
                    Outstanding
                  </Typography>
                )}
              </Box>

              <Box
                display="flex"
                justifyContent="flex-end"
                pl="24px"
                pr="16px"
                height="74px"
                alignItems="center"
              >
                <Button
                  title="CANCEL"
                  size={ButtonSize.medium}
                  type={ButtonType.secondary}
                  onClick={p_onClose}
                  width="114px"
                />
                <Box width="8px" />
                <Button
                  loading={saving}
                  disabled={sentOrCompleted}
                  title="SAVE"
                  size={ButtonSize.medium}
                  type={ButtonType.primary}
                  onClick={onSave}
                  width="79px"
                />
              </Box>
            </Box>
          </Box>
        </Dialog>
        <YouHaveUnsavedChangesDialog
          open={unsavedDialogOpen}
          onExit={() => {
            setUnsavedDialogOpen(false);
            resetFields();
            onClose();
          }}
          onSave={() => {
            setUnsavedDialogOpen(false);
            onSave();
          }}
          onClose={() => setUnsavedDialogOpen(false)}
        />
      </>
    )
  );
}
