import { PDFDownloadLink } from "@react-pdf/renderer";
import React from "react";
import Button, { ButtonSize, ButtonType } from "../common/Button";
import InvoiceDocument from "./InvoiceDocument";
import { useMobile } from "../../hooks/mobile";
import { Order } from "../../api/models";

interface Props {
  order: Order;
}

export default function DownloadInvoiceButton({ order }: Props) {
  const isMobile = useMobile();

  const filename = () => {
    // TODO
    return "contrax_invoice.pdf";
  };

  return (
    <div
    // style={{
    //   pointerEvents: loadingRequests || loadingProperty ? "none" : undefined,
    // }}
    >
      <PDFDownloadLink
        document={<InvoiceDocument order={order} />}
        fileName={filename()}
        style={{ textDecoration: "none" }}
      >
        {({ blob, url, loading, error }) => {
          return (
            <Button
              loading={loading}
              title={"DOWNLOAD INVOICE"}
              type={ButtonType.primary}
              size={ButtonSize.medium}
              width={isMobile ? "100%" : "212px"}
            />
          );
        }}
      </PDFDownloadLink>
    </div>
  );
}
