import React from "react";
import {
  Box,
  Typography,
  ButtonBase,
  IconButton,
  Menu,
  MenuItem,
  withStyles,
} from "@material-ui/core";
import Placeholder from "../../images/dashboard-property-placeholder.svg";
import { Colors } from "../../constants/colors";
import { useMobile } from "../../hooks/mobile";
import { DashboardContract, MyTeamMember } from "../../api/models";
import { useSessionStore } from "../../stores/Session";
import { User, UserType } from "../../api/models";
import pluralize from "pluralize";
import Button, { ButtonType, ButtonSize } from "../../components/common/Button";
import InviteUserIcon from "../../images/invite-user-icon.svg";
import EditTeamAccountDetails from "./EditMyTeamDetailsModal";
import AlertWrapper from "../../components/AlertWrapper";
import StatusPill, { StatusPillType } from "../StatusPill";

interface Props {
  teamData: MyTeamMember;
  onUpdated: () => unknown;
}

export default function DashboardItem({ teamData, onUpdated }: Props) {
  const isMobile = useMobile();

  //   const { address } = contract.listing;

  //   const image = contract.listing.image || Placeholder;

  const { data: userData } = useSessionStore();
  const user = userData && (userData as User);

  const [editTeamMemberDialogOpen, setEditTeamMemberDialogOpen] =
    React.useState(false);

  const onEditTeamMember = () => {
    setEditTeamMemberDialogOpen(true);
  };

  // const editButtonHandle = () => {
  //   console.log("button clicked");
  // };

  return (
    <>
      <Box
        style={{
          width: isMobile ? "95vw" : "1095px",
          height: isMobile ? "92px" : "112px",
          marginTop: "4px",
          marginBottom: "4px",
        }}
      >
        <Box
          display="flex"
          height="100%"
          flexDirection="row"
          bgcolor="white"
          flex={1}
          border="1px solid #ECF2FB"
          borderRadius="4px"
          alignItems="center"
          pl={isMobile ? "8px" : "24px"}
          pr={isMobile ? "8px" : "24px"}
        >
          <Box
            width={isMobile ? "30px" : "60px"}
            height={isMobile ? "30px" : "60px"}
            bgcolor="#E8E8E8"
            borderRadius="4px"
            textAlign="center"
          >
            <img
              src={InviteUserIcon}
              width={isMobile ? "20px" : "30px"}
              height={isMobile ? "20px" : "30px"}
              style={{
                objectFit: "cover",
                position: "relative",
                top: "calc(50% - 12px)",
              }}
              alt="Error loading"
            />
          </Box>
          <Box
            flex={1}
            textAlign="left"
            ml={isMobile ? "15px" : "24px"}
            mr={isMobile ? "15px" : "24px"}
            style={{ wordBreak: "break-word" }}
          >
            <Typography variant={isMobile ? "body2" : "h6"}>
              {teamData.first_name} {teamData.last_name}
            </Typography>
            <Typography variant={isMobile ? "body2" : "h6"}>
              {teamData.email}
            </Typography>
          </Box>

          {/* {(isLawyer || isAgent) && ( */}
          {teamData.invite_status === "Pending" && !isMobile && (
            <Box>
              {/* <Typography variant={isMobile ? "body2" : "h6"}>
                    {teamData.invite_status}
                  </Typography> */}
              <StatusPill type={StatusPillType.pending} />
            </Box>
          )}

          <Box mr={2} />
          {!isMobile && (
            <Button
              type={ButtonType.secondary}
              size={ButtonSize.medium}
              title="EDIT"
              onClick={onEditTeamMember}
            />
          )}
          {isMobile && (
            <Button
              type={ButtonType.secondary}
              size={ButtonSize.small}
              width="80px"
              title="EDIT"
              onClick={onEditTeamMember}
            />
          )}
        </Box>
      </Box>
      <EditTeamAccountDetails
        open={editTeamMemberDialogOpen}
        onSaved={() => {
          setEditTeamMemberDialogOpen(false);
          onUpdated();
        }}
        onDeleted={() => {
          setEditTeamMemberDialogOpen(false);
          onUpdated();
        }}
        onClose={() => setEditTeamMemberDialogOpen(false)}
        editTeamMember={teamData}
        setEditTeamMemberDialogOpen={setEditTeamMemberDialogOpen}
      />
    </>
  );
}
