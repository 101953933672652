import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import { Activity, ActivityAction } from "../../api/models";
import AgreedIcon from "../../images/activity-agreed.svg";
import ContractIcon from "../../images/activity-contract.svg";
import UserIcon from "../../images/activity-user.svg";
import RespondedIcon from "../../images/activity-responded.svg";
import NotProceedingIcon from "../../images/activity-not-proceeding.svg";
import moment from "moment";

interface Props {
  activity: Activity;
}
/*  Joined,
  Published,
  Request,
  Respond,
  Agree,
  Paused,
  Cancel,*/
export default function ({ activity }: Props) {
  const _icon = () => {
    switch (activity.action) {
      case ActivityAction.Agree:
      case ActivityAction.ReviewRequest:
        return AgreedIcon;

      case ActivityAction.Published:
        return ContractIcon;

      case ActivityAction.Pause:
      case ActivityAction.Cancel:
        return NotProceedingIcon;

      case ActivityAction.Request:
      case ActivityAction.Respond:
      case ActivityAction.UpdatedRequest:
      case ActivityAction.UpdatedResponse:
        return RespondedIcon;

      case ActivityAction.Joined:
        return UserIcon;
    }
  };

  const _action = () => {
    switch (activity.action) {
      case ActivityAction.Agree:
        return "agreed to proceed";

      case ActivityAction.Published:
        return "finalised the legal review";

      case ActivityAction.Joined:
        return "joined the team";

      case ActivityAction.Request:
        return "sent requests";

      case ActivityAction.Respond:
        return "responded to requests";

      case ActivityAction.Pause:
        return "does not want to proceed";

      case ActivityAction.ReviewRequest:
        return "reviewed requests";

      case ActivityAction.UpdatedRequest:
        return "sent updated requests";

      case ActivityAction.UpdatedResponse:
        return "responded to requests";

      case ActivityAction.Cancel:
        return "cancelled";
    }
  };

  const _name = () => {
    return `${activity.user.first_name} ${activity.user.last_name}`;
  };

  const _date = () => {
    const format = "Do [of] MMMM YYYY";

    return moment(activity.created).format(format);
  };

  const icon = _icon();
  const action = _action();
  const name = _name();
  const date = _date();

  return (
    <Box display="flex" flexDirection="row" position="relative" mb="16px">
      <Box
        display="flex"
        flexDirection="row"
        width="13px"
        height="13px"
        borderRadius="6.5px"
        bgcolor={Colors.Grey4}
        position="absolute"
        left="-8px"
      />

      <Box width="20px" height="20px" ml="15px">
        <img src={icon} />
      </Box>

      <Box display="flex" flexDirection="column" ml="10px">
        <Typography
          variant="body2"
          style={{ fontWeight: 700, lineHeight: "18px" }}
        >
          {name}
        </Typography>
        <Typography
          variant="body2"
          style={{ fontWeight: 400, lineHeight: "20px" }}
        >
          {action}
        </Typography>
        <Typography
          variant="caption"
          style={{ color: Colors.Grey3, marginTop: "2px" }}
        >
          {date}
        </Typography>
      </Box>
    </Box>
  );
}
