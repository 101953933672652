import React from "react";
import { Box, Typography, Link } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import HeroLayout from "../components/HeroLayout";
import { useMobile } from "../hooks/mobile";
import AlertWrapper from "../components/AlertWrapper";
import AuthenticatedWrapper from "../components/AuthenticatedWrapper";
import Checkbox from "../components/common/Checkbox";
import { Colors } from "../constants/colors";
import Button, { ButtonType, ButtonSize } from "../components/common/Button";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";

enum ContractStatusOption {
  Sold,
  Delete,
}

export default function UpdateContract(): JSX.Element {
  const isMobile = useMobile();
  const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const propertyId = query.get("propertyId");

  const [selectedOption, setSelectedOption] =
    React.useState<ContractStatusOption>(ContractStatusOption.Sold);

  function nextClicked() {
    const action =
      selectedOption === ContractStatusOption.Sold ? "sold" : "delete";

    history.push(
      `/update-contract-confirm?propertyId=${propertyId}&action=${action}`
    );
  }

  return (
    <AlertWrapper>
      <AuthenticatedWrapper>
        <HeroLayout>
          <Box mt={isMobile ? 5 : 22} ml={3} mr={3}>
            <Typography variant="h5">
              We can help you update this contract
            </Typography>

            <Typography
              variant="body2"
              style={{ fontWeight: 700, marginTop: "32px" }}
            >
              Update contract status
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              mt={1.5}
            >
              <Checkbox
                style={{ color: Colors.Grey2 }}
                checked={selectedOption === ContractStatusOption.Sold}
                onChange={(event) => {
                  setSelectedOption(ContractStatusOption.Sold);
                }}
              />
              <Typography variant="body1">Sold</Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              mt={1.5}
            >
              <Checkbox
                style={{ color: Colors.Grey2 }}
                checked={selectedOption === ContractStatusOption.Delete}
                onChange={(event) => {
                  setSelectedOption(ContractStatusOption.Delete);
                }}
              />
              <Typography variant="body1">Delete</Typography>
            </Box>
            <Typography variant="body1" style={{ marginTop: "23px" }}>
              For help changing anything else about this property, please get in
              touch with us{" "}
              <Link
                style={{
                  color: Colors.Link,
                  fontWeight: 700,
                  cursor: "pointer",
                }}
                onClick={() => {
                  history.push("/agent/help");
                }}
              >
                here
              </Link>
              .
            </Typography>
            {!isMobile && (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                mt={5}
              >
                <Button
                  type={ButtonType.secondary}
                  size={ButtonSize.large}
                  title="CANCEL"
                  onClick={() => history.goBack()}
                />
                <Box ml={2} />
                <Button
                  type={ButtonType.primary}
                  size={ButtonSize.large}
                  title="NEXT"
                  onClick={() => nextClicked()}
                />
              </Box>
            )}
            {isMobile && (
              <MobileStickyFooter
                buttonsLayout={FooterButtonsLayout.sideBySide}
                leftButtonTitle="CANCEL"
                leftButtonOnClick={() => history.goBack()}
                rightButtonTitle="NEXT"
                rightButtonOnClick={() => nextClicked()}
              />
            )}
          </Box>
        </HeroLayout>
      </AuthenticatedWrapper>
    </AlertWrapper>
  );
}
