import React from "react";
import { Box, Dialog, Typography, DialogContent } from "@material-ui/core";
import { useMobile } from "../../hooks/mobile";
import { Colors } from "../../constants/colors";
import CloseButton from "../common/CloseButton";

interface Props {
  open: boolean;
  items: React.ReactNode;
  title: string;
  onClose: () => unknown;
}

export default function ViewAccentColorModal({ open, items, title, onClose }: Props) {
  const isMobile = useMobile();

  return (
    <Dialog
      open={open}
      aria-labelledby="help-guide-dialog"
      aria-describedby="help-guide-dialog-description"
      fullScreen={isMobile}
      maxWidth={"xl"}
      onClose={onClose}
    >
      <Box p="32px" bgcolor={Colors.BrandBackground3}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" style={{ fontWeight: 700 }}>
              {title}
            </Typography>
            <CloseButton onClick={onClose} />
          </Box>

          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            mt="16px"
          >
            {items}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
