import { Box, Typography } from "@material-ui/core";
import { debounce, isNil } from "lodash-es";
import moment from "moment";
import React from "react";
import {
  BuyerSellerStatus,
  Enquiry,
  EnquiryStage,
  EnquiryStatus,
} from "../../api/models";
import { Colors } from "../../constants/colors";
import { debounceDelay } from "../../constants/common";
import { useMobile } from "../../hooks/mobile";
import Button, { ButtonSize, ButtonType } from "../common/Button";
import StatusPill, { statusPillTypeForEnquiry } from "../StatusPill";

interface Props {
  enquiry: Enquiry;
  index: number;
  onAnswerChanged: (answer: string) => unknown;
  onEdit: () => unknown;
  onSave: (answer: string) => unknown;
  onViewComments: () => unknown;
  actionsEnabled: boolean;
}

interface HeaderProps {
  enquiry: Enquiry;
}

function Header({ enquiry }: HeaderProps) {
  const isMobile = useMobile();
  const statusPillType = statusPillTypeForEnquiry(enquiry);

  if (isMobile) {
    return (
      <Box
        boxShadow="0px 1px 0px #E0E0E0"
        display="flex"
        flexDirection="column"
        pl="16px"
        pr="16px"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          mt="16px"
          mb="16px"
        >
          <Box display="flex" flexDirection="row" alignItems="center" flex={1}>
            <Typography
              variant={isMobile ? "body1" : "h6"}
              style={{
                fontWeight: 700,
              }}
            >
              {enquiry.title}
            </Typography>
          </Box>

          <StatusPill type={statusPillType} />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      flex={1}
      pl="24px"
      pr="24px"
      boxShadow="0px 1px 0px #E0E0E0"
      minHeight="77px"
    >
      <Box display="flex" flexDirection="row" alignItems="center" flex={1}>
        <Typography variant="h6" style={{ fontWeight: 700, marginLeft: "8px" }}>
          {enquiry.title}
        </Typography>
      </Box>

      <StatusPill type={statusPillType} />
    </Box>
  );
}

interface ContentProps {
  enquiry: Enquiry;
  answer: string;
  onAnswer: (answer: string) => unknown;
}

function Content({ enquiry, answer, onAnswer }: ContentProps) {
  const isMobile = useMobile();
  const titleVariant = isMobile ? "body2" : "body1";

  return (
    <Box
      display="flex"
      flexDirection={isMobile ? "column" : "row"}
      flex={1}
      alignItems={isMobile ? "center" : undefined}
    >
      <Box
        display="flex"
        flexDirection="column"
        width={isMobile ? "90%" : "483px"}
        m={isMobile ? undefined : "24px"}
      >
        <Box
          // p={isMobile ? undefined : "8px"}
          mb="16px"
          mt={isMobile ? "16px" : undefined}
        >
          <Typography variant={titleVariant} style={{ fontWeight: 700 }}>
            General Enquiry
          </Typography>
          <Typography variant="body2">{enquiry.enquiry}</Typography>
        </Box>
      </Box>
      {enquiry.status === EnquiryStatus.Editing && (
        <Box
          display="flex"
          flexDirection="column"
          width={isMobile ? "90%" : "515px"}
        >
          {!isMobile && (
            <Typography
              variant={titleVariant}
              style={{
                fontWeight: 700,
                marginTop: "24px",
              }}
            >
              Response to enquiry
            </Typography>
          )}

          <Box
            marginTop={isMobile ? "8px" : "24px"}
            marginBottom="16px"
            display="flex"
            flexDirection="column"
            flex={1}
          >
            <Typography
              variant="body2"
              style={{ fontWeight: 700, marginBottom: "4px" }}
            >
              Answer to enquiry
            </Typography>

            <textarea
              style={{
                minHeight: "74px",
                fontFamily: "GT Walsheim Pro",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
                border: "1px solid #E8E8E8",
                padding: "16px",
                flex: 1,
              }}
              value={answer}
              onChange={(event) => onAnswer(event.target.value)}
            />
          </Box>
        </Box>
      )}

      {enquiry.status === EnquiryStatus.Published && (
        <Box
          p="18px"
          display="flex"
          flexDirection="column"
          width={isMobile ? "90%" : "515px"}
        >
          <Box marginBottom="32px" marginTop="14px" whiteSpace="pre-line">
            <Typography
              variant="body2"
              style={{ fontWeight: 700, marginBottom: "4px" }}
            >
              Answer
            </Typography>

            <Typography variant="body2">{enquiry.answer_to_enquiry}</Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}

interface FooterProps {
  enquiry: Enquiry;
  onViewComments: () => unknown;
  onEdit: () => unknown;
  onSave: () => unknown;
  actionsEnabled: boolean;
}

function Footer({
  enquiry,
  onViewComments,
  onEdit,
  onSave,
  actionsEnabled,
}: FooterProps) {
  const isMobile = useMobile();

  const _commentsCount = () => {
    if (enquiry.p_commentsCount > 0) {
      return enquiry.p_commentsCount;
    }

    return enquiry.comments_count || 0;
  };

  const _messagesCount = () => {
    if (enquiry.p_messagesCount > 0) {
      return enquiry.p_messagesCount;
    }

    return enquiry.messages_count || 0;
  };

  const commentsCount = _commentsCount();
  const messagesCount = _messagesCount();

  const editButtonEnabled = () => {
    if (enquiry.enquiry_status === EnquiryStage.Answered) {
      return enquiry.seller_status !== BuyerSellerStatus.Completed;
    }

    return (
      enquiry.status === EnquiryStatus.Published &&
      enquiry.enquiry_status !== EnquiryStage.Deleted
    );
  };

  const isSent = !isNil(enquiry.sent_on);

  return (
    <Box
      display="flex"
      flexDirection="row"
      boxShadow="0px -1px 0px #E0E0E0"
      height="74px"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="row" alignItems="center" pl="12px">
        <Button
          title={isMobile ? "COMMENTS" : "VIEW COMMENTS"}
          type={ButtonType.transparent}
          size={ButtonSize.medium}
          onClick={onViewComments}
          letterSpacing="2px"
          width={isMobile ? "115px" : "179px"}
        />
        {!isMobile && (
          <Typography variant="body2" style={{ marginLeft: "8px" }}>
            {commentsCount + messagesCount} Comments
          </Typography>
        )}
      </Box>

      <Box display="flex" flexDirection="row" alignItems="center" pr="16px">
        {!isMobile && isSent && (
          <Typography
            variant="body2"
            style={{ color: Colors.Grey3, marginRight: "16px" }}
          >
            Sent on {moment(enquiry.sent_on).format("D MMM [at] h:mm A")}
          </Typography>
        )}

        {!isMobile && !isSent && (
          <Typography
            variant="body2"
            style={{ color: Colors.Grey3, marginRight: "16px" }}
          >
            Not sent
          </Typography>
        )}

        {actionsEnabled && editButtonEnabled() && (
          <Button
            type={ButtonType.secondary}
            size={ButtonSize.medium}
            title="EDIT"
            width="84px"
            onClick={onEdit}
          />
        )}

        {actionsEnabled && enquiry.status === EnquiryStatus.Editing && (
          <Button
            type={ButtonType.primary}
            size={ButtonSize.medium}
            title="SAVE"
            width="79px"
            onClick={onSave}
          />
        )}
      </Box>
    </Box>
  );
}

export default function EnquiryItem({
  enquiry,
  index,
  onAnswerChanged,
  onEdit,
  onSave,
  onViewComments,
  actionsEnabled = true,
}: Props) {
  const isMobile = useMobile();

  const [answer, setAnswer] = React.useState(enquiry.answer_to_enquiry);

  const answerChanged = (text: string) => {
    onAnswerChanged && onAnswerChanged(text);
  };

  const debouncedAnswerChanged = React.useCallback(
    debounce(answerChanged, debounceDelay),
    []
  );

  const onAnswer = (text) => {
    setAnswer(text);

    debouncedAnswerChanged(text);
  };

  const isDeleted = enquiry?.enquiry_status === EnquiryStage.Deleted;

  return (
    <Box
      border="1px solid #E1EAFF"
      bgcolor="white"
      borderRadius="16px"
      m="10px"
      display="flex"
      flexDirection="column"
      width={isMobile ? "95vw" : "1097px"}
      color={isDeleted ? Colors.Grey3 : undefined}
    >
      <Header enquiry={enquiry} />

      <Box display="flex" flexDirection="row">
        <Content enquiry={enquiry} answer={answer} onAnswer={onAnswer} />
      </Box>

      <Footer
        enquiry={enquiry}
        onViewComments={onViewComments}
        onEdit={onEdit}
        onSave={() => onSave(answer)}
        actionsEnabled={actionsEnabled}
      />
    </Box>
  );
}
