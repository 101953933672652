import React from "react";
import { Box, ButtonBase } from "@material-ui/core";
import BackButtonIcon from "../../images/back-mobile.svg";

interface Props {
  onClick: () => unknown;
}

export default function BackButtonMobile({ onClick }: Props) {
  return (
    <ButtonBase onClick={onClick}>
      <img src={BackButtonIcon} />
    </ButtonBase>
  );
}
