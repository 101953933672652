import React from "react";
import { Box, Link, Typography } from "@material-ui/core";
import DashboardHeaderDesktop from "../components/DashboardHeaderDesktop";
import { useMobile, useDesktop } from "../hooks/mobile";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import DashboardHeaderMobile from "../components/DashboardHeaderMobile";
import Button, { ButtonType, ButtonSize } from "../components/common/Button";
import { get } from "lodash-es";
import api from "../api/instance";
import ConfirmationIcon from "../images/Confirmation.png";
import { useLocation } from "react-router-dom";

export default function PropertyNotReviewedSuccess(): JSX.Element {
  const isMobile = useMobile();
  const location = useLocation();

  const locationState = location && location.state;
  const name = get(locationState, "name");

  function closeClicked() {
    window.location.assign(window.location.origin);
  }

  return (
    <Box>
      {isMobile && <DashboardHeaderMobile />}

      {!isMobile && <DashboardHeaderDesktop />}

      <Box
        maxWidth={isMobile ? "375px" : "450px"}
        marginLeft="auto"
        marginRight="auto"
        pt={isMobile ? "16px" : "38px"}
      >
        <img src={ConfirmationIcon} />
        <Box mt={2.875} />
        <Typography variant="h4">Thanks {name}</Typography>
        <Box mt={2} />
        <Typography variant="body1">
          We have received your request and will get back to you within 2
          business days.
        </Typography>
        <Box mt={3.75} />

        {!isMobile && (
          <Button
            type={ButtonType.primary}
            size={ButtonSize.large}
            title="CLOSE"
            onClick={() => closeClicked()}
          />
        )}
        {isMobile && (
          <MobileStickyFooter
            buttonsLayout={FooterButtonsLayout.centered}
            buttonTitle="CLOSE"
            buttonOnClick={() => closeClicked()}
          />
        )}
      </Box>
    </Box>
  );
}
