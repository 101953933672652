import { Box, Typography } from "@material-ui/core";
import { loadStripe } from "@stripe/stripe-js";
import { isNil } from "lodash-es";
import React from "react";
import { useLocation } from "react-router-dom";
import useSWR from "swr";
import {
  Activity,
  addressForProperty,
  Contract,
  Property,
  TeamMemberRole,
  User,
} from "../api/models";
import ActivityLog from "../components/ActivityLog/ActivityLog";
import AlertWrapper from "../components/AlertWrapper";
import LegalReviewProgressBar from "../components/LegalReviewProgressBar";
import InviteModal from "../components/LegalReviewViewer/InviteModal";
import InviteYourTeamMembersModal, {
  InviteYourTeamMembersModalForScreen,
  InviteYourTeamMembersModalType,
} from "../components/LegalReviewViewer/InviteYourTeamMembersModal";
import LegalReviewWhiteHeader from "../components/LegalReviewWhiteHeader";
import OnboardingWrapper from "../components/OnboardingWrapper";
import { useAlert } from "../contexts/Alert";
import { useMobile } from "../hooks/mobile";
import Hero from "../images/lawyer-hero.svg";
import { refreshContractTeamsAndRole } from "../stores/contract";
import LoginModal from "../components/Invited/LoginModal";
import SignUpModal from "../components/Invited/SignUpModal";
import { useSessionStore } from "../stores/Session";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";
import JoinWorkspaceModal from "../components/JoinWorkspace/JoinWorkspaceModal";
import InviteModalReadOnly, {
  InviteModalType,
} from "../components/LegalReviewViewer/InviteModalReadOnly";

interface ContentProps {
  contractId: number;
  recordId: number;
  property?: Property;
}

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_KEY ||
    "pk_test_51H7XqhIXwp5ovB54AxG4YgHyGzIBhm8hUdlekd8jNoSTF4hMNqBDSYesY3AlUIrpZPIDWH3Tb5GzjwheatvbOby7009gtMi85E"
);

function Content({ contractId, recordId, property }: ContentProps) {
  const isMobile = useMobile();
  const { setSuccessMessage } = useAlert();

  const [joinWorkspaceModalOpen, setJoinWorkspaceModalOpen] =
    React.useState(false);

  const onJoinWorkspace = () => {
    setJoinWorkspaceModalOpen(true);
  };

  return (
    <Box
      display="flex"
      flex={1}
      justifyContent="center"
      alignItems="center"
      height="80vh"
    >
      <Box
        width={isMobile ? "90%" : "385px"}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {!isMobile && (
          <LegalReviewProgressBar contractId={contractId} recordId={recordId} />
        )}

        <img
          src={Hero}
          style={{ marginTop: "16px", marginBottom: "10px" }}
          height={isMobile ? "200px" : undefined}
        />

        <Typography
          variant="h6"
          style={{ fontWeight: 700, textAlign: "center" }}
        >
          Join this workspace
        </Typography>
        <Typography
          variant="body2"
          style={{
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "27px",
          }}
        >
          Start collaborating with your team on this property
        </Typography>
        <Box mt={1}>
          <Button
            type={ButtonType.primary}
            size={ButtonSize.large}
            title="JOIN WORKSPACE"
            width={isMobile ? "90%" : "301px"}
            onClick={onJoinWorkspace}
          />
        </Box>
      </Box>
      <JoinWorkspaceModal
        open={joinWorkspaceModalOpen}
        onClose={() => setJoinWorkspaceModalOpen(false)}
        onConfirmation={() => {
          setJoinWorkspaceModalOpen(false);
          setSuccessMessage("You successfully joined this workspace");
        }}
        property={property}
      />
    </Box>
  );
}

// interface Props {
//   onLoggedIn: () => unknown;
//   onSignedUp: () => unknown;
// }

export default function JoinWorkspace() {
  const isMobile = useMobile();

  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const _contractId = query.get("contractId");
  const contractId = !isNil(_contractId) ? parseInt(_contractId) : null;
  const _recordId = query.get("recordId");
  const recordId = !isNil(_recordId) ? parseInt(_recordId) : null;
  const type = query.get("type");

  const { data, mutate: contractMutate } = useSWR(
    `/view-contracts/${contractId}/`
  );
  const contract = data ? (data as Contract) : null;

  const { data: userData, refresh: refreshUserData } = useSessionStore();
  const user = userData ? (userData as User) : null;

  //   if(contract?.primary_buyer_lawyer.pk !== null){

  //     const customurl = `/legal-review-viewer?contractId=${contractId}&recordId=${recordId}`;
  //     window.location.replace(customurl);
  //   }

  const { data: propertyData } = useSWR(
    contract?.property_id ? `/view-properties/${contract.property_id}/` : null
  );
  const property = propertyData && (propertyData as Property);

  const { data: activitiesData, mutate: activitiesMutate } = useSWR(
    !isNil(contractId)
      ? `/contract/${contractId}/record/${recordId}/activities/`
      : null
  );

  const invitedVerificationToken = query.get("token");
  const inviterFirstName = query.get("first_name") || "";
  const inviterLastName = query.get("last_name") || "";
  const [signUpModalOpen, setSignUpModalOpen] = React.useState(false);

  const signUpModalOnClose = () => setSignUpModalOpen(false);
  const signUpModalOnSignedUp = () => {
    setSignUpModalOpen(false);
    refreshUserData();
  };
  const signUpModalOnLogin = () => {
    setSignUpModalOpen(false);
    setLoginModalOpen(true);
  };

  const { refresh: refreshUser, refreshTimestamp } = useSessionStore();

  React.useEffect(() => {
    refreshUserData(false);
  }, []);

  React.useEffect(() => {
    if (!isNil(contractId)) {
      refreshContractTeamsAndRole();
    }
  }, [contractId]);

  React.useEffect(() => {
    user && refreshContractTeamsAndRole();
  }, [refreshTimestamp]);

  const activities = activitiesData ? (activitiesData as Activity[]) : [];

  const [inviteModalOpen, setInviteModalOpen] = React.useState(false);
  const [inviteYourTeamMembersOpen, setInviteYourTeamMembersOpen] =
    React.useState(false);

  const [inviteYourTeamMembersType, setInviteYourTeamMembersType] =
    React.useState(InviteYourTeamMembersModalType.default);

  const [
    inviteYourTeamMembersUserTeamRole,
    setInviteYourTeamMembersUserTeamRole,
  ] = React.useState<TeamMemberRole | null>(null);

  const { setSuccessMessage } = useAlert();

  const [loginModalOpen, setLoginModalOpen] = React.useState(false);

  const onLoggedIn = () => {
    contractMutate();
    activitiesMutate();
  };

  const onSignedUp = () => {
    contractMutate();
    activitiesMutate();
  };

  return (
    <OnboardingWrapper onLoggedIn={onLoggedIn} onSignedUp={onSignedUp}>
      <AlertWrapper>
        <Box display="flex" flexDirection="column">
          <LegalReviewWhiteHeader
            property={property}
            onInviteClicked={() => {
              setInviteModalOpen(true);
            }}
          />

          {isMobile && contractId && recordId && (
            <Box>
              <Content
                contractId={+contractId}
                recordId={+recordId}
                property={property}
              />
            </Box>
          )}

          {!isMobile && contractId && recordId && (
            <Box display="flex" flexDirection="row">
              <Content
                contractId={+contractId}
                recordId={+recordId}
                property={property}
              />
              <Box mt="40px" width="353px">
                <Typography variant="h6">Activity Log</Typography>
                <ActivityLog activities={activities} />
              </Box>
            </Box>
          )}
        </Box>
      </AlertWrapper>

      <LoginModal
        open={loginModalOpen}
        recordId={recordId}
        contractId={contractId}
        onLoggedIn={() => {
          setLoginModalOpen(false);

          refreshUser(false);

          contractMutate();
          activitiesMutate();
        }}
        inviterName={`${inviterFirstName} ${inviterLastName}`.trim()}
        onCancel={() => setLoginModalOpen(false)}
      />

      <SignUpModal
        open={signUpModalOpen}
        onClose={signUpModalOnClose}
        contractId={contractId}
        inviterName={`${inviterFirstName} ${inviterLastName}`}
        token={invitedVerificationToken}
        onSignedUp={signUpModalOnSignedUp}
        onLogin={signUpModalOnLogin}
      />

      <InviteModalReadOnly
        type={
          type === "buying" ? InviteModalType.buying : InviteModalType.selling
        }
        open={inviteModalOpen}
        onClose={() => setInviteModalOpen(false)}
        contractId={contractId}
        recordId={recordId}
      />

      <InviteYourTeamMembersModal
        open={inviteYourTeamMembersOpen}
        onClose={() => setInviteYourTeamMembersOpen(false)}
        onInvited={(firstName, lastName) => {
          setInviteYourTeamMembersOpen(false);
          setSuccessMessage(
            `${firstName} ${lastName} has been invited to your team`
          );

          refreshContractTeamsAndRole();

          setInviteModalOpen(true);
        }}
        contractId={contractId}
        recordId={recordId}
        userTeamMemberRole={inviteYourTeamMembersUserTeamRole}
        type={inviteYourTeamMembersType}
        forScreen={InviteYourTeamMembersModalForScreen.buyerinvitedworkspace}
      />
    </OnboardingWrapper>
  );
}
