import { Box, Typography } from "@material-ui/core";
import { isNil } from "lodash-es";
import React from "react";
import { useHistory } from "react-router-dom";
import useSWR from "swr";
import {
  Contract,
  ContractSummary,
  ContractSummaryState,
  Property,
  Activity,
  TeamMemberRole,
} from "../api/models";
import AlertWrapper from "../components/AlertWrapper";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";
import HelpGuideModal from "../components/HelpGuide/HelpGuideModal";
import HelpGuideModalItem from "../components/HelpGuide/HelpGuideModalItem";
import LegalReviewProgressBar from "../components/LegalReviewProgressBar";
import LegalReviewWhiteHeader from "../components/LegalReviewWhiteHeader";
import StartAddingRequestsHero from "../images/lawyer-hero.svg";
import RequestHelp1 from "../images/req-workspace-buyer-lawyer-help1.svg";
import RequestHelp2 from "../images/req-workspace-buyer-lawyer-help2.svg";
import RequestHelp3 from "../images/req-workspace-buyer-lawyer-help3.svg";
import "../styles/podium.css";
import { useLocation } from "react-router-dom";
import ActivityLog from "../components/ActivityLog/ActivityLog";
import { useMobile } from "../hooks/mobile";
import InviteYourTeamMembersModal, {
  InviteYourTeamMembersModalType,
} from "../components/LegalReviewViewer/InviteYourTeamMembersModal";
import InviteModal from "../components/LegalReviewViewer/InviteModal";
import { useAlert } from "../contexts/Alert";
import { refreshContractTeamsAndRole } from "../stores/contract";

export default function StartAddingRequests() {
  const history = useHistory();
  const location = useLocation();
  const isMobile = useMobile();

  const query = new URLSearchParams(location.search);
  const _contractId = query.get("contractId");
  const contractId = !isNil(_contractId) ? parseInt(_contractId) : null;
  const _recordId = query.get("recordId");
  const recordId = !isNil(_recordId) ? parseInt(_recordId) : null;

  const { setSuccessMessage } = useAlert();

  const { data, mutate: contractMutate } = useSWR(
    `/view-contracts/${contractId}/?recordId=${recordId}`
  );
  const contract = data ? (data as Contract) : null;

  const published = contract ? contract.legal_summary_published : false;

  const { data: propertyData } = useSWR(
    contract?.property_id ? `/view-properties/${contract.property_id}/` : null
  );
  const property = propertyData && (propertyData as Property);

  const { data: summariesData, mutate: summariesMutate } = useSWR(
    !isNil(contractId)
      ? `contract/${contractId}/record/${recordId}/legal-review-summaries/`
      : null
  );

  const summaries = summariesData
    ? (summariesData as ContractSummary[]).filter(
        (summary) => summary.status === ContractSummaryState.published
      )
    : [];

  const [inviteModalOpen, setInviteModalOpen] = React.useState(false);
  const [requestsHelpModalOpen, setRequestsHelpModalOpen] =
    React.useState(false);

  const [inviteYourTeamMembersType, setInviteYourTeamMembersType] =
    React.useState(InviteYourTeamMembersModalType.default);

  const [inviteYourTeamMembersOpen, setInviteYourTeamMembersOpen] =
    React.useState(false);

  const [
    inviteYourTeamMembersUserTeamRole,
    setInviteYourTeamMembersUserTeamRole,
  ] = React.useState<TeamMemberRole | null>(null);

  const { data: activitiesData, mutate: activitiesMutate } = useSWR(
    !isNil(contractId)
      ? `/contract/${contractId}/record/${recordId}/activities/`
      : null
  );

  const activities = activitiesData ? (activitiesData as Activity[]) : [];

  const numberOfRequests = summaries.length;

  return (
    <>
      <AlertWrapper>
        <Box display="flex" flexDirection="column">
          <LegalReviewWhiteHeader
            property={property}
            onInviteClicked={() => {
              setInviteModalOpen(true);
            }}
            // membersCount={teamMembers.length}
          />

          <Box display="flex" flexDirection="row" justifyContent="center">
            <Box display="flex" flexDirection="row">
              <Box
                display="flex"
                flex={1}
                justifyContent="center"
                alignItems="center"
                height="80vh"
                width={isMobile ? "85vw" : "768px"}
              >
                <Box
                  width="385px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  {contractId && recordId && (
                    <LegalReviewProgressBar
                      contractId={contractId}
                      recordId={recordId}
                    />
                  )}

                  <img
                    src={StartAddingRequestsHero}
                    style={{ marginTop: "16px", marginBottom: "10px" }}
                  />

                  <Typography
                    variant="h6"
                    style={{ fontWeight: 700, textAlign: "center" }}
                  >
                    Start adding requests
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      textAlign: "center",
                      marginTop: "10px",
                      marginBottom: "27px",
                    }}
                  >
                    Contrax has identified {numberOfRequests} requests for this
                    contract. Edit, add or dismiss them to prepare your
                    requests. Send them to the vendor's representives when
                    you're ready.
                  </Typography>

                  <Button
                    type={ButtonType.primary}
                    size={ButtonSize.large}
                    title="CREATE REQUESTS"
                    onClick={() => {
                      history.push(
                        `/request-builder?contractId=${contractId}&recordId=${recordId}`
                      );
                    }}
                  />
                  <Box height="8px" />
                  <Button
                    type={ButtonType.secondary}
                    size={ButtonSize.medium}
                    title="HELP GUIDE"
                    onClick={() => {
                      setRequestsHelpModalOpen(true);
                    }}
                  />
                </Box>
              </Box>

              {!isMobile && (
                <Box mt="40px">
                  <Typography variant="h6">Activity Log</Typography>
                  <ActivityLog activities={activities} />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </AlertWrapper>

      <InviteModal
        open={inviteModalOpen}
        onClose={() => setInviteModalOpen(false)}
        onInviteTeamMembersClicked={(userTeamMemberRole) => {
          setInviteModalOpen(false);
          setInviteYourTeamMembersType(InviteYourTeamMembersModalType.default);
          setInviteYourTeamMembersUserTeamRole(userTeamMemberRole);
          setInviteYourTeamMembersOpen(true);
        }}
        onInviteAgents={() => {
          setInviteModalOpen(false);
          setInviteYourTeamMembersType(InviteYourTeamMembersModalType.agents);
          setInviteYourTeamMembersUserTeamRole(null);
          setInviteYourTeamMembersOpen(true);
        }}
        contractId={contractId}
        recordId={recordId}
      />

      <InviteYourTeamMembersModal
        open={inviteYourTeamMembersOpen}
        onClose={() => setInviteYourTeamMembersOpen(false)}
        onInvited={(firstName, lastName) => {
          setInviteYourTeamMembersOpen(false);
          setSuccessMessage(
            `${firstName} ${lastName} has been invited to your team`
          );

          refreshContractTeamsAndRole();

          setInviteModalOpen(true);
        }}
        contractId={contractId}
        recordId={recordId}
        userTeamMemberRole={inviteYourTeamMembersUserTeamRole}
        type={inviteYourTeamMembersType}
      />

      <HelpGuideModal
        title="How to create your requests"
        open={requestsHelpModalOpen}
        onClose={() => setRequestsHelpModalOpen(false)}
        items={
          <>
            <HelpGuideModalItem
              index={1}
              title="Add, edit or dismiss suggested requests or create your own"
              image={RequestHelp1}
              imageMarginTop="69px"
            />
            <Box width="6px" />
            <HelpGuideModalItem
              index={2}
              title="Send your request to the vendor’s legal representatives"
              image={RequestHelp2}
            />
            <Box width="6px" />
            <HelpGuideModalItem
              index={3}
              title="Keep track of responses, manage requests and send updates if needed"
              image={RequestHelp3}
            />
          </>
        }
      />
    </>
  );
}
