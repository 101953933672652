import React from "react";
import { Box, Typography, Button as MUIButton } from "@material-ui/core";
import produce from "immer";
import api from "../api/instance";
import { isEmpty, isNil, omit } from "lodash-es";
import { useHistory, useLocation } from "react-router-dom";
import { useMobile } from "../hooks/mobile";
import AlertWrapper from "../components/AlertWrapper";
import HeroLayout from "../components/HeroLayout";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import { Colors } from "../constants/colors";
import Button, { ButtonType, ButtonSize } from "../components/common/Button";
import { useAlert } from "../contexts/Alert";
import Checkbox from "../components/common/Checkbox";
import Separator from "../components/common/Separator";
import TextField from "../components/common/TextField";
import { AxiosError } from "axios";
import { useSessionStore } from "../stores/Session";
import { addressForProperty, Contract, Property, User } from "../api/models";
import AuthenticatedWrapper from "../components/AuthenticatedWrapper";
import CloseIcon from "../images/x.png";
import useSWR from "swr";

interface ContactDetails {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export default function AddAgentDetails(): JSX.Element {
  const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const contractId = query.get("contractId");
  const propertyId = query.get("propertyId");

  const { data: propertyData } = useSWR(`/view-properties/${propertyId}/`);
  const property = propertyData && (propertyData as Property);

  const [loading, setLoading] = React.useState(false);

  const [firstName, setFirstName] = React.useState("");
  const [firstNameError, setFirstNameError] = React.useState<string | null>(
    null
  );
  const [phone, setPhone] = React.useState("");
  const [PhoneError, setPhoneError] = React.useState<string | null>(null);

  const isMobile = useMobile();

  const { setErrorMessage } = useAlert();

  const { refresh: refreshUserData, data: userData } = useSessionStore();
  const user = userData && (userData as User);

  function Header() {
    return (
      <Box
        width={isMobile ? undefined : "700px"}
        mt={isMobile ? 3 : 22}
        mb={1.5}
      >
        <Button
          type={ButtonType.white}
          size={ButtonSize.small}
          title="Back to property"
          sidePadding={1}
          onClick={() =>
            history.push(`/property-info?propertyId=${propertyId}`)
          }
        />
        <Typography
          variant={isMobile ? "h5" : "h4"}
          style={{ marginTop: "8px", fontWeight: 400 }}
        >
          {property && addressForProperty(property)}
        </Typography>
        <Typography
          variant={isMobile ? "h5" : "h4"}
          style={{ marginTop: "20px" }}
        >
          Add agent’s details
        </Typography>
        <Typography
          variant={isMobile ? "body1" : "body1"}
          style={{ marginTop: "8px", fontWeight: 400 }}
        >
          Add the agent's details so that Contrax can get the contract of sale.
        </Typography>
      </Box>
    );
  }

  function resetErrors() {
    setFirstNameError(null);
    setPhoneError(null);
  }

  function verifyFields() {
    resetErrors();

    let allFieldsValid = true;

    if (isEmpty(firstName)) {
      setFirstNameError("Please enter agent's first name");
      allFieldsValid = false;
    }

    if (isEmpty(phone)) {
      setFirstNameError("Please enter agent's phone");
      allFieldsValid = false;
    }

    return allFieldsValid;
  }

  const handleCheckout = () => {
    api
      .post("/dummy-contracts/", { property_id: propertyId })
      .then((response) => {
        if (user) {
          history.push(`/payment-preview?propertyId=${propertyId}&cross=false`);
        } else {
          history.push(`/signup?propertyId=${propertyId}`);
        }
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        setErrorMessage(error.message);
      });
  };

  function nextClicked() {
    if (!verifyFields()) {
      return;
    }

    setLoading(true);

    const data = {
      first_name: firstName,
      phone: phone,
    };

    api
      .post(`/request-review/?public=true`, data)
      .then((response) => {
        console.debug("response: ", response);
        handleCheckout();
      })
      .catch((error: AxiosError) => {
        setLoading(false);

        setErrorMessage(error.message);
      });
  }

  return (
    <AlertWrapper>
      <HeroLayout>
        <Box
          marginLeft="auto"
          marginRight="auto"
          maxWidth="768px"
          pl={3.25}
          pr={3.25}
          pb={8}
        >
          <Header />
          <Box display="flex" flexDirection="row" alignItems="center" mt={1.5}>
            <TextField
              title="First name"
              value={firstName}
              error={firstNameError !== null}
              helperText={firstNameError}
              onChange={(value) => setFirstName(value)}
            />
            <Box ml={1} />
            <TextField
              title="Phone number"
              value={phone}
              error={PhoneError !== null}
              helperText={PhoneError}
              onChange={(value) => setPhone(value)}
            />
          </Box>

          {!isMobile && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              mt={5}
              mb={10}
            >
              <Button
                type={ButtonType.primary}
                size={ButtonSize.large}
                title="NEXT"
                width={isMobile ? "100%" : "137px"}
                loading={loading}
                onClick={() => nextClicked()}
              />
            </Box>
          )}

          <Separator mt={isMobile ? 2 : 4.25} mb={isMobile ? "18px" : "24px"} />

          <Button
            type={ButtonType.transparent}
            size={ButtonSize.small}
            title="OR Upload the contract of sale"
            loading={loading}
            onClick={() =>
              history.push(`/search/upload-contract?propertyId=${propertyId}`)
            }
          />
        </Box>
        {isMobile && (
          <MobileStickyFooter
            buttonsLayout={FooterButtonsLayout.sideBySide}
            leftButtonTitle="BACK"
            leftButtonOnClick={() => history.goBack()}
            rightButtonTitle="NEXT"
            rightButtonLoading={loading}
            rightButtonOnClick={() => nextClicked()}
          />
        )}
      </HeroLayout>
    </AlertWrapper>
  );
}
