import React from "react";
import { Box, Typography, Link } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import AlertWrapper from "../components/AlertWrapper";
import HeroLayout from "../components/HeroLayout";
import TextField from "../components/common/TextField";
import { useAlert } from "../contexts/Alert";
import { useMobile } from "../hooks/mobile";
import { Colors } from "../constants/colors";
import Separator from "../components/common/Separator";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import Button, { ButtonType, ButtonSize } from "../components/common/Button";
import { isEmpty } from "lodash-es";
import axios, { AxiosError } from "axios";
import api from "../api/instance";

const noAuthApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export default function ResetPassword(): JSX.Element {
  const location = useLocation();
  const history = useHistory();
  const isMobile = useMobile();

  const query = new URLSearchParams(location.search);
  const token = query.get("token");

  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState<string | null>(null);
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [confirmPasswordError, setConfirmPasswordError] = React.useState<
    string | null
  >(null);

  const [loading, setLoading] = React.useState(false);

  const { setErrorMessage, setSuccessMessage } = useAlert();

  function saveClicked() {
    if (loading) {
      return;
    }

    let fieldsValid = true;

    if (isEmpty(password)) {
      setPasswordError("Please enter your new password");
      fieldsValid = false;
    }

    if (isEmpty(confirmPassword)) {
      setConfirmPasswordError("Please confirm your password");
      fieldsValid = false;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords did not match");
      fieldsValid = false;
    }

    if (fieldsValid) {
      setLoading(true);

      noAuthApi
        .post("/reset-password/", {
          token: token,
          password: confirmPassword,
        })
        .then((response) => {
          history.push("/login?resetPasswordSuccess=true");
        })
        .catch((error: AxiosError) => {
          setLoading(false);

          setErrorMessage(
            "An error has occurred. Your reset password request may have expired."
          );
        });
    }
  }

  if (!token) {
    return <Box />;
  }

  return (
    <AlertWrapper>
      <HeroLayout>
        <Box
          mt={isMobile ? 4 : 25}
          ml={3.25}
          mr={3.25}
          width={isMobile ? "323px" : "353px"}
          marginLeft="auto"
          marginRight="auto"
          alignItems={isMobile ? "center" : undefined}
        >
          <Typography variant={isMobile ? "h5" : "h4"}>
            Create a new password
          </Typography>
          <Box mt={4} />
          <TextField
            title="New password"
            type="password"
            value={password}
            error={passwordError !== null}
            helperText={passwordError}
            onChange={(value) => setPassword(value)}
          />
          <Box mt={3} />
          <TextField
            title="Confirm password"
            type="password"
            value={confirmPassword}
            error={confirmPasswordError !== null}
            helperText={confirmPasswordError}
            onChange={(value) => setConfirmPassword(value)}
          />
          <Box mt={3} />
          {isMobile && <Separator mb={2} />}
        </Box>
        {!isMobile && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            mt={25}
          >
            <Button
              type={ButtonType.primary}
              size={ButtonSize.large}
              title="DONE"
              width={"137px"}
              loading={loading}
              onClick={() => saveClicked()}
            />
          </Box>
        )}
      </HeroLayout>
      {isMobile && (
        <MobileStickyFooter
          buttonsLayout={FooterButtonsLayout.centered}
          buttonTitle="DONE"
          buttonLoading={loading}
          buttonOnClick={() => saveClicked()}
        />
      )}
    </AlertWrapper>
  );
}
