import { Box, CircularProgress } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { isLoggedIn } from "../stores/Session";

import { AxiosResponse } from "axios";
import React from "react";
import api from "../api/instance";

export default function VerifyEmail(): JSX.Element {
  const location = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(location.search);
  const token = query.get("token");
  const invite = query.get("invite");

  const dashboard = query.get("dashboard");
  const isNew = query.get("is_new");
  const joinOrganisation = query.get("joinOrg");

  // verify account and if invite, redirect to reset password with reset token

  React.useEffect(() => {
    if (dashboard === "true" && isNew === "true") {
      history.push(`/dashboard${location.search}`);
    }
    else if (joinOrganisation === "true" && isNew === "false" && isLoggedIn()){
      history.push(`/dashboard${location.search}`);
    }
    else if (joinOrganisation === "true" && isNew === "false" && !isLoggedIn()){
      history.push(`/login${location.search}`);
    }
     else {
      api
        .get("/verify-user/", {
          params: {
            token,
          },
        })
        .then((response: AxiosResponse) => {
          if (invite) {
            history.push(`/reset-password?token=${response.data.token}`);
          } else {
            history.push("/login?verifySuccess=true");
          }
        })
        .catch((error) => {
          history.push("/login?verifySuccess=false");
        });
    }
  }, []);

  return (
    <Box>
      <CircularProgress />
    </Box>
  );
}
