import { Box, Checkbox, Link, Typography } from "@material-ui/core";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { find, isEmpty, isNil } from "lodash-es";
import React from "react";
import { useHistory } from "react-router-dom";
import api from "../api/instance";
import { Order, OrderStatus, PaymentMethod } from "../api/models";
import AlertWrapper from "../components/AlertWrapper";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";
import DesktopFooter from "../components/common/DesktopFooter";
import TextField from "../components/common/TextField";
import PrivacyLink from "../components/PrivacyLink";
import CardBrandItem from "../components/PurchaseCredits/CardBrandItem";
import Header from "../components/PurchaseCredits/Header";
import TermsLink from "../components/TermsLink";
import { Colors } from "../constants/colors";
import { useAlert } from "../contexts/Alert";
import { parseApiError } from "../helpers/error";
import { useMobile } from "../hooks/mobile";
import Amex from "../images/AMEX.svg";
import Mastercard from "../images/Mastercard.svg";
import Visa from "../images/Visa.svg";
import { usePurchaseStore } from "../stores/purchase";
import { useSessionStore } from "../stores/Session";
import "../styles/stripe.css";
import PromoCodeTick from "../images/promo-code-tick.svg";
import PromoCodeX from "../images/promo-code-x.svg";

interface StripeFieldTitleProps {
  title: string;
}

function StripeFieldTitle({ title }: StripeFieldTitleProps) {
  return (
    <Typography
      variant="body2"
      style={{
        fontWeight: 700,
        marginBottom: "4px",
        color: Colors.Grey1,
      }}
    >
      {title}
    </Typography>
  );
}

interface SetupIntentResponse {
  clientSecret: string;
}

interface PaymentIntentResponse {
  clientSecret: string;
  orderId: number;
}

interface ConfirmPaymentResponse {
  successfulCharge: boolean;
}

export default function PurchaseCredits() {
  const isMobile = useMobile();
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const { data: user } = useSessionStore();

  const { setErrorMessage, setSuccessMessage } = useAlert();

  const [isPaymentLoading, setPaymentLoading] = React.useState(false);
  const [isFetchingPaymentIntent, setFetchingPaymentIntent] =
    React.useState(false);

  const [nameOnCard, setNameOnCard] = React.useState("");
  const [promoCode, setPromoCode] = React.useState("");
  const [saveCardInfo, setSaveCardInfo] = React.useState(true);

  const [isTokenLoading, setTokenLoading] = React.useState(false);

  const [defaultPaymentMethod, setDefaultPaymentMethod] =
    React.useState<PaymentMethod | null>(null);
  const [useDefaultPayment, setUseDefaultPayment] = React.useState(false);

  const [clientSecret, setClientSecret] = React.useState<string | null>(null);
  const [orderId, setOrderId] = React.useState<number | null>(null);

  const [discountCents, setDiscountCents] = React.useState<number>(0);
  const [afterDiscountTotal, setAfterDiscountTotal] = React.useState<number>(0);

  const [invalidPromoCode, setInvalidPromoCode] = React.useState(false);
  const [applyingCode, setApplyingCode] = React.useState(false);

  const {
    credits,
    amountCents,
    creditPackId,
    reset: resetPurchaseStore,
    purchaseJourneyStartPath,
    purchaseJourneySuccessPath,
  } = usePurchaseStore();

  React.useEffect(() => {
    if (credits === 0 || amountCents === 0) {
      history.goBack();
      setErrorMessage("Please do not refresh during checkout");
    } else {
      // Get payment intent
      setFetchingPaymentIntent(true);

      api
        .post("/payment/new/", {
          credit_package_id: !isNil(creditPackId) ? creditPackId : undefined,
          credit_amount: credits,
          total_units: amountCents,
        })
        .then((response) => {
          setFetchingPaymentIntent(false);

          const paymentIntentResponse = response.data as PaymentIntentResponse;

          const { orderId, clientSecret } = paymentIntentResponse;

          setClientSecret(clientSecret);
          setOrderId(orderId);
        })
        .catch((error) => {
          history.goBack();

          setErrorMessage("Unable to setup payment. Please try again.");
        });
    }
  }, []);

  React.useEffect(() => {
    api
      .get("/payment-methods/")
      .then((response) => {
        const paymentMethods = response.data as PaymentMethod[];

        const defaultCard = find(paymentMethods, { is_enabled: true });

        if (defaultCard) {
          setDefaultPaymentMethod(defaultCard);
          setUseDefaultPayment(true);
        }
      })
      .catch((error) => {
        setErrorMessage(parseApiError(error));
      });
  }, []);

  React.useEffect(() => {
    setInvalidPromoCode(false);
    setDiscountCents(0);
    setAfterDiscountTotal(0);
  }, [promoCode]);

  function checkIfOrderIsComplete(onComplete: () => unknown) {
    api
      .get(`/orders/${orderId}/`)
      .then((response) => {
        const order = response.data as Order;

        if (order.status === OrderStatus.Successful) {
          onComplete();
        }
      })
      .catch((error) => {
        setErrorMessage(parseApiError(error));
      });
  }

  function onSuccessfulChange() {
    if (!isNil(purchaseJourneySuccessPath)) {
      // Induce a 3 second delay to ensure Stripe webhook was successful on the backend
      const interval = setInterval(() => {
        checkIfOrderIsComplete(() => {
          clearInterval(interval);

          resetPurchaseStore();

          history.push(purchaseJourneySuccessPath);
        });
      }, 500);

      return;
    }

    // Induce a 3 second delay to ensure Stripe webhook was successful on the backend
    const interval = setInterval(() => {
      checkIfOrderIsComplete(() => {
        clearInterval(interval);

        setSuccessMessage("You have successfully purchased credits!");

        history.push("/user/credits");
      });
    }, 500);
  }

  function confirmUsingSavedCard() {
    setPaymentLoading(true);

    api
      .post("/payment/confirm/", {
        order_id: orderId,
      })
      .then((response) => {
        const confirmPaymentResponse = response.data as ConfirmPaymentResponse;

        const { successfulCharge } = confirmPaymentResponse;

        if (successfulCharge) {
          onSuccessfulChange();
        } else {
          setPaymentLoading(false);

          setErrorMessage("Unable to make payment. Please try again!");
        }
      })
      .catch((error) => {
        console.debug("error: ", error);

        setPaymentLoading(false);

        setErrorMessage("Unable to make payment. Please try again!");
      });
  }

  async function confirmUsingEnteredCard() {
    if (!stripe) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (!elements) {
      return;
    }

    const cardNumberEle = elements.getElement(CardNumberElement);
    const cvcEle = elements.getElement(CardCvcElement);

    if (!cardNumberEle || !cvcEle || !clientSecret) {
      return;
    }

    setPaymentLoading(true);

    // Use one off card
    const paymentResult = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardNumberEle,
        billing_details: {
          name: nameOnCard,
        },
      },
      setup_future_usage: "off_session",
    });

    if (paymentResult.error?.message) {
      setPaymentLoading(false);

      setErrorMessage(paymentResult.error.message);
    } else {
      if (paymentResult.paymentIntent?.status === "succeeded") {
        onSuccessfulChange();
      }
    }
  }

  const onPay = async () => {
    if (!useDefaultPayment && isEmpty(nameOnCard)) {
      setErrorMessage("Please enter Name on card");
      return;
    }

    if (!stripe) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (useDefaultPayment) {
      // Use saved card
      confirmUsingSavedCard();
    } else {
      if (!elements) {
        return;
      }

      const cardNumberEle = elements.getElement(CardNumberElement);
      const cvcEle = elements.getElement(CardCvcElement);

      if (!cardNumberEle || !cvcEle || !clientSecret) {
        return;
      }

      setPaymentLoading(true);

      if (saveCardInfo) {
        // REMINDER: Needs to be setup intent for saving card
        api.post("/payment/methods/new/").then(async (response) => {
          const setupIntentResponse = response.data as SetupIntentResponse;

          const { clientSecret } = setupIntentResponse;

          // Save card as default
          const setupResult = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
              card: cardNumberEle,
              billing_details: {
                name: nameOnCard,
              },
            },
          });

          if (setupResult.error?.message) {
            setPaymentLoading(false);

            setErrorMessage(setupResult.error.message);
          } else {
            if (setupResult.setupIntent?.status === "succeeded") {
              // REMINDER: Checkout using entered card rather than saved card as Stripe webhook may not have been called in the backend
              confirmUsingEnteredCard();
            }
          }
        });
      } else {
        confirmUsingEnteredCard();
      }
    }
  };

  const onApplyCode = () => {
    if (isNil(orderId) || isEmpty(promoCode)) {
      return;
    }

    setInvalidPromoCode(false);
    setDiscountCents(0);
    setAfterDiscountTotal(0);

    setApplyingCode(true);

    api
      .post("/payment/apply-code/", {
        code: promoCode,
        order_id: orderId,
      })
      .then((response) => {
        setApplyingCode(false);

        const order = response.data;

        const { discount_units, after_discount } = order;

        setDiscountCents(discount_units);
        setAfterDiscountTotal(after_discount);
      })

      .catch((error) => {
        setApplyingCode(false);
        setInvalidPromoCode(true);
      });
  };

  const textFieldAccessory = React.useMemo(() => {
    if (invalidPromoCode) {
      return <img src={PromoCodeX} />;
    }

    if (discountCents > 0) {
      return <img src={PromoCodeTick} />;
    }

    return undefined;
  }, [invalidPromoCode, discountCents, afterDiscountTotal]);

  const payButtonTotal = React.useMemo(() => {
    if (!invalidPromoCode && discountCents > 0) {
      return afterDiscountTotal;
    }

    return amountCents;
  }, [invalidPromoCode, discountCents, afterDiscountTotal]);

  /*
  const onPay = async () => {
    if (!useDefaultPayment && isEmpty(nameOnCard)) {
      setErrorMessage("Please enter Name on card");
      return;
    }

    if (!stripe) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setPaymentLoading(true);

    api
      .post("/payments/stripe/create-paymentintent", {
        total_units: amountCents, // REMINDER: in cents
        credit_amount: credits,
        new_card: !useDefaultPayment,
        save_card: saveCardInfo,
      })
      .then(async (response) => {
        const paymentIntentResponse = response.data as PaymentIntentResponse;

        const { clientSecret } = paymentIntentResponse;

        let paymentResult;

        if (useDefaultPayment) {
          paymentResult = await stripe.confirmCardPayment(clientSecret);
        } else {
          if (!elements) {
            return;
          }

          const cardNumberEle = elements.getElement(CardNumberElement);
          const cvcEle = elements.getElement(CardCvcElement);

          if (!cardNumberEle || !cvcEle) {
            return;
          }

          paymentResult = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card: cardNumberEle,
              billing_details: {
                name: nameOnCard,
              },
            },
          });
        }

        setPaymentLoading(false);

        if (paymentResult.error?.message) {
          setErrorMessage(paymentResult.error.message);
        } else {
          if (paymentResult.paymentIntent?.status === "succeeded") {
            if (!isNil(contractId) && !isNil(recordId)) {
              resetPurchaseStore();

              history.push(
                `/create-workspace?contractId=${contractId}&recordId=${recordId}&type=buying&paymentSuccessful=true`
              );
              return;
            }

            setSuccessMessage("You have successfully purchased credits!");
            console.log(paymentResult);

            history.push("/user/credits");
          }
        }
      })
      .catch((error) => {
        console.debug("Stripe error: ", error);

        setErrorMessage("Error setting up Stripe");
      });
  };*/

  /*
  const onCreateToken = async () => {
    if (isEmpty(nameOnCard)) {
      setErrorMessage("Please enter Name on card");
      return;
    }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const cardNumberEle = elements.getElement(CardNumberElement);
    const cvcEle = elements.getElement(CardCvcElement);

    if (!cardNumberEle || !cvcEle) {
      return;
    }

    setTokenLoading(true);

    const createTokenResult = await stripe.createToken(cardNumberEle);

    setTokenLoading(false);

    if (createTokenResult.error?.message) {
      setErrorMessage(createTokenResult.error.message);
    } else {
      console.log(createTokenResult);
    }
  };*/

  return (
    <AlertWrapper>
      <Box minHeight="100vh" display="flex" flexDirection="column">
        <Header
          onBack={() => history.goBack()}
          onClose={() => {
            if (!isNil(purchaseJourneyStartPath)) {
              history.push(purchaseJourneyStartPath);
            } else {
              history.push("/user/credits");
            }
          }}
        />

        <Box
          display="flex"
          flexDirection={"column"}
          alignItems="center"
          flex={1}
          pl="15px"
          pr="15px"
          boxSizing="border-box"
        >
          <Box maxWidth="557px" width="100%">
            <Box pt="56px">
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography
                  variant={isMobile ? "h5" : "h4"}
                  style={{ fontWeight: 400 }}
                >
                  {credits} Contrax credits
                </Typography>
                <Typography variant={isMobile ? "h5" : "h4"}>
                  ${(amountCents / 100).toFixed(2)}
                </Typography>
              </Box>

              <Box
                display="flex"
                justifyContent={"flex-end"}
                color={Colors.Grey3}
              >
                <Typography variant="overline">INCL. GST</Typography>
              </Box>

              {!invalidPromoCode && discountCents > 0 && (
                <>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    mt="16px"
                  >
                    <Typography
                      variant={isMobile ? "h6" : "h5"}
                      style={{ fontWeight: 400 }}
                    >
                      Discount
                    </Typography>
                    <Typography
                      variant={isMobile ? "h6" : "h5"}
                      style={{ fontWeight: "bold" }}
                    >
                      -${(discountCents / 100).toFixed(2)}
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    mt="8px"
                  >
                    <Typography
                      variant={isMobile ? "h6" : "h5"}
                      style={{ fontWeight: 400 }}
                    >
                      Total
                    </Typography>
                    <Typography
                      variant={isMobile ? "h6" : "h5"}
                      style={{ fontWeight: "bold" }}
                    >
                      ${(afterDiscountTotal / 100).toFixed(2)}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>

            <Box border="1px solid #ECF2FB" mt="16px" mb="32px" />

            <Box display="flex" flexDirection={"row"}>
              <TextField
                value={promoCode}
                onChange={(text) => setPromoCode(text)}
                title="Promo code"
                accessory={textFieldAccessory}
              />

              <Box mt="26px" ml={2}>
                <Button
                  type={ButtonType.primary}
                  size={ButtonSize.medium}
                  width={143}
                  height={52}
                  title="APPLY CODE"
                  onClick={onApplyCode}
                  loading={applyingCode}
                />
              </Box>
            </Box>

            <Box
              border="1px solid #ECF2FB"
              boxSizing="border-box"
              borderRadius="8px"
              pb="12px"
              mb="22px"
            >
              <Box
                height="62px"
                bgcolor={Colors.BrandBackground3}
                width="100%"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems={"center"}
                boxSizing="border-box"
                paddingLeft="16px"
                paddingRight="16px"
              >
                <Typography variant="h6" style={{ fontWeight: 700 }}>
                  Pay with card
                </Typography>

                <Box display="flex" flexDirection={"row"}>
                  <img src={Mastercard} height="30px" />
                  <img
                    src={Visa}
                    height="30px"
                    style={{ marginLeft: "16px" }}
                  />
                  <img
                    src={Amex}
                    height="30px"
                    style={{ marginLeft: "16px" }}
                  />
                </Box>
              </Box>

              {useDefaultPayment && (
                <Box
                  display="flex"
                  flexDirection={"column"}
                  p="15px"
                  boxSizing={"border-box"}
                >
                  <Box display="flex" flexDirection={"row"}>
                    <CardBrandItem brand={defaultPaymentMethod?.brand} />

                    <Box display="flex" flexDirection={"column"} ml="25px">
                      <Typography>
                        ···· ···· ···· {defaultPaymentMethod?.last_digits}
                      </Typography>
                      <Typography style={{ marginTop: "8px" }}>
                        {defaultPaymentMethod?.exp_month}/
                        {defaultPaymentMethod?.exp_year}
                      </Typography>
                      <Typography style={{ marginTop: "8px" }}>
                        {user?.current_organization?.name ||
                          `${user?.first_name} ${user?.last_name}`}
                      </Typography>
                    </Box>
                  </Box>
                  <Link
                    style={{
                      color: Colors.Link,
                      cursor: "pointer",
                      marginTop: "16px",
                    }}
                    onClick={() => {
                      setUseDefaultPayment(false);
                    }}
                  >
                    <Typography>Use a different card</Typography>
                  </Link>
                </Box>
              )}

              {!useDefaultPayment && (
                <div
                  style={{
                    padding: "0rem",
                  }}
                >
                  <div
                    style={{
                      maxWidth: "525px",
                      margin: "0 auto",
                    }}
                  >
                    <form
                      style={{
                        display: "block",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          width="100%"
                          mt={2}
                        >
                          <StripeFieldTitle title="Card number" />
                          <CardNumberElement
                            className="card"
                            options={{
                              style: {
                                base: {
                                  backgroundColor: "white",
                                  fontFamily: "Mulish",
                                },
                              },
                            }}
                          />
                          <Box
                            display="flex"
                            flexDirection="row"
                            width="100%"
                            mt={2}
                            mb={2}
                          >
                            <Box
                              display="flex"
                              flexDirection="column"
                              flex={1}
                              mr={1}
                            >
                              <StripeFieldTitle title="Expiration date" />
                              <CardExpiryElement
                                className="card"
                                options={{
                                  style: {
                                    base: {
                                      backgroundColor: "white",
                                      fontFamily: "Mulish",
                                    },
                                  },
                                }}
                              />
                            </Box>

                            <Box
                              display="flex"
                              flexDirection="column"
                              flex={1}
                              ml={1}
                            >
                              <StripeFieldTitle title="CVC" />
                              <CardCvcElement
                                className="card"
                                options={{
                                  style: {
                                    base: {
                                      backgroundColor: "white",
                                      fontFamily: "Mulish",
                                    },
                                  },
                                }}
                              />
                            </Box>
                          </Box>
                          <TextField
                            value={nameOnCard}
                            onChange={(text) => setNameOnCard(text)}
                            title="Name on card"
                          />

                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems={"center"}
                          >
                            <Checkbox
                              style={{ color: Colors.Grey2 }}
                              checked={saveCardInfo}
                              onChange={(event) => {
                                setSaveCardInfo(event.target.checked);
                              }}
                            />

                            <Typography variant="body1">
                              Save my information to pay faster next time
                            </Typography>
                          </Box>
                        </Box>

                        {/* <Button
                    type={ButtonType.secondary}
                    size={ButtonSize.large}
                    onClick={onCreateToken}
                    loading={isTokenLoading}
                    title={`***TEST CREATE TOKEN***`}
                    width="100%"
                  /> */}
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </Box>
            <Typography variant="body2" style={{ marginBottom: "30px" }}>
              By clicking pay you agree to our <TermsLink /> and <PrivacyLink />
            </Typography>

            <Button
              type={ButtonType.primary}
              size={ButtonSize.large}
              onClick={onPay}
              loading={isPaymentLoading || isFetchingPaymentIntent}
              title={`PAY $${(payButtonTotal / 100).toFixed(2)}`}
              width="100%"
            />

            <Box height="55px" />
          </Box>
        </Box>

        <DesktopFooter />
      </Box>
    </AlertWrapper>
  );
}
