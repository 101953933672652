import { Box, Typography, Radio } from "@material-ui/core";
import React from "react";
import { Colors } from "../../constants/colors";
import { useAlert } from "../../contexts/Alert";
import { useMobile } from "../../hooks/mobile";
import Button, { ButtonSize, ButtonType } from "../common/Button";
import TextField from "../common/TextField";
import StatusPill, { StatusPillType } from "../StatusPill";

interface Props {
  onAddRequest: (
    title: string,
    description: string,
    proposal: string
  ) => unknown;
  onCancel: () => unknown;
  adding: boolean;
}

// export enum CustomRequestType {
//   AmendmentRequest,
//   GeneralEnquiry,
// }

export default function ({ onAddRequest, onCancel, adding }: Props) {
  const isMobile = useMobile();

  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [proposal, setProposal] = React.useState("");

  // const [customRequestType, setCustomRequestType] =
  //   React.useState<CustomRequestType>(CustomRequestType.AmendmentRequest);

  const { setErrorMessage } = useAlert();

  return (
    <Box
      border="1px solid #E1EAFF"
      bgcolor="white"
      borderRadius="16px"
      display="flex"
      flexDirection="column"
      maxWidth={isMobile ? "100%" : "1097px"}
      mt="12px"
    >
      <Box
        height="77px"
        boxShadow="0px 1px 0px #E0E0E0"
        display="flex"
        flexDirection="row"
        alignItems="center"
        pl="24px"
        pr="24px"
      >
        <Typography variant="h6" style={{ fontWeight: 700, flex: 1 }}>
          New Request
        </Typography>
        <StatusPill type={StatusPillType.pending} />
      </Box>

      <Box display="flex" flexDirection="row" p="24px">
        <Box>
          <TextField
            title="Title"
            value={title}
            onChange={(text) => setTitle(text)}
            placeholder="Enter title"
          />

          <Box
            width={isMobile ? "80vw" : "515px"}
            bgcolor={Colors.Grey5}
            borderRadius="8px"
          >
            <Box m="16px" pt="16px">
              <TextField
                title="Request Description"
                value={description}
                onChange={(text) => setDescription(text)}
                multiline
                placeholder="Enter description"
              />
              <TextField
                title="Propose change to contract"
                value={proposal}
                onChange={(text) => setProposal(text)}
                multiline
                placeholder="Enter change"
              />
            </Box>
          </Box>
        </Box>

        {!isMobile && (
          <Box
            width="515px"
            ml="20px"
            pl="61px"
            pr="61px"
            height="100%"
            display="flex"
            alignItems="center"
          >
            <Typography variant="body2" style={{ textAlign: "center" }}>
              Add your custom request as an amendment request to change the
              contract or a general enquiry about the property.
            </Typography>
          </Box>
        )}
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        boxShadow="0px -1px 0px #E0E0E0"
        height="74px"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" flexDirection="row" alignItems="center"></Box>

        <Box display="flex" flexDirection="row" alignItems="center" pr="16px">
          <Button
            type={ButtonType.destructive}
            size={ButtonSize.small}
            title="DISMISS"
            width="93px"
            onClick={onCancel}
          />
          <Box width="8px" />
          <Button
            loading={adding}
            type={ButtonType.primary}
            size={ButtonSize.small}
            title="ADD"
            width="58px"
            onClick={() => onAddRequest(title, description, proposal)}
          />
        </Box>
      </Box>
    </Box>
  );
}
