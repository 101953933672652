import { AxiosError } from "axios";

export interface ErrorResponse {
  message?: string;
  error_code?: string;
}

export function parseApiError(error: AxiosError) {
  return (
    (error.response?.data as ErrorResponse)?.message ||
    (error.response?.data as ErrorResponse)?.error_code ||
    "An unknown error has occured"
  );
}
