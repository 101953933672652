import { Box, Dialog, Typography } from "@material-ui/core";
import { PDFDownloadLink } from "@react-pdf/renderer";
import React from "react";
import api from "../../api/instance";
import {
  addressForProperty,
  Comment,
  Contract,
  ContractSummary,
  Property,
  TeamMemberRole,
} from "../../api/models";
import { useMobile } from "../../hooks/mobile";
import { generateLegalReviewDocxAndSave } from "../../utils/docx-generator";
import Button, { ButtonType } from "../common/Button";
import RadioWithTitle from "../common/RadioWithTitle";
import PDFDocument from "./PDFDocument";

interface Props {
  open: boolean;
  onCancel: () => unknown;
  summaries: ContractSummary[];
  contract: Contract;
  contractS: Contract;
  recordId: number;
  property: Property;
  userTeamMemberRole: TeamMemberRole;
  legalReviewForName: string;
}

enum DocumentType {
  pdf,
  word,
}

export default function DownloadDialog({
  open,
  onCancel,
  summaries,
  contract,
  contractS,
  recordId,
  property,
  userTeamMemberRole,
  legalReviewForName,
}: Props) {
  const isMobile = useMobile();

  const [documentType, setDocumentType] = React.useState<DocumentType>(
    DocumentType.pdf
  );
  const [includeComments, setIncludeComments] = React.useState<boolean>(true);
  const [commentsLoaded, setCommentsLoaded] = React.useState(false);

  const [summariesWithComments, setSummariesWithComments] = React.useState<
    ContractSummary[]
  >([]);

  const [fetchingComments, setFetchingComments] = React.useState(false);

  React.useEffect(() => {
    async function fetchComments(summaries: ContractSummary[]) {
      setFetchingComments(true);

      let _summaries = summaries;

      await Promise.all(
        _summaries.map(async (summary, index) => {
          const { data } = await api.get(
            `/contract/${contract.id}/record/${recordId}/summary/${summary.pk}/comments/`
          );

          summary.p_comments = data ? (data as Comment[]) : [];
        })
      );

      setFetchingComments(false);
      setSummariesWithComments(_summaries);
      setCommentsLoaded(true);
    }

    if (open && summaries && !fetchingComments) {
      fetchComments(summaries);
    }
  }, [open, summaries]);

  const filename = `${addressForProperty(property).replace(/[\W_]+/g, "_")}`;

  return (
    <Dialog
      open={open}
      aria-labelledby="download-dialog"
      aria-describedby="download-dialog-description"
      fullScreen={isMobile}
    >
      <Box
        display="flex"
        flexDirection="column"
        width={isMobile ? "100%" : "524px"}
      >
        <Box
          height="62px"
          boxShadow="0px 1px 0px #E0E0E0"
          alignItems="center"
          display="flex"
          width="100w"
        >
          <Typography
            variant="h5"
            style={{ fontWeight: 700, marginLeft: "24px" }}
          >
            Download your legal review
          </Typography>
        </Box>

        <Box pt="24px" pb="24px" pl="20px" pr="20px">
          <Typography variant="body1" style={{ fontWeight: 700 }}>
            What type of file would you like to download?
          </Typography>

          <Box display="flex" flexDirection={"row"} mt="4px">
            <RadioWithTitle
              title="PDF (.pdf)"
              selected={documentType === DocumentType.pdf}
              onSelected={() => setDocumentType(DocumentType.pdf)}
            />
            <Box width="15px" />
            <RadioWithTitle
              title="Word document (.docx)"
              selected={documentType === DocumentType.word}
              onSelected={() => setDocumentType(DocumentType.word)}
            />
          </Box>

          <Typography
            variant="body1"
            style={{ fontWeight: 700, marginTop: "24px" }}
          >
            Would you like to include comments?
          </Typography>

          <Box display="flex" flexDirection={"row"} mt="4px">
            <RadioWithTitle
              title="Yes"
              selected={includeComments === true}
              onSelected={() => setIncludeComments(true)}
            />
            <Box width="15px" />
            <RadioWithTitle
              title="No"
              selected={includeComments === false}
              onSelected={() => setIncludeComments(false)}
            />
          </Box>
        </Box>

        <Box
          height="74px"
          boxShadow={"0px -1px 0px #E0E0E0"}
          display="flex"
          flexDirection={"row"}
          alignItems="center"
          justifyContent={"flex-end"}
          pr="16px"
          boxSizing="border-box"
        >
          <Button
            type={ButtonType.secondary}
            title="CANCEL"
            width="114px"
            onClick={onCancel}
          />
          <Box width="8px" />

          {documentType === DocumentType.pdf && (
            <div>
              <PDFDownloadLink
                document={
                  <PDFDocument
                    summaries={summariesWithComments}
                    property={property}
                    contract={contract}
                    contractS={contractS}
                    recordId={recordId}
                    userTeamMemberRole={userTeamMemberRole}
                    comments={includeComments}
                    legalReviewForName={legalReviewForName}
                  />
                }
                fileName={`${filename}.pdf`}
                style={{ textDecoration: "none" }}
              >
                {({ blob, url, loading, error }) => {
                  return (
                    <Button
                      loading={loading || !commentsLoaded}
                      title={"DOWNLOAD"}
                      type={ButtonType.primary}
                      width="136px"
                    />
                  );
                }}
              </PDFDownloadLink>
            </div>
          )}

          {documentType !== DocumentType.pdf && (
            <Button
              type={ButtonType.primary}
              title="DOWNLOAD"
              width="136px"
              onClick={() => {
                if (documentType === DocumentType.word) {
                  generateLegalReviewDocxAndSave({
                    summaries,
                    property,
                    contract,
                    contractId: contract.id,
                    recordId,
                    includeComments,
                    legalReviewForName,
                  });
                }
              }}
            />
          )}
        </Box>
      </Box>
    </Dialog>
  );
}
