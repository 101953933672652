import React from "react";
import { useParams } from "react-router-dom";
import { useMobile } from "../hooks/mobile";
import PartnerPageHeader from "../components/PartnerLandingPage/PartnerLandingPageHeader";
import { Box, Typography } from "@material-ui/core";
import { Colors } from "../constants/colors";
import Button from "@material-ui/core/Button";
import { withStyles, Theme } from "@material-ui/core/styles";
import PartnerLandingPageFooter from "../components/PartnerLandingPage/PartnerLandingPageFooter";
import TickIcon from "../images/Tick.svg";
import whiteTick from "../images/white-tick.svg";
import prtlandingpagepic1 from "../images/prtlandingpagepic1.png";
import BeforeIcon from "../images/Before-Icon.svg";
import AfterIcon from "../images/After-Icon.svg";
import clock from "../images/buyer-reason-2.png";
import Confirmation from "../images/Confirmation.png";
import SignUp from "../images/Sign-Up.svg";
import Running from "../images/Running.svg";
import RadioBlue from "../images/radio-blue.svg";
import BLUELOGO from "../images/Blue.png";
import mobile2 from "../images/mobile2.png";
import prtlandingpagepic2 from "../images/prtlandingpagepic2.png";
import { Organisation } from "../api/models";
import useSWR from "swr";
import PartnerAddOnProperty from "../components/PartnerLandingPage/PartnerAddOnProperty";
import StatusPill, { StatusPillType } from "../components/StatusPill";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";

interface Params {
  uuid?: string;
  x;
}

const ColorButton3 = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: Colors.BrandBackground3,
    width: "200px",
    height: "60px",
    borderRadius: "8px",
    fontWeight: 700,
    fontSize: "16px",
    color: Colors.BrandPrimary,
    letterSpacing: "2px",
    "&:hover": {
      backgroundColor: Colors.BrandBackground2,
      color: Colors.BrandPrimary,
    },
  },
}))(Button);

const ToggleButton1 = withStyles({
  root: {
    height: "64px",
    width: "104px",
    borderColor: Colors.BrandBackground3,
    "&:hover": {
      backgroundColor: Colors.White,
      borderColor: Colors.BrandBackground3,
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: Colors.BrandBackground2,
    },
  },
})(ToggleButton);

const ToggleButton2 = withStyles({
  root: {
    height: "64px",
    width: "104px",
    borderColor: Colors.BrandBackground3,
    "&:hover": {
      backgroundColor: Colors.White,
      borderColor: Colors.BrandBackground3,
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: Colors.BrandBackground2,
    },
  },
})(ToggleButton);

const ToggleButton3 = withStyles({
  root: {
    height: "64px",
    width: "104px",
    borderColor: Colors.BrandBackground3,
    "&:hover": {
      backgroundColor: Colors.White,
      borderColor: Colors.BrandBackground3,
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: Colors.BrandBackground2,
    },
  },
})(ToggleButton);

export default function MyLandingPage() {
  const isMobile = useMobile();

  const sidePadding = isMobile ? "24px" : "172px";

  const textVariant = isMobile ? "body2" : "body1";

  // styling buttons

  const ColorButton = withStyles((theme: Theme) => ({
    root: {
      backgroundColor: Colors.BrandPrimary,
      width: isMobile ? "100%" : "200px",
      height: "60px",
      borderRadius: "8px",
      fontWeight: 700,
      fontSize: "16px",
      color: Colors.White,
      letterSpacing: "2px",
      "&:hover": {
        backgroundColor: Colors.BrandBackground2,
        color: Colors.BrandPrimary,
      },
    },
  }))(Button);

  const ColorButton2 = withStyles((theme: Theme) => ({
    root: {
      backgroundColor: Colors.BrandBackground3,
      width: isMobile ? "100%" : "200px",
      height: "60px",
      borderRadius: "8px",
      fontWeight: 700,
      fontSize: "16px",
      color: Colors.BrandPrimary,
      letterSpacing: "2px",
      "&:hover": {
        backgroundColor: Colors.BrandPrimary,
        color: Colors.White,
      },
    },
  }))(Button);

  const contactUsClicked = () => {
    window.open("https://contrax.com.au/contact-us", "_blank");
  };

  const { uuid } = useParams<Params>();

  React.useEffect(() => {
    if (uuid) {
      localStorage.setItem("uuid", uuid);
    }
  }, [uuid]);

  const { data: orgData } = useSWR(`/organization/${uuid}/`);

  const organisation = orgData ? (orgData as Organisation) : undefined;

  const [buttonText, setButtonText] = React.useState("85");

  // const [alignment, setAlignment] = React.useState("");

  // const handleAlignment = (e) => {
  //   setAlignment(e.value);
  // };

  const [alignment, setAlignment] = React.useState<string | null>("left");

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    setAlignment(newAlignment);
  };

  // console.debug("***uuid: ", uuid);
  // console.debug("organisation: ", organisation);

  return (
    <>
      <PartnerPageHeader uuid={uuid} />

      {!isMobile && (
        <Box
          display="flex"
          flexDirection="column"
          // bgcolor={Colors.BrandBackground2}

          justifyContent="center"
          alignItems="center"
          bgcolor="#E1EAFF"
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            pl={sidePadding}
            pr={sidePadding}
            id="getStarted-button"
          >
            <Box mt="184px" height="539px" width="539px">
              <Typography
                variant="h3"
                style={{ fontWeight: 700, marginBottom: "8px" }}
              >
                Get set to make an offer fast
              </Typography>
              <Typography
                variant="h5"
                style={{ fontWeight: 400, marginBottom: "32px" }}
              >
                Every conveyance starts with a Contrax legal review
              </Typography>
              <Box
                display="flex"
                flexDirection="row"
                mt="16px"
                alignItems="flex-start"
              >
                <img
                  src={TickIcon}
                  style={{ marginRight: "8px" }}
                  alt="tickicon"
                />
                <Typography variant={textVariant}>
                  Get started for just $85
                </Typography>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                mt="10px"
                alignItems="flex-start"
              >
                <img
                  src={TickIcon}
                  style={{ marginRight: "8px" }}
                  alt="tickicon"
                />
                <Typography variant={textVariant}>
                  Prepared by property lawyers
                </Typography>
              </Box>

              {/* <TextField value="Enter the property address" /> */}
              <PartnerAddOnProperty />
            </Box>
            <Box>
              <img
                src={prtlandingpagepic1}
                height="593px"
                width="520px"
                style={{ marginLeft: "19px" }}
                alt="Preview of Contrax Logo"
              />
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            pl={sidePadding}
            pr={sidePadding}
            mb={14}
          >
            <Box width="539px">
              <Box
                display="flex"
                flexDirection="row"
                // justifyContent="center"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  // justifyContent="center"
                  width="140px"
                  mr={3}
                >
                  <img
                    src={BeforeIcon}
                    height="140px"
                    width="140px"
                    alt="Preview of Contrax Logo"
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  // justifyContent="center"
                  width="auto"
                >
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 700, marginBottom: "8px" }}
                  >
                    Before Contrax:
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ fontWeight: 400, marginBottom: "32px" }}
                  >
                    You're on the back foot. Agents are calling. Competing
                    offers are rolling in. But you're stuck looking for a lawyer
                    and waiting for them to get back to you.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box width="539px">
              <Box
                display="flex"
                flexDirection="row"
                // justifyContent="center"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  // justifyContent="center"
                  width="140px"
                  mr={3}
                >
                  <img
                    src={AfterIcon}
                    height="140px"
                    width="140px"
                    alt="Preview of Contrax Logo"
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  // justifyContent="center"
                  width="auto"
                >
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 700, marginBottom: "8px" }}
                  >
                    After Contrax:
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ fontWeight: 400, marginBottom: "32px" }}
                  >
                    You're prepared and in control. You quickly understand any
                    issues with the contract and move fast to personalise it to
                    your needs.You're all set.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor={Colors.Grey5}
            width="100%"
            mt={10}
          >
            <Box
              display="flex"
              flexDirection="row"
              mt="12%"
              mb={2}
              position="absolute"
            >
              <Box bgcolor="#FFF" width="260px" height={"260px"} p={2}>
                <Box
                  m="32px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Box
                    width="50px"
                    height="50px"
                    borderRadius="33px"
                    bgcolor={Colors.Grey5}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mb={3}
                    mt={3}
                  >
                    <img src={clock} height="110px" width="141px" alt="Clock" />
                  </Box>

                  <Typography
                    variant="h6"
                    style={{
                      textAlign: "center",
                      fontWeight: 700,
                      marginBottom: "16px",
                      marginTop: "16px",
                    }}
                  >
                    Fast
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: 400,
                      marginBottom: "32px",
                      textAlign: "center",
                    }}
                  >
                    Contrax legal review in less than 24 hours.
                  </Typography>
                </Box>
              </Box>
              <Box bgcolor="#FFF" width={"260px"} height="260px" p={2}>
                <Box
                  m="32px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Box
                    width="50px"
                    height="50px"
                    borderRadius="33px"
                    bgcolor={Colors.Grey5}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mb={3}
                    mt={3}
                  >
                    <img
                      src={SignUp}
                      height="110px"
                      width="141px"
                      alt="SignUp"
                    />
                  </Box>

                  <Typography
                    variant="h6"
                    style={{
                      textAlign: "center",
                      fontWeight: 700,
                      marginBottom: "16px",
                      marginTop: "16px",
                    }}
                  >
                    Convenient
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: 400,
                      marginBottom: "32px",
                      textAlign: "center",
                    }}
                  >
                    Simple online experience. No jargon, no complexity.
                  </Typography>
                </Box>
              </Box>
              <Box bgcolor="#FFF" width={"260px"} height={"260px"} p={2}>
                <Box
                  m="32px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Box
                    width="50px"
                    height="50px"
                    borderRadius="33px"
                    bgcolor={Colors.Grey5}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mb={3}
                    mt={3}
                  >
                    <img
                      src={Confirmation}
                      height="110px"
                      width="141px"
                      alt="Confirmation"
                    />
                  </Box>

                  <Typography
                    variant="h6"
                    style={{
                      textAlign: "center",
                      fontWeight: 700,
                      marginBottom: "16px",
                      marginTop: "16px",
                    }}
                  >
                    Affordable
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: 400,
                      marginBottom: "32px",
                      textAlign: "center",
                    }}
                  >
                    Clear, simple and better pricing with no hidden fees.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor={Colors.Grey5}
            width="100%"
            height="1000px"
            mt={10}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              pl={sidePadding}
              pr={sidePadding}
              mb={14}
              mt={50}
            >
              <Box width="539px">
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Typography
                    variant="h4"
                    style={{ fontWeight: 700, marginBottom: "8px" }}
                  >
                    Easement running through the property? Land tax payable?
                    Swimming pool not compliant? Lucky for you, Contrax has got
                    you covered.
                  </Typography>
                  <img
                    src={Running}
                    height="380px"
                    width="380px"
                    alt="Preview of Contrax Logo"
                  />
                </Box>
              </Box>
              <Box width="539px">
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  width="auto"
                >
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: 400,
                      marginBottom: "24px",
                      marginTop: "82px",
                    }}
                  >
                    Avoid the current run-around and quickly get a handle on the
                    contract. That means no jargon, no complication and no
                    chasing lawyers to get back to you.
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      Comprehensive legal review
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      Prepared by property lawyers
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      Simple to understand and jargon-free
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      Cross-reference with the actual contract sale online
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      Unfair clauses highlighted
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      Negotiation with vendor included
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      Personalise the contract for you
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      Help you need, every step of the way
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor={Colors.Grey5}
            width="100%"
            height="712px"
          >
            <Box
              bgcolor="#FFF"
              width={"725px"}
              height={"396px"}
              borderRadius="24px"
            >
              <Box
                p={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mb={3}
                  mt={3}
                >
                  <img
                    src={BLUELOGO}
                    height="32px"
                    style={{ marginRight: "16px" }}
                    alt="bluelogo"
                  />
                  {organisation?.logo && (
                    <>
                      <Box
                        height="62px"
                        width="3px"
                        borderRadius="4px"
                        mr={2}
                        bgcolor={Colors.BrandPrimary}
                      />
                      <img
                        src={organisation?.logo}
                        height="40px"
                        style={{
                          maxWidth: "80%",
                          objectFit: "contain",
                        }}
                        alt={"Org Logo"}
                      />
                    </>
                  )}
                </Box>

                <Typography
                  variant="h5"
                  style={{
                    textAlign: "center",
                    fontWeight: 700,
                    marginBottom: "16px",
                    marginTop: "16px",
                    width: "310px",
                    color: Colors.BrandPrimary,
                  }}
                >
                  Contrax in partnership with {organisation?.name}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: 400,
                    marginBottom: "32px",
                    textAlign: "center",
                    color: Colors.BrandPrimary,
                  }}
                >
                  Expect remarkable legal service and unparalleled speed and
                  convenience powered by the latest technology brought to you by
                  the team at Contrax. With Contrax, buying a property is
                  seamless and straightforward, and you'll get to exchange
                  contracts quicker and settle on your new purchase with less
                  hassle.
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor="#FFF"
            width="100%"
            height="966px"
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box mt="184px" height="539px" width="539px">
                <Typography
                  variant="h4"
                  style={{ fontWeight: 700, marginBottom: "16px" }}
                >
                  By your side from contract review to exchange*
                </Typography>
                <Typography
                  variant="body1"
                  style={{ fontWeight: 400, marginBottom: "32px" }}
                >
                  We'll help you uncover any legal issues with the contract and
                  negotiate with the vendor's solicitor to remove unfair clauses
                  and personalise the deal to your individual needs.
                </Typography>
                <Box
                  display="flex"
                  flexDirection="row"
                  mt="16px"
                  alignItems="flex-start"
                >
                  {/* <img src={TickIcon} style={{ marginRight: "8px" }} /> */}
                  <Box
                    position="absolute"
                    width="3px"
                    height="26px"
                    mt="24px"
                    ml="10px"
                    bgcolor={Colors.BrandBackground1}
                  />
                  <Box
                    borderRadius="50%"
                    bgcolor={Colors.BrandBackground2}
                    width="24px"
                    height="24px"
                    mb={2}
                    mr={1}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: 700,
                        color: Colors.BrandBackground4,
                        margin: "1px",
                        textAlign: "center",
                      }}
                    >
                      1
                    </Typography>
                  </Box>
                  <Typography variant="body1">Search the property</Typography>
                </Box>

                <Box
                  display="flex"
                  flexDirection="row"
                  mt="10px"
                  alignItems="flex-start"
                >
                  {/* <img src={TickIcon} style={{ marginRight: "8px" }} /> */}
                  <Box
                    position="absolute"
                    width="3px"
                    height="26px"
                    mt="24px"
                    ml="10px"
                    bgcolor={Colors.BrandBackground1}
                  />
                  <Box
                    borderRadius="50%"
                    bgcolor={Colors.BrandBackground2}
                    width="24px"
                    height="24px"
                    mb={2}
                    mr={1}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: 700,
                        color: Colors.BrandBackground4,
                        margin: "1px",
                        textAlign: "center",
                      }}
                    >
                      2
                    </Typography>
                  </Box>
                  <Typography variant="body1">
                    Order online and get legal review in less than 24 hours
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  flexDirection="row"
                  mt="10px"
                  alignItems="flex-start"
                >
                  {/* <img src={TickIcon} style={{ marginRight: "8px" }} /> */}
                  <Box
                    position="absolute"
                    width="3px"
                    height="26px"
                    mt="24px"
                    ml="10px"
                    bgcolor={Colors.BrandBackground1}
                  />
                  <Box
                    borderRadius="50%"
                    bgcolor={Colors.BrandBackground2}
                    width="24px"
                    height="24px"
                    mb={2}
                    mr={1}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: 700,
                        color: Colors.BrandBackground4,
                        margin: "1px",
                        textAlign: "center",
                      }}
                    >
                      3
                    </Typography>
                  </Box>
                  <Typography variant="body1">
                    Negotiate contract terms on your behalf
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  flexDirection="row"
                  mt="10px"
                  alignItems="flex-start"
                >
                  {/* <img src={TickIcon} style={{ marginRight: "8px" }} /> */}

                  <Box
                    borderRadius="50%"
                    bgcolor={Colors.BrandBackground2}
                    width="24px"
                    height="24px"
                    mb={2}
                    mr={1}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: 700,
                        color: Colors.BrandBackground4,
                        margin: "1px",
                        textAlign: "center",
                      }}
                    >
                      4
                    </Typography>
                  </Box>
                  <Typography variant="body1">Make your offer</Typography>
                </Box>

                <Box mt={4}>
                  <Typography variant="body2">
                    *And all the way to settlement if you're successful with
                    your purchase and you choose to move forward with Contrax.
                  </Typography>
                </Box>
              </Box>
              <Box>
                <img
                  src={mobile2}
                  height="600px"
                  width="700px"
                  alt="Preview of Contrax Logo"
                />
                {/* <img
                  src={mobile2}
                  height="638px"
                  width="414px"
                  style={{ marginLeft: "19px" }}
                  alt="Preview of Contrax Logo"
                /> */}
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor="#FFF"
            width="100%"
            height="auto"
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              pl={sidePadding}
              pr={sidePadding}
              mb={14}
            >
              <Box width="539px">
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <img
                    src={prtlandingpagepic2}
                    height="637px"
                    width="502px"
                    alt="LandingPage"
                  />
                </Box>
              </Box>
              <Box width="539px">
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  width="auto"
                >
                  <Typography
                    variant="h4"
                    style={{
                      fontWeight: 700,
                      marginBottom: "16px",
                    }}
                  >
                    Get in the know before you make an offer
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: 400,
                      marginBottom: "32px",
                    }}
                  >
                    We get homebuyers and understand that the market is
                    fast-paced and competitive. That's why we're committed to
                    making sure our buyers are fully informed and ahead of the
                    game. So what are some of the things you risk missing in the
                    contract without a Contrax legal review?
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      <b>Short settlement date</b> which your 
                      <b>lender can't meet</b>
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      <b>Your deposit may be used</b> by the vendor for any
                      reason instead of remaining in the agent's trust account
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      <b>Land tax is payable</b> to the vendor on top of the
                      purchase price
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      There's an <b>easement running through the property</b>
                       which you <b>can't build over</b>
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      <b>Heritage restrictions</b> applying to the property
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      A <b>vendor lease back</b> for some time after settlement
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      <b>GST is payable</b> on the purchase price
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      The <b>swimming pool is not compliant</b> meaning you will
                      have to <b>make it within 90 days</b> following settlement
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      The property is a <b>deceased estate,</b>and the{" "}
                      <b>executors have months</b> in which to{" "}
                      <b>obtain probate</b> before you can settle
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            bgcolor={Colors.BrandBackground2}
            height="1332px"
            pl={sidePadding}
            pr={sidePadding}
          >
            <Box mt={13} mb={10} ml="62px">
              <Typography
                variant="h4"
                style={{ fontWeight: 700, color: Colors.BrandBackground4 }}
              >
                Clear, simple and better pricing
              </Typography>
              <Typography
                variant="h6"
                style={{
                  fontWeight: 400,
                  color: Colors.BrandBackground4,
                  marginTop: "8px",
                }}
              >
                No obligation. Never pay for more than you need.
              </Typography>
            </Box>
            <Box>
              <Box display="flex" flexDirection="column" width="850px">
                <Box display="flex" flexDirection="row" width="auto">
                  <Box
                    borderRadius="50%"
                    bgcolor={Colors.BrandPrimary}
                    width="38px"
                    height="38px"
                    mt={1}
                  >
                    <Typography
                      variant="h5"
                      style={{
                        fontWeight: 700,
                        color: "#FFF",
                        margin: "3px",
                        textAlign: "center",
                      }}
                    >
                      1
                    </Typography>
                  </Box>
                  <Box ml={3}>
                    <Typography
                      variant="h5"
                      style={{
                        fontWeight: 700,
                        color: Colors.BrandBackground4,
                      }}
                    >
                      Contract reviewed to exchange
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: 400,
                        color: Colors.Grey2,
                        marginTop: "8px",
                      }}
                    >
                      Contract review, advice, requests to seller reps, contract
                      amendment and exchange of contracts
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row">
                  <Box width="38px" display="flex" justifyContent="center">
                    <Box
                      height="444px"
                      width="4px"
                      bgcolor={Colors.BrandBackground1}
                    />
                  </Box>
                  <Box
                    ml={3}
                    mt={4}
                    width="740px"
                    height="362px"
                    bgcolor={Colors.White}
                    borderRadius="8px"
                    boxShadow="0px 0px 10px rgba(0, 0, 0, 0.25)"
                  >
                    <Box display="flex" flexDirection="row">
                      <Box width="400px" mt={3} ml={4}>
                        <Typography
                          variant="h6"
                          style={{ fontWeight: 400, color: Colors.Grey2 }}
                        >
                          How many contracts do you need?
                        </Typography>
                        <Box mt={2}>
                          <ToggleButtonGroup
                            exclusive
                            value={alignment}
                            onChange={handleAlignment}
                            aria-label="text alignment"
                          >
                            <ToggleButton1
                              onClick={() => {
                                setButtonText("85");
                              }}
                              value="left"
                              aria-label="left aligned"
                            >
                              <Typography
                                variant="h6"
                                style={{ fontWeight: 400, color: Colors.Grey2 }}
                              >
                                1
                              </Typography>
                            </ToggleButton1>
                            <ToggleButton2
                              onClick={() => {
                                setButtonText("76.7");
                              }}
                              value="center"
                              aria-label="centered"
                            >
                              {" "}
                              <Typography
                                variant="h6"
                                style={{ fontWeight: 400, color: Colors.Grey2 }}
                              >
                                3
                              </Typography>
                              <Box position="absolute" mt={8}>
                                <StatusPill
                                  type={StatusPillType.partnersave11}
                                />
                              </Box>
                            </ToggleButton2>

                            <ToggleButton3
                              onClick={() => {
                                setButtonText("68");
                              }}
                              value="right"
                              aria-label="right aligned"
                            >
                              {" "}
                              <Typography
                                variant="h6"
                                style={{ fontWeight: 400, color: Colors.Grey2 }}
                              >
                                5
                              </Typography>
                              <Box position="absolute" mt={8}>
                                <StatusPill
                                  type={StatusPillType.partnersave15}
                                />
                              </Box>
                            </ToggleButton3>
                          </ToggleButtonGroup>
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        m="auto"
                        mt={4}
                      >
                        <Typography
                          variant="h2"
                          style={{
                            fontWeight: 400,
                            color: Colors.BrandPrimary,
                            fontSize: "60px",
                          }}
                        >
                          ${buttonText}*
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ textAlign: "center" }}
                        >
                          per contract
                        </Typography>
                      </Box>
                    </Box>

                    <Box display="flex" flexDirection="row">
                      <Box width="100px" mt={6} mb={2} ml={4} mr={4}>
                        <Typography
                          variant="overline"
                          style={{ fontWeight: 700, color: Colors.Grey2 }}
                        >
                          Good to know
                        </Typography>
                      </Box>
                      <Box mr={3} display="flex" flexDirection="row" mt="58px">
                        <Box
                          height="1px"
                          width="552px"
                          bgcolor={Colors.BrandBackground3}
                        />
                      </Box>
                    </Box>

                    <Box display="flex" flexDirection="row" ml={4} mr={3}>
                      <Box height="auto" width="400px">
                        <Box
                          display="flex"
                          flexDirection="row"
                          mt="16px"
                          alignItems="flex-start"
                        >
                          <img
                            src={TickIcon}
                            style={{ marginRight: "8px" }}
                            alt="tickicon"
                          />
                          <Typography variant="body2">
                            Nothing more to pay if you miss out or don’t proceed
                          </Typography>
                        </Box>

                        <Box
                          display="flex"
                          flexDirection="row"
                          mt="10px"
                          alignItems="flex-start"
                        >
                          <img
                            src={TickIcon}
                            style={{ marginRight: "8px" }}
                            alt="tickicon"
                          />
                          <Typography variant="body2">
                            Contract reviews never expire and be used at any
                            time
                          </Typography>
                        </Box>

                        <Box
                          display="flex"
                          flexDirection="row"
                          mt="10px"
                          alignItems="flex-start"
                          mb={5}
                        >
                          <img
                            src={TickIcon}
                            style={{ marginRight: "8px" }}
                            alt="tickicon"
                          />
                          <Typography variant="body2">
                            Offset unused credits against your final
                            conveyancing fee.
                          </Typography>
                        </Box>
                      </Box>

                      <Box display="flex" flexDirection="row">
                        <Box width="200px" mt={4} ml={7} mr={3}>
                          <ColorButton href="#getStarted-button">
                            Get Started
                          </ColorButton>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box display="flex" flexDirection="row" width="auto">
                  <Box
                    borderRadius="50%"
                    bgcolor={Colors.BrandPrimary}
                    width="38px"
                    height="38px"
                    mt={3}
                  >
                    <Typography
                      variant="h5"
                      style={{
                        fontWeight: 700,
                        color: Colors.White,
                        margin: "3px",
                        textAlign: "center",
                      }}
                    >
                      2
                    </Typography>
                  </Box>
                  <Box ml={3}>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: 400,
                        color: Colors.BrandBackground4,
                        marginBottom: "4px",
                      }}
                    >
                      Optional
                    </Typography>
                    <Typography
                      variant="h5"
                      style={{
                        fontWeight: 700,
                        color: Colors.BrandBackground4,
                      }}
                    >
                      Exchange to settlement
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: 400,
                        color: Colors.Grey2,
                        marginTop: "8px",
                      }}
                    >
                      Once your offer has been approved we can help you with the
                      settlement
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex" flexDirection="row">
                  <Box width="38px" display="flex" justifyContent="center">
                    <Box
                      height="413px"
                      width="4px"
                      bgcolor={Colors.BrandBackground1}
                    />
                  </Box>
                  <Box
                    ml={3}
                    mt={4}
                    width="740px"
                    height="314px"
                    bgcolor={Colors.BrandBackground4}
                    borderRadius="8px"
                    boxShadow="0px 0px 10px rgba(0, 0, 0, 0.25)"
                  >
                    <Box display="flex" flexDirection="row">
                      <Box width="400px" mt={3} ml={4}>
                        <Typography
                          variant="h5"
                          style={{
                            fontWeight: 400,
                            color: Colors.BrandBackground3,
                          }}
                        >
                          Conveyance
                        </Typography>
                        <Box mt={2}></Box>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        m="auto"
                        mt={3}
                      >
                        <Typography
                          variant="h2"
                          style={{
                            fontWeight: 400,
                            color: Colors.White,
                            fontSize: "60px",
                          }}
                        >
                          $1,199*
                        </Typography>
                      </Box>
                    </Box>

                    <Box display="flex" flexDirection="row">
                      <Box width="100px" mt={6} mb={2} ml={4} mr={4}>
                        <Typography
                          variant="overline"
                          style={{ fontWeight: 700, color: Colors.Grey5 }}
                        >
                          Good to know
                        </Typography>
                      </Box>
                      <Box mr={3} display="flex" flexDirection="row" mt="58px">
                        <Box
                          height="1px"
                          width="552px"
                          bgcolor={Colors.Grey5}
                        />
                      </Box>
                    </Box>

                    <Box display="flex" flexDirection="row" ml={4} mr={3}>
                      <Box height="auto" width="400px">
                        <Box
                          display="flex"
                          flexDirection="row"
                          mt="16px"
                          alignItems="flex-start"
                        >
                          <img
                            src={whiteTick}
                            style={{
                              marginRight: "14px",
                              marginTop: "auto",
                              marginBottom: "auto",
                              marginLeft: "4px",
                            }}
                            alt="whitetick"
                          />
                          <Typography
                            variant="body2"
                            style={{ color: Colors.Grey5 }}
                          >
                            1:1 with one of our Lawyers
                          </Typography>
                        </Box>

                        <Box
                          display="flex"
                          flexDirection="row"
                          mt="10px"
                          alignItems="flex-start"
                        >
                          <img
                            src={whiteTick}
                            style={{
                              marginRight: "14px",
                              marginTop: "auto",
                              marginBottom: "auto",
                              marginLeft: "4px",
                            }}
                            alt="whitetick"
                          />
                          <Typography
                            variant="body2"
                            style={{ color: Colors.Grey5 }}
                          >
                            You will only pay at success
                          </Typography>
                        </Box>

                        <Box
                          display="flex"
                          flexDirection="row"
                          mt="10px"
                          alignItems="flex-start"
                          mb={5}
                        >
                          <img
                            src={whiteTick}
                            style={{
                              marginRight: "14px",
                              marginTop: "auto",
                              marginBottom: "auto",
                              marginLeft: "4px",
                            }}
                            alt="whitetick"
                          />
                          <Typography
                            variant="body2"
                            style={{ color: Colors.Grey5 }}
                          >
                            Offset unused credits against your final
                            conveyancing fee
                          </Typography>
                        </Box>
                      </Box>

                      <Box display="flex" flexDirection="row">
                        <Box width="200px" mt={4} ml={7} mr={3}>
                          <ColorButton2 onClick={contactUsClicked}>
                            CONTACT US
                          </ColorButton2>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box display="flex" flexDirection="row" width="auto">
                  <Box
                    borderRadius="50%"
                    bgcolor={Colors.BrandPrimary}
                    width="38px"
                    height="38px"
                    mt="14px"
                  >
                    <Typography
                      variant="h5"
                      style={{
                        fontWeight: 700,
                        color: Colors.White,
                        margin: "3px",
                        textAlign: "center",
                      }}
                    >
                      3
                    </Typography>
                  </Box>
                  <Box ml={3}>
                    <Typography
                      variant="h5"
                      style={{
                        fontWeight: 700,
                        color: Colors.BrandBackground4,
                      }}
                    >
                      🎉 Success!
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: 400,
                        color: Colors.Grey2,
                        marginTop: "8px",
                      }}
                    >
                      Congratulationss on your new home/investment
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box display="flex" justifyContent="center" mb={13} mt={7}>
            <Typography
              variant="body2"
              style={{ fontWeight: 400, color: Colors.Grey2 }}
            >
              *All the costs are including GST
            </Typography>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            pl={sidePadding}
            pr={sidePadding}
            bgcolor={Colors.BrandPrimary}
            height="302px"
            width="-webkit-fill-available"
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              textAlign="center"
            >
              <Box>
                <Typography
                  variant="h3"
                  style={{
                    fontWeight: 700,
                    color: Colors.White,
                    marginBottom: "48px",
                  }}
                >
                  Ready to get started?
                </Typography>

                <ColorButton3 href="#getStarted-button">
                  Let’s Begin
                </ColorButton3>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {/* Mobile view */}

      {isMobile && (
        <Box
          display="flex"
          flexDirection="column"
          // bgcolor={Colors.BrandBackground2}

          justifyContent="center"
          alignItems="center"
          bgcolor="#E1EAFF"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            pl={sidePadding}
            pr={sidePadding}
            id="getStarted-button-mobile"
          >
            <Box mt="50px">
              <Typography
                variant="h4"
                style={{ fontWeight: 700, marginBottom: "8px" }}
              >
                Get set to make an offer fast
              </Typography>
              <Typography
                variant="body1"
                style={{ fontWeight: 700, marginBottom: "32px" }}
              >
                Every conveyance starts with a Contrax legal review
              </Typography>
              <Box
                display="flex"
                flexDirection="row"
                mt="16px"
                alignItems="flex-start"
              >
                <img
                  src={TickIcon}
                  style={{ marginRight: "8px" }}
                  alt="tickicon"
                />
                <Typography variant="body2" style={{ fontWeight: 400 }}>
                  Get started for just $85
                </Typography>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                mt="10px"
                alignItems="flex-start"
              >
                <img
                  src={TickIcon}
                  style={{ marginRight: "8px" }}
                  alt="tickicon"
                />
                <Typography variant="body2" style={{ fontWeight: 400 }}>
                  Prepared by property lawyers
                </Typography>
              </Box>

              {/* <TextField value="Enter the property address" /> */}
              <PartnerAddOnProperty />
            </Box>
            <Box mt={5}>
              <img
                src={prtlandingpagepic1}
                height="373px"
                width="327px"
                alt="Preview of Contrax Logo"
              />
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            pl={sidePadding}
            pr={sidePadding}
            mt={10}
            height="1200px"
          >
            <Box textAlign="center" mt={5}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  mb={2}
                >
                  <img
                    src={BeforeIcon}
                    height="140px"
                    width="140px"
                    alt="Preview of Contrax Logo"
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  width="auto"
                  alignItems="center"
                >
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 700, marginBottom: "8px" }}
                  >
                    Before Contrax:
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ fontWeight: 400, marginBottom: "72px" }}
                  >
                    You're on the back foot. Agents are calling. Competing
                    offers are rolling in. But you're stuck looking for a lawyer
                    and waiting for them to get back to you.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box textAlign="center">
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width="140px"
                  mb={2}
                >
                  <img
                    src={AfterIcon}
                    height="140px"
                    width="140px"
                    alt="Preview of Contrax Logo"
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width="auto"
                >
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 700, marginBottom: "8px" }}
                  >
                    After Contrax:
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ fontWeight: 400, marginBottom: "32px" }}
                  >
                    You're prepared and in control. You quickly understand any
                    issues with the contract and move fast to personalise it to
                    your needs.You're all set.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor={Colors.Grey5}
            pl={sidePadding}
            pr={sidePadding}
          >
            <Box
              width="64%"
              display="flex"
              flexDirection="column"
              mt="12%"
              mb={2}
              position="absolute"
              mr={3}
            >
              <Box bgcolor="#FFF" width="100%" height={"284px"} p={2}>
                <Box
                  m="32px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Box
                    width="50px"
                    height="50px"
                    borderRadius="33px"
                    bgcolor={Colors.Grey5}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mb={3}
                    mt={3}
                  >
                    <img src={clock} height="110px" width="141px" alt="Clock" />
                  </Box>

                  <Typography
                    variant="h6"
                    style={{
                      textAlign: "center",
                      fontWeight: 700,
                      marginBottom: "16px",
                      marginTop: "16px",
                    }}
                  >
                    Fast
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: 400,
                      marginBottom: "32px",
                      textAlign: "center",
                    }}
                  >
                    Contrax legal review in less than 24 hours.
                  </Typography>
                </Box>
              </Box>
              <Box bgcolor="#FFF" width="100%" height="260px" p={2}>
                <Box
                  m="32px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Box
                    width="50px"
                    height="50px"
                    borderRadius="33px"
                    bgcolor={Colors.Grey5}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mb={3}
                    mt={3}
                  >
                    <img
                      src={SignUp}
                      height="110px"
                      width="141px"
                      alt="SignUp"
                    />
                  </Box>

                  <Typography
                    variant="h6"
                    style={{
                      textAlign: "center",
                      fontWeight: 700,
                      marginBottom: "16px",
                      marginTop: "16px",
                    }}
                  >
                    Convenient
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: 400,
                      marginBottom: "32px",
                      textAlign: "center",
                    }}
                  >
                    Simple online experience. No jargon, no complexity.
                  </Typography>
                </Box>
              </Box>
              <Box bgcolor="#FFF" width="100%" height={"260px"} p={2}>
                <Box
                  m="32px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Box
                    width="50px"
                    height="50px"
                    borderRadius="33px"
                    bgcolor={Colors.Grey5}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mb={3}
                    mt={3}
                  >
                    <img
                      src={Confirmation}
                      height="110px"
                      width="141px"
                      alt="Confirmation"
                    />
                  </Box>

                  <Typography
                    variant="h6"
                    style={{
                      textAlign: "center",
                      fontWeight: 700,
                      marginBottom: "16px",
                      marginTop: "16px",
                    }}
                  >
                    Affordable
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: 400,
                      marginBottom: "32px",
                      textAlign: "center",
                    }}
                  >
                    Clear, simple and better pricing with no hidden fees.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor={Colors.Grey5}
            pl={sidePadding}
            pr={sidePadding}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              mb={14}
              mt="555px"
            >
              <Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    variant="h4"
                    style={{ fontWeight: 700, marginBottom: "8px" }}
                  >
                    Easement running through the property? Land tax payable?
                    Swimming pool not compliant? Lucky for you, Contrax has got
                    you covered.
                  </Typography>
                  <img
                    src={Running}
                    height="373px"
                    width="327px"
                    alt="Preview of Contrax Logo"
                  />
                </Box>
              </Box>
              <Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  width="auto"
                >
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: 400,
                      marginBottom: "24px",
                    }}
                  >
                    Avoid the current run-around and quickly get a handle on the
                    contract. That means no jargon, no complication and no
                    chasing lawyers to get back to you.
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                    ml={1}
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      Comprehensive legal review
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                    ml={1}
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      Prepared by property lawyers
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                    ml={1}
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      Simple to understand and jargon-free
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                    ml={1}
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      Cross-reference with the actual contract sale online
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                    ml={1}
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      Unfair clauses highlighted
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                    ml={1}
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      Negotiation with vendor included
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                    ml={1}
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      Personalise the contract for you
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                    ml={1}
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      Help you need, every step of the way
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor={Colors.Grey5}
            height="620px"
            pl={sidePadding}
            pr={sidePadding}
          >
            <Box bgcolor="#FFF" height="508px" borderRadius="24px">
              <Box
                p={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  p="inherit"
                >
                  <img
                    src={BLUELOGO}
                    height="32px"
                    style={{
                      marginLeft: "24px",
                      marginRight: "12px",
                      // maxWidth: "80%",
                      // objectFit: "contain",
                    }}
                    alt="bluelogo"
                  />
                  {organisation?.logo && (
                    <>
                      <Box
                        height="54px"
                        width="2px"
                        borderRadius="4px"
                        mr={1}
                        bgcolor={Colors.BrandPrimary}
                      />
                      <img
                        src={organisation?.logo}
                        height="32px"
                        style={{
                          maxWidth: "60%",
                          marginRight: "22px",
                          objectFit: "contain",
                        }}
                        alt={"Org Logo"}
                      />
                    </>
                  )}
                </Box>

                <Typography
                  variant="h5"
                  style={{
                    textAlign: "left",
                    fontWeight: 700,
                    marginBottom: "16px",
                    marginTop: "16px",

                    color: Colors.BrandPrimary,
                  }}
                >
                  Contrax in partnership with {organisation?.name}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: 400,
                    marginBottom: "32px",
                    textAlign: "left",
                    color: Colors.BrandPrimary,
                  }}
                >
                  Expect remarkable legal service and unparalleled speed and
                  convenience powered by the latest technology brought to you by
                  the team at Contrax. With Contrax, buying a property is
                  seamless and straightforward, and you'll get to exchange
                  contracts quicker and settle on your new purchase with less
                  hassle.
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor="#FFF"
            height="966px"
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Box mt="122px" height="539px" pl={sidePadding} pr={sidePadding}>
                <Typography
                  variant="h4"
                  style={{ fontWeight: 700, marginBottom: "16px" }}
                >
                  By your side from contract review to exchange*
                </Typography>
                <Typography
                  variant="body1"
                  style={{ fontWeight: 400, marginBottom: "32px" }}
                >
                  We'll help you uncover any legal issues with the contract and
                  negotiate with the vendor's solicitor to remove unfair clauses
                  and personalise the deal to your individual needs.
                </Typography>
                <Box
                  display="flex"
                  flexDirection="row"
                  mt="16px"
                  alignItems="flex-start"
                >
                  <Box
                    borderRadius="50%"
                    bgcolor={Colors.BrandBackground2}
                    width="24px"
                    height="24px"
                    mb={2}
                    mr={1}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: 700,
                        color: Colors.BrandBackground4,
                        margin: "1px",
                        textAlign: "center",
                      }}
                    >
                      1
                    </Typography>
                  </Box>
                  <Typography variant="body1">Search the property</Typography>
                </Box>

                <Box
                  display="flex"
                  flexDirection="row"
                  mt="10px"
                  alignItems="flex-start"
                >
                  <Box
                    borderRadius="50%"
                    bgcolor={Colors.BrandBackground2}
                    width="26px"
                    height="24px"
                    mb={2}
                    mr={1}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: 700,
                        color: Colors.BrandBackground4,
                        margin: "1px",
                        textAlign: "center",
                      }}
                    >
                      2
                    </Typography>
                  </Box>
                  <Typography variant="body1">
                    Order online and get legal review in less than 24 hours
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  flexDirection="row"
                  mt="10px"
                  alignItems="flex-start"
                >
                  <Box
                    borderRadius="50%"
                    bgcolor={Colors.BrandBackground2}
                    width="24px"
                    height="24px"
                    mb={2}
                    mr={1}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: 700,
                        color: Colors.BrandBackground4,
                        margin: "1px",
                        textAlign: "center",
                      }}
                    >
                      3
                    </Typography>
                  </Box>
                  <Typography variant="body1">
                    Negotiate contract terms on your behalf
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  flexDirection="row"
                  mt="10px"
                  alignItems="flex-start"
                >
                  <Box
                    borderRadius="50%"
                    bgcolor={Colors.BrandBackground2}
                    width="24px"
                    height="24px"
                    mb={2}
                    mr={1}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: 700,
                        color: Colors.BrandBackground4,
                        margin: "1px",
                        textAlign: "center",
                      }}
                    >
                      4
                    </Typography>
                  </Box>
                  <Typography variant="body1">Make your offer</Typography>
                </Box>

                <Box mt={4}>
                  <Typography variant="body2">
                    *And all the way to settlement if you're successful with
                    your purchase and you choose to move forward with Contrax.
                  </Typography>
                </Box>
              </Box>
              <Box>
                <img
                  src={mobile2}
                  height="373px"
                  width="382px"
                  // style={{ marginLeft: "19px" }}
                  alt="Preview of Contrax Logo"
                />
                {/* <img
                  src={mobile2}
                  height="638px"
                  width="414px"
                  style={{ marginLeft: "19px" }}
                  alt="Preview of Contrax Logo"
                /> */}
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor="#FFF"
            height="1494px"
            pl={sidePadding}
            pr={sidePadding}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              mb={14}
            >
              <Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  width="auto"
                >
                  <Typography
                    variant="h4"
                    style={{
                      fontWeight: 700,
                      marginBottom: "16px",
                    }}
                  >
                    Get in the know before you make an offer
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: 400,
                      marginBottom: "32px",
                    }}
                  >
                    We get homebuyers and understand that the market is
                    fast-paced and competitive. That's why we're committed to
                    making sure our buyers are fully informed and ahead of the
                    game. So what are some of the things you risk missing in the
                    contract without a Contrax legal review?
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      <b>Short settlement date</b> which your 
                      <b>lender can't meet</b>
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      <b>Your deposit may be used</b> by the vendor for any
                      reason instead of remaining in the agent's trust account
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      <b>Land tax is payable</b> to the vendor on top of the
                      purchase price
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      There's an <b>easement running through the property</b>
                       which you <b>can't build over</b>
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      <b>Heritage restrictions</b> applying to the property
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      A <b>vendor lease back</b> for some time after settlement
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      <b>GST is payable</b> on the purchase price
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      The <b>swimming pool is not compliant</b> meaning you will
                      have to <b>make it within 90 days</b> following settlement
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="center"
                  >
                    <img
                      src={RadioBlue}
                      style={{ marginRight: "16px" }}
                      alt="radioblue"
                    />
                    <Typography variant={textVariant}>
                      The property is a <b>deceased estate,</b>and the{" "}
                      <b>executors have months</b> in which to{" "}
                      <b>obtain probate</b> before you can settle
                    </Typography>
                  </Box>
                </Box>
                <Box mt={6} textAlign="center">
                  <img
                    src={prtlandingpagepic2}
                    height="373px"
                    width="327px"
                    alt="LandingPage"
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            bgcolor={Colors.BrandBackground2}
            height="2160px"
            pl={sidePadding}
            pr={sidePadding}
          >
            <Box mt={7} mb={7}>
              <Typography
                variant="h4"
                style={{ fontWeight: 700, color: Colors.BrandBackground4 }}
              >
                Clear, simple and better pricing
              </Typography>
              <Typography
                variant="h6"
                style={{
                  fontWeight: 400,
                  color: Colors.BrandBackground4,
                  marginTop: "16px",
                }}
              >
                No obligation. Never pay for more than you need.
              </Typography>
            </Box>

            <Box display="flex" flexDirection="column">
              <Box
                borderRadius="50%"
                bgcolor={Colors.BrandPrimary}
                width="38px"
                height="38px"
                mb={2}
              >
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: 700,
                    color: "#FFF",
                    margin: "3px",
                    textAlign: "center",
                  }}
                >
                  1
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: 700,
                    color: Colors.BrandBackground4,
                  }}
                >
                  Contract reviewed to exchange
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: 400,
                    color: Colors.Grey2,
                    marginTop: "8px",
                  }}
                >
                  Contract review, advice, requests to seller reps, contract
                  amendment and exchange of contracts
                </Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column">
              <Box
                mt={4}
                width="auto"
                height="656px"
                bgcolor={Colors.White}
                borderRadius="8px"
                boxShadow="0px 0px 10px rgba(0, 0, 0, 0.25)"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  textAlign="center"
                  m={2}
                >
                  <Box mt={1}>
                    <Typography
                      variant="h6"
                      style={{ fontWeight: 400, color: Colors.Grey2 }}
                    >
                      How many contracts do you need?
                    </Typography>
                    <Box display="flex" flexDirection="column" mt={3}>
                      <Typography
                        variant="h2"
                        style={{
                          fontWeight: 400,
                          color: Colors.BrandPrimary,
                          fontSize: "60px",
                        }}
                      >
                        ${buttonText}*
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ textAlign: "center", marginTop: "8px" }}
                      >
                        per contract
                      </Typography>
                    </Box>
                    <Box mt={2}>
                      <ToggleButtonGroup
                        exclusive
                        value={alignment}
                        onChange={handleAlignment}
                        aria-label="text alignment"
                      >
                        <ToggleButton1
                          onClick={() => {
                            setButtonText("85");
                          }}
                          value="left"
                          aria-label="left aligned"
                        >
                          <Typography
                            variant="h6"
                            style={{ fontWeight: 400, color: Colors.Grey2 }}
                          >
                            1
                          </Typography>
                        </ToggleButton1>
                        <ToggleButton2
                          onClick={() => {
                            setButtonText("76.7");
                          }}
                          value="center"
                          aria-label="centered"
                        >
                          {" "}
                          <Typography
                            variant="h6"
                            style={{ fontWeight: 400, color: Colors.Grey2 }}
                          >
                            3
                          </Typography>
                          <Box position="absolute" mt={8}>
                            <StatusPill type={StatusPillType.partnersave11} />
                          </Box>
                        </ToggleButton2>

                        <ToggleButton3
                          onClick={() => {
                            setButtonText("68");
                          }}
                          value="right"
                          aria-label="right aligned"
                        >
                          {" "}
                          <Typography
                            variant="h6"
                            style={{ fontWeight: 400, color: Colors.Grey2 }}
                          >
                            5
                          </Typography>
                          <Box position="absolute" mt={8}>
                            <StatusPill type={StatusPillType.partnersave15} />
                          </Box>
                        </ToggleButton3>
                      </ToggleButtonGroup>
                      <Box mt={4} mb={4}>
                        <ColorButton href="#getStarted-button-mobile">
                          Get Started
                        </ColorButton>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box display="flex" flexDirection="row" m={2}>
                  <Box mb={2}>
                    <Typography
                      variant="overline"
                      style={{ fontWeight: 700, color: Colors.Grey2 }}
                    >
                      Good to know
                    </Typography>
                  </Box>
                  <Box
                    ml="inherit"
                    display="flex"
                    flexDirection="row"
                    mt="10px"
                  >
                    <Box
                      height="2px"
                      width="192px"
                      bgcolor={Colors.BrandBackground3}
                    />
                  </Box>
                </Box>

                <Box height="auto" m={2}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="flex-start"
                  >
                    <img
                      src={TickIcon}
                      style={{ marginRight: "8px" }}
                      alt="tickicon"
                    />
                    <Typography variant="body2">
                      Nothing more to pay if you miss out or don’t proceed
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="10px"
                    alignItems="flex-start"
                  >
                    <img
                      src={TickIcon}
                      style={{ marginRight: "8px" }}
                      alt="tickicon"
                    />
                    <Typography variant="body2">
                      Contract reviews never expire and be used at any time
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="10px"
                    alignItems="flex-start"
                    mb={5}
                  >
                    <img
                      src={TickIcon}
                      style={{ marginRight: "8px" }}
                      alt="tickicon"
                    />
                    <Typography variant="body2">
                      Offset unused credits against your final conveyancing fee.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column">
              <Box
                borderRadius="50%"
                bgcolor={Colors.BrandPrimary}
                width="38px"
                height="38px"
                mb={2}
                mt={4}
              >
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: 700,
                    color: "#FFF",
                    margin: "3px",
                    textAlign: "center",
                  }}
                >
                  2
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: 400,
                    color: Colors.Grey2,
                    marginBottom: "8px",
                  }}
                >
                  Optional
                </Typography>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: 700,
                    color: Colors.BrandBackground4,
                  }}
                >
                  Exchange to settlement
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: 400,
                    color: Colors.Grey2,
                    marginTop: "8px",
                  }}
                >
                  Once your offer has been approved we can help you with the
                  settlement
                </Typography>
              </Box>
            </Box>

            <Box display="flex" flexDirection="column">
              <Box
                mt={4}
                width="auto"
                height="482px"
                bgcolor={Colors.BrandBackground4}
                borderRadius="8px"
                boxShadow="0px 0px 10px rgba(0, 0, 0, 0.25)"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  textAlign="center"
                  m={2}
                >
                  <Box mt={1}>
                    <Typography
                      variant="h6"
                      style={{ fontWeight: 400, color: Colors.White }}
                    >
                      Conveyance
                    </Typography>
                    <Box display="flex" flexDirection="column" mt={3}>
                      <Typography
                        variant="h2"
                        style={{
                          fontWeight: 400,
                          color: Colors.White,
                          fontSize: "60px",
                        }}
                      >
                        ${buttonText}*
                      </Typography>
                    </Box>

                    <Box mt={4} mb={4}>
                      <ColorButton2 href="#getStarted-button-mobile">
                        Contact Us
                      </ColorButton2>
                    </Box>
                  </Box>
                </Box>

                <Box display="flex" flexDirection="row" m={2}>
                  <Box mb={2}>
                    <Typography
                      variant="overline"
                      style={{ fontWeight: 700, color: Colors.White }}
                    >
                      Good to know
                    </Typography>
                  </Box>
                  <Box
                    ml="inherit"
                    display="flex"
                    flexDirection="row"
                    mt="10px"
                  >
                    <Box
                      height="2px"
                      width="192px"
                      bgcolor={Colors.BrandBackground3}
                    />
                  </Box>
                </Box>

                <Box height="auto" m={2}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="16px"
                    alignItems="flex-start"
                  >
                    <img
                      src={whiteTick}
                      style={{
                        marginRight: "14px",
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginLeft: "4px",
                      }}
                      alt="whitetick"
                    />
                    <Typography variant="body2" style={{ color: Colors.Grey5 }}>
                      1:1 with one of our Lawyers
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="10px"
                    alignItems="flex-start"
                  >
                    <img
                      src={whiteTick}
                      style={{
                        marginRight: "14px",
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginLeft: "4px",
                      }}
                      alt="whitetick"
                    />
                    <Typography variant="body2" style={{ color: Colors.Grey5 }}>
                      You will only pay at success
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="row"
                    mt="10px"
                    alignItems="flex-start"
                    mb={5}
                  >
                    <img
                      src={whiteTick}
                      style={{
                        marginRight: "14px",
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginLeft: "4px",
                      }}
                      alt="whitetick"
                    />
                    <Typography variant="body2" style={{ color: Colors.Grey5 }}>
                      Offset unused credits against your final conveyancing fee
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex" flexDirection="column">
                  <Box
                    borderRadius="50%"
                    bgcolor={Colors.BrandPrimary}
                    width="38px"
                    height="38px"
                    mb={2}
                    mt={4}
                  >
                    <Typography
                      variant="h5"
                      style={{
                        fontWeight: 700,
                        color: "#FFF",
                        margin: "3px",
                        textAlign: "center",
                      }}
                    >
                      3
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h5"
                      style={{
                        fontWeight: 700,
                        color: Colors.BrandBackground4,
                        marginBottom: "8px",
                      }}
                    >
                      🎉 &nbsp; Success!
                    </Typography>

                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: 400,
                        color: Colors.Grey2,
                        marginTop: "8px",
                      }}
                    >
                      Congratulationss on your new home/investment
                    </Typography>
                  </Box>
                </Box>
                <Box textAlign="center" mt={4} mb={10}>
                  <Typography
                    variant="body2"
                    style={{
                      fontWeight: 400,
                      color: Colors.Grey2,
                    }}
                  >
                    *All the costs are including GST
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor={Colors.BrandPrimary}
            width="100%"
          >
            <Box
              height="300px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              textAlign="center"
            >
              <Box>
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 700,
                    color: Colors.White,
                    marginBottom: "48px",
                    width: "212px",
                  }}
                >
                  Ready to get started?
                </Typography>

                <ColorButton3 href="#getStarted-button-mobile">
                  Let’s Begin
                </ColorButton3>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      <PartnerLandingPageFooter />
    </>
  );
}
