import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  Typography,
  ButtonBase,
  Button,
  Menu,
  MenuItem,
  withStyles,
} from "@material-ui/core";
import {
  Contract,
  ContractSummary,
  Comment,
  LegalReviewRequest,
  Enquiry,
  LegalTeamMember,
} from "../../api/models";
import { Colors } from "../../constants/colors";
import CloseButton, { CloseButtonColor } from "../common/CloseButton";
import { useSessionStore } from "../../stores/Session";
import { initialsForUser, nameForUser } from "../../helpers/user";
import TextField from "../common/TextField";
import ImageButton from "../common/ImageButton";
import SendIcon from "../../images/comment-send-icon.svg";
import CommentsDialogItem from "./CommentsDialogItem";
import useSWR from "swr";
import { isEmpty, isNil } from "lodash-es";
import { useAlert } from "../../contexts/Alert";
import api from "../../api/instance";
import { useMobile, useSmallHeight } from "../../hooks/mobile";
import NoCommentsIcon from "../../images/no-comments-icon.svg";
import WhiteLogo from "../../images/contrax-white-logo.svg";
import {
  useContractStore,
  refreshContractTeamsAndRole,
} from "../../stores/contract";
import { TeamMember, TeamMemberRole } from "../../api/models";
import SectionControl from "../common/SectionControl";
import InviteUserIcon from "../../images/invite-user-icon.svg";
import { white } from "material-ui/styles/colors";
import "../../styles/scrollbar-hidden.css";

export enum CommentsDialogType {
  summary,
  request,
  enquiry,
}

export enum CommentsDialogJourney {
  LegalReview,
  Request,
}

enum Section {
  team = "TEAM",
  buyerReps = "LEGAL",
  vendorReps = "LEGAL",
}

const MyMenuItem = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "white",
    },
    fontFamily: "GT Walsheim Pro",
    fontWeight: 400,
    fontSize: "14px",
    height: "80px",
    width: "241px",
    whiteSpace: "normal",
  },
})(MenuItem);

const MyMemberMenuItem = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "white",
    },
    fontFamily: "GT Walsheim Pro",
    fontWeight: 400,
    fontSize: "14px",
    height: "40px",
    width: "140px",
    whiteSpace: "normal",
  },
})(MenuItem);

interface Props {
  type: CommentsDialogType;
  journey: CommentsDialogJourney;
  open: boolean;
  onClose: () => unknown;
  contractId: number | null;
  recordId: number | null;
  summary?: ContractSummary | null;
  request?: LegalReviewRequest | null;
  enquiry?: Enquiry | null;
  onRequestCommentsCountUpdated?: (
    request: LegalReviewRequest,
    count: number
  ) => unknown;
  onEnquiryCommentsCountUpdated?: (enquiry: Enquiry, count: number) => unknown;
  onRequestMessagesCountUpdated?: (
    request: LegalReviewRequest,
    count: number
  ) => unknown;
  onEnquiryMessagesCountUpdated?: (enquiry: Enquiry, count: number) => unknown;
}

function SellerBuyerContraxHelpMessage() {
  return (
    <>
      <Box>
        <Box display="flex" flexDirection="row" p="16px" pr="32px">
          <Box
            minWidth="40px"
            width="40px"
            height="40px"
            borderRadius="20px"
            bgcolor={Colors.BrandPrimary}
            display="flex"
            alignItems="center"
            justifyContent="center"
            mr="8px"
          >
            <img
              src={WhiteLogo}
              style={{ height: "20px", width: "20px" }}
              alt="Error"
            />
          </Box>

          <Box display="flex" flexDirection="column" flex={1}>
            <Box display="flex" flexDirection="row">
              <Typography variant="body2" style={{ fontWeight: 700, flex: 1 }}>
                Contrax Help
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" flex={1}>
              <Box
                borderRadius="16px"
                border="1px solid #F4F6F8"
                pt="8px"
                pl="14px"
                pr="14px"
                pb="8px"
                mt="7px"
                bgcolor={Colors.Grey5}
              >
                <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                  You're messaging your <b>TEAM</b> for everybody invited to
                  your team, including your legal representative.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

function ContraxHelpTeamMessage() {
  return (
    <>
      <Box>
        <Box display="flex" flexDirection="row" p="16px" pr="32px">
          <Box
            minWidth="40px"
            width="40px"
            height="40px"
            borderRadius="20px"
            bgcolor={Colors.BrandPrimary}
            display="flex"
            alignItems="center"
            justifyContent="center"
            mr="8px"
          >
            <img
              src={WhiteLogo}
              style={{ height: "20px", width: "20px" }}
              alt="Error"
            />
          </Box>

          <Box display="flex" flexDirection="column" flex={1}>
            <Box display="flex" flexDirection="row">
              <Typography variant="body2" style={{ fontWeight: 700, flex: 1 }}>
                Contrax Help
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" flex={1}>
              <Box
                borderRadius="16px"
                border="1px solid #F4F6F8"
                pt="8px"
                pl="14px"
                pr="14px"
                pb="8px"
                mt="7px"
                bgcolor={Colors.Grey5}
              >
                <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                  You're messaging <b>TEAM</b> for everybody invited to your
                  team. Click <b>LEGAL</b> to message the other side's legal
                  representatives.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

function ContraxHelpLegalMessage() {
  return (
    <>
      <Box>
        <Box display="flex" flexDirection="row" p="16px" pr="32px">
          <Box
            minWidth="40px"
            width="40px"
            height="40px"
            borderRadius="20px"
            bgcolor={Colors.BrandPrimary}
            display="flex"
            alignItems="center"
            justifyContent="center"
            mr="8px"
          >
            <img
              src={WhiteLogo}
              style={{ height: "20px", width: "20px" }}
              alt="Error"
            />
          </Box>

          <Box display="flex" flexDirection="column" flex={1}>
            <Box display="flex" flexDirection="row">
              <Typography variant="body2" style={{ fontWeight: 700, flex: 1 }}>
                Contrax Help
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" flex={1}>
              <Box
                borderRadius="16px"
                border="1px solid #F4F6F8"
                pt="8px"
                pl="14px"
                pr="14px"
                pb="8px"
                mt="7px"
                bgcolor={Colors.Grey5}
              >
                <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                  You're messaging <b>LEGAL</b> for the other side's legal
                  representatives. Click <b>TEAM</b> to message everybody
                  invited to your team.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

function LegalReviewContraxHelp() {
  return (
    <>
      <Box>
        <Box display="flex" flexDirection="row" p="16px" pr="32px">
          <Box
            minWidth="40px"
            width="40px"
            height="40px"
            borderRadius="20px"
            bgcolor={Colors.BrandPrimary}
            display="flex"
            alignItems="center"
            justifyContent="center"
            mr="8px"
          >
            <img
              src={WhiteLogo}
              style={{ height: "20px", width: "20px" }}
              alt="Error"
            />
          </Box>

          <Box display="flex" flexDirection="column" flex={1}>
            <Box display="flex" flexDirection="row">
              <Typography variant="body2" style={{ fontWeight: 700, flex: 1 }}>
                Contrax Help
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" flex={1}>
              <Box
                borderRadius="16px"
                border="1px solid #F4F6F8"
                pt="8px"
                pl="14px"
                pr="14px"
                pb="8px"
                mt="7px"
                bgcolor={Colors.Grey5}
              >
                <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                  You're messaging your <b>TEAM</b> for everybody invited to
                  your team, including clients and colleagues.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

interface NoCommentsPlaceholderProps {
  isSameTeam: boolean;
}

function NoCommentsPlaceholder({ isSameTeam }: NoCommentsPlaceholderProps) {
  return (
    <Box
      alignItems="center"
      justifyContent="center"
      minHeight="100px"
      display="flex"
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <img src={NoCommentsIcon} />
        <Typography
          variant="body2"
          style={{ marginTop: "4px", color: Colors.Grey3 }}
        >
          There are no {isSameTeam ? "comments" : "messages"} yet
        </Typography>
      </Box>
    </Box>
  );
}

export default function CommentsDialog({
  type,
  journey,
  open,
  onClose,
  contractId,
  recordId,
  summary,
  request,
  enquiry,
  onRequestCommentsCountUpdated,
  onEnquiryCommentsCountUpdated,
  onRequestMessagesCountUpdated,
  onEnquiryMessagesCountUpdated,
}: Props) {
  const isMobile = useMobile();
  const isSmallHeight = useSmallHeight();

  const { data: user } = useSessionStore();
  const initials = user ? initialsForUser(user) : null;
  const name = user ? nameForUser(user) : null;

  const [comment, setComment] = React.useState("");

  const {
    userTeamMemberRole,
    buyerTeamMembers,
    sellerTeamMembers,
    agentTeamMembers,
    setContractAndRecordId,
  } = useContractStore();

  const isSeller =
    userTeamMemberRole === TeamMemberRole.seller ||
    userTeamMemberRole === TeamMemberRole.sellerLawyer;

  const isBuyer =
    userTeamMemberRole === TeamMemberRole.buyer ||
    userTeamMemberRole === TeamMemberRole.buyerLawyer ||
    userTeamMemberRole === TeamMemberRole.partner;

  React.useEffect(() => {
    fetchContractLegalTeamMembers();
    if (!isNil(contractId) && !isNil(recordId)) {
      setContractAndRecordId(contractId, recordId);
      refreshContractTeamsAndRole();
    }
  }, [contractId, recordId]);

  const [selectedSection, setSelectedSection] = React.useState(Section.team);

  const isSameTeamSection =
    (isSeller && selectedSection === Section.team) ||
    (isBuyer && selectedSection === Section.team);

  const _fetchCommentsPath = () => {
    if (type === CommentsDialogType.request && request) {
      return `/contract/${contractId}/record/${recordId}/request/${
        isSeller ? request?.buyer_request_pk : request.pk
      }/${
        isSameTeamSection ? `comments/?is_buyer_flow=${isBuyer}` : "messages/"
      }`;
    }

    if (type === CommentsDialogType.enquiry && enquiry) {
      return `/contract/${contractId}/record/${recordId}/enquiry/${
        isSeller ? enquiry.buyer_enquiry_pk : enquiry.pk
      }/${
        isSameTeamSection ? `comments/?is_buyer_flow=${isBuyer}` : "messages/"
      }`;
    }

    if (type === CommentsDialogType.summary && summary) {
      return `/contract/${contractId}/record/${recordId}/summary/${summary.pk}/comments/`;
    }

    return null;
  };

  const fetchCommentsPath = _fetchCommentsPath();

  const { data: commentsData, mutate: commentsMutate } =
    useSWR(fetchCommentsPath);
  const comments = commentsData ? (commentsData as Comment[]) : [];

  const { setErrorMessage } = useAlert();

  const commentsRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (commentsRef.current) {
      commentsRef.current.scrollTop = commentsRef.current.scrollHeight;
    }

    if (!isNil(commentsData) && isSameTeamSection) {
      if (type === CommentsDialogType.request && request) {
        if (isSameTeamSection) {
          onRequestCommentsCountUpdated &&
            onRequestCommentsCountUpdated(request, comments.length);
        } else {
          onRequestMessagesCountUpdated &&
            onRequestMessagesCountUpdated(request, comments.length);
        }
      }

      if (type === CommentsDialogType.enquiry && enquiry) {
        if (isSameTeamSection) {
          onEnquiryCommentsCountUpdated &&
            onEnquiryCommentsCountUpdated(enquiry, comments.length);
        } else {
          onEnquiryMessagesCountUpdated &&
            onEnquiryMessagesCountUpdated(enquiry, comments.length);
        }
      }
    }
  }, [comments]);

  const noComments = !isNil(commentsData) && comments.length === 0;

  const _sections = () => {
    switch (userTeamMemberRole) {
      case TeamMemberRole.seller:
      case TeamMemberRole.buyer:
        return [Section.team];
      case TeamMemberRole.sellerLawyer:
        return [Section.team, Section.buyerReps];
      case TeamMemberRole.buyerLawyer:
        return [Section.team, Section.vendorReps];
    }

    // if (isSeller) {
    //   return [Section.team, Section.buyerReps];
    // }

    // if (isBuyer) {
    //   return [Section.team, Section.vendorReps];
    // }

    return [];
  };

  const sections = _sections();

  const fullScreen = isMobile;

  const _teamMembers = () => {
    if (
      !isNil(buyerTeamMembers.find((member) => member.email === user?.email))
    ) {
      return buyerTeamMembers;
    }

    if (
      !isNil(sellerTeamMembers.find((member) => member.email === user?.email))
    ) {
      return sellerTeamMembers;
    }

    if (
      !isNil(agentTeamMembers.find((member) => member.email === user?.email))
    ) {
      return agentTeamMembers;
    }

    return [];
  };

  const [dropdownMenuAnchorEl, setDropdownMenuAnchorEl] =
    React.useState<HTMLElement | null>(null);

  const [dropdownLegalMenuAnchorEl, setDropdownLegalMenuAnchorEl] =
    React.useState<HTMLElement | null>(null);

  const teamMembers = _teamMembers();

  const commentMembersCount = teamMembers.length;

  const [legalTeamMemberData, setLegalTeamMemberData] = React.useState<
    LegalTeamMember[]
  >([]);

  function fetchContractLegalTeamMembers() {
    api
      .get(`/contract/${contractId}/record/${recordId}/lawyers-team/`)
      .then((response) => {
        const legalTeamMembers = response.data as LegalTeamMember[];
        setLegalTeamMemberData(legalTeamMembers);
      });
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="comments-dialog"
      aria-describedby="comments-dialog-description"
      fullWidth
      fullScreen={fullScreen}
      onClose={onClose}
    >
      <Box
        maxWidth={fullScreen ? "100vw" : "626px"}
        maxHeight={fullScreen ? "100vh" : "80vh"}
        display="flex"
        flexDirection="column"
        flex={1}
      >
        <Box
          bgcolor="white"
          display="flex"
          flexDirection="row"
          minHeight="62px"
          pl="24px"
          pr="24px"
          alignItems="center"
        >
          <Typography variant="h5" style={{ color: Colors.Grey2, flex: 1 }}>
            {summary?.title || request?.title || enquiry?.title}
          </Typography>
          <CloseButton color={CloseButtonColor.BLACK} onClick={onClose} />
        </Box>

        {journey === CommentsDialogJourney.Request &&
          (userTeamMemberRole === TeamMemberRole.sellerLawyer ||
            userTeamMemberRole === TeamMemberRole.buyerLawyer) && (
            <SectionControl
              sections={sections}
              selectedSection={selectedSection}
              onSelectedSection={(section) =>
                setSelectedSection(section as Section)
              }
              sectionWidth="207px"
            />
          )}

        {/* Team User Icon count */}
        {selectedSection === Section.team && (
          <Box alignSelf="flex-end" mb="14px" mr="16px">
            <Button
              onClick={(event) => {
                setDropdownMenuAnchorEl(event.currentTarget);
              }}
              style={{
                alignSelf: "flex-end",
                width: "55px",
                height: "34px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #ECF2FB",
                flexDirection: "row",
                borderRadius: "6px",
                marginTop: "20%",
              }}
            >
              <Box display="flex" flexDirection="row">
                <img src={InviteUserIcon} alt="Error" />
                <Box
                  fontFamily="GT Walsheim Pro"
                  fontWeight={400}
                  fontSize="14px"
                  lineHeight="20px"
                  ml="4px"
                >
                  {commentMembersCount}
                </Box>
              </Box>
            </Button>
          </Box>
        )}

        {/* Legal User Icon count */}
        {selectedSection === Section.buyerReps &&
          selectedSection === Section.vendorReps && (
            <Box alignSelf="flex-end" mb="14px" mr="16px">
              <Button
                onClick={(event) => {
                  setDropdownLegalMenuAnchorEl(event.currentTarget);
                }}
                style={{
                  alignSelf: "flex-end",
                  width: "55px",
                  height: "34px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #ECF2FB",
                  flexDirection: "row",
                  borderRadius: "6px",
                  marginTop: "20%",
                }}
              >
                <Box display="flex" flexDirection="row">
                  <img src={InviteUserIcon} alt="Error" />
                  <Box
                    fontFamily="GT Walsheim Pro"
                    fontWeight={400}
                    fontSize="14px"
                    lineHeight="20px"
                    ml="4px"
                  >
                    {legalTeamMemberData.length}
                  </Box>
                </Box>
              </Button>
            </Box>
          )}

        {/* Comments list */}
        <div
          style={{
            overflowY: "scroll",
            minHeight: "100px",
            paddingTop: "12px",
            paddingBottom: "12px",
            flex: 1,
          }}
          className="scrollbar-hidden"
          ref={(node) => {
            commentsRef.current = node;

            if (node) {
              node.scrollTop = node.scrollHeight;
            }
          }}
        >
          {noComments && (
            <NoCommentsPlaceholder isSameTeam={isSameTeamSection} />
          )}

          {comments.map((comment) => (
            <CommentsDialogItem comment={comment} />
          ))}
        </div>

        {/* Bottom */}

        {journey === CommentsDialogJourney.LegalReview &&
          (userTeamMemberRole === TeamMemberRole.seller ||
            userTeamMemberRole === TeamMemberRole.buyer ||
            userTeamMemberRole === TeamMemberRole.partner) && (
            <SellerBuyerContraxHelpMessage />
          )}

        {journey === CommentsDialogJourney.LegalReview ? (
          (userTeamMemberRole === TeamMemberRole.sellerLawyer ||
            userTeamMemberRole === TeamMemberRole.buyerLawyer) && (
            <LegalReviewContraxHelp />
          )
        ) : selectedSection === Section.team &&
          (userTeamMemberRole === TeamMemberRole.sellerLawyer ||
            userTeamMemberRole === TeamMemberRole.buyerLawyer) ? (
          <ContraxHelpTeamMessage />
        ) : (
          selectedSection === Section.buyerReps &&
          selectedSection === Section.vendorReps && <ContraxHelpLegalMessage />
        )}

        {journey === CommentsDialogJourney.Request &&
          selectedSection === Section.team &&
          (userTeamMemberRole === TeamMemberRole.seller ||
            userTeamMemberRole === TeamMemberRole.buyer ||
            userTeamMemberRole === TeamMemberRole.partner) && (
            <SellerBuyerContraxHelpMessage />
          )}

        <Box
          bgcolor={Colors.Grey5}
          display="flex"
          flexDirection="row"
          pb="16px"
        >
          <Box
            width="40px"
            height="40px"
            borderRadius="20px"
            bgcolor={Colors.BrandBackground3}
            display="flex"
            alignItems="center"
            justifyContent="center"
            ml="16px"
            mr="8px"
            mt="16px"
          >
            <Typography variant="body2" style={{ fontWeight: 700 }}>
              {initials}
            </Typography>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            mr="8px"
            mt="16px"
          >
            <Typography
              variant="body2"
              style={{ fontWeight: 700, marginBottom: "8px" }}
            >
              {name}
            </Typography>
            <TextField
              placeholder="Write a comment"
              value={comment}
              fontSize="14px"
              lineHeight="20px"
              onChange={(text) => setComment(text)}
              multiline
            />
          </Box>

          <Box alignSelf="flex-end" mb="14px" mr="16px">
            <ImageButton
              disabled={isEmpty(comment)}
              image={SendIcon}
              onClick={() => {
                if (isEmpty(comment)) {
                  return;
                }

                if (type === CommentsDialogType.summary) {
                  api
                    .post(
                      `/contract/${contractId}/record/${recordId}/summary/${summary?.pk}/comment/`,
                      {
                        content: comment,
                      }
                    )
                    .then((response) => {
                      commentsMutate();
                    })
                    .catch((error) => {
                      setErrorMessage(error.message);
                    });
                }

                if (type === CommentsDialogType.request) {
                  api
                    .post(
                      `/contract/${contractId}/record/${recordId}/request/${
                        isSeller ? request?.buyer_request_pk : request?.pk
                      }/${isSameTeamSection ? "comment" : "message"}/`,
                      {
                        content: comment,
                        is_buyer_flow:
                          isSameTeamSection && isBuyer ? true : false,
                      }
                    )
                    .then((response) => {
                      commentsMutate();
                    })
                    .catch((error) => {
                      setErrorMessage(error.message);
                    });
                }

                if (type === CommentsDialogType.enquiry) {
                  api
                    .post(
                      `/contract/${contractId}/record/${recordId}/enquiry/${
                        isSeller ? enquiry?.buyer_enquiry_pk : enquiry?.pk
                      }/${isSameTeamSection ? "comment" : "message"}/`,
                      {
                        content: comment,
                        is_buyer_flow:
                          isSameTeamSection && isBuyer ? true : false,
                      }
                    )
                    .then((response) => {
                      commentsMutate();
                    })
                    .catch((error) => {
                      setErrorMessage(error.message);
                    });
                }

                setComment("");
              }}
            />
          </Box>
        </Box>
      </Box>

      <Menu
        id="dropdown-menu"
        anchorEl={dropdownMenuAnchorEl}
        keepMounted
        open={Boolean(dropdownMenuAnchorEl)}
        onClose={() => setDropdownMenuAnchorEl(null)}
        getContentAnchorEl={null}
        style={{ top: "40px", left: "60px" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {/* NOTE: Put appropriate content here... */}
        {/* Team/Legal Member Count Dropdown Logic */}
        {commentMembersCount === 1 ? (
          <MyMenuItem
            onClick={(event) => {
              event.stopPropagation();
              setDropdownMenuAnchorEl(null);
            }}
          >
            <Typography
              variant="body1"
              style={{ fontWeight: 700, lineHeight: "20px" }}
            >
              {name} <br></br>{" "}
              <Typography
                variant="body2"
                style={{ fontWeight: 400, lineHeight: "20px" }}
              >
                Add people by inviting them from the header of your workspace.
              </Typography>
            </Typography>
          </MyMenuItem>
        ) : (
          <>
            {teamMembers.map((member) => (
              <MyMemberMenuItem>
                <Typography variant="body2" style={{ lineHeight: "18px" }}>
                  {member.first_name} {member.last_name}
                </Typography>
              </MyMemberMenuItem>
            ))}
          </>
        )}
      </Menu>
      <Menu
        id="dropdown-menu"
        anchorEl={dropdownLegalMenuAnchorEl}
        keepMounted
        open={Boolean(dropdownLegalMenuAnchorEl)}
        onClose={() => setDropdownLegalMenuAnchorEl(null)}
        getContentAnchorEl={null}
        style={{ top: "40px", left: "60px" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {legalTeamMemberData.length === 1 ? (
          <MyMenuItem
            onClick={(event) => {
              event.stopPropagation();
              setDropdownLegalMenuAnchorEl(null);
            }}
          >
            <Typography
              variant="body1"
              style={{ fontWeight: 700, lineHeight: "20px" }}
            >
              {name} <br></br>{" "}
              <Typography
                variant="body2"
                style={{ fontWeight: 400, lineHeight: "20px" }}
              >
                Add people by inviting them from the header of your workspace.
              </Typography>
            </Typography>
          </MyMenuItem>
        ) : (
          <>
            {legalTeamMemberData && legalTeamMemberData.length > 1 ? (
              <>
                {legalTeamMemberData.map((data) => (
                  <MyMemberMenuItem>
                    <Typography variant="body2" style={{ lineHeight: "18px" }}>
                      {data.first_name} {data.last_name}
                    </Typography>
                  </MyMemberMenuItem>
                ))}
              </>
            ) : (
              "No user defined"
            )}
          </>
        )}
      </Menu>
    </Dialog>
  );
}
