import React from "react";
import { Box, Typography } from "@material-ui/core";
import Wrapper from "../../components/UserAccount/Wrapper";
import InvoiceDocument from "../../components/UserAccount/InvoiceDocument";
import { Colors } from "../../constants/colors";
import { PDFViewer } from "@react-pdf/renderer";
import DownloadInvoiceButton from "../../components/UserAccount/DownloadInvoiceButton";
import { useMobile } from "../../hooks/mobile";
import { useLocation } from "react-router-dom";
import { get } from "lodash-es";
import { Order } from "../../api/models";

export default function ViewInvoice() {
  const isMobile = useMobile();
  const location = useLocation();

  const { state } = location;
  const order = get(state, "order") as Order;

  console.debug("order: ", order);

  const vPadding = isMobile ? "4px" : "40px";
  const hPadding = isMobile ? "4px" : "93px";

  return (
    <Wrapper
      title="Invoices"
      accessory={<DownloadInvoiceButton order={order} />}
    >
      <Typography
        variant="h6"
        style={{ marginTop: "16px", marginBottom: "32px" }}
      >
        {order.organization?.address}
      </Typography>
      <Box
        maxWidth="726px"
        minHeight="50vh"
        bgcolor={Colors.Grey4}
        pt={vPadding}
        pb={vPadding}
        pl={hPadding}
        pr={hPadding}
      >
        <PDFViewer
          width="100%"
          height={window.innerHeight * 0.5}
          showToolbar={false}
        >
          <InvoiceDocument order={order} />
        </PDFViewer>
      </Box>
    </Wrapper>
  );
}
