import { Box, Dialog, Typography } from "@material-ui/core";
import React from "react";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";
import { useMobile } from "../hooks/mobile";
import Icon from "../images/check-your-email.svg";

interface Props {
  open: boolean;
  email?: string | null;
  onResendEmail: () => unknown;
}

export default function ({ open, email, onResendEmail }: Props) {
  const isMobile = useMobile();

  return (
    <Dialog open={open} fullScreen={isMobile} maxWidth="lg">
      <Box width={isMobile ? "100%" : "514px"}>
        <Box
          display="flex"
          flexDirection="column"
          pt="37px"
          boxSizing="border-box"
          alignItems="center"
          pb="69px"
        >
          <img src={Icon} width="100px" height="100px" />

          <Typography
            variant="h6"
            style={{ marginTop: "32px", marginBottom: "16px" }}
          >
            Check your email
          </Typography>

          <Typography style={{ width: "279px", textAlign: "center" }}>
            We’ve sent a message to {email}. Open it and click to{" "}
            <strong>Activate your account</strong>. We’ll take it from there.
          </Typography>
        </Box>
        <Box
          height="74px"
          boxShadow="0px -1px 0px #E0E0E0"
          boxSizing="border-box"
          pt="16px"
          pl="16px"
          pr="16px"
        >
          <Button
            title="RESEND THE EMAIL"
            type={ButtonType.secondary}
            size={ButtonSize.small}
            width="100%"
            onClick={onResendEmail}
          />
        </Box>
      </Box>
    </Dialog>
  );
}
