import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import { useMobile } from "../../hooks/mobile";
import BLUELOGO from "../../images/BlueLogo.png";
import { Link } from "@material-ui/core";

const height = "100px";

const phantomStyle = {
  height,
};

const footerStyle = {
  backgroundColor: Colors.Grey4,
  bottom: 0,
  boxShadow: "0px -1px 0px #E0E0E0",
};

export default function MyLawyerLandingPageFooter(): JSX.Element {
  const isMobile = useMobile();

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Box>
      {/* Phantom view so views on top of footer knows "it's there" and respects margins */}
      {/* <Box style={phantomStyle} width="100%" /> */}
      {!isMobile && (
        <Box
          style={footerStyle}
          // zIndex={1}
          // position="fixed"
          // left={0}
          // right={0}
          height="100px"
          width="100%"
        >
          <Box
            flexDirection="row"
            display="flex"
            flex={1}
            alignItems="center"
            justifyContent="space-between"
            ml={isMobile ? 5 : 21.25}
            mr={isMobile ? 5 : 21.25}
            height="100%"
          >
            <Box>
              <Typography variant="caption">
                <Link
                  style={{ color: Colors.Link, cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      "https://contrax.com.au/privacy-policy",
                      "_blank"
                    )
                  }
                >
                  Privacy Policy
                </Link>{" "}
                &nbsp; . &nbsp;
                <Link
                  style={{ color: Colors.Link, cursor: "pointer" }}
                  onClick={() =>
                    window.open("https://contrax.com.au/terms", "_blank")
                  }
                >
                  Terms and Conditions
                </Link>
              </Typography>
              <Box>
                <Typography variant="caption">
                  © Copyright {getCurrentYear()} Contrax Pty. Ltd
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography variant="body2">Powered by</Typography>
              <Box>
                <img src={BLUELOGO} height="32px" />
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {isMobile && (
        <Box
          style={footerStyle}
          // zIndex={1}
          // position="fixed"
          // left={0}
          // right={0}
          height="auto"
          width="100%"
        >
          <Box
            flexDirection="column"
            display="flex"
            flex={1}
            alignItems="left"
            justifyContent="space-between"
            ml={2}
            mr={2}
            pt={2}
            pb={2}
            height="100%"
          >
            <Box mb={1}>
              <Typography variant="caption">
                <Link
                  style={{ color: Colors.Link, cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      "https://contrax.com.au/privacy-policy",
                      "_blank"
                    )
                  }
                >
                  Privacy Policy
                </Link>{" "}
                &nbsp; . &nbsp;
                <Link
                  style={{ color: Colors.Link, cursor: "pointer" }}
                  onClick={() =>
                    window.open("https://contrax.com.au/terms", "_blank")
                  }
                >
                  Terms and Conditions
                </Link>
              </Typography>
              <Box>
                <Typography variant="caption">
                  © Copyright {getCurrentYear()} Contrax Pty. Ltd
                </Typography>
              </Box>
            </Box>
            <Box mt={2}>
              <Typography variant="body2">Powered by</Typography>
              <Box>
                <img src={BLUELOGO} height="28px" />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
