import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import { useMobile } from "../../hooks/mobile";

interface Props {
  index: number;
  title: string;
  image: string;
  imageMarginTop?: string | number;
}

export default function HelpGuideModalItem({
  index,
  title,
  image,
  imageMarginTop = "32px",
}: Props) {
  const isMobile = useMobile();

  return (
    <Box
      bgcolor="rgba(255, 255, 255, 0.2)"
      borderRadius="16px"
      width={isMobile ? "100%" : "374px"}
      height={"453px"}
    >
      <Box m="32px" display="flex" flexDirection="column" alignItems="center">
        <Box
          width="50px"
          height="50px"
          borderRadius="33px"
          bgcolor={Colors.BrandBackground3}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="h5"
            style={{ fontWeight: 700, color: Colors.BrandPrimary }}
          >
            {index}
          </Typography>
        </Box>

        <Typography
          variant="body1"
          style={{
            fontWeight: 700,
            lineHeight: "20px",
            marginBottom: imageMarginTop,
            textAlign: "center",
          }}
        >
          {title}
        </Typography>
        <img src={image} />
      </Box>
    </Box>
  );
}
