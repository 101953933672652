import { Box, Typography } from "@material-ui/core";
import { last, sortBy } from "lodash-es";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import api from "../api/instance";
import { ClaimRecordResponse, Property, User, UserType } from "../api/models";
import AuthenticatedWrapper from "../components/AuthenticatedWrapper";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import CreditsRemainingText from "../components/CreditsRemainingText";
import HeroLayout from "../components/HeroLayout";
import PropertyDetailsHeader from "../components/PropertyDetailsHeader";
import { useAlert } from "../contexts/Alert";
import { parseApiError } from "../helpers/error";
import { useDesktop, useMobile } from "../hooks/mobile";
import { useCreditStore } from "../stores/credit";
import { usePurchaseStore } from "../stores/purchase";
import { isLoggedIn, useSessionStore } from "../stores/Session";

export default function PayForProperty(): JSX.Element {
  const isMobile = useMobile();
  const isDesktop = useDesktop();
  const location = useLocation();
  const history = useHistory();

  const orgUuid = localStorage.getItem("uuid") || "";

  const query = new URLSearchParams(location.search);
  const propertyId = query.get("propertyId");
  const contractId = query.get("contractId"); // Only for redirecting back to page
  const recordId = query.get("recordId");
  const cross = query.get("cross");
  const makePayment = query.get("makePayment");

  const { data: propertyData } = useSWR(`/view-properties/${propertyId}/`);
  const property = propertyData && (propertyData as Property);

  const { data: userData, refresh: refreshUserData } = useSessionStore();
  const user = userData && (userData as User);

  const { availableCredits, refreshCredits } = useCreditStore();
  const { setPurchaseJourneyStartPath, setPurchaseJourneySuccessPath } =
    usePurchaseStore();

  const [usingCredit, setUsingCredit] = React.useState(false);

  React.useEffect(() => {
    if (makePayment === "true") {
      refreshUserData();

      setSuccessMessage("Payment successful");
    }
  }, []);

  React.useEffect(() => {
    if (user && user.user_type === UserType.Agent) {
      history.push("/dashboard?agentBuyError=true");
    }

    refreshCredits();
  }, [user]);

  const { setErrorMessage, setSuccessMessage } = useAlert();

  const onMakePayment = async () => {
    if (availableCredits === 0) {
      setPurchaseJourneyStartPath(`${location.pathname}${location.search}`);
      setPurchaseJourneySuccessPath(
        `${location.pathname}${location.search}&makePayment=true`
      );

      history.push("/plans");
      return;
    }

    setUsingCredit(true);

    // Fetch property details again as contract version id might not be available yet
    api
      .get(`/view-properties/${propertyId}/`)
      .then((response) => {
        const fetchedProperty = response.data as Property;

        const contract_version_id = last(
          sortBy(fetchedProperty.contract_versions, (version) => {
            return version.number;
          })
        )?.id;

        if (!contract_version_id) {
          // Contract version id not yet available
          setUsingCredit(false);
          setErrorMessage("An unknown error has occured. Please try again.");
          return;
        }

        api
          .post(
            "/credits/claim_record/",
            recordId
              ? { record_id: recordId }
              : { contract_version_id: contract_version_id, uuid: orgUuid }
          )
          .then((response) => {
            const data = response.data as ClaimRecordResponse;
            const { contract_id, record_id } = data;

            setUsingCredit(false);

            localStorage.removeItem("uuid");

            // if (localStorage.getItem("uuid") === null) {
            //   console.log("REMOVE UUID");
            // }

            setSuccessMessage("Workspace purchased");

            if (user?.is_active === true) {
              history.push(
                `/legal-review-viewer?contractId=${contract_id}&recordId=${record_id}`
              );
            } else {
              history.push("/dashboard");
            }
          })
          .catch((error) => {
            setUsingCredit(false);

            setErrorMessage(parseApiError(error));
          });
      })
      .catch((error) => {
        setUsingCredit(false);

        setErrorMessage(parseApiError(error));
      });
  };

  const ctaButtonText = React.useMemo(() => {
    return availableCredits > 0 ? "USE CREDIT" : "MAKE PAYMENT";
  }, [availableCredits]);

  return (
    <AuthenticatedWrapper>
      <HeroLayout
        onCloseClicked={() => {
          if (contractId && isLoggedIn() && cross === "false") {
            history.push(
              `/property-details?contractId=${contractId}&type=buying`
            );
          } else if (propertyId && cross === "false") {
            history.push(`/property-info?propertyId=${propertyId}`);
          } else if (propertyId && cross === "true") {
            history.push(
              `/legal-review-buyer?contractId=${contractId}&recordId=${recordId}`
            );
          } else if (propertyId) {
            history.push(`/property-info?propertyId=${propertyId}`);
          } else {
            // history.push(
            //   `/pay-workspace?contractId=${contractId}&recordId=${recordId}`
            // );
            history.push(
              `/create-workspace?contractId=${contractId}&recordId=${recordId}&type=buying`
            );
          }
        }}
      >
        <Box
          mt={isMobile ? 5 : 22}
          display="flex"
          flexDirection="column"
          marginLeft="auto"
          marginRight="auto"
          alignItems="center"
          width={!isMobile ? "500px" : undefined}
          p={isMobile ? 3 : 0}
        >
          <Box
            height="62px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h5">Pay for property</Typography>
          </Box>

          {propertyId && <PropertyDetailsHeader propertyId={+propertyId} />}

          <Box
            width={isMobile ? "100%" : "524px"}
            display="flex"
            flexDirection="column"
            boxSizing={"border-box"}
            pl="16px"
            pr="16px"
            mt="44px"
            mb="44px"
            alignItems={"center"}
          >
            <CreditsRemainingText />

            {availableCredits > 0 && (
              <Typography style={{ marginTop: "22px", textAlign: "center" }}>
                You will be using 1 of your credits to purchase this workspace,
                this credit cannot be refunded, do you want to continue?
              </Typography>
            )}
          </Box>

          <Box mt={4} />
          {property && (
            <React.Fragment>
              {isDesktop && (
                <Button
                  type={ButtonType.primary}
                  size={ButtonSize.large}
                  title={ctaButtonText}
                  width="100%"
                  loading={usingCredit}
                  onClick={onMakePayment}
                />
              )}
              <Box mb={12} />
            </React.Fragment>
          )}
          {!isDesktop && (
            <MobileStickyFooter
              buttonsLayout={FooterButtonsLayout.custom}
              customView={
                <Box display="flex" justifyContent="center" width="100%">
                  <Box width="375px">
                    <Button
                      type={ButtonType.primary}
                      size={ButtonSize.large}
                      title={ctaButtonText}
                      width="100%"
                      loading={usingCredit}
                      onClick={onMakePayment}
                    />
                  </Box>
                </Box>
              }
            />
          )}
        </Box>
      </HeroLayout>
    </AuthenticatedWrapper>
  );
}
