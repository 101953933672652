import React from "react";
import {
  Box,
  Typography,
  ButtonBase,
  IconButton,
  Menu,
  MenuItem,
  withStyles,
} from "@material-ui/core";
import Placeholder from "../../images/dashboard-property-placeholder.svg";
import { Colors } from "../../constants/colors";
import { useMobile } from "../../hooks/mobile";
import MoreIcon from "../../images/more-icon.svg";
import { DashboardContract } from "../../api/models";
import { useSessionStore } from "../../stores/Session";
import { User, UserType, TeamMemberRole } from "../../api/models";
import pluralize from "pluralize";
import StatusPill, { StatusPillType } from "../StatusPill";
import {
  useContractStore,
  refreshContractTeamsAndRole,
} from "../../stores/contract";
import api from "../../api/instance";
import { useAlert } from "../../contexts/Alert";

const MyMenuItem = withStyles({
  root: {
    "&:hover": {
      backgroundColor: Colors.BrandBackground2,
      color: Colors.BrandPrimary,
    },
    fontFamily: "GT Walsheim Pro",
    fontWeight: 400,
    fontSize: "16px",
    height: "40px",
    width: "241px",
  },
})(MenuItem);

const MyBox = withStyles({
  root: {
    "&:hover": {
      backgroundColor: Colors.BrandBackground3,
    },
  },
})(Box);

export enum DashboardItemType {
  Buying,
  Selling,
}

interface Props {
  type: DashboardItemType;
  contract: DashboardContract;
  onClick: () => unknown;
  onShareLink?: () => unknown;
}

export default function DashboardItem({
  type,
  contract,
  onClick,
  onShareLink,
}: Props) {
  const isMobile = useMobile();
  const { setErrorMessage } = useAlert();
  const [menuEl, setMenuEl] = React.useState<any>(null);

  const { address } = contract.listing;

  const image = contract.listing.image || Placeholder;

  const arch_contract = contract.id;
  const arch_record = contract.record.pk;

  const { data: userData } = useSessionStore();
  const user = userData && (userData as User);

  const isLawyer = user?.user_type === UserType.Solicitor;
  const isAgent = user?.user_type === UserType.Agent;
  const isPartner = user?.user_type === UserType.Partner;

  return (
    <>
      <ButtonBase
        onClick={onClick}
        style={{
          width: isMobile ? "95vw" : "1095px",
          height: isMobile ? "92px" : "112px",
          marginTop: "4px",
          marginBottom: "4px",
        }}
      >
        <MyBox
          display="flex"
          height="100%"
          flexDirection="row"
          bgcolor={Colors.Grey5}
          flex={1}
          borderRadius="4px"
          alignItems="center"
          pl="15px"
          pr={isMobile ? "8px" : "24px"}
        >
          <img
            src={image}
            width={isMobile ? "78px" : "117px"}
            height={isMobile ? "60px" : "88px"}
            style={{ objectFit: "cover" }}
          />
          <Box
            flex={1}
            textAlign="left"
            ml={isMobile ? "15px" : "24px"}
            mr="24px"
          >
            <Typography variant={isMobile ? "body2" : "h6"}>
              {address.street} {address.city} {address.state}{" "}
              {address.postal_code}
            </Typography>

            {!isMobile &&
              contract.assigned_to_me &&
              (type === DashboardItemType.Buying ||
                DashboardItemType.Selling) &&
              (isLawyer || isPartner) && (
                <StatusPill type={StatusPillType.assigned} />
              )}
          </Box>

          {/* {!isMobile && isBuyer && <img src={BLUELOGO} height="32px" />} */}

          {!isMobile &&
            type === DashboardItemType.Buying &&
            (isLawyer || isPartner) && (
              <Box>
                {contract.new_count > 0 && (
                  <StatusPill type={StatusPillType.new} contract={contract} />
                )}
                {contract.in_progress_count > 0 && (
                    <Box>
                      <StatusPill
                        type={StatusPillType.inProgress}
                        contract={contract}
                      />
                    </Box>
                  )}
                {contract.publish_status === "SLD" && (
                    <StatusPill type={StatusPillType.sold} />
                  )}
                {/* {contract.is_archive && (
                  <StatusPill type={StatusPillType.archive} />
                )} */}
              </Box>
            )}

          {!isMobile && (type === DashboardItemType.Selling || isAgent) && (
            <Box ml="15px" mr="15px">
              <Typography variant="body1">
                {pluralize("Buyer", contract.buyer_count, true)}
              </Typography>
            </Box>
          )}

          {(isLawyer || isAgent) && (
            <IconButton
              onClick={(event) => {
                event.stopPropagation();

                setMenuEl(event.currentTarget);
              }}
            >
              <img src={MoreIcon} />
            </IconButton>
          )}
        </MyBox>
      </ButtonBase>
      {(isLawyer || isAgent) && (
        <Menu
          anchorEl={menuEl}
          keepMounted
          open={Boolean(menuEl)}
          onClose={() => setMenuEl(null)}
          getContentAnchorEl={null}
          style={{ top: "55px" }}
        >
          {/* {isAgent && <MyMenuItem onClick={() => {}}>Mark as sold</MyMenuItem>} */}

          <MyMenuItem
            onClick={() => {
              setMenuEl(null);
              onShareLink && onShareLink();
            }}
          >
            Share link
          </MyMenuItem>
          {/* {contract.assigned_to_me && arch_record && (
            <MyMenuItem
              onClick={() => {
                setMenuEl(null);
                api
                  .post(
                    `/contract/${arch_contract}/record/${arch_record}/archive/`
                  )
                  .then((response) => {
                    return contract;
                  })
                  .catch((error) => {
                    setErrorMessage(error.message);
                  });
              }}
            >
              Mark Archived
            </MyMenuItem>
          )} */}
        </Menu>
      )}
    </>
  );
}
