import { Box, Link, Typography } from '@material-ui/core';
import Button, { ButtonSize, ButtonType } from '../components/common/Button';
import { useHistory, useLocation } from 'react-router-dom';

import AlertWrapper from '../components/AlertWrapper';
import ChevronLeftIcon from '../images/chevron-left-blue.png';
import { Colors } from '../constants/colors';
import DashboardHeaderMobile from '../components/DashboardHeaderMobile';
import HeroLayout from '../components/HeroLayout';
import { Property } from '../api/models';
import PropertyDetailsHeader from '../components/PropertyDetailsHeader';
import QRCode from 'qrcode.react';
import React from 'react';
import copy from 'copy-to-clipboard';
import { useAlert } from '../contexts/Alert';
import { useMobile } from '../hooks/mobile';
import useSWR from 'swr';

const downloadQR = () => {
  const canvas = document.getElementById('link-qrcode') as HTMLCanvasElement;
  const pngUrl =
    canvas &&
    canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
  let downloadLink = document.createElement('a');
  downloadLink.href = pngUrl;
  downloadLink.download = 'contrax-legal-review-link.png';
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export default function LegalReviewPending(): JSX.Element {
  const history = useHistory();
  const isMobile = useMobile();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const propertyId = query.get('propertyId');

  const { data: propertyData } = useSWR(`/view-properties/${propertyId}/`);
  const property = propertyData && (propertyData as Property);

  const { setSuccessMessage } = useAlert();

  if (!property || !propertyId) {
    return <Box />;
  }

  const shareLink = `${window.location.origin}/app/property-info?propertyId=${propertyId}&code=${property.code}`;

  return (
    <AlertWrapper>
      <HeroLayout logoHidden={true} headerHidden={isMobile}>
        <Box>
          {isMobile && <DashboardHeaderMobile />}
          {!isMobile && (
            <Box position='absolute' left='20px' top='24px'>
              <Button
                type={ButtonType.grey}
                size={ButtonSize.small}
                title='BACK TO PROPERTY'
                leftImage={ChevronLeftIcon}
                width='198px'
                onClick={() => history.goBack()}
              />
            </Box>
          )}

          <Box
            m={isMobile ? 3 : 21.5}
            width={isMobile ? undefined : '542px'}
            display='flex'
            flexDirection='column'
            marginLeft='auto'
            marginRight='auto'
            alignItems={isMobile ? 'center' : undefined}
            pl={5}
            pr={5}
          >
            {isMobile && propertyId && (
              <PropertyDetailsHeader propertyId={+propertyId} />
            )}

            {/* <Box display="flex" alignItems="flex-start" width="100%">
              <img
                src={ConfirmationIcon}
                width={isMobile ? "64px" : "120px"}
                height={isMobile ? "64px" : "120px"}
                style={{ marginTop: "22px" }}
              />
            </Box> */}

            <Box mt={isMobile ? 1.25 : 3.5} width='100%'>
              <Typography variant={isMobile ? 'h4' : 'h3'}>
                This legal review is pending.
              </Typography>
            </Box>
            <Box mt={isMobile ? 1.25 : 4.5}>
              <Typography variant='body1'>
                You can still send this contract of sale to buyers and their
                legal teams using the link and QR code provided.
              </Typography>
              <br />
              <Typography>
                We'll notify you when the legal review is ready and update the
                existing link, so there's no need to resend. Still, we advise
                letting your buyers know when the legal review is available.
              </Typography>
            </Box>
            <Box
              bgcolor={Colors.BrandBackground3}
              width={isMobile ? '100%' : '450px'}
              height={isMobile ? '131px' : '150px'}
              mt={2}
              alignItems='center'
              justifyContent='center'
              display='flex'
            >
              <Box ml={2} mr={2}>
                <Typography>
                  Click the button below, then paste into your email ready send.
                </Typography>
                <Box mt={isMobile ? 0.75 : 1}>
                  <Button
                    type={ButtonType.primary}
                    size={ButtonSize.medium}
                    title='COPY LINK TO SHARE'
                    width='100%'
                    onClick={() => {
                      copy(shareLink);
                      setSuccessMessage('Copied Link');
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              bgcolor={Colors.BrandBackground3}
              display='flex'
              flexDirection='row'
              alignItems='center'
              height={isMobile ? '215px' : '175px'}
              width={isMobile ? '100%' : '450px'}
              mt={1}
            >
              <Box ml={isMobile ? 2 : 3.25} mr={1.625}>
                <Typography variant='body1'>Alternatively you can</Typography>
                <Typography variant='body1' style={{}}>
                  <Link
                    style={{ fontWeight: 700, cursor: 'pointer' }}
                    onClick={() => downloadQR()}
                  >
                    Click here to download the QR code.
                  </Link>
                  <br />
                  <br />
                  Use this in your marketing materials to share access to this
                  contract of sale and legal review.
                </Typography>
              </Box>
              <Box
                bgcolor='white'
                width='76px'
                height='76px'
                alignItems='center'
                justifyContent='center'
                display='flex'
                mr={isMobile ? 1.375 : 6}
              >
                <QRCode id='link-qrcode' size={60} value={shareLink} />
              </Box>
            </Box>
          </Box>
        </Box>
        {/* {isMobile && (
          <MobileStickyFooter
            buttonsLayout={FooterButtonsLayout.centered}
            buttonTitle="CLOSE"
            buttonOnClick={() => {
              history.goBack();
            }}
          />
        )} */}
      </HeroLayout>
    </AlertWrapper>
  );
}
