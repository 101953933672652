import { Box, Dialog, Typography } from "@material-ui/core";
import { isEmpty } from "lodash-es";
import React from "react";
import api from "../../api/instance";
import { User } from "../../api/models";
import { useAlert } from "../../contexts/Alert";
import { useSessionStore } from "../../stores/Session";
import Button, { ButtonSize, ButtonType } from "../common/Button";
import Separator from "../common/Separator";
import TextField from "../common/TextField";
import InfoMessageBar from "../InfoMessageBar";

interface Props {
  open: boolean;
  onClose: () => unknown;
  contractId: number | null;
  onAdded: (firstName: string, lastName: string) => unknown;
}

function InvitePeopleMessage() {
  return (
    <InfoMessageBar
      message={
        "Adding a buyer will invite them to contrax to view this property contract"
      }
    />
  );
}

export default function ({ open, onClose, contractId, onAdded }: Props) {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");

  const { data: userData } = useSessionStore();
  const user = userData && (userData as User);

  const { setErrorMessage } = useAlert();

  const [inviting, setInviting] = React.useState(false);

  function resetFields() {
    setFirstName("");
    setLastName("");
    setEmail("");
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="invite-dialog"
      aria-describedby="invite-dialog-description"
    >
      <Box width="524px" display="flex" flexDirection="column">
        <Box
          height="62px"
          pl="24px"
          alignItems="center"
          display="flex"
          flexDirection="row"
        >
          <Typography variant="h5" style={{ fontWeight: 700 }}>
            Add Buyer
          </Typography>
        </Box>
        <Separator />
        <InvitePeopleMessage />
        <Box p="24px">
          <Box display="flex" flexDirection="row">
            <TextField
              title="First Name"
              value={firstName}
              onChange={(text) => setFirstName(text)}
            />
            <Box width="12.5px" />
            <TextField
              title="Last Name"
              value={lastName}
              onChange={(text) => setLastName(text)}
            />
          </Box>
          <TextField
            title="Email"
            value={email}
            onChange={(text) => setEmail(text)}
          />
        </Box>
        <Separator />
        <Box
          display="flex"
          justifyContent="flex-end"
          pl="24px"
          pr="16px"
          height="74px"
          alignItems="center"
        >
          <Button
            title="CANCEL"
            size={ButtonSize.medium}
            type={ButtonType.secondary}
            onClick={onClose}
            width="114px"
          />
          <Box width="8px" />
          <Button
            loading={inviting}
            title="ADD"
            size={ButtonSize.medium}
            type={ButtonType.primary}
            onClick={() => {
              if (isEmpty(firstName)) {
                setErrorMessage("Please enter their first name");
                return;
              }

              if (isEmpty(lastName)) {
                setErrorMessage("Please enter their last name");
                return;
              }

              if (isEmpty(email)) {
                setErrorMessage("Please enter their email");
                return;
              }

              setInviting(true);

              api
                .post(`/contract/${contractId}/dashboard-invite-buyer/`, {
                  first_name: firstName,
                  last_name: lastName,
                  email,
                })
                .then((response) => {
                  setInviting(false);

                  onAdded(firstName, lastName);

                  resetFields();
                })
                .catch((error) => {
                  setInviting(false);

                  setErrorMessage(error.message);
                });
            }}
            width="74px"
          />
        </Box>
      </Box>
    </Dialog>
  );
}
