import React from "react";
import { MyTeamMember } from "../../api/models";
import { Box, Typography, CircularProgress, Dialog } from "@material-ui/core";
import Separator from "../common/Separator";
import Button, { ButtonType, ButtonSize } from "../../components/common/Button";
import { useMobile, useDesktop } from "../../hooks/mobile";
import api from "../../api/instance";
import { useAlert } from "../../contexts/Alert";
import { AxiosError } from "axios";
import { parseApiError } from "../../helpers/error";

interface Props {
  // type?: AddMyTeamMemberModal;
  open: boolean;
  onDeleted: () => unknown;
  onClose: () => unknown;
  deleteTeamMember: MyTeamMember;
}

export default function DeleteModal({
  open,
  onDeleted,
  onClose,
  deleteTeamMember,
}: Props) {
  const isMobile = useMobile();
  const user_pk = deleteTeamMember.pk;

  const { setSuccessMessage, setErrorMessage } = useAlert();

  function onTeamMemberDelete() {
    api
      .post(`/delete-member/`, {
        user_pk,
      })
      .then((response) => {
        const deletedData = response;
        console.log(deletedData, "deleted");
        onDeleted();

        setSuccessMessage(deletedData.data.message);
      })
      .catch((error) => {
        setErrorMessage(parseApiError(error));
      });
  }

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="invite-dialog"
        aria-describedby="invite-dialog-description"
        onClose={onClose}
        fullScreen={isMobile}
      >
        <Box
          width={isMobile ? "100%" : "524px"}
          display="flex"
          flexDirection="column"
        >
          <Box
            height="62px"
            pl="24px"
            alignItems="center"
            display="flex"
            flexDirection="row"
          >
            <Typography variant="h5" style={{ fontWeight: 700 }}>
              Are you sure you want to delete this user?
            </Typography>
          </Box>
          <Separator />
          <Box p="24px">
            <Typography variant="h6" style={{ fontWeight: 400 }}>
              Deleting will remove this person from your firm and their access
              to your firm contracts. Their data and work will remain within the
              contract.
            </Typography>
          </Box>
          <Separator />
          <Box
            display="flex"
            justifyContent="flex-end"
            pl="16px"
            pr="16px"
            height="74px"
            alignItems="center"
          >
            <Button
              title="DELETE"
              size={ButtonSize.medium}
              type={ButtonType.destructive}
              onClick={onTeamMemberDelete}
              width="114px"
            />
            <Box width="8px" mr={isMobile ? "125px" : "250px"} />
            <Button
              title="CANCEL"
              size={ButtonSize.medium}
              type={ButtonType.secondary}
              onClick={onClose}
              width="114px"
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
