import React from "react";
import { Box, Link, Typography } from "@material-ui/core";
import DashboardHeaderDesktop from "../components/DashboardHeaderDesktop";
import { useMobile, useDesktop } from "../hooks/mobile";
import { Colors } from "../constants/colors";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import DashboardHeaderMobile from "../components/DashboardHeaderMobile";
import { useAlert } from "../contexts/Alert";
import AlertWrapper from "../components/AlertWrapper";
import { useLocation, useHistory } from "react-router-dom";
import NoContractAvailable from "../images/no-contract-available.png";
import TextField from "../components/common/TextField";
import Button, { ButtonType, ButtonSize } from "../components/common/Button";
import Checkbox from "../components/common/Checkbox";
import { UserType } from "../api/models";
import { get, isEmpty } from "lodash-es";
import api from "../api/instance";
import { AxiosError } from "axios";
import TermsLink from "../components/TermsLink";
import PrivacyLink from "../components/PrivacyLink";

export default function PropertyNotReviewedConfirm(): JSX.Element {
  const isMobile = useMobile();
  const location = useLocation();
  const history = useHistory();

  const locationState = location && location.state;
  const address = get(locationState, "address");
  const agent_name = get(locationState, "agent_name");
  const property_url = get(locationState, "property_url");

  const [firmName, setFirmName] = React.useState("");
  const [firmNameError, setFirmNameError] = React.useState<null | string>(null);

  const [firstName, setFirstName] = React.useState("");
  const [firstNameError, setFirstNameError] = React.useState<null | string>(
    null
  );

  const [lastName, setLastName] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState<null | string>(null);

  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState<null | string>(null);

  const [phone, setPhone] = React.useState("");
  const [phoneError, setPhoneError] = React.useState<null | string>(null);

  const [loading, setLoading] = React.useState(false);

  const [selectedUserType, setSelectedUserType] = React.useState<UserType>(
    UserType.Buyer
  );

  const { setSuccessMessage, setErrorMessage } = useAlert();
  function FooterText() {
    return (
      <Box>
        <Typography variant="body2">
          By selecting get in touch, I agree to Contrax <TermsLink /> and{" "}
          <PrivacyLink />
        </Typography>
      </Box>
    );
  }

  function resetErrors() {
    setFirmNameError(null);
    setFirstNameError(null);
    setLastNameError(null);
    setEmailError(null);
    setPhoneError(null);
  }
  function verifyFields() {
    resetErrors();

    let allFieldsValid = true;

    if (isEmpty(firstName)) {
      setFirstNameError("Please enter your first name");
      allFieldsValid = false;
    }

    if (isEmpty(lastName)) {
      setLastNameError("Please enter your last name");
      allFieldsValid = false;
    }

    if (isEmpty(email)) {
      setEmailError("Please enter your email");
      allFieldsValid = false;
    }

    return allFieldsValid;
  }

  function getInTouchClicked() {
    if (!verifyFields()) {
      return;
    }

    setLoading(true);

    const commonData = {
      account_type:
        selectedUserType === UserType.Solicitor ? "Solicitor" : "Buyer",
      first_name: firstName,
      last_name: lastName,
      email,
      phone,
      address,
      agent_name,
      property_url,
    };

    const data =
      selectedUserType === UserType.Solicitor
        ? {
            firm_name: firmName,
            ...commonData,
          }
        : commonData;

    api
      .post("/request-review/", data)
      .then((response) => {
        setLoading(false);

        history.push("/property-not-reviewed-success", {
          name: `${firstName} ${lastName}`,
        });
      })
      .catch((error: AxiosError) => {
        setLoading(false);

        setErrorMessage(error.message);
      });
  }

  return (
    <AlertWrapper>
      {isMobile && <DashboardHeaderMobile />}

      {!isMobile && <DashboardHeaderDesktop />}

      <Box
        maxWidth={isMobile ? "375px" : "450px"}
        marginLeft="auto"
        marginRight="auto"
        pt={isMobile ? "16px" : "38px"}
        pl={isMobile ? 3 : 0}
        pr={isMobile ? 3 : 0}
      >
        <img src={NoContractAvailable} height={isMobile ? "80px" : "167px"} />
        <Box mt={2.875} />
        <Typography variant="h4">Get in touch</Typography>
        <Box mt={2} />
        <Typography variant="body1">
          We will send you an email once the Legal Review is ready
        </Typography>
        <Box mt={3.75} />

        <Box
          mt={2}
          mb={2}
          display="flex"
          alignItems="left"
          flexDirection="column"
          width={isMobile ? undefined : "353px"}
        >
          <Typography variant="body2" style={{ fontWeight: 700 }}>
            Are you a
          </Typography>
          <Box display="flex" flexDirection="row" alignItems="center" mt={1.5}>
            <Checkbox
              style={{ color: Colors.Grey2 }}
              checked={selectedUserType === UserType.Buyer}
              onChange={(event) => {
                if (event.target.checked) {
                  setSelectedUserType(UserType.Buyer);
                }
              }}
            />
            <Typography variant="body2">Potential Buyer</Typography>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Checkbox
              style={{ color: Colors.Grey2 }}
              checked={selectedUserType === UserType.Solicitor}
              onChange={(event) => {
                if (event.target.checked) {
                  setSelectedUserType(UserType.Solicitor);
                }
              }}
            />
            <Typography variant="body2">Lawyer & Conveyancer</Typography>
          </Box>
        </Box>
        {selectedUserType === UserType.Solicitor && (
          <React.Fragment>
            <TextField
              title="Firm name (optional)"
              value={firmName}
              error={firmNameError !== null}
              helperText={firmNameError}
              onChange={(value) => setFirmName(value)}
            />
            <Box mt={2} />
          </React.Fragment>
        )}
        <TextField
          title="First name"
          value={firstName}
          error={firstNameError !== null}
          helperText={firstNameError}
          onChange={(value) => setFirstName(value)}
        />
        <Box mt={2} />
        <TextField
          title="Last name"
          value={lastName}
          error={lastNameError !== null}
          helperText={lastNameError}
          onChange={(value) => setLastName(value)}
        />
        <Box mt={2} />
        <TextField
          title="Email"
          value={email}
          error={emailError !== null}
          helperText={emailError}
          onChange={(value) => setEmail(value)}
        />
        <Box mt={2} />
        <TextField
          title="Phone number"
          value={phone}
          error={phoneError !== null}
          helperText={phoneError}
          onChange={(value) => setPhone(value)}
        />
        <Box mt={2} mb={5}>
          <FooterText />
        </Box>
        {!isMobile && (
          <React.Fragment>
            <Button
              type={ButtonType.primary}
              size={ButtonSize.large}
              title="GET IN TOUCH"
              loading={loading}
              width="185px"
              onClick={() => getInTouchClicked()}
            />
            <Box mb={5} />
          </React.Fragment>
        )}
        {isMobile && (
          <MobileStickyFooter
            buttonsLayout={FooterButtonsLayout.centered}
            buttonTitle="GET IN TOUCH"
            buttonLoading={loading}
            buttonOnClick={() => getInTouchClicked()}
          />
        )}
      </Box>
    </AlertWrapper>
  );
}
