import React from "react";

import {
  TextField as MUITextField,
  Box,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Colors } from "../../constants/colors";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: Colors.Grey4,
      },
      "&.Mui-focused fieldset": {
        // Border color on focus
        borderColor: Colors.BrandPrimary,
        boxShadow: "0px 0px 4px #5A75FF, 0px 4px 4px rgba(90, 117, 255, 0.2)",
      },
      "&.Mui-error fieldset": {
        borderColor: Colors.Error,
        boxShadow: "0px 0px 4px #F95555",
      },
      "&.Mui-disabled fieldset": {
        backgroundColor: Colors.Grey4,
        borderColor: Colors.Grey5,
      },
      backgroundColor: "white",
      height: "40px",
    },
  },
});

interface Props {
  value: string | null | undefined;
  placeholder?: string | undefined;
  onChange?: (value: string) => unknown;
}

export default function ({ value, placeholder, onChange }: Props) {
  const classes = useStyles();

  const inputStyle = {
    fontFamily: "GT Walsheim Pro",
    fontWeight: 400,
    fontSize: "16px",
    color: Colors.Grey7,
  }; // Need to raise z index when disabled to show value

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <MUITextField
        className={classes.root}
        value={value}
        placeholder={placeholder}
        fullWidth={true}
        variant="outlined"
        inputProps={{
          style: inputStyle,
        }}
        onChange={(event) => {
          onChange && onChange(event.target.value);
        }}
      />
    </Box>
  );
}
