import React from "react";
import { ButtonBase, Box } from "@material-ui/core";
import BlueCloseIcon from "../../images/x-blue.png";

export enum CloseButtonColor {
  WHITE,
  BLUE,
  CIRCLE,
  BLACK,
}

interface Props {
  onClick: () => unknown;
}
export default function CloseButton({ onClick }: Props): JSX.Element {
  return (
    <ButtonBase
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
    >
      <Box
        width="44px"
        height="44px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        border="1px solid #ECF2FB"
        borderRadius="6px"
        bgcolor="white"
      >
        <img src={BlueCloseIcon} />
      </Box>
    </ButtonBase>
  );
}
