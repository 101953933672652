import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useDesktop } from "../hooks/mobile";
import { Colors } from "../constants/colors";
import AlertWrapper from "../components/AlertWrapper";
import NoProperties from "../images/no-properties.png";
import { isEmpty } from "lodash-es";
import api from "../api/instance";
import AuthenticatedWrapper from "../components/AuthenticatedWrapper";
import NotificationMenuItem from "../components/Notification/NotificationMenuItem";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";
import AccountDetailsHeader from "../components/Dashboard/AccountDetailsHeader";

export default function NotificationHistory(): JSX.Element {
  const isDesktop = useDesktop();

  const [notificationItems, setNotificationItems] = React.useState([]);
  const [limit, setLimit] = React.useState(8);

  const fetchNotification = () => {
    api.get(`contract/notifications/?limit=${limit}`).then((response) => {
      setNotificationItems(response.data.results);
    });
  };

  React.useEffect(() => {
    fetchNotification();
  }, []);

  function EmptyPlaceholder() {
    return (
      <Box textAlign="center" mt={14}>
        <img src={NoProperties} height="167px" />
        <Typography variant="body1" style={{ marginTop: "24px" }}>
          No Notifications Yet!!
        </Typography>
      </Box>
    );
  }

  const handleLoadMore = () => {
    const newLimit = limit + 8;
    api.get(`contract/notifications/?limit=${newLimit}`).then((response) => {
      setNotificationItems(response.data.results);
      setLimit(newLimit);
    });
  };

  return (
    <AuthenticatedWrapper>
      <AlertWrapper>
        <Box display="flex" flexDirection="column" minHeight="100vh">
          <Box display="flex" flexDirection="column" flex={1}>
            {isDesktop && (
              <React.Fragment>
                <AccountDetailsHeader />

                <Box bgcolor={Colors.BrandBackground2} pt={8} pb={8} pl={21}>
                  <Typography variant="h4">Notification History</Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  pl={21}
                  pr={21}
                  pt={1.5}
                  alignItems="flex-start"
                >
                  <Box display="flex" flexDirection="column" flex={1} mr={3}>
                    {notificationItems && isEmpty(notificationItems) && (
                      <EmptyPlaceholder />
                    )}
                    {notificationItems &&
                      notificationItems.map((activity) => (
                        <NotificationMenuItem
                          dropdown={false}
                          activity={activity}
                        />
                      ))}
                  </Box>
                </Box>
              </React.Fragment>
            )}
            {!isDesktop && (
              <React.Fragment>
                <AccountDetailsHeader />
                <Box bgcolor={Colors.BrandBackground2} pt={8} pb={8} pl={3}>
                  <Typography variant="h4">Notification History</Typography>
                </Box>
                {notificationItems && isEmpty(notificationItems) && (
                  <EmptyPlaceholder />
                )}
                {notificationItems &&
                  notificationItems.map((activity) => (
                    <NotificationMenuItem
                      dropdown={false}
                      activity={activity}
                    />
                  ))}
              </React.Fragment>
            )}
          </Box>
          <Box mt={5} />
          {isDesktop && (
            <Box
              position="fixed"
              bottom="0"
              left="0"
              right="0"
              display="flex"
              justifyContent="center"
              padding={2}
            >
              <Button
                type={ButtonType.secondary}
                size={ButtonSize.medium}
                title="LOAD MORE"
                titleColor={Colors.BrandPrimary}
                onClick={handleLoadMore}
              />
            </Box>
          )}
          {!isDesktop && (
            <Box
              position="fixed"
              bottom="0"
              left="0"
              right="0"
              display="flex"
              justifyContent="center"
              padding={2}
            >
              <Button
                width="100%"
                type={ButtonType.secondary}
                size={ButtonSize.medium}
                title="LOAD MORE"
                titleColor={Colors.BrandPrimary}
                onClick={handleLoadMore}
              />
            </Box>
          )}
        </Box>
      </AlertWrapper>
    </AuthenticatedWrapper>
  );
}
