import { Box, Link, Typography } from "@material-ui/core";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import axios, { AxiosError } from "axios";
import { useHistory, useLocation, useParams } from "react-router-dom";

import AlertWrapper from "../components/AlertWrapper";
import { Colors } from "../constants/colors";
import React from "react";
import Separator from "../components/common/Separator";
import TextField from "../components/common/TextField";
import api from "../api/instance";
import { get } from "lodash-es";
import { isEmpty } from "lodash-es";
import { useAlert } from "../contexts/Alert";
import { useMobile } from "../hooks/mobile";
import BLUELOGO from "../images/Blue.png";
import OrganisationLogo from "../components/OrganisationLogo";
import useSWR from "swr";
import { Property, Contract, ContractPublishStatus } from "../api/models";

const noAuthApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

interface Params {
  uuid?: string;
}

export default function MyLoginPage(): JSX.Element {
  const location = useLocation();
  const history = useHistory();
  const isMobile = useMobile();

  const { uuid } = useParams<Params>();

  const token = localStorage.getItem("token");

  const query = new URLSearchParams(location.search);
  const verifySuccess = query.get("verifySuccess");
  const sessionExpired = query.get("sessionExpired");
  const resetPassword = query.get("resetPassword");
  const resetPasswordSuccess = query.get("resetPasswordSuccess");
  const locationState = location && location.state && location.state;
  const next = locationState && get(locationState, "next");
  const propertyId = query.get("propertyId");

  const { data: propertyData } = useSWR(
    propertyId && `/view-properties/${propertyId}/`
  );
  const property = propertyData && (propertyData as Property);

  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState<string | null>(null);
  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  const { setErrorMessage, setSuccessMessage } = useAlert();

  const sessionExpiredPath = localStorage.getItem("sessionExpiredPath");

  if (
    token !== null &&
    isEmpty(verifySuccess) &&
    isEmpty(sessionExpired) &&
    isEmpty(resetPassword) &&
    isEmpty(resetPasswordSuccess)
  ) {
    history.push("/dashboard");
  }

  React.useEffect(() => {
    if (verifySuccess === "true") {
      setSuccessMessage(
        "Your account was successfully verified! Please login with your credentials"
      );
    } else if (verifySuccess === "false") {
      setErrorMessage(
        "We were unable to verify your account as the link has expired or an unexpected error has occurred. Please try again or contact us for further support."
      );
    } else if (sessionExpired === "true") {
      setErrorMessage(
        "Your session has expired. Please login again to proceed"
      );
    } else if (resetPassword === "true") {
      setSuccessMessage(
        "We have sent you an email with instructions on how to reset your password. Check your inbox and click on the link provided"
      );
    } else if (resetPasswordSuccess === "true") {
      setSuccessMessage(
        "Your password was successfully updated. Please login with your new credentials"
      );
    }
  }, []);

  function loginClicked() {
    if (loading) {
      return;
    }

    let fieldsValid = true;

    if (isEmpty(email)) {
      setEmailError("Please enter your email");
      fieldsValid = false;
    }

    if (isEmpty(password)) {
      setPasswordError("Please enter your password");
      fieldsValid = false;
    }

    if (fieldsValid) {
      setLoading(true);

      noAuthApi
        .post("/token/", {
          username: email,
          password,
        })
        .then((response) => {
          const token = response.data.token;
          localStorage.setItem("token", token);

          api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

          // if (sessionExpired === "true" && sessionExpiredPath) {
          //   window.location.assign(sessionExpiredPath);
          // } else

          if (property.contract_id) {
            noAuthApi
              .get(`/view-contracts/${property.contract_id}`)
              .then((response) => {
                const contract = response.data as Contract;

                const allowContractUpload =
                  !contract ||
                  (contract &&
                    (contract.publish_status === ContractPublishStatus.Delete ||
                      contract.publish_status === ContractPublishStatus.Sold));

                if (allowContractUpload) {
                  history.push(
                    `/org/${uuid}/my-uploadcontract?propertyId=${property.id}`
                  );
                } else {
                  // Redirect to sale contract available
                  history.push(
                    `/org/${uuid}/contract-available?propertyId=${property.id}`
                  );
                }
              })
              .catch((error) => {
                setErrorMessage(
                  "There was an error fetching the contract data for this property"
                );
              });
          }

          //   if (next) {
          //     history.push(next, { user: response.data });
          //   }
          else {
            history.push(
              `/org/${uuid}/my-uploadcontract?propertyId=${property.id}`
            );
          }
        })
        .catch((error: AxiosError) => {
          setLoading(false);

          const errorData = error.response?.data;
          const message =
            errorData &&
            errorData.non_field_errors &&
            errorData.non_field_errors[0];
          setErrorMessage(message || error.message);
        });
    }
  }

  return (
    <AlertWrapper>
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        height={88}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box ml={4}>
          <OrganisationLogo uuid={uuid} />
        </Box>
      </Box>

      <Box
        mt={isMobile ? 4 : "100px"}
        width={isMobile ? "323px" : "725px"}
        marginLeft="auto"
        marginRight="auto"
        alignItems={isMobile ? "center" : undefined}
      >
        <Typography variant={isMobile ? "h5" : "h4"}>Log in</Typography>
        <Box mt={4} />
        <TextField
          title="Your email"
          type="email"
          value={email}
          error={emailError !== null}
          helperText={emailError}
          onChange={(value) => setEmail(value)}
        />
        <TextField
          title="Enter password"
          type="password"
          value={password}
          error={passwordError !== null}
          helperText={passwordError}
          onChange={(value) => setPassword(value)}
        />

        <Typography
          variant="body2"
          style={{ fontWeight: 700, marginTop: "24px" }}
        >
          Forgot your{" "}
          <Link
            href="#"
            style={{ color: Colors.Link }}
            onClick={() => history.push("/reset-password-request")}
          >
            password?
          </Link>
        </Typography>
        <Box mt={3} />
        {isMobile && <Separator mb={2} />}
        {!isMobile && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            mt={8}
            mb={12}
            ml="auto"
            mr="auto"
          >
            <Button
              type={ButtonType.dark}
              size={ButtonSize.large}
              title="LOG IN"
              width={"137px"}
              loading={loading}
              onClick={() => loginClicked()}
            />
          </Box>
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        height={88}
        position="fixed"
        alignItems="center"
        justifyContent="space-between"
        bottom="0"
      >
        <Box ml={4}>
          <Typography variant="body2">Powered by</Typography>
          <Box>
            <img src={BLUELOGO} height="32px" />
          </Box>
        </Box>
      </Box>

      {isMobile && (
        <MobileStickyFooter
          buttonsLayout={FooterButtonsLayout.centeredDark}
          buttonTitle="LOG IN"
          buttonLoading={loading}
          buttonOnClick={() => loginClicked()}
        />
      )}
    </AlertWrapper>
  );
}
