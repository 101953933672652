import { Box, Link, Typography, Menu, Badge } from "@material-ui/core";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Contract,
  addressForProperty,
  Property,
  TeamMemberRole,
} from "../api/models";
import BackButtonMobile from "../components/common/BackButtonMobile";
import ImageButton from "../components/common/ImageButton";
import StickyHeader from "../components/common/StickyHeader";
import LoggedInUserDropdown from "../components/LoggedInUserDropdown";
import { Colors } from "../constants/colors";
import { useMobile } from "../hooks/mobile";
import BlueLogo from "../images/contrax-blue-logo.svg";
import NotificationsIcon from "../images/notifications-icon.svg";
import SmallBlueBackIcon from "../images/small-blue-back-icon.svg";
import { useSessionStore } from "../stores/Session";
import ViewContractButton from "../images/view-contract-button.svg";
import LegalReviewInviteButton from "../components/LegalReviewInviteButton";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";
import ViewContractIcon from "../images/view-contract-icon.svg";
import useSWR from "swr";
import { User } from "../api/models";
import { isNil } from "lodash-es";
import {
  refreshContractTeamsAndRole,
  useContractStore,
} from "../stores/contract";
import NotificationMenu from "./Notification/NotificationMenu";
import { borderRadius } from "react-select/src/theme";
import ViewContractDrawer from "./ViewContractDrawer";

interface Props {
  property?: Property | null;
  onInviteClicked: () => unknown;
  // membersCount: number;
}

function Separator() {
  return (
    <Box
      width="100%"
      height="1px"
      bgcolor={Colors.Grey4}
      style={{ opacity: 0.7 }}
    />
  );
}

export default function LegalReviewWhiteHeader({
  property,
  onInviteClicked,
}: // membersCount,
Props) {
  const { data: userData, refresh: refreshUserData } = useSessionStore();
  const user = userData && (userData as User);

  React.useEffect(() => {
    const token = localStorage.getItem("token");

    const socket = new WebSocket(
      `wss://api-dev.contrax.com.au/connect/?token=${token}`
    );

    //Connection data
    socket.addEventListener("message", function (event) {
      const messageData = JSON.parse(event.data);

      // console.log("message count ", messageData.data.new_notification_count);
      if (user) {
        user.new_notification_count = messageData.data.new_notification_count;
      }
    });

    // Connection reconnect
    socket.addEventListener("close", function (event) {
      console.log("reconnecting...");
      setTimeout(function () {
        socket.addEventListener("message", function (event) {
          const messageData = JSON.parse(event.data);

          if (user) {
            user.new_notification_count =
              messageData.data.new_notification_count;
          }
        });
      }, 1000);
    });
  }, [user]);

  // console.log(user, "user")
  const location = useLocation();
  const history = useHistory();
  const isMobile = useMobile();

  const query = new URLSearchParams(location.search);
  const contractId = query.get("contractId");
  const recordId = query.get("recordId");
  const type = query.get("type");

  const { data } = useSWR(`/view-contracts/${contractId}/`);
  const contract = data ? (data as Contract) : null;
  const contractFile = contract ? contract.file : null;

  const { data: recdata } = useSWR(
    `/view-contracts/${contractId}/?recordId=${recordId}`
  );
  const record = recdata ? (recdata as Contract) : null;

  const {
    buyerTeamMembers,
    sellerTeamMembers,
    agentTeamMembers,
    userTeamMemberRole,
  } = useContractStore();

  const isSeller =
    userTeamMemberRole === TeamMemberRole.seller ||
    userTeamMemberRole === TeamMemberRole.sellerLawyer;

  const isAgent = userTeamMemberRole === TeamMemberRole.agent;

  const [userMenu, setUserMenu] = React.useState(null);

  const [contractDrawer, setContractDrawer] = React.useState(false);

  const [notificationMenu, setNotificationMenu] = React.useState(null);

  const handleClick = (event) => {
    // setUserMenu(event.currentTarget);

    history.push("/user");
  };

  const handleClose = () => {
    setUserMenu(null);
  };

  const handleNotificationMenu = (event) => {
    setNotificationMenu(event.currentTarget);
  };

  const handleNotificationMenuClose = () => {
    setNotificationMenu(null);
  };

  const _teamMembers = () => {
    if (
      !isNil(buyerTeamMembers.find((member) => member.email === user?.email))
    ) {
      return buyerTeamMembers;
    } else if (
      !isNil(sellerTeamMembers.find((member) => member.email === user?.email))
    ) {
      return sellerTeamMembers;
    } else if (
      !isNil(agentTeamMembers.find((member) => member.email === user?.email))
    ) {
      return agentTeamMembers;
    }

    if (type === "buying") {
      return buyerTeamMembers;
    }

    if (type === "selling") {
      return sellerTeamMembers;
    }

    return [];
  };

  const teamMembers = _teamMembers();

  // console.log(teamMembers, "Check");

  React.useEffect(() => {
    refreshUserData(false);
  }, []);

  const onViewContract = () => {
    setContractDrawer(true);
  };

  const handleContactDrawer = () => {
    setContractDrawer(false);
  };

  const _rightView = () => {
    if (isMobile) {
      return (
        <>
          <ImageButton image={ViewContractButton} onClick={onViewContract} />
          <ViewContractDrawer
            contractFile={contractFile}
            contractDrawer={contractDrawer}
            handleContactDrawer={handleContactDrawer}
          />
          <Box width="4px" />
          <LegalReviewInviteButton
            onClick={onInviteClicked}
            membersCount={teamMembers.length}
          />
          <Box width="4px" />

          <Badge
            color="secondary"
            variant="standard"
            badgeContent={user?.new_notification_count}
            overlap="rectangle"
          >
            <ImageButton
              image={NotificationsIcon}
              onClick={handleNotificationMenu}
            />
          </Badge>

          <Menu
            id="notification-menu"
            open={Boolean(notificationMenu)}
            anchorEl={notificationMenu}
            onClose={handleNotificationMenuClose}
            getContentAnchorEl={null}
            style={{ top: "40px", borderRadius: "8px" }}
            PaperProps={{
              style: {
                width: "95%",
                maxWidth: "95%",
                left: 0,
                right: 0,
              },
            }}
          >
            <NotificationMenu handleClose={handleNotificationMenuClose} />
          </Menu>
        </>
      );
    }

    return (
      <>
        <LegalReviewInviteButton
          onClick={onInviteClicked}
          membersCount={teamMembers.length}
        />
        <Box width="8px" />
        <Button
          width="176px"
          type={ButtonType.secondary}
          size={ButtonSize.small}
          title="VIEW CONTRACT"
          rightImage={ViewContractIcon}
          titleColor={Colors.BrandPrimary}
          onClick={onViewContract}
        />
        <ViewContractDrawer
          contractFile={contractFile}
          contractDrawer={contractDrawer}
          handleContactDrawer={handleContactDrawer}
        />
      </>
    );
  };

  React.useEffect(() => {
    if (!isNil(property?.contract_id)) {
      refreshContractTeamsAndRole();
    }
  }, [property]);

  const onBack = () => {
    if (userTeamMemberRole === TeamMemberRole.buyer) {
      history.push(`/dashboard`);
    } else if (type === "selling") {
      history.push(`/property-details?contractId=${contractId}&type=selling`);
    } else if (userTeamMemberRole === TeamMemberRole.agent) {
      history.push(`/property-details?contractId=${contractId}`); //If agent clicks on back to property dashboard they will be directed to the correct page.
    } else {
      history.push(
        `/property-details?contractId=${contractId}&type=${
          isSeller ? "selling" : "buying"
        }`
      );
    }
  };

  const _logoView = () => {
    if (isAgent) {
      return (
        <img
          alt="blue-logo"
          src={BlueLogo}
          style={{ marginLeft: "24px", marginRight: "24px" }}
        />
      );
    } else {
      if (record) {
        const { logo, company_title, company_pk } = record;
        if (
          userTeamMemberRole === TeamMemberRole.buyer ||
          userTeamMemberRole === TeamMemberRole.buyerLawyer ||
          userTeamMemberRole === TeamMemberRole.partner ||
          user?.current_organization?.pk === record?.company_pk ||
          user?.current_organization?.pk === record?.partner_company_pk
        ) {
          if (logo) {
            return (
              <img
                src={logo}
                height="40px"
                style={{
                  marginLeft: "24px",
                  marginRight: "12px",
                  maxWidth: "80%",
                  objectFit: "contain",
                }}
                alt="logo"
              />
            );
          }

          if (company_title) {
            return (
              <Typography
                style={{ marginLeft: "24px", marginRight: "24px" }}
                variant="h5"
              >
                {company_title}
              </Typography>
            );
          }
        }
      }

      if (contract) {
        const { logo, company_title, company_pk } = contract;

        if (
          userTeamMemberRole === TeamMemberRole.seller ||
          userTeamMemberRole === TeamMemberRole.sellerLawyer ||
          user?.current_organization?.pk === contract?.company_pk
        ) {
          if (logo) {
            return (
              <img
                src={logo}
                height="40px"
                style={{
                  marginLeft: "24px",
                  marginRight: "12px",
                  maxWidth: "80%",
                  objectFit: "contain",
                }}
                alt="logo"
              />
            );
          }

          if (company_title) {
            return (
              <Typography
                style={{ marginLeft: "24px", marginRight: "24px" }}
                variant="h5"
              >
                {company_title}
              </Typography>
            );
          }
        }
      }
    }

    return (
      <img
        src={BlueLogo}
        style={{ marginLeft: "24px", marginRight: "24px" }}
        alt="blue_logo"
      />
    );
  };

  const _colouredSeparator = () => {
    if (isAgent) {
      return <></>;
    }

    if (
      contract &&
      (userTeamMemberRole === TeamMemberRole.seller ||
        userTeamMemberRole === TeamMemberRole.sellerLawyer ||
        user?.current_organization?.pk === contract?.company_pk)
    ) {
      const { color_code } = contract;

      if (color_code) {
        return <Box height="2px" bgcolor={color_code} />;
      }
    }

    if (
      record &&
      (userTeamMemberRole === TeamMemberRole.buyer ||
        userTeamMemberRole === TeamMemberRole.buyerLawyer ||
        userTeamMemberRole === TeamMemberRole.partner ||
        user?.current_organization?.pk === record?.company_pk ||
        user?.current_organization?.pk === record?.partner_company_pk)
    ) {
      const { color_code } = record;

      if (color_code) {
        return <Box height="2px" bgcolor={color_code} />;
      }
    }

    return <></>;
  };

  if (isMobile) {
    return (
      <StickyHeader bgcolor={"white"} height="72px">
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            height="100%"
            ml="20px"
            mr="20px"
          >
            <BackButtonMobile onClick={onBack} />

            <Typography
              variant="body1"
              style={{ marginTop: "5px", marginLeft: "12px" }}
            >
              {property && addressForProperty(property)}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            height="100%"
            mr="20px"
          >
            {_rightView()}
          </Box>
        </Box>

        <Separator />
      </StickyHeader>
    );
  }

  return (
    <StickyHeader bgcolor={"white"} height="72px">
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          height="100%"
        >
          <Box
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {_logoView()}
          </Box>
          <Box
            width="1px"
            height="100%"
            bgcolor={Colors.Grey4}
            style={{ opacity: 0.7 }}
            mr="21px"
          />

          {property?.image && (
            <Box>
              <img
                src={property.image}
                width="68 px"
                height="46px"
                style={{ objectFit: "cover", borderRadius: "4px" }}
                alt="property_image"
              />
            </Box>
          )}
          <Box display="flex" flexDirection="column" ml="21px">
            <Link
              style={{ color: Colors.Link, cursor: "pointer" }}
              onClick={onBack}
            >
              <Box display="flex" flexDirection="row">
                <img src={SmallBlueBackIcon} alt="smallbluebackicon" />
                <Typography
                  variant="caption"
                  style={{ fontWeight: 700, marginLeft: "9px" }}
                >
                  Back to property dashboard
                </Typography>
              </Box>
            </Link>
            <Typography
              variant="body1"
              style={{ marginTop: "5px", marginLeft: "12px" }}
            >
              {property && addressForProperty(property)}
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          height="100%"
        >
          {_rightView()}
          <Box width="8px" />

          <Badge
            color="secondary"
            variant="standard"
            badgeContent={user?.new_notification_count}
            overlap="rectangle"
          >
            <ImageButton
              image={NotificationsIcon}
              onClick={handleNotificationMenu}
            />
          </Badge>

          <Menu
            id="notification-menu"
            open={Boolean(notificationMenu)}
            anchorEl={notificationMenu}
            onClose={handleNotificationMenuClose}
            keepMounted
            getContentAnchorEl={null}
            style={{ top: "60px", borderRadius: "8px" }}
          >
            <NotificationMenu handleClose={handleNotificationMenuClose} />
          </Menu>
          <LoggedInUserDropdown onClick={handleClick} />
        </Box>
      </Box>

      <Separator />
      {_colouredSeparator()}
    </StickyHeader>
  );
}
