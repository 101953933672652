import { Box, BoxProps, ButtonBase, Typography } from "@material-ui/core";
import { isEmpty, isNil } from "lodash-es";
import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import { addressForProperty, Contract, Property, User } from "../api/models";
import AlertWrapper from "../components/AlertWrapper";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";
import CreateAnAccountModal from "../components/CreateAnAccountModal";
import CreateAnAccountSuccessModal from "../components/CreateAnAccountSuccessModal";
import CreditsRemainingText from "../components/CreditsRemainingText";
import LoginModal from "../components/Invited/LoginModal";
import SignUpModal from "../components/Invited/SignUpModal";
import GetStartedButton from "../components/PropertyLanding/GetStartedButton";
import ViewContractDrawer from "../components/ViewContractDrawer";
import { Colors } from "../constants/colors";
import { useAlert } from "../contexts/Alert";
import { placeDetails, search } from "../googleplaces/queries";
import { useMobile } from "../hooks/mobile";
import BLUELOGO from "../images/Blue.png";
import DummyContract from "../images/DummyContract.png";
import DummyContractMobile from "../images/DummyContractMobile.png";
import GiveUsACallIcon from "../images/give-us-a-call-icon.svg";
import QuoteIcon from "../images/quote-icon.svg";
import TestimonialAvatar from "../images/testimonial-avatar.png";
import TickIcon from "../images/Tick.svg";
import { isLoggedIn, useSessionStore } from "../stores/Session";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PropertyInfoProps {
  property: Property | null;
  address: string | null;
}

function PropertyInfo({ property, address }: PropertyInfoProps) {
  const isMobile = useMobile();

  return (
    <Box
      pl="12px"
      pr="12px"
      display="flex"
      flexDirection="row"
      width={isMobile ? undefined : "398px"}
      minHeight="84px"
      alignItems="center"
      bgcolor={Colors.Grey5}
      borderRadius="8px"
      justifyContent={isNil(property?.image) ? "center" : undefined}
    >
      {property?.image && (
        <Box>
          <img
            src={property.image}
            width="80px"
            height="54px"
            style={{
              objectFit: "cover",
              borderRadius: "4px",
              marginRight: "12px",
            }}
          />
        </Box>
      )}

      <Typography variant="body2">
        {property ? addressForProperty(property) : address}
      </Typography>

      {/* <Typography variant="body2">
        ??? has shared the contract for{" "}
        {property && addressForProperty(property)}
      </Typography> */}
    </Box>
  );
}

function GiveUsACall({ ...props }: BoxProps) {
  return (
    <Box display="flex" flexDirection="row" {...props}>
      <Typography variant="body1">Give us a call</Typography>
      <img src={GiveUsACallIcon} style={{ marginLeft: "8px" }} />
      <Typography
        variant="body1"
        style={{
          fontWeight: 700,
          color: Colors.BrandPrimary,
        }}
      >
        02 8001 6123
      </Typography>
    </Box>
  );
}

function Testimonial() {
  return (
    <Box display="flex" flex={1} position="relative" mt="13px" mb="13px">
      <Box position="absolute" top={0} right={0}>
        <img src={QuoteIcon} />
      </Box>

      <Box
        mt="12px"
        mr="8px"
        flex={1}
        border="1px solid #F4F6F8"
        borderRadius="7px"
        display="flex"
        flexDirection="row"
      >
        <img
          src={TestimonialAvatar}
          width="42px"
          height="42px"
          style={{ marginTop: "16px", marginLeft: "15px" }}
        />

        <Box ml="8px" mt="16px" mb="16px" mr="24px">
          <Typography variant="body2">
            Contrax was great. The contract summary was clear and easy to
            understand. The process was really efficient, and the speedy
            turnaround meant I could move quickly with an offer."
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

interface ContentProps {
  propertyId?: number | null;
  contract?: Contract | null;
  placeId?: string | null;
  address?: string | null;
  onDownloadContract: () => unknown;
  onSignUp: () => unknown;
  onLogin: () => unknown;
  showDownloadContract: boolean;
  onViewSampleLegalReview: () => unknown;
  role: PropertyLandingRole;
}

function Content({
  propertyId,
  contract,
  placeId,
  address,
  onDownloadContract,
  onSignUp,
  onLogin,
  showDownloadContract,
  onViewSampleLegalReview,
  role,
}: ContentProps) {
  const isMobile = useMobile();

  const textVariant = isMobile ? "body2" : "body1";
  const isBuyer = role === "buyer";

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      pt="18px"
      pb="55px"
    >
      <Typography
        variant={isMobile ? "h5" : "h4"}
        style={{ textAlign: "center", maxWidth: "405px" }}
      >
        {isBuyer
          ? "Are you interested in buying this property?"
          : "Contrax for lawyers and conveyancers"}
      </Typography>

      <Box
        width={isMobile ? "100%" : "398px"}
        display="flex"
        flexDirection="column"
        pt="8px"
        mt="13px"
      >
        <Typography variant={textVariant}>
          {isBuyer
            ? "Quickly understand any issues with the contract and move fast to personalise it to your needs."
            : "Streamline your process and get more done."}
        </Typography>

        <Box
          display="flex"
          flexDirection="row"
          mt="16px"
          alignItems="flex-start"
        >
          <img src={TickIcon} style={{ marginRight: "8px" }} />
          <Typography variant={textVariant}>
            {isBuyer
              ? "Get a summary of the contract in easy to understand language."
              : "Save time with a digital contract summary and platform for you to complete your contract review."}
          </Typography>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          mt="8px"
          alignItems="flex-start"
        >
          <img src={TickIcon} style={{ marginRight: "8px" }} />
          <Typography variant={textVariant}>
            {isBuyer
              ? "Uncover any issues and ask any questions before you move forward."
              : "Communicate with your clients quickly and efficiently throughout the process."}
          </Typography>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          mt="8px"
          alignItems="flex-start"
        >
          <img src={TickIcon} style={{ marginRight: "8px" }} />
          <Typography variant={textVariant}>
            {isBuyer
              ? "Proceed with Contrax to propose changes and negotiate terms before you buy."
              : "Seamlessly prepare and send your amendment requests and general enquiries and keep track of agreed changes."}
          </Typography>
        </Box>

        <Testimonial />

        {isLoggedIn() && (
          <Box
            display="flex"
            flexDirection={"column"}
            alignItems="center"
            mt={3}
          >
            <CreditsRemainingText />
          </Box>
        )}

        <GetStartedButton
          contract={contract}
          propertyId={propertyId}
          placeId={placeId}
          address={address}
          onSignUp={onSignUp}
        />
        <Box height="8px" />
        {/* <Button
          type={ButtonType.secondary}
          size={ButtonSize.medium}
          title="VIEW SAMPLE LEGAL REVIEW"
          onClick={onViewSampleLegalReview}
        />
        <Box height="8px" /> */}

        {isMobile && showDownloadContract && (
          <>
            <Button
              type={ButtonType.secondary}
              size={ButtonSize.medium}
              title="DOWNLOAD CONTRACT"
              onClick={onDownloadContract}
            />
            <Box height="8px" />
          </>
        )}

        {!isLoggedIn() && (
          <Button
            type={ButtonType.secondary}
            size={ButtonSize.medium}
            title="LOGIN"
            onClick={onLogin}
          />
        )}
      </Box>
    </Box>
  );
}

interface ContractProps {
  contract: Contract | null;
}

function ContractView({ contract }: ContractProps) {
  const isMobile = useMobile();
  const loading = "Loading contract...";

  if (contract?.file && isLoggedIn()) {
    if (isMobile) {
      return (
        <Box height="240px" overflow="hidden">
          <Document file={contract.file} loading={loading}>
            <Page pageNumber={1} width={window.innerWidth * 0.7} />
          </Document>
        </Box>
      );
    } else {
      return (
        <Box>
          <Document file={contract.file} loading={loading}>
            <Page pageNumber={1} width={457} />
          </Document>
        </Box>
      );
    }
  } else {
    if (isMobile) {
      return <img src={DummyContractMobile} width="70%" style={{}} />;
    } else {
      return <img src={DummyContract} width="489px" height="658px" />;
    }
  }
}

export enum PropertyLandingRole {
  buyer = "buyer",
  lawyer = "lawyer",
}

export default function PropertyLanding() {
  const isMobile = useMobile();
  const location = useLocation();
  const history = useHistory();

  const { data: userData, refresh: refreshUserData } = useSessionStore();
  const user = userData ? (userData as User) : null;

  const query = new URLSearchParams(location.search);

  const propertyId = query.get("propertyId");

  const { data: propertyData } = useSWR(
    propertyId ? `/view-properties/${propertyId}/` : null
  );
  const property = propertyData && (propertyData as Property);

  const contractId = property ? property.contract_id : null;

  const { data, mutate: contractMutate } = useSWR(
    `/view-contracts/${contractId}/`
  );
  const contract = data ? (data as Contract) : null;

  const label = query.get("label");
  const placeId = query.get("placeId");
  const paymentFailed = query.get("paymentFailed");

  const role = query.get("role") as PropertyLandingRole;

  const [address, setAddress] = React.useState("");

  const { setErrorMessage } = useAlert();

  const contractFile = contract?.file;

  const [createAnAccountModalOpen, setCreateAnAccountModalOpen] =
    React.useState(false);

  const [createAnAccountSuccessModalOpen, setCreateAnAccountSuccessModalOpen] =
    React.useState(false);

  const [createAnAccountSuccessEmail, setCreateAnAccountSuccessEmail] =
    React.useState<string | null>(null);

  const [loginModalOpen, setLoginModalOpen] = React.useState(false);

  const downloadContract = () => {
    if (!isLoggedIn()) {
      onSignUp();
    } else {
      setContractDrawerOpen(true);

      // contractFile && window.open(contractFile);
    }
  };

  React.useEffect(() => {
    refreshUserData(false);
  }, []);

  React.useEffect(() => {
    if (label) {
      search(label)
        .then((results: any) => {
          setAddress(results[0].description);
        })
        .catch((error) => {
          console.debug("error: ", error);
        });
    }
  }, []);

  React.useEffect(() => {
    placeDetails(placeId).then((result) => {
      let place = result as google.maps.places.PlaceResult;

      setAddress(place.formatted_address || "");
    });
  }, [placeId]);

  React.useEffect(() => {
    if (paymentFailed === "true") {
      setErrorMessage(
        "We were unable to process payment on the card you have provided"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentFailed]);

  function onSignUp() {
    // setCreateAnAccountModalOpen(true);

    const data = {};

    if (role) {
      data["role"] = role;
    }

    if (propertyId) {
      data["propertyId"] = propertyId;
    }

    const params = new URLSearchParams(data);

    history.push(`/signup?${params.toString()}`);
  }

  function onSignedUp(email: string) {
    setCreateAnAccountModalOpen(false);
    setCreateAnAccountSuccessEmail(email);
    setCreateAnAccountSuccessModalOpen(true);
  }

  function onLogin() {
    setCreateAnAccountModalOpen(false);
    setLoginModalOpen(true);
  }

  function onLoggedIn() {
    setLoginModalOpen(false);
    refreshUserData(false);
  }

  function onResendEmail() {
    setCreateAnAccountSuccessModalOpen(false);
  }

  function onViewSampleLegalReview() {
    history.push(`/sample-legal-review?propertyId=${propertyId}`);
  }

  const showDownloadContract = !isNil(contract) && !isNil(contract.file);

  const invited = query.get("is_invited") === "true";

  const invitedVerificationToken = query.get("token");
  const inviterFirstName = query.get("first_name") || "";
  const inviterLastName = query.get("last_name") || "";

  const [signUpModalOpen, setSignUpModalOpen] = React.useState(false);

  const [contractDrawerOpen, setContractDrawerOpen] = React.useState(false);

  React.useEffect(() => {
    // If an existing user has been invited
    if (invited) {
      if (isLoggedIn()) {
        contractMutate();
      } else {
        setLoginModalOpen(true);
      }
    }
  }, []);

  React.useEffect(() => {
    // For inviting a new user
    if (!isEmpty(invitedVerificationToken)) {
      setSignUpModalOpen(true);
    }
  }, []);

  const signUpModalOnClose = () => setSignUpModalOpen(false);
  const signUpModalOnSignedUp = () => {
    setSignUpModalOpen(false);
    refreshUserData();
  };
  const signUpModalOnLogin = () => {
    setSignUpModalOpen(false);
    setLoginModalOpen(true);
  };

  if (isMobile) {
    return (
      <>
        <AlertWrapper>
          <Box
            display="flex"
            flexDirection="column"
            bgcolor={Colors.Grey5}
            alignItems="center"
          >
            <GiveUsACall mt="13px" mb="17px" />
            <ButtonBase
              onClick={() => window.location.assign(window.location.origin)}
            >
              <img src={BLUELOGO} height="33px" />
            </ButtonBase>
            <Box height="15px" />
            <ContractView contract={contract} />
          </Box>

          <Box display="flex" flexDirection="column" alignItems="center">
            <Box display="flex" flexDirection="column" width="88%" pt="27px">
              <PropertyInfo property={property} address={address} />
              <Content
                contract={contract}
                propertyId={!isNil(propertyId) ? +propertyId : null}
                placeId={placeId}
                address={address}
                onDownloadContract={downloadContract}
                onSignUp={onSignUp}
                onLogin={onLogin}
                showDownloadContract={showDownloadContract}
                onViewSampleLegalReview={onViewSampleLegalReview}
                role={role}
              />
            </Box>
          </Box>
        </AlertWrapper>

        <CreateAnAccountModal
          open={createAnAccountModalOpen}
          onCancel={() => setCreateAnAccountModalOpen(false)}
          onSignedUp={onSignedUp}
          onLogin={onLogin}
          role={role}
        />

        <CreateAnAccountSuccessModal
          open={createAnAccountSuccessModalOpen}
          email={createAnAccountSuccessEmail}
          onResendEmail={onResendEmail}
        />

        <LoginModal
          open={loginModalOpen}
          onLoggedIn={onLoggedIn}
          onCancel={() => setLoginModalOpen(false)}
        />

        <SignUpModal
          open={signUpModalOpen}
          onClose={signUpModalOnClose}
          contractId={contractId}
          inviterName={`${inviterFirstName} ${inviterLastName}`}
          token={invitedVerificationToken}
          onSignedUp={signUpModalOnSignedUp}
          onLogin={signUpModalOnLogin}
        />

        <ViewContractDrawer
          contractFile={contractFile}
          contractDrawer={contractDrawerOpen}
          handleContactDrawer={() => setContractDrawerOpen(false)}
        />
      </>
    );
  }

  return (
    <>
      <AlertWrapper>
        <Box display="flex" flexDirection="row" flex={1}>
          <Box
            bgcolor="white"
            width="592px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            pt="115px"
          >
            <ButtonBase
              onClick={() => window.location.assign(window.location.origin)}
            >
              <img
                src={BLUELOGO}
                height="40px"
                style={{ marginBottom: "31px" }}
              />
            </ButtonBase>

            {/* Property info */}
            <PropertyInfo property={property} address={address} />
            <Content
              contract={contract}
              propertyId={!isNil(propertyId) ? +propertyId : null}
              placeId={placeId}
              address={address}
              onDownloadContract={downloadContract}
              onSignUp={onSignUp}
              onLogin={onLogin}
              showDownloadContract={showDownloadContract}
              onViewSampleLegalReview={onViewSampleLegalReview}
              role={role}
            />
          </Box>
          <Box
            bgcolor={Colors.Grey5}
            display="flex"
            alignItems="center"
            justifyContent="center"
            minHeight="100vh"
            flex={1}
            position="relative"
          >
            <Box
              position="absolute"
              top={50}
              right={50}
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              {showDownloadContract && (
                <Button
                  title="DOWNLOAD CONTRACT"
                  type={ButtonType.primary}
                  size={ButtonSize.small}
                  width="198px"
                  onClick={downloadContract}
                />
              )}

              <Box ml="22px">
                <GiveUsACall />
              </Box>
            </Box>

            <ContractView contract={contract} />
          </Box>
        </Box>
      </AlertWrapper>

      <CreateAnAccountModal
        open={createAnAccountModalOpen}
        onCancel={() => setCreateAnAccountModalOpen(false)}
        onSignedUp={onSignedUp}
        onLogin={onLogin}
        role={role}
      />

      <CreateAnAccountSuccessModal
        open={createAnAccountSuccessModalOpen}
        email={createAnAccountSuccessEmail}
        onResendEmail={onResendEmail}
      />

      <LoginModal
        open={loginModalOpen}
        onLoggedIn={onLoggedIn}
        onCancel={() => setLoginModalOpen(false)}
      />

      <SignUpModal
        open={signUpModalOpen}
        onClose={signUpModalOnClose}
        contractId={contractId}
        inviterName={`${inviterFirstName} ${inviterLastName}`}
        token={invitedVerificationToken}
        onSignedUp={signUpModalOnSignedUp}
        onLogin={signUpModalOnLogin}
      />

      <ViewContractDrawer
        contractFile={contractFile}
        contractDrawer={contractDrawerOpen}
        handleContactDrawer={() => setContractDrawerOpen(false)}
      />
    </>
  );
}
