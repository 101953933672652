import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Colors } from "../constants/colors";
import {
  LegalReviewRequest,
  LegalReviewRequestStage,
  Enquiry,
  EnquiryStage,
} from "../api/models";
import CompleteIcon from "../images/record-status-complete.svg";
import { DashboardContract } from "../api/models";
import { useMobile } from "../hooks/mobile";

export enum StatusPillType {
  pending,
  agreed,
  notAgreed,
  alternativeSuggested,
  answered,
  deleted,
  assigned,
  fromPartner,
  inProgress,
  new,
  sold,
  partnersave11,
  partnersave15,
  archive,
}

interface Props {
  type: StatusPillType;
  contract?: DashboardContract;
}

export function statusPillTypeForRequest(request: LegalReviewRequest) {
  switch (request.request_status) {
    case LegalReviewRequestStage.buyerAgreed:
    case LegalReviewRequestStage.sellerAgreed:
      return StatusPillType.agreed;
    case LegalReviewRequestStage.buyerNotAgreed:
    case LegalReviewRequestStage.sellerNotAgreed:
      return StatusPillType.notAgreed;
    case LegalReviewRequestStage.pending:
      return StatusPillType.pending;
    case LegalReviewRequestStage.alternativeSuggested:
      return StatusPillType.alternativeSuggested;
    case LegalReviewRequestStage.answered:
      return StatusPillType.answered;
    case LegalReviewRequestStage.deleted:
      return StatusPillType.deleted;
  }
}

export function statusPillTypeForEnquiry(enquiry: Enquiry) {
  switch (enquiry.enquiry_status) {
    case EnquiryStage.Pending:
      return StatusPillType.pending;
    case EnquiryStage.Answered:
      return StatusPillType.answered;
    case EnquiryStage.Deleted:
      return StatusPillType.deleted;
  }
}

export default function ({ type, contract }: Props) {
  const isMobile = useMobile();

  if (type === StatusPillType.pending) {
    return (
      <Box
        width="77px"
        height="24px"
        bgcolor={Colors.Grey5}
        borderRadius="7px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="overline"
          style={{ color: Colors.Grey3, letterSpacing: "0.1em" }}
        >
          PENDING
        </Typography>
      </Box>
    );
  }

  if (type === StatusPillType.deleted) {
    return (
      <Box
        width="72px"
        height="24px"
        bgcolor={Colors.Grey5}
        borderRadius="7px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="overline"
          style={{ color: Colors.Grey3, letterSpacing: "0.1em" }}
        >
          DELETED
        </Typography>
      </Box>
    );
  }

  if (type === StatusPillType.agreed) {
    return (
      <Box
        width="70px"
        height="24px"
        bgcolor={Colors.SuccessBackground}
        borderRadius="7px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="overline"
          style={{ color: Colors.UISuccess, letterSpacing: "0.1em" }}
        >
          AGREED
        </Typography>
      </Box>
    );
  }

  if (type === StatusPillType.notAgreed) {
    return (
      <Box
        width="101px"
        height="24px"
        bgcolor={Colors.ErrorBackground}
        borderRadius="7px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="overline"
          style={{ color: Colors.UIError, letterSpacing: "0.1em" }}
        >
          NOT AGREED
        </Typography>
      </Box>
    );
  }

  if (type === StatusPillType.alternativeSuggested) {
    return (
      <Box
        width="186px"
        height="24px"
        bgcolor={Colors.WarningBackground}
        borderRadius="7px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="overline"
          style={{ color: Colors.Warning, letterSpacing: "0.1em" }}
        >
          ALTERNATIVE SUGGESTED
        </Typography>
      </Box>
    );
  }

  if (type === StatusPillType.answered) {
    return (
      <Box
        width="91px"
        height="24px"
        bgcolor={Colors.SuccessBackground}
        borderRadius="7px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="overline"
          style={{ color: Colors.Success, letterSpacing: "0.1em" }}
        >
          ANSWERED
        </Typography>
      </Box>
    );
  }

  if (type === StatusPillType.assigned) {
    return (
      <Box
        width="168px"
        height="28px"
        bgcolor={Colors.BrandBackground2}
        borderRadius="15px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        mb={0.25}
      >
        <img
          src={CompleteIcon}
          height="22px"
          width="22px"
          style={{ marginRight: "4px" }}
        />

        <Typography
          variant="body2"
          style={{ color: Colors.BrandPrimary, letterSpacing: "0.1em" }}
        >
          Assigned to me
        </Typography>
      </Box>
    );
  }

  if (type === StatusPillType.archive){
    return (
      <Box
        width="168px"
        height="28px"
        bgcolor={Colors.Grey5}
        borderRadius="15px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="overline"
          style={{ color: Colors.Grey3, letterSpacing: "0.1em" }}
        >
          Archived
        </Typography>
      </Box>
    );
  }

  if (type === StatusPillType.fromPartner) {
    return (
      <Box
        width="24px"
        height="24px"
        bgcolor={Colors.BrandBackground2}
        borderRadius="7px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="overline"
          style={{ color: Colors.Pressed, letterSpacing: "0.1em" }}
        >
          P
        </Typography>
      </Box>
    );
  }

  if (type === StatusPillType.inProgress) {
    return (
      <Box
        width="118px"
        height="24px"
        bgcolor={Colors.SuccessBackground}
        borderRadius="15px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        mb={1}
      >
        <Typography
          variant="overline"
          style={{ color: Colors.Success, letterSpacing: "0.1em" }}
        >
          {contract?.in_progress_count === 0
            ? " "
            : contract?.in_progress_count}{" "}
          IN PROGRESS
        </Typography>
      </Box>
    );
  }

  if (type === StatusPillType.new) {
    return (
      <Box
        width="57px"
        height="24px"
        mb={1}
        bgcolor={Colors.WarningBackground}
        borderRadius="15px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="overline"
          style={{ color: Colors.Warning, letterSpacing: "0.1em" }}
        >
          {contract?.new_count === 0 ? " " : contract?.new_count} NEW
        </Typography>
      </Box>
    );
  }

  if (type === StatusPillType.sold) {
    return (
      <Box
        width="51px"
        height="24px"
        bgcolor={Colors.Grey5}
        borderRadius="15px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="overline"
          style={{ color: Colors.Grey3, letterSpacing: "0.1em" }}
        >
          SOLD
        </Typography>
      </Box>
    );
  }

  if (type === StatusPillType.partnersave11) {
    return (
      <Box
        width={isMobile ? "88px" : "82px"}
        height="22px"
        bgcolor={Colors.Success}
        borderRadius="16px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="overline"
          style={{ color: Colors.White, letterSpacing: "0.2px" }}
        >
          SAVE 11%
        </Typography>
      </Box>
    );
  }

  if (type === StatusPillType.partnersave15) {
    return (
      <Box
        width={isMobile ? "88px" : "82px"}
        height="22px"
        bgcolor={Colors.Success}
        borderRadius="16px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="overline"
          style={{ color: Colors.White, letterSpacing: "0.2px" }}
        >
          SAVE 25%
        </Typography>
      </Box>
    );
  }

  return <></>;
}
