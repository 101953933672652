import { Box, Typography } from "@material-ui/core";
import { isNaN, isEmpty } from "lodash-es";
import React from "react";
import { Colors } from "../../constants/colors";
import { useAlert } from "../../contexts/Alert";
import Button, { ButtonSize, ButtonType } from "../common/Button";
import NumberField from "../common/NumberField";
import TextField from "../common/TextField";

interface Props {
  onAddSummary: (title: string, content: string, page: number) => unknown;
  onCancel: () => unknown;
  adding: boolean;
}

export default function ({ onAddSummary, onCancel, adding }: Props) {
  const [title, setTitle] = React.useState("");
  const [summary, setSummary] = React.useState("");
  const [page, setPage] = React.useState<number | "">("");

  const { setErrorMessage } = useAlert();

  return (
    <Box p="16px" bgcolor={Colors.BrandBackground3} m="8px" borderRadius="8px">
      <TextField
        title="Title"
        value={title}
        onChange={(text) => setTitle(text)}
      />
      <TextField
        title="Summary"
        value={summary}
        onChange={(text) => setSummary(text)}
        multiline
      />

      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography
            variant="body2"
            style={{ fontWeight: 700, marginRight: "8px" }}
          >
            Page no.
          </Typography>

          <Box width="60px">
            <NumberField
              value={String(page)}
              onChange={(text) => {
                const number = parseInt(text);

                if (!isNaN(number)) {
                  setPage(number);
                } else {
                  setPage("");
                }
              }}
            />
          </Box>
        </Box>

        <Box display="flex" flexDirection="row">
          <Button
            type={ButtonType.secondary}
            size={ButtonSize.small}
            title="CANCEL"
            width="90px"
            onClick={onCancel}
          />
          <Box width="8px" />
          <Button
            loading={adding}
            type={ButtonType.primary}
            size={ButtonSize.small}
            title="ADD"
            width="58px"
            onClick={() => {
              if (isEmpty(title)) {
                setErrorMessage("Please enter a title");
                return;
              }

              if (isEmpty(summary)) {
                setErrorMessage("Please enter a summary");
                return;
              }

              if (page === "") {
                setErrorMessage(
                  "Please enter the page number for this summary"
                );
                return;
              }

              onAddSummary(title, summary, page || 1);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
