import { Box, Typography, Radio } from "@material-ui/core";
import React from "react";
import { Colors } from "../../constants/colors";
import { useAlert } from "../../contexts/Alert";
import { useMobile } from "../../hooks/mobile";
import Button, { ButtonSize, ButtonType } from "../common/Button";
import TextField from "../common/TextField";
import StatusPill, { StatusPillType } from "../StatusPill";

interface Props {
  onAddEnquiry: (title: string, enquiry: string) => unknown;
  onCancel: () => unknown;
  adding: boolean;
}

export default function CustomEnquiryBox({
  onAddEnquiry,
  onCancel,
  adding,
}: Props) {
  const isMobile = useMobile();

  const [title, setTitle] = React.useState("");

  const { setErrorMessage } = useAlert();

  const [enquiry, setEnquiry] = React.useState("");

  return (
    <Box
      border="1px solid #E1EAFF"
      bgcolor="white"
      borderRadius="16px"
      display="flex"
      flexDirection="column"
      maxWidth={isMobile ? "100%" : "1097px"}
      mt="12px"
    >
      <Box
        height="77px"
        boxShadow="0px 1px 0px #E0E0E0"
        display="flex"
        flexDirection="row"
        alignItems="center"
        pl="24px"
        pr="24px"
      >
        <Typography variant="h6" style={{ fontWeight: 700, flex: 1 }}>
          New Enquiry
        </Typography>
        <StatusPill type={StatusPillType.pending} />
      </Box>

      <Box display="flex" flexDirection="row" p="24px">
        <Box>
          <TextField
            title="Title"
            value={title}
            onChange={(text) => setTitle(text)}
            placeholder="Enter title"
          />

          <Box
            width={isMobile ? "80vw" : "515px"}
            bgcolor={Colors.Grey5}
            borderRadius="8px"
          >
            <Box m="16px" pt="16px">
              <TextField
                title="Enquiry"
                value={enquiry}
                onChange={(text) => setEnquiry(text)}
                multiline
                placeholder="Enter enquiry"
              />
            </Box>
          </Box>
        </Box>

        {!isMobile && (
          <Box
            width="515px"
            ml="20px"
            pr="61px"
            height="100%"
            display="flex"
            flexDirection="column"
          >
            <Typography
              variant="body2"
              style={{ fontWeight: 700, marginBottom: "16px" }}
            >
              Description
            </Typography>
            <Typography variant="body2">
              General enquiries are not a request to change the contract of sale
              but rather general questions about the property. <br />
              <br />
              Modify them, or use them as is to speed up your workflow.
            </Typography>
          </Box>
        )}
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        boxShadow="0px -1px 0px #E0E0E0"
        height="74px"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" flexDirection="row" alignItems="center"></Box>

        <Box display="flex" flexDirection="row" alignItems="center" pr="16px">
          <Button
            type={ButtonType.destructive}
            size={ButtonSize.small}
            title="DISMISS"
            width="93px"
            onClick={onCancel}
          />
          <Box width="8px" />
          <Button
            loading={adding}
            type={ButtonType.primary}
            size={ButtonSize.small}
            title="ADD"
            width="58px"
            onClick={() => onAddEnquiry(title, enquiry)}
          />
        </Box>
      </Box>
    </Box>
  );
}
