import { Box, Dialog, Link, Typography } from "@material-ui/core";
import axios, { AxiosError } from "axios";
import { isEmpty } from "lodash-es";
import React from "react";
import { useHistory } from "react-router-dom";
import api from "../api/instance";
import { User } from "../api/models";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";
import TextField from "../components/common/TextField";
import PrivacyLink from "../components/PrivacyLink";
import TermsLink from "../components/TermsLink";
import { Colors } from "../constants/colors";
import { useAlert } from "../contexts/Alert";
import { useMobile } from "../hooks/mobile";
import { PropertyLandingRole } from "../pages/PropertyLanding";

interface Props {
  open: boolean;
  onLogin: () => unknown;
  onSignedUp: (email: string) => unknown;
  onCancel: () => unknown;
  role: PropertyLandingRole;
}

const noAuthApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export default function ({ open, onLogin, onSignedUp, onCancel, role }: Props) {
  const isMobile = useMobile();
  const history = useHistory();

  const [firmName, setFirmName] = React.useState("");
  const [firmNameError, setFirmNameError] = React.useState<string | null>(null);

  const [firstName, setFirstName] = React.useState("");
  const [firstNameError, setFirstNameError] = React.useState<string | null>(
    null
  );
  const [lastName, setLastName] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState<string | null>(null);

  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState<string | null>(null);

  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [phoneNumberError, setPhoneNumberError] = React.useState<string | null>(
    null
  );

  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState<string | null>(null);

  const [consent, setConsent] = React.useState<boolean>(false);
  const [consentError, setConsentError] = React.useState<string | null>(null);

  const [loading, setLoading] = React.useState(false);

  const { setErrorMessage } = useAlert();

  function resetErrors() {
    setFirmNameError(null);
    setFirstNameError(null);
    setLastNameError(null);
    setEmailError(null);
    setPhoneNumberError(null);
    setPasswordError(null);
    setErrorMessage(null);
    setConsentError(null);
  }

  function verifyFields() {
    resetErrors();

    let allFieldsValid = true;

    if (isEmpty(firstName)) {
      setFirstNameError("Please enter your first name");
      allFieldsValid = false;
    }

    if (isEmpty(lastName)) {
      setLastNameError("Please enter your last name");
      allFieldsValid = false;
    }

    if (isEmpty(email)) {
      setEmailError("Please enter your email");
      allFieldsValid = false;
    }

    if (isEmpty(password)) {
      setPasswordError("Please enter a password");
      allFieldsValid = false;
    }

    if (!consent) {
      setConsentError("This box must be checked to create an account");
      allFieldsValid = false;
    }

    return allFieldsValid;
  }

  function signUpTapped() {
    if (loading) {
      return;
    }

    if (!verifyFields()) {
      setErrorMessage("Please fill in the missing fields");
      return;
    }

    const commonData = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone: phoneNumber,
      password,
    };

    const data =
      role === "lawyer"
        ? {
            firm_name: firmName,
            ...commonData,
          }
        : commonData;

    setLoading(true);

    noAuthApi
      .post(role === "lawyer" ? "/solicitor/?version=v2" : "/buyer/", data)
      .then((response) => {
        setLoading(false);

        console.debug("response: ", response);

        const user = response.data as User;
        if (user.validation_token) {
          localStorage.setItem("token", user.validation_token);

          api.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${user.validation_token}`;

          onSignedUp(email);
        }
      })
      .catch((error: AxiosError) => {
        console.debug("error: ", error);

        setLoading(false);
        setErrorMessage("Please check errors on this page");

        const errors = error && error.response && error.response.data;

        const { firm_name, first_name, last_name, email, phone, password } =
          errors;

        firm_name && setFirmNameError(firm_name[0]);
        first_name && setFirstNameError(first_name[0]);
        last_name && setLastNameError(last_name[0]);
        email && setEmailError(email[0]);
        phone && setPhoneNumberError(phone[0]);
        password && setPasswordError(password[0]);
      });
  }

  return (
    <Dialog open={open} fullScreen={isMobile} maxWidth="lg">
      <Box width={isMobile ? "100%" : "665px"}>
        <Box
          height="62px"
          boxSizing="border-box"
          pl="24px"
          display="flex"
          flexDirection="row"
          alignItems="center"
          boxShadow="0px 1px 0px #E0E0E0"
        >
          <Typography variant="h5" style={{ fontWeight: 700 }}>
            Create an account
          </Typography>
        </Box>

        <Box pl="24px" pr="26px" mt="16px">
          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            mt={1}
          >
            <Box width={isMobile ? "100%" : "298px"}>
              <TextField
                title="First name"
                value={firstName}
                error={firstNameError !== null}
                helperText={firstNameError}
                onChange={(value) => setFirstName(value)}
              />
            </Box>

            <Box mt={2} width="19px" />

            <Box width={isMobile ? "100%" : "298px"}>
              <TextField
                title="Last name"
                value={lastName}
                error={lastNameError !== null}
                helperText={lastNameError}
                onChange={(value) => setLastName(value)}
              />
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            mt={1}
          >
            <Box width={isMobile ? "100%" : "298px"}>
              <TextField
                type="email"
                title="Email"
                error={emailError !== null}
                helperText={emailError}
                value={email}
                onChange={(value) => setEmail(value)}
              />
            </Box>

            <Box mt={2} width="19px" />

            <Box width={isMobile ? "100%" : "298px"}>
              <TextField
                type="phone"
                title="Phone number"
                error={phoneNumberError !== null}
                helperText={phoneNumberError}
                value={phoneNumber}
                onChange={(value) => setPhoneNumber(value)}
              />
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            mt={1}
          >
            <Box width={isMobile ? "100%" : "298px"}>
              <TextField
                type="password"
                title="Password"
                error={passwordError !== null}
                helperText={passwordError}
                value={password}
                onChange={(value) => setPassword(value)}
              />
            </Box>

            <Box mt={2} width="19px" />

            {role === "lawyer" && (
              <Box width={isMobile ? "100%" : "298px"}>
                <TextField
                  title="Firm name (optional)"
                  value={firmName}
                  error={firmNameError !== null}
                  helperText={firmNameError}
                  onChange={(value) => setFirmName(value)}
                />
              </Box>
            )}
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" pl="24px" pr="26px" mt={1}>
          <input
            type="checkbox"
            style={{ outline: consentError ? "2px solid red" : "" }}
            onChange={(event) => {
              if (event.target.checked) {
                setConsent(true);
              } else {
                setConsent(false);
              }
            }}
            checked={consent}
          />
          <Typography variant="body2" style={{ marginLeft: "8px" }}>
            I agree to the <TermsLink /> (including those relating to privacy
            and communication) and <PrivacyLink />
          </Typography>
          <Typography variant="caption" color="secondary">
            {consentError}
          </Typography>
        </Box>
        <Box pl="24px" pr="26px" pb="38px">
          <Typography variant="body2" style={{ marginTop: "20px" }}>
            Already have an account?{" "}
            <Link
              style={{ fontWeight: 700, color: Colors.Link, cursor: "pointer" }}
              onClick={onLogin}
            >
              Log in
            </Link>
          </Typography>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          height="74px"
          pr="16px"
          boxSizing="border-box"
          boxShadow="0px -1px 0px #E0E0E0"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            type={ButtonType.secondary}
            size={ButtonSize.medium}
            title="CANCEL"
            width="114px"
            onClick={onCancel}
          />
          <Box width="8px" />
          <Button
            type={ButtonType.primary}
            size={ButtonSize.medium}
            title="SIGN UP"
            width="108px"
            loading={loading}
            onClick={signUpTapped}
          />
        </Box>
      </Box>
    </Dialog>
  );
}
