import React from "react";
import { Box, Typography } from "@material-ui/core";
import InitialsView from "../InitialsView";
import { TeamMember, TeamMemberRole } from "../../api/models";
import { initialsForTeamMember, nameForTeamMember } from "../../helpers/user";

interface Props {
  member: TeamMember;
}

export default function ({ member }: Props) {
  const role = () => {
    switch (member.role) {
      case TeamMemberRole.sellerLawyer:
        return "Seller Legal Rep";
      case TeamMemberRole.buyerLawyer:
        return "Buyer Legal Rep";
      default:
        return member.role;
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      pl="24px"
      pr="24px"
      mt="10px"
      mb="10px"
      alignItems="center"
    >
      <InitialsView initials={initialsForTeamMember(member)} />

      <Box display="flex" flexDirection="column" ml="10px" flex={1}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          flex={1}
        >
          <Typography variant="body2" style={{ fontWeight: 700 }}>
            {nameForTeamMember(member)}
          </Typography>
          <Typography variant="caption">{role()}</Typography>
        </Box>

        <Typography variant="caption">{member.email}</Typography>
      </Box>
    </Box>
  );
}
