import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { Font } from "@react-pdf/renderer";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { SWRConfig } from "swr";
import api from "./api/instance";
import App from "./App";
import { AlertContextProvider } from "./contexts/Alert";
import GTWalsheimBold from "./fonts/GTWalsheimPro-Bold.ttf";
import GTWalsheimRegular from "./fonts/GTWalsheimPro-Regular.ttf";
import "./fonts/GTWalsheimPro.css";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

const theme = createMuiTheme({
  typography: {
    fontFamily: "GT Walsheim Pro",
    fontWeightRegular: 400,
    fontWeightBold: 700,
    h4: {
      fontWeight: 700,
      fontSize: "34px",
      lineHeight: "40px",
    },
    h5: {
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "30px",
    },
    body2: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
    },
    body1: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "22px",
    },
    overline: {
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: "16px",
    },
    caption: {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "14px",
    },
  },
});

const fetcher = (url: string) => api.get(url).then((res) => res.data);

const gaKey = process.env.REACT_APP_GA_KEY;
gaKey && ReactGA.initialize(gaKey);

// Register font for react-pdf
Font.register({
  family: "GT Walsheim Pro",
  fonts: [
    {
      src: GTWalsheimRegular,
    },
    {
      src: GTWalsheimBold,
      fontWeight: 700,
    },
  ],
});

const stripeFonts = [
  {
    cssSrc: "https://fonts.googleapis.com/css2?family=Mulish&display=swap",
  },
];

const options = {
  // passing the client secret obtained from the server
  // clientSecret: clientSecret || "",
  fonts: stripeFonts,
};

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_KEY ||
    "pk_test_51H7XqhIXwp5ovB54AxG4YgHyGzIBhm8hUdlekd8jNoSTF4hMNqBDSYesY3AlUIrpZPIDWH3Tb5GzjwheatvbOby7009gtMi85E"
);

ReactDOM.render(
  <>
    <ThemeProvider theme={theme}>
      <SWRConfig
        value={{
          fetcher,
        }}
      >
        <AlertContextProvider>
          <Elements stripe={stripePromise} options={options}>
            <App />
          </Elements>
        </AlertContextProvider>
      </SWRConfig>
    </ThemeProvider>
  </>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
