import { Box, Typography } from "@material-ui/core";
import { AxiosError } from "axios";
import { isNil } from "lodash-es";
import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import useSWR from "swr";
import api from "../api/instance";
import { addressForProperty, Property, User, UserType } from "../api/models";
import AlertWrapper from "../components/AlertWrapper";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import OrganisationLogo from "../components/OrganisationLogo";
import { Colors } from "../constants/colors";
import { useAlert } from "../contexts/Alert";
import { useMobile } from "../hooks/mobile";
import BLUELOGO from "../images/Blue.png";
import InfoIcon from "../images/info-thick.svg";
import PDFIcon from "../images/pdf-icon.png";
import { useSessionStore } from "../stores/Session";

interface Params {
  uuid?: string;
}

export default function SaleContractAvailable(): JSX.Element {
  const location = useLocation();
  const history = useHistory();

  const { uuid } = useParams<Params>();

  const query = new URLSearchParams(location.search);
  const propertyId = query.get("propertyId");

  const { data: propertyData } = useSWR(`/view-properties/${propertyId}/`);
  const property = propertyData && (propertyData as Property);

  const { data: userData, refresh: refreshUserData } = useSessionStore();
  const user = userData && (userData as User);

  React.useEffect(() => {
    if (user && user.user_type === UserType.Agent) {
      history.push("/dashboard?agentBuyError=true");
    }
  }, [user]);

  const [loading, setLoading] = React.useState(false);

  const isMobile = useMobile();
  const { setErrorMessage } = useAlert();

  function Header() {
    return (
      <Box width={"100%"} mt={isMobile ? 3 : 22} mb={isMobile ? 4 : 7}>
        <Typography variant="overline">STEP 2 OF 3</Typography>
        <Typography
          variant={isMobile ? "h5" : "h4"}
          style={{ marginTop: "8px", marginBottom: "8px" }}
        >
          Sale contract available
        </Typography>

        <Box
          width="100%"
          bgcolor={Colors.WarningBackground}
          display="flex"
          flexDirection={"row"}
          alignItems="center"
          pt="11px"
          pb="11px"
          pl="20px"
          pr="20px"
          boxSizing="border-box"
        >
          <img src={InfoIcon} height="14px" style={{ marginRight: "10px" }} />
          <Typography variant="caption" style={{ color: Colors.Grey2 }}>
            It appears a sale contract already exists in our records for this
            property.
          </Typography>
        </Box>
      </Box>
    );
  }

  function Content() {
    return !isNil(property) ? (
      <Box
        width={"100%"}
        bgcolor={Colors.Grey5}
        borderRadius="8px"
        boxSizing={"border-box"}
        pl="19px"
        pr="19px"
        pt="16px"
        pb="16px"
        display="flex"
        flexDirection={"row"}
      >
        <img src={PDFIcon} />
        <Box display="flex" flexDirection={"column"}>
          <Typography
            variant="caption"
            style={{ fontWeight: 700, marginBottom: "2px" }}
          >
            Contract of sale
          </Typography>
          <Typography variant="caption">
            {addressForProperty(property).replace(/[\W_]+/g, "_")}.pdf
          </Typography>
        </Box>
      </Box>
    ) : (
      <></>
    );
  }

  function onSubmit() {
    // Add record for user
    setLoading(true);

    const form = new FormData();
    property.id && form.append("property_id", property.id.toString());

    const path = `/public-contracts/?uuid=${uuid}&version=v2`;

    api
      .post(path, form)
      .then((response) => {
        history.push(`/org/${uuid}/thankyou?propertyId=${property.id}`);
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        setErrorMessage(error.message);
      });
  }

  return (
    <AlertWrapper>
      {!isMobile && (
        <>
          <Box
            mb={4}
            display="flex"
            flexDirection="row"
            width="100%"
            height={88}
            position="absolute"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box ml={4}>
              <OrganisationLogo uuid={uuid} />
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Box
              display="flex"
              flexDirection="column"
              marginLeft="auto"
              marginRight="auto"
              alignItems={isMobile ? "center" : undefined}
              mb={8}
              width="725px"
            >
              <Header />

              <Content />

              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                mt={6}
              >
                <Button
                  type={ButtonType.dark}
                  size={ButtonSize.large}
                  title={"SUBMIT"}
                  width={"124px"}
                  loading={loading}
                  onClick={onSubmit}
                />
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            height={88}
            position="fixed"
            alignItems="center"
            justifyContent="space-between"
            bottom="0"
          >
            <Box ml={4}>
              <Typography variant="body2">Powered by</Typography>
              <Box>
                <img src={BLUELOGO} height="32px" />
              </Box>
            </Box>
          </Box>
        </>
      )}

      {isMobile && (
        <>
          <Box
            mb={4}
            display="flex"
            flexDirection="row"
            width="100%"
            height={72}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box ml={0}>
              <OrganisationLogo uuid={uuid} />
            </Box>
          </Box>
          <Box ml={3.25} mr={3.25}>
            <Header />
            <Content />
          </Box>

          <MobileStickyFooter
            buttonsLayout={FooterButtonsLayout.centeredDark}
            buttonTitle={"SUBMIT"}
            buttonLoading={loading}
            buttonOnClick={onSubmit}
          />
        </>
      )}
    </AlertWrapper>
  );
}
