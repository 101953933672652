import React from "react";
import { Box, Typography } from "@material-ui/core";
import Header from "../components/Dashboard/Header";
import { Colors } from "../constants/colors";
import { useMobile } from "../hooks/mobile";
import { useSessionStore } from "../stores/Session";
import { useAlert } from "../contexts/Alert";
import { User, MyTeamMember, UserType } from "../api/models";
import Button, { ButtonType, ButtonSize } from "../components/common/Button";
import SearchField from "../components/common/SearchField";
import AlertWrapper from "../components/AlertWrapper";
import MyTeamItem from "../components/MyTeam/MyTeamItem";
import HelpGuideModal from "../components/HelpGuide/HelpGuideModal";
import HelpGuideModalItem from "../components/HelpGuide/HelpGuideModalItem";
import BLHelp1 from "../images/uploadHelpGuide.png";
import BLHelp2 from "../images/inviteModalHelpGuide.png";
import BLHelp3 from "../images/colorHelpGuide.png";
import AddMyTeamMemberModal from "../components/MyTeam/AddMyTeamMemberModal";
import useSWR from "swr";

export default function MyTeam() {
  const isMobile = useMobile();

  const sidePadding = isMobile ? "27px" : "172px";

  const { setSuccessMessage } = useAlert();

  const { data: userData } = useSessionStore();
  const user = userData && (userData as User);

  const isPartner = user?.user_type === UserType.Partner;

  const isSolicitor  = user?.user_type === UserType.Solicitor;

  const { data: teamMembersData, mutate: teamMembersMutate } = useSWR(
    "/organization-member/"
  );
  const teamMembers = teamMembersData
    ? (teamMembersData as MyTeamMember[])
    : [];

  const _helpModalTitle = () => {
    return "How to use My Team";
  };

  const _helpModalItems = () => {
    return (
      <>
        <HelpGuideModalItem
          index={1}
          title="Search for a property, upload the contract and create your workspace"
          image={BLHelp1}
          imageMarginTop="32px"
        />
        <Box width="6px" />
        <HelpGuideModalItem
          index={2}
          title="Invite your team to Contrax"
          image={BLHelp2}
          imageMarginTop="32px"
        />
        <Box width="6px" />
        <HelpGuideModalItem
          index={3}
          title="Personalise your workspace and landing page with your logo and brand colour"
          image={BLHelp3}
          imageMarginTop="32px"
        />
      </>
    );
  };

  const helpModalTitle = _helpModalTitle();
  const helpModalItems = _helpModalItems();

  const [helpModalOpen, setHelpModalOpen] = React.useState(false);

  const [addTeamMemberDialogOpen, setaddTeamMemberDialogOpen] =
    React.useState(false);

  const [search, setSearch] = React.useState("");

  const onAddTeamMember = () => {
    setaddTeamMemberDialogOpen(true);
  };

  function TitleBar() {
    if (isMobile) {
      return (
        <Box
          display="flex"
          flexDirection="row"
          bgcolor={Colors.BrandBackground2}
          pl={sidePadding}
          pr={sidePadding}
          justifyContent="center"
          alignItems="center"
        >
          <Box flex={1}>
            <Box
              flex={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              minHeight="86px"
            >
              <Typography
                variant="h4"
                style={{ color: Colors.Grey1, fontWeight: 400 }}
              >
                My Team
              </Typography>
            </Box>

            <Box>
              {!isPartner && (
                <Box mb="8px">
                  <Button
                    width="100%"
                    type={ButtonType.white}
                    size={ButtonSize.medium}
                    title="HELP GUIDE"
                    onClick={() => setHelpModalOpen(true)}
                  />
                </Box>
              )}
              <Button
                width="100%"
                type={ButtonType.primary}
                size={ButtonSize.medium}
                title="ADD TEAM MEMBER"
                onClick={onAddTeamMember}
              />
            </Box>

            <Box height="20px" />
          </Box>
        </Box>
      );
    }

    return (
      <Box
        display="flex"
        flexDirection="row"
        bgcolor={Colors.BrandBackground2}
        pl={sidePadding}
        pr={sidePadding}
        justifyContent="center"
        alignItems="center"
      >
        <Box flex={1}>
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            minHeight="108px"
          >
            <Typography
              variant="h4"
              style={{ color: Colors.Grey1, fontWeight: 400 }}
            >
              My Team
            </Typography>
          </Box>
        </Box>
        {!isPartner && (
          <Box mr="8px">
            <Button
              type={ButtonType.white}
              size={ButtonSize.medium}
              title="HELP GUIDE"
              onClick={() => setHelpModalOpen(true)}
            />
          </Box>
        )}

        <Box>
          <Button
            type={ButtonType.primary}
            size={ButtonSize.medium}
            title="ADD TEAM MEMBER"
            onClick={onAddTeamMember}
          />
        </Box>
      </Box>
    );
  }

  return (
    <>
      <AlertWrapper>
        <Box>
          <Header />

          <TitleBar />

          {isMobile && (
            <Box
              mt="12px"
              ml="11px"
              mr="11px"
              display="flex"
              flexDirection="row"
            >
              <SearchField
                value={search}
                onTextChanged={(value) => {
                  setSearch(value);
                }}
              />
            </Box>
          )}

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            {!isMobile && (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                width="1095px"
                mb="8px"
              >
                <SearchField
                  value={search}
                  placeholder="Search Team"
                  onTextChanged={(value) => {
                    setSearch(value);
                  }}
                />
              </Box>
            )}

            {teamMembers
              .filter((teamData) => {
                if (search === "") {
                  return teamData;
                } else if (
                  teamData.first_name
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                  teamData.last_name
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                  teamData.email.toLowerCase().includes(search.toLowerCase())
                ) {
                  return teamData;
                }
              })
              .map((teamData) => (
                <MyTeamItem
                  key={`teamData-${teamData.pk}`}
                  teamData={teamData}
                  onUpdated={() => teamMembersMutate()}
                />
              ))}
          </Box>
        </Box>

        <AddMyTeamMemberModal
          open={addTeamMemberDialogOpen}
          onClose={() => setaddTeamMemberDialogOpen(false)}
          onInvited={(firstName, lastName) => {
            setaddTeamMemberDialogOpen(false);
            setSuccessMessage(
              `${firstName} ${lastName} has been invited to your team`
            );
            teamMembersMutate();
          }}
          isPartner={isPartner}
          isSolicitor={isSolicitor}
        />

        <HelpGuideModal
          open={helpModalOpen}
          title={helpModalTitle}
          onClose={() => setHelpModalOpen(false)}
          items={helpModalItems}
        />
      </AlertWrapper>
    </>
  );
}
