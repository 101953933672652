import React from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { useSessionStore } from "../stores/Session";
import { User } from "../api/models";
import TextField from "../components/common/TextField";
import { isEmpty } from "lodash-es";
import api from "../api/instance";
import { useAlert } from "../contexts/Alert";
import { AxiosError } from "axios";
import AlertWrapper from "../components/AlertWrapper";

interface Props {
  isSend?: boolean;
  onClose: () => unknown;
  property: any;
  name: any;
  openConfirmation: any;
  resetIsSend?: any;
}

export default function InviteBuyer({
  isSend,
  property,
  name,
  resetIsSend,
  onClose,
  openConfirmation,
}: Props): JSX.Element {
  const { data: userData } = useSessionStore();
  const user = userData && (userData as User);

  const [firstName, setFirstName] = React.useState("");
  const [firstNameError, setFirstNameError] = React.useState<null | string>(
    null
  );

  const [lastName, setLastName] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState<null | string>(null);

  const [phone, setPhone] = React.useState("");
  const [phoneError, setPhoneError] = React.useState<null | string>(null);

  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState<null | string>(null);

  const [, setLoading] = React.useState(false);

  const [successMsg, setSuccussMsg] = React.useState(false);

  const { setSuccessMessage, setErrorMessage } = useAlert();

  React.useEffect(() => {
    if (isSend) {
      saveClicked();
    }
  }, [isSend]);

  React.useEffect(() => {
    if (successMsg) {
      onClose();
      openConfirmation(true);
    }
  });

  if (!user) {
    return <CircularProgress />;
  }

  function resetErrors() {
    setFirstNameError(null);
    setLastNameError(null);
    setPhoneError(null);
    setEmailError(null);
  }

  function verifyFields() {
    let allFieldsValid = true;

    if (isEmpty(firstName)) {
      setFirstNameError("Please enter your first name");
      allFieldsValid = false;
    }
    if (isEmpty(lastName)) {
      setLastNameError("Please enter your last name");
      allFieldsValid = false;
    }
    if (isEmpty(phone)) {
      setPhoneError("Please enter your phone number");
      allFieldsValid = false;
    }

    if (isEmpty(email)) {
      setEmailError("Please enter your email address");
      allFieldsValid = false;
    }

    return allFieldsValid;
  }

  function saveClicked() {
    resetErrors();
    resetIsSend(false);

    if (!verifyFields()) {
      return;
    }

    setLoading(true);

    const data = {
      first_name: firstName,
      last_name: lastName,
      phone,
      email,
    };

    name(firstName + " " + lastName);

    api
      .post(`/invite-buyer/?contract_id=${property.contract_id}`, data)
      .then(() => {
        setLoading(false);
        setSuccessMessage("The invite has been send successfully");
        setTimeout(() => {
          setSuccussMsg(true);
        }, 5000);
      })
      .catch((error: AxiosError) => {
        setLoading(false);

        const errors = error && error.response && error.response.data;
        setErrorMessage(errors.message);
        const { first_name, last_name, phone, email } = errors;

        first_name && setFirstNameError(first_name[0]);
        last_name && setLastNameError(last_name[0]);
        phone && setPhoneError(phone[0]);
        email && setEmailError(email[0]);
      });
  }

  return (
    <AlertWrapper>
      <Box display="flex" flexDirection="column" flex={1}>
        <TextField
          title="First name"
          value={firstName}
          error={firstNameError !== null}
          helperText={firstNameError}
          onChange={(value) => setFirstName(value)}
        />
        <Box mt={2} />
        <TextField
          title="Last name"
          value={lastName}
          error={lastNameError !== null}
          helperText={lastNameError}
          onChange={(value) => setLastName(value)}
        />
        <Box mt={2} />
        <TextField
          title="Email"
          value={email}
          error={emailError !== null}
          helperText={emailError}
          onChange={(value) => setEmail(value)}
        />
        <Box mt={2} />
        <TextField
          title="Phone number"
          type="phone"
          value={phone}
          error={phoneError !== null}
          helperText={phoneError}
          onChange={(value) => setPhone(value)}
        />
      </Box>
    </AlertWrapper>
  );
}
