import React from "react";
import { Box, Typography, Dialog } from "@material-ui/core";
import Button, { ButtonType, ButtonSize } from "./common/Button";
import Separator from "./common/Separator";
import Icon from "../images/you-have-finalised-icon.svg";
import { useMobile } from "../hooks/mobile";

interface Props {
  open: boolean;
  onClose: () => unknown;
  content: string | React.ReactNode;
}

export default function AddAMessageSentDialog({
  open,
  onClose,
  content,
}: Props) {
  const isMobile = useMobile();

  return (
    <Dialog
      open={open}
      aria-labelledby="you-have-finalised-dialog"
      aria-describedby="you-have-finalised-description"
      fullScreen={isMobile}
    >
      <Box
        width={isMobile ? "100%" : "514px"}
        display="flex"
        flexDirection="column"
      >
        <Box
          display="flex"
          flexDirection="column"
          pt="37px"
          alignItems="center"
        >
          <img src={Icon} style={{ width: "100px", height: "100px" }} />

          <Typography
            variant="h6"
            style={{
              marginTop: "32px",
              marginBottom: "60px",
              whiteSpace: "pre-line",
              width: isMobile ? "90vw" : "279px",
              textAlign: "center",
            }}
          >
            {content}
          </Typography>
        </Box>

        <Separator />
        <Box
          display="flex"
          justifyContent="flex-end"
          pl="24px"
          pr="16px"
          height="74px"
          alignItems="center"
        >
          <Button
            title="CLOSE"
            size={ButtonSize.medium}
            type={ButtonType.primary}
            onClick={onClose}
            width="100%"
          />
        </Box>
      </Box>
    </Dialog>
  );
}
