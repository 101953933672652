import React from "react";
import { Box, Dialog, Typography } from "@material-ui/core";
import { useMobile } from "../../hooks/mobile";
import Button, { ButtonType, ButtonSize } from "../common/Button";
import CloseButton, { CloseButtonColor } from "../common/CloseButton";
import api from "../../api/instance";
import { useAlert } from "../../contexts/Alert";
import { parseApiError } from "../../helpers/error";
import { useLocation } from "react-router";
import useSWR from "swr";

interface Props {
  open: boolean;
  onClose: () => unknown;
  orgUuid: string;
}

interface ItemProps {
  title: string;
  description: string;
  actionComponent: React.ReactNode;
}

function Item({ title, description, actionComponent }: ItemProps) {
  return (
    <Box
      borderRadius="8px"
      border="1px solid #91A8EF"
      p="16px"
      width="296px"
      height="270px"
      textAlign="center"
      display="flex"
      flexDirection="column"
      boxSizing="border-box"
    >
      <Typography
        variant="h6"
        style={{
          marginLeft: "16px",
          marginRight: "16px",
          fontWeight: 700,
          height: "24px",
          marginBottom: "30px",
        }}
      >
        {title}
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        flex={1}
      >
        <Typography
          variant="body1"
          style={{ flex: 1, marginLeft: "16px", marginRight: "16px" }}
        >
          {description}
        </Typography>
      </Box>

      {actionComponent}
    </Box>
  );
}

export default function FirmModal({ open, onClose, orgUuid }: Props) {
  const isMobile = useMobile();
  const location = useLocation();

  const { setErrorMessage, setSuccessMessage } = useAlert();

  const query = new URLSearchParams(location.search);
  const organisationName = query.get("organisation_name") || "";
  const token_expiry = query.get("link_token");

  const [joining, setJoining] = React.useState(false);
  const [rejecting, setRejecting] = React.useState(false);

  const { data, mutate: contractsMutate } = useSWR("buying-contracts/");

  const onSubmit = (join: boolean) => {
    if (join) {
      setJoining(true);
    } else {
      setRejecting(true);
    }

    const path = `/join-organization/`;

    api
      .post(path, {
        organization_uuid: orgUuid,
        status: join ? "accept" : "reject",
      })
      .then((response) => {
        api.get(`/link-token-expire/${token_expiry}/`).then((response) => {
          console.log("FINISHED", response.data);
        });

        if (join === true) {
          setSuccessMessage("You have joined this firm");
        } else {
          setSuccessMessage("You declined joining this firm");
        }
        onClose();

        setJoining(false);
        setRejecting(false);

        contractsMutate();

        // TODO: Refresh logo?
      })
      .catch((error) => {
        setJoining(false);
        setRejecting(false);
        setErrorMessage(parseApiError(error));
      });
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="help-guide-dialog"
      aria-describedby="help-guide-dialog-description"
      // onClose={onClose}
      maxWidth="xl"
      fullScreen={isMobile}
    >
      <Box>
        <Box
          width={isMobile ? "100%" : "664px"}
          height="62px"
          display="flex"
          flexDirection="row"
          pl="24px"
          pr="24px"
          alignItems="center"
          boxShadow="0px 1px 0px #E0E0E0"
          boxSizing="border-box"
          justifyContent="space-between"
        >
          <Typography variant="h5" style={{ fontWeight: 700 }}>
            Join your teammates on Contrax
          </Typography>

          {/* <CloseButton color={CloseButtonColor.BLACK} onClick={onClose} /> */}
        </Box>
        <Box
          width="664px"
          height="62px"
          display="flex"
          boxSizing="border-box"
          alignItems="center"
          pl="24px"
          pr="24px"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Typography variant="body1">
            You’ve been invited to <b> {organisationName} </b>. Join, or don't
            join and keep things as they are.
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          p="24px"
          alignItems="center"
        >
          <Item
            title={`Join ${organisationName}`}
            description="You'll still be able to access any workspaces currently assigned to you."
            actionComponent={
              <Button
                disabled={rejecting}
                loading={joining}
                title="JOIN"
                type={ButtonType.primary}
                size={ButtonSize.medium}
                width="264px"
                height={66}
                onClick={() => onSubmit(true)}
              />
            }
          />

          <Box width="24px" height="24px" />

          <Item
            title="Don’t join"
            description="Keep things as they are."
            actionComponent={
              <Button
                disabled={joining}
                loading={rejecting}
                title="DON'T JOIN"
                type={ButtonType.primary}
                size={ButtonSize.medium}
                width="264px"
                height={66}
                onClick={() => onSubmit(false)}
              />
            }
          />
        </Box>
      </Box>
    </Dialog>
  );
}
