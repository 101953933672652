import React from "react";
import {
  Menu,
  MenuItem,
  Box,
  Typography,
  ButtonBase,
  withStyles,
} from "@material-ui/core";
import ArrowIcon from "../../images/chevron-down.svg";
import { Colors } from "../../constants/colors";
import DropdownIcon from "../../images/dropdown-icon.svg";
import DropdownIconSelected from "../../images/dropdown-icon-selected.svg";
import ChevronRight from "../../images/chevron-right.svg";
import { useMobile } from "../../hooks/mobile";

export interface DropdownOption {
  title: string;
  value: any;
}

export enum DropdownViewType {
  Text,
  Icon,
}

interface Props {
  title?: string;
  viewType?: DropdownViewType;
  options: DropdownOption[];
  selectedOption?: DropdownOption | null;
  onSelectedOption?: (option: DropdownOption) => unknown;
  width?: number | string;
  footer?: React.ReactNode;
}

const MyMenuItem = withStyles({
  root: {
    "&:hover": {
      backgroundColor: Colors.BrandBackground2,
      color: Colors.BrandPrimary,
    },
    fontFamily: "GT Walsheim Pro",
    fontWeight: 400,
    fontSize: "16px",
    height: "40px",
    width: "241px",
  },
})(MenuItem);

export default function Dropdown({
  title,
  viewType = DropdownViewType.Text,
  options,
  selectedOption,
  onSelectedOption,
  width = "100%",
  footer,
}: Props) {
  const isMobile = useMobile();
  const [menuEl, setMenuEl] = React.useState<any>(null);

  return (
    <Box width={width}>
      {viewType === DropdownViewType.Text && (
        <Box display="flex" flexDirection="row" alignItems="center">
          {title && (
            <Typography variant="body2" style={{ width: "80px" }}>
              {title}
            </Typography>
          )}
          <ButtonBase
            onClick={(event) => setMenuEl(event.currentTarget)}
            style={{ width: "100%" }}
          >
            <Box
              border="1px solid #E8E8E8"
              borderRadius="2px"
              display="flex"
              flexDirection="row"
              height="42px"
              width="100%"
              alignItems="center"
              textAlign="left"
              pl="16px"
              pr="12px"
              boxSizing="border-box"
            >
              <Typography variant="body1" style={{ flex: 1 }}>
                {selectedOption ? selectedOption.title : "Select..."}
              </Typography>

              <img src={ArrowIcon} />
            </Box>
          </ButtonBase>
        </Box>
      )}

      {viewType === DropdownViewType.Icon && (
        <ButtonBase
          onClick={(event) => setMenuEl(event.currentTarget)}
          style={{ width: "48px", height: "48px" }}
        >
          {menuEl ? (
            <img src={DropdownIconSelected} />
          ) : (
            <img src={DropdownIcon} />
          )}
        </ButtonBase>
      )}

      <Menu
        anchorEl={menuEl}
        keepMounted
        open={Boolean(menuEl)}
        onClose={() => setMenuEl(null)}
        getContentAnchorEl={null}
        style={{ top: "56px" }}
      >
        {isMobile && title && (
          <Typography
            style={{
              marginTop: "12px",
              marginBottom: "12px",
              marginLeft: "16px",
              marginRight: "16px",
            }}
            variant="body2"
          >
            {title}
          </Typography>
        )}

        {options.map((option) => (
          <Box display="flex" flexDirection="row" pl="8px" pr="12px">
            <MyMenuItem
              onClick={() => {
                onSelectedOption && onSelectedOption(option);
                setMenuEl(null);
              }}
            >
              {option.title}
            </MyMenuItem>
            {isMobile && <img src={ChevronRight} />}
          </Box>
        ))}

        {footer && <Box border="1px solid #E8E8E8" />}

        {footer}
      </Menu>
    </Box>
  );
}
