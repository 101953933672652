import React from "react";
import { Box, Typography } from "@material-ui/core";
import BLUELOGO from "../../images/BlueLogo.png";

export default function ModalHeader() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      mt="40px"
      mb="40px"
    >
      <Typography variant="h6" style={{ fontWeight: 700 }}>
        Welcome to
      </Typography>

      <img src={BLUELOGO} height="40px" />
    </Box>
  );
}
