import React from "react";
import { Box, Link, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useMobile, useDesktop } from "../hooks/mobile";
import { Colors } from "../constants/colors";
import AlertWrapper from "./AlertWrapper";
import CloseButton, { CloseButtonColor } from "./common/CloseButton";
import DashboardHeaderMobile from "../components/DashboardHeaderMobile";

import { Property, addressForProperty } from "../api/models";
import useSWR from "swr";
import PropertyDetailsHeader from "./PropertyDetailsHeader";

interface LayoutProps
  extends React.PropsWithChildren<{
    propertyId: number;
    shareLinkDialog?:any;
    inviteBuyerDialog?:any;
    inviteConfirmation?:any;
  }> {}
export default function PropertyLayoutMobile({
  children,
  propertyId,
  shareLinkDialog,
  inviteBuyerDialog,
  inviteConfirmation
}: LayoutProps): JSX.Element {
  return (
    <Box>
      {shareLinkDialog && shareLinkDialog()}
      {inviteBuyerDialog && inviteBuyerDialog()}
      {inviteConfirmation && inviteConfirmation()}
      <DashboardHeaderMobile />

      <PropertyDetailsHeader
        propertyId={+propertyId}
        bgcolor={Colors.BrandBackground2}
      />
      {children}
    </Box>
  );
}
