import React, { useEffect } from "react";
import { Box, Typography, Link } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import NotificationMenuItem from "./NotificationMenuItem";
import NoProperties from "../../images/no-properties.png";
import useSWR from "swr";
import { useHistory } from "react-router";
import api from "../../api/instance";
import { useAlert } from "../../contexts/Alert";
import { parseApiError } from "../../helpers/error";

interface Props {
  handleClose: () => unknown;
}

export default function ({ handleClose }: Props) {
  const { data } = useSWR(`contract/notifications/`);
  const notificationItems = data && data.results;

  const history = useHistory();

  const { setErrorMessage } = useAlert();

  function EmptyPlaceholder() {
    return (
      <Box
        bgcolor={Colors.Grey5}
        display="flex"
        justifyContent="center"
        flexDirection="row"
        textAlign="center"
        margin={3}
        padding={2}
        borderRadius="10px"
      >
        <img src={NoProperties} height="60px" alt="no prop" />
        <Typography
          variant="body1"
          style={{
            marginTop: "24px",
            color: Colors.BrandBackground1,
          }}
        >
          No Notifications
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
        margin="15px"
        padding="5px"
      >
        <Box>
          <Typography style={{ fontWeight: 700, lineHeight: "18px" }}>
            Notifications
          </Typography>
        </Box>
        {notificationItems && notificationItems.length !== 0 && (
          <Box>
            <Link
              style={{ color: Colors.Link, cursor: "pointer" }}
              onClick={() => {
                history.push(`/notification-history`);

                api
                  .post(`/contract/notification-update/?all=true`)
                  .then((response) => {
                    console.debug("response: ", response);
                  })
                  .catch((error) => {
                    console.debug("error: ", error);
                    setErrorMessage(parseApiError(error));
                  });
              }}
            >
              <Typography style={{ fontWeight: 700, marginLeft: "9px" }}>
                View All
              </Typography>
            </Link>
          </Box>
        )}
      </Box>
      {notificationItems &&
        notificationItems
          .slice(0, 7)
          .map((activity) => (
            <NotificationMenuItem dropdown={true} activity={activity} />
          ))}
      {notificationItems && notificationItems.length === 0 && (
        <EmptyPlaceholder />
      )}
    </Box>
  );
}
