import React from "react";
import { Box, Link, Typography, ButtonBase } from "@material-ui/core";
import { MyPropertyStats } from "../api/models";
import { Colors } from "../constants/colors";
import InviteImage from "../images/invite.svg";
import ViewedImage from "../images/view.svg";
import PurchaseImage from "../images/purchase.svg";

import { useMobile, useDesktop } from "../hooks/mobile";



interface Props {
  property: MyPropertyStats;
}

function StatusBadge({ status }: any) {
  return (
    <Box
      borderRadius="14px"
      bgcolor="white"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        color={Colors.BrandPrimary}
        textAlign="center"
        fontFamily="GT Walsheim Pro"
        fontStyle="normal"
        fontWeight={700}
        fontSize="12px"
        p={0.5}
        lineHeight="16px"
        letterSpacing="2px"
      >
        {status === 'Invite Sent' && <span><img src={InviteImage} />INVITE SENT</span>}
        {status === 'Invite Accepted' && <span><img src={InviteImage} />INVITE ACCEPTED</span>}
        {status === 'Viewed' && <span><img src={ViewedImage} /> VIEWED</span>}
        {status === 'Purchased' && <span><img src={PurchaseImage} /> PURCHASED</span>}

      </Box>
    </Box>
  );
}

export default function MyPropertyRowStats({
  property,
}: Props): JSX.Element {

  const isMobile = useMobile();
  const isDesktop = useDesktop();

  if (!property) {
    return <Box />;
  }
  return (
    <Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        bgcolor={Colors.Grey5}
        pl={1}
        pr={2}
        pt={1}
        pb={1}
        mb={1}
        flex={1}
      >
        <Box ml={2} flexGrow={1} display="flex" flexDirection="column">
          <Typography variant="body1" style={{ marginTop: "8px" }}>
            {property.user && property.user.first_name} {property.user && property.user.last_name}
          </Typography>
          <Typography variant="caption">
            {property.user && property.user.email}
          </Typography>
          {isMobile &&
            <Typography variant="caption" style={{ marginTop: "4px" }}>
              {property.user && property.user.phone}
            </Typography>
          }
        </Box>
        {isDesktop && 
          <Box flexGrow={1} display="flex" flexDirection="column">
          <Typography variant="caption">
            {property.user && property.user.phone}
          </Typography>
        </Box>}
        <Box display="flex" flexDirection="column">
          <StatusBadge status={property.status} />
          <Box mt={1}/>
          <Typography align="right" variant="caption">
            {property.modified}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
