import React from "react";
import { Box, Typography, BoxProps } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import Button, { ButtonType, ButtonSize } from "../common/Button";
import { isEmpty } from "lodash-es";
import { CreditPackage } from "../../api/models";
import { useSessionStore } from "../../stores/Session";
import { isNil } from "lodash-es";

interface Props extends BoxProps {
  pack: CreditPackage;
  onPurchase: () => unknown;
}

export default function CreditPackItem({ pack, onPurchase, ...rest }: Props) {
  const { data: user } = useSessionStore();

  const hasOrg = !isNil(user?.current_organization);

  const neverExpireMsg = React.useMemo(() => {
    return hasOrg
      ? "Contract credits never expire and can be used at any time."
      : "Credits never expire, and you can offset any remaining against your final conveyancing fee.";
  }, [hasOrg]);

  return (
    <Box {...rest}>
      <Box
        width="100%"
        height="32px"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {pack.is_recommended && (
          <Box
            bgcolor={Colors.BrandBackground2}
            borderRadius="8px 8px 0px 0px"
            width="213px"
            height="100%"
            color={Colors.BrandPrimary}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="overline" style={{ letterSpacing: "0.1em" }}>
              MOST POPULAR
            </Typography>
          </Box>
        )}
      </Box>

      <Box
        borderRadius="8px"
        width="288px"
        // height="436px"
        position="relative"
        boxShadow={
          "0px 4px 8px rgba(0, 0, 0, 0.25), 0px 1px 0px rgba(0, 0, 0, 0.1)"
        }
        overflow="hidden"
      >
        <Box
          width="100%"
          height="68px"
          bgcolor={Colors.BrandPrimary}
          color="white"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h6" style={{ fontWeight: 700 }}>
            {pack.credit_amount} contracts
          </Typography>
        </Box>

        <Box
          width="83px"
          height="24px"
          position="absolute"
          borderRadius="16px"
          bgcolor={Colors.SuccessBackground}
          top="56px"
          left={0}
          right={0}
          margin="auto"
          display="flex"
          alignItems="center"
          justifyContent="center"
          color={Colors.UISuccess}
        >
          <Typography variant="overline">{pack.discount}% OFF</Typography>
        </Box>

        <Box
          width="100%"
          height="100%"
          pt="44px"
          boxSizing="border-box"
          pl="16px"
          pr="16px"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Typography variant="h4">
            {/* NOTE: Remove decimal part, add fraction commas */}$
            {Math.trunc(pack.total_units / 100).toLocaleString("en-US")}
          </Typography>

          <Box mt="32px" textAlign={"center"}>
            <Typography>
              {pack.credit_amount} contracts included
              <br />
              SAVE {pack.discount}%
            </Typography>
          </Box>

          <Box border="1px solid #ECF2FB" width="100%" mt={4.75} mb={2} />

          <Box textAlign={"center"}>
            <Typography>{neverExpireMsg}</Typography>
          </Box>

          <Box mt={5} mb={2} width="100%">
            <Button
              title={`BUY ${pack.credit_amount} PACK`}
              type={ButtonType.primary}
              size={ButtonSize.medium}
              sidePadding={1}
              width="100%"
              onClick={onPurchase}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
