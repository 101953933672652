import { Box, Dialog, Typography } from "@material-ui/core";
import { isEmpty, isNil } from "lodash-es";
import React from "react";
import {
  BuyerSellerStatus,
  LegalReviewRequest,
  LegalReviewRequestStage,
  LegalReviewRequestStatus,
} from "../../api/models";
import { Colors } from "../../constants/colors";
import { useMobile } from "../../hooks/mobile";
import RadioWithTitle from "../common/RadioWithTitle";
import Separator from "../common/Separator";
import StatusPill, { statusPillTypeForRequest } from "../StatusPill";
import moment from "moment";
import Button, { ButtonType, ButtonSize } from "../common/Button";
import api from "../../api/instance";
import { parseApiError } from "../../helpers/error";
import { useAlert } from "../../contexts/Alert";
import YouHaveUnsavedChangesDialog from "./YouHaveUnsavedChangesDialog";

interface Props {
  open: boolean;
  contractId: number | null;
  recordId: number | null;
  request: LegalReviewRequest | null;
  onClose: () => unknown;
  onSaved: () => unknown;
}

export default function RespondRequestDialog({
  open,
  contractId,
  recordId,
  request,
  onClose,
  onSaved,
}: Props) {
  const isMobile = useMobile();
  const [stage, setStage] = React.useState<LegalReviewRequestStage | null>(
    null
  );

  const [alternative, setAlternative] = React.useState("");
  const [message, setMessage] = React.useState("");

  const [saving, setSaving] = React.useState(false);
  const [edited, setEdited] = React.useState(false);

  const [unsavedDialogOpen, setUnsavedDialogOpen] = React.useState(false);

  const isSent = !isNil(request?.sent_on);
  const isPublished = request?.status === LegalReviewRequestStatus.published;
  const isOutstanding =
    request?.request_status === LegalReviewRequestStage.pending;

  const { setErrorMessage } = useAlert();

  React.useEffect(() => {
    resetFields();
  }, [request]);

  const resetFields = () => {
    if (request) {
      setStage(request.request_status);
      setAlternative(request.alternate_change);
      setMessage(request.seller_message);
      setEdited(false);
    }
  };

  const sentOrCompleted =
    request?.seller_status === BuyerSellerStatus.Sent ||
    request?.seller_status === BuyerSellerStatus.Completed;

  const onSave = () => {
    if (request) {
      setSaving(true);

      const { buyer_request_pk, status, index } = request;

      const data = [
        {
          pk: buyer_request_pk,
          alternate_change: isEmpty(alternative) ? null : alternative,
          seller_message: message,
          seller_status:
            stage === LegalReviewRequestStage.pending
              ? LegalReviewRequestStatus.editing
              : LegalReviewRequestStatus.published,
          status: stage,
          seller_responded: true,
          index,
          type: "Request",
        },
      ];

      api
        .post(`/contract/${contractId}/record/${recordId}/seller-response/`, {
          data,
        })
        .then((response) => {
          setSaving(false);

          onSaved();
        })
        .catch((error) => {
          setSaving(false);

          setErrorMessage(parseApiError(error));
        });
    }
  };

  const p_onClose = () => {
    if (sentOrCompleted || !edited) {
      resetFields();
      onClose();
    } else {
      setUnsavedDialogOpen(true);
    }
  };

  return (
    request && (
      <>
        <Dialog
          open={open}
          aria-labelledby="add-a-message-dialog"
          aria-describedby="add-a-message-description"
          fullScreen={isMobile}
          onClose={p_onClose}
        >
          <Box
            width={isMobile ? "100%" : "524px"}
            display="flex"
            flexDirection="column"
          >
            <Box
              height="62px"
              display="flex"
              flexDirection="row"
              alignItems="center"
              pl="24px"
              pr="24px"
            >
              <Typography variant="h5" style={{ fontWeight: 700, flex: 1 }}>
                {request.title}
              </Typography>

              <StatusPill type={statusPillTypeForRequest(request)} />
            </Box>

            <Separator />

            <Box p="16px">
              <Box p="8px" mb="16px">
                <Typography
                  variant="body1"
                  style={{ fontWeight: 700, marginBottom: "8px" }}
                >
                  Amendment Request
                </Typography>
                <Typography variant="body2">{request.description}</Typography>
              </Box>

              <Box
                bgcolor={
                  request.request_status ===
                  LegalReviewRequestStage.alternativeSuggested
                    ? Colors.Grey4
                    : Colors.BrandBackground3
                }
                borderRadius="4px"
                p="8px"
                mb="16px"
              >
                <Typography
                  variant="body1"
                  style={{ fontWeight: 700, marginBottom: "8px" }}
                >
                  Proposed change to contract
                </Typography>
                <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                  {request.request_status ===
                  LegalReviewRequestStage.buyerAgreed
                    ? request.alternate_change
                    : request.propose}
                </Typography>
              </Box>

              <Box display="flex" flexDirection="column" marginBottom="24px">
                <Typography
                  variant="body1"
                  style={{ fontWeight: 700, marginBottom: "8px" }}
                >
                  Response to request
                </Typography>

                <Box display="flex" flexDirection="row">
                  <RadioWithTitle
                    title="Agreed"
                    selected={stage === LegalReviewRequestStage.sellerAgreed}
                    onSelected={() => {
                      setStage(LegalReviewRequestStage.sellerAgreed);
                      setEdited(true);
                    }}
                    onUnselected={() => {
                      setStage(LegalReviewRequestStage.pending);
                      setEdited(true);
                    }}
                    disabled={sentOrCompleted}
                  />
                  <Box width="8px" />
                  <RadioWithTitle
                    title="Not Agreed"
                    selected={stage === LegalReviewRequestStage.sellerNotAgreed}
                    onSelected={() => {
                      setStage(LegalReviewRequestStage.sellerNotAgreed);
                      setEdited(true);
                    }}
                    onUnselected={() => {
                      setStage(LegalReviewRequestStage.pending);
                      setEdited(true);
                    }}
                    disabled={sentOrCompleted}
                  />
                  <Box width="8px" />

                  <RadioWithTitle
                    title="Propose Alternative"
                    selected={
                      stage === LegalReviewRequestStage.alternativeSuggested
                    }
                    onSelected={() => {
                      setStage(LegalReviewRequestStage.alternativeSuggested);
                      setEdited(true);
                    }}
                    onUnselected={() => {
                      setStage(LegalReviewRequestStage.pending);
                      setEdited(true);
                    }}
                    disabled={sentOrCompleted}
                  />
                </Box>
              </Box>
              <Box p="8px" display="flex" flexDirection="column">
                <Box height="14px" />
                {stage === LegalReviewRequestStage.alternativeSuggested && (
                  <Box
                    marginBottom="32px"
                    display="flex"
                    flex={1}
                    flexDirection="column"
                  >
                    <Typography
                      variant="body1"
                      style={{ fontWeight: 700, marginBottom: "4px" }}
                    >
                      Alternative change to contract
                    </Typography>

                    <textarea
                      style={{
                        minHeight: "74px",
                        fontFamily: "GT Walsheim Pro",
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "20px",
                        border: "1px solid #E8E8E8",
                        padding: "16px",
                        flex: 1,
                      }}
                      value={alternative}
                      onChange={(event) => {
                        setAlternative(event.target.value);
                        setEdited(true);
                      }}
                      disabled={sentOrCompleted}
                    />
                  </Box>
                )}

                <Typography
                  variant="body1"
                  style={{
                    fontWeight: 700,
                    marginBottom: "4px",
                  }}
                >
                  Note (optional)
                </Typography>

                <textarea
                  style={{
                    minHeight: "74px",
                    fontFamily: "GT Walsheim Pro",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                    border: "1px solid #E8E8E8",
                    padding: "16px",
                    flex: 1,
                  }}
                  value={message}
                  onChange={(event) => {
                    setMessage(event.target.value);
                    setEdited(true);
                  }}
                  disabled={sentOrCompleted}
                />
              </Box>
            </Box>

            <Separator />

            <Box display="flex" flexDirection="row" alignItems="center">
              <Box pl="24px" flex={1}>
                {isSent && request && (
                  <Typography
                    variant="body2"
                    style={{ color: Colors.Grey3, marginRight: "16px" }}
                  >
                    Sent on{" "}
                    {moment(request.sent_on).format("D MMM [at] h:mm A")}
                  </Typography>
                )}
                {!isSent && !isOutstanding && (
                  <Typography
                    variant="body2"
                    style={{ color: Colors.Grey3, marginRight: "16px" }}
                  >
                    Not sent
                  </Typography>
                )}
                {!isSent && isOutstanding && (
                  <Typography
                    variant="body2"
                    style={{ color: Colors.Grey3, marginRight: "16px" }}
                  >
                    Outstanding
                  </Typography>
                )}
              </Box>

              <Box
                display="flex"
                justifyContent="flex-end"
                pl="24px"
                pr="16px"
                height="74px"
                alignItems="center"
              >
                <Button
                  title="CANCEL"
                  size={ButtonSize.medium}
                  type={ButtonType.secondary}
                  onClick={p_onClose}
                  width="114px"
                />
                <Box width="8px" />
                <Button
                  loading={saving}
                  disabled={sentOrCompleted}
                  title="SAVE"
                  size={ButtonSize.medium}
                  type={ButtonType.primary}
                  onClick={onSave}
                  width="79px"
                />
              </Box>
            </Box>
          </Box>
        </Dialog>
        <YouHaveUnsavedChangesDialog
          open={unsavedDialogOpen}
          onExit={() => {
            setUnsavedDialogOpen(false);
            resetFields();
            onClose();
          }}
          onSave={() => {
            setUnsavedDialogOpen(false);
            onSave();
          }}
          onClose={() => setUnsavedDialogOpen(false)}
        />
      </>
    )
  );
}
