import React from "react";
import { Box, Typography, ButtonBase } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import useSWR from "swr";
import { Contract, RecordStatus, TeamMemberRole } from "../api/models";
import {
  refreshContractTeamsAndRole,
  useContractStore,
} from "../stores/contract";

export enum ProgressBarProgress {
  legalReview = "Legal review",
  requests = "Requests",
  exchange = "Exchange",
}

const progresses = [
  ProgressBarProgress.legalReview,
  ProgressBarProgress.requests,
  ProgressBarProgress.exchange,
];

function progressBarProgressForContract(contract: Contract | null) {
  // TODO: Exchange
  if (
    contract?.amendment_request_status === RecordStatus.InProgress ||
    contract?.amendment_request_status === RecordStatus.Completed
  ) {
    return ProgressBarProgress.requests;
  }

  if (
    contract?.legal_review_status === RecordStatus.InProgress ||
    contract?.legal_review_status === RecordStatus.Completed
  ) {
    return ProgressBarProgress.legalReview;
  }

  return ProgressBarProgress.legalReview;
}

interface Props {
  width?: string | number;
  contractId: number;
  recordId?: number;
}

enum ProgressBarItemState {
  completed,
  active,
  inactive,
}

interface ProgressBarItemProps {
  title: string;
  state: ProgressBarItemState;
  index: number;
  onClick?: () => unknown;
}

function ProgressBarItem({
  title,
  state,
  index,
  onClick,
}: ProgressBarItemProps) {
  const _backgroundColor = () => {
    switch (state) {
      case ProgressBarItemState.completed:
        return "#C9EBE2";
      case ProgressBarItemState.active:
        return "#E1EAFF";
      case ProgressBarItemState.inactive:
        return "#E8E8E8";
    }
  };

  const backgroundColor = _backgroundColor();

  const _color = () => {
    if (state === ProgressBarItemState.inactive) {
      return "#9F9F9F";
    }

    return "#313131";
  };

  const color = _color();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flex={1}
      style={{
        cursor:
          state !== ProgressBarItemState.inactive && onClick
            ? "pointer"
            : undefined,
      }}
      onClick={() => {
        if (state !== ProgressBarItemState.inactive) {
          onClick && onClick();
        }
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        bgcolor={backgroundColor}
        flex={1}
        justifyContent="center"
        alignItems="center"
        height="100%"
        color={color}
      >
        <Box
          width="18px"
          height="18px"
          borderRadius="9px"
          bgcolor="rgba(255, 255, 255, 0.4)"
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <Typography variant="caption" style={{ fontWeight: 700 }}>
            {index + 1}
          </Typography>
        </Box>
        <Typography
          variant="caption"
          style={{ fontWeight: 700, marginLeft: "5px" }}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
}

export default function LegalReviewProgressBar({
  width = "100%",
  contractId,
  recordId,
}: Props) {
  const history = useHistory();

  const { data: contractData } = useSWR(
    `/view-contracts/${contractId}?recordId=${recordId}`
  );
  const contract = contractData && (contractData as Contract);

  const progress = progressBarProgressForContract(contract);

  const { userTeamMemberRole, setContractAndRecordId } = useContractStore();

  const isBuyer =
    userTeamMemberRole === TeamMemberRole.buyer ||
    userTeamMemberRole === TeamMemberRole.buyerLawyer ||
    userTeamMemberRole === TeamMemberRole.partner;

  React.useEffect(() => {
    refreshContractTeamsAndRole();
  }, []);

  return (
    <Box
      display="flex"
      height="34px"
      borderRadius="17px"
      flexDirection="row"
      overflow="hidden"
      width={width}
    >
      {progresses.map((thisProgress, index) => {
        const indexOfCurrentProgress = progresses.indexOf(progress);

        const _state = () => {
          if (index === indexOfCurrentProgress) {
            return ProgressBarItemState.active;
          }

          if (index < indexOfCurrentProgress) {
            return ProgressBarItemState.completed;
          }

          return ProgressBarItemState.inactive;
        };

        const state = _state();

        return (
          <>
            <ProgressBarItem
              title={thisProgress}
              state={state}
              index={index}
              onClick={() => {
                switch (thisProgress) {
                  case ProgressBarProgress.legalReview:
                    if (isBuyer) {
                      history.push(
                        `/legal-review-viewer?contractId=${contractId}&recordId=${recordId}`
                      );
                    }

                    break;

                  case ProgressBarProgress.requests:
                    history.push(
                      `/request-workspace?contractId=${contractId}&recordId=${recordId}`
                    );
                    break;

                  case ProgressBarProgress.exchange:
                    break;

                  // TODO: Not ready yet
                }
              }}
            />

            {index < progresses.length - 1 && <Box width="1px" />}
          </>
        );
      })}
    </Box>
  );
}
