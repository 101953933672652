import { AxiosError } from "axios";
import { isNil } from "lodash-es";
import create from "zustand";
import api from "../api/instance";
import { User } from "../api/models";

export const userCacheKey = "cache::user";

function getData() {
  const cachedJSON = localStorage.getItem(userCacheKey);
  return cachedJSON && JSON.parse(cachedJSON);
}

interface State {
  refresh: (sessionExpiryHandling?: boolean) => unknown;
  refreshTimestamp: Date | null;
  data: User | null | undefined;
}

export const useSessionStore = create<State>((set, get) => ({
  refresh: (sessionExpiryHandling: boolean = true) => {
    api
      .get("/me/")
      .then((response) => {
        const user = response.data as User;
        set({
          data: user,
        });
        localStorage.setItem(userCacheKey, JSON.stringify(user));

        set({
          refreshTimestamp: new Date(),
        });
      })
      .catch((error: AxiosError) => {
        console.debug("fetchUserData error: ", error);

        if (error.response?.status === 403 || error.response?.status === 401) {
          // Invalid token
          localStorage.removeItem("token");
          localStorage.removeItem(userCacheKey);

          if (sessionExpiryHandling) {
            localStorage.setItem("sessionExpiredPath", window.location.href);

            window.location.assign("/app/login?sessionExpired=true");
          }
        }
      });
  },
  refreshTimestamp: null,
  data: getData(),
}));

export function getUserName() {
  const { data } = useSessionStore.getState();

  if (data) {
    return `${data.first_name} ${data.last_name}`;
  }

  return "";
}

export function isLoggedIn() {
  const { data } = useSessionStore.getState();

  return !isNil(data);
}

export function resetSession() {
  localStorage.removeItem("token");
  localStorage.removeItem(userCacheKey);
}
