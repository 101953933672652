import React from "react";
import {
  TextField as MUITextField,
  Box,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Colors } from "../../constants/colors";

interface StyleProps {
  minHeight: string;
}

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: Colors.Grey4,
      },
      "&.Mui-focused fieldset": {
        // Border color on focus
        borderColor: Colors.BrandPrimary,
        boxShadow: "0px 0px 4px #5A75FF, 0px 4px 4px rgba(90, 117, 255, 0.2)",
      },
      "&.Mui-error fieldset": {
        borderColor: Colors.Error,
        boxShadow: "0px 0px 4px #F95555",
      },
      "&.Mui-disabled fieldset": {
        backgroundColor: Colors.Grey4,
        borderColor: Colors.Grey5,
      },
      minHeight: ({ minHeight }: StyleProps) => `${minHeight}`,
      backgroundColor: "white",
    },
  },
});

interface TextFieldProps {
  title?: string | null | undefined;
  type?: string | undefined;
  value: string | null | undefined;
  placeholder?: string | undefined;
  maxLength?: number | null | undefined;
  helperText?: string | null | undefined;
  onChange?: (value: string) => unknown;
  error?: boolean | undefined;
  disabled?: boolean;
  greyedOut?: boolean;
  multiline?: boolean;
  minHeight?: string;
  fontSize?: string | number;
  lineHeight?: string | number;
  letterSpacing?: string | number;
  accessory?: React.ReactNode;
}

export default function TextField({
  title,
  type,
  value,
  placeholder,
  maxLength,
  helperText,
  onChange,
  error,
  disabled,
  greyedOut = false,
  multiline,
  minHeight = "52px",
  fontSize = "16px",
  lineHeight,
  letterSpacing,
  accessory,
}: TextFieldProps): JSX.Element {
  const classes = useStyles({ minHeight });

  const inputStyle = {
    fontFamily: "GT Walsheim Pro",
    fontWeight: 400,
    fontSize,
    lineHeight,
    letterSpacing,
    color: disabled && greyedOut ? Colors.Grey3 : Colors.Grey7,
    zIndex: disabled ? 1 : undefined,
  }; // Need to raise z index when disabled to show value

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      {title && (
        <Typography
          variant="body2"
          style={{
            fontWeight: 700,
            marginBottom: "4px",
            color: disabled && greyedOut ? Colors.Grey3 : Colors.Grey1,
          }}
        >
          {title}
        </Typography>
      )}
      <MUITextField
        className={classes.root}
        type={type}
        value={value}
        placeholder={placeholder}
        fullWidth={true}
        variant="outlined"
        inputProps={{
          maxLength: maxLength,
          style: inputStyle,
        }}
        onChange={(event) => {
          onChange && onChange(event.target.value);
        }}
        error={error}
        disabled={disabled}
        multiline={multiline}
        InputProps={{
          endAdornment: accessory,
        }}
      />
      <Box height="14px">
        {helperText && (
          <Typography
            variant="caption"
            style={{ color: error ? Colors.Error : Colors.Grey2 }}
          >
            {helperText}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
