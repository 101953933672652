import React from "react";
import { Box, Typography } from "@material-ui/core";
import Dropzone from "react-dropzone";
import { useMobile } from "../../hooks/mobile";
import { Colors } from "../../constants/colors";
import CloudIcon from "../../images/upload-cloud.png";
import CloudIconError from "../../images/upload-cloud-error.png";
import { isEmpty } from "lodash-es";
import CloseButton, { CloseButtonColor } from "./CloseButton";
import PDFIcon from "../../images/pdf-icon.png";
import PDFJS from "pdfjs-dist";

interface Props {
  onSelectFileError?: () => unknown;
  onSelectedFile?: (file: File | null) => unknown;
  selectedFile?: File | null;
  error?: boolean | null;
}

const fileSizeString = (file: File) => {
  if (file.size < 10000) {
    // Less than 10kb
    return `${Math.round(file.size / 1000)} KB`;
  }

  return `${Math.round((file.size / 1000000) * 100) / 100} MB`;
};

export default function UploadFileBox({
  onSelectFileError,
  onSelectedFile,
  selectedFile,
  error,
}: Props): JSX.Element {
  const isMobile = useMobile();
  const maxFileSizeMessage = "Max file size 18MB";
  const errorMessageTitle = "We couldn't upload your contract";
  const errorMessageSubtitle =
    "Make sure that the file is not bigger than 18MB and that is a PDF format.";

  const [pdfTitle, setPdfTitle] = React.useState("");

  const onDrop = React.useCallback((acceptedFiles: Array<File>) => {
    if (!isEmpty(acceptedFiles)) {
      const file = acceptedFiles[0];

      onSelectedFile && onSelectedFile(file);
    }
  }, []);

  React.useEffect(() => {
    const setWorkerSrc = async () => {
      // REMINDER: This must be set or it doesn't work
      // pdf.worker.js is usually inside node_modules/pdfjs-dist/build directory
      // https://github.com/mozilla/pdf.js/issues/8305#issuecomment-518674409
      // @ts-ignore
      const pdfjs = await import("pdfjs-dist/build/pdf");
      // @ts-ignore
      const pdfjsWorker = await import("pdfjs-dist/build/pdf.worker.entry");
      PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    };

    setWorkerSrc();
  }, []);

  React.useEffect(() => {
    if (selectedFile) {
      new Response(selectedFile).arrayBuffer().then((arrayBuffer) => {
        console.debug("arrayBuffer:", arrayBuffer);

        const loadingTask = PDFJS.getDocument(arrayBuffer);
        loadingTask.promise.then((pdf) => {
          pdf.getMetadata().then((metadata) => {
            console.debug("metadata: ", metadata);

            const { Title } = metadata.info;
            setPdfTitle(Title || "PDF file");
          });
        });
      });
    }
  }, [selectedFile]);

  return (
    <Dropzone
      multiple={false}
      maxSize={19000000}
      accept="application/pdf"
      onDrop={onDrop}
      onDropRejected={(rejections, event) => {
        console.debug("rejections: ", rejections);
        console.debug("event: ", event);
        onSelectFileError && onSelectFileError();
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <section>
          <div {...getRootProps()}>
            <input {...getInputProps()} />

            {selectedFile && (
              <Box
                bgcolor={Colors.Grey5}
                display="flex"
                flexDirection="row"
                alignItems="center"
                borderRadius={"8px"}
                maxWidth="353px"
                p={2}
              >
                <img src={PDFIcon} />
                <Box ml={1} display="flex" flexDirection="column" flex={1}>
                  <Typography variant="caption" style={{ fontWeight: 700 }}>
                    {pdfTitle}
                  </Typography>
                  <Typography
                    variant="caption"
                    style={{ marginTop: "2px", marginBottom: "3px" }}
                  >
                    {selectedFile.name}
                  </Typography>
                  <Typography variant="caption">
                    {fileSizeString(selectedFile)}
                  </Typography>
                </Box>
                <Box ml={2}>
                  <CloseButton
                    color={CloseButtonColor.CIRCLE}
                    onClick={() => {
                      onSelectedFile && onSelectedFile(null);
                    }}
                  />
                </Box>
              </Box>
            )}

            {!selectedFile && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Box
                  bgcolor={
                    error === true
                      ? Colors.ErrorBackground
                      : Colors.BrandBackground3
                  }
                  border={
                    error === true ? "2px dashed #F95555" : "2px dashed #0038E4"
                  }
                  borderRadius="8px"
                  height={isMobile ? "346px" : "243px"}
                  alignItems="center"
                  justifyContent="center"
                  display="flex"
                  flexDirection="column"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: 700,
                        color:
                          error === true ? Colors.Error : Colors.BrandPrimary,
                      }}
                    >
                      Tap to upload file
                    </Typography>
                    <img
                      src={error === true ? CloudIconError : CloudIcon}
                      style={{ marginTop: "16px" }}
                    />
                  </Box>
                  {!isMobile && (
                    <Typography
                      variant="caption"
                      style={{
                        color:
                          error === true ? Colors.Error : Colors.BrandPrimary,
                        marginTop: "16px",
                      }}
                    >
                      {maxFileSizeMessage}
                    </Typography>
                  )}
                </Box>
                {isMobile && error !== true && (
                  <Typography
                    variant="caption"
                    style={{
                      marginTop: "16px",
                      textAlign: "center",
                    }}
                  >
                    {maxFileSizeMessage}
                  </Typography>
                )}
                {error === true && (
                  <React.Fragment>
                    <Typography
                      variant="caption"
                      style={{
                        marginTop: "15px",
                        textAlign: "center",
                        fontWeight: 700,
                        color: Colors.Error,
                      }}
                    >
                      {errorMessageTitle}
                    </Typography>
                    <Typography
                      variant="caption"
                      style={{
                        marginTop: "15px",
                        marginLeft: "15px",
                        marginRight: "15px",
                        textAlign: "center",
                        color: Colors.Error,
                      }}
                    >
                      {errorMessageSubtitle}
                    </Typography>
                  </React.Fragment>
                )}
              </Box>
            )}
          </div>
        </section>
      )}
    </Dropzone>
  );
}
