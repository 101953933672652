import React from "react";
import { Box, ButtonBase } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import BLUELOGO from "../images/Blue.png";
import BUYER from "../images/Buyer.png";
import { useMobile, useDesktop } from "../hooks/mobile";
import { Colors } from "../constants/colors";
import AlertWrapper from "./AlertWrapper";
import CloseButton, { CloseButtonColor } from "./common/CloseButton";

interface LayoutProps
  extends React.PropsWithChildren<{
    logoHidden?: boolean;
    headerHidden?: boolean;
    onCloseClicked?: () => unknown;
  }> {}

export default function HeroLayout({
  children,
  logoHidden,
  headerHidden,
  onCloseClicked,
}: LayoutProps): JSX.Element {
  const history = useHistory();

  const isMobile = useMobile();
  const isDesktop = useDesktop();

  const onCloseClick = () => {
    if (onCloseClicked) {
      onCloseClicked();
      return;
    }

    const token = localStorage.getItem("token");

    if (token) {
      history.push("/dashboard");
    } else {
      window.location.assign(window.location.origin);
    }
  };

  function Logo() {
    return (
      <ButtonBase
        onClick={() => window.location.assign(window.location.origin)}
      >
        <img alt="blue-logo" src={BLUELOGO} height="32px" />
      </ButtonBase>
    );
  }

  function Header() {
    if (headerHidden === true) {
      return <Box />;
    }

    if (isMobile) {
      return (
        <Box
          height={72}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          pl={3.5}
          pr={3.5}
        >
          {!logoHidden && <Logo />}
          <CloseButton color={CloseButtonColor.BLUE} onClick={onCloseClick} />
        </Box>
      );
    } else {
      return (
        <Box
          mb={4}
          display="flex"
          flexDirection="row"
          width="100%"
          height={88}
          position="absolute"
          alignItems="center"
          justifyContent={logoHidden ? "flex-end" : "space-between"}
        >
          {!logoHidden && (
            <Box ml={4}>
              <Logo />
            </Box>
          )}
          <Box mt={0} mr={4}>
            <CloseButton
              color={isDesktop ? CloseButtonColor.WHITE : CloseButtonColor.BLUE}
              onClick={onCloseClick}
            />
          </Box>
        </Box>
      );
    }
  }

  return (
    <AlertWrapper>
      <Box>
        {isMobile && (
          <React.Fragment>
            <Header />

            {children}
          </React.Fragment>
        )}
        {!isMobile && (
          <Box
            display="flex"
            flexDirection="row"
            minHeight="100vh"
            height="100%"
          >
            <Header />
            <Box marginLeft="auto" marginRight="auto">
              {children}
            </Box>
            {isDesktop && (
              <Box
                p={4}
                display="flex"
                bgcolor={Colors.BrandBackground}
                alignItems="center"
              >
                <img src={BUYER} alt="buyer"/>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </AlertWrapper>
  );
}
