import React from "react";
import { Box, BoxProps } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import Button, { ButtonType, ButtonSize } from "./Button";

const height = "96px";

const phantomStyle = {
  height,
};

const footerStyle = {
  height,
  backgroundColor: Colors.Grey5,
  bottom: 0,
  boxShadow: "0px -1px 0px #E8E8E8",
};

export enum FooterButtonsLayout {
  sideBySide,
  sideBySideDark,
  centered,
  centeredDark,
  custom,
}

interface FooterProps {
  buttonsLayout: FooterButtonsLayout;
  leftButtonTitle?: string;
  leftButtonOnClick?: () => unknown;
  rightButtonTitle?: string;
  rightButtonTitleDark?: string;
  rightButtonOnClick?: () => unknown;
  rightButtonLoading?: boolean;
  rightButtonDestructive?: boolean;
  buttonTitle?: string;
  buttonOnClick?: () => unknown;
  buttonLoading?: boolean;
  customView?: React.ReactElement | null;
}

export default function MobileStickyFooter({
  buttonsLayout,
  leftButtonTitle,
  leftButtonOnClick,
  rightButtonTitle,
  rightButtonTitleDark,
  rightButtonOnClick,
  rightButtonLoading,
  rightButtonDestructive,
  buttonTitle,
  buttonOnClick,
  buttonLoading,
  customView,
}: FooterProps): JSX.Element {
  return (
    <Box>
      {/* Phantom view so views on top of footer knows "it's there" and respects margins */}
      <Box style={phantomStyle} width="100%" />
      <Box
        style={footerStyle}
        flexDirection="row"
        display="flex"
        flex={1}
        alignItems="center"
        zIndex={1}
        paddingBottom="10px"
        position="fixed"
        left={0}
        right={0}
        justifyContent={
          buttonsLayout === FooterButtonsLayout.sideBySide ||
          FooterButtonsLayout.sideBySideDark
            ? "space-between"
            : undefined
        }
        pl={FooterButtonsLayout.sideBySide ? 3.5 : 3}
        pr={FooterButtonsLayout.sideBySide ? 3.5 : 3}
      >
        {buttonsLayout === FooterButtonsLayout.sideBySide && (
          <React.Fragment>
            <Button
              type={ButtonType.secondary}
              size={ButtonSize.large}
              title={leftButtonTitle || ""}
              onClick={() => leftButtonOnClick && leftButtonOnClick()}
            />
            <Button
              type={
                rightButtonDestructive
                  ? ButtonType.destructive
                  : ButtonType.primary
              }
              size={ButtonSize.large}
              title={rightButtonTitle || ""}
              loading={rightButtonLoading}
              onClick={() => rightButtonOnClick && rightButtonOnClick()}
            />
          </React.Fragment>
        )}

        {buttonsLayout === FooterButtonsLayout.sideBySideDark && (
          <React.Fragment>
            <Button
              type={ButtonType.secondary}
              size={ButtonSize.large}
              title={leftButtonTitle || ""}
              onClick={() => leftButtonOnClick && leftButtonOnClick()}
            />

            <Button
              type={ButtonType.dark}
              size={ButtonSize.large}
              title={rightButtonTitleDark || ""}
              loading={rightButtonLoading}
              onClick={() => rightButtonOnClick && rightButtonOnClick()}
            />
          </React.Fragment>
        )}

        {buttonsLayout === FooterButtonsLayout.centered && (
          <React.Fragment>
            <Button
              type={ButtonType.primary}
              size={ButtonSize.large}
              title={buttonTitle || ""}
              width="100%"
              loading={buttonLoading}
              onClick={() => buttonOnClick && buttonOnClick()}
            />
          </React.Fragment>
        )}

        {buttonsLayout === FooterButtonsLayout.centeredDark && (
          <React.Fragment>
            <Button
              type={ButtonType.dark}
              size={ButtonSize.large}
              title={buttonTitle || ""}
              width="100%"
              loading={buttonLoading}
              onClick={() => buttonOnClick && buttonOnClick()}
            />
          </React.Fragment>
        )}

        {buttonsLayout === FooterButtonsLayout.custom && (
          <React.Fragment>{customView}</React.Fragment>
        )}
      </Box>
    </Box>
  );
}
