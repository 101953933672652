import React from "react";
import { Box, Typography, Link } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import HeroLayout from "../components/HeroLayout";
import { useMobile } from "../hooks/mobile";
import AlertWrapper from "../components/AlertWrapper";
import AuthenticatedWrapper from "../components/AuthenticatedWrapper";
import Checkbox from "../components/common/Checkbox";
import { Colors } from "../constants/colors";
import Button, { ButtonType, ButtonSize } from "../components/common/Button";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import api from "../api/instance";
import { useAlert } from "../contexts/Alert";
import { AxiosError } from "axios";

export default function UpdateContractConfirm(): JSX.Element {
  const isMobile = useMobile();
  const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const propertyId = query.get("propertyId");
  const action = query.get("action");

  const { setErrorMessage } = useAlert();

  if (!propertyId || !action) {
    return <Box />;
  }

  function TitleString() {
    if (action === "sold") {
      return "Are you sure that you want to mark this property as sold?";
    }

    if (action === "delete") {
      return "Are you sure that you want to delete this property?";
    }

    return "";
  }

  function MessageString() {
    if (action === "sold") {
      return "Marking the property as sold will remove this property from the active listings on our website. This listing will still appear in your management area.";
    }

    if (action === "delete") {
      return "Deleting this property will remove this property from our website and from all agents accounts that are associated with it in Contrax.";
    }

    return "";
  }

  function ConfirmButtonString() {
    if (action === "sold") {
      return "SOLD";
    }

    if (action === "delete") {
      return "DELETE";
    }

    return "";
  }

  function confirmApiStatus() {
    if (action === "sold") {
      return "Sold";
    }

    if (action === "delete") {
      return "Delete";
    }

    return "";
  }

  function confirmClicked() {
    api
      .put(`/properties/${propertyId}/update-status/`, {
        status: confirmApiStatus(),
      })
      .then((response) => {
        if (action === "sold") {
          history.push("/update-contract-success?action=sold");
        } else if (action === "delete") {
          history.push("/update-contract-success?action=delete");
        }
      })
      .catch((error: AxiosError) => {
        setErrorMessage(error.message);
      });
  }

  return (
    <AlertWrapper>
      <AuthenticatedWrapper>
        <HeroLayout>
          <Box mt={isMobile ? 5 : 22} ml={3} mr={3}>
            <Typography variant="h5">{TitleString()}</Typography>

            <Typography variant="body1" style={{ marginTop: "32px" }}>
              {MessageString()}
            </Typography>

            {!isMobile && (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                mt={5}
              >
                <Button
                  type={ButtonType.secondary}
                  size={ButtonSize.large}
                  title="CANCEL"
                  onClick={() => history.goBack()}
                />
                <Box ml={2} />
                <Button
                  type={ButtonType.destructive}
                  size={ButtonSize.large}
                  title={ConfirmButtonString()}
                  onClick={() => confirmClicked()}
                />
              </Box>
            )}
            {isMobile && (
              <MobileStickyFooter
                buttonsLayout={FooterButtonsLayout.sideBySide}
                leftButtonTitle="CANCEL"
                leftButtonOnClick={() => history.goBack()}
                rightButtonTitle={ConfirmButtonString()}
                rightButtonDestructive={true}
                rightButtonOnClick={() => confirmClicked()}
              />
            )}
          </Box>
        </HeroLayout>
      </AuthenticatedWrapper>
    </AlertWrapper>
  );
}
