import React from "react";
import api from "../api/instance";
import { Box, Typography, Link } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import { useMobile, useDesktop, useTablet } from "../hooks/mobile";
import AgentSignUpLayout from "../components/HeroLayout";
import TextField from "../components/common/TextField";
import AsyncSelect from "../components/common/AsyncSelect";
import Button, { ButtonType, ButtonSize } from "../components/common/Button";
import { Colors } from "../constants/colors";
import { isNil, isEmpty } from "lodash-es";
import { Agency } from "../api/models";
import AgencyIcon from "../images/agency.png";
import { useAlert } from "../contexts/Alert";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import TermsLink from "../components/TermsLink";
import PrivacyLink from "../components/PrivacyLink";

interface AgencyOption {
  value: number;
  label: string;
  image: string;
}
const otherOption: AgencyOption = {
  value: -1,
  label: "Other",
} as AgencyOption;

export default function AgentSignUp(): JSX.Element {
  const isMobile = useMobile();
  const location = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(location.search);
  const agentInviteeEmail = query.get("agentInviteeEmail");
  const agentInviteeFirstName = query.get("agentInviteeFirstName");
  const agentInviteeLastName = query.get("agentInviteeLastName");
  const agentBusinessName = query.get("agentBusinessName");

  const [firstName, setFirstName] = React.useState("");
  const [firstNameError, setFirstNameError] = React.useState<string | null>(
    null
  );
  const [lastName, setLastName] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState<string | null>(null);

  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState<string | null>(null);

  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [phoneNumberError, setPhoneNumberError] = React.useState<string | null>(
    null
  );

  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState<string | null>(null);

  const [selectedAgencyId, setSelectedAgencyId] = React.useState<number | null>(
    null
  );
  const [selectedAgencyError, setSelectedAgencyError] = React.useState<
    string | null
  >(null);

  const [otherAgencyName, setOtherAgencyName] = React.useState("");
  const [otherAgencyError, setOtherAgencyError] = React.useState<string | null>(
    null
  );

  const [loading, setLoading] = React.useState(false);

  const { setErrorMessage } = useAlert();

  const searchTimeoutRef = React.useRef<any>(null);

  React.useEffect(() => {
    agentInviteeEmail && setEmail(agentInviteeEmail);
    agentInviteeFirstName && setFirstName(agentInviteeFirstName);
    agentInviteeLastName && setLastName(agentInviteeLastName);
  }, []);

  function Title() {
    return (
      <Box maxWidth={isMobile ? "353px" : "700px"} mt={isMobile ? 3 : 22}>
        <Typography variant={isMobile ? "h5" : "h4"}>Agent Sign Up</Typography>
        <Typography
          variant={isMobile ? "body2" : "body1"}
          style={{ marginTop: "16px" }}
        >
          Sign up with your own details. Don't worry, you'll be able to add team
          members for the property later on.
        </Typography>
      </Box>
    );
  }

  function FooterText() {
    return (
      <Box>
        <Typography variant="body2">
          By signing up you are agreeing to our <TermsLink /> and{" "}
          <PrivacyLink />
        </Typography>
        <Typography variant="body2" style={{ marginTop: "20px" }}>
          Already have an account?{" "}
          <Link
            href="#"
            style={{ fontWeight: 700, color: Colors.Link }}
            onClick={() => history.push("/login")}
          >
            Log in
          </Link>
        </Typography>
      </Box>
    );
  }

  function resetErrors() {
    setSelectedAgencyError(null);
    setOtherAgencyError(null);
    setFirstNameError(null);
    setLastNameError(null);
    setEmailError(null);
    setPhoneNumberError(null);
    setPasswordError(null);
    setErrorMessage(null);
  }

  function verifyFields() {
    resetErrors();

    let allFieldsValid = true;

    if (isNil(selectedAgencyId)) {
      setSelectedAgencyError("Please select your agency");
      allFieldsValid = false;
    }

    if (selectedAgencyId === otherOption.value && isEmpty(otherAgencyName)) {
      setOtherAgencyError("Please enter your agency name");
      allFieldsValid = false;
    }

    if (isEmpty(firstName)) {
      setFirstNameError("Please enter your first name");
      allFieldsValid = false;
    }

    if (isEmpty(lastName)) {
      setLastNameError("Please enter your last name");
      allFieldsValid = false;
    }

    if (isEmpty(email)) {
      setEmailError("Please enter your email");
      allFieldsValid = false;
    }

    if (isEmpty(phoneNumber)) {
      setPhoneNumberError("Please enter your phone number");
      allFieldsValid = false;
    }

    if (isEmpty(password)) {
      setPasswordError("Please enter a password");
      allFieldsValid = false;
    }

    return allFieldsValid;
  }

  function signUpTapped() {
    if (loading) {
      return;
    }

    if (!verifyFields()) {
      setErrorMessage("Please fill in the missing fields");
      return;
    }

    const commonData = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone: phoneNumber,
      password,
    };

    let data;

    if (agentBusinessName) {
      data = {
        ...commonData,
        agency_name: agentBusinessName,
      };
    } else if (selectedAgencyId === otherOption.value) {
      data = {
        ...commonData,
        agency_name: otherAgencyName,
      };
    } else {
      data = {
        ...commonData,
        agency_id: selectedAgencyId,
      };
    }

    setLoading(true);

    api
      .post("/agents/", data)
      .then((response) => {
        const agent = response.data;

        // localStorage.setItem("agent", JSON.stringify(agent));

        history.push("/agent/signup-success", {
          agent,
        });
      })
      .catch((error) => {
        setLoading(false);

        setErrorMessage("Please check errors on this page");

        const errors = error.response.data;

        const { first_name, last_name, email, phone, password } = errors;

        first_name && setFirstNameError(first_name[0]);
        last_name && setLastNameError(last_name[0]);
        email && setEmailError(email[0]);
        phone && setPhoneNumberError(phone[0]);
        password && setPasswordError(password[0]);
      });
  }

  return (
    <AgentSignUpLayout>
      <Box
        display="flex"
        flexDirection="column"
        marginLeft="auto"
        marginRight="auto"
        alignItems={isMobile ? "center" : undefined}
        pl={isMobile ? 3 : 0}
        pr={isMobile ? 3 : 0}
      >
        <Title />

        <Box
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          mt={3}
          maxWidth="768px"
        >
          <Box width={isMobile ? undefined : "353px"}>
            {!agentBusinessName && (
              <AsyncSelect
                title="Real estate agency"
                placeholder="Please select"
                defaultOptions={[otherOption]}
                error={selectedAgencyError !== null}
                helperText={selectedAgencyError}
                loadOptions={(inputValue, callback) => {
                  if (searchTimeoutRef.current) {
                    clearTimeout(searchTimeoutRef.current);
                  }

                  searchTimeoutRef.current = setTimeout(() => {
                    console.debug("loadOptions");

                    api
                      .get("/search-agencies/", {
                        params: {
                          q: inputValue,
                        },
                      })
                      .then((res) => {
                        const agencies = res.data as Array<Agency>;

                        const agencyOptions = agencies.map((agency) => {
                          return {
                            value: agency.id,
                            label: `${agency.name} · ${agency.suburb}`,
                            image: AgencyIcon,
                          };
                        });

                        callback(agencyOptions.concat(otherOption));
                      })
                      .catch((error) => {});
                  }, 300);
                }}
                onChange={(value) => {
                  const selectedOption = value as AgencyOption;
                  setSelectedAgencyId(selectedOption.value);
                }}
              />
            )}
            {agentBusinessName && (
              <TextField
                title="Agency name"
                value={agentBusinessName}
                disabled={true}
              />
            )}

            {selectedAgencyId === -1 && (
              <React.Fragment>
                <TextField
                  title="Other Real estate agency name"
                  value={otherAgencyName}
                  error={otherAgencyError !== null}
                  helperText={otherAgencyError}
                  onChange={(value) => setOtherAgencyName(value)}
                />
                <Box mt={2} />
              </React.Fragment>
            )}
            <TextField
              title="First name"
              value={firstName}
              error={firstNameError !== null}
              helperText={firstNameError}
              onChange={(value) => setFirstName(value)}
            />
            <Box mt={1} />
            <TextField
              title="Last name"
              value={lastName}
              error={lastNameError !== null}
              helperText={lastNameError}
              onChange={(value) => setLastName(value)}
            />
            <Box mt={1} />
          </Box>
          <Box width={isMobile ? undefined : "353px"} ml={isMobile ? 0 : 2}>
            <TextField
              type="email"
              title="Email"
              error={emailError !== null}
              helperText={emailError}
              value={email}
              onChange={(value) => setEmail(value)}
            />
            <Box mt={1} />
            <TextField
              type="phone"
              title="Phone number"
              error={phoneNumberError !== null}
              helperText={phoneNumberError}
              value={phoneNumber}
              onChange={(value) => setPhoneNumber(value)}
            />
            <Box mt={1} />
            <TextField
              type="password"
              title="Password"
              error={passwordError !== null}
              helperText={passwordError}
              value={password}
              onChange={(value) => setPassword(value)}
            />
            <Box mt={1} />

            {isMobile && (
              <Box mb={8}>
                <FooterText />
              </Box>
            )}
          </Box>
        </Box>
        {!isMobile && (
          <React.Fragment>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              maxWidth="353px"
            >
              <FooterText />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              mt={5}
              mb={10}
            >
              <Button
                type={ButtonType.primary}
                size={ButtonSize.large}
                title="SIGN UP"
                width={isMobile ? "100%" : "137px"}
                loading={loading}
                onClick={() => signUpTapped()}
              />
            </Box>
          </React.Fragment>
        )}
      </Box>

      {isMobile && (
        <MobileStickyFooter
          buttonsLayout={FooterButtonsLayout.centered}
          buttonTitle="SIGN UP"
          buttonLoading={loading}
          buttonOnClick={() => signUpTapped()}
        />
      )}
    </AgentSignUpLayout>
  );
}
