import React from "react";
import { Box, Typography } from "@material-ui/core";
import BLUELOGO from "../images/Blue.png";
import BackButtonBlue from "../components/common/BackButtonBlue";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";
import { Colors } from "../constants/colors";
import Separator from "../components/common/Separator";
import AmendmentAppendixItem from "../components/AmendmentAppendix/AmendmentAppendixItem";
import PlaceholderHero from "../images/lawyer-conveyancer.png";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import { isNil, isEmpty } from "lodash-es";
import { LegalReviewRequestStage, LegalReviewRequest } from "../api/models";
import { useMobile } from "../hooks/mobile";
import DownloadAmendmentAppendixButton from "../components/AmendmentAppendix/DownloadAmendmentAppendixButton";

interface HeaderProps {
  contractId: number | null;
  recordId: number | null;
}

function Header({ contractId, recordId }: HeaderProps) {
  const history = useHistory();
  const isMobile = useMobile();

  return (
    <>
      <Box
        height="72px"
        justifyContent="space-between"
        display="flex"
        alignItems="center"
      >
        <Box width="33.3%" pl={isMobile ? "8px" : "18px"}>
          <BackButtonBlue onClick={() => history.goBack()} />
        </Box>
        <Box width="33.3%" display="flex" justifyContent="center">
          <img src={BLUELOGO} height="32px" />
        </Box>
        <Box
          width="33.3%"
          display="flex"
          justifyContent="flex-end"
          pr={isMobile ? "8px" : "40px"}
        >
          {/* <Button
            title={isMobile ? "DOWNLOAD" : "DOWNLOAD AMENDMENT APPENDIX"}
            type={ButtonType.secondary}
            size={ButtonSize.small}
            width="292px"
            titleColor={Colors.BrandPrimary}
          /> */}
          <DownloadAmendmentAppendixButton
            contractId={contractId}
            recordId={recordId}
          />
        </Box>
      </Box>
      <Separator />
    </>
  );
}

function Placeholder() {
  const isMobile = useMobile();

  return (
    <Box width={isMobile ? "90vw" : "433px"} textAlign="center">
      <img src={PlaceholderHero} />
      <Typography
        variant="h5"
        style={{ fontWeight: 700, marginBottom: "20px" }}
      >
        No amendment requests agreed yet
      </Typography>
      <Typography variant="body1">
        Requests that the parties agree to will appear here and form part of the
        amendment appendix.
      </Typography>
    </Box>
  );
}

export default function AmendmentAppendix() {
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const _contractId = query.get("contractId");
  const contractId = !isNil(_contractId) ? parseInt(_contractId) : null;
  const _recordId = query.get("recordId");
  const recordId = !isNil(_recordId) ? parseInt(_recordId) : null;

  const { data: requestsData, mutate: requestsMutate } = useSWR(
    `/contract/${contractId}/record/${recordId}/legal-review-requests/`
  );

  const requests = requestsData
    ? (requestsData as LegalReviewRequest[]).filter(
        (request) =>
          request.request_status === LegalReviewRequestStage.buyerAgreed ||
          request.request_status === LegalReviewRequestStage.sellerAgreed
      )
    : [];

  const noRequests = !isNil(requestsData) && isEmpty(requests);

  return (
    <Box>
      <Header contractId={contractId} recordId={recordId} />
      <Box
        pt="18px"
        pb="18px"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {noRequests && (
          <Box height="calc(100vh - 108px)" alignItems="center" display="flex">
            <Placeholder />
          </Box>
        )}

        {requests.map((request) => (
          <AmendmentAppendixItem request={request} />
        ))}
      </Box>
    </Box>
  );
}
