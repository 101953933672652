import { Box, Typography } from "@material-ui/core";
import { AxiosError } from "axios";
import { isNil } from "lodash-es";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import api from "../api/instance";
import { Property } from "../api/models";
import AlertWrapper from "../components/AlertWrapper";
import AuthenticatedWrapper from "../components/AuthenticatedWrapper";
import AsyncSelect from "../components/common/AsyncSelect";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import TextField from "../components/common/TextField";
import { SelectedImage } from "../components/common/UploadImageBox";
import HeroLayout from "../components/HeroLayout";
import { useAlert } from "../contexts/Alert";
import {
  GooglePlaceSearchResult,
  normalisedPlaceId,
  placeDetails,
  search as searchPlace,
} from "../googleplaces/queries";
import { useMobile } from "../hooks/mobile";
import MapPinIcon from "../images/map-pin.png";
import SearchIcon from "../images/search.png";

interface PlaceOption {
  image: string;
  value: string;
  label: string;
  subLabel: string;
  description: string;
}

interface PlaceDetails {
  street: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}

export default function AddProperty(): JSX.Element {
  const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const role = query.get("role");

  const [selectedPlaceId, setSelectedPlaceId] = React.useState<string | null>(
    null
  );

  const [selectedLabel, setSelectedLabel] = React.useState<String | null>(null);

  const [streetName, setStreetName] = React.useState<String | null>(null);

  const [selectedPlaceDetails, setSelectedPlaceDetails] =
    React.useState<PlaceDetails | null>(null);
  const [selectedPlaceError, setSelectedPlaceError] = React.useState<
    string | null
  >(null);

  const [propertyURL, setPropertyURL] = React.useState("");

  const [selectedImage, setSelectedImage] =
    React.useState<SelectedImage | null>(null);

  const [loading, setLoading] = React.useState(false);

  const isMobile = useMobile();
  const { setErrorMessage } = useAlert();

  const searchTimeoutRef = React.useRef<any>(null);
  const prefilledPropertyRef = React.useRef(false);

  function Header() {
    return (
      <Box
        width={isMobile ? undefined : "700px"}
        mt={isMobile ? 3 : 22}
        pl={isMobile ? 3 : 0}
        pr={isMobile ? 3 : 0}
        alignSelf="flex-start"
      >
        <Typography variant="overline">STEP 1 OF 2</Typography>
        <Typography
          variant={isMobile ? "h5" : "h4"}
          style={{ marginTop: "8px" }}
        >
          Search property
        </Typography>
      </Box>
    );
  }

  React.useEffect(() => {
    if (selectedPlaceId) {
      placeDetails(selectedPlaceId).then((result) => {
        console.debug("view result: ", result);
        let place = result as google.maps.places.PlaceResult;
        let components = place.address_components;
        if (components) {
          let subPremise =
            components.find((component) =>
              component.types.includes("subpremise")
            )?.long_name || "";

          let streetNumber =
            components.find((component) =>
              component.types.includes("street_number")
            )?.long_name || "";
          let streetName =
            components.find((component) => component.types.includes("route"))
              ?.short_name || "";
          let city =
            components.find((component) => component.types.includes("locality"))
              ?.long_name || "";
          let state =
            components.find((component) =>
              component.types.includes("administrative_area_level_1")
            )?.short_name || "";
          let postalCode =
            components.find((component) =>
              component.types.includes("postal_code")
            )?.long_name || "";
          let country =
            components.find((component) => component.types.includes("country"))
              ?.long_name || "";

          setSelectedPlaceDetails({
            street: `${
              subPremise && subPremise + "/"
            }${streetNumber} ${streetName}`.trim(),
            city,
            state,
            postalCode,
            country,
          });
        }
      });
    }
  }, [selectedPlaceId]);

  async function nextClicked() {
    if (loading) {
      return;
    }

    // Otherwise add new property
    setSelectedPlaceError(null);

    if (isNil(selectedPlaceDetails)) {
      setSelectedPlaceError("Please select your property address");
      return;
    }

    setLoading(true);

    if (selectedPlaceDetails) {
      const {
        street,
        city,
        state,
        postalCode: postal_code,
        country,
      } = selectedPlaceDetails;

      let placeId: any = null;

      if (selectedPlaceId) {
        await normalisedPlaceId(selectedPlaceId).then((Id) => {
          placeId = Id;
        });
      }
      console.log(placeId, "placeId");
      console.log(placeId, "placeId");
      const form = new FormData();
      selectedPlaceId && form.append("place_id", placeId);
      selectedImage &&
        selectedImage.file &&
        form.append("image", selectedImage.file as Blob);
      form.append("property_url", propertyURL);
      form.append("street", street);
      form.append("city", city);
      form.append("state", state);
      form.append("postal_code", postal_code);
      form.append("country", country);

      api
        .post(
          `/public-properties/?public=true&label=${selectedLabel}&street_name=${streetName}`,
          form
        )
        .then((response) => {
          const property = response.data as Property;

          if (!isNil(property.contract_id)) {
            if (role === "buying") {
              history.push(`/payment-preview?propertyId=${property.id}`);
            } else {
              // Show error
              setLoading(false);
              setErrorMessage("This property already has an existing contract");
            }
          } else {
            history.push(
              `/upload-sale-contract?propertyId=${property.id}&role=${role}`
            );
          }
        })
        .catch((error: AxiosError) => {
          setLoading(false);

          setErrorMessage(error.message);
        });
    }
  }

  return (
    <AlertWrapper>
      <AuthenticatedWrapper>
        <HeroLayout>
          <Box
            display="flex"
            flexDirection="column"
            marginLeft="auto"
            marginRight="auto"
            alignItems={isMobile ? "center" : undefined}
            mb={8}
          >
            <Header />

            <Box
              display="flex"
              flexDirection={isMobile ? "column" : "row"}
              mt={isMobile ? 4 : 7.25}
              maxWidth={isMobile ? "100vw" : "768px"}
              width={isMobile ? "90vw" : undefined}
            >
              <Box width={isMobile ? "100%" : "353px"}>
                <React.Fragment>
                  <AsyncSelect
                    title="Search for a property address"
                    placeholder=""
                    error={selectedPlaceError !== null}
                    helperText={selectedPlaceError}
                    loadOptions={(inputValue, callback) => {
                      if (searchTimeoutRef.current) {
                        clearTimeout(searchTimeoutRef.current);
                      }

                      searchTimeoutRef.current = setTimeout(() => {
                        console.debug("loadOptions");

                        searchPlace(inputValue)
                          .then((results) => {
                            const options = (
                              results as Array<GooglePlaceSearchResult>
                            ).map((result) => {
                              return {
                                image: MapPinIcon,
                                value: result.place_id,
                                label: result.name,
                                subLabel: result.formatted_address,
                                description: result.description,
                              } as PlaceOption;
                            });

                            callback(options);
                          })
                          .catch((error) => {
                            console.debug("error: ", error);
                          });
                      }, 500);
                    }}
                    dropdownImage={SearchIcon}
                    onChange={(value) => {
                      setStreetName((value as PlaceOption).label);
                      setSelectedLabel((value as PlaceOption).description);
                      setSelectedPlaceId((value as PlaceOption).value);
                    }}
                  />
                  <Typography
                    variant="caption"
                    component="sup"
                    style={{ verticalAlign: "text-top", lineHeight: 4 }}
                  >
                    E.g. 1/100 York Street, Sydney
                  </Typography>
                </React.Fragment>
              </Box>
            </Box>
          </Box>
          {isMobile && (
            <MobileStickyFooter
              buttonsLayout={FooterButtonsLayout.sideBySide}
              leftButtonTitle="BACK"
              leftButtonOnClick={() => history.goBack()}
              rightButtonTitle="NEXT"
              rightButtonOnClick={() => nextClicked()}
            />
          )}
          {!isMobile && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              mt={20}
              mb={10}
            >
              <Button
                type={ButtonType.primary}
                size={ButtonSize.large}
                title="NEXT"
                width={"137px"}
                loading={loading}
                onClick={() => nextClicked()}
              />
            </Box>
          )}
        </HeroLayout>
      </AuthenticatedWrapper>
    </AlertWrapper>
  );
}
