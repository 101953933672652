import React from "react";
import { Box, Typography } from "@material-ui/core";
import { ContractSummary } from "../../api/models";
import Button, { ButtonSize, ButtonType } from "../common/Button";
import { useMobile } from "../../hooks/mobile";

interface Props {
  contractSummary: ContractSummary;
  onViewInContract: () => unknown;
  onComment: () => unknown;
}

export default function ({
  contractSummary,
  onViewInContract,
  onComment,
}: Props) {
  const isMobile = useMobile();

  const commentsCount = contractSummary.comments.length;

  return (
    <Box
      border="1px solid #E1EAFF"
      borderRadius="4px"
      p="16px"
      mb="8px"
      bgcolor="white"
    >
      <Typography variant="body1" style={{ fontWeight: 700 }}>
        {contractSummary.title}
      </Typography>

      <Typography
        variant="body2"
        style={{
          marginTop: "16px",
          marginBottom: "16px",
          whiteSpace: "pre-line",
        }}
      >
        {contractSummary.content}
      </Typography>

      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Button
          type={ButtonType.secondary}
          size={ButtonSize.small}
          title="VIEW IN CONTRACT"
          letterSpacing="2px"
          onClick={onViewInContract}
          width="175px"
        />

        <Box display="flex" flexDirection="row" alignItems="center">
          {!isMobile && (
            <Typography variant="body2" style={{ marginRight: "16px" }}>
              {commentsCount} Comment{commentsCount === 1 ? "" : "s"}
            </Typography>
          )}

          <Button
            type={ButtonType.secondary}
            size={ButtonSize.small}
            title="COMMENT"
            width="106px"
            onClick={onComment}
          />
        </Box>
      </Box>
    </Box>
  );
}
