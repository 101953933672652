import React from "react";
import { Box, Typography } from "@material-ui/core";
import Dropzone from "react-dropzone";
import { useMobile } from "../../hooks/mobile";
import { Colors } from "../../constants/colors";
import CloudIcon from "../../images/upload-cloud.png";
import CloudIconError from "../../images/upload-cloud-error.png";
import { isEmpty } from "lodash-es";
import CloseButton, { CloseButtonColor } from "./CloseButton";

interface Props {
  onSelectFileError?: () => unknown;
  onSelectedImage?: (image: SelectedImage | null) => unknown;
  selectedImage?: SelectedImage | null;
  error?: boolean | null;
}

export interface SelectedImage {
  file?: File | null;
  url?: string | null;
}

export default function UploadImageBox({
  onSelectFileError,
  onSelectedImage,
  selectedImage,
  error,
}: Props): JSX.Element {
  const isMobile = useMobile();
  const maxFileSizeMessage = "Max file size 8MB";
  const errorMessageTitle = "We couldn't upload your image";
  const errorMessageSubtitle =
    "Make sure that the file is not bigger than 8MB and that is a JPEG file";

  // const selectedFileURL =
  //   (selectedFile && URL.createObjectURL(selectedFile)) || uploadedFileURL;

  const selectedImageURL =
    (selectedImage &&
      selectedImage.file &&
      URL.createObjectURL(selectedImage.file)) ||
    (selectedImage && selectedImage.url);

  const onDrop = React.useCallback((acceptedFiles: Array<File>) => {
    if (!isEmpty(acceptedFiles)) {
      const file = acceptedFiles[0];
      console.log(file);
      onSelectedImage &&
        onSelectedImage({
          file,
        });
    }
  }, []);

  return (
    <Dropzone
      multiple={false}
      maxSize={8000000}
      accept="image/jpeg, image/png"
      onDrop={onDrop}
      onDropRejected={(rejections, event) => {
        console.debug("rejections: ", rejections);
        console.debug("event: ", event);
        onSelectFileError && onSelectFileError();
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <section>
          <div {...getRootProps()}>
            <input {...getInputProps()} />

            {selectedImage && (
              <Box
                bgcolor={Colors.Grey5}
                display="flex"
                flexDirection="row"
                alignItems="center"
                borderRadius={"8px"}
              >
                <Box m={2}>
                  <img
                    src={selectedImageURL || undefined}
                    width="100%"
                    height="79px"
                    alt="Preview of Selection"
                  />
                </Box>
                <Box mr={2} ml="auto">
                  <CloseButton
                    color={CloseButtonColor.CIRCLE}
                    onClick={() => {
                      onSelectedImage && onSelectedImage(null);
                    }}
                  />
                </Box>
              </Box>
            )}

            {!selectedImage && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Box
                  bgcolor={
                    error === true
                      ? Colors.ErrorBackground
                      : Colors.BrandBackground3
                  }
                  border={
                    error === true ? "2px dashed #F95555" : "2px dashed #0038E4"
                  }
                  borderRadius="8px"
                  height={isMobile ? "143px" : "119px"}
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    mt={isMobile ? 3 : 4}
                  >
                    {!isMobile && (
                      <img
                        src={error === true ? CloudIconError : CloudIcon}
                        style={{ marginRight: "11px" }}
                        alt="Preview of CloudIcon"
                      />
                    )}

                    <Typography
                      variant={isMobile ? "body1" : "body2"}
                      style={{
                        fontWeight: 700,
                        color:
                          error === true ? Colors.Error : Colors.BrandPrimary,
                      }}
                    >
                      Tap to upload image
                    </Typography>
                  </Box>
                  {isMobile && (
                    <img
                      src={error === true ? CloudIconError : CloudIcon}
                      style={{
                        marginRight: "11px",
                        marginTop: "15px",
                        height: "40px",
                        width: "30px",
                      }}
                      alt="Preview of CloudIcon"
                    />
                  )}
                  {!isMobile && error !== true && (
                    <Typography
                      variant="caption"
                      style={{
                        color: Colors.BrandPrimary,
                        marginBottom: "23px",
                      }}
                    >
                      {maxFileSizeMessage}
                    </Typography>
                  )}

                  {isMobile && error !== true && (
                    <Typography
                      variant="caption"
                      style={{
                        color: Colors.BrandPrimary,
                        marginBottom: "23px",
                        marginTop: "15px",
                      }}
                    >
                      {maxFileSizeMessage}
                    </Typography>
                  )}
                </Box>
                {error === true && (
                  <React.Fragment>
                    <Typography
                      variant="caption"
                      style={{
                        marginTop: "15px",
                        textAlign: "center",
                        fontWeight: 700,
                        color: Colors.Error,
                      }}
                    >
                      {errorMessageTitle}
                    </Typography>
                    <Typography
                      variant="caption"
                      style={{
                        marginTop: "15px",
                        marginLeft: "15px",
                        marginRight: "15px",
                        textAlign: "center",
                        color: Colors.Error,
                      }}
                    >
                      {errorMessageSubtitle}
                    </Typography>
                  </React.Fragment>
                )}
              </Box>
            )}
          </div>
        </section>
      )}
    </Dropzone>
  );
}
