import React from "react";
import { Link } from "@material-ui/core";
import { Colors } from "../constants/colors";

export default function TermsLink(): JSX.Element {
  return (
    <Link
      style={{ color: Colors.Link, cursor: "pointer" }}
      onClick={() => window.open(window.location.origin + "/terms")}
    >
      Terms and Conditions
    </Link>
  );
}
