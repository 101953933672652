import React from "react";
import { useMobile } from "../../hooks/mobile";
import { Organisation } from "../../api/models";
import useSWR from "swr";
import {
  Box,
  Drawer,
  Button as MUIButton,
  Typography,
} from "@material-ui/core";
import DropDown from "../../images/DropDown.png";
import OrganisationLogo from "../OrganisationLogo";

interface Props {
  uuid?: string | null;
}

export default function MyLandingPageHeader({ uuid }: Props) {
  const isMobile = useMobile();

  const { data: orgData, error: orgError } = useSWR(`/organization/${uuid}/`);

  const organisation = orgData ? (orgData as Organisation) : undefined;


  if (organisation?.redirect === true) {
    // history.replace(`/app/org/${organisation.current_url}`);
    const customurl = `/app/org/${organisation?.current_url}`;
    window.location.replace(customurl);
  }  
  if (orgError) {
    window.location.replace(`/`);
  }

  const [openDrawer, setOpenDrawer] = React.useState(false);

  return (
    <>
      {!isMobile && (
        <Box>
          <Box
            width="100%"
            height="76px"
            display="flex"
            flexDirection="row"
            alignItems="center"
            bgcolor="#FFF"
            pt="2px"
          >
            <Box>
              <OrganisationLogo uuid={uuid} />
            </Box>

            <Box display="flex" flexDirection="row" flex={1} />

            <Box textAlign="left" mr={4}>
              <Typography variant="body2">
                {organisation?.name} <br /> {organisation?.email}
              </Typography>
            </Box>
            <Box textAlign="center" mr={4}>
              <Typography variant="body1">{organisation?.phone_no}</Typography>
            </Box>
          </Box>
          <Box height="2px" bgcolor={organisation?.color_code} />
        </Box>
      )}

      {isMobile && (
        <Box>
          <Box
            width="100%"
            height="76px"
            display="flex"
            flexDirection="row"
            alignItems="left"
            bgcolor="#FFF"
          >
            <Box display="flex" flexDirection="row" alignItems="center">
              <OrganisationLogo uuid={uuid} />
            </Box>

            <Box display="flex" flexDirection="column" flex={1} />

            <MUIButton onClick={() => setOpenDrawer(!openDrawer)}>
              <img src={DropDown} />
            </MUIButton>
            {/* <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
              <MenuIcon />
            </IconButton> */}
          </Box>
          <Drawer
            anchor="top"
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
          >
            <Box m={2}>
              <Box mb={2}>
                <Typography variant="body2" style={{ marginBottom: "4px" }}>
                  {organisation?.name}
                </Typography>
                <Typography variant="body2">{organisation?.email}</Typography>
              </Box>
              <Box mb={1}>
                <Typography variant="body1" style={{ fontWeight: 700 }}>
                  {organisation?.phone_no}
                </Typography>
              </Box>
            </Box>
            <Box height="2px" bgcolor={organisation?.color_code} />
          </Drawer>
          <Box height="2px" bgcolor={organisation?.color_code} />
        </Box>
      )}
    </>
  );
}
