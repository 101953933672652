import React from "react";
import { Box, Typography } from "@material-ui/core";
import HeroLayout from "../components/HeroLayout";
import ConfirmationIcon from "../images/Confirmation.png";
import { Colors } from "../constants/colors";
import { useMobile } from "../hooks/mobile";
import { get } from "lodash-es";
import { useLocation } from "react-router-dom";

export default function CheckYourEmail(): JSX.Element {
  const isMobile = useMobile();
  const location = useLocation();

  const locationState = location && location.state && location.state;
  const agent = locationState && get(locationState, "agent");

  return (
    <HeroLayout>
      <Box mt={isMobile ? 5 : 25} ml={isMobile ? 3 : 22} mr={isMobile ? 3 : 22}>
        <Box mb={4}>
          <img src={ConfirmationIcon} />
        </Box>
        <Typography
          variant={isMobile ? "h4" : "h3"}
          style={{ fontWeight: 700, marginBottom: "24px" }}
        >
          Check your email
        </Typography>
        <Typography variant="body1">
          Thanks for creating an account{" "}
          <span style={{ fontWeight: 700, color: Colors.BrandPrimary }}>
            {agent && agent.first_name} {agent && agent.last_name}
          </span>
          . We've sent an email to{" "}
          <span style={{ fontWeight: 700, color: Colors.BrandPrimary }}>
            {agent && agent.email}
          </span>
          . Open it up and activate your account.
        </Typography>
        <Typography variant="body1">We'll take it from there.</Typography>
      </Box>
    </HeroLayout>
  );
}
