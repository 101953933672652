import React from "react";
import { Box, Typography } from "@material-ui/core";
import Header from "../../components/Dashboard/Header";
import { Colors } from "../../constants/colors";
import { useMobile } from "../../hooks/mobile";
import { useSessionStore } from "../../stores/Session";
import {
  User,
  UserType,
  DashboardData,
  ContractPublishStatus,
  // DashboardContract,
  workspaceProgressForRecord,
  WorkspaceProgress,
  LinkVerify,
  isRecordPaid,
} from "../../api/models";
import SegmentedControl from "../../components/common/SegmentedControl";
import Button, { ButtonType, ButtonSize } from "../../components/common/Button";
import DashboardItem, {
  DashboardItemType,
} from "../../components/Dashboard/DashboardItem";
import SearchField from "../../components/common/SearchField";
import Dropdown, {
  DropdownOption,
  DropdownViewType,
} from "../../components/common/Dropdown";
// import Switch from "../../components/common/Switch";
import useSWR from "swr";
// import useSWRImmutable from "swr/immutable";
import { useHistory } from "react-router-dom";
import ShareLinkDialog from "../../components/Dashboard/ShareLinkDialog";
// import moment from "moment";
import PlaceholderIcon from "../../images/dashboard-placeholder-icon.svg";
import { isEmpty, isNil } from "lodash-es";
import AddPropertyModal from "../../components/Dashboard/AddPropertyModal";
import CheckYourEmailAndVerifyModal from "../../components/Dashboard/CheckYourEmailAndVerifyModal";
import { AxiosError } from "axios";
import FirmModal from "../../components/Dashboard/FirmModal";
import { useLocation } from "react-router-dom";
import AlertWrapper from "../../components/AlertWrapper";
import HelpGuideModal from "../../components/HelpGuide/HelpGuideModal";
import HelpGuideModalItem from "../../components/HelpGuide/HelpGuideModalItem";
import BLHelp1 from "../../images/uploadHelpGuide.png";
import BLHelp2 from "../../images/inviteModalHelpGuide.png";
import BLHelp3 from "../../images/colorHelpGuide.png";
import FirmSignUpModal from "../../components/Invited/FirmSignUpModal";
import AdminSignUpModal from "../../components/Invited/AdminSignUpModal";
import { useAlert } from "../../contexts/Alert";
import PropagateLoader from "react-spinners/PropagateLoader";
// import { useCreditStore } from "../../stores/credit";
import LoginModal from "../../components/Invited/LoginModal";
import api from "../../api/instance";
import { error } from "console";

enum SortBy {
  Select,
  DateCreated,
  NumberOfBuyers,
  Progress,
}

const sortByDropdownOptions: DropdownOption[] = [
  {
    title: "Select",
    value: SortBy.Select,
  },
  {
    title: "Date created",
    value: SortBy.DateCreated,
  },

  {
    title: "Number of buyers",
    value: SortBy.NumberOfBuyers,
  },

  {
    title: "Progress",
    value: SortBy.Progress,
  },
];

const progressStatuses = [
  ContractPublishStatus.Pending,
  ContractPublishStatus.Published,
  ContractPublishStatus.Sold,
];

interface PlaceholderProps {
  onAddProperty: () => unknown;
  onReset: () => unknown;
  isBuyer: boolean;
  isPartner: boolean;
}

function Placeholder({
  onAddProperty,
  onReset,
  isBuyer,
  isPartner,
}: PlaceholderProps) {
  const isMobile = useMobile();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt="100px">
      <img src={PlaceholderIcon} width="167px" height="167px" alt="phi"/>
      <Typography style={{ marginTop: "30px" }} variant="h6">
        There are no results
      </Typography>

      {!isBuyer && !isPartner && (
        <Box
          mt="30px"
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
        >
          <Button
            type={ButtonType.primary}
            size={ButtonSize.medium}
            title="ADD PROPERTY"
            width="215px"
            onClick={onAddProperty}
          />
          <Box width="11px" height="12px" />
          <Button
            type={ButtonType.secondary}
            size={ButtonSize.medium}
            title="RESET SEARCH"
            width="215px"
            onClick={onReset}
          />
        </Box>
      )}
    </Box>
  );
}

export default function Dashboard() {
  const isMobile = useMobile();
  const history = useHistory();
  const location = useLocation();

  const sidePadding = isMobile ? "27px" : "172px";

  const query = new URLSearchParams(location.search);

  const joinOrg = query.get("joinOrg");
  const orgUuid = query.get("orgUuid");

  const token = query.get("token");
  const firstName = query.get("first_name");
  const lastName = query.get("last_name");
  const orgName = query.get("organisation_name");
  const isNew = query.get("is_new");
  const orgId = query.get("organization_id");
  const link_token = query.get("link_token");
  const dashboard = query.get("dashboard");

  const { data: userData, refresh: refreshUserData } = useSessionStore();
  const user = userData && (userData as User);

  // const { refreshCredits } = useCreditStore();

  const [selectedValue, setSelectedValue] = React.useState<string>("buying");

  const [sortBy, setSortBy] = React.useState<SortBy>(SortBy.Select);
  const [search, setSearch] = React.useState("");
  // const [hideSold, setHideSold] = React.useState(false);
  // const [hideArchived, setHideArchived] = React.useState(false);
  const [dashboardData, setDashboardData] = React.useState<DashboardData | null>(null);

  const [limit, setLimit] = React.useState(50);
  const [offset, setOffset] = React.useState(0);
  const [limitSort, setLimitSort] = React.useState(50);
  const [limitSearch, setLimitSearch] = React.useState(50);

  const fetchDashboardData = () => {
    // console.log(selectedValue, "SVFDD");
    const offsetOld = 0;
    if((selectedValue === "buying" || selectedValue === "selling") && (sortBy === 0)) {
      api
        .get(
          `buying-contracts-v2/?limit=${limit}&offset=${offsetOld}&tab=${
            selectedValue === "buying" ? "buying" : "selling"
          }`
        )
        .then((response) => {
          setDashboardData(response.data as DashboardData);
        })
        .catch((error) => {
          if (error) {
            const axiosError = error as AxiosError;
            if (
              axiosError.response?.status === 403 &&
              !showFirmSignUpModal &&
              !showFirmModal &&
              !showAdminInvitedSignUpModal
            ) {
              setCheckYourEmailOpen(true);
            } else {
              setCheckYourEmailOpen(false);
            }
          } else {
            setCheckYourEmailOpen(false);
          }
        });
    }
  };

  React.useEffect(() => {
    fetchDashboardData();
    // console.log(selectedValue, "SV");
    
  }, [selectedValue, sortBy === 0]);

  // const { data, error } = useSWR(
  //   `buying-contracts-v2/?limit=${limit}&offset=${offset}&tab=${
  //     selectedValue === "buying" ? "buying" : "selling"
  //   }`,
  //   {
  //     revalidateIfStale: true,
  //     revalidateOnFocus: false,
  //     revalidateOnReconnect: true,
  //   }
  // );
  
  // const parsedDashboardData: DashboardData | null = data
  //   ? (data as DashboardData)
  //   : null;

  //   React.useEffect(() => {
  //     if (parsedDashboardData) {
  //       setDashboardData(parsedDashboardData);
  //       console.log("yep...");
        
  //     }
  //   }, [parsedDashboardData]);

  const { data: userTokenData } =
    // useSWR(`/link-token-verify/${link_token}/`);
    useSWR(() => {
      if (!isEmpty(link_token)) {
        return `/link-token-verify/${link_token}/`;
      } else {
        return null;
      }
    });
  const token_data = userTokenData ? (userTokenData as LinkVerify) : undefined;

  const isBuyer = user?.user_type === UserType.Buyer;
  const isLawyer = user?.user_type === UserType.Solicitor;
  const isAgent = user?.user_type === UserType.Agent;
  const isPartner = user?.user_type === UserType.Partner;
  const isInternalUser = user?.is_internal_user;

  const [shareLinkContractId, setShareLinkContractId] = React.useState<
    number | null
  >(null);
  const [shareLinkOpen, setShareLinkOpen] = React.useState(false);

  const [addPropertyModalOpen, setAddPropertyModalOpen] = React.useState(false);

  const [checkYourEmailOpen, setCheckYourEmailOpen] = React.useState(false);

  const [firmModalOpen, setFirmModalOpen] = React.useState(false);
  const [firmSignUpModalOpen, setFirmSignUpModalOpen] = React.useState(false);
  const [adminInvitedSignUpModalOpen, setAdminInvitedSignUpModalOpen] =
    React.useState(false);

  const { setErrorMessage, setSuccessMessage } = useAlert();

  const showFirmSignUpModal =
    !isEmpty(token) &&
    !isEmpty(firstName) &&
    !isEmpty(lastName) &&
    !isEmpty(orgName) &&
    isNew === "true";

  React.useEffect(() => {
    if (showFirmSignUpModal) {
      setFirmSignUpModalOpen(true);
    }
  }, []);

  // Show Join/Reject Firm Modal based on active email links
  const showFirmModal =
    !isEmpty(orgUuid) &&
    joinOrg === "true" &&
    isNew === "false" &&
    token_data?.status === true;

  React.useEffect(() => {
    if (showFirmModal) {
      setFirmModalOpen(true);
    }
  }, []);

  // Show Admin Invited User signup modal based on new user accounts
  const showAdminInvitedSignUpModal =
    !isEmpty(token) &&
    !isEmpty(firstName) &&
    !isEmpty(lastName) &&
    dashboard === "true" &&
    isNew === "true";

  React.useEffect(() => {
    if (showAdminInvitedSignUpModal) {
      setAdminInvitedSignUpModalOpen(true);
    }
  }, []);

  const redirectAdminInvitedUser =
    !isEmpty(token) &&
    !isEmpty(firstName) &&
    !isEmpty(lastName) &&
    !isEmpty(orgId) &&
    dashboard === "true" &&
    isNew === "true" &&
    token_data?.status === false;

  React.useEffect(() => {
    if (redirectAdminInvitedUser) {
      history.push("/login");
    }
  }, []);

  // Show error message if the same email link is used again
  const showErrorMessage =
    !isEmpty(link_token) &&
    !isEmpty(orgUuid) &&
    joinOrg === "true" &&
    isNew === "false" &&
    token_data?.status === false;

  React.useEffect(() => {
    if (showErrorMessage) {
      setErrorMessage("The link you followed has expired");
    }
  }, [showErrorMessage]);

  const [helpModalOpen, setHelpModalOpen] = React.useState(false);

  const _helpModalTitle = () => {
    return "How to use My Team";
  };

  const _helpModalItems = () => {
    return (
      <>
        <HelpGuideModalItem
          index={1}
          title="Search for a property, upload the contract and create your workspace"
          image={BLHelp1}
          imageMarginTop="32px"
        />
        <Box width="6px" />
        <HelpGuideModalItem
          index={2}
          title="Invite your team to Contrax"
          image={BLHelp2}
          imageMarginTop="32px"
        />
        <Box width="6px" />
        <HelpGuideModalItem
          index={3}
          title="Personalise your workspace and landing page with your logo and brand colour"
          image={BLHelp3}
          imageMarginTop="32px"
        />
      </>
    );
  };

  const helpModalTitle = _helpModalTitle();
  const helpModalItems = _helpModalItems();

  // React.useEffect(() => {
  //   if (error) {
  //     const axiosError = error as AxiosError;
  //     if (
  //       axiosError.response?.status === 403 &&
  //       !showFirmSignUpModal &&
  //       !showFirmModal &&
  //       !showAdminInvitedSignUpModal
  //     ) {
  //       setCheckYourEmailOpen(true);
  //     } else {
  //       setCheckYourEmailOpen(false);
  //     }
  //   } else {
  //     setCheckYourEmailOpen(false);
  //   }
  // }, [error]);

  React.useEffect(() => {
    if (joinOrg === "true" && !isEmpty(orgUuid)) {
      setFirmModalOpen(true);
    }
  }, []);

  // Custom Loader
  const [loading, setLoading] = React.useState(true);

  // const sorter = (first: DashboardContract, second: DashboardContract) => {
  //   if (sortBy === SortBy.DateCreated) {
  //     const secondDate = second.record.created || second.listing.created;
  //     const firstDate = first.record.created || first.listing.created;

  //     return moment(secondDate).isAfter(moment(firstDate)) ? 1 : -1;
  //   }

  //   if (sortBy === SortBy.NumberOfBuyers) {
  //     return second.buyer_count > first.buyer_count ? 1 : -1;
  //   }

  //   if (sortBy === SortBy.Progress) {
  //     return progressStatuses.indexOf(second.publish_status) >
  //       progressStatuses.indexOf(first.publish_status)
  //       ? 1
  //       : -1;
  //   }

  //   return 0;
  // };

  // const searchLowercased = search.toLowerCase();

  // const searchFilter = (contract: DashboardContract) =>
  //   contract.listing.address.street.toLowerCase().includes(searchLowercased) ||
  //   contract.listing.address.city.toLowerCase().includes(searchLowercased) ||
  //   contract.listing.address.state.toLowerCase().includes(searchLowercased) ||
  //   String(contract.listing.address.postal_code)
  //     .toLowerCase()
  //     .includes(searchLowercased);

  // const hideSoldFilter = (contract: DashboardContract) => {
  //   if (hideSold) {
  //     return contract.publish_status !== ContractPublishStatus.Sold;
  //   }

  //   return contract;
  // };

  // const hideArchivedFilter = (contract : DashboardContract) => {
  //   if (hideArchived) {
  //     return contract.is_archive;
  //   }
  //   else {
  //   return contract;
  //   }
  // }

  const buying = () => {
    if (dashboardData) {
      return dashboardData.results;
        // .filter(hideSoldFilter)
        // .filter(hideArchivedFilter)
        // .filter(searchFilter)
        // .sort(sorter);
    }

    return [];
  };

  const selling = () => {
    if (dashboardData) {
      return dashboardData.results;
        // .filter(hideSoldFilter)
        // .filter(hideArchivedFilter)
        // .filter(searchFilter)
        // .sort(sorter);
    }

    return [];
  };

  const [loginModalOpen, setLoginModalOpen] = React.useState(false);
  const [signUpModalOpen, setSignUpModalOpen] = React.useState(false);

  const signUpModalOnClose = () => setSignUpModalOpen(false);
  const signUpModalOnSignedUp = () => {
    setSignUpModalOpen(false);
    refreshUserData();
  };
  const signUpModalOnLogin = () => {
    setAdminInvitedSignUpModalOpen(false);
    setLoginModalOpen(true);
  };

  function MySegmentedControl() {
    if (isAgent || isPartner) {
      return <></>;
    }

    return (
      <Box width={isMobile ? "100%" : "240px"}>
        <SegmentedControl
          options={tabOptions()}
          selectedValue={selectedValue}
          onSelectedValue={(value) => {
            setSelectedValue(value);
            localStorage.setItem("sv", value);  
          }}
        />
      </Box>
    );
  }

  function tabOptions() {
    return [
      {
        value: "buying",
        title: "Buying",
      },
      {
        value: "selling",
        title: "Selling",
      },
    ];
  }

  function TitleBar() {
    if (isMobile) {
      return (
        <Box
          display="flex"
          flexDirection="row"
          bgcolor={Colors.BrandBackground2}
          pl={sidePadding}
          pr={sidePadding}
          justifyContent="center"
          alignItems="center"
        >
          <Box flex={1}>
            <Box
              flex={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              minHeight="86px"
            >
              <Typography
                variant="h4"
                style={{ color: Colors.Grey1, fontWeight: 400 }}
              >
                My Dashboard
              </Typography>
            </Box>

            {!isAgent && !isBuyer ? (
              <Box>
                <Button
                  width="100%"
                  type={ButtonType.primary}
                  size={ButtonSize.medium}
                  title="ADD PROPERTY"
                  onClick={onAddProperty}
                />
              </Box>
            ) : (
              <Box>
                <Button
                  width="100%"
                  type={ButtonType.primary}
                  size={ButtonSize.medium}
                  title="PROPERTY SEARCH"
                  onClick={onPropertySearch}
                />
              </Box>
            )}

            <Box height="20px" />

            <MySegmentedControl />
          </Box>
        </Box>
      );
    }

    return (
      <Box
        display="flex"
        flexDirection="row"
        bgcolor={Colors.BrandBackground2}
        pl={sidePadding}
        pr={sidePadding}
        justifyContent="center"
        alignItems="center"
      >
        <Box flex={1}>
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            minHeight="108px"
          >
            <Typography
              variant="h4"
              style={{ color: Colors.Grey1, fontWeight: 400 }}
            >
              My Dashboard
            </Typography>
          </Box>

          <MySegmentedControl />
        </Box>

        {isLawyer && (
          <Box mr="8px">
            <Button
              type={ButtonType.white}
              size={ButtonSize.medium}
              title="HELP GUIDE"
              onClick={() => setHelpModalOpen(true)}
            />
          </Box>
        )}

        {isLawyer && isInternalUser && (
          <Box>
            <Button
              type={ButtonType.primary}
              size={ButtonSize.medium}
              title="ADD PROPERTY"
              onClick={onAddProperty}
            />
          </Box>
        )}

        {isAgent && (
          <Box>
            <Button
              type={ButtonType.primary}
              size={ButtonSize.medium}
              title="ADD PROPERTY"
              onClick={onAddProperty}
            />
          </Box>
        )}
      </Box>
    );
  }

  const showPlaceholder = () => {
    if (isNil(dashboardData)) {
      return false;
    }

    if (selectedValue === "buying") {
      return isEmpty(buying());
    }
    if (selectedValue === "selling") {
      return isEmpty(selling());
    }

    return false;
  };

  function onAddProperty() {
    if (isAgent) {
      history.push("/agent/add-property");
    } else {
      setAddPropertyModalOpen(true);
    }
  }

  function onPropertySearch() {
    if (isAgent) {
      history.push("/agent/add-property");
    } else {
      window.open(window.location.origin + "/search-properties", "_self");
    }
  }

  const handleLoadMore = () => {
    setLoading(false);
    const newOffSet = offset + 50;
      api
        .get(
          `buying-contracts-v2/?limit=${limit}&offset=${newOffSet}&tab=${
            selectedValue === "buying" ? "buying" : "selling"
          }`
        )
        .then((response) => {
          setLoading(true);
          const updatedDashboardDataLoadMore = response.data as DashboardData;
          // setDashboardData(updatedDashboardDataLoadMore);
          setDashboardData((prevDashboardData) => {
            if (prevDashboardData) {
              return {
                ...prevDashboardData,
                // Merge the old data with the new offset data
                results: [
                  ...prevDashboardData.results,
                  ...updatedDashboardDataLoadMore.results,
                ],
              };
            } else {
              return updatedDashboardDataLoadMore;
            }
          });
          setOffset(newOffSet);
        })
        .catch((error) => {
          setErrorMessage(error.message);
        });
  };

  const handleLoadMoreSortBy = () => {
    setLoading(false);
    const newOffSet = offset + 50;
    api
      .get(
        `buying-contracts-v2/?limit=${limit}&offset=${newOffSet}&tab=${
          selectedValue === "buying" ? "buying" : "selling"
        }&sort_by=${
          sortBy === 1 ? "date" : sortBy === 2 ? "buyer_count" : "in_progress"
        }`
      )
      .then((response) => {
        setLoading(true);
        const updatedDashboardDataLoadMoreSortBy =
          response.data as DashboardData;
        setDashboardData((prevDashboardDataSortBy) => {
          if (prevDashboardDataSortBy) {
            return {
              ...prevDashboardDataSortBy,
              // Merge the old data with the new offset data
              results: [
                ...prevDashboardDataSortBy.results,
                ...updatedDashboardDataLoadMoreSortBy.results,
              ],
            };
          } else {
            return updatedDashboardDataLoadMoreSortBy;
          }
        });
        setOffset(newOffSet);
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };

  const handleLoadMoreSearch = () => {
    setLoading(false);
    const newOffSet = offset + 50;
    api
      .get(
        `buying-contracts-v2/?limit=${limit}&offset=${newOffSet}&tab=${
          selectedValue === "buying" ? "buying" : "selling"
        }&query=${search}`
      )
      .then((response) => {
        setLoading(true);
        const updatedDashboardDataLoadMoreSearch =
          response.data as DashboardData;
        setDashboardData((prevDashboardDataMoreSearch) => {
          if (prevDashboardDataMoreSearch) {
            return {
              ...prevDashboardDataMoreSearch,
              // Merge the old data with the new offset data
              results: [
                ...prevDashboardDataMoreSearch.results,
                ...updatedDashboardDataLoadMoreSearch.results,
              ],
            };
          } else {
            return updatedDashboardDataLoadMoreSearch;
          }
        });
        setOffset(newOffSet);
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }

  const fetchSortByDashboardData = () => {
    const offsetOldSort = 0;
    if (
      (selectedValue === "buying" || selectedValue === "selling") &&
      (sortBy === 1 || sortBy === 2 || sortBy === 3)
    ) {
      api
        .get(
          `buying-contracts-v2/?limit=${limitSort}&offset=${offsetOldSort}&tab=${
            selectedValue === "buying" ? "buying" : "selling"
          }&sort_by=${
            sortBy === 1 ? "date" : sortBy === 2 ? "buyer_count" : "in_progress"
          }`
        )
        .then((response) => {
          // Assuming response.data contains the contract from the API
          const updatedDashboardData = response.data as DashboardData;
          setDashboardData(updatedDashboardData);
        })
        .catch((error) => {
          setErrorMessage(error.message);
        });
    }
  };

  React.useEffect(() => {
    fetchSortByDashboardData();
    setOffset(0);
  }, [selectedValue, sortBy === 1, sortBy === 2, sortBy === 3]);

  const fetchMoreSearchDashboardData = () => {
    const offsetOldSort = 0;
    if (
      (selectedValue === "buying" || selectedValue === "selling") &&
      search &&
      (sortBy === 0 || sortBy === 1 || sortBy === 2 || sortBy === 3)
    ) {
      let url = `buying-contracts-v2/?limit=${limitSearch}&offset=${offsetOldSort}&tab=${
        selectedValue === "buying" ? "buying" : "selling"
      }&query=${search}`;

      if (sortBy === 1 || sortBy === 2 || sortBy === 3) {
        url += `&sort_by=${
          sortBy === 1 ? "date" : sortBy === 2 ? "buyer_count" : "in_progress"
        }`;
      }
      api
        .get(url)
        .then((response) => {
          // Assuming response.data contains the contract from the API
          const updatedDashboardDataSearch = response.data as DashboardData;
          setDashboardData(updatedDashboardDataSearch);
        })
        .catch((error) => {
          setErrorMessage(error.message);
        });
    }
  };

  React.useEffect(() => {
    fetchMoreSearchDashboardData();
    setOffset(0);
  }, [search, selectedValue, sortBy]);

  return (
    <AlertWrapper>
      <Box>
        <Header />
        <TitleBar />
        {isMobile && (isLawyer || isAgent || isPartner) && (
          <Box mt="12px" ml="11px" mr="11px" display="flex" flexDirection="row">
            <SearchField
              value={search}
              onTextChanged={(value) => {
                setSearch(value);
                fetchMoreSearchDashboardData();
                // const selval = localStorage.getItem("sv");
                // const tab = selval === "buying" ? "buying" : "selling";
                // api
                //   .get(
                //     `buying-contracts-v2/?limit=${limitSearch}&offset=${offset}&tab=${tab}&query=${value}`
                //   )
                //   .then((response) => {
                //     // Assuming response.data contains the contract from the API
                //     const updatedDashboardDataSearch =
                //       response.data as DashboardData;
                //     setDashboardData(updatedDashboardDataSearch);
                //   })
                //   .catch((error) => {
                //     setErrorMessage(error.message);
                //   });
              }}
            />
            <Box width="4px" />
            <Dropdown
              width="50px"
              title="Sort by:"
              viewType={DropdownViewType.Icon}
              options={sortByDropdownOptions}
              onSelectedOption={(option) => {
                setSortBy(option.value);
                fetchSortByDashboardData();
                // const offsetOldSort = 0;
                // api
                //   .get(
                //     `buying-contracts-v2/?limit=${limitSort}&offset=${offsetOldSort}&tab=${
                //       selectedValue === "buying" ? "buying" : "selling"
                //     }&sort_by=${
                //       option.value === 0
                //         ? "date"
                //         : option.value === 1
                //         ? "buyer_count"
                //         : "in_progress"
                //     }`
                //   )
                //   .then((response) => {
                //     // Assuming response.data contains the contract from the API
                //     const updatedDashboardData = response.data as DashboardData;
                //     setDashboardData(updatedDashboardData);
                //   })
                //   .catch((error) => {
                //     setErrorMessage(error.message);
                //   });
              }}
              selectedOption={sortByDropdownOptions.find(
                (option) => option.value === sortBy
              )}
              // footer={
              //   <Box
              //     pl="16px"
              //     pr="16px"
              //     display="flex"
              //     flexDirection="row"
              //     alignItems="center"
              //     height="56px"
              //     boxSizing="border-box"
              //   >
              //     <Switch
              //       checked={hideArchived}
              //       onChange={(event) => setHideArchived(event.target.checked)}
              //     />
              //     <Typography variant="body2" style={{ marginLeft: "8px" }}>
              //       Show Archive
              //     </Typography>
              //     <Switch
              //       checked={hideSold}
              //       onChange={(event) => setHideSold(event.target.checked)}
              //     />
              //     <Typography variant="body2" style={{ marginLeft: "8px" }}>
              //       Hide sold
              //     </Typography>
              //   </Box>
              // }
            />
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt="25px"
        >
          {!isMobile && (isLawyer || isAgent || isPartner) && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              width="1095px"
              mb="8px"
            >
              <SearchField
                value={search}
                onTextChanged={(value) => {
                  setSearch(value);
                  // const options = tabOptions();
                  // const firstOptionValue = options[0].value;
                  if (value) {
                    fetchMoreSearchDashboardData();
                  } else {
                    // setSelectedValue("selling");
                    // console.log("...");
                    fetchDashboardData();
                  }
                }}
              />
              <Box display="flex" flexDirection="row" alignItems="center">
                <Dropdown
                  title="Sort by:"
                  options={sortByDropdownOptions}
                  onSelectedOption={(option) => {
                    setSortBy(option.value);
                    fetchSortByDashboardData();
                  }}
                  selectedOption={sortByDropdownOptions.find(
                    (option) => option.value === sortBy
                  )}
                  width="280px"
                />

                {/* <Box
                  pl="16px"
                  pr="16px"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  height="56px"
                  boxSizing="border-box"
                > */}
                {/* <Switch
                    checked={hideArchived}
                    onChange={(event) => setHideArchived(event.target.checked)}
                  />
                  <Typography variant="body2" style={{ marginLeft: "8px" }}>
                    Show Archive
                  </Typography> */}
                {/* <Switch
                    checked={hideSold}
                    onChange={(event) => setHideSold(event.target.checked)}
                  />
                  <Typography variant="body2" style={{ marginLeft: "8px" }}>
                    Hide sold
                  </Typography> */}
                {/* </Box> */}
              </Box>
            </Box>
          )}

          {dashboardData === null ? (
            <Box position="fixed" top={"65%"}>
              <PropagateLoader
                color={Colors.BrandPrimary}
                loading={loading}
                size={20}
              />
            </Box>
          ) : (
            selectedValue === "buying" &&
            buying()?.map((contract) => (
              <DashboardItem
                type={DashboardItemType.Buying}
                contract={contract}
                onClick={() => {
                  if (isBuyer) {
                    if (contract.record && isRecordPaid(contract.record)) {
                      const progress = workspaceProgressForRecord(
                        contract.record
                      );

                      if (
                        progress === WorkspaceProgress.Contract ||
                        progress === WorkspaceProgress.LegalReview
                      ) {
                        history.push(
                          `/legal-review-viewer?contractId=${contract.id}&recordId=${contract.record.pk}`
                        );
                      } else if (progress === WorkspaceProgress.Requests) {
                        history.push(
                          `/request-workspace?contractId=${contract.id}&recordId=${contract.record.pk}`
                        );
                      }
                    } else if (
                      contract.invited_by === true &&
                      contract.buyer_invite_lawyer === false &&
                      contract.seller_lawyer_invite_buyer === false &&
                      contract.buyer_invite_buyer === false
                    ) {
                      history.push(
                        `/legal-review-buyer?contractId=${contract.id}&recordId=${contract.record.pk}`
                      );
                    } else if (
                      contract.invited_by === true &&
                      contract.buyer_invite_lawyer === true &&
                      contract.seller_lawyer_invite_buyer === false &&
                      contract.buyer_invite_buyer === true
                    ) {
                      history.push(
                        `/buyer-invited-workspace?contractId=${contract.id}&recordId=${contract.record.pk}`
                      );
                    } else if (
                      contract.seller_lawyer_invite_buyer === true &&
                      contract.buyer_invite_lawyer === false &&
                      contract.invited_by === false
                    ) {
                      history.push(
                        `/legal-review-buyer?contractId=${contract.id}&recordId=${contract.record.pk}`
                      );
                    } else if (
                      contract.seller_lawyer_invite_buyer === true &&
                      contract.buyer_invite_lawyer === true &&
                      contract.invited_by === false
                    ) {
                      history.push(
                        `/buyer-invited-workspace?contractId=${contract.id}&recordId=${contract.record.pk}`
                      );
                    } else if (
                      contract.seller_lawyer_invite_buyer === false &&
                      contract.buyer_invite_lawyer === false &&
                      contract.invited_by === false &&
                      contract.buyer_invite_buyer === false
                    ) {
                      history.push(
                        `/buyer-invited-workspace?contractId=${contract.id}&recordId=${contract.record.pk}`
                      );
                    } else if (
                      contract.seller_lawyer_invite_buyer === false &&
                      contract.buyer_invite_lawyer === false &&
                      contract.invited_by === false &&
                      contract.buyer_invite_buyer === true
                    ) {
                      history.push(
                        `/legal-review-buyer?contractId=${contract.id}&recordId=${contract.record.pk}`
                      );
                    } else if (
                      contract.seller_lawyer_invite_buyer === false &&
                      contract.buyer_invite_lawyer === true &&
                      contract.invited_by === false &&
                      contract.buyer_invite_buyer === true
                    ) {
                      history.push(
                        `/buyer-invited-workspace?contractId=${contract.id}&recordId=${contract.record.pk}`
                      );
                    } else if (
                      contract.seller_lawyer_invite_buyer === false &&
                      contract.buyer_invite_lawyer === true &&
                      contract.invited_by === true &&
                      contract.buyer_invite_buyer === false
                    ) {
                      history.push(
                        `/buyer-invited-workspace?contractId=${contract.id}&recordId=${contract.record.pk}`
                      );
                    } else if (
                      contract.seller_lawyer_invite_buyer === false &&
                      contract.buyer_invite_lawyer === false &&
                      contract.invited_by === true &&
                      contract.buyer_invite_buyer === true
                    ) {
                      history.push(
                        `/legal-review-buyer?contractId=${contract.id}&recordId=${contract.record.pk}`
                      );
                    } else {
                      // No record yet or record not paid, redirect to property details page
                      // history.push(
                      //   `/property-info?propertyId=${contract.listing.pk}`
                      // );
                      history.push(
                        `/legal-review-buyer?contractId=${contract.id}&recordId=${contract.record.pk}`
                      );
                    }
                  } else if (isAgent) {
                    history.push(`/property-details?contractId=${contract.id}`);
                  } else {
                    history.push(
                      `/property-details?contractId=${contract.id}&type=buying`
                    );
                  }
                }}
                onShareLink={() => {
                  setShareLinkContractId(contract.id);
                  setShareLinkOpen(true);
                }}
              />
            ))
          )}

          {dashboardData === null ? (
            <Box position="fixed" top={"65%"}>
              <PropagateLoader
                color={Colors.BrandPrimary}
                loading={loading}
                size={20}
              />
            </Box>
          ) : (
            selectedValue === "selling" &&
            selling()?.map((contract) => (
              <DashboardItem
                type={DashboardItemType.Selling}
                contract={contract}
                onClick={() =>
                  history.push(
                    `/property-details?contractId=${contract.id}&type=selling`
                  )
                }
                onShareLink={() => {
                  setShareLinkContractId(contract.id);
                  setShareLinkOpen(true);
                }}
              />
            ))
          )}

          {showPlaceholder() && (
            <Placeholder
              onAddProperty={onAddProperty}
              onReset={() => {
                setSearch("");
              }}
              isBuyer={isBuyer}
              isPartner={isPartner}
            />
          )}
        </Box>
        {dashboardData &&
          dashboardData.count >= 50 &&
          dashboardData.count >= offset &&
          !sortBy && (
            <Box
              position="fixed"
              bottom="0"
              left="0"
              right="0"
              display="flex"
              justifyContent="center"
              padding={2}
            >
              <Button
                width={isMobile ? "100%" : "12%"}
                type={ButtonType.secondary}
                size={ButtonSize.medium}
                title={loading ? "LOAD MORE" : "LOADING"}
                titleColor={Colors.BrandPrimary}
                onClick={handleLoadMore}
              />
            </Box>
          )}
        {dashboardData &&
          sortBy &&
          dashboardData.count >= 50 &&
          dashboardData.count >= offset && (
            <Box
              position="fixed"
              bottom="0"
              left="0"
              right="0"
              display="flex"
              justifyContent="center"
              padding={2}
            >
              <Button
                width={isMobile ? "100%" : "12%"}
                type={ButtonType.secondary}
                size={ButtonSize.medium}
                title={loading ? "LOAD MORE" : "LOADING"}
                titleColor={Colors.BrandPrimary}
                onClick={handleLoadMoreSortBy}
              />
            </Box>
          )}
        {dashboardData &&
          search &&
          dashboardData.count >= 50 &&
          dashboardData.count >= offset && (
            <Box
              position="fixed"
              bottom="0"
              left="0"
              right="0"
              display="flex"
              justifyContent="center"
              padding={2}
            >
              <Button
                width={isMobile ? "100%" : "12%"}
                type={ButtonType.secondary}
                size={ButtonSize.medium}
                title={loading ? "LOAD MORE" : "LOADING"}
                titleColor={Colors.BrandPrimary}
                onClick={handleLoadMoreSearch}
              />
            </Box>
          )}
      </Box>

      <ShareLinkDialog
        contractId={shareLinkContractId}
        open={shareLinkOpen}
        onClose={() => setShareLinkOpen(false)}
      />

      <HelpGuideModal
        open={helpModalOpen}
        title={helpModalTitle}
        onClose={() => setHelpModalOpen(false)}
        items={helpModalItems}
      />

      <AddPropertyModal
        open={addPropertyModalOpen}
        onClose={() => setAddPropertyModalOpen(false)}
        onBuyer={() => history.push("/search-property?role=buying")}
        onSeller={() => history.push("/search-property?role=selling")}
      />

      <CheckYourEmailAndVerifyModal
        open={checkYourEmailOpen}
        email={user?.email}
        onContactSupport={() =>
          window.open(window.location.origin + "/contact-us", "_blank")?.focus()
        }
        onClose={() => setCheckYourEmailOpen(false)}
      />

      {showFirmModal && orgUuid && (
        <FirmModal
          open={firmModalOpen}
          onClose={() => setFirmModalOpen(false)}
          orgUuid={orgUuid}
        />
      )}

      {showFirmSignUpModal && (
        <FirmSignUpModal
          open={firmSignUpModalOpen}
          onClose={() => setFirmSignUpModalOpen(false)}
          inviterName={`${firstName} ${lastName}`}
          inviterFirmName={orgName}
          token={token}
          orgId={orgId}
          onSignedUp={() => {
            refreshUserData();
            setSuccessMessage(`You have joined ${orgName}`);
            history.push("/dashboard");
          }}
        />
      )}

      {showAdminInvitedSignUpModal && (
        <>
          <AdminSignUpModal
            open={adminInvitedSignUpModalOpen}
            onClose={() => setAdminInvitedSignUpModalOpen(false)}
            inviterName={`${firstName} ${lastName}`}
            inviterFirmName={orgName}
            token={token}
            orgId={orgId}
            onSignedUp={() => {
              refreshUserData();
              setSuccessMessage(`Welcome to Contrax`);
              history.push("/dashboard");
            }}
            onLogin={signUpModalOnLogin}
          />

          <LoginModal
            open={loginModalOpen}
            onLoggedIn={() => {
              setLoginModalOpen(false);
              setAdminInvitedSignUpModalOpen(false);
              refreshUserData(false);
            }}
            inviterName={`${firstName} ${lastName}`.trim()}
            onCancel={() => setLoginModalOpen(false)}
          />
        </>
      )}
    </AlertWrapper>
  );
}
