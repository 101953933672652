import React from "react";
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import {
  addressForProperty,
  Contract,
  Property,
  ContractSummary,
  TeamMemberRole,
} from "../../api/models";
import moment from "moment";
import { isEmpty } from "lodash-es";
import { useSessionStore } from "../../stores/Session";
import BLUELOGO from "../../images/BlueLogo.png";

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "50px",
    paddingBottom: 105,
  },

  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: "50px",
    right: "50px",
    textAlign: "center",
    color: "#000000",
    fontFamily: "GT Walsheim Pro",
    fontWeight: 400,
  },
});

interface Props {
  summaries: ContractSummary[];
  property: Property;
  contract: Contract;
  contractS: Contract;
  recordId: number;
  userTeamMemberRole: TeamMemberRole;
  comments: boolean;
  legalReviewForName: string;
}

export default function PDFDocument({
  summaries,
  property,
  contract,
  contractS,
  recordId,
  userTeamMemberRole,
  comments,
  legalReviewForName,
}: Props) {
  const { data: user } = useSessionStore();

  const logo = () => {
    if (contract) {
      const { logo, company_title, company_pk } = contract;
      if (
        userTeamMemberRole === TeamMemberRole.buyer ||
        userTeamMemberRole === TeamMemberRole.buyerLawyer ||
        userTeamMemberRole === TeamMemberRole.partner ||
        user?.current_organization?.pk === contract?.company_pk ||
        user?.current_organization?.pk === contract?.partner_company_pk
      ) {
        if (logo) {
          return (
            <Image
              src={{
                uri: logo,
                method: "GET",
                headers: {
                  "Cache-Control": "no-cache",
                },
                body: "",
              }}
              style={{
                maxWidth: "100%",
                objectFit: "contain",
                height: "40px",
              }}
            />
          );
        }

        if (company_title) {
          return (
            <Text
              style={{
                fontFamily: "GT Walsheim Pro",
                fontWeight: 700,
                fontSize: "24px",
              }}
            >
              {company_title}
            </Text>
          );
        }
      }
    }

    if (contractS) {
      const { logo, company_title, company_pk } = contractS;

      if (
        userTeamMemberRole === TeamMemberRole.seller ||
        userTeamMemberRole === TeamMemberRole.sellerLawyer ||
        user?.current_organization?.pk === contractS?.company_pk
      ) {
        if (logo) {
          return (
            <Image
              src={{
                uri: logo,
                method: "GET",
                headers: {
                  "Cache-Control": "no-cache",
                },
                body: "",
              }}
              style={{
                maxWidth: "100%",
                objectFit: "contain",
                height: "40px",
              }}
            />
          );
        }

        if (company_title) {
          return (
            <Text
              style={{
                fontFamily: "GT Walsheim Pro",
                fontWeight: 700,
                fontSize: "24px",
              }}
            >
              {company_title}
            </Text>
          );
        }
      }
    }

    return <Image src={BLUELOGO} style={{ height: "40px" }} />;
  };

  // REMINDER: lineHeight and letterSpacing for Text doesn't work

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View>
              <Text
                style={{
                  fontFamily: "GT Walsheim Pro",
                  fontWeight: 400,
                  fontSize: "12px",
                  width: "122px",
                }}
              >
                {addressForProperty(property)}
              </Text>
            </View>

            <View style={{ display: "flex", flexDirection: "row", flex: 1 }} />

            <View>{logo()}</View>
          </View>

          <View
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.32)",
              height: 1,
              marginTop: "8px",
              marginBottom: "24px",
            }}
          />

          <View
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Text
              style={{
                fontFamily: "GT Walsheim Pro",
                fontWeight: 400,
                fontSize: "20px",
                marginBottom: "24px",
              }}
            >
              Legal Review for {legalReviewForName}
            </Text>

            {summaries.map((summary) => (
              <View style={{ display: "flex", flexDirection: "column" }}>
                <Text
                  style={{
                    fontFamily: "GT Walsheim Pro",
                    fontWeight: 700,
                    fontSize: "16px",
                  }}
                >
                  {summary.title}
                </Text>

                <View
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.16)",
                    height: 0.8,
                    marginTop: "8px",
                    marginBottom: "16px",
                  }}
                />

                <Text
                  style={{
                    fontFamily: "GT Walsheim Pro",
                    fontWeight: 400,
                    fontSize: "10px",
                    marginBottom: "12px",
                  }}
                >
                  {summary.content}
                </Text>

                {comments && !isEmpty(summary.p_comments) && (
                  <Text
                    style={{
                      fontFamily: "GT Walsheim Pro",
                      fontWeight: 700,
                      fontSize: "12px",
                      marginBottom: "16px",
                    }}
                  >
                    Comments:
                  </Text>
                )}

                {comments &&
                  summary.p_comments?.map((comment) => (
                    <View style={{ width: "100%" }}>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <View style={{ marginRight: "16px", width: "25%" }}>
                          <Text
                            style={{
                              fontFamily: "GT Walsheim Pro",
                              fontWeight: 700,
                              fontSize: "10px",
                            }}
                          >
                            {comment.user.first_name}&nbsp;
                            {comment.user.last_name}
                            {"\n"}
                            <Text
                              style={{
                                fontFamily: "GT Walsheim Pro",
                                fontWeight: 400,
                                fontSize: "8px",
                                color: "#9F9F9F",
                              }}
                            >
                              {moment(comment.created).format(
                                "MMM D [at] hh:mm A"
                              )}
                            </Text>
                          </Text>
                        </View>
                        <View style={{ width: "75%" }}>
                          <Text
                            style={{
                              fontFamily: "GT Walsheim Pro",
                              fontWeight: 400,
                              fontSize: "10px",
                            }}
                          >
                            {comment.content}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={{
                          backgroundColor: "rgba(0, 0, 0, 0.16)",
                          height: 0.5,
                          marginTop: "8px",
                          marginBottom: "8px",
                        }}
                      />
                    </View>
                  ))}
              </View>
            ))}
          </View>
        </View>
        <View style={styles.pageNumber} fixed>
          <View
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.32)",
              height: 1,
              marginBottom: "8px",
            }}
          />
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View>
              <Text
                style={{
                  fontFamily: "GT Walsheim Pro",
                  fontWeight: 400,
                  fontSize: "10px",
                  width: "109px",
                  color: "#000000",
                }}
              >
                Generated with{" "}
                <Text style={{ textDecoration: "underline" }}>
                  <a href="https://contrax.com.au/">Contrax</a>
                </Text>
              </Text>
            </View>

            <View style={{ display: "flex", flexDirection: "row", flex: 1 }} />

            <Text
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
            />
          </View>
        </View>
      </Page>
    </Document>
  );
}
