import { Box, Link, Typography } from "@material-ui/core";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";
import {
  Contract,
  ContractWorkflowStatus,
  Property,
  User,
} from "../api/models";
import { useDesktop, useMobile } from "../hooks/mobile";
import { useHistory, useLocation } from "react-router-dom";

import AlertWrapper from "../components/AlertWrapper";
import BackIcon from "../images/chevron-left-blue.png";
import { Colors } from "../constants/colors";
import ContractPendingButton from "../components/ContractPendingButton";
import PurchaseLegalReviewButton from "../components/PurchaseLegalReviewButton";
import React from "react";
import { useAlert } from "../contexts/Alert";
import useSWR from "swr";
import { useSessionStore } from "../stores/Session";

export default function SampleLegalReview(): JSX.Element {
  const isDesktop = useDesktop();
  const isMobile = useMobile();
  const history = useHistory();
  const location = useLocation();

  const headerHeight = isDesktop ? "88px" : "66px";
  const warningHeight = isDesktop ? "40px" : "38px";
  const height = isDesktop ? "128px" : "104px";

  const query = new URLSearchParams(location.search);
  const propertyId = query.get("propertyId");

  const { data: propertyData } = useSWR(`/view-properties/${propertyId}/`);
  const property = propertyData && (propertyData as Property);

  const { data: contractData } = useSWR(
    (property &&
      property.contract_id &&
      `/view-contracts/${property.contract_id}/`) ||
      null
  );
  const contract = contractData && (contractData as Contract);

  const { refresh: refreshUserData, data: userData } = useSessionStore();
  const user = userData && (userData as User);

  const { setErrorMessage } = useAlert();

  const phantomStyle = {
    height,
  };

  // function purchaseLegalReviewClicked() {
  //   if (user && user.user_type === UserType.Agent) {
  //     setErrorMessage(
  //       "We noticed you’re trying to buy a legal review from your agent account. You must create a personal account to purchase this review, to do this simply logout, search for a property and create a personal account"
  //     );
  //   } else {
  //     history.push(`/signup?propertyId=${propertyId}`);
  //   }
  // }

  function openSampleContractWithPage(page: number) {
    window.open(
      `/app/sale-contract?sample=true&page=${page}`,
      "coview-sample-contract"
    );
  }

  return (
    <AlertWrapper>
      <Box bgcolor={Colors.BrandBackground4} minHeight="100vh">
        {/* Phantom view so views on top of footer knows "it's there" and respects margins */}
        <Box style={phantomStyle} width="100%" />
        <Box
          height={headerHeight}
          alignItems="center"
          display="flex"
          boxShadow="0px 1px 0px rgba(255, 255, 255, 0.2)"
          pl={2}
          pr={2}
          justifyContent="space-between"
          zIndex={1}
          position="fixed"
          top={0}
          left={0}
          right={0}
          bgcolor={Colors.BrandBackground4}
        >
          <Button
            type={ButtonType.secondary}
            size={ButtonSize.small}
            leftImage={BackIcon}
            title="BACK"
            onClick={() => history.goBack()}
          />
          {isDesktop &&
            contract &&
            contract.workflow_status === ContractWorkflowStatus.Reviewed && (
              <PurchaseLegalReviewButton
                width="305px"
                propertyId={propertyId ? +propertyId : null}
              />
            )}
          {isDesktop &&
            contract &&
            contract.workflow_status !== ContractWorkflowStatus.Reviewed && (
              <ContractPendingButton
                width="400px"
                propertyId={propertyId ? +propertyId : null}
                contract={contract && contract.file ? true : false}
              />
            )}
        </Box>
        <Box
          bgcolor={Colors.WarningBackground}
          width="100%"
          height={warningHeight}
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex={1}
          position="fixed"
          top={headerHeight}
          left={0}
          right={0}
        >
          <Typography variant={isDesktop ? "h6" : "body1"}>
            This is a sample legal review
          </Typography>
        </Box>
        <Box
          bgcolor="white"
          pt={isMobile ? 4 : 8}
          pb={isMobile ? 4 : 8}
          pl={isMobile ? 5.5 : 11}
          pr={isMobile ? 5.5 : 11}
        >
          <Typography>Dear (purchaser name),</Typography>
          <br />
          <Typography>
            Thank you for using Contrax for your legal review of X/XX Beach
            Road, Beachville NSW 2053.
          </Typography>
          <br />
          <Typography style={{ fontWeight: 700, textDecoration: "underline" }}>
            How it all works
          </Typography>
          <br />
          <Typography>
            If you make an unconditional offer on the property which is
            accepted, you will be required to pay the deposit and sign the
            contract of sale. Therefore it’s crucial that you are happy with the
            contract of sale and that you or your legal adviser ask any
            questions or request any amendments prior to signing.
            <br />
            <br />
            If you are successful at auction, you will be required to sign the
            contract of sale and pay the deposit on the spot. There are no
            cooling-off periods for auctions, so you need to be happy with the
            contract of sale before you attend the auction.
            <br />
            <br />
            <Typography
              style={{ fontWeight: 700, textDecoration: "underline" }}
            >
              Legal review of the contract of sale
            </Typography>
            <ol>
              <li>
                <strong>Settlement</strong>: This is set for 80 days which is
                longer than the standard settlement period (42 days). Settlement
                is when the balance of the purchase price is paid to the vendor,
                your bank will advance your loan (if you are obtaining a loan
                for the purchase), stamp duty is paid, legal title is
                transferred to you and you will receive the keys to the
                property. If you require a different settlement period, your
                legal adviser will need to request this from the vendor’s
                lawyer.
                <br />
                <br />
                <Link
                  style={{ fontWeight: 700, cursor: "pointer" }}
                  onClick={() => openSampleContractWithPage(1)}
                >
                  Click to view
                </Link>
                <br />
                <br />
              </li>
              <li>
                <strong>Subject to existing tenants</strong>: The property is
                being sold with a tenancy in place which expires on 14 July
                2021. If the tenancy is still in place at settlement, you will
                need to honour the lease and step into the position of landlord.
                We advise asking the vendor’s lawyer if there have been any
                tenant defaults or if there are any outstanding work orders
                requested by the tenant to which the vendor has not yet
                attended. Please also note that if you are proposing to use this
                property as an investment, once a property is on the market, a
                tenant has a legal right to terminate the lease even if the
                lease is not due to expire.
                <br />
                <br />
                <Link
                  style={{ fontWeight: 700, cursor: "pointer" }}
                  onClick={() => openSampleContractWithPage(1)}
                >
                  Click to view
                </Link>
                <br />
                <br />
              </li>
              <li>
                <strong>Deposit</strong>: The deposit is set for 10% of the
                purchase price which is paid to the real estate agent to hold in
                their trust account until settlement. If you require a 5%
                deposit (which is a common request), your legal adviser will
                need to request this from the vendor’s lawyer.
                <br />
                <br />
                <Link
                  style={{ fontWeight: 700, cursor: "pointer" }}
                  onClick={() => openSampleContractWithPage(1)}
                >
                  Click to view
                </Link>
                <br />
                <br />
              </li>
              <li>
                <strong>Improvements</strong>: You are buying a unit and garage.
                There is no separate storage space. If this is inaccurate, this
                needs to be raised with the vendor’s lawyer/conveyancer.
                <br />
                <br />
                <Link
                  style={{ fontWeight: 700, cursor: "pointer" }}
                  onClick={() => openSampleContractWithPage(1)}
                >
                  Click to view
                </Link>
                <br />
                <br />
              </li>
              <li>
                <strong>Inclusions</strong>: On the front page of the contract
                in the black box, you will see the word “Inclusions” and a bunch
                of small boxes. Boxes that are marked reflect those items that
                come with the sale (i.e. dishwasher and light fittings). Please
                check that these have been listed accurately. It doesn’t need to
                be limited to what is noted. When you are inspecting the
                property, it is also important to note whether inclusions are
                operating or not. This often causes issues just prior to
                settlement if it is discovered that an inclusion is
                non-operating but there is no evidence to suggest that it was
                operating when you signed contracts.
                <br />
                <br />
                <Link
                  style={{ fontWeight: 700, cursor: "pointer" }}
                  onClick={() => openSampleContractWithPage(1)}
                >
                  Click to view
                </Link>
                <br />
                <br />
              </li>
              <li>
                <strong>Land tax adjustable</strong>: Land tax is adjustable
                which means you will need to pay a proportion of the land tax on
                the property (on a pro-rata basis from when you settle until 31
                December). If you are planning on moving into the property after
                settlement, we advise requesting that land tax not be adjustable
                as you shouldn't have to cover the land tax if this will be your
                primary residence (land tax is only levied against investment
                properties).
                <br />
                <br />
                <Link
                  style={{ fontWeight: 700, cursor: "pointer" }}
                  onClick={() => openSampleContractWithPage(2)}
                >
                  Click to view
                </Link>
                <br />
                <br />
              </li>
              <li>
                <strong>GST</strong>: There is no GST payable on the purchase
                price.
                <br />
                <br />
                <Link
                  style={{ fontWeight: 700, cursor: "pointer" }}
                  onClick={() => openSampleContractWithPage(2)}
                >
                  Click to view
                </Link>
                <br />
                <br />
              </li>
              <li>
                <strong>
                  Representations and warranties - Special Condition 3
                </strong>
                : Here you are prevented from relying on any representations
                that have been made to you about the property other than as
                disclosed in the contract. If there has been anything promised
                to you separately, you will need to let your legal adviser know
                so that it can be included in the contract.This is a standard
                clause.
                <br />
                <br />
                <Link
                  style={{ fontWeight: 700, cursor: "pointer" }}
                  onClick={() => openSampleContractWithPage(3)}
                >
                  Click to view
                </Link>
                <br />
                <br />
              </li>
              <li>
                <strong>Buyer beware - Special Conditions 4 and 5</strong>:
                These clauses are very important. They state that you are buying
                the property in its present condition regardless of any faults
                or defects. Buying property is done generally on this “buyer
                beware” principle meaning that it is really up to the purchaser
                to make all inquiries and do their due diligence as there are
                very few opportunities to pull out of the purchase or to make a
                claim after you sign contracts. This is a standard clause.
                <br />
                <br />
                <Link
                  style={{ fontWeight: 700, cursor: "pointer" }}
                  onClick={() => openSampleContractWithPage(3)}
                >
                  Click to view
                </Link>
                <br />
                <br />
              </li>
              <li>
                <strong>
                  Interest for late settlement - Special Condition 8
                </strong>
                : If you are late to settle, you will be charged 10% interest
                (per annum) on the balance of the purchase price and you will
                have 14 days in which to settle or otherwise lose your deposit.
                This is a standard clause in respect to paying interest but we
                advise requesting that interest be reduced to a more reasonable
                rate of 6%.
                <br />
                <br />
                <Link
                  style={{ fontWeight: 700, cursor: "pointer" }}
                  onClick={() => openSampleContractWithPage(4)}
                >
                  Click to view
                </Link>
                <br />
                <br />
              </li>
              <li>
                <strong>Real estate agents - Special Condition 9</strong>: The
                only real estate agent with whom you should be dealing is Top
                Real Estate. If there are other agents involved, the vendor can
                hold you liable for the extra commission. This is a standard
                clause.
                <br />
                <br />
                <Link
                  style={{ fontWeight: 700, cursor: "pointer" }}
                  onClick={() => openSampleContractWithPage(4)}
                >
                  Click to view
                </Link>
                <br />
                <br />
              </li>
              <li>
                <strong>Deposit release - Special Condition 10</strong>: The
                deposit normally remains in the sale agent’s trust account until
                settlement, at which point the purchaser lawyer/conveyancer
                authorises its release to the vendor. Here, the deposit may be
                released for the purchase of other property by the vendor. We
                advise that this clause be removed so that the deposit remains
                in trust until settlement.
                <br />
                <br />
                <Link
                  style={{ fontWeight: 700, cursor: "pointer" }}
                  onClick={() => openSampleContractWithPage(4)}
                >
                  Click to view
                </Link>
                <br />
                <br />
              </li>
              <li>
                <strong>Zoning</strong>: The land is zoned R2 Low Density
                Residential and the objectives are set out on page 5. The land
                falls within a heritage conservation area but the property
                itself is not heritage listed. According to the zoning
                certificate attached to the sale contract, the property:
                <ul>
                  <li>does not include critical habitat;</li>
                  <li>cannot have complying development;</li>
                  <li> is not affected by the Coastal Protection Act;</li>
                  <li>
                    is not affected by any road widening or road realignment;
                  </li>
                  <li>
                    is not subject to a policy on hazard risk restriction;
                  </li>
                  <li>is not subject to flood related development control;</li>
                  <li> is not land reserved for acquisition;</li>
                  <li> is not biodiversity certified land;</li>
                  <li>
                    does not contain a set aside area for native vegetation (ie
                    an area that cannot be cleared);
                  </li>
                  <li>is not bushfire prone land;</li>
                  <li>is not subject to a vegetation plan;</li>
                  <li>
                    Is not subject to an order to carry out work in relation to
                    a tree on the land;
                  </li>
                  <li>
                    has not been identified in the Loose-Fill Asbestos
                    Insulation Register;
                  </li>
                  <li>and Is not contaminated land.</li>
                </ul>
                <br />
                <Link
                  style={{ fontWeight: 700, cursor: "pointer" }}
                  onClick={() => openSampleContractWithPage(5)}
                >
                  Click to view
                </Link>
                <br />
                <br />
              </li>
              <li>
                <strong>Surrounding development:</strong> Sale contracts do not
                disclose whether there are surrounding developments or approved
                proposed developments which may impact your decision to
                purchase. It is therefore advisable to call council to ensure
                you are aware of any developments.
              </li>
              <br />
              <br />
              <li>
                <strong>Strata Report:</strong> It is strongly advised that you
                obtain a strata report for this property before bidding at
                auction or signing contracts unconditionally. A strata report is
                prepared by a strata inspector who checks the books of the body
                corporate and reports on financial information for the body
                corporate, special levies, insurance information and general
                issues with the building or owners.
              </li>
            </ol>
          </Typography>
        </Box>
        {!isDesktop &&
          contract &&
          contract.workflow_status === ContractWorkflowStatus.Reviewed && (
            <React.Fragment>
              <Box height="96px" />
              <Box
                height="96px"
                boxShadow="0px -1px 0px rgba(255, 255, 255, 0.2)"
                display="flex"
                alignItems="center"
                justifyContent="center"
                zIndex={1}
                position="fixed"
                left={0}
                right={0}
                bottom={0}
                bgcolor={Colors.BrandBackground4}
              >
                <Box mt={2} mb={2} ml={3} mr={3} width="100%" maxWidth="375px">
                  <PurchaseLegalReviewButton
                    width="100%"
                    propertyId={propertyId ? +propertyId : null}
                  />
                </Box>
              </Box>
            </React.Fragment>
          )}
        {!isDesktop &&
          contract &&
          contract.workflow_status !== ContractWorkflowStatus.Reviewed && (
            <React.Fragment>
              <Box height="96px" />
              <Box
                height="96px"
                boxShadow="0px -1px 0px rgba(255, 255, 255, 0.2)"
                display="flex"
                alignItems="center"
                justifyContent="center"
                zIndex={1}
                position="fixed"
                left={0}
                right={0}
                bottom={0}
                bgcolor={Colors.BrandBackground4}
              >
                <Box mt={2} mb={2} ml={3} mr={3} width="100%" maxWidth="375px">
                  <ContractPendingButton
                    width="100%"
                    propertyId={propertyId ? +propertyId : null}
                  />
                </Box>
              </Box>
            </React.Fragment>
          )}
      </Box>
    </AlertWrapper>
  );
}
