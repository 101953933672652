import React from "react";
import { Box, BoxProps, Typography } from "@material-ui/core";
import { Colors } from "../constants/colors";
import { useCreditStore } from "../stores/credit";
import { useSessionStore } from "../stores/Session";
import { User } from "../api/models";
import pluralize from "pluralize";

interface Props extends BoxProps {}

export default function CreditsRemainingText({ ...rest }: BoxProps) {
  const { data: userData } = useSessionStore();
  const { refreshCredits, availableCredits } = useCreditStore();

  const user = userData ? (userData as User) : null;

  React.useEffect(() => {
    if (user) {
      refreshCredits();
    }
  }, []);

  return (
    <Box {...rest}>
      <Typography
        variant="h6"
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        You have
        <Typography
          variant="h6"
          style={{
            fontWeight: 700,
            marginLeft: "6px",
            marginRight: "6px",
            color: Colors.BrandPrimary,
          }}
        >
          {availableCredits}
        </Typography>
        {pluralize("credit", availableCredits)}{" "}
        {availableCredits > 0 ? " remaining" : ""}
      </Typography>
    </Box>
  );
}
