import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Colors } from "../constants/colors";
import useSWR from "swr";
import { Property, addressForProperty } from "../api/models";
import NoPropertyImage from "../images/no-property-image.svg";

interface Props {
  propertyId: number | null;
  bgcolor?: string | null;
  address?: string | null;
}

export default function PropertyDetailsHeader({
  propertyId,
  bgcolor,
  address
}: Props): JSX.Element {
  const { data: propertyData } = useSWR(`/view-properties/${propertyId}/`);
  const property = propertyData && (propertyData as Property);
  return property ? (
    <Box
      width="100%"
      height="112px"
      display="flex"
      flexDirection="row"
      bgcolor={bgcolor || Colors.Grey5}
      alignItems="center"
    >
      {property && property.image && (
        <Box height="88px" ml={1} borderRadius="4px">
          <img src={property.image} height="88px" />
        </Box>
      )}
      {property && !property.image && (
        <Box height="88px" ml={1} borderRadius="4px">
          <img src={NoPropertyImage} height="88px" />
        </Box>
      )}
      <Box ml={2} mr={2} display="flex" flexDirection="column">
        <Typography variant="body1">
          {(property && addressForProperty(property))}
        </Typography>
      </Box>
    </Box>
  ) : (
      <Box
        width="100%"
        height="112px"
        display="flex"
        flexDirection="row"
        bgcolor={bgcolor || Colors.Grey5}
        alignItems="center"
      >

        <Box height="88px" ml={1} borderRadius="4px">
          <img src={NoPropertyImage} height="88px" />
        </Box>
        <Box ml={2} mr={2} display="flex" flexDirection="column">
          <Typography variant="body2">
            {address}
          </Typography>
        </Box>
      </Box>
    );
}
