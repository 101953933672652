import React from "react";
import { Box, Typography } from "@material-ui/core";
import SidebarLink from "./SidebarLink";
import { Colors } from "../../constants/colors";
import { User, UserType } from "../../api/models";
import Button, { ButtonType, ButtonSize } from "../common/Button";
import { resetSession, useSessionStore } from "../../stores/Session";
import { useHistory } from "react-router-dom";
import { isNil } from "lodash-es";
import { useSWRConfig } from "swr";

export default function Sidebar() {
  const history = useHistory();
  const { cache } = useSWRConfig();

  const { refresh: refreshUserData, data: userData } = useSessionStore();
  const user = userData && (userData as User);

  const isAgent = user?.user_type === UserType.Agent;

  const isPartner = user?.user_type === UserType.Partner;

  const showHeaders = !isNil(user?.current_organization) && !isPartner;

  return (
    <Box
      width="419px"
      boxSizing="border-box"
      bgcolor={Colors.BrandBackground2}
      pt="56px"
      pb="56px"
      display="flex"
      flexDirection="column"
      pl="176px"
    >
      <Box display="flex" flexDirection={"column"}>
        <Typography variant="h4" style={{ marginBottom: "28px" }}>
          {user?.first_name}
        </Typography>

        {showHeaders && (
          <Typography variant="overline" style={{ marginBottom: "4px" }}>
            ORGANISATION DETAILS
          </Typography>
        )}

        {!isAgent && !isPartner && (
          <>
            <SidebarLink title="Contrax credits" path="/user/credits" />
            <SidebarLink
              title="Billing information"
              path="/user/billing"
              altPaths={["/user/card"]}
            />
            <SidebarLink
              title="Invoices"
              path="/user/invoices"
              altPaths={["/user/view-invoice"]}
            />
            {/* <SidebarLink title="Usage" path="/user/usage" /> */}
          </>
        )}

        {showHeaders && (
          <Typography
            variant="overline"
            style={{ marginTop: "86px", marginBottom: "4px" }}
          >
            PERSONAL DETAILS
          </Typography>
        )}

        <SidebarLink title="Account details" path="/user/account" />
      </Box>

      <Box
        display="flex"
        flexDirection={"column"}
        flex={1}
        justifyContent={"flex-end"}
      >
        <Button
          type={ButtonType.primary}
          size={ButtonSize.medium}
          title="LOG OUT"
          onClick={() => {
            cache.delete("buying-contracts/");
            cache.delete("/me/");

            resetSession();
            history.push("/login");
          }}
          width="112px"
        />
      </Box>
    </Box>
  );
}
