import { Box, Drawer, Link, Typography } from "@material-ui/core";
import { isEmpty, isNil, some } from "lodash-es";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import api from "../api/instance";
import {
  Activity,
  ActivityAction,
  BuyerSellerStatus,
  Contract,
  Enquiry,
  EnquiryStage,
  EnquiryStatus,
  isEnquiryBuyerRespondedAndPublished,
  isEnquirySellerRespondedAndPublished,
  isRequestBuyerRespondedAndPublished,
  isRequestSellerRespondedAndPublished,
  LegalReviewRequest,
  LegalReviewRequestStage,
  LegalReviewRequestStatus,
  Property,
  TeamMemberRole,
  User,
} from "../api/models";
import ActivityLog from "../components/ActivityLog/ActivityLog";
import ActivityLogDrawer from "../components/ActivityLog/ActivityLogDrawer";
import AddAMessageDialog, {
  AddAMessageDialogForScreen,
} from "../components/AddAMessageDialog";
import AddAMessageSentDialog from "../components/AddAMessageSentDialog";
import AlertWrapper from "../components/AlertWrapper";
import CommentsDialog, {
  CommentsDialogJourney,
  CommentsDialogType,
} from "../components/Comments/CommentsDialog";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";
import PageSection from "../components/common/PageSection";
import StickyFooter from "../components/common/StickyFooter";
import HelpGuideModal from "../components/HelpGuide/HelpGuideModal";
import HelpGuideModalItem from "../components/HelpGuide/HelpGuideModalItem";
import LoginModal from "../components/Invited/LoginModal";
import SignUpModal from "../components/Invited/SignUpModal";
import LegalReviewProgressBar from "../components/LegalReviewProgressBar";
import InviteModal from "../components/LegalReviewViewer/InviteModal";
import InviteYourTeamMembersModal, {
  InviteYourTeamMembersModalForScreen,
  InviteYourTeamMembersModalType,
} from "../components/LegalReviewViewer/InviteYourTeamMembersModal";
import LegalReviewWhiteHeader from "../components/LegalReviewWhiteHeader";
import RequestItem, {
  RequestItemType,
} from "../components/RequestWorkspace/RequestItem";
import YouNeedToInviteABuyerDialog from "../components/RequestWorkspace/YouNeedToInviteABuyerDialog";
import ShareWithDialog, {
  ShareWithDialogType,
} from "../components/ShareWithDialog";
import { Colors } from "../constants/colors";
import { useAlert } from "../contexts/Alert";
import { parseApiError } from "../helpers/error";
import { useMobile } from "../hooks/mobile";
import BuyerHelp1 from "../images/buyer-req-workspace-help-1.svg";
import BuyerHelp2 from "../images/buyer-req-workspace-help-2.svg";
import BuyerHelp3 from "../images/buyer-req-workspace-help-3.svg";
import BLHelp1 from "../images/req-workspace-buyer-lawyer-help1.svg";
import BLHelp2 from "../images/req-workspace-buyer-lawyer-help2.svg";
import BLHelp3 from "../images/req-workspace-buyer-lawyer-help3.svg";
import SLHelp1 from "../images/req-workspace-seller-lawyer-help1.svg";
import SLHelp2 from "../images/req-workspace-seller-lawyer-help2.svg";
import SLHelp3 from "../images/req-workspace-seller-lawyer-help3.svg";
import ViewAmendmentAppendixIcon from "../images/view-amendment-appendix.svg";
import {
  refreshContractTeamsAndRole,
  useContractStore,
} from "../stores/contract";
import { isLoggedIn, useSessionStore } from "../stores/Session";
import "../styles/podium.css";
import "../styles/scrollbar-hidden.css";
import StartAddingRequests from "./StartAddingRequests";
import RespondRequestDialog from "../components/RequestWorkspace/RespondRequestDialog";
import RespondEnquiryDialog from "../components/RequestWorkspace/RespondEnquiryDialog";
import YouHaveSentYourResponseDialog from "../components/RequestWorkspace/YouHaveSentYourResponseDialog";
import DownloadButton from "../components/DownloadButton";
import DownloadDialog from "../components/RequestWorkspace/DownloadDialog";
import EditButton from "../components/EditButton";
import TextField from "../components/common/TextField";
import NoBuyerShareWithDialog, {
  NoBuyerShareWithDialogType,
} from "../components/NoBuyerShareWithDialog";

interface MobileStickyFooterProps {
  onBuilderOrResponder: () => unknown;
  onMoreOptions: () => unknown;
  requestsSent: boolean;
  role: TeamMemberRole | null;
  onViewAppendix: () => unknown;
  onHelpGuide: () => unknown;
}

function MobileStickyFooter({
  onBuilderOrResponder,
  onMoreOptions,
  requestsSent,
  role,
  onViewAppendix,
  onHelpGuide,
}: MobileStickyFooterProps) {
  const title = () => {
    if (role === TeamMemberRole.buyerLawyer) {
      return requestsSent ? "EDIT REQUESTS" : "EDIT REQUESTS";
    }

    if (role === TeamMemberRole.sellerLawyer) {
      return "SEND RESPONSES";
    }

    return "";
  };

  const isAgent = role === TeamMemberRole.agent;
  const isPartner = role === TeamMemberRole.partner;

  return (
    <StickyFooter
      height={isAgent ? "80px" : "108px"}
      bgcolor="white"
      boxShadow="0px -1px 0px #E0E0E0"
    >
      <Box m="16px" display="flex" flexDirection="column">
        {role === TeamMemberRole.agent ||
        role === TeamMemberRole.buyer ||
        role === TeamMemberRole.seller ||
        role === TeamMemberRole.partner ? (
          <>
            <Button
              title={"VIEW AMENDMENT APPENDIX"}
              type={ButtonType.primary}
              size={ButtonSize.medium}
              onClick={onViewAppendix}
            />
            {!isAgent && !isPartner && (
              <>
                <Box height="4px" />
                <Button
                  title="HELP GUIDE"
                  type={ButtonType.secondary}
                  size={ButtonSize.small}
                  onClick={onHelpGuide}
                />
              </>
            )}
          </>
        ) : (
          <>
            <Button
              title={title()}
              type={ButtonType.primary}
              size={ButtonSize.medium}
              onClick={onBuilderOrResponder}
            />

            <Box height="4px" />
            <Button
              title="MORE OPTIONS"
              type={ButtonType.secondary}
              size={ButtonSize.small}
              onClick={onMoreOptions}
            />
          </>
        )}
      </Box>
    </StickyFooter>
  );
}

interface MobileFooterDrawerProps {
  open: boolean;
  onClose: () => unknown;
  onBuilderOrResponder: () => unknown;
  onSend: () => unknown;
  onSendResponses: () => unknown;
  onViewAppendix: () => unknown;
  onHelpGuide: () => unknown;
  requestsSent: boolean;
  role: TeamMemberRole | null;
  canSend: boolean;
}

function MobileFooterDrawer({
  open,
  onClose,
  onBuilderOrResponder,
  onSend,
  onSendResponses,
  onViewAppendix,
  onHelpGuide,
  requestsSent,
  role,
  canSend,
}: MobileFooterDrawerProps) {
  return (
    <Drawer anchor="bottom" open={open} onClose={onClose}>
      <Box width="100%" bgcolor="white">
        <Box m="16px" display="flex" flexDirection="column">
          {role === TeamMemberRole.buyerLawyer && (
            <>
              <Button
                title={requestsSent ? "EDIT REQUESTS" : "EDIT REQUESTS"}
                type={ButtonType.primary}
                size={ButtonSize.medium}
                onClick={onBuilderOrResponder}
              />

              <Box height="4px" />
              <Button
                title={"SEND UPDATES"}
                type={ButtonType.secondary}
                size={ButtonSize.small}
                onClick={onSend}
                // disabled={!canSend}
              />
            </>
          )}

          {role === TeamMemberRole.sellerLawyer && (
            <>
              <Button
                title={"SEND RESPONSES"}
                type={ButtonType.primary}
                size={ButtonSize.medium}
                onClick={onSendResponses}
              />
            </>
          )}

          <Box height="4px" />
          <Button
            title="VIEW AMENDMENT APPENDIX"
            type={ButtonType.secondary}
            size={ButtonSize.small}
            onClick={onViewAppendix}
          />

          <Box height="4px" />
          <Button
            title="HELP GUIDE"
            type={ButtonType.secondary}
            size={ButtonSize.small}
            onClick={onHelpGuide}
          />
        </Box>
      </Box>
    </Drawer>
  );
}

export default function RequestWorkspace() {
  const location = useLocation();
  const history = useHistory();
  const isMobile = useMobile();

  const [inviteModalOpen, setInviteModalOpen] = React.useState(false);
  const [inviteYourTeamMembersOpen, setInviteYourTeamMembersOpen] =
    React.useState(false);
  const [
    inviteYourTeamMembersUserTeamRole,
    setInviteYourTeamMembersUserTeamRole,
  ] = React.useState<TeamMemberRole | null>(null);
  const [inviteYourTeamMembersType, setInviteYourTeamMembersType] =
    React.useState(InviteYourTeamMembersModalType.default);

  const query = new URLSearchParams(location.search);
  const _contractId = query.get("contractId");
  const contractId = !isNil(_contractId) ? parseInt(_contractId) : null;
  const _recordId = query.get("recordId");
  const recordId = !isNil(_recordId) ? parseInt(_recordId) : null;
  // const _request_part = query.get("request_part");
  const orgName = query.get("organization_name");

  const { data, mutate: contractMutate } = useSWR(
    `/view-contracts/${contractId}/?recordId=${recordId}`
  );
  const contract = data ? (data as Contract) : null;

  const { data: recdata } = useSWR(`/view-contracts/${contractId}/`);
  const contractS = recdata as Contract;

  const { data: propertyData } = useSWR(
    contract?.property_id ? `/view-properties/${contract.property_id}/` : null
  );
  const property = propertyData && (propertyData as Property);

  const { data: activitiesData, mutate: activitiesMutate } = useSWR(
    !isNil(contractId)
      ? `/contract/${contractId}/record/${recordId}/activities/`
      : null
  );

  const activities = activitiesData ? (activitiesData as Activity[]) : [];

  const [isEditing, setIsEditing] = React.useState(false);
  const [title, setTitle] = React.useState("");

  const { userTeamMemberRole, buyerTeamMembers } = useContractStore();

  const {
    refresh: refreshUser,
    data: user,
    refreshTimestamp,
  } = useSessionStore();

  const userData = user && (user as User);

  const isSeller =
    userTeamMemberRole === TeamMemberRole.seller ||
    userTeamMemberRole === TeamMemberRole.sellerLawyer;

  const { data: requestsData, mutate: requestsMutate } = useSWR(
    `/contract/${contractId}/record/${recordId}/${
      isSeller ? "seller-" : ""
    }legal-review-requests/`
  );
  const { data: enquiriesData, mutate: enquiriesMutate } = useSWR(
    `/contract/${contractId}/record/${recordId}/${
      isSeller ? "seller-" : ""
    }legal-review-enquiries/`
  );

  const allRequests = requestsData
    ? (requestsData as LegalReviewRequest[])
    : [];
  const allEnquiries = enquiriesData ? (enquiriesData as Enquiry[]) : [];

  const allUndeletedRequests = allRequests.filter(
    (r) => r.request_status !== LegalReviewRequestStage.deleted
  );
  const allUndeletedEnquiries = allEnquiries.filter(
    (e) => e.enquiry_status !== EnquiryStage.Deleted
  );

  const requests = requestsData
    ? (requestsData as LegalReviewRequest[])
        .filter((request) => {
          if (isSeller) {
            return true;
          }

          return request.status === LegalReviewRequestStatus.published;
        })

        .sort((a, b) =>
          b.request_status === LegalReviewRequestStage.deleted ? -1 : 0
        )
    : [];

  const enquiries = enquiriesData
    ? (enquiriesData as Enquiry[])
        .filter((enquiry) => {
          if (isSeller) {
            return true;
          }

          return enquiry.status === EnquiryStatus.Published;
        })

        .sort((a, b) => (b.enquiry_status === EnquiryStage.Deleted ? -1 : 0))
    : [];

  const { setSuccessMessage, setErrorMessage } = useAlert();

  const [enquiryForCommentsDialog, setEnquiryForCommentsDialog] =
    React.useState<Enquiry | null>(null);
  const [enquiryCommentsDialogOpen, setEnquiryCommentsDialogOpen] =
    React.useState(false);

  const [requestForCommentsDialog, setRequestForCommentsDialog] =
    React.useState<LegalReviewRequest | null>(null);
  const [requestCommentsDialogOpen, setRequestCommentsDialogOpen] =
    React.useState(false);

  const [activityLogDrawerOpen, setActivityLogDrawerOpen] =
    React.useState(false);

  React.useEffect(() => {
    if (!isNil(contractId)) {
      refreshContractTeamsAndRole();
    }
  }, [contractId]);

  React.useEffect(() => {
    user && refreshContractTeamsAndRole();
  }, [refreshTimestamp]);

  const onViewAmendmentAppendix = () => {
    !isNil(contractId) &&
      history.push(
        `/amendment-appendix?contractId=${contractId}&recordId=${recordId}`
      );
  };

  const onManageRequests = () => {
    !isNil(contractId) &&
      history.push(
        `/request-builder?contractId=${contractId}&recordId=${recordId}`
      );
  };

  const onRespondToRequests = () => {
    !isNil(contractId) &&
      history.push(
        `/request-responder?contractId=${contractId}&recordId=${recordId}`
      );
  };

  const onSendRequests = () => {
    if (userTeamMemberRole === TeamMemberRole.buyerLawyer) {
      const noBuyers = isEmpty(
        buyerTeamMembers.filter((m) => m.role === TeamMemberRole.buyer)
      );

      if (noBuyers) {
        // If no buyers yet...
        setNoBuyerShareWithYourTeam(true);
      } else {
        setNoBuyerShareWithYourTeam(true);
      }
    } else {
      setNoBuyerShareWithYourTeam(true);
    }
  };

  const unsentItemsCount =
    requests.filter((request) =>
      isSeller
        ? request.seller_status !== BuyerSellerStatus.Sent &&
          request.seller_status !== BuyerSellerStatus.Completed
        : request.buyer_status !== BuyerSellerStatus.Sent &&
          request.seller_status !== BuyerSellerStatus.Completed
    ).length +
    enquiries.filter((enquiry) =>
      isSeller
        ? enquiry.seller_status !== BuyerSellerStatus.Sent &&
          enquiry.seller_status !== BuyerSellerStatus.Completed
        : enquiry.buyer_status !== BuyerSellerStatus.Sent &&
          enquiry.seller_status !== BuyerSellerStatus.Completed
    ).length;

  const unrespondedItemsCount =
    allUndeletedRequests.filter((request) =>
      isSeller
        ? request.seller_status === BuyerSellerStatus.Pending ||
          request.status === LegalReviewRequestStatus.editing
        : request.buyer_status === BuyerSellerStatus.Pending ||
          request.status === LegalReviewRequestStatus.editing
    ).length +
    allUndeletedEnquiries.filter((e) =>
      isSeller
        ? e.seller_status === BuyerSellerStatus.Pending ||
          e.status === EnquiryStatus.Editing
        : e.buyer_status === BuyerSellerStatus.Pending ||
          e.status === EnquiryStatus.Editing
    ).length;

  const respondedItemsCount =
    requests.filter((request) =>
      isSeller
        ? isRequestSellerRespondedAndPublished(request)
        : isRequestBuyerRespondedAndPublished(request)
    ).length +
    enquiries.filter((enquiry) =>
      isSeller
        ? isEnquirySellerRespondedAndPublished(enquiry)
        : isEnquiryBuyerRespondedAndPublished(enquiry)
    ).length;

  const allResponsesSent = respondedItemsCount === 0;

  const canSend = () => {
    if (userTeamMemberRole === TeamMemberRole.buyerLawyer) {
      const altSuggestionsUnrespondedCount = requests.filter(
        (r) => r.request_status === LegalReviewRequestStage.alternativeSuggested
      ).length;

      return !allResponsesSent && altSuggestionsUnrespondedCount === 0;
    }

    if (userTeamMemberRole === TeamMemberRole.sellerLawyer) {
      return !allResponsesSent && unrespondedItemsCount === 0;
    }

    return false;
  };

  const requestsSent = !isNil(
    activities.find((activity) => activity.action === ActivityAction.Request)
  );

  const requestsRespondedOnce = !isNil(
    activities.find(
      (activity) =>
        activity.action === ActivityAction.Respond ||
        activity.action === ActivityAction.UpdatedResponse
    )
  );

  const [shareWithYourTeamOpen, setShareWithYourTeamOpen] =
    React.useState(false);

  const [addAMessageOpen, setAddAMessageOpen] = React.useState(false);
  const [addAMessageSentOpen, setAddAMessageSentOpen] = React.useState(false);
  const [sendingMessage, setSendingMessage] = React.useState(false);

  const _sendMessagePath = () => {
    // Send requests to seller lawyer

    if (userTeamMemberRole === TeamMemberRole.buyerLawyer) {
      // NOTE: Requests sent = Send updates

      return requestsSent
        ? `/contract/${contractId}/record/${recordId}/request-updates-seller-team/`
        : `/contract/${contractId}/record/${recordId}/request-seller-team/`;
    }

    // Send response to buyer team (Send unsent responses)

    if (userTeamMemberRole === TeamMemberRole.sellerLawyer) {
      return `/contract/${contractId}/record/${recordId}/request-updates-respond/`;
    }

    return undefined;
  };

  const sendMessagePath = _sendMessagePath();

  const onSendMessage = (message: string, pdf: boolean) => {
    if (sendMessagePath) {
      setSendingMessage(true);

      const data = {
        message,
        send_pdf_to: pdf,
      };

      api
        .post(sendMessagePath, !isEmpty(message) ? data : {})
        .then((response) => {
          activitiesMutate();
          requestsMutate();
          enquiriesMutate();

          setSendingMessage(false);
          setAddAMessageOpen(false);
          setAddAMessageSentOpen(true);
        })
        .catch((error) => {
          setSendingMessage(false);
          setErrorMessage(parseApiError(error));
        });
    }
  };

  const _messageSentMessage = () => {
    if (userTeamMemberRole === TeamMemberRole.buyerLawyer) {
      return "You've sent your requests to the vendor's representatives.";
    }

    if (userTeamMemberRole === TeamMemberRole.sellerLawyer) {
      return "Your response has been sent to the Buyer’s legal representatives";
    }
  };

  const messageSentMessage = _messageSentMessage();

  const _shareWithTitle = () => {
    if (userTeamMemberRole === TeamMemberRole.buyerLawyer) {
      return "Send to vendor's representatives";
    }

    if (userTeamMemberRole === TeamMemberRole.sellerLawyer) {
      return "Send response to buyer team";
    }

    return "";
  };

  const _shareWithMessage = () => {
    if (userTeamMemberRole === TeamMemberRole.buyerLawyer) {
      return "After you send, the vendor's legal representatives can view and respond to your requests, while agents can only view them. Summary points and comments remain private.";
    }

    if (userTeamMemberRole === TeamMemberRole.sellerLawyer) {
      return "After you send, the buyer team can view and reply to your responses, while agents can only view them. Comments remain private.";
    }

    return "";
  };

  const onlySentOnce =
    !isNil(
      activities.find((activity) => activity.action === ActivityAction.Request)
    ) &&
    isNil(
      activities.find(
        (activity) => activity.action === ActivityAction.UpdatedRequest
      )
    );

  const _requestsSentMessage = () => {
    // Still loading
    if (isNil(requestsData) || isNil(enquiriesData)) {
      return "";
    }

    if (userTeamMemberRole === TeamMemberRole.buyerLawyer) {
      const sentOnce =
        some(requests, { sent_once: true }) ||
        some(enquiries, { sent_once: true });

      if (requestsSent) {
        if (respondedItemsCount > 0) {
          return `You have ${respondedItemsCount} ${
            sentOnce ? "updates" : "requests"
          } to send`;
        } else if (!isEmpty(requests)) {
          if (onlySentOnce) {
            return `All requests have been sent`;
          }

          return `All ${sentOnce ? "updates" : "requests"} have been sent`;
        }
      } else {
        if (respondedItemsCount > 0) {
          return `You have ${unsentItemsCount} requests to send`;
        } else if (!isEmpty(requests)) {
          return `All requests have been sent`;
        }
      }
    }

    if (userTeamMemberRole === TeamMemberRole.sellerLawyer) {
      if (requestsSent) {
        if (unrespondedItemsCount > 0) {
          return `You have ${unrespondedItemsCount} outstanding requests`;
        } else if (respondedItemsCount > 0) {
          return `You have ${respondedItemsCount} responses to send`;
        } else {
          return requestsRespondedOnce && unsentItemsCount === 0
            ? `All requests responded and sent`
            : `You have ${unsentItemsCount} outstanding requests`;
        }
      } else {
        return `You have ${unsentItemsCount} outstanding requests`;
      }
    }

    return "";
  };

  const shareWithTitle = _shareWithTitle();
  const shareWithMessage = _shareWithMessage();
  const requestsSentMessage = _requestsSentMessage();

  const onSendResponses = () => {
    const canSends = canSend();

    if (!canSends) {
      if (allResponsesSent) {
        if (unrespondedItemsCount > 0) {
          setErrorMessage(
            "Complete all outstanding requests before sending responses"
          );
        } else {
          setErrorMessage("All requests already responded and sent");
        }
      } else {
        setErrorMessage(
          "You need to respond to all request before you can continue"
        );
      }
    } else {
      setShareWithYourTeamOpen(true);
    }
  };

  const [helpModalOpen, setHelpModalOpen] = React.useState(false);

  const _helpModalTitle = () => {
    if (userTeamMemberRole === TeamMemberRole.buyerLawyer) {
      return "How to create your requests";
    }

    if (userTeamMemberRole === TeamMemberRole.sellerLawyer) {
      return "How to respond to requests";
    }

    if (
      userTeamMemberRole === TeamMemberRole.buyer ||
      userTeamMemberRole === TeamMemberRole.seller
    ) {
      return "How to monitor your requests";
    }

    return "";
  };

  const _helpModalItems = () => {
    if (userTeamMemberRole === TeamMemberRole.buyerLawyer) {
      return (
        <>
          <HelpGuideModalItem
            index={1}
            title="Add, edit or dismiss suggested requests or create your own"
            image={BLHelp1}
            imageMarginTop="32px"
          />
          <Box width="6px" />
          <HelpGuideModalItem
            index={2}
            title="Send your request to the vendor’s legal representatives"
            image={BLHelp2}
            imageMarginTop="32px"
          />
          <Box width="6px" />
          <HelpGuideModalItem
            index={3}
            title="Keep track of responses, manage requests and send updates if needed"
            image={BLHelp3}
            imageMarginTop="32px"
          />
        </>
      );
    }

    if (userTeamMemberRole === TeamMemberRole.sellerLawyer) {
      return (
        <>
          <HelpGuideModalItem
            index={1}
            title="Invite your client to view and comment on these requests"
            image={SLHelp1}
            imageMarginTop="32px"
          />
          <Box width="6px" />
          <HelpGuideModalItem
            index={2}
            title="Respond to each of the Buyer’s requests"
            image={SLHelp2}
            imageMarginTop="32px"
          />
          <Box width="6px" />
          <HelpGuideModalItem
            index={3}
            title="Send your responses back to the Buyer"
            image={SLHelp3}
            imageMarginTop="35px"
          />
        </>
      );
    }

    if (
      userTeamMemberRole === TeamMemberRole.buyer ||
      userTeamMemberRole === TeamMemberRole.seller
    ) {
      return (
        <>
          <HelpGuideModalItem
            index={1}
            title="Read your requests and comment if your have questions"
            image={BuyerHelp1}
            imageMarginTop="33px"
          />
          <Box width="6px" />
          <HelpGuideModalItem
            index={2}
            title="The seller team will respond to each request"
            image={BuyerHelp2}
            imageMarginTop="32px"
          />
          <Box width="6px" />
          <HelpGuideModalItem
            index={3}
            title="Agreed requests will appear in the Amendment Appendix"
            image={BuyerHelp3}
            imageMarginTop="32px"
          />
        </>
      );
    }

    return <></>;
  };

  const helpModalTitle = _helpModalTitle();
  const helpModalItems = _helpModalItems();

  const [footerDrawerOpen, setFooterDrawerOpen] = React.useState(false);

  const invitedVerificationToken = query.get("token");
  const inviterFirstName = query.get("first_name") || "";
  const inviterLastName = query.get("last_name") || "";

  const [loginModalOpen, setLoginModalOpen] = React.useState(false);
  const [signUpModalOpen, setSignUpModalOpen] = React.useState(false);

  const [youNeedToInviteBuyerOpen, setYouNeedToInviteBuyerOpen] =
    React.useState(false);

  const [noBuyerShareWithYourTeam, setNoBuyerShareWithYourTeam] =
    React.useState(false);

  const [requestForRespondDialog, setRequestForRespondDialog] =
    React.useState<LegalReviewRequest | null>(null);
  const [respondRequestOpen, setRespondRequestOpen] = React.useState(false);

  const [enquiryForEnquiryDialog, setEnquiryForEnquiryDialog] =
    React.useState<Enquiry | null>(null);
  const [respondEnquiryOpen, setRespondEnquiryOpen] = React.useState(false);

  const [sentResponseDialogOpen, setSentResponseDialogOpen] =
    React.useState(false);

  const [downloadDialogOpen, setDownloadDialogOpen] = React.useState(false);

  React.useEffect(() => {
    if (!isLoggedIn() && !isEmpty(invitedVerificationToken)) {
      setSignUpModalOpen(true);
    } else {
      if (isLoggedIn()) {
        activitiesMutate();
      } else {
        setLoginModalOpen(true);
      }
    }
  }, []);

  React.useEffect(() => {
    if (contract) {
      setTitle(contract.title);
    }
  }, [contract]);

  const onEditButton = () => {
    setIsEditing(true);
  };

  const onCancelButton = () => {
    setIsEditing(false);
  };

  const onSaveButton = () => {
    // Update Record name
    api
      .post(`/contract/${contractId}/record/${recordId}/update-record/`, {
        title: title,
      })
      .then((response) => {
        setIsEditing(false);
        contractMutate();
        setSuccessMessage("Record title has been updated");
      })
      .catch((error) => {
        setIsEditing(false);

        setErrorMessage(parseApiError(error));
      });
  };

  const requestsForName = !isEmpty(contract?.title)
    ? `${contract?.title}`
    : "...";

  const onRespondRequest = (request: LegalReviewRequest) => {
    const sentOrCompleted =
      request.seller_status === BuyerSellerStatus.Sent ||
      request.seller_status === BuyerSellerStatus.Completed;

    if (sentOrCompleted) {
      setSentResponseDialogOpen(true);
    } else {
      setRequestForRespondDialog(request);
      setRespondRequestOpen(true);
    }
  };

  const onRespondEnquiry = (enquiry: Enquiry) => {
    const sentOrCompleted =
      enquiry.seller_status === BuyerSellerStatus.Sent ||
      enquiry.seller_status === BuyerSellerStatus.Completed;

    if (sentOrCompleted) {
      setSentResponseDialogOpen(true);
    } else {
      setEnquiryForEnquiryDialog(enquiry);
      setRespondEnquiryOpen(true);
    }
  };

  if (
    !isNil(requestsData) &&
    !isNil(enquiriesData) &&
    isEmpty(requests) &&
    isEmpty(enquiries) &&
    userTeamMemberRole === TeamMemberRole.buyerLawyer
  ) {
    return <StartAddingRequests />;
  }

  return (
    <>
      <AlertWrapper>
        <Box display="flex" flexDirection="column">
          <LegalReviewWhiteHeader
            property={property}
            onInviteClicked={() => {
              setInviteModalOpen(true);
            }}
          />

          {!isMobile && contractId && recordId && (
            <Box display="flex" flexDirection="row" justifyContent="center">
              <Box
                maxWidth="726px"
                width="100%"
                maxHeight="calc(100vh - 72px)"
                height="100%"
                // overflow="auto"
                className="scrollbar-hidden"
                pl={1}
                pr={1}
                boxSizing="border-box"
              >
                <Box mt={3}>
                  <LegalReviewProgressBar
                    width="auto"
                    contractId={+contractId}
                    recordId={+recordId}
                  />
                </Box>
                <Box
                  mt={3}
                  mb={3}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" flexDirection={"row"}>
                    {!isEditing ? (
                      <>
                        <Typography
                          variant="h6"
                          style={{ fontWeight: 700, marginRight: "5px" }}
                        >
                          Requests for
                        </Typography>
                        <span
                          style={{
                            color: Colors.BrandPrimary,
                            marginRight: "8px",
                            fontWeight: 700,
                            fontVariant: "inherit",
                            fontSize: "1.25rem",
                            fontFamily: "GT Walsheim Pro",
                            lineHeight: "1.6",
                          }}
                        >
                          {requestsForName}
                        </span>
                        {userTeamMemberRole === TeamMemberRole.buyerLawyer && (
                          <EditButton onClick={onEditButton} />
                        )}
                      </>
                    ) : (
                      <>
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          width="100%"
                        >
                          <Typography
                            variant="h6"
                            style={{ fontWeight: 700, marginRight: "8px" }}
                          >
                            Requests for
                          </Typography>
                          <Box mr={1} height="55px">
                            <TextField
                              value={title}
                              onChange={(text) => setTitle(text)}
                            />
                          </Box>
                          <Box mr={1}>
                            <Button
                              title="SAVE"
                              size={ButtonSize.medium}
                              type={ButtonType.primary}
                              onClick={onSaveButton}
                              width="63px"
                            />
                          </Box>
                          <Box mr={1}>
                            <Button
                              title="CANCEL"
                              size={ButtonSize.medium}
                              type={ButtonType.secondary}
                              onClick={onCancelButton}
                              width="83px"
                            />
                          </Box>
                        </Box>
                      </>
                    )}

                    {property &&
                      requests &&
                      enquiries &&
                      contractId &&
                      recordId &&
                      userTeamMemberRole && (
                        <DownloadButton
                          onClick={() => setDownloadDialogOpen(true)}
                        />
                      )}
                  </Box>
                </Box>
                <PageSection title="AMENDMENT REQUESTS" width="100%" />
                {requests.map((request) => (
                  <RequestItem
                    key={`request-${request.pk}`}
                    type={RequestItemType.request}
                    request={request}
                    onComment={() => {
                      setRequestForCommentsDialog(request);
                      setRequestCommentsDialogOpen(true);
                    }}
                    userTeamMemberRole={userTeamMemberRole}
                    onRespond={() => onRespondRequest(request)}
                  />
                ))}

                <PageSection title="GENERAL ENQUIRIES" width="100%" />
                {enquiries.map((enquiry) => (
                  <RequestItem
                    key={`enquiry-${enquiry.pk}`}
                    type={RequestItemType.enquiry}
                    enquiry={enquiry}
                    onComment={() => {
                      setEnquiryForCommentsDialog(enquiry);
                      setEnquiryCommentsDialogOpen(true);
                    }}
                    userTeamMemberRole={userTeamMemberRole}
                    onRespond={() => onRespondEnquiry(enquiry)}
                  />
                ))}
              </Box>
              {/* </Box> */}

              <Box width="353px" ml="19px">
                <Box
                  width="100%"
                  bgcolor={Colors.BrandBackground3}
                  pl="24px"
                  pr="24px"
                  pt="16px"
                  pb="16px"
                  borderRadius="4px"
                  mt="24px"
                >
                  {(userTeamMemberRole === TeamMemberRole.buyer ||
                    userTeamMemberRole === TeamMemberRole.seller ||
                    userTeamMemberRole === TeamMemberRole.agent ||
                    userTeamMemberRole === TeamMemberRole.partner) && (
                    <>
                      <Button
                        type={ButtonType.primary}
                        size={ButtonSize.large}
                        title="VIEW AMENDMENT APPENDIX"
                        onClick={onViewAmendmentAppendix}
                        width="100%"
                        rightImage={ViewAmendmentAppendixIcon}
                      />
                    </>
                  )}

                  {userTeamMemberRole === TeamMemberRole.buyerLawyer &&
                    !requestsSent && (
                      <>
                        <Button
                          type={ButtonType.primary}
                          size={ButtonSize.large}
                          title="EDIT REQUESTS"
                          onClick={onManageRequests}
                          width="100%"
                        />

                        <Box height="16px" />

                        {!isEmpty(requestsSentMessage) && (
                          <>
                            <Typography
                              variant="body2"
                              style={{ textAlign: "center" }}
                            >
                              {requestsSentMessage}
                            </Typography>

                            <Box height="16px" />
                          </>
                        )}

                        <Button
                          type={ButtonType.secondary}
                          size={ButtonSize.medium}
                          title="SEND REQUESTS"
                          onClick={onSendRequests}
                          width="100%"
                        />
                      </>
                    )}

                  {userTeamMemberRole === TeamMemberRole.buyerLawyer &&
                    requestsSent && (
                      <>
                        <Button
                          type={ButtonType.primary}
                          size={ButtonSize.large}
                          title="EDIT REQUESTS"
                          onClick={onManageRequests}
                          width="100%"
                        />

                        <Box height="16px" />

                        {!isEmpty(requestsSentMessage) && (
                          <>
                            <Typography
                              variant="body2"
                              style={{ textAlign: "center" }}
                            >
                              {requestsSentMessage}
                            </Typography>

                            <Box height="16px" />
                          </>
                        )}

                        <Button
                          type={ButtonType.secondary}
                          size={ButtonSize.medium}
                          title={"SEND UPDATES"}
                          onClick={onSendRequests}
                          width="100%"
                          // disabled={!canSend()}
                        />

                        <Box height="16px" />

                        <Button
                          type={ButtonType.secondary}
                          size={ButtonSize.medium}
                          title="VIEW AMENDMENT APPENDIX"
                          onClick={onViewAmendmentAppendix}
                          width="100%"
                        />
                      </>
                    )}

                  {userTeamMemberRole === TeamMemberRole.sellerLawyer && (
                    <>
                      {!isEmpty(requestsSentMessage) && (
                        <>
                          <Typography
                            variant="body2"
                            style={{ textAlign: "center" }}
                          >
                            {requestsSentMessage}
                          </Typography>

                          <Box height="16px" />
                        </>
                      )}

                      <Button
                        type={ButtonType.primary}
                        size={ButtonSize.large}
                        title="SEND RESPONSES"
                        onClick={onSendResponses}
                        width="100%"
                        // disabled={!canSend()}
                      />

                      <Box height="16px" />

                      <Button
                        type={ButtonType.secondary}
                        size={ButtonSize.medium}
                        title="VIEW AMENDMENT APPENDIX"
                        onClick={onViewAmendmentAppendix}
                        width="100%"
                      />
                    </>
                  )}

                  {userTeamMemberRole !== TeamMemberRole.agent &&
                    userTeamMemberRole !== TeamMemberRole.partner && (
                      <>
                        <Box height="16px" />

                        <Button
                          type={ButtonType.secondary}
                          size={ButtonSize.small}
                          title="HELP GUIDE"
                          onClick={() => setHelpModalOpen(true)}
                          width="100%"
                        />
                      </>
                    )}
                </Box>

                <Box display="flex" flexDirection="column" height="100%">
                  <Typography variant="h6" style={{ marginTop: "24px" }}>
                    Activity Log
                  </Typography>

                  <ActivityLog
                    activities={activities}
                    maxHeight="calc(100vh - 500px)"
                  />
                </Box>
              </Box>
            </Box>
          )}

          {isMobile && contractId && recordId && (
            <Box
              pt="13px"
              pl="16px"
              pr="16px"
              pb="55px"
              bgcolor={Colors.Grey5}
              minHeight="90vh"
            >
              <LegalReviewProgressBar
                contractId={+contractId}
                recordId={+recordId}
              />

              <Box
                mt="17px"
                mb="17px"
                ml="5px"
                mr="5px"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" flexDirection={"row"}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 400, marginRight: "8px" }}
                  >
                    {!isEditing ? (
                      <>
                        <span
                          style={{
                            color: Colors.BrandPrimary,
                            marginRight: "16px",
                            fontWeight: 700,
                            fontVariant: "inherit",
                            fontSize: "1.25rem",
                            fontFamily: "GT Walsheim Pro",
                            lineHeight: "1.6",
                          }}
                        >
                          {requestsForName}
                        </span>
                        {userTeamMemberRole === TeamMemberRole.buyerLawyer && (
                          <EditButton onClick={onEditButton} />
                        )}
                      </>
                    ) : (
                      <>
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          width="100%"
                        >
                          <Box mr={1} height="55px">
                            <TextField
                              value={title}
                              onChange={(text) => setTitle(text)}
                            />
                          </Box>
                          <Box mr={1}>
                            <Button
                              title="SAVE"
                              size={ButtonSize.medium}
                              type={ButtonType.primary}
                              onClick={onSaveButton}
                              width="63px"
                            />
                          </Box>
                          <Box>
                            <Button
                              title="CANCEL"
                              size={ButtonSize.medium}
                              type={ButtonType.secondary}
                              onClick={onCancelButton}
                              width="83px"
                            />
                          </Box>
                        </Box>
                      </>
                    )}
                  </Typography>

                  {property &&
                    requests &&
                    enquiries &&
                    contractId &&
                    recordId &&
                    userTeamMemberRole && (
                      <Box
                        mr={3}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <DownloadButton
                          onClick={() => setDownloadDialogOpen(true)}
                        />
                      </Box>
                    )}
                </Box>

                <Link
                  style={{
                    color: Colors.Link,
                    cursor: "pointer",
                  }}
                  onClick={() => setActivityLogDrawerOpen(true)}
                >
                  <Typography variant="body2" style={{ fontWeight: 700 }}>
                    Activity log
                  </Typography>
                </Link>
              </Box>

              <PageSection title="AMENDMENT REQUESTS" width="100%" />
              {requests.map((request) => (
                <RequestItem
                  key={`request-${request.pk}`}
                  type={RequestItemType.request}
                  request={request}
                  onComment={() => {
                    setRequestForCommentsDialog(request);
                    setRequestCommentsDialogOpen(true);
                  }}
                  userTeamMemberRole={userTeamMemberRole}
                  onRespond={() => onRespondRequest(request)}
                />
              ))}

              <PageSection title="GENERAL ENQUIRIES" width="100%" />
              {enquiries.map((enquiry) => (
                <RequestItem
                  key={`enquiry-${enquiry.pk}`}
                  type={RequestItemType.enquiry}
                  enquiry={enquiry}
                  onComment={() => {
                    setEnquiryForCommentsDialog(enquiry);
                    setEnquiryCommentsDialogOpen(true);
                  }}
                  userTeamMemberRole={userTeamMemberRole}
                  onRespond={() => onRespondEnquiry(enquiry)}
                />
              ))}

              <MobileStickyFooter
                onBuilderOrResponder={() => {
                  if (userTeamMemberRole === TeamMemberRole.buyerLawyer) {
                    onManageRequests();
                  }

                  if (userTeamMemberRole === TeamMemberRole.sellerLawyer) {
                    onSendResponses();
                  }
                }}
                onMoreOptions={() => setFooterDrawerOpen(true)}
                requestsSent={requestsSent}
                role={userTeamMemberRole}
                onViewAppendix={onViewAmendmentAppendix}
                onHelpGuide={() => setHelpModalOpen(true)}
              />
            </Box>
          )}
        </Box>
      </AlertWrapper>

      <InviteModal
        open={inviteModalOpen}
        onClose={() => setInviteModalOpen(false)}
        onInviteTeamMembersClicked={(userTeamMemberRole) => {
          setInviteModalOpen(false);
          setInviteYourTeamMembersType(InviteYourTeamMembersModalType.default);
          setInviteYourTeamMembersUserTeamRole(userTeamMemberRole);
          setInviteYourTeamMembersOpen(true);
        }}
        onInviteAgents={() => {
          setInviteModalOpen(false);
          setInviteYourTeamMembersType(InviteYourTeamMembersModalType.agents);
          setInviteYourTeamMembersUserTeamRole(null);
          setInviteYourTeamMembersOpen(true);
        }}
        contractId={contractId}
        recordId={recordId}
      />

      <InviteYourTeamMembersModal
        open={inviteYourTeamMembersOpen}
        onClose={() => setInviteYourTeamMembersOpen(false)}
        onInvited={(firstName, lastName) => {
          setInviteYourTeamMembersOpen(false);
          setSuccessMessage(
            `${firstName} ${lastName} has been invited to your team`
          );

          refreshContractTeamsAndRole();
          contractMutate();
          setInviteModalOpen(true);
        }}
        contractId={contractId}
        recordId={recordId}
        userTeamMemberRole={inviteYourTeamMembersUserTeamRole}
        type={inviteYourTeamMembersType}
        forScreen={InviteYourTeamMembersModalForScreen.request}
      />

      <CommentsDialog
        type={CommentsDialogType.request}
        journey={CommentsDialogJourney.Request}
        contractId={contractId}
        recordId={recordId}
        request={requestForCommentsDialog}
        open={requestCommentsDialogOpen}
        onClose={() => {
          setRequestCommentsDialogOpen(false);

          requestsMutate();
        }}
      />

      <CommentsDialog
        type={CommentsDialogType.enquiry}
        journey={CommentsDialogJourney.Request}
        contractId={contractId}
        recordId={recordId}
        enquiry={enquiryForCommentsDialog}
        open={enquiryCommentsDialogOpen}
        onClose={() => {
          setEnquiryCommentsDialogOpen(false);

          enquiriesMutate();
        }}
      />

      <ActivityLogDrawer
        open={activityLogDrawerOpen}
        onClose={() => setActivityLogDrawerOpen(false)}
        activities={activities}
      />

      <ShareWithDialog
        type={ShareWithDialogType.otherParty}
        open={shareWithYourTeamOpen}
        title={shareWithTitle}
        message={shareWithMessage}
        onClose={() => setShareWithYourTeamOpen(false)}
        onNext={(membersCount) => {
          setShareWithYourTeamOpen(false);
          setAddAMessageOpen(true);
          contractMutate();
        }}
      />

      <NoBuyerShareWithDialog
        type={NoBuyerShareWithDialogType.otherParty}
        open={noBuyerShareWithYourTeam}
        title="Review name and recipients"
        message={shareWithMessage}
        onClose={() => setNoBuyerShareWithYourTeam(false)}
        onNext={(membersCount) => {
          setNoBuyerShareWithYourTeam(false);
          setAddAMessageOpen(true);
          contractMutate();
        }}
        contractId={contractId}
        recordId={recordId}
        contract={contract}
      />

      {contract && (
        <AddAMessageDialog
          open={addAMessageOpen}
          onCancel={() => setAddAMessageOpen(false)}
          onSend={onSendMessage}
          sending={sendingMessage}
          contract={contract}
          forScreen={AddAMessageDialogForScreen.requestworkspace}
          userTeamMemberRole={inviteYourTeamMembersUserTeamRole}
          userData={userData}
        />
      )}

      <AddAMessageSentDialog
        open={addAMessageSentOpen}
        onClose={() => setAddAMessageSentOpen(false)}
        content={messageSentMessage}
      />

      <HelpGuideModal
        open={helpModalOpen}
        title={helpModalTitle}
        onClose={() => setHelpModalOpen(false)}
        items={helpModalItems}
      />

      <MobileFooterDrawer
        open={footerDrawerOpen}
        onClose={() => setFooterDrawerOpen(false)}
        onHelpGuide={() => setHelpModalOpen(true)}
        requestsSent={requestsSent}
        role={userTeamMemberRole}
        onBuilderOrResponder={() => {
          if (userTeamMemberRole === TeamMemberRole.buyerLawyer) {
            onManageRequests();
          }

          if (userTeamMemberRole === TeamMemberRole.sellerLawyer) {
            onRespondToRequests();
          }
        }}
        onSend={onSendRequests}
        onSendResponses={onSendResponses}
        onViewAppendix={onViewAmendmentAppendix}
        canSend={canSend()}
      />

      <LoginModal
        open={loginModalOpen}
        contractId={contractId}
        recordId={recordId}
        onLoggedIn={() => {
          setLoginModalOpen(false);

          refreshUser(false);

          contractMutate();
          activitiesMutate();
          requestsMutate();
          enquiriesMutate();
        }}
        inviterName={`${inviterFirstName} ${inviterLastName}`.trim()}
      />

      <SignUpModal
        open={signUpModalOpen}
        onClose={() => setSignUpModalOpen(false)}
        contractId={contractId}
        inviterName={`${inviterFirstName} ${inviterLastName}`.trim()}
        inviterFirmName={orgName}
        token={invitedVerificationToken}
        onSignedUp={() => {
          setSignUpModalOpen(false);

          refreshUser(false);

          contractMutate();
          activitiesMutate();
          requestsMutate();
          enquiriesMutate();
        }}
        onLogin={() => {
          setSignUpModalOpen(false);
          setLoginModalOpen(true);
        }}
      />

      <YouNeedToInviteABuyerDialog
        open={youNeedToInviteBuyerOpen}
        onInviteBuyer={() => {
          setYouNeedToInviteBuyerOpen(false);
          setInviteYourTeamMembersType(InviteYourTeamMembersModalType.default);
          setInviteYourTeamMembersUserTeamRole(userTeamMemberRole);
          setInviteYourTeamMembersOpen(true);
        }}
        onCancel={() => setYouNeedToInviteBuyerOpen(false)}
      />

      <RespondRequestDialog
        open={respondRequestOpen}
        contractId={contractId}
        recordId={recordId}
        request={requestForRespondDialog}
        onClose={() => {
          setRespondRequestOpen(false);
        }}
        onSaved={() => {
          setRespondRequestOpen(false);
          requestsMutate();
        }}
      />

      <RespondEnquiryDialog
        open={respondEnquiryOpen}
        contractId={contractId}
        recordId={recordId}
        enquiry={enquiryForEnquiryDialog}
        onClose={() => {
          setRespondEnquiryOpen(false);
        }}
        onSaved={() => {
          setRespondEnquiryOpen(false);
          enquiriesMutate();
        }}
      />

      <YouHaveSentYourResponseDialog
        open={sentResponseDialogOpen}
        onClose={() => setSentResponseDialogOpen(false)}
      />

      {contract && recordId && userTeamMemberRole && (
        <DownloadDialog
          open={downloadDialogOpen}
          onCancel={() => setDownloadDialogOpen(false)}
          requests={requests}
          enquiries={enquiries}
          contract={contract}
          contractS={contractS}
          recordId={recordId}
          property={property}
          userTeamMemberRole={userTeamMemberRole}
          requestsForName={requestsForName}
        />
      )}
    </>
  );
}
