import { Box, Typography, IconButton } from "@material-ui/core";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { Enquiry, EnquiryStage, EnquiryStatus } from "../../api/models";
import Separator from "../../components/common/Separator";
import { useMobile } from "../../hooks/mobile";
import DraggableIcon from "../../images/draggable-icon.svg";
import Button, { ButtonSize, ButtonType } from "../common/Button";
import StatusPill, {
  StatusPillType,
  statusPillTypeForEnquiry,
} from "../StatusPill";
import ExpandIcon from "../../images/chevron-down.svg";
import { generalEnquiriesText } from "./constants";
import { Colors } from "../../constants/colors";
import moment from "moment";
import { isNil } from "lodash-es";

interface Props {
  enquiry: Enquiry;
  index: number;
  onEdit: () => unknown;
  onViewComments: () => unknown;
  onRemove: () => unknown;
}

interface HeaderProps {
  enquiry: Enquiry;
}

function Header({ enquiry }: HeaderProps) {
  const isMobile = useMobile();
  const statusPillType = statusPillTypeForEnquiry(enquiry);

  if (isMobile) {
    return (
      <Box
        mt="8px"
        height="55px"
        display="flex"
        flexDirection="row"
        alignItems="center"
        pl="16px"
        pr="16px"
      >
        <Box display="flex" flexDirection="row" alignItems="center" flex={1}>
          <Typography variant="body1" style={{ fontWeight: 700 }}>
            {enquiry.title}
          </Typography>
        </Box>

        <StatusPill type={statusPillType} />
      </Box>
    );
  }

  return (
    <Box
      height="77px"
      boxShadow="0px 1px 0px #E0E0E0"
      display="flex"
      flexDirection="row"
      alignItems="center"
      pl="24px"
      pr="24px"
    >
      <Box display="flex" flexDirection="row" alignItems="center" flex={1}>
        <img src={DraggableIcon} style={{ marginRight: "8px" }} />

        <Typography variant="h6" style={{ fontWeight: 700 }}>
          {enquiry.title}
        </Typography>
      </Box>

      <StatusPill type={statusPillType} />
    </Box>
  );
}

interface ContentProps {
  enquiry: Enquiry;
}

function Content({ enquiry }: ContentProps) {
  const isMobile = useMobile();

  const [showGeneralEnq, setShowGeneralEnq] = React.useState(false);

  if (isMobile) {
    return (
      <Box display="flex" flexDirection="column" whiteSpace="pre-line">
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          mb="16px"
          pl="16px"
          pr="16px"
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="body2" style={{ fontWeight: 700, flex: 1 }}>
              General Enquiries
            </Typography>
            <IconButton onClick={() => setShowGeneralEnq(!showGeneralEnq)}>
              <img src={ExpandIcon} />
            </IconButton>
          </Box>

          {showGeneralEnq && (
            <Typography variant="body2">{generalEnquiriesText}</Typography>
          )}
        </Box>
        <Separator />

        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          mt="16px"
          mb="16px"
          pl="16px"
          pr="16px"
        >
          <Typography variant="body2" style={{ fontWeight: 700, flex: 1 }}>
            Enquiry
          </Typography>

          <Box height="8px" />

          <Typography variant="body2">{enquiry.enquiry}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="row" m="24px" whiteSpace="pre-line">
      <Box display="flex" flexDirection="column" flex={1}>
        <Typography variant="body1" style={{ fontWeight: 700 }}>
          General Enquiry
        </Typography>

        <Box height="8px" />

        <Typography variant="body2">{enquiry.enquiry}</Typography>

        {enquiry.answer_to_enquiry && (
          <>
            <Typography
              variant="body1"
              style={{ fontWeight: 700, marginTop: "16px" }}
            >
              Answer
            </Typography>

            <Box height="8px" />

            <Typography variant="body2">{enquiry.answer_to_enquiry}</Typography>
          </>
        )}
      </Box>

      <Box display="flex" flexDirection="column" flex={1}>
        <Typography variant="body1" style={{ fontWeight: 700 }}>
          Description
        </Typography>

        <Box height="8px" />

        <Typography variant="body2">{generalEnquiriesText}</Typography>
      </Box>
    </Box>
  );
}

interface FooterProps {
  enquiry: Enquiry;
  onEditRequest: () => unknown;
  onViewComments: () => unknown;
  onRemove: () => unknown;
}

function Footer({
  enquiry,
  onEditRequest,
  onViewComments,
  onRemove,
}: FooterProps) {
  const isMobile = useMobile();

  const _commentsCount = () => {
    if (enquiry.p_commentsCount > 0) {
      return enquiry.p_commentsCount;
    }

    return enquiry.comments_count || 0;
  };

  const _messagesCount = () => {
    if (enquiry.p_messagesCount > 0) {
      return enquiry.p_messagesCount;
    }

    return enquiry.messages_count || 0;
  };

  const commentsCount = _commentsCount();
  const messagesCount = _messagesCount();

  const isSent = !isNil(enquiry.sent_on);

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      pl="16px"
      pr="16px"
      justifyContent="space-between"
      height="74px"
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        <Button
          title={isMobile ? "COMMENTS" : "VIEW COMMENTS"}
          type={ButtonType.transparent}
          size={ButtonSize.medium}
          onClick={onViewComments}
          letterSpacing="2px"
          width={isMobile ? "115px" : "179px"}
        />
        {!isMobile && (
          <Typography variant="body2" style={{ marginLeft: "8px" }}>
            {commentsCount + messagesCount} Comments
          </Typography>
        )}
      </Box>

      <Box display="flex" flexDirection="row" alignItems="center">
        {!isMobile && isSent && (
          <Typography
            variant="body2"
            style={{ color: Colors.Grey3, marginRight: "16px" }}
          >
            Sent on {moment(enquiry.sent_on).format("D MMM [at] h:mm A")}
          </Typography>
        )}

        {!isMobile && !isSent && (
          <Typography
            variant="body2"
            style={{ color: Colors.Grey3, marginRight: "16px" }}
          >
            Not sent
          </Typography>
        )}
        {!enquiry.is_sent &&
          enquiry.enquiry_status === EnquiryStage.Pending && (
            <Button
              type={ButtonType.secondary}
              size={ButtonSize.medium}
              title="EDIT REQUEST"
              width="163px"
              onClick={onEditRequest}
            />
          )}

        {enquiry.is_sent && !enquiry.seller_responded && (
          <Button
            type={ButtonType.destructive}
            size={ButtonSize.medium}
            title="DELETE"
            width="105px"
            onClick={onRemove}
          />
        )}

        {enquiry.enquiry_status === EnquiryStage.Answered && (
          <Button
            type={ButtonType.destructive}
            size={ButtonSize.medium}
            title="REMOVE"
            width="115px"
            onClick={onRemove}
          />
        )}
      </Box>
    </Box>
  );
}

export default function EnquiryItem({
  enquiry,
  index,
  onEdit,
  onViewComments,
  onRemove,
}: Props) {
  const isMobile = useMobile();

  return (
    <Draggable
      draggableId={`enquiry-item-${enquiry.pk}-${index}`}
      index={index}
    >
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Box
            border="1px solid #E1EAFF"
            bgcolor="white"
            borderRadius="16px"
            m="10px"
            display="flex"
            flexDirection="column"
            width={isMobile ? "95vw" : "1097px"}
          >
            <Header enquiry={enquiry} />

            <Content enquiry={enquiry} />

            <Separator />

            <Footer
              enquiry={enquiry}
              onViewComments={onViewComments}
              onEditRequest={onEdit}
              onRemove={onRemove}
            />
          </Box>
        </div>
      )}
    </Draggable>
  );
}
