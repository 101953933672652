import React, { useState, createContext } from "react";

interface Props {
  errorMessage: string | null;
  setErrorMessage: (message: string | null) => unknown;
  successMessage: string | null;
  setSuccessMessage: (message: string | null) => unknown;
}

// Create Context Object
export const AlertContext = createContext({} as Props);

export const useAlert = () => {
  return React.useContext(AlertContext);
};

// Create a provider for components to consume and subscribe to changes
export const AlertContextProvider = (props: React.PropsWithChildren<{}>) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const value = {
    errorMessage,
    setErrorMessage,
    successMessage,
    setSuccessMessage,
  };

  return (
    <AlertContext.Provider value={value}>
      {props.children}
    </AlertContext.Provider>
  );
};
