import React, { CSSProperties } from "react";
import ReactAsyncSelect, {
  Props as ReactAsyncSelectProps,
} from "react-select/async";
import { components } from "react-select";

import { Box, Typography } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import { selectStyles, formatOptionLabel } from "./SelectStyles";
import DropdownArrow from "../../images/DropdownArrow.png";

interface Props extends ReactAsyncSelectProps<{}> {
  title?: string | null | undefined;
  error?: boolean;
  helperText?: string | null;
  dropdownImage?: string | null;
}

export default function AsyncSelect({
  title,
  error,
  helperText,
  dropdownImage,
  ...rest
}: Props): JSX.Element {
  return (
    <Box display="flex" flexDirection="column" flex={1} height={78}>
      {title && (
        <Typography
          variant="body2"
          style={{ fontWeight: 700, marginBottom: "4px", color: Colors.Grey1 }}
        >
          {title}
        </Typography>
      )}
      <ReactAsyncSelect
        styles={selectStyles(error) as any}
        formatOptionLabel={formatOptionLabel}
        components={{
          DropdownIndicator: (props: any) => {
            return (
              <components.DropdownIndicator {...props}>
                <img src={dropdownImage || DropdownArrow} />
              </components.DropdownIndicator>
            );
          },
          ValueContainer: ({ children, ...props }) => {
            return (
              <components.ValueContainer {...props}>
                <Typography variant="body1">{children}</Typography>
              </components.ValueContainer>
            );
          },
        }}
        {...rest}
      />
      <Box height="14px">
        {helperText && (
          <Typography
            variant="caption"
            style={{ color: error ? Colors.Error : Colors.Grey2 }}
          >
            {helperText}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
