import React from "react";
import { Box, ButtonBase } from "@material-ui/core";
import { isNil } from "lodash-es";
import { Colors } from "../../constants/colors";
import {
  makeStyles,
  createStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import CircularProgress, {
  CircularProgressProps,
} from "@material-ui/core/CircularProgress";

const useStylesProgress = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
    },
    bottom: {
      color: Colors.Grey5,
    },
    top: {
      color: Colors.BrandPrimary,
      animationDuration: "550ms",
      position: "absolute",
      left: 0,
    },
    circle: {
      strokeLinecap: "round",
    },
  })
);

interface Props {
  type?: ButtonType;
  size?: ButtonSize;
  width?: number | string;
  height?: number;
  title?: string;
  leftImage?: string;
  rightImage?: string;
  onClick?: () => unknown;
  disabled?: boolean;
  loading?: boolean;
  sidePadding?: number;
  letterSpacing?: string;
  titleColor?: string;
  image?: string;
}

export enum ButtonType {
  secondary,
  primary,
  grey,
  white,
  destructive,
  transparent,
  light,
  dark,
}

// Sizes dictate button height and font sizes
export enum ButtonSize {
  small,
  medium,
  large,
}

export default function Button({
  type = ButtonType.primary,
  size = ButtonSize.medium,
  width,
  height,
  title,
  leftImage,
  rightImage,
  onClick,
  disabled,
  loading = false,
  sidePadding,
  letterSpacing,
  titleColor,
  image,
}: Props): JSX.Element {
  const progressClasses = useStylesProgress();

  const buttonHeight = () => {
    if (height) {
      return height;
    }

    switch (size) {
      case ButtonSize.small:
        return 34;
      case ButtonSize.medium:
        return 42;
      case ButtonSize.large:
        return 60;
    }
  };

  const fontSize = () => {
    switch (size) {
      case ButtonSize.small:
        return 12;
      case ButtonSize.medium:
        return 14;
      case ButtonSize.large:
        return 16;
    }
  };

  const borderRadius = () => {
    switch (size) {
      case ButtonSize.small:
        return "6px";
      case ButtonSize.medium:
        return "6px";
      case ButtonSize.large:
        return "8px";
    }
  };

  const buttonStyle = () => {
    const style: any = {
      width,
      height: buttonHeight(),
      borderRadius: borderRadius(),
    };

    switch (type) {
      case ButtonType.secondary:
      case ButtonType.destructive:
        style.backgroundColor = "#FFFFFF";
        style.border = "1px solid #ECF2FB";
        break;

      case ButtonType.primary:
        style.backgroundColor = Colors.BrandPrimary;
        break;

      case ButtonType.grey:
        style.backgroundColor = Colors.Grey4;
        break;

      case ButtonType.transparent:
        style.backgroundColor = "transparent";
        break;

      case ButtonType.white:
        style.backgroundColor = "white";
        break;

      case ButtonType.light:
        style.backgroundColor = Colors.LightBackground;
        break;

      case ButtonType.dark:
        style.backgroundColor = "#111111";
        break;
    }

    if (disabled) {
      style.opacity = 0.3;
    }

    return style;
  };

  const titleStyle = () => {
    const style: any = {
      fontFamily: "GT Walsheim Pro",
      fontWeight: 700,
      fontSize: fontSize(),
      letterSpacing: "2px",
    };

    switch (type) {
      case ButtonType.secondary:
        style.color = "#0E448D";
        break;

      case ButtonType.primary:
        style.color = "#FFFFFF";
        break;

      case ButtonType.grey:
        style.color = Colors.BrandPrimary;
        break;

      case ButtonType.white:
        style.color = Colors.BrandPrimary;
        break;

      case ButtonType.transparent:
        style.color = Colors.BrandPrimary;
        style.letterSpacing = 0;

        break;

      case ButtonType.destructive:
        style.color = "#F95555";
        break;

      case ButtonType.light:
        style.color = "#0235d1";
        style.letterSpacing = "1px";
        style.fontSize = "18px";
        style.lineHeight = "20px";
        style.fontWeight = 400;
        break;

      case ButtonType.dark:
        style.color = "#FFFFFF";
        style.letterSpacing = "2px";
        style.fontSize = "16px";
        style.lineHeight = "18px";
        style.fontWeight = 700;
        break;
    }
    // Overrides
    if (letterSpacing) {
      style.letterSpacing = letterSpacing;
    }

    if (titleColor) {
      style.color = titleColor;
    }

    return style;
  };

  const boxProps = isNil(width)
    ? {
        pl: sidePadding || 4,
        pr: sidePadding || 4,
      }
    : {};

  function ButtonContent() {
    return (
      <Box display="flex" flexDirection="row" alignItems="center" {...boxProps}>
        {leftImage && <img style={{ marginRight: "8px" }} src={leftImage} />}

        <Box style={titleStyle()}>{title}</Box>

        {rightImage && <img style={{ marginLeft: "8px" }} src={rightImage} />}

        {image && <img style={{ padding: "2px" }} src={image} />}
      </Box>
    );
  }

  function ButtonLoader() {
    return (
      <CircularProgress
        variant="indeterminate"
        className={progressClasses.bottom}
        size={buttonHeight() * 0.6}
        thickness={4}
        style={{
          color: titleStyle().color,
        }}
      />
    );
  }

  return (
    <ButtonBase
      style={buttonStyle()}
      focusRipple={true}
      onClick={(event) => {
        if (loading === false) {
          event.stopPropagation();

          onClick && onClick();
        }
      }}
      disabled={disabled}
    >
      {loading === true ? <ButtonLoader /> : <ButtonContent />}
    </ButtonBase>
  );
}
