import React from "react";
import { Box, ButtonBase, Typography } from "@material-ui/core";
import { Colors } from "../../constants/colors";

interface Props {
  sections: string[];
  selectedSection: string;
  onSelectedSection: (section: string, index: number) => unknown;
  sectionWidth?: number | string;
}

export default function SectionControlUnderlined({
  sections,
  selectedSection,
  onSelectedSection,
  sectionWidth = "120px",
}: Props) {
  return (
    <Box display="flex" flexDirection="row" bgcolor="white">
      {sections.map((section, index) => (
        <ButtonBase
          onClick={(event) => {
            event.stopPropagation();
            onSelectedSection(section, index);
          }}
        >
          <Box width={sectionWidth} height="48px">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flex={1}
              height="46.67px"
              width="100%"
              bgcolor="white"
            >
              <Typography
                variant="body1"
                style={{ fontWeight: 700, color: Colors.BrandPrimary }}
              >
                {section}
              </Typography>
            </Box>
            <Box
              height="2px"
              width="100%"
              bgcolor={
                selectedSection === section ? Colors.BrandPrimary : Colors.Grey4
              }
            />
          </Box>
        </ButtonBase>
      ))}
    </Box>
  );
}
