import { Box } from "@material-ui/core";
import React from "react";
import Dropdown from "../../components/common/Dropdown";
import Wrapper from "../../components/UserAccount/Wrapper";
import { useMobile } from "../../hooks/mobile";
import UsageItem from "../../components/UserAccount/UsageItem";
import useSWR from "swr";
import { CreditUsage } from "../../api/models";
import moment from "moment";

const options = [
  {
    title: "Most recent",
    value: "most_recent",
  },
];

export default function Usage() {
  const isMobile = useMobile();

  const [sort, setSort] = React.useState("most_recent");

  const { data } = useSWR("/credits/");

  const sorter = (first: CreditUsage, second: CreditUsage) => {
    // Most recent
    return moment(first.claimed_at).isBefore(moment(second.claimed_at))
      ? 1
      : -1;
  };

  const creditUsages = data ? (data as CreditUsage[]).sort(sorter) : [];

  return (
    <Wrapper title="Usage">
      <Box
        display="flex"
        flexDirection={"column"}
        pt={isMobile ? "36px" : "55px"}
        pb="55px"
      >
        <Dropdown
          width={isMobile ? undefined : "280px"}
          title="Sort by:"
          options={options}
          selectedOption={options.find((option) => option.value === sort)}
          onSelectedOption={(option) => setSort(option.value)}
        />

        <Box mt="24px">
          {creditUsages.map((usage) => (
            <UsageItem usage={usage} />
          ))}
        </Box>
      </Box>
    </Wrapper>
  );
}
