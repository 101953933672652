import { Box, Typography } from "@material-ui/core";
import { isNil } from "lodash-es";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR, { useSWRConfig } from "swr";
import api from "../api/instance";
import {
  Activity,
  Contract,
  ContractPublishStatus,
  ContractSummary,
  ContractWorkflowStatus,
  Property,
  TeamMemberRole,
  User,
} from "../api/models";
import ActivityLog from "../components/ActivityLog/ActivityLog";
import AlertWrapper from "../components/AlertWrapper";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";
import CreditsRemainingText from "../components/CreditsRemainingText";
import LegalReviewProgressBar from "../components/LegalReviewProgressBar";
import InviteModalReadOnly, {
  InviteModalType,
} from "../components/LegalReviewViewer/InviteModalReadOnly";
import InviteYourTeamMembersModal, {
  InviteYourTeamMembersModalType,
} from "../components/LegalReviewViewer/InviteYourTeamMembersModal";
import LegalReviewWhiteHeader from "../components/LegalReviewWhiteHeader";
import OnboardingWrapper from "../components/OnboardingWrapper";
import UseCreditsDialog from "../components/PurchaseCredits/UseCreditsDialog";
import { useAlert } from "../contexts/Alert";
import { parseApiError } from "../helpers/error";
import { useMobile } from "../hooks/mobile";
import Hero from "../images/lawyer-hero.svg";
import { refreshContractTeamsAndRole } from "../stores/contract";
import { useCreditStore } from "../stores/credit";
import { usePurchaseStore } from "../stores/purchase";
import { useSessionStore } from "../stores/Session";

interface ContentProps {
  contractId: number;
  recordId: number;
  property: Property | null;
  legalReviewsPublished: boolean;
  numberOfDefaultReviews: number;
  onCreateWorkspace: () => unknown;
}

function Content({
  contractId,
  recordId,
  property,
  legalReviewsPublished,
  numberOfDefaultReviews,
  onCreateWorkspace,
}: ContentProps) {
  const isMobile = useMobile();
  const history = useHistory();

  const [loading, setLoading] = React.useState(false);

  const { setErrorMessage } = useAlert();

  return (
    <Box
      display="flex"
      flex={1}
      justifyContent="center"
      alignItems="center"
      height="80vh"
    >
      <Box
        width={isMobile ? "90%" : "385px"}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {!isMobile && (
          <LegalReviewProgressBar contractId={contractId} recordId={recordId} />
        )}

        <img
          src={Hero}
          style={{ marginTop: "16px", marginBottom: "10px" }}
          height={isMobile ? "200px" : undefined}
        />

        <Typography
          variant="h6"
          style={{ fontWeight: 700, textAlign: "center" }}
        >
          {/* {legalReviewsPublished
            ? "Start adding review items"
            : "Contrax is preparing review items for this contract"} */}
          Pay and create your workspace
        </Typography>
        <Typography
          variant="body2"
          style={{
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "40px",
          }}
        >
          {/* {legalReviewsPublished
            ? `Contrax has prepared ${numberOfDefaultReviews} review items for you to
          begin your review. Edit, add or dismiss them to create your legal
          review. Then share it with your client when you're ready.`
            : "We'll notify you when they're ready. Then edit, add or dismiss them to create your legal review and share it with your client when you're ready."} */}
          Create your workspace to get started on this contract.
        </Typography>

        <CreditsRemainingText mb="24px" />

        {property && (
          <>
            <Button
              type={ButtonType.primary}
              size={ButtonSize.large}
              title="CREATE WORKSPACE"
              width="301px"
              loading={loading}
              onClick={onCreateWorkspace}
            />
          </>
        )}
      </Box>
    </Box>
  );
}

export default function CreateWorkspace() {
  const isMobile = useMobile();

  const location = useLocation();
  const history = useHistory();

  const { cache } = useSWRConfig();

  const { setErrorMessage } = useAlert();

  const query = new URLSearchParams(location.search);
  const _contractId = query.get("contractId");
  const contractId = !isNil(_contractId) ? parseInt(_contractId) : null;
  const _recordId = query.get("recordId");
  const recordId = !isNil(_recordId) ? parseInt(_recordId) : null;
  const paymentSuccessful = query.get("paymentSuccessful") === "true";
  const testZeroCredits = query.get("testZeroCredits");

  const { data: userData, refresh: refreshUserData } = useSessionStore();
  const user = userData ? (userData as User) : null;

  const { availableCredits } = useCreditStore();
  const { setPurchaseJourneyStartPath, setPurchaseJourneySuccessPath } =
    usePurchaseStore();

  const { data, mutate: contractMutate } = useSWR(
    `/view-contracts/${contractId}/`
  );
  const contract = data ? (data as Contract) : null;

  const legalReviewsPublished =
    contract?.publish_status === ContractPublishStatus.Published &&
    contract?.workflow_status === ContractWorkflowStatus.Reviewed;

  const { data: propertyData, mutate: propertyMutate } = useSWR(
    contract?.property_id ? `/view-properties/${contract.property_id}/` : null
  );
  const property = propertyData && (propertyData as Property);

  const { data: activitiesData, mutate: activitiesMutate } = useSWR(
    !isNil(contractId)
      ? `/contract/${contractId}/record/${recordId}/activities/`
      : null
  );

  const activities = activitiesData ? (activitiesData as Activity[]) : [];

  const { refresh: refreshUser, refreshTimestamp } = useSessionStore();

  const [inviteYourTeamMembersOpen, setInviteYourTeamMembersOpen] =
    React.useState(false);

  const [inviteModalOpen, setInviteModalOpen] = React.useState(false);
  const [useCreditsOpen, setUseCreditsOpen] = React.useState(false);
  const [usingCredit, setUsingCredit] = React.useState(false);

  const [inviteYourTeamMembersType, setInviteYourTeamMembersType] =
    React.useState(InviteYourTeamMembersModalType.default);

  const [
    inviteYourTeamMembersUserTeamRole,
    setInviteYourTeamMembersUserTeamRole,
  ] = React.useState<TeamMemberRole | null>(null);

  // Get Default legal review summaries for count
  const { data: defaultSummariesData } = useSWR(
    !isNil(contractId)
      ? `contract/${contractId}/record/${recordId}/contrax-summaries/`
      : null
  );
  const defaultSummaries = defaultSummariesData
    ? (defaultSummariesData as ContractSummary[])
    : [];

  React.useEffect(() => {
    refreshUserData(false);
  }, []);

  React.useEffect(() => {
    if (paymentSuccessful) {
      setUseCreditsOpen(true);
    }
  }, []);

  React.useEffect(() => {
    if (!isNil(contractId)) {
      refreshContractTeamsAndRole();
    }
  }, [contractId]);

  React.useEffect(() => {
    user && refreshContractTeamsAndRole();
  }, [user, refreshTimestamp]);

  const { setSuccessMessage } = useAlert();

  const onLoggedIn = () => {
    contractMutate();
    activitiesMutate();
  };

  const onSignedUp = () => {
    contractMutate();
    activitiesMutate();
  };

  const onCreateWorkspace = () => {
    if (availableCredits === 0 || testZeroCredits === "true") {
      setPurchaseJourneyStartPath(`${location.pathname}${location.search}`);
      setPurchaseJourneySuccessPath(
        `/create-workspace?contractId=${contractId}&recordId=${recordId}&type=buying&paymentSuccessful=true`
      );

      history.push("/plans");
      return;
    }

    setUseCreditsOpen(true);
  };

  const onUseCredit = () => {
    setUsingCredit(true);

    api
      .post("/credits/claim_record/", {
        record_id: recordId,
      })
      .then((response) => {
        setUsingCredit(false);
        setSuccessMessage("Workspace created");

        // Remove cached contracts to force refresh
        cache.delete("buying-contracts/");

        // Change page
        history.push(
          `/legal-review-viewer?contractId=${contractId}&recordId=${recordId}`
        );
      })
      .catch((error) => {
        setUsingCredit(false);
        setErrorMessage(parseApiError(error));
      });
  };

  return (
    <OnboardingWrapper onLoggedIn={onLoggedIn} onSignedUp={onSignedUp}>
      <AlertWrapper>
        <Box display="flex" flexDirection="column">
          <LegalReviewWhiteHeader
            property={property}
            onInviteClicked={() => {
              setInviteModalOpen(true);
            }}
          />

          {isMobile && contractId && recordId && (
            <Box>
              <Content
                contractId={+contractId}
                recordId={+recordId}
                property={property}
                legalReviewsPublished={legalReviewsPublished}
                numberOfDefaultReviews={defaultSummaries.length}
                onCreateWorkspace={onCreateWorkspace}
              />
            </Box>
          )}

          {!isMobile && contractId && recordId && (
            <Box display="flex" flexDirection="row">
              <Content
                contractId={+contractId}
                recordId={+recordId}
                property={property}
                legalReviewsPublished={legalReviewsPublished}
                numberOfDefaultReviews={defaultSummaries.length}
                onCreateWorkspace={onCreateWorkspace}
              />
              <Box mt="40px" width="353px">
                <Typography variant="h6">Activity Log</Typography>
                <ActivityLog activities={activities} />
              </Box>
            </Box>
          )}
        </Box>
      </AlertWrapper>

      <InviteYourTeamMembersModal
        open={inviteYourTeamMembersOpen}
        onClose={() => setInviteYourTeamMembersOpen(false)}
        onInvited={(firstName, lastName) => {
          setInviteYourTeamMembersOpen(false);
          setSuccessMessage(
            `${firstName} ${lastName} has been invited to your team`
          );

          refreshContractTeamsAndRole();

          setInviteModalOpen(true);
        }}
        contractId={contractId}
        recordId={recordId}
        userTeamMemberRole={inviteYourTeamMembersUserTeamRole}
        type={inviteYourTeamMembersType}
      />

      <InviteModalReadOnly
        type={InviteModalType.buying}
        open={inviteModalOpen}
        onClose={() => setInviteModalOpen(false)}
        contractId={contractId}
        recordId={recordId}
      />

      <UseCreditsDialog
        open={useCreditsOpen}
        onUseCredit={onUseCredit}
        onCancel={() => setUseCreditsOpen(false)}
        loading={usingCredit}
        paymentSuccessful={paymentSuccessful}
      />
    </OnboardingWrapper>
  );
}
