import { Box, Dialog, Typography } from "@material-ui/core";
import React from "react";
import { useMobile } from "../../hooks/mobile";
import Button, { ButtonSize, ButtonType } from "../common/Button";
import Separator from "../common/Separator";

interface Props {
  open: boolean;
  onClose: () => unknown;
}

export default function YouHaveSentYourResponseDialog({
  open,
  onClose,
}: Props) {
  const isMobile = useMobile();

  return (
    <Dialog open={open} fullScreen={isMobile}>
      <Box
        width={isMobile ? "100%" : "524px"}
        display="flex"
        flexDirection="column"
      >
        <Box boxSizing="border-box" pl="24px" pr="24px" pt="16px" pb="16px">
          <Typography variant="h6" style={{ fontWeight: 700 }}>
            You've already sent your response
          </Typography>
        </Box>
        <Separator />

        <Box p="24px">
          <Typography variant="body1">
            You can no longer edit this response because you've already sent it
            to the buyer's legal representative.
            <br />
            <br /> Contact Contrax support if you need to make changes.
          </Typography>
        </Box>

        <Separator />

        <Box
          display="flex"
          justifyContent="flex-end"
          pl="24px"
          pr="16px"
          height="74px"
          alignItems="center"
        >
          <Button
            title="CLOSE"
            size={ButtonSize.medium}
            type={ButtonType.secondary}
            onClick={onClose}
            width="101px"
          />
        </Box>
      </Box>
    </Dialog>
  );
}
