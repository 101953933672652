import React from "react";
import { Box, Typography, Dialog } from "@material-ui/core";
import Button, { ButtonType, ButtonSize } from "./common/Button";
import Separator from "./common/Separator";
import { TeamMember, TeamMemberRole, Contract, Activity, ActivityAction, LegalReviewRequestStatus, LegalReviewRequestStage, EnquiryStatus, EnquiryStage } from "../api/models";
import InviteModalUserItem from "./LegalReviewViewer/InviteModalUserItem";
import { useContractStore } from "../stores/contract";
import TextField from "../components/common/TextField";
import api from "../api/instance";
import { isEmpty, isNil } from "lodash-es";
import { useAlert } from "../contexts/Alert";
import { parseApiError } from "../helpers/error";
import useSWR from "swr";
import { Document, Packer, Paragraph, TextRun } from 'docx';
import { saveAs } from 'file-saver';


export enum NoBuyerShareWithDialogType {
  team,
  otherParty, // i.e. vendor's representatives
}


interface Props {
  type?: NoBuyerShareWithDialogType;
  open: boolean;
  title: string;
  message: string;
  onClose: () => unknown;
  onNext: (membersCount: number) => unknown;
  contractId: number | null;
  recordId: number | null;
  contract?: Contract | null;
  // contractId: number | null;
}

export default function NoBuyerShareWithDialog({
  type = NoBuyerShareWithDialogType.team,
  open,
  title,
  message,
  onClose,
  onNext,
  contractId,
  recordId,
  contract,
}: // contractId,
Props) {
  const [recordTitle, setRecordTitle] = React.useState("");
  const [recordTitleError, setRecordTitleError] = React.useState<string | null>(
    null
  );

  const { setSuccessMessage, setErrorMessage } = useAlert();

  const [nextPublishing, setNextPublishing] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(false);
  // TODO: Show agents as well when API ready, all users can see all agents
  const {
    teamMembers,
    otherPartyMembers,
    agentTeamMembers,
    userTeamMemberRole,
  } = useContractStore();

  const isBuyer =
    userTeamMemberRole === TeamMemberRole.buyer ||
    userTeamMemberRole === TeamMemberRole.buyerLawyer;

    const { data: activitiesData } = useSWR(
      !isNil(contract?.id)
        ? `/contract/${contract?.id}/record/${recordId}/activities/`
        : null
    );

    const activities = activitiesData ? (activitiesData as Activity[]) : [];

    const requestsSent = !isNil(
      activities.find((activity) => activity.action === ActivityAction.Request)
    );

  function resetErrors() {
    setRecordTitleError(null);
  }

  function verifyFields() {
    resetErrors();

    let allFieldsValid = true;

    if (isEmpty(recordTitle)) {
      setRecordTitleError("Please enter a record title to continue.");
      allFieldsValid = false;
    }

    return allFieldsValid;
  }

  const onNextPublish = () => {
    if (nextPublishing) {
      return;
    }

    if (!verifyFields()) {
      setErrorMessage("Please fill in the missing fields");
      return;
    }

    setNextPublishing(true);

    api
      .post(`/contract/${contractId}/record/${recordId}/update-record/`, {
        title: recordTitle,
      })
      .then((response) => {
        setNextPublishing(false);
        onNext(teamMembers.length);
        // setSuccessMessage("Record title has been updated");
      })
      .catch((error) => {
        setNextPublishing(false);

        setErrorMessage(parseApiError(error));
      });
  };

  React.useEffect(() => {
    if (contract) {
      setRecordTitle(contract.title);
    }
  }, [contract, open]);
  const FetchReceiversList = async () => {
    try {
      const result = await api.get(
        `/contract/${contract?.id}/record/${recordId}/request-recipients/`
      );

      const propertyAddress = result.data?.property_address;
      const recipients = result.data.recipients.filter(recipient => recipient.user_type === "SOL");
      const CCrecipients = result.data.recipients.filter(recipient => recipient.user_type !== "SOL");
      const CC= CCrecipients.map((item) =>item.email)
      const emailList = recipients.map((item) => item.email);
     
      
      return { propertyAddress, emailList, CC }; // Return both propertyAddress and emailList
    } catch (error) {
      console.log(error);
      return { propertyAddress: null, emailList: [] }; // Return propertyAddress as null in case of error
    }
  };
 
  const isSeller =
    userTeamMemberRole === TeamMemberRole.seller ||
    userTeamMemberRole === TeamMemberRole.sellerLawyer;

  const FetchLegalReviews = async () => {
    try {
      const result = await api.get(
        `/contract/${contract?.id}/record/${recordId}/legal-review-requests/`
      );

      const LegalReviews = result.data
      .filter((request) => {
        // Exclude requests with status 'deleted'
        if (request.request_status === LegalReviewRequestStage.deleted) {
          return false;
        }
        // Include all requests if user is a seller, or only published requests otherwise
        return isSeller || request.status === LegalReviewRequestStatus.published;
      })
      .sort((a, b) => {
        return 0;
      });
      
      return LegalReviews
    } catch (error) {
      console.log(error);
    }
  };
 
  const FetchGeneralEnquires = async () => {
    try {
      const result = await api.get(
        `/contract/${contract?.id}/record/${recordId}/legal-review-enquiries/`
      );
      const Enquires = result.data.filter((enquiry) => {
        // Exclude enquiries with status 'deleted'
        if (enquiry.enquiry_status === EnquiryStage.Deleted) {
          return false;
        }
        // Include all enquiries if user is a seller, or only published enquiries otherwise
        return isSeller || enquiry.status === EnquiryStatus.Published;
      })
      .sort((a, b) => {
        // Apply any specific sorting logic if needed
        return 0;
      })

      return Enquires;
    } catch (error) {
      console.log(error, "error fetching the general enquiries");
    }
  };

  const openMail = async (message) => {
    try {
        // Fetch data
        const [pendingReviews = [], pendingEnquiries = []] = await Promise.all([
          FetchLegalReviews(),
          FetchGeneralEnquires()
        ]);
    
        const sellarLawyer = otherPartyMembers[0]?.first_name || 'Unknown';
        const { propertyAddress, emailList, CC } = await FetchReceiversList() || {}; // Destructure propertyAddress and emailList

        if (!emailList || emailList.length === 0) {
            console.error("No receivers found");
            return;
        }

        const emailString = emailList.join("; ");
        const ccString = CC ? CC.join("; ") : '';
        const subject = `Sale of ${propertyAddress || 'Unknown Property'} - Contract Requests & Inquiries`; // Use propertyAddress in the subject

        let index = 1; // Initialize the index

        // Format the pending reviews with bold index and title
        const pendingReviewsText = pendingReviews.map(review =>
            new Paragraph({
                children: [
                    new TextRun({
                        text: `${index++}. `,
                        bold: true, // Bold the index
                        size: 28,
                    }),
                    new TextRun({
                        text: `${review.title}: `,
                        bold: true, // Bold the title
                        size: 28,
                    }),
                    new TextRun({
                        text: review.description || '',
                        size: 28,
                    })
                ],
            })
        );

        // Format the pending enquiries with bold index and title
        const pendingEnquiriesText = pendingEnquiries.map(enquiry =>
            new Paragraph({
                children: [
                    new TextRun({
                        text: `${index++}. `,
                        bold: true, // Bold the index
                        size: 28,
                    }),
                    new TextRun({
                        text: `${enquiry.title}: `,
                        bold: true, // Bold the title
                        size: 28,
                    }),
                    new TextRun({
                        text: enquiry.enquiry || '',
                        size: 28,
                    })
                ],
            })
        );

        const customMessage = `We are acting for ${contract?.title || 'Unknown Party'} buyer, who is interested in purchasing the above property, subject to the following contract requests/inquiries:`;
        const Buyer = ()=>{
          const buyer = teamMembers.find(b => b?.role ===TeamMemberRole.buyer);
          return buyer? `${buyer.email}` : 'Unknown';
        }
       
        const doc = new Document({
          sections: [
              {
                  properties: {},
                  children: [
                      new Paragraph({
                          children: [
                              new TextRun({
                                  text: `Subject: ${subject}`,
                                  bold: true,
                                  size: 32, // 32 half-points, equivalent to 16 points
                              }),
                          ],
                      }),
                      new Paragraph({ text: '' }),
                      new Paragraph({
                          children: [
                              new TextRun({
                                  text: 'To: ',
                                  size: 28,
                                  bold: true, // Make "To:" bold
                              }),
                              new TextRun({
                                  text: `${emailString};`,
                                  size: 28, // Same size for consistency
                              }),
                          ],
                      }),
                      new Paragraph({ text: '' }),
                      new Paragraph({
                          children: [
                              new TextRun({
                                  text: 'CC: ',
                                  size: 28, // 28 half-points, equivalent to 14 points
                                  bold: true, // Make "CC:" bold
                              }),
                              new TextRun({
                                  text: `${ccString};`,
                                  size: 28, // Same size for consistency
                              }),
                          ],
                      }),
                      new Paragraph({ text: '' }),
                      new Paragraph({
                          children: [
                              new TextRun({
                                  text: 'BCC: ',
                                  size: 28, // 28 half-points
                                  bold: true, // Make "BCC:" bold
                              }),
                              new TextRun({
                                  text: Buyer(), // Call the Buyer function to get the name
                                  size: 28, // Same size for consistency
                              }),
                          ],
                      }),
                      new Paragraph({ text: '' }), 
                      new Paragraph({
                          children: [
                              new TextRun({
                                  text:` Dear ${sellarLawyer},`,
                                  size: 28, // 28 half-points, equivalent to 14 points
                              }),
                          ],
                      }),
                      new Paragraph({ text: '' }), 
                      new Paragraph({
                          children: [
                              new TextRun({
                                  text: customMessage,
                                  size: 28, // 28 half-points, equivalent to 14 points
                              }),
                          ],
                      }),
                      new Paragraph({ text: '' }),
                      // Add pending reviews and enquiries directly as paragraphs
                      ...pendingReviewsText,
                      ...pendingEnquiriesText,
                      new Paragraph({ text: '' }), 
                      new Paragraph({
                          children: [
                              new TextRun({
                                  text: `Thank you and kind regards,`,
                                  size: 28,
                              }),
                          ],
                      }),
                      new Paragraph({ text: '' }),
                      // Add pending reviews and enquiries directly as paragraphs
                      new Paragraph({ text: '' }), 
                      new Paragraph({
                          children: [
                              new TextRun({
                                  text: `${contract?.primary_buyer_lawyer?.first_name || 'Unknown'} ${contract?.primary_buyer_lawyer?.last_name || 'Unknown'}`,
                                  size: 28,
                              }),
                          ],
                      }),
                  ],
              },
          ],
      });
      
      

        // Pack the document into a .docx file
        const blob = await Packer.toBlob(doc);

        // Download the file
        saveAs(blob, `${propertyAddress || 'Unknown Property'} - Contract Requests.docx`);

    } catch (error) {
        console.error("Error opening mail:", error);
    }
};

  const markSend = async () => {
    try {
      const result =
        await api.post(`/contract/${contract?.id}/record/${recordId}/request-sent-seller-team/
    `);
      if (result?.data?.message === "Message sent") {
        setIsDisabled(true);
        onClose();
      }
      return result;
    } catch (error) {
      console.log(error);
    }
  };
  
  
  

  const UpdatedRequest = async () => {
    try {
      const result = await api.post(
        `/contract/${contract?.id}/record/${recordId}/request-updates-sent-seller-team/`
      );
      if (result?.data?.message === "Message sent") {
        onClose();
      }
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const handleButtonClick = (message) => {
    
      openMail(message);
   // Uncomment this line if you want to call onSend here
    
  };
  
  return (
    <Dialog
      open={open}
      aria-labelledby="share-with-your-team-dialog"
      aria-describedby="share-with-your-team-description"
      fullWidth
    >
      <Box maxWidth="626px" display="flex" flexDirection="column">
        <Box
          height="62px"
          width="100%"
          pl="24px"
          alignItems="center"
          display="flex"
          flexDirection="row"
          boxSizing="border-box"
        >
          <Typography variant="h5" style={{ fontWeight: 700 }}>
            {title}
          </Typography>
        </Box>
        <Separator />
        <Box p="24px">
          <Box mb={2}>
            <TextField
              placeholder="Name your request"
              title="Buyer name*"
              value={recordTitle}
              onChange={(text) => setRecordTitle(text)}
              helperText={recordTitleError}
              error={recordTitleError !== null}
              disabled
            />
            <Typography variant="caption">
              This is the title that appears on your requests.
            </Typography>
          </Box>
          <Separator />
          <Box mt={2} mb={1}>
            <Typography variant="body1" style={{ fontWeight: 700 }}>
              Recipients attached to this record
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" style={{ fontWeight: 400 }}>
              After you send, recipients below can view the requests. Only the
              seller's legal reps can respond. Review points and comments remain
              private.
            </Typography>
          </Box>
        </Box>

        <Box minHeight="80px">
          {type === NoBuyerShareWithDialogType.team &&
            teamMembers.map((member) => (
              <InviteModalUserItem member={member} />
            ))}
          {type === NoBuyerShareWithDialogType.otherParty &&
            otherPartyMembers
              .concat(isBuyer ? agentTeamMembers : [])
              .map((member) => <InviteModalUserItem member={member} />)}
        </Box>

        <Separator />
        {/* <Box
          display="flex"
          justifyContent="flex-end"
          pl="24px"
          pr="16px"
          height="74px"
          alignItems="center"
          boxSizing="border-box"
        >
          <Button
            title="CANCEL"
            size={ButtonSize.medium}
            type={ButtonType.secondary}
            onClick={onClose}
            width="114px"
          />
          <Box width="8px" />
          <Button
            title="NEXT"
            size={ButtonSize.medium}
            type={ButtonType.primary}
            onClick={onNextPublish}
            // onClick={() => onNext(teamMembers.length)}
            width="93px"
            loading={nextPublishing}
          />
        </Box> */}
        <Box
          display={
            userTeamMemberRole === TeamMemberRole.buyerLawyer && title !=="FINALISE" 
              ? "flex"
              : undefined
          }
          // width={
          //   userTeamMemberRole === TeamMemberRole.buyerLawyer&&  title !=="FINALISE" 
          //     ? "100%"
          //     : undefined
          // }
          alignItems={
            userTeamMemberRole === TeamMemberRole.buyerLawyer &&  title !=="FINALISE" 
              ? "center"
              : undefined
          }
          justifyContent={
            userTeamMemberRole === TeamMemberRole.buyerLawyer && title !=="FINALISE" 
              ? "space-between"
              : undefined
          }
          paddingLeft={
            userTeamMemberRole === TeamMemberRole.buyerLawyer && title !=="FINALISE" 
              ? "15px"
              : undefined
          }
          paddingRight={
            userTeamMemberRole === TeamMemberRole.buyerLawyer && title !=="FINALISE" 
              ? "15px"
              : undefined
          }
          
         
       
            
          
        >
          {userTeamMemberRole === TeamMemberRole.buyerLawyer &&
                    requestsSent && title !=="FINALISE" && (
              <Button
                title="MARK AS SENT"
                size={ButtonSize.medium}
                type={ButtonType.secondary}
                onClick={UpdatedRequest}
                width="150px"
               
              />
            )}
         {userTeamMemberRole === TeamMemberRole.buyerLawyer &&
                    !requestsSent && title !=="FINALISE" && (
              <Button
                disabled={isDisabled}
                title="MARK AS SENT"
                size={ButtonSize.medium}
                type={ButtonType.secondary}
                onClick={markSend}
                width="150px"
              />
            )}

          <Box
            display="flex"
            justifyContent="flex-end"
            // pl="24px"
            // pr="16px"
            height="74px"
            alignItems="center"
          >
            <Button
              title="CANCEL"
              size={ButtonSize.medium}
              type={ButtonType.secondary}
              onClick={onClose}
              width="114px"
            />
            <Box width="8px" />
            <Button
              // loading={sending}
              title="DOWNLOAD"
              size={ButtonSize.medium}
              type={ButtonType.primary}
              // onClick={() => onSend(message, pdf)}
              onClick={() => handleButtonClick(message)}
              width="112px"
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
