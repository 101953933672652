import React from "react";
import { Box, Typography, Link, CircularProgress } from "@material-ui/core";
import useSWR from "swr";
import { Property, Contract } from "../api/models";
import { useHistory, useLocation } from "react-router-dom";

export default function PaymentFailed(): JSX.Element {
  const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const contractId = query.get("contractId");

  const { data: contractData } = useSWR(`/view-contracts/${contractId}/`);
  const contract = contractData && (contractData as Contract);

  React.useEffect(() => {
    if (contract) {
      history.push(
        `/property-info?propertyId=${contract.property_id}&paymentFailed=true`
      );
    }
  }, [contract]);

  return <CircularProgress />;
}
