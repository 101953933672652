import React from "react";
import { Box, Link, Typography } from "@material-ui/core";
import DashboardHeaderDesktop from "../components/DashboardHeaderDesktop";
import { useMobile, useDesktop } from "../hooks/mobile";
import { Colors } from "../constants/colors";
import useSWR from "swr";
import { useLocation, useHistory } from "react-router-dom";
import SegmentedControl, {
  SegmentedControlOption,
} from "../components/common/SegmentedControl";
import AlertWrapper from "../components/AlertWrapper";
import DashboardHeaderMobile from "../components/DashboardHeaderMobile";
import MyPropertyRow from "../components/MyPropertyRow";
import DashboardButton from "../components/DashboardButton";
import { User, UserType, Contract, MyProperty } from "../api/models";
import { useSessionStore } from "../stores/Session";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import NoProperties from "../images/no-properties.png";
import { isEmpty } from "lodash-es";
import AuthenticatedWrapper from "../components/AuthenticatedWrapper";
import DesktopFooter from "../components/common/DesktopFooter";

export default function MyProperties(): JSX.Element {
  const isDesktop = useDesktop();
  const location = useLocation();
  const history = useHistory();

  const [selectedValue, setSelectedValue] = React.useState<null | string>(null);

  const { data: myPropertiesData } = useSWR("/my-properties/");
  const myProperties =
    myPropertiesData && (myPropertiesData as Array<MyProperty>);

  const { data: userData, refresh: refreshUserData } = useSessionStore();
  const user = userData && (userData as User);
  // console.debug("my prop data: ", myPropertiesData);
  // console.debug("myProperties: ", myProperties);

  React.useEffect(() => {
    if (user && !selectedValue) {
      setSelectedValue(tabOptions()[0].value);
    }
  }, [user]);

  function filteredProperties() {
    if (myProperties) {
      switch (selectedValue) {
        case "all":
          return myProperties.filter(
            (property) => property.status.toLowerCase() !== "delete"
          );
        case "ready":
        case "available":
          return myProperties.filter(
            (property) => property.status.toLowerCase() === "published"
          );
        case "pending":
          return myProperties.filter(
            (property) => property.status.toLowerCase() === "pending"
          );
        case "sold":
          return myProperties.filter(
            (property) => property.status.toLowerCase() === "sold"
          );
        case "invited":
          return myProperties.filter(
            (property) =>
              property.buyer_status &&
              property.buyer_status.toLowerCase() !== "purchased"
          );
        case "purchased":
          return myProperties.filter(
            (property) =>
              property.buyer_status &&
              property.buyer_status.toLowerCase() === "purchased"
          );
      }
    }

    return undefined;
  }

  function tabOptions() {
    if (user && user.user_type === UserType.Agent) {
      return [
        {
          value: "all",
          title: "All",
        },
        {
          value: "ready", // published
          title: "Ready",
        },
        {
          value: "pending",
          title: "Pending",
        },
        {
          value: "sold",
          title: "Sold",
        },
      ] as Array<SegmentedControlOption>;
    }

    if (
      user &&
      (user.user_type === UserType.Buyer ||
        user.user_type === UserType.Solicitor)
    ) {
      return [
        {
          value: "all",
          title: "All",
        },
        {
          value: "invited",
          title: "Invited",
        },
        {
          value: "purchased",
          title: "Purchased",
        },
      ] as Array<SegmentedControlOption>;
    }

    return [
      {
        value: "all",
        title: "All",
      },
    ];
  }

  function MySegmentedControl() {
    return (
      <SegmentedControl
        options={tabOptions()}
        selectedValue={selectedValue}
        onSelectedValue={(value) => {
          setSelectedValue(value);
        }}
      />
    );
  }

  function EmptyPlaceholder() {
    return (
      <Box textAlign="center" mt={14}>
        <img src={NoProperties} height="167px" />
        <Typography variant="body1" style={{ marginTop: "24px" }}>
          {user && user.user_type === UserType.Agent
            ? "You haven't uploaded any legal reviews"
            : "You haven't purchased any legal reviews"}
        </Typography>
      </Box>
    );
  }

  const filtered = filteredProperties();

  return (
    <AuthenticatedWrapper>
      <AlertWrapper>
        <Box display="flex" flexDirection="column" minHeight="100vh">
          <Box display="flex" flexDirection="column" flex={1}>
            {isDesktop && (
              <React.Fragment>
                <DashboardHeaderDesktop />

                <Box bgcolor={Colors.BrandBackground2} pt={8} pl={21}>
                  <Typography variant="h4">My properties</Typography>
                </Box>
                <Box bgcolor={Colors.BrandBackground2} pt={7.5} pl={21}>
                  <Box width="800px">
                    <MySegmentedControl />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  pl={21}
                  pr={21}
                  pt={1.5}
                  alignItems="flex-start"
                >
                  <Box display="flex" flexDirection="column" flex={1} mr={3}>
                    {filtered && isEmpty(filtered) && <EmptyPlaceholder />}
                    {filtered &&
                      filtered.map((property) => (
                        <MyPropertyRow
                          property={property}
                          status={user && user.user_type}
                          onClick={() => {
                            if (user && user.user_type === UserType.Agent) {
                              history.push(
                                `/my-property?propertyId=${property.property_id}`
                              );
                            } else if (
                              property &&
                              property.buyer_status &&
                              property.buyer_status.includes("Purchased")
                            ) {
                              history.push(
                                `/legal-review-purchased?contractId=${property.contract_id}&myProperty=true`
                              );
                            } else {
                              history.push(
                                `/property-info?propertyId=${property.property_id}`
                              );
                            }
                          }}
                        />
                      ))}
                  </Box>
                  {user && user.user_type === UserType.Agent && (
                    <DashboardButton
                      isMobile={false}
                      title="Upload a contract"
                      subtitle="Get started with the upload process to help get to exchange faster"
                      onClick={() => history.push("/agent/add-property")}
                    />
                  )}
                  {user && user.user_type === UserType.Buyer && (
                    <DashboardButton
                      isMobile={false}
                      title="My invoices"
                      subtitle="From here you can view all of your purchase invoices and choose to download or print"
                      onClick={() => history.push("/my-invoices")}
                    />
                  )}
                </Box>
              </React.Fragment>
            )}
            {!isDesktop && (
              <React.Fragment>
                <DashboardHeaderMobile />
                <Box bgcolor={Colors.BrandBackground2} pt={8} pl={3}>
                  <Typography variant="h4">My properties</Typography>
                </Box>
                <Box bgcolor={Colors.BrandBackground2} pt={7.5}>
                  <MySegmentedControl />
                </Box>
                {filtered && isEmpty(filtered) && <EmptyPlaceholder />}
                {filtered &&
                  filtered.map((property) => (
                    <MyPropertyRow
                      property={property}
                      onClick={() => {
                        if (user && user.user_type === UserType.Agent) {
                          console.log(user.user_type);
                          history.push(
                            `/my-property?propertyId=${property.property_id}`
                          );
                        } else if (
                          property &&
                          property.buyer_status &&
                          property.buyer_status.includes("Invite")
                        ) {
                          history.push(
                            `/property-info?propertyId=${property.property_id}`
                          );
                        } else {
                          history.push(
                            `/legal-review-purchased?contractId=${property.contract_id}&myProperty=true`
                          );
                        }
                      }}
                    />
                  ))}
                {user && user.user_type === UserType.Agent && (
                  <MobileStickyFooter
                    buttonsLayout={FooterButtonsLayout.centered}
                    buttonTitle="UPLOAD A CONTRACT"
                    buttonOnClick={() => history.push("/agent/add-property")}
                  />
                )}
                {user && user.user_type === UserType.Buyer && (
                  <MobileStickyFooter
                    buttonsLayout={FooterButtonsLayout.centered}
                    buttonTitle="MY INVOICES"
                    buttonOnClick={() => history.push("/my-invoices")}
                  />
                )}
              </React.Fragment>
            )}
          </Box>

          <Box mt={5} />
          <DesktopFooter />
        </Box>
      </AlertWrapper>
    </AuthenticatedWrapper>
  );
}
