import React from "react";
import { Box, Typography, Link, Grid } from "@material-ui/core";
import HeroLayout from "../components/HeroLayout";
import { useLocation, useHistory } from "react-router-dom";
import { useMobile } from "../hooks/mobile";
import TextField from "../components/common/TextField";
import Button, { ButtonType, ButtonSize } from "../components/common/Button";
import { useAlert } from "../contexts/Alert";
import { Colors } from "../constants/colors";
import {
  User,
  UserType,
  Property,
  Contract,
  ContractWorkflowStatus,
} from "../api/models";
import { isEmpty } from "lodash-es";
import api from "../api/instance";
import axios, { AxiosError } from "axios";
import useSWR from "swr";
import TermsLink from "../components/TermsLink";
import PrivacyLink from "../components/PrivacyLink";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import { usePurchaseStore } from "../stores/purchase";

const noAuthApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export default function SignUp(): JSX.Element {
  const isMobile = useMobile();
  const location = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(location.search);
  const propertyId = query.get("propertyId");
  const contractId = query.get("contractId");
  const viewId = query.get("viewId");
  const role = query.get("role");

  const { data: propertyData } = useSWR(`/view-properties/${propertyId}/`);
  const property = propertyData && (propertyData as Property);

  const { data: contractData } = useSWR(
    property && `/view-contracts/${property.contract_id}/`
  );
  const contract = contractData && (contractData as Contract);

  // console.debug("contract: ", contract);

  const [firmName, setFirmName] = React.useState("");
  const [firmNameError, setFirmNameError] = React.useState<string | null>(null);

  const [firstName, setFirstName] = React.useState("");
  const [firstNameError, setFirstNameError] = React.useState<string | null>(
    null
  );
  const [lastName, setLastName] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState<string | null>(null);

  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState<string | null>(null);

  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [phoneNumberError, setPhoneNumberError] = React.useState<string | null>(
    null
  );

  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState<string | null>(null);

  const [loading, setLoading] = React.useState(false);

  const [selectedUserType, setSelectedUserType] = React.useState<UserType>(
    UserType.Buyer
  );

  const [consent, setConsent] = React.useState<boolean>(false);
  const [consentError, setConsentError] = React.useState<string | null>(null);

  const { setErrorMessage } = useAlert();

  const { setPurchaseJourneyStartPath, setPurchaseJourneySuccessPath } =
    usePurchaseStore();

  React.useEffect(() => {
    if (role === "buyer") {
      setSelectedUserType(UserType.Buyer);
    } else if (role === "lawyer") {
      setSelectedUserType(UserType.Solicitor);
    }
  }, [role]);

  function Title() {
    const message =
      contract && contract.workflow_status === ContractWorkflowStatus.Reviewed
        ? "Enter your details to create a Contrax account where you can view and download the contract of sale, as well as purchase the legal review for this property"
        : "Enter your details to create a Contrax account so we can notify you when the legal review for this property is ready";

    return (
      <Box maxWidth={isMobile ? "353px" : "700px"} mt={isMobile ? 3 : 22}>
        <Typography variant={isMobile ? "h5" : "h4"}>
          Create an account
        </Typography>
        <Typography
          variant={isMobile ? "body2" : "body1"}
          style={{ marginTop: "16px" }}
        >
          {message}
        </Typography>
      </Box>
    );
  }

  function FooterText() {
    return (
      <Box>
        <Grid container spacing={2} style={{ marginTop: "10px" }}>
          <Grid item xs={1}>
            <input
              type="checkbox"
              style={{ outline: consentError ? "2px solid red" : "" }}
              onChange={(event) => {
                if (event.target.checked) {
                  setConsent(true);
                } else {
                  setConsent(false);
                }
              }}
              checked={consent}
            />
          </Grid>
          <Grid item xs={10}>
            <Typography variant="body2">
              I agree to the <TermsLink /> (including those relating to privacy
              and communication) and <PrivacyLink />
            </Typography>
            <Typography variant="caption" color="secondary">
              {consentError}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="body2" style={{ marginTop: "20px" }}>
          Already have an account?{" "}
          <Link
            style={{ fontWeight: 700, color: Colors.Link, cursor: "pointer" }}
            onClick={() => {
              if (propertyId) {
                if (
                  contract &&
                  contract.workflow_status === ContractWorkflowStatus.Reviewed
                ) {
                  history.push("/login", {
                    next: `/payment-preview?propertyId=${propertyId}`,
                  });
                } else {
                  history.push("/login", {
                    next: `/payment-preview?propertyId=${propertyId}`,
                  });
                }
              } else if (contractId) {
                history.push("/login", {
                  next: `/sale-contract?contractId=${contractId}&forPurchase=true`,
                });
              } else {
                history.push("/login");
              }
            }}
          >
            Log in
          </Link>
        </Typography>
      </Box>
    );
  }

  function resetErrors() {
    setFirmNameError(null);
    setFirstNameError(null);
    setLastNameError(null);
    setEmailError(null);
    setPhoneNumberError(null);
    setPasswordError(null);
    setErrorMessage(null);
    setConsentError(null);
  }

  function verifyFields() {
    resetErrors();

    let allFieldsValid = true;

    if (isEmpty(firmName) && selectedUserType === UserType.Solicitor) {
      setFirmNameError("Please enter the firm name");
      allFieldsValid = false;
    }

    if (isEmpty(firstName)) {
      setFirstNameError("Please enter your first name");
      allFieldsValid = false;
    }

    if (isEmpty(lastName)) {
      setLastNameError("Please enter your last name");
      allFieldsValid = false;
    }

    if (isEmpty(email)) {
      setEmailError("Please enter your email");
      allFieldsValid = false;
    }

    if (isEmpty(password)) {
      setPasswordError("Please enter a password");
      allFieldsValid = false;
    }

    if (!consent) {
      setConsentError("This box must be checked to create an account");
      allFieldsValid = false;
    }

    return allFieldsValid;
  }

  function signUpTapped() {
    if (loading) {
      return;
    }

    if (!verifyFields()) {
      setErrorMessage("Please fill in the missing fields");
      return;
    }

    const commonData = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone: phoneNumber,
      password,
    };

    const data =
      selectedUserType === UserType.Solicitor
        ? {
            firm_name: firmName,
            ...commonData,
          }
        : commonData;

    setLoading(true);

    noAuthApi
      .post(
        selectedUserType === UserType.Solicitor
          ? "/solicitor/?version=v2"
          : "/buyer/",
        data
      )
      .then((response) => {
        setLoading(false);

        console.debug("response: ", response);

        const user = response.data as User;
        if (user.validation_token) {
          localStorage.setItem("token", user.validation_token);

          api.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${user.validation_token}`;

          // Check if legal review is ready
          // Redirect to purchase screen if ready
          // Redirect to thank you $userName screen is not ready

          if (
            contract &&
            contract.workflow_status === ContractWorkflowStatus.Reviewed
          ) {
            history.push(`/payment-preview?propertyId=${propertyId}`);
          } else if (contractId) {
            const gotoURL = `/property-info?propertyId=${viewId}`;
            history.push(
              `/sale-contract?contractId=${contractId}&forPurchase=true`,
              {
                gotoURL,
                user,
              }
            );
          } else if (propertyId === null) {
            history.push(`/`);
          } else {
            setPurchaseJourneyStartPath(
              `${location.pathname}${location.search}`
            );
            setPurchaseJourneySuccessPath(
              `/payment-preview?propertyId=${propertyId}`
            );

            history.push("/plans");

            // history.push(`/payment-preview?propertyId=${propertyId}`);
          }
        }
      })
      .catch((error: AxiosError) => {
        console.debug("error: ", error);

        setLoading(false);
        setErrorMessage("Please check errors on this page");

        const errors = error && error.response && error.response.data;

        const { firm_name, first_name, last_name, email, phone, password } =
          errors;

        firm_name && setFirmNameError(firm_name[0]);
        first_name && setFirstNameError(first_name[0]);
        last_name && setLastNameError(last_name[0]);
        email && setEmailError(email[0]);
        phone && setPhoneNumberError(phone[0]);
        password && setPasswordError(password[0]);
      });
  }

  return (
    <HeroLayout
      onCloseClicked={
        propertyId
          ? () => history.push(`/property-info?propertyId=${propertyId}&role=buyer`)
          : undefined
      }
    >
      <Box
        display="flex"
        flexDirection="column"
        marginLeft="auto"
        marginRight="auto"
        alignItems={isMobile ? "center" : undefined}
        pl={isMobile ? 3 : 0}
        pr={isMobile ? 3 : 0}
      >
        <Title />
        <Box
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          mt={3}
          maxWidth="768px"
        >
          <Box width={isMobile ? undefined : "353px"}>
            <TextField
              title="First name"
              value={firstName}
              error={firstNameError !== null}
              helperText={firstNameError}
              onChange={(value) => setFirstName(value)}
            />
            <Box mt={1} />
            <TextField
              title="Last name"
              value={lastName}
              error={lastNameError !== null}
              helperText={lastNameError}
              onChange={(value) => setLastName(value)}
            />
            <Box mt={1} />
            <TextField
              type="email"
              title="Email"
              error={emailError !== null}
              helperText={emailError}
              value={email}
              onChange={(value) => setEmail(value)}
            />
            <Box mt={1} />
          </Box>
          <Box width={isMobile ? undefined : "353px"} ml={isMobile ? 0 : 2}>
            <TextField
              type="phone"
              title="Phone number"
              error={phoneNumberError !== null}
              helperText={phoneNumberError}
              value={phoneNumber}
              onChange={(value) => setPhoneNumber(value)}
            />
            <Box mt={1} />
            <TextField
              type="password"
              title="Password"
              error={passwordError !== null}
              helperText={passwordError}
              value={password}
              onChange={(value) => setPassword(value)}
            />
            <Box mt={1} />
            {isMobile && (
              <Box mb={8}>
                <FooterText />
              </Box>
            )}
          </Box>
        </Box>
        {!isMobile && (
          <React.Fragment>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              maxWidth="353px"
            >
              <FooterText />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              mt={5}
              mb={10}
            >
              <Button
                type={ButtonType.primary}
                size={ButtonSize.large}
                title="SIGN UP"
                width="137px"
                loading={loading}
                onClick={() => signUpTapped()}
              />
            </Box>
          </React.Fragment>
        )}
      </Box>
      {isMobile && (
        <MobileStickyFooter
          buttonsLayout={FooterButtonsLayout.sideBySide}
          leftButtonTitle="CANCEL"
          leftButtonOnClick={() => history.goBack()}
          rightButtonTitle="NEXT"
          rightButtonLoading={loading}
          rightButtonOnClick={() => signUpTapped()}
        />
      )}
    </HeroLayout>
  );
}
