import React from "react";
import { Box } from "@material-ui/core";
import ActivityLogItem from "./ActivityLogItem";
import { Colors } from "../../constants/colors";
import { Activity } from "../../api/models";
import moment from "moment";
import "../../styles/scrollbar-hidden.css";

interface Props {
  activities: Activity[];
  maxHeight?: string;
}

export default function ({ activities, maxHeight }: Props) {
  const sorter = (first: Activity, second: Activity) => {
    return moment(second.created).isAfter(moment(first.created)) ? 1 : -1;
  };

  const activitiesSorted = activities.sort(sorter);

  return (
    <Box
      display="flex"
      flexDirection="row"
      mt="24px"
      minWidth="250px"
      pl="8px"
    >
      <Box width="3px" bgcolor={Colors.Grey4} />
      <Box display="flex" flexDirection="column">
        {activitiesSorted.map((activity) => (
          <ActivityLogItem
            key={`activity-${activity.pk}`}
            activity={activity}
          />
        ))}
      </Box>
    </Box>
  );
}
