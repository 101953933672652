import React from "react";
import { useSessionStore } from "../stores/Session";
import { User } from "../api/models";

interface Props extends React.PropsWithChildren<{}> {}

export default function AuthenticatedWrapper({ children }: Props): JSX.Element {
  const { refresh: refreshUserData, data: userData } = useSessionStore();
  const user = userData && (userData as User);

  // refreshUserData() will redirect to login page if session token has expired
  React.useEffect(() => {
    refreshUserData();
  }, []);

  return <React.Fragment>{children}</React.Fragment>;
}
