import { Box, CircularProgress, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { User, UserType } from "../api/models";
import AuthenticatedWrapper from "../components/AuthenticatedWrapper";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";
import DesktopFooter from "../components/common/DesktopFooter";
import DashboardHeader from "../components/Dashboard/Header";
import { Colors } from "../constants/colors";
import { useMobile } from "../hooks/mobile";
import { useSessionStore } from "../stores/Session";
interface DetailsRowProps {
  title: string;
  value?: string;
}

function DetailsRow({ title, value }: DetailsRowProps) {
  return (
    <Box mt={4}>
      <Typography variant="body1" style={{ fontWeight: 700 }}>
        {title}
      </Typography>
      <Typography
        variant="body1"
        style={{
          overflowWrap: "break-word",
        }}
      >
        {value}
      </Typography>
    </Box>
  );
}

export function AccountDetailsContent() {
  const history = useHistory();

  const { data: userData } = useSessionStore();
  const user = userData && (userData as User);

  return user ? (
    <Box>
      {user.user_type === UserType.Agent && (
        <DetailsRow title="Agency Name" value={user.agency_name} />
      )}
      {user.user_type === UserType.Solicitor && (
        <DetailsRow title="Organisation Name" value={user.firm_name} />
      )}
      <DetailsRow title="First Name" value={user.first_name} />
      <DetailsRow title="Last Name" value={user.last_name} />
      <DetailsRow title="Email" value={user.email} />
      <DetailsRow
        title="Phone Number"
        value={
          user.phone === null || user.phone === ""
            ? "Not Available"
            : user.phone
        }
      />
      <Box display="flex" flexDirection="row" mt={4}>
        <Button
          type={ButtonType.primary}
          size={ButtonSize.medium}
          title="EDIT"
          width="76px"
          onClick={() => history.push("/user/account/edit")}
        />
        <Box ml={2} />
        <Button
          type={ButtonType.secondary}
          size={ButtonSize.medium}
          title="CHANGE PASSWORD"
          width="217px"
          onClick={() => history.push("/user/account/password")}
        />
      </Box>
    </Box>
  ) : (
    <></>
  );
}

export default function AccountDetails(): JSX.Element {
  const isMobile = useMobile();
  const history = useHistory();

  const { data: userData } = useSessionStore();
  const user = userData && (userData as User);

  if (!user) {
    return <CircularProgress />;
  }

  const sideMargin = isMobile ? 3.25 : 21.5;

  return (
    <AuthenticatedWrapper>
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <Box display="flex" flexDirection="column" flex={1}>
          {isMobile && <DashboardHeader />}
          {!isMobile && (
            <React.Fragment>
              <DashboardHeader />

              <Box
                bgcolor={Colors.BrandBackground2}
                height="192px"
                display="flex"
                alignItems="center"
              >
                <Box ml={sideMargin} mr={sideMargin}>
                  <Typography variant="h4">Account details</Typography>
                </Box>
              </Box>
            </React.Fragment>
          )}
          <Box mt={4} mb={5} ml={sideMargin} mr={sideMargin}>
            {isMobile && (
              <React.Fragment>
                <Typography variant="h5">Account details</Typography>
                <Box mt={4} />
              </React.Fragment>
            )}

            <AccountDetailsContent />
          </Box>
        </Box>

        <DesktopFooter />
      </Box>
    </AuthenticatedWrapper>
  );
}
