import { Box, CircularProgress } from "@material-ui/core";
import { AxiosError } from "axios";
import { isEmpty } from "lodash-es";
import React from "react";
import { useHistory } from "react-router-dom";
import api from "../api/instance";
import { User } from "../api/models";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";
import DesktopFooter from "../components/common/DesktopFooter";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import TextField from "../components/common/TextField";
import Wrapper from "../components/UserAccount/Wrapper";
import { useAlert } from "../contexts/Alert";
import { useMobile } from "../hooks/mobile";
import { useSessionStore } from "../stores/Session";

export default function EditAccountDetails(): JSX.Element {
  const isMobile = useMobile();
  const history = useHistory();

  const { data: userData, refresh: refreshUserData } = useSessionStore();
  const user = userData && (userData as User);

  const [firstName, setFirstName] = React.useState("");
  const [firstNameError, setFirstNameError] = React.useState<null | string>(
    null
  );

  const [lastName, setLastName] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState<null | string>(null);

  const [phone, setPhone] = React.useState("");
  const [phoneError, setPhoneError] = React.useState<null | string>(null);

  const [loading, setLoading] = React.useState(false);

  const { setSuccessMessage, setErrorMessage } = useAlert();

  React.useEffect(() => {
    if (user) {
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setPhone(user.phone);
    }
  }, [user]);

  if (!user) {
    return <CircularProgress />;
  }

  // const sideMargin = isMobile ? 3.25 : 21.5;

  function resetErrors() {
    setFirstNameError(null);
    setLastNameError(null);
    setPhoneError(null);
  }

  function verifyFields() {
    let allFieldsValid = true;

    if (isEmpty(firstName)) {
      setFirstNameError("Please enter your first name");
      allFieldsValid = false;
    }

    if (isEmpty(lastName)) {
      setLastNameError("Please enter your last name");
      allFieldsValid = false;
    }

    if (isEmpty(phone)) {
      setPhoneError("Please enter your phone number");
      allFieldsValid = false;
    }

    return allFieldsValid;
  }

  function saveClicked() {
    resetErrors();

    if (!verifyFields()) {
      return;
    }

    const data = {
      first_name: firstName,
      last_name: lastName,
      phone,
    };

    console.debug("data: ", data);

    setLoading(true);

    api
      .put("/me/", data)
      .then((response) => {
        setLoading(false);

        refreshUserData();

        setSuccessMessage("Your account details have been updated");

        history.push("/user/account");
      })
      .catch((error: AxiosError) => {
        setLoading(false);

        setErrorMessage("Please check errors on this page");

        const errors = error && error.response && error.response.data;

        const { first_name, last_name, phone } = errors;

        first_name && setFirstNameError(first_name[0]);
        last_name && setLastNameError(last_name[0]);
        phone && setPhoneError(phone[0]);
      });
  }

  return (
    <Wrapper title="Edit account details">
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <Box display="flex" flexDirection="column" flex={1}>
          <Box
            mt={8}
            mb={5}
            maxWidth={isMobile ? `calc(100vw - 64px)` : "500px"}
          >
            <TextField
              title="First name"
              value={firstName}
              error={firstNameError !== null}
              helperText={firstNameError}
              onChange={(value) => setFirstName(value)}
            />
            <Box mt={2} />
            <TextField
              title="Last name"
              value={lastName}
              error={lastNameError !== null}
              helperText={lastNameError}
              onChange={(value) => setLastName(value)}
            />
            <Box mt={2} />
            <TextField
              title="Phone number"
              type="phone"
              value={phone}
              error={phoneError !== null}
              helperText={phoneError}
              onChange={(value) => setPhone(value)}
            />
            <Box mt={2} />

            {!isMobile && (
              <Box
                display="flex"
                flexDirection="row"
                mt={4}
                justifyContent="flex-end"
              >
                <Button
                  type={ButtonType.secondary}
                  size={ButtonSize.medium}
                  title="CANCEL"
                  width="137px"
                  onClick={() => history.goBack()}
                />
                <Box ml={2} />
                <Button
                  type={ButtonType.primary}
                  size={ButtonSize.medium}
                  title="SAVE"
                  width="100px"
                  loading={loading}
                  onClick={() => saveClicked()}
                />
              </Box>
            )}
          </Box>
        </Box>

        {!isMobile && <DesktopFooter />}
        {isMobile && (
          <MobileStickyFooter
            buttonsLayout={FooterButtonsLayout.sideBySide}
            leftButtonTitle="CANCEL"
            leftButtonOnClick={() => history.goBack()}
            rightButtonTitle="SAVE"
            rightButtonLoading={loading}
            rightButtonOnClick={() => saveClicked()}
          />
        )}
      </Box>
    </Wrapper>
  );
}
