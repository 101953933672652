import { Box, Typography } from "@material-ui/core";
import { isEmpty, isNil } from "lodash-es";
import moment from "moment";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import {
  addressForProperty,
  ClaimRecordResponse,
  Contract,
  ContractRecord,
  isRecordPaid,
  Property,
  RecordStatus,
  User,
  UserType,
  WorkspaceProgress,
  workspaceProgressForRecord,
} from "../../api/models";
import AlertWrapper from "../../components/AlertWrapper";
import Button, { ButtonSize, ButtonType } from "../../components/common/Button";
import DesktopFooter from "../../components/common/DesktopFooter";
import Dropdown, {
  DropdownOption,
  DropdownViewType,
} from "../../components/common/Dropdown";
import AddBuyerDialog from "../../components/Dashboard/AddBuyerDialog";
import AccountDetailsHeader from "../../components/Dashboard/AccountDetailsHeader";
import ShareLinkDialog from "../../components/Dashboard/ShareLinkDialog";
import WorkspaceItem from "../../components/Dashboard/WorkspaceItem";
import InviteModal, {
  InviteModalType,
} from "../../components/LegalReviewViewer/InviteModal";
import InviteModalNotInvited, {
  InviteModalTypeNotInvited,
} from "../../components/LegalReviewViewer/InviteModalNotInvited";
import PropertyDetailsInviteYourTeamMembersModal, {
  PropertyDeatilsInviteYourTeamMembersModalType,
} from "../../components/LegalReviewViewer/PropertyDetailsInviteYourTeamMembersModal";
import ViewContractDrawer from "../../components/ViewContractDrawer";
import { Colors } from "../../constants/colors";
import { useAlert } from "../../contexts/Alert";
import { useMobile } from "../../hooks/mobile";
import AlarmClockIcon from "../../images/alarm-clock.svg";
import ChevronLeftIcon from "../../images/chevron-left-blue.png";
import PropertyPlaceholder from "../../images/dashboard-property-placeholder.svg";
import { isLoggedIn, useSessionStore } from "../../stores/Session";
import { refreshContractTeamsAndRole } from "../../stores/contract";
import LoginModal from "../../components/Invited/LoginModal";
import SignUpModal from "../../components/Invited/SignUpModal";
import { useCreditStore } from "../../stores/credit";
import { usePurchaseStore } from "../../stores/purchase";
import UseCreditsDialog from "../../components/PurchaseCredits/UseCreditsDialog";
import { last, sortBy } from "lodash-es";
import api from "../../api/instance";
import { parseApiError } from "../../helpers/error";
import { AxiosError } from "axios";

enum Sort {
  Progress,
  DateAdded,
}

const sortByOptions: DropdownOption[] = [
  {
    title: "Progress",
    value: Sort.Progress,
  },
  {
    title: "Date Added",
    value: Sort.DateAdded,
  },
];

const progresses = [
  WorkspaceProgress.Contract,
  WorkspaceProgress.LegalReview,
  WorkspaceProgress.Requests,
  WorkspaceProgress.Exchange,
];

interface NoWorkspacesPlaceholderProps {
  onAddBuyer: () => unknown;
}

function NoWorkspacesPlaceholder({ onAddBuyer }: NoWorkspacesPlaceholderProps) {
  const isMobile = useMobile();

  return (
    <Box mt="40px" mb="40px" width={isMobile ? "90vw" : "1095px"}>
      <Typography variant="h6" style={{ fontWeight: 700 }}>
        No buyers have been added
      </Typography>
      <Typography
        variant="body2"
        style={{ marginTop: "16px", marginBottom: "16px" }}
      >
        Add buyers by inviting one person from each buying party.
      </Typography>
      <Button
        title="ADD BUYER"
        type={ButtonType.primary}
        size={ButtonSize.medium}
        onClick={onAddBuyer}
      />
    </Box>
  );
}

export default function RecordDetails() {
  const isMobile = useMobile();
  const location = useLocation();
  const history = useHistory();

  const { availableCredits, refreshCredits: refreshCredits } = useCreditStore();
  const { setPurchaseJourneyStartPath, setPurchaseJourneySuccessPath } =
    usePurchaseStore();

  const query = new URLSearchParams(location.search);
  const sidePadding = isMobile ? "25px" : "172px";

  const { data: userData, refresh: refreshUserData } = useSessionStore();
  const user = userData && (userData as User);
  const contractId = query.get("contractId");
  const contract_Id = !isNil(contractId) ? parseInt(contractId) : null;
  const type = query.get("type");

  const orgName = query.get("organization_name");
  const invitedVerificationToken = query.get("token");
  const inviterFirstName = query.get("first_name") || "";
  const inviterLastName = query.get("last_name") || "";
  const paymentSuccess = query.get("paymentSuccess") === "true";

  const [loginModalOpen, setLoginModalOpen] = React.useState(false);
  const [signUpModalOpen, setSignUpModalOpen] = React.useState(false);

  React.useEffect(() => {
    if (!isLoggedIn() && !isEmpty(invitedVerificationToken)) {
      setSignUpModalOpen(true);
    } else {
      if (isLoggedIn()) {
        recordsMutate();
      } else {
        setLoginModalOpen(true);
      }
    }

    if (paymentSuccess) {
      setUseCreditsOpen(true);
    }
  }, []);

  React.useEffect(() => {
    refreshCredits();
  }, [user]);

  const isBuying = type === "buying";
  const isSelling = type === "selling";

  const { data: contractData, error: contractError } = useSWR(
    `/view-contracts/${contractId}/`
  );

  const contract = React.useMemo(() => {
    if (contractData) {
      return contractData as Contract;
    }

    return null;
  }, [contractData]);

  React.useEffect(() => {
    if (
      contractError &&
      (contractError as AxiosError).response?.status === 403
    ) {
      history.push("/dashboard");
    }
  }, [contractError]);

  const contractFile = contract ? contract.file : null;

  const { data: recordsData, mutate: recordsMutate } = useSWR(
    `/contract/${contractId}/records/?tab=${isSelling ? "seller" : "buyer"}`
  );
  const records = recordsData ? (recordsData as ContractRecord[]) : [];

  const [sort, setSort] = React.useState<Sort>(Sort.Progress);

  const sorter = (first: ContractRecord, second: ContractRecord) => {
    if (sort === Sort.Progress) {
      const firstProgress = workspaceProgressForRecord(first);
      const secondProgress = workspaceProgressForRecord(second);

      if (firstProgress === secondProgress) {
        // If same then sort by date created
        return moment(second.created).isAfter(moment(first.created)) ? 1 : -1;
      }

      return progresses.indexOf(firstProgress) <
        progresses.indexOf(secondProgress)
        ? 1
        : -1;
    }

    if (sort === Sort.DateAdded) {
      return moment(second.created).isAfter(moment(first.created)) ? 1 : -1;
    }

    return 0;
  };

  const recordsSorted = React.useMemo(() => {
    return records.sort(sorter);
  }, [records, sort]);

  const propertyId = contract && contract.property_id;

  const { data: propertyData } = useSWR(
    propertyId ? `/view-properties/${propertyId}/` : null
  );

  const property = React.useMemo(() => {
    if (propertyData) {
      return propertyData as Property;
    }

    return null;
  }, [propertyData]);

  const contractVersionId = React.useMemo(() => {
    if (property) {
      const { contract_versions } = property;

      return last(
        sortBy(contract_versions, (version) => {
          return version.number;
        })
      )?.id;
    }

    return null;
  }, [property]);

  const [shareLinkDialogOpen, setShareLinkDialogOpen] = React.useState(false);

  const [inviteToSellerTeamOpen, setInviteToSellerTeamOpen] =
    React.useState(false);

  const [addBuyerDialogOpen, setAddBuyerDialogOpen] = React.useState(false);

  const isLawyer = user?.user_type === UserType.Solicitor;
  const isAgent = user?.user_type === UserType.Agent;
  const isPartner = user?.user_type === UserType.Partner;
  const isBuySeller = user?.user_type === UserType.Buyer;
  const isInternalUser = user?.is_internal_user;

  const isAgentForContract = contract?.agent?.email === user?.email;

  const { setSuccessMessage, setErrorMessage } = useAlert();

  const [contractDrawerOpen, setContractDrawerOpen] = React.useState(false);

  const [inviteToAgentTeamOpen, setInviteToAgentTeamOpen] =
    React.useState(false);
  const [inviteModalOpen, setInviteModalOpen] = React.useState(false);

  const [useCreditsOpen, setUseCreditsOpen] = React.useState(false);
  const [usingCredit, setUsingCredit] = React.useState(false);

  const onViewContract = () => setContractDrawerOpen(true);

  // Buyer lawyer only
  const onAddBuyerWorkspace = () => {
    if (availableCredits === 0) {
      setPurchaseJourneyStartPath(`${location.pathname}${location.search}`);
      setPurchaseJourneySuccessPath(
        `${location.pathname}${location.search}&paymentSuccess=true`
      );

      history.push("/plans");
      return;
    }

    setUseCreditsOpen(true);

    // history.push(
    //   `/payment-preview?propertyId=${propertyId}&contractId=${contractId}&cross=false`
    // );
  };

  const onUseCredit = () => {
    setUsingCredit(true);

    api
      .post("/credits/claim_record/", {
        contract_version_id: contractVersionId,
      })
      .then((response) => {
        const data = response.data as ClaimRecordResponse;
        const { record_id, contract_id } = data;

        setUsingCredit(false);
        setUseCreditsOpen(false);

        setSuccessMessage("Buyer workspace added");

        refreshCredits();

        history.push(
          `/legal-review-viewer?contractId=${contract_id}&recordId=${record_id}`
        );

        // recordsMutate();
        // refreshCredits();

        // const queryParams = new URLSearchParams(location.search);

        // // Remove paymentSuccess from params
        // if (queryParams.has("paymentSuccess")) {
        //   queryParams.delete("paymentSuccess");
        //   history.replace({
        //     search: queryParams.toString(),
        //   });
        // }
      })
      .catch((error) => {
        setUsingCredit(false);

        setErrorMessage(parseApiError(error));
      });
  };

  function TitleBar() {
    const propertyImage = property?.image
      ? property.image
      : PropertyPlaceholder;

    if (isMobile) {
      return (
        <Box
          display="flex"
          flexDirection="row"
          bgcolor={Colors.BrandBackground2}
          pl={sidePadding}
          pr={sidePadding}
          justifyContent="center"
          alignItems="center"
          height="105px"
        >
          <Box>
            <img
              src={propertyImage}
              width="99px"
              height="64px"
              style={{
                objectFit: "cover",
                borderRadius: "4px",
                marginRight: "12px",
              }}
              alt="propImage"
            />
          </Box>

          <Typography variant="body2" style={{ fontWeight: 700, flex: 1 }}>
            {property && addressForProperty(property)}
          </Typography>
        </Box>
      );
    }

    return (
      <Box
        display="flex"
        flexDirection="row"
        bgcolor={Colors.BrandBackground2}
        pl={sidePadding}
        pr={sidePadding}
        justifyContent="center"
        alignItems="center"
        height="134px"
      >
        <Box>
          <img
            src={propertyImage}
            width="117px"
            height="88px"
            style={{
              objectFit: "cover",
              borderRadius: "4px",
              marginRight: "12px",
            }}
            alt="propImage"
          />
        </Box>
        <Box display="flex" flexDirection="column" flex={1}>
          <Button
            type={ButtonType.transparent}
            size={ButtonSize.small}
            title="Back to Dashboard"
            leftImage={ChevronLeftIcon}
            width="150px"
            onClick={() => history.push("/dashboard")}
          />

          <Typography variant="h5" style={{ marginLeft: "31px" }}>
            {property && addressForProperty(property)}
          </Typography>
        </Box>
      </Box>
    );
  }

  function NoVendorAndAgentPlaceholder() {
    const isMobile = useMobile();

    return (
      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        alignItems="center"
      >
        <img
          src={AlarmClockIcon}
          width="124px"
          height="124px"
          alt="alrmClkImage"
        />
        <Box height="24px" />
        <Typography
          variant="body1"
          style={{ marginLeft: "24px", maxWidth: "466px" }}
        >
          Vendor’s legal representative and agent details will be displayed
          after Contrax has completed their review
        </Typography>
      </Box>
    );
  }

  function PropertyInfo() {
    const isMobile = useMobile();

    const bodyFont = isMobile ? "body2" : "body1";
    const noVendorDetails =
      isEmpty(contract?.seller_lawyer?.first_name) &&
      isEmpty(contract?.seller_lawyer?.last_name) &&
      isEmpty(contract?.seller_lawyer?.email);

    const noAgentDetails = isNil(contract?.agent_details);

    return (
      <Box width={isMobile ? "95vw" : "1095px"}>
        <Typography
          variant="h6"
          style={{ fontWeight: 700, marginTop: "32px", marginBottom: "32px" }}
        >
          Property info and controls
        </Typography>

        <Box
          bgcolor={Colors.BrandBackground3}
          boxSizing="border-box"
          pl={isMobile ? "16px" : "35px"}
          pr={isMobile ? "16px" : "35px"}
          pt={isMobile ? "16px" : "38px"}
          pb={isMobile ? "16px" : "38px"}
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          justifyContent="space-between"
          alignItems="center"
        >
          {contract && noAgentDetails && noVendorDetails && (
            <NoVendorAndAgentPlaceholder />
          )}

          {contract && (!noAgentDetails || !noVendorDetails) && (
            <>
              <Box
                display="flex"
                flexDirection="column"
                width={isMobile ? "100%" : "305px"}
                height={isMobile ? undefined : "100px"}
              >
                <Typography variant={bodyFont} style={{ fontWeight: 700 }}>
                  Vendor Solicitor / Conveyancer
                </Typography>
                {contract && !noVendorDetails && (
                  <>
                    <Typography variant={bodyFont}>
                      {contract.seller_lawyer.first_name}{" "}
                      {contract.seller_lawyer.last_name}
                    </Typography>
                    <Typography variant={bodyFont}>
                      {contract.seller_lawyer.firm_name === "Not Available" ? (
                        <>&nbsp;</>
                      ) : (
                        contract.seller_lawyer?.firm_name
                      )}
                    </Typography>
                    <Typography variant={bodyFont}>
                      {contract.seller_lawyer.email}
                    </Typography>
                  </>
                )}

                {contract && noVendorDetails && (
                  <Typography variant="body2" style={{ color: Colors.Grey3 }}>
                    Details have not been provided
                  </Typography>
                )}
              </Box>

              <Box height="13px" />

              <Box
                display="flex"
                flexDirection="column"
                width={isMobile ? "100%" : "305px"}
                height={isMobile ? undefined : "100px"}
              >
                <Typography variant={bodyFont} style={{ fontWeight: 700 }}>
                  Agent
                </Typography>
                <Typography>
                  {contract && !noAgentDetails && (
                    <>
                      <Typography variant={bodyFont}>
                        {contract.agent_details?.first_name}{" "}
                        {contract.agent_details?.last_name}
                      </Typography>
                      <Typography variant={bodyFont}>
                        {contract.agent_details?.agency_name ===
                        "Not Available" ? (
                          <>&nbsp;</>
                        ) : (
                          contract.agent_details?.agency_name
                        )}
                      </Typography>
                      <Typography variant={bodyFont}>
                        {contract.agent_details?.email}
                      </Typography>
                    </>
                  )}

                  {contract && noAgentDetails && (
                    <Typography variant="body2" style={{ color: Colors.Grey3 }}>
                      Agent details have not been provided
                    </Typography>
                  )}
                </Typography>
              </Box>
            </>
          )}

          <Box height="16px" />

          <Box
            width={isMobile ? "100%" : "305px"}
            display="flex"
            flexDirection="column"
          >
            {isBuying && !isAgent && isLawyer && isInternalUser && (
              <>
                <Button
                  type={ButtonType.secondary}
                  size={ButtonSize.medium}
                  width="100%"
                  title="ADD BUYER WORKSPACE"
                  onClick={onAddBuyerWorkspace}
                />
                <Box height="8px" />
              </>
            )}

            {isBuying && !isAgent && (
              <>
                <Button
                  type={ButtonType.secondary}
                  size={ButtonSize.medium}
                  width="100%"
                  title="VIEW CONTRACT"
                  onClick={onViewContract}
                />
              </>
            )}

            {(isAgent || isSelling) && (
              <>
                <Button
                  type={ButtonType.secondary}
                  size={ButtonSize.medium}
                  width="100%"
                  title="INVITE TO TEAM"
                  onClick={() => {
                    setInviteModalOpen(true);
                  }}
                />

                <Box height="8px" />

                <Button
                  type={ButtonType.secondary}
                  size={ButtonSize.medium}
                  width="100%"
                  title="VIEW CONTRACT"
                  onClick={onViewContract}
                />

                <Box height="8px" />

                <Button
                  type={ButtonType.secondary}
                  size={ButtonSize.medium}
                  width="100%"
                  title="SHARE LINK"
                  onClick={() => setShareLinkDialogOpen(true)}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
    );
  }

  function AddBuyerButton() {
    if (isMobile) {
      return (
        <Button
          type={ButtonType.primary}
          size={ButtonSize.medium}
          title="ADD"
          width="80px"
          onClick={() => {
            setAddBuyerDialogOpen(true);
          }}
        />
      );
    }

    return (
      <Button
        type={ButtonType.primary}
        size={ButtonSize.medium}
        title="ADD BUYER"
        width="154px"
        onClick={() => {
          setAddBuyerDialogOpen(true);
        }}
      />
    );
  }

  function TitleAndSort() {
    const showAddBuyerButton = isAgent || (isSelling && isLawyer);

    return (
      <Box
        display="flex"
        justifyContent="space-between"
        width={isMobile ? "90vw" : "1095px"}
        flexDirection="row"
        alignItems="center"
        mt={isMobile ? "13px" : "27px"}
        mb={isMobile ? "13px" : "27px"}
      >
        <Typography
          variant="h6"
          style={{
            fontWeight: 700,
          }}
        >
          Buyer workspaces
        </Typography>

        <Box display="flex" flexDirection="row" alignItems="center">
          {isMobile && showAddBuyerButton && (
            <Box mr="8px">
              <AddBuyerButton />
            </Box>
          )}

          <Dropdown
            width={isMobile ? undefined : "280px"}
            title="Sort by:"
            viewType={isMobile ? DropdownViewType.Icon : DropdownViewType.Text}
            options={sortByOptions}
            selectedOption={sortByOptions.find(
              (option) => option.value === sort
            )}
            onSelectedOption={(option) => setSort(option.value)}
          />

          {!isMobile && showAddBuyerButton && (
            <Box ml="8px">
              <AddBuyerButton />
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  function TableHeader() {
    if (isMobile) {
      return (
        <Box
          display="flex"
          flexDirection="row"
          mt="17px"
          mb="17px"
          width="90vw"
        >
          <Box
            width="33%"
            justifyContent="flex-end"
            display="flex"
            flexDirection="column"
          >
            <Typography variant="overline" style={{ fontWeight: 700 }}>
              NAME OF
            </Typography>
            <Typography variant="overline" style={{ fontWeight: 700 }}>
              PRIMARY BUYER
            </Typography>
          </Box>

          <Box
            width="33%"
            textAlign="center"
            justifyContent="flex-end"
            display="flex"
            flexDirection="column"
          >
            <Typography variant="overline" style={{ fontWeight: 700 }}>
              PROGRESS
            </Typography>
          </Box>

          <Box width="33%"></Box>
        </Box>
      );
    }

    return (
      <Box
        display="flex"
        flexDirection="row"
        mt="11px"
        mb="11px"
        width="1095px"
      >
        <Box width="40%">
          <Typography variant="overline" style={{ fontWeight: 700 }}>
            BUYER NAME
          </Typography>
        </Box>

        <Box width="142px" textAlign="center">
          <Typography variant="overline" style={{ fontWeight: 700 }}>
            CONTRACT
          </Typography>
        </Box>

        <Box width="142px" textAlign="center">
          <Typography variant="overline" style={{ fontWeight: 700 }}>
            LEGAL REVIEW
          </Typography>
        </Box>

        <Box width="142px" textAlign="center">
          <Typography variant="overline" style={{ fontWeight: 700 }}>
            REQUESTS
          </Typography>
        </Box>

        <Box width="142px" textAlign="center">
          <Typography variant="overline" style={{ fontWeight: 700 }}>
            EXCHANGE
          </Typography>
        </Box>

        <Box width="100px"></Box>
      </Box>
    );
  }

  function TableContent() {
    return (
      <Box>
        {recordsSorted.map((record) => (
          <WorkspaceItem
            contractId={contractId}
            recordId={record.pk}
            record={record}
            role={isBuying ? "buyer" : isSelling ? "seller" : undefined}
            onOpen={() => {
              const isPaid = isRecordPaid(record);

              if (
                isBuying &&
                (isLawyer || isPartner) &&
                isPaid &&
                record.assigned_to_me === false &&
                record.part_of_team === false &&
                record.pay_notinvited === true
              ) {
                history.push(
                  `/pay-notinvited-workspace?contractId=${contractId}&recordId=${record.pk}&type=buying`
                );
              } else if (isBuying && isLawyer && !isPaid) {
                // if (localStorage.getItem(creditsFeatureKey) === "true") {
                history.push(
                  `/create-workspace?contractId=${contractId}&recordId=${record.pk}&type=buying`
                );
                // } else {
                //   history.push(
                //     `/pay-workspace?contractId=${contractId}&recordId=${record.pk}&type=buying`
                //   );
                // }
              } else if (
                isSelling &&
                isLawyer &&
                isPaid &&
                record.assigned_to_me === false
              ) {
                history.push(
                  `/pay-notinvited-workspace?contractId=${contractId}&recordId=${record.pk}&type=selling`
                );
              } else if (
                isBuying &&
                (isLawyer || isPartner) &&
                isPaid &&
                record.assigned_to_me === false &&
                record.part_of_team === false &&
                record.pay_notinvited === false
              ) {
                history.push(
                  // For buyer lawyer to open to join workspace page
                  `/join-workspace?contractId=${contractId}&recordId=${record.pk}&type=buying`
                );
              } else if (
                isSelling &&
                isBuySeller &&
                isPaid &&
                record.assigned_to_me === false &&
                (record.amendment_request_status === RecordStatus.InProgress ||
                  record.amendment_request_status === RecordStatus.Completed)
              ) {
                history.push(
                  `/request-workspace?contractId=${contractId}&recordId=${record.pk}`
                );
              }

              if (
                isPaid &&
                (record.assigned_to_me === true ||
                  record.part_of_team === true ||
                  isAgentForContract)
              ) {
                if (
                  record.amendment_request_status === RecordStatus.InProgress ||
                  record.amendment_request_status === RecordStatus.Completed
                ) {
                  history.push(
                    `/request-workspace?contractId=${contractId}&recordId=${record.pk}`
                  );
                } else if (
                  record.legal_review_status === RecordStatus.InProgress ||
                  record.legal_review_status === RecordStatus.Completed
                ) {
                  history.push(
                    `/legal-review-viewer?contractId=${contractId}&recordId=${record.pk}`
                  );
                } else if (
                  record.contract_status === RecordStatus.InProgress ||
                  record.contract_status === RecordStatus.Completed
                ) {
                  history.push(
                    `/legal-review-viewer?contractId=${contractId}&recordId=${record.pk}`
                  );
                } else {
                  history.push(
                    `/legal-review-builder?contractId=${contractId}&recordId=${record.pk}`
                  );
                }
              }
            }}
          />
        ))}
      </Box>
    );
  }

  const showPlaceholder = !isNil(recordsData) && isEmpty(records);

  return (
    <>
      <AlertWrapper>
        <Box display="flex" flexDirection="column" minHeight="100vh">
          <Box display="flex" flexDirection="column" flex={1}>
            <AccountDetailsHeader />

            <TitleBar />

            <Box display="flex" flexDirection="column" alignItems="center">
              <PropertyInfo />

              {!showPlaceholder && (
                <>
                  <TitleAndSort />
                  <TableHeader />
                  <TableContent />
                </>
              )}

              {showPlaceholder && (
                <NoWorkspacesPlaceholder
                  onAddBuyer={() => {
                    setAddBuyerDialogOpen(true);
                  }}
                />
              )}
            </Box>
          </Box>

          <DesktopFooter />
        </Box>
      </AlertWrapper>
      <ShareLinkDialog
        open={shareLinkDialogOpen}
        onClose={() => setShareLinkDialogOpen(false)}
      />

      <LoginModal
        open={loginModalOpen}
        contractId={contract_Id}
        onLoggedIn={() => {
          setLoginModalOpen(false);
          refreshUserData();
          recordsMutate();
        }}
        inviterName={`${inviterFirstName} ${inviterLastName}`.trim()}
      />

      <SignUpModal
        open={signUpModalOpen}
        onClose={() => setSignUpModalOpen(false)}
        contractId={contract_Id}
        inviterName={`${inviterFirstName} ${inviterLastName}`.trim()}
        inviterFirmName={orgName}
        token={invitedVerificationToken}
        onSignedUp={() => {
          setSignUpModalOpen(false);
          refreshUserData();
          recordsMutate();
        }}
        onLogin={() => {
          setSignUpModalOpen(false);
          setLoginModalOpen(true);
          recordsMutate();
        }}
      />

      {contractId && isSelling && user && (
        <>
          {contract?.seller_lawyers.includes(user.pk) ||
          contract?.sellers_pk.includes(user.pk) ? (
            <InviteModal
              type={InviteModalType.sellers}
              open={inviteModalOpen}
              onClose={() => setInviteModalOpen(false)}
              onInviteTeamMembersClicked={() => {
                setInviteModalOpen(false);
                setInviteToSellerTeamOpen(true);
              }}
              onInviteAgents={() => {
                setInviteModalOpen(false);
                setInviteToAgentTeamOpen(true);
              }}
              contractId={+contractId}
            />
          ) : (
            <InviteModalNotInvited
              type={InviteModalTypeNotInvited.sellers}
              open={inviteModalOpen}
              onClose={() => setInviteModalOpen(false)}
              onInviteTeamMembersClicked={() => {
                setInviteModalOpen(false);
                setInviteToSellerTeamOpen(true);
              }}
              onInviteAgents={() => {
                setInviteModalOpen(false);
                setInviteToAgentTeamOpen(true);
              }}
              contractId={+contractId}
            />
          )}
          <PropertyDetailsInviteYourTeamMembersModal
            type={PropertyDeatilsInviteYourTeamMembersModalType.sellerTeam}
            open={inviteToSellerTeamOpen}
            onClose={() => setInviteToSellerTeamOpen(false)}
            onInvited={(firstName, lastName) => {
              setInviteToSellerTeamOpen(false);
              setSuccessMessage(
                `${firstName} ${lastName} has been invited to your team`
              );

              refreshContractTeamsAndRole();
            }}
            contractId={+contractId}
            recordId={""}
          />
          <PropertyDetailsInviteYourTeamMembersModal
            type={PropertyDeatilsInviteYourTeamMembersModalType.agents}
            open={inviteToAgentTeamOpen}
            onClose={() => setInviteToAgentTeamOpen(false)}
            onInvited={(firstName, lastName) => {
              setInviteToAgentTeamOpen(false);
              setSuccessMessage(
                `${firstName} ${lastName} has been invited to your team`
              );

              setInviteModalOpen(true);
              refreshContractTeamsAndRole();
            }}
            contractId={+contractId}
            recordId={""}
          />
          {/* </>
          )} */}
        </>
      )}

      {isAgent && contractId && (
        <>
          <InviteModal
            type={InviteModalType.agents}
            open={inviteModalOpen}
            onClose={() => setInviteModalOpen(false)}
            onInviteAgents={() => {
              setInviteModalOpen(false);
              setInviteToAgentTeamOpen(true);
            }}
            onInviteSellers={() => {
              setInviteModalOpen(false);
              setInviteToSellerTeamOpen(true);
            }}
            contractId={+contractId}
          />

          <PropertyDetailsInviteYourTeamMembersModal
            type={PropertyDeatilsInviteYourTeamMembersModalType.agents}
            open={inviteToAgentTeamOpen}
            onClose={() => setInviteToAgentTeamOpen(false)}
            onInvited={(firstName, lastName) => {
              setInviteToAgentTeamOpen(false);
              setSuccessMessage(
                `${firstName} ${lastName} has been invited to your team`
              );

              setInviteModalOpen(true);
              refreshContractTeamsAndRole();
            }}
            contractId={+contractId}
            recordId={""}
          />

          <PropertyDetailsInviteYourTeamMembersModal
            type={PropertyDeatilsInviteYourTeamMembersModalType.sellerTeam}
            open={inviteToSellerTeamOpen}
            onClose={() => setInviteToSellerTeamOpen(false)}
            onInvited={(firstName, lastName) => {
              setInviteToSellerTeamOpen(false);
              setSuccessMessage(
                `${firstName} ${lastName} has been invited to your team`
              );
              refreshContractTeamsAndRole();
            }}
            contractId={+contractId}
            recordId={""}
          />
        </>
      )}

      {contractId && (
        <AddBuyerDialog
          open={addBuyerDialogOpen}
          onClose={() => setAddBuyerDialogOpen(false)}
          contractId={+contractId}
          onAdded={(firstName, lastName) => {
            setAddBuyerDialogOpen(false);
            setSuccessMessage(
              `${firstName} ${lastName} has been added as a buyer`
            );
            recordsMutate();
          }}
        />
      )}

      {contractFile && (
        <ViewContractDrawer
          contractFile={contractFile}
          contractDrawer={contractDrawerOpen}
          handleContactDrawer={() => setContractDrawerOpen(false)}
        />
      )}

      {property && (
        <UseCreditsDialog
          open={useCreditsOpen}
          onUseCredit={onUseCredit}
          onCancel={() => setUseCreditsOpen(false)}
          loading={usingCredit}
          paymentSuccessful={paymentSuccess}
        />
      )}
    </>
  );
}
