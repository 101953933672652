import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useMobile, useDesktop } from "../hooks/mobile";
import { useHistory, useLocation } from "react-router-dom";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import Button, { ButtonType, ButtonSize } from "../components/common/Button";
import AuthenticatedWrapper from "../components/AuthenticatedWrapper";
import HeroLayout from "../components/HeroLayout";
import ConfirmationIcon from "../images/Confirmation.png";

export default function UpdateContractSuccess(): JSX.Element {
  const history = useHistory();
  const location = useLocation();
  const isMobile = useMobile();
  const isDesktop = useDesktop();

  const query = new URLSearchParams(location.search);
  const action = query.get("action");

  function TitleString() {
    if (action === "sold") {
      return "The property has been marked as sold";
    }

    if (action === "delete") {
      return "The property has been deleted";
    }

    return "";
  }

  function Content() {
    return (
      <Box mt={4}>
        <Typography variant="h4">{TitleString()}</Typography>
      </Box>
    );
  }

  return isDesktop ? (
    <AuthenticatedWrapper>
      <HeroLayout>
        <Box mt={isDesktop ? 22 : 5} mb={5}>
          <img src={ConfirmationIcon} />
          <Content />
        </Box>
        <Box display="flex" justifyContent="right">
          <Button
            type={ButtonType.primary}
            size={ButtonSize.medium}
            title="CLOSE"
            width="143px"
            onClick={() => history.push("/dashboard")}
          />
        </Box>
      </HeroLayout>
    </AuthenticatedWrapper>
  ) : (
    <AuthenticatedWrapper>
      <Box m={3}>
        <img src={ConfirmationIcon} height="80px" />
        <Content />
        <MobileStickyFooter
          buttonsLayout={FooterButtonsLayout.centered}
          buttonTitle="CLOSE"
          buttonOnClick={() => history.push("/dashboard")}
        />
      </Box>
    </AuthenticatedWrapper>
  );
}
