import React from "react";
import { Drawer, Box, Typography } from "@material-ui/core";
import StickyHeader from "../common/StickyHeader";
import BackButtonMobile from "../common/BackButtonMobile";
import ActivityLog from "./ActivityLog";
import { Activity } from "../../api/models";
import { Colors } from "../../constants/colors";

interface ActivityLogDrawerProps {
  open: boolean;
  onClose: () => unknown;
  activities: Activity[];
}
export default function ActivityLogDrawer({
  open,
  onClose,
  activities,
}: ActivityLogDrawerProps) {
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box height="100vh" width="100vw" bgcolor={Colors.Grey5}>
        <StickyHeader
          height="72px"
          display="flex"
          flexDirection="row"
          pl="18px"
          alignItems="center"
          bgcolor="white"
        >
          <BackButtonMobile onClick={onClose} />
          <Typography variant="h6" style={{ marginLeft: "18px" }}>
            Activity Log
          </Typography>
        </StickyHeader>

        <Box m="27px">
          <ActivityLog activities={activities} />
        </Box>
      </Box>
    </Drawer>
  );
}
