import { Box, Typography } from "@material-ui/core";
import { debounce, isEmpty, isNil } from "lodash-es";
import moment from "moment";
import React from "react";
import {
  BuyerSellerStatus,
  isSellerRequestStage,
  LegalReviewRequest,
  LegalReviewRequestStage,
  LegalReviewRequestStatus,
} from "../../api/models";
import { Colors } from "../../constants/colors";
import { debounceDelay } from "../../constants/common";
import { useMobile } from "../../hooks/mobile";
import Button, { ButtonSize, ButtonType } from "../common/Button";
import RadioWithTitle from "../common/RadioWithTitle";
import Separator from "../common/Separator";
import StatusPill, { statusPillTypeForRequest } from "../StatusPill";

interface Props {
  request: LegalReviewRequest;
  index: number;
  onStage: (stage: LegalReviewRequestStage) => unknown;
  onAlternativeChanged: (alternative: string) => unknown;
  onMessageChanged: (message: string) => unknown;
  onEdit: () => unknown;
  onSave: () => unknown;
  onViewComments: () => unknown;
  actionsEnabled: boolean;
}

interface HeaderProps {
  request: LegalReviewRequest;
}

function Header({ request }: HeaderProps) {
  const isMobile = useMobile();
  const statusPillType = statusPillTypeForRequest(request);

  if (isMobile) {
    return (
      <Box
        boxShadow="0px 1px 0px #E0E0E0"
        display="flex"
        flexDirection="column"
        pl="16px"
        pr="16px"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          mt="16px"
          mb="16px"
        >
          <Box display="flex" flexDirection="row" alignItems="center" flex={1}>
            <Typography
              variant={isMobile ? "body1" : "h6"}
              style={{
                fontWeight: 700,
              }}
            >
              {request.title}
            </Typography>
          </Box>

          <StatusPill type={statusPillType} />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      flex={1}
      pl="24px"
      pr="24px"
      boxShadow="0px 1px 0px #E0E0E0"
      minHeight="77px"
    >
      <Box display="flex" flexDirection="row" alignItems="center" flex={1}>
        <Typography variant="h6" style={{ fontWeight: 700, marginLeft: "8px" }}>
          {request.title}
        </Typography>
      </Box>

      <StatusPill type={statusPillType} />
    </Box>
  );
}

interface ContentProps {
  request: LegalReviewRequest;
  message: string;
  onMessage: (message: string) => unknown;
  alternative: string;
  onAlternative: (alternative: string) => unknown;
  onStage: (stage: LegalReviewRequestStage) => unknown;
}

function Content({
  request,
  message,
  onMessage,
  onStage,
  alternative,
  onAlternative,
}: ContentProps) {
  const isMobile = useMobile();
  const titleVariant = isMobile ? "body2" : "body1";

  return (
    <Box
      display="flex"
      flexDirection={isMobile ? "column" : "row"}
      flex={1}
      alignItems={isMobile ? "center" : undefined}
    >
      <Box
        display="flex"
        flexDirection="column"
        width={isMobile ? "90%" : "483px"}
        m={isMobile ? undefined : "24px"}
      >
        <Box p="8px" mb="16px">
          <Typography variant={titleVariant} style={{ fontWeight: 700 }}>
            Amendment Request
          </Typography>
          <Typography variant="body2">{request.description}</Typography>
        </Box>

        <Box bgcolor={Colors.BrandBackground3} borderRadius="4px" p="8px">
          <Typography variant={titleVariant} style={{ fontWeight: 700 }}>
            Proposed change to contract
          </Typography>
          <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
            {request.request_status === LegalReviewRequestStage.buyerAgreed
              ? request.alternate_change
              : request.propose}
          </Typography>
        </Box>

        {request.buyer_message && (
          <Box p="8px" mt="8px">
            <Typography
              variant="body1"
              style={{ fontWeight: 700, marginBottom: "8px" }}
            >
              Message from buyer
            </Typography>
            <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
              {request.buyer_message}
            </Typography>
          </Box>
        )}
      </Box>

      {isMobile && <Separator width="100%" />}
      {request.status === LegalReviewRequestStatus.editing && (
        <Box
          display="flex"
          flexDirection="column"
          width={isMobile ? "90%" : undefined}
        >
          <Typography
            variant="body2"
            style={{ fontWeight: 700, marginBottom: "4px", marginTop: "24px" }}
          >
            Response to request
          </Typography>

          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            marginBottom="24px"
          >
            <RadioWithTitle
              title="Agreed"
              selected={
                request.request_status === LegalReviewRequestStage.sellerAgreed
              }
              onSelected={() => onStage(LegalReviewRequestStage.sellerAgreed)}
              onUnselected={() => onStage(LegalReviewRequestStage.pending)}
            />
            <Box width="8px" />
            <RadioWithTitle
              title="Not Agreed"
              selected={
                request.request_status ===
                LegalReviewRequestStage.sellerNotAgreed
              }
              onSelected={() =>
                onStage(LegalReviewRequestStage.sellerNotAgreed)
              }
              onUnselected={() => onStage(LegalReviewRequestStage.pending)}
            />
            <Box width="8px" />

            <RadioWithTitle
              title="Propose Alternative"
              selected={
                request.request_status ===
                LegalReviewRequestStage.alternativeSuggested
              }
              onSelected={() =>
                onStage(LegalReviewRequestStage.alternativeSuggested)
              }
              onUnselected={() => onStage(LegalReviewRequestStage.pending)}
            />
          </Box>

          {request.request_status ===
            LegalReviewRequestStage.alternativeSuggested && (
            <Box
              marginBottom="16px"
              display="flex"
              flexDirection="column"
              flex={1}
            >
              <Typography
                variant={titleVariant}
                style={{ fontWeight: 700, marginBottom: "4px" }}
              >
                Alternative change to contract
              </Typography>

              <textarea
                style={{
                  minHeight: "74px",
                  fontFamily: "GT Walsheim Pro",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "20px",
                  border: "1px solid #E8E8E8",
                  padding: "16px",
                  flex: 1,
                }}
                value={alternative}
                onChange={(event) => onAlternative(event.target.value)}
              />
            </Box>
          )}

          <Box
            marginBottom="24px"
            display="flex"
            flexDirection="column"
            flex={1}
          >
            <Typography
              variant={titleVariant}
              style={{ fontWeight: 700, marginBottom: "4px" }}
            >
              Message (optional)
            </Typography>

            <textarea
              style={{
                minHeight: "74px",
                fontFamily: "GT Walsheim Pro",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
                border: "1px solid #E8E8E8",
                padding: "16px",
                flex: 1,
              }}
              value={message}
              onChange={(event) => onMessage(event.target.value)}
            />
          </Box>
        </Box>
      )}

      {request.status === LegalReviewRequestStatus.published && (
        <Box
          p="18px"
          display="flex"
          flexDirection="column"
          width={isMobile ? "90%" : "515px"}
        >
          <Box height="14px" />
          {request.request_status ===
            LegalReviewRequestStage.alternativeSuggested && (
            <Box marginBottom="32px">
              <Typography
                variant={titleVariant}
                style={{ fontWeight: 700, marginBottom: "4px" }}
              >
                Alternative change to contract
              </Typography>

              <Typography variant="body2">
                {request.alternate_change}
              </Typography>
            </Box>
          )}

          <Typography
            variant={titleVariant}
            style={{
              fontWeight: 700,
              marginBottom: "4px",
            }}
          >
            Message (optional)
          </Typography>

          <Typography
            variant="body2"
            style={{
              color: isEmpty(request.seller_message) ? Colors.Grey3 : undefined,
            }}
          >
            {isEmpty(request.seller_message)
              ? "No message entered"
              : request.seller_message}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

interface FooterProps {
  request: LegalReviewRequest;
  onViewComments: () => unknown;
  onEdit: () => unknown;
  onSave: () => unknown;
  actionsEnabled: boolean;
}

function Footer({
  request,
  onViewComments,
  onEdit,
  onSave,
  actionsEnabled,
}: FooterProps) {
  const isMobile = useMobile();

  const _commentsCount = () => {
    if (request.p_commentsCount > 0) {
      return request.p_commentsCount;
    }

    return request.comments_count || 0;
  };

  const _messagesCount = () => {
    if (request.p_messagesCount > 0) {
      return request.p_messagesCount;
    }

    return request.messages_count || 0;
  };

  const commentsCount = _commentsCount();
  const messagesCount = _messagesCount();

  const isSent = !isNil(request.sent_on);
  const isCompleted = request.seller_status === BuyerSellerStatus.Completed;

  const saveEnabled =
    request.request_status === LegalReviewRequestStage.sellerAgreed ||
    request.request_status === LegalReviewRequestStage.sellerNotAgreed ||
    request.request_status === LegalReviewRequestStage.alternativeSuggested;

  return (
    <Box
      display="flex"
      flexDirection="row"
      boxShadow="0px -1px 0px #E0E0E0"
      height="74px"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="row" alignItems="center" pl="12px">
        <Button
          title={isMobile ? "COMMENTS" : "VIEW COMMENTS"}
          type={ButtonType.transparent}
          size={ButtonSize.medium}
          onClick={onViewComments}
          letterSpacing="2px"
          width={isMobile ? "115px" : "179px"}
        />
        {!isMobile && (
          <Typography variant="body2" style={{ marginLeft: "8px" }}>
            {commentsCount + messagesCount} Comments
          </Typography>
        )}
      </Box>

      <Box display="flex" flexDirection="row" alignItems="center" pr="16px">
        {!isMobile && isSent && (
          <Typography
            variant="body2"
            style={{ color: Colors.Grey3, marginRight: "16px" }}
          >
            Sent on {moment(request.sent_on).format("D MMM [at] h:mm A")}
          </Typography>
        )}

        {!isMobile && !isSent && (
          <Typography
            variant="body2"
            style={{ color: Colors.Grey3, marginRight: "16px" }}
          >
            Not sent
          </Typography>
        )}

        {actionsEnabled &&
          !isCompleted &&
          !isSent &&
          request.status === LegalReviewRequestStatus.published &&
          isSellerRequestStage(request.request_status) && (
            <Button
              type={ButtonType.secondary}
              size={ButtonSize.medium}
              title="EDIT"
              width="84px"
              onClick={onEdit}
            />
          )}

        {actionsEnabled &&
          request.status === LegalReviewRequestStatus.editing && (
            <Button
              type={ButtonType.primary}
              size={ButtonSize.medium}
              title="SAVE"
              width="79px"
              onClick={onSave}
              disabled={!saveEnabled}
            />
          )}
      </Box>
    </Box>
  );
}

export default function RequestItem({
  request,
  index,
  onStage,
  onAlternativeChanged,
  onMessageChanged,
  onEdit,
  onSave,
  onViewComments,
  actionsEnabled = true,
}: Props) {
  const isMobile = useMobile();

  const [message, setMessage] = React.useState(request.seller_message);
  const [alternative, setAlternative] = React.useState(
    request.alternate_change
  );

  const messageChanged = (text: string) => {
    onMessageChanged && onMessageChanged(text);
  };

  const debouncedMessageChanged = React.useCallback(
    debounce(messageChanged, debounceDelay),
    []
  );

  const onMessage = (text) => {
    setMessage(text);

    debouncedMessageChanged(text);
  };

  const alternativeChanged = (text: string) => {
    onAlternativeChanged && onAlternativeChanged(text);
  };

  const debouncedAlternativeChanged = React.useCallback(
    debounce(alternativeChanged, debounceDelay),
    []
  );

  const onAlternative = (text) => {
    setAlternative(text);

    debouncedAlternativeChanged(text);
  };

  const isDeleted = request.request_status === LegalReviewRequestStage.deleted;

  return (
    <Box
      border="1px solid #E1EAFF"
      bgcolor="white"
      borderRadius="16px"
      m="10px"
      display="flex"
      flexDirection="column"
      width={isMobile ? "95vw" : "1097px"}
      color={isDeleted ? Colors.Grey3 : undefined}
    >
      <Header request={request} />

      <Box display="flex" flexDirection="row">
        <Content
          request={request}
          message={message}
          onMessage={onMessage}
          alternative={alternative}
          onAlternative={onAlternative}
          onStage={onStage}
        />
      </Box>

      <Footer
        request={request}
        onViewComments={onViewComments}
        onEdit={onEdit}
        onSave={onSave}
        actionsEnabled={actionsEnabled}
      />
    </Box>
  );
}
