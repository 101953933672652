import { Box, ButtonBase, Typography, withStyles } from "@material-ui/core";
import React from "react";
import { useLocation } from "react-router-dom";
import { Colors } from "../constants/colors";
import { useSessionStore } from "../stores/Session";

interface Props {
  onClick: (event: any) => void;
}

const MyTypography = withStyles({
  root: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
})(Typography);

export default function ({ onClick }: Props) {
  const location = useLocation();
  const { pathname } = location;

  const { data: user } = useSessionStore();

  const activePaths = [
    "/user/credits",
    "/user/billing",
    "/user/invoices",
    "/user/usage",
    "/user/account",
  ];

  const isActive = activePaths.includes(pathname);

  return (
    <ButtonBase onClick={onClick}>
      <Box display="flex" flexDirection="row" alignItems="center" p={2}>
        <MyTypography
          variant="body1"
          style={{
            marginRight: "12px",
            color: isActive ? Colors.BrandPrimary : Colors.Grey2,
          }}
        >
          {user?.first_name}
        </MyTypography>
        {/* <img src={DropdownIcon} /> */}
      </Box>
    </ButtonBase>
  );
}
