import React from "react";
import { Box } from "@material-ui/core";
import Amex from "../../images/AMEX.svg";
import Mastercard from "../../images/Mastercard.svg";
import Visa from "../../images/Visa.svg";
import { CardBrand } from "../../api/models";

function imageForCardBrand(brand: CardBrand) {
  switch (brand) {
    case CardBrand.Amex:
      return Amex;
    case CardBrand.Visa:
      return Visa;
    case CardBrand.Mastercard:
      return Mastercard;
  }
}

interface Props {
  brand?: CardBrand | null;
}

export default function CardBrandItem({ brand }: Props) {
  return brand ? (
    <Box>
      <img src={imageForCardBrand(brand)} height="30px" />
    </Box>
  ) : (
    <></>
  );
}
