import React from "react";
import { Box, ButtonBase } from "@material-ui/core";

interface Props {
  image: string;
  onClick: (event: any) => void;
  disabled?: boolean;
}

export default function ({ image, onClick, disabled = false }: Props) {
  return (
    <ButtonBase
      onClick={onClick}
      disabled={disabled}
      style={{ opacity: disabled ? 0.3 : 1.0 }}
    >
      <img src={image} />
    </ButtonBase>
  );
}
