import { Box, Link, Typography } from "@material-ui/core";
import copy from "copy-to-clipboard";
import QRCode from "qrcode.react";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import { Contract, Property } from "../api/models";
import AlertWrapper from "../components/AlertWrapper";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";
import { Colors } from "../constants/colors";
import { useAlert } from "../contexts/Alert";
import { useMobile } from "../hooks/mobile";

const downloadQR = () => {
  const canvas = document.getElementById("link-qrcode") as HTMLCanvasElement;
  const pngUrl =
    canvas &&
    canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
  let downloadLink = document.createElement("a");
  downloadLink.href = pngUrl;
  downloadLink.download = "contrax-legal-review-link.png";
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

interface Props {
  contractId?: number | null;
}

export default function ShareLink({
  contractId: contractIdProp,
}: Props): JSX.Element {
  const history = useHistory();
  const isMobile = useMobile();
  const location = useLocation();

  const query = new URLSearchParams(location.search);

  // NOTE: can be either contractId or propertyId
  // If contractId is provided then it fetches the contract and get propertyId
  // ContractId can be provided through props, or obtained via URL parameters
  const contractId = contractIdProp || query.get("contractId");

  const { data: contractData } = useSWR(
    contractId ? `/view-contracts/${contractId}/` : null
  );
  const contract = contractData && (contractData as Contract);

  const propertyId = query.get("propertyId") || contract?.property_id;

  const { data: propertyData } = useSWR(`/view-properties/${propertyId}/`);
  const property = propertyData && (propertyData as Property);

  const { setSuccessMessage } = useAlert();

  if (!property || !propertyId) {
    return <Box />;
  }

  const shareLink = `${window.location.origin}/app/property-info?propertyId=${propertyId}&code=${property.code}/`;

  return (
    <AlertWrapper>
      <Box>
        <Box
          display="flex"
          flexDirection="column"
          marginLeft="auto"
          marginRight="auto"
          alignItems={isMobile ? "center" : undefined}
          pl={5}
          pr={5}
        >
          <Box>
            <Typography variant="body1">
              Send this contract of sale and legal review to your buyers and
              their legal advisers using the link and QR code provided below.
            </Typography>
          </Box>
          <Box
            minHeight={isMobile ? "131px" : "131px"}
            width="100%"
            alignItems="center"
            justifyContent="center"
            display="flex"
          >
            <Box width="100%" ml={2} mr={2}>
              <Box>
                <Button
                  type={ButtonType.primary}
                  size={ButtonSize.large}
                  title="COPY LINK TO SHARE"
                  width="100%"
                  onClick={() => {
                    copy(shareLink);
                    setSuccessMessage("Copied Link");
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box textAlign="center">
            <Typography variant="button" style={{ fontWeight: 700 }}>
              Alternatively you can
            </Typography>
          </Box>
          <Box
            bgcolor={Colors.BrandBackground3}
            display="flex"
            flexDirection="row"
            alignItems="center"
            minHeight={isMobile ? "100px" : "100px"}
            width={isMobile ? "100%" : "100%"}
            mt={1}
          >
            <Box ml={isMobile ? 2 : 3.25} mr={1.625}>
              <Typography variant="body1" style={{}}>
                <Link
                  style={{ fontWeight: 700, cursor: "pointer" }}
                  onClick={() => downloadQR()}
                >
                  Click here to download the QR code.
                </Link>
              </Typography>
            </Box>
            <Box
              bgcolor="white"
              width="76px"
              height="76px"
              alignItems="center"
              justifyContent="center"
              display="flex"
              mr={isMobile ? 1.375 : 6}
            >
              <QRCode size={60} value={shareLink} />
            </Box>
          </Box>
        </Box>
      </Box>
      <QRCode
        id="link-qrcode"
        size={128}
        value={shareLink}
        style={{ display: "none" }}
      />
    </AlertWrapper>
  );
}
