import { Box } from "@material-ui/core";
import { AxiosError } from "axios";
import { isEmpty } from "lodash-es";
import React from "react";
import { useHistory } from "react-router-dom";
import api from "../api/instance";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";
import DesktopFooter from "../components/common/DesktopFooter";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import TextField from "../components/common/TextField";
import Wrapper from "../components/UserAccount/Wrapper";
import { useAlert } from "../contexts/Alert";
import { useMobile } from "../hooks/mobile";

export default function ChangePassword(): JSX.Element {
  const isMobile = useMobile();
  const history = useHistory();

  const [currentPassword, setCurrentPassword] = React.useState("");
  const [currentPasswordError, setCurrentPasswordError] = React.useState<
    null | string
  >(null);
  const [newPassword, setNewPassword] = React.useState("");
  const [newPasswordError, setNewPasswordError] = React.useState<null | string>(
    null
  );
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [confirmPasswordError, setConfirmPasswordError] = React.useState<
    null | string
  >(null);

  const [loading, setLoading] = React.useState(false);

  // const sideMargin = isMobile ? 3.25 : 21.5;

  const { setErrorMessage } = useAlert();

  function verifyFields() {
    let allFieldsValid = true;

    if (isEmpty(currentPassword)) {
      setCurrentPasswordError("Please enter your current password");
      allFieldsValid = false;
    }

    if (isEmpty(newPassword)) {
      setNewPasswordError("Please enter your new password");
      allFieldsValid = false;
    }

    if (isEmpty(confirmPassword)) {
      setConfirmPasswordError("Please repeat your new password");
      allFieldsValid = false;
    }

    if (newPassword !== confirmPassword) {
      setConfirmPasswordError("Please make sure your new passwords match");
      allFieldsValid = false;
    }

    return allFieldsValid;
  }

  function resetErrors() {
    setCurrentPasswordError(null);
    setNewPasswordError(null);
    setConfirmPasswordError(null);
  }

  function saveClicked() {
    resetErrors();

    if (!verifyFields()) {
      return;
    }

    setLoading(true);

    api
      .post("/change-password/", {
        old_password: currentPassword,
        new_password: newPassword,
        confirm_password: confirmPassword,
      })
      .then((response) => {
        setLoading(false);

        history.push("/login?resetPasswordSuccess=true");
      })
      .catch((error: AxiosError) => {
        setLoading(false);

        setErrorMessage(
          "Could not update password, please make sure your current password is valid"
        );
      });
  }

  return (
    <Wrapper title="Change Password">
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <Box display="flex" flexDirection="column" flex={1}>
          {/* {!isMobile && (
            <React.Fragment>
              <DashboardHeaderDesktop />

              <Box
                bgcolor={Colors.BrandBackground2}
                height="192px"
                display="flex"
                alignItems="center"
              >
                <Box ml={sideMargin} mr={sideMargin}>
                  <Typography variant="h4">Change Password</Typography>
                </Box>
              </Box>
            </React.Fragment>
          )} */}
          <Box
            mt={8}
            mb={5}
            maxWidth={isMobile ? `calc(100vw - 64px)` : "500px"}
          >
            {/* {isMobile && (
              <React.Fragment>
                <Typography variant="h5">Change Password</Typography>
                <Box mt={4} />
              </React.Fragment>
            )} */}

            <TextField
              title="Enter Current Password"
              type="password"
              value={currentPassword}
              error={currentPasswordError !== null}
              helperText={currentPasswordError}
              onChange={(value) => setCurrentPassword(value)}
            />
            <Box mt={2} />
            <TextField
              title="New Password"
              type="password"
              value={newPassword}
              error={newPasswordError !== null}
              helperText={newPasswordError}
              onChange={(value) => setNewPassword(value)}
            />
            <Box mt={2} />
            <TextField
              title="Repeat New Password"
              type="password"
              value={confirmPassword}
              error={confirmPasswordError !== null}
              helperText={confirmPasswordError}
              onChange={(value) => setConfirmPassword(value)}
            />
            <Box mt={2} />

            {!isMobile && (
              <Box
                display="flex"
                flexDirection="row"
                mt={4}
                justifyContent="flex-end"
              >
                <Button
                  type={ButtonType.secondary}
                  size={ButtonSize.medium}
                  title="CANCEL"
                  width="137px"
                  onClick={() => history.goBack()}
                />
                <Box ml={2} />
                <Button
                  type={ButtonType.primary}
                  size={ButtonSize.medium}
                  title="SAVE"
                  width="100px"
                  loading={loading}
                  onClick={() => saveClicked()}
                />
              </Box>
            )}
          </Box>
        </Box>

        {!isMobile && <DesktopFooter />}
        {isMobile && (
          <MobileStickyFooter
            buttonsLayout={FooterButtonsLayout.sideBySide}
            leftButtonTitle="CANCEL"
            leftButtonOnClick={() => history.goBack()}
            rightButtonTitle="SAVE"
            rightButtonLoading={loading}
            rightButtonOnClick={() => saveClicked()}
          />
        )}
      </Box>
    </Wrapper>
  );
}
