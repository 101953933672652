import React from "react";
import { Box, Checkbox, Typography } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import Radio from "../../components/common/Radio";

interface Props {
  title: string;
  description: string;
  selected: boolean;
  onSelected: () => unknown;
}

export default function ProceedDialogItem({
  title,
  description,
  selected,
  onSelected,
}: Props) {
  return (
    <Box
      borderRadius="8px"
      bgcolor={selected ? "#C9EBE2" : "white"}
      border={selected ? "1px solid #F4F6F8" : "1px solid #E8E8E8"}
      display="flex"
      flexDirection="column"
    >
      <Box
        display="flex"
        flexDirection="row"
        color={Colors.Grey2}
        alignItems="center"
        mt="8px"
        ml="8px"
      >
        <Radio
          checked={selected}
          onChange={(event) => {
            event.target.checked && onSelected();
          }}
          value={selected}
        />
        <Typography variant="body1" style={{ fontWeight: 700 }}>
          {title}
        </Typography>
      </Box>
      <Box pl="20px" pr="12px" pt="4px" pb="24px">
        <Typography variant="body1">{description}</Typography>
      </Box>
    </Box>
  );
}
