import { Box, Dialog, Link, Typography } from "@material-ui/core";
import { isEmpty, isNil } from "lodash-es";
import React from "react";
import { useHistory } from "react-router-dom";
import useSWR from "swr";
import { Contract, Property } from "../../api/models";
import Button, { ButtonSize, ButtonType } from "../../components/common/Button";
import Separator from "../../components/common/Separator";
import TextField from "../../components/common/TextField";
import { Colors } from "../../constants/colors";
import { useMobile } from "../../hooks/mobile";
import ModalHeader from "./ModalHeader";
import PropertyInfoBar from "./PropertyInfoBar";
import api from "../../api/instance";
import axios, { AxiosError } from "axios";
import { useAlert } from "../../contexts/Alert";

interface Props {
  open: boolean;
  contractId?: number | null;
  recordId?: number | null;
  onLoggedIn: () => unknown;
  inviterName?: string;
  onCancel?: () => unknown;
}

const noAuthApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export default function LoginModal({
  open,
  contractId,
  recordId,
  onLoggedIn,
  inviterName,
  onCancel,
}: Props) {
  const isMobile = useMobile();
  const history = useHistory();

  const { data, mutate: contractMutate } = useSWR(
    !isNil(contractId) ? `/view-contracts/${contractId}/` : null
  );
  const contract = data ? (data as Contract) : null;

  const { data: propertyData } = useSWR(
    contract?.property_id ? `/view-properties/${contract.property_id}/` : null
  );
  const property = propertyData && (propertyData as Property);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loggingIn, setLoggingIn] = React.useState(false);

  const { setErrorMessage } = useAlert();

  const onLogin = () => {
    setLoggingIn(true);

    noAuthApi
      .post("/token/", {
        username: email,
        password,
      })
      .then((response) => {
        const token = response.data.token;
        localStorage.setItem("token", token);

        api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        // api
        //   .post(`/contract/${contractId}/record/${recordId}/team/joined/`)
        //   .then((response) => {
        //     setLoggingIn(false);

        //     onLoggedIn();
        //   })
        //   .catch((error) => {
        //     setErrorMessage(error.message);
        //   });

        setLoggingIn(false);
        onLoggedIn();
      })
      .catch((error: AxiosError) => {
        setLoggingIn(false);

        const errorData = error.response?.data;
        const message =
          errorData &&
          errorData.non_field_errors &&
          errorData.non_field_errors[0];
        setErrorMessage(message || error.message);
      });
  };

  const showPropertyBar =
    !isNil(contractId) && !isNil(recordId) && !isEmpty(inviterName);

  return (
    <Dialog
      open={open}
      aria-labelledby="login-modal"
      aria-describedby="login-modal-description"
      fullScreen={isMobile}
    >
      <Box width={isMobile ? "100%" : "425px"}>
        <ModalHeader />
        {showPropertyBar && (
          <PropertyInfoBar property={property} inviterName={inviterName} />
        )}

        <Box pl="39px" pr="39px" pt="43px" pb="133px">
          <TextField
            title="Your email"
            value={email}
            onChange={(text) => setEmail(text)}
            type="email"
          />

          <TextField
            title="Password"
            value={password}
            onChange={(text) => setPassword(text)}
            type="password"
          />

          <Box mt="30px">
            <Typography variant="caption" style={{ fontWeight: 700 }}>
              Forgot your{" "}
              <Link
                href="#"
                style={{ color: Colors.Link }}
                onClick={() => history.push("/reset-password-request")}
              >
                password?
              </Link>
            </Typography>
          </Box>
        </Box>

        <Separator />
        <Box
          height="74px"
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          boxSizing="border-box"
          pl="16px"
          pr="16px"
        >
          {onCancel && (
            <>
              <Button
                type={ButtonType.secondary}
                size={ButtonSize.medium}
                title="CANCEL"
                width="114px"
                onClick={onCancel}
              />
            </>
          )}

          <Button
            loading={loggingIn}
            title="CONTINUE"
            type={ButtonType.primary}
            size={ButtonSize.medium}
            width="125px"
            onClick={onLogin}
          />
        </Box>
      </Box>
    </Dialog>
  );
}
