import React from "react";
import { Box } from "@material-ui/core";
import TextField from "../common/TextField";
import { ContractSummary } from "../../api/models";
import { Colors } from "../../constants/colors";
import Button, { ButtonSize, ButtonType } from "../common/Button";
import { Draggable } from "react-beautiful-dnd";
import { debounce } from "lodash-es";
import { debounceDelay } from "../../constants/common";
export enum SummaryEditorBoxType {
  default,
  dismissed,
}

interface Props {
  type?: SummaryEditorBoxType;
  contractSummary: ContractSummary;
  onViewInContract: () => unknown;
  onAdd?: () => unknown;
  onDismiss?: () => unknown;
  onDelete?: () => unknown;
  onRestore?: () => unknown;
  index: number;
  onTitleChanged?: (title: string) => unknown;
  onSummaryChanged?: (summary: string) => unknown;
}

function _SummaryEditorBox({
  type = SummaryEditorBoxType.default,
  contractSummary,
  onViewInContract,
  onAdd,
  onDismiss,
  onDelete,
  onRestore,
  index,
  onTitleChanged,
  onSummaryChanged,
}: Props) {
  // console.debug("rendering for index: ", index);

  const [title, setTitle] = React.useState(contractSummary.title);
  const [summary, setSummary] = React.useState(contractSummary.content);

  const titleChanged = (text: string) => {
    onTitleChanged && onTitleChanged(text);
  };

  const debouncedTitleChanged = React.useCallback(
    debounce(titleChanged, debounceDelay),
    []
  );

  const onTitle = (text) => {
    setTitle(text);

    debouncedTitleChanged(text);
  };

  const summaryChanged = (text: string) => {
    onSummaryChanged && onSummaryChanged(text);
  };

  const debouncedSummaryChanged = React.useCallback(
    debounce(summaryChanged, debounceDelay),
    []
  );

  const onSummary = (text) => {
    setSummary(text);

    debouncedSummaryChanged(text);
  };

  return (
    <Draggable
      draggableId={`summary-${contractSummary.pk}-${index}`}
      index={index}
    >
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Box
            p="16px"
            bgcolor={
              type === SummaryEditorBoxType.dismissed
                ? "#F7F7F7"
                : Colors.BrandBackground3
            }
            m="8px"
            borderRadius="8px"
          >
            <TextField
              title="Title"
              value={title}
              onChange={onTitle}
              disabled={type === SummaryEditorBoxType.dismissed}
              greyedOut={type === SummaryEditorBoxType.dismissed}
            />
            <TextField
              title="Summary"
              value={summary}
              onChange={onSummary}
              multiline
              disabled={type === SummaryEditorBoxType.dismissed}
              greyedOut={type === SummaryEditorBoxType.dismissed}
            />

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                type={ButtonType.transparent}
                size={ButtonSize.small}
                title="VIEW IN CONTRACT"
                letterSpacing="2px"
                onClick={onViewInContract}
              />

              <Box display="flex" flexDirection="row">
                {type === SummaryEditorBoxType.default && (
                  <>
                    <Button
                      type={ButtonType.destructive}
                      size={ButtonSize.small}
                      title="DISMISS"
                      width="93px"
                      onClick={onDismiss}
                    />
                    <Box width="8px" />
                    <Button
                      type={ButtonType.primary}
                      size={ButtonSize.small}
                      title="ADD"
                      width="58px"
                      onClick={onAdd}
                    />
                  </>
                )}

                {type === SummaryEditorBoxType.dismissed && (
                  <>
                    <Button
                      type={ButtonType.destructive}
                      size={ButtonSize.small}
                      title="DELETE"
                      width="83px"
                      onClick={onDelete}
                    />
                    <Box width="8px" />
                    <Button
                      type={ButtonType.primary}
                      size={ButtonSize.small}
                      title="RESTORE"
                      width="96px"
                      onClick={onRestore}
                    />
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </div>
      )}
    </Draggable>
  );
}

const SummaryEditorBox = React.memo(
  _SummaryEditorBox,
  (prevProps, nextProps) => {
    return (
      prevProps.contractSummary.title === nextProps.contractSummary.title &&
      prevProps.contractSummary.content === nextProps.contractSummary.content &&
      prevProps.contractSummary.status === nextProps.contractSummary.status &&
      prevProps.onDelete === nextProps.onDelete
    );
  }
);

export default SummaryEditorBox;
