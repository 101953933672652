import { Box, Typography } from "@material-ui/core";
import { isEmpty, isNil } from "lodash-es";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import { MyProperty, User, UserType } from "../api/models";
import AlertWrapper from "../components/AlertWrapper";
import AuthenticatedWrapper from "../components/AuthenticatedWrapper";
import DesktopFooter from "../components/common/DesktopFooter";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import DashboardHeader from "../components/Dashboard/Header";
import DashboardButton from "../components/DashboardButton";
import MyInvoiceRow from "../components/MyInvoiceRow";
import { Colors } from "../constants/colors";
import { useDesktop } from "../hooks/mobile";
import NoProperties from "../images/no-properties.png";
import { useSessionStore } from "../stores/Session";

export default function MyInvoices(): JSX.Element {
  const isDesktop = useDesktop();
  const location = useLocation();
  const history = useHistory();

  const [selectedValue, setSelectedValue] = React.useState<null | string>(null);

  const { data: myPropertiesData } = useSWR("/my-properties/");
  const myProperties =
    myPropertiesData && (myPropertiesData as Array<MyProperty>);
  const myPropertiesWithInvoices =
    myProperties && myProperties.filter((property) => !isNil(property.invoice));

  const { data: userData, refresh: refreshUserData } = useSessionStore();
  const user = userData && (userData as User);
  // console.debug("my prop data: ", myPropertiesData);
  // console.debug("myProperties: ", myProperties);

  function EmptyPlaceholder() {
    return (
      <Box textAlign="center" mt={14}>
        <img src={NoProperties} height="167px" />
        <Typography variant="body1" style={{ marginTop: "24px" }}>
          You haven't purchased any legal reviews
        </Typography>
      </Box>
    );
  }

  return (
    <AuthenticatedWrapper>
      <AlertWrapper>
        <Box display="flex" flexDirection="column" minHeight="100vh">
          <Box display="flex" flexDirection="column" flex={1}>
            {isDesktop && (
              <React.Fragment>
                <DashboardHeader />

                <Box bgcolor={Colors.BrandBackground2} pt={8} pb={8} pl={21}>
                  <Typography variant="h4">My invoices</Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  pl={21}
                  pr={21}
                  pt={1.5}
                  alignItems="flex-start"
                >
                  <Box display="flex" flexDirection="column" flex={1} mr={3}>
                    {myPropertiesWithInvoices &&
                      isEmpty(myPropertiesWithInvoices) && <EmptyPlaceholder />}
                    {myPropertiesWithInvoices &&
                      myPropertiesWithInvoices.map((property) => (
                        <MyInvoiceRow property={property} />
                      ))}
                  </Box>

                  {/* <DashboardButton
                    isMobile={false}
                    title="My properties"
                    subtitle={
                      user?.user_type === UserType.Agent
                        ? "From here you can view all of the sale contracts you have uploaded and their status"
                        : "From here, you can view all the legal reviews and contracts of sale you have purchased"
                    }
                    onClick={() => history.push("/my-properties")}
                  /> */}
                </Box>
              </React.Fragment>
            )}
            {!isDesktop && (
              <React.Fragment>
                <DashboardHeader />
                <Box bgcolor={Colors.BrandBackground2} pt={8} pb={8} pl={3}>
                  <Typography variant="h4">My invoices</Typography>
                </Box>
                {myPropertiesWithInvoices &&
                  isEmpty(myPropertiesWithInvoices) && <EmptyPlaceholder />}
                {myPropertiesWithInvoices &&
                  myPropertiesWithInvoices.map((property) => (
                    <MyInvoiceRow property={property} />
                  ))}

                {/* <MobileStickyFooter
                  buttonsLayout={FooterButtonsLayout.centered}
                  buttonTitle="MY PROPERTIES"
                  buttonOnClick={() => history.push("/my-properties")}
                /> */}
              </React.Fragment>
            )}
          </Box>
          <Box mt={5} />
          <DesktopFooter />
        </Box>
      </AlertWrapper>
    </AuthenticatedWrapper>
  );
}
