import { Box, Dialog, Typography } from "@material-ui/core";
import React from "react";
import { useMobile } from "../../hooks/mobile";
import ProceedDialogItem from "./ProceedDialogItem";
import Button, { ButtonType, ButtonSize } from "../common/Button";
import Separator from "../common/Separator";
import api from "../../api/instance";
import { useAlert } from "../../contexts/Alert";

interface Props {
  open: boolean;
  onSent: () => unknown;
  onCancelled: () => unknown;
  contractId: number | null;
  recordId: number | null;
}

export default function ProceedDialog({
  open,
  onSent,
  onCancelled,
  contractId,
  recordId,
}: Props) {
  const isMobile = useMobile();

  const [proceed, setProceed] = React.useState<boolean | null>(null);
  const [message, setMessage] = React.useState("");
  const [sending, setSending] = React.useState(false);

  const { setErrorMessage } = useAlert();

  return (
    <Dialog
      open={open}
      aria-labelledby="proceed-dialog"
      aria-describedby="proceed-dialog-description"
      fullScreen={isMobile}
    >
      <Box
        display="flex"
        flexDirection="column"
        maxWidth={isMobile ? "100%" : "520px"}
      >
        <Box
          height="62px"
          boxShadow="0px 1px 0px #E0E0E0"
          alignItems="center"
          display="flex"
          width="100w"
        >
          <Typography
            variant="h5"
            style={{ fontWeight: 700, marginLeft: "24px" }}
          >
            Do you want to proceed?
          </Typography>
        </Box>

        <Box pt="24px" pb="24px" pl="20px" pr="20px">
          <Typography
            variant="body1"
            style={{ fontWeight: 700, marginBottom: "16px" }}
          >
            Are you ready to proceed?
          </Typography>

          <ProceedDialogItem
            selected={proceed === true}
            title="Yes, Proceed"
            description="Your lawyer or conveyancer will act on your behalf and make these requests and enquiries to the seller's representative."
            onSelected={() => setProceed(true)}
          />

          <Box height="8px" />

          <ProceedDialogItem
            selected={proceed === false}
            title="No, Pause"
            description="I’m not ready or no longer interested in this property."
            onSelected={() => setProceed(false)}
          />

          <Box display="flex" flexDirection="column" mt="24px" mb="24px">
            <Typography
              variant="body2"
              style={{ fontWeight: 700, marginBottom: "4px" }}
            >
              Message (optional)
            </Typography>

            <textarea
              style={{
                minHeight: "117px",
                fontFamily: "GT Walsheim Pro",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
                border: "1px solid #E8E8E8",
                padding: "16px",
                flex: 1,
              }}
              value={message}
              onChange={(event) => setMessage(event.target.value)}
            />
          </Box>
        </Box>
        <Separator />
        <Box
          display="flex"
          justifyContent="flex-end"
          pl="24px"
          pr="16px"
          height="74px"
          alignItems="center"
        >
          <Button
            title="CANCEL"
            size={ButtonSize.medium}
            type={ButtonType.secondary}
            onClick={onCancelled}
            width="114px"
          />
          <Box width="8px" />
          <Button
            width="201px"
            loading={sending}
            title="SEND INSTRUCTION"
            size={ButtonSize.medium}
            type={ButtonType.primary}
            onClick={() => {
              if (proceed === null) {
                setErrorMessage(
                  "Please select if you want to proceed: Yes or No"
                );
                return;
              }

              setSending(true);

              api
                .post(
                  `/contract/${contractId}/record/${recordId}/proceed/legal-review/`,
                  {
                    message,
                    action: proceed === true ? "Agree" : "Pause",
                  }
                )
                .then((response) => {
                  setSending(false);
                  onSent();
                })
                .catch((error) => {
                  setSending(false);
                  setErrorMessage(error.message);
                });
            }}
          />
        </Box>
      </Box>
    </Dialog>
  );
}
