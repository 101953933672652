import React, { useEffect } from "react";
import { Box, Drawer, Typography, Paper } from "@material-ui/core";
import ViewSDKClient from '../helpers/ViewSDKClient'
import { useMobile, useDesktop } from "../hooks/mobile";
import CloseButton, {
    CloseButtonColor,
} from "../components/common/CloseButton";
import { Colors } from "../constants/colors";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";

interface Props {
    contractFile?: string | null;
    contractDrawer?: boolean;
    handleContactDrawer: () => unknown;
    pageNo?: number | null;
}

export default function ({ contractFile, contractDrawer, handleContactDrawer, pageNo }: Props) {

    const isMobile = useMobile();
    const isDesktop = useDesktop();

    useEffect(() => {
        if (contractDrawer && contractFile) {
            const viewSDKClient = new ViewSDKClient();
            viewSDKClient.ready().then(() => {
                /* Invoke file preview */
                const AdobeEmbedInstance = viewSDKClient.previewFile("pdf-div", contractFile, "Contrax.pdf",
                    {
                        showAnnotationTools: false,
                        enableFormFilling: false,
                        dockPageControls: false,
                        showPageControls: false,
                        showDownloadPDF: false,
                        showLeftHandPanel: false,
                        showPrintPDF: false,
                        defaultView: "FIT_PAGE"
                    }
                );
                AdobeEmbedInstance.then((adobeViewer) => {
                    adobeViewer.getAPIs().then((apis) => {
                      apis.gotoLocation(pageNo)
                        .then(() => console.log("Success"))
                        .catch((error) => console.log(error));
                    });
                  })
            })
        }
    }, [contractDrawer])

    return (
      <Box>
        {isMobile && (
          <Drawer
            anchor={"right"}
            open={contractDrawer}
            onClose={handleContactDrawer}
          >
            <Box padding="10px" display="flex" justifyContent="space-between">
              <Typography variant="h5">Contract</Typography>
              <CloseButton
                color={CloseButtonColor.BLUE}
                onClick={handleContactDrawer}
              />
            </Box>
            <div
              id="pdf-div"
              className="sized-container-div"
              style={{ width: "100vw" }}
            />
          </Drawer>
        )}

        {isDesktop && (
          <Drawer
            anchor={"right"}
            open={contractDrawer}
            onClose={handleContactDrawer}
            disableBackdropClick
            variant="temporary"
          >
            <Box position="fixed" left="calc(100vw - 55vw)" top="14px">
              <Paper
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CloseButton
                  color={CloseButtonColor.BLUE}
                  onClick={handleContactDrawer}
                />
              </Paper>
            </Box>
            <Box padding="10px" display="flex" justifyContent="space-between">
              <Typography variant="h5">Contract</Typography>
              <Button
                width="176px"
                type={ButtonType.secondary}
                size={ButtonSize.small}
                title="DOWNLOAD"
                titleColor={Colors.BrandPrimary}
                onClick={() => contractFile && window.open(contractFile)}
              />
            </Box>
            <div
              id="pdf-div"
              className="sized-container-div"
              style={{ width: isMobile ? 0 : "50vw" }}
            />
          </Drawer>
        )}
      </Box>
    );
}
