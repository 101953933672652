import {
  Document,
  Image,
  Page,
  PDFDownloadLink,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { isEmpty } from "lodash-es";
import React from "react";
import api from "../../api/instance";
import {
  addressForProperty,
  Contract,
  LegalReviewRequest,
  LegalReviewRequestStage,
  Property,
} from "../../api/models";
import { Colors } from "../../constants/colors";
import { useMobile } from "../../hooks/mobile";
import BLUELOGO from "../../images/BlueLogo.png";
import Button, { ButtonSize, ButtonType } from "../common/Button";

// Create styles
const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "50px",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

// Create Document Component
interface MyDocumentProps {
  requests: LegalReviewRequest[];
  property: Property | null;
}

interface LinedFieldProps {
  title: string;
}

function LinedField({ title }: LinedFieldProps) {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "33px",
        width: "45%",
      }}
    >
      <View style={{ height: "1px", flex: 1, backgroundColor: "#D3D3D3" }} />
      <Text
        style={{
          fontFamily: "GT Walsheim Pro",
          fontWeight: 400,
          fontSize: "8px",
          marginTop: "8px",
        }}
      >
        {title}
      </Text>
    </View>
  );
}

const MyDocument = ({ requests, property }: MyDocumentProps) => {
  // REMINDER: lineHeight and letterSpacing for Text doesn't work
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Text
              style={{
                fontFamily: "GT Walsheim Pro",
                fontWeight: 700,
                fontSize: "10px",
              }}
            >
              Amendment appendix for
            </Text>
            <Text
              style={{
                marginTop: "5px",
                fontFamily: "GT Walsheim Pro",
                fontWeight: 400,
                fontSize: "12px",
              }}
            >
              {property && addressForProperty(property)}
            </Text>
          </View>
          <Image src={BLUELOGO} style={{ height: "19px" }} />
        </View>

        <Text
          style={{
            fontFamily: "GT Walsheim Pro",
            fontWeight: 400,
            fontSize: "10px",
            marginTop: "30px",
            marginBottom: "24px",
            width: "100%",
          }}
        >
          This appendix contains the agreed amendments between the parties and
          forms part of the sale contract.
        </Text>

        {requests.map((request) => (
          <View
            wrap={false}
            style={{
              width: "85vw",
              backgroundColor: "white",
              margin: "8px",
              display: "flex",
              flexDirection: "column",
              borderRadius: "8px",
              border: "1px solid #E8E8E8",
            }}
          >
            {/* Top */}
            <View
              style={{
                height: "60px",
                paddingLeft: "16px",
                paddingRight: "16px",
                justifyContent: "space-between",
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
              }}
            >
              {/* Title */}
              <Text
                style={{
                  fontWeight: 700,
                  fontSize: "16px",
                  fontFamily: "GT Walsheim Pro",
                }}
              >
                {request.title}
              </Text>
              {/* Status pill */}
              <View
                style={{
                  width: "56px",
                  height: "19px",
                  backgroundColor: Colors.SuccessBackground,
                  borderRadius: "7px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    color: Colors.UISuccess,
                    fontWeight: 700,
                    fontSize: "10px",
                    fontFamily: "GT Walsheim Pro",
                  }}
                >
                  AGREED
                </Text>
              </View>
            </View>

            {/* Bottom */}
            <View
              style={{
                padding: "16px",
                backgroundColor: Colors.BrandBackground3,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text
                style={{
                  marginBottom: "8px",
                  fontWeight: 700,
                  fontSize: "10px",
                  fontFamily: "GT Walsheim Pro",
                }}
              >
                {request.alternate_change
                  ? "ALTERNATIVE CHANGE TO CONTRACT"
                  : "PROPOSED CHANGE TO CONTRACT"}
              </Text>
              <Text
                style={{
                  fontFamily: "GT Walsheim Pro",
                  fontWeight: 400,
                  fontSize: "11px",
                }}
              >
                {request.alternate_change || request.propose}
              </Text>
            </View>
          </View>
        ))}

        <View
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "60px",
            width: "100%",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <LinedField title="Purchaser signature" />
            <LinedField title="Purchaser signature" />
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <LinedField title="Date" />
            <LinedField title="Date" />
          </View>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "60px",
            width: "100%",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <LinedField title="Vendor signature" />
            <LinedField title="Vendor signature" />
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <LinedField title="Date" />
            <LinedField title="Date" />
          </View>
        </View>
      </Page>
    </Document>
  );
};

interface Props {
  contractId: number | null;
  recordId: number | null;
}

export default function ({ contractId, recordId }: Props) {
  const isMobile = useMobile();

  const [loadingRequests, setLoadingRequests] = React.useState(false);
  const [loadingProperty, setLoadingProperty] = React.useState(false);
  const [requests, setRequests] = React.useState<LegalReviewRequest[]>([]);
  const [property, setProperty] = React.useState<Property | null>(null);

  // console.debug("download requests: ", requests);

  React.useEffect(() => {
    // Requests
    setLoadingRequests(true);
    api
      .get(`/contract/${contractId}/record/${recordId}/legal-review-requests/`)
      .then((response) => {
        setLoadingRequests(false);

        const allRequests = response.data as LegalReviewRequest[];
        const requests = allRequests.filter(
          (request) =>
            request.request_status === LegalReviewRequestStage.buyerAgreed ||
            request.request_status === LegalReviewRequestStage.sellerAgreed
        );
        setRequests(requests);
      })
      .catch((error) => {});

    // Property
    setLoadingProperty(true);
    api
      .get(`/view-contracts/${contractId}/`)
      .then((response) => {
        const contract = response.data as Contract;

        api
          .get(`/view-properties/${contract.property_id}/`)
          .then((response) => {
            setLoadingProperty(false);

            const property = response.data as Property;
            setProperty(property);
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  }, []);

  const filename = () => {
    const address = property ? addressForProperty(property) : "";

    if (!isEmpty(address)) {
      return `contrax_${address.replace(/[\W_]+/g, "_")}`;
    }

    return "contrax.pdf";
  };

  return (
    <div
      style={{
        pointerEvents: loadingRequests || loadingProperty ? "none" : undefined,
      }}
    >
      <PDFDownloadLink
        document={<MyDocument requests={requests} property={property} />}
        fileName={filename()}
        style={{ textDecoration: "none" }}
      >
        {({ blob, url, loading, error }) => {
          return (
            <Button
              loading={loadingRequests || loadingProperty || loading}
              title={isMobile ? "DOWNLOAD" : "DOWNLOAD AMENDMENT APPENDIX"}
              type={ButtonType.secondary}
              size={ButtonSize.small}
              width="292px"
              titleColor={Colors.BrandPrimary}
            />
          );
        }}
      </PDFDownloadLink>
    </div>
  );
}
