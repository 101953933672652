export interface Agency {
  id: number;
  name: string;
  suburb: string;
}

export enum UserType {
  Agent = "AGT",
  Buyer = "BYR",
  Solicitor = "SOL",
  Admin = "ADM",
  Partner = "PRT",
}

export interface User {
  pk: number;
  first_name: string;
  last_name: string;
  user_type: UserType;
  email: string;
  phone: string;
  agency_name?: string;
  agency_id?: number;
  firm_name?: string;
  validation_token?: string;
  current_organization?: UserCurrentOrganisation;
  available_credits?: number;
  is_active: boolean;
  new_notification_count: number;
  is_internal_user: boolean;
}

export interface LinkVerify {
  status: boolean;
}

export interface LinkExpire {
  status: boolean;
}

export interface UserCurrentOrganisation {
  pk: number;
  name: string;
  title: string;
  email: string;
  phone_no: string;
  logo: string;
  color_code: string;
  uuid: string;
}

export interface Organisation {
  name: string;
  description: string;
  email: string;
  website: string;
  phone_no: string;
  fax_no: string;
  logo: string;
  address: {
    street: string;
    city: string;
    state: string;
    country: string;
    postal_code: number;
  };
  color_code: string;
  title: string;
  uuid: string;
  current_url: string;
  redirect: boolean;
  available_credits: number;
}

export interface OrgDashboardDetail {
  name: string;
  logo: string;
}

export interface ContractVersion {
  id: number;
  number: number;
}

export interface Property {
  id: number;
  place_id: string;
  property_url?: string;
  image?: string;
  address: {
    street: string;
    city: string;
    state: string;
    country: string;
    postal_code: number;
  };
  property_stats: Array<MyPropertyStats>;
  status: string;
  buyer_status?: string;
  agent?: User;
  contract_id: number;
  contract_versions: Array<ContractVersion>;
  code: string;
  view_count: string;
}

export function addressForProperty(property: Property) {
  return `${property.address.street} ${property.address.city}, ${property.address.state}, ${property.address.postal_code}`;
}

export interface MyPropertyReview {
  page: number;
  section: string;
  clause: string;
  title: string;
  content: string;
  checked: boolean;
  flagged: boolean;
}

export interface MyProperty {
  address: string;
  image: string;
  status: string;
  buyer_status?: string;
  agent: string;
  agency: string;
  invoice?: string;
  contract: string;
  contract_id: number;
  property_id: number;
  invite_count?: number;
  view_count?: number;
  purchase_count?: number;
  reviews?: Array<MyPropertyReview>;
}

export interface MyPropertyStats {
  user: {
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
    user_type: string;
  };
  status: string;
  modified: string;
}

export enum ContractPublishStatus {
  Pending = "PND",
  Published = "PBL",
  Delete = "DLT",
  Sold = "SLD",
}

export enum ContractWorkflowStatus {
  Backlog = "BKL",
  InProgress = "PRG",
  OnHold = "HLD",
  ReadyForReview = "RFR",
  Reviewed = "RVW",
  Archived = "ARC",
}

export interface Contract {
  id: number;
  file: string;
  property_id: number;
  agent: {
    first_name: string;
    last_name: string;
    user_type: string;
    email: string;
    phone: string;
    agency_id: number;
    agency_name: string;
  };
  publish_status: ContractPublishStatus;
  workflow_status: ContractWorkflowStatus;
  legal_summary_published: boolean;
  seller_lawyer: {
    first_name: string;
    last_name: string;
    user_type: string;
    email: string;
    phone: string;
    firm_name: string;
    pk: number;
  };
  seller_lawyers: number[];
  sellers_pk: number[];
  primary_buyer: {
    first_name: string;
    last_name: string;
    user_type: string;
    email: string;
    phone: string;
  };
  primary_buyer_lawyer: {
    first_name: string;
    last_name: string;
    user_type: string;
    email: string;
    phone: string;
    firm_name: string;
    pk: number;
  };
  contract_completed: boolean;
  legal_review_status: RecordStatus;
  amendment_request_status: RecordStatus;
  contract_status: RecordStatus;
  agent_details: {
    first_name: string;
    last_name: string;
    user_type: string;
    email: string;
    phone: string;
    agency_id: number;
    agency_name: string;
  };
  logo: string;
  color_code: string;
  company_title: string;
  company_pk: number;
  title: string;
  partner_company_pk: number;
}

export interface ContractSummary {
  pk: number;
  title: string;
  content: string;
  page: number;
  index?: number;
  status: ContractSummaryState;
  created: string;
  modified: string;
  comments: ContractSummaryComment[];
  default_summary: number;
  p_isDefault: boolean;
  p_adding: boolean;
  p_comments?: Comment[]; // Internal to keep track of comments, mainly for PDF generating purposes
}

export interface ContractSummaryComment {
  id: number;
  user: number;
  content: string;
  created: string;
}

export enum ContractSummaryState {
  published = "Published",
  editing = "Editing",
  dismissed = "Dismissed",
  deleted = "Deleted",
}

export interface Comment {
  pk: number;
  legal_review_summary: number;
  user: User;
  content: string;
  created: string;
  is_buyer_flow: boolean;
}

// NOTE: On UI Buyer Lawyer is Buyer Legal Rep
export enum TeamMemberRole {
  buyer = "Buyer",
  buyerLawyer = "Buyer Lawyer",
  seller = "Seller",
  sellerLawyer = "Seller Lawyer",
  agent = "Agent",
  partner = "Buyer Rep",
}

export interface MyTeamMember {
  pk: number;
  first_name: string;
  last_name: string;
  user_type: string;
  email: string;
  phone: string;
  firm_name: string;
  invite_status: string;
}

export interface LegalTeamMember {
  pk: number;
  first_name: string;
  last_name: string;
  email: string;
}

//This api is for invite modal(buyer team, seller team and agent team)

export interface TeamMember {
  pk: number;
  first_name: string;
  last_name: string;
  email: string;
  role: TeamMemberRole;
}

export enum ActivityAction {
  Joined = "Joined",
  Published = "Published",
  Request = "Request",
  Respond = "Respond",
  Agree = "Agree",
  Pause = "Pause",
  Cancel = "Cancel",
  ReviewRequest = "Review Request",
  Comment = "Comment",
  Message = "Message",
  UpdatedRequest = "Updated Request",
  UpdatedResponse = "Updated Response",
}

export interface Activity {
  pk: number;
  contract_version: number;
  user: User;
  action: ActivityAction;
  created: string;
  modified: string;
}

export interface MessageData {
  enquiry_id: number;
  message_content: string;
  message_id: number;
  request_id: number;
  title: string;
}

export interface CommentData {
  comment_content: string;
  comment_id: number;
  enquiry_id: string;
  request_id: number;
  summary_id: number;
  title: string;
}

export interface Progress {
  amendment_request_status: string;
  contract_status: string;
  legal_review_status: string;
}

export interface NotificationActivity {
  pk: number;
  contract_version: number;
  user: User;
  action: ActivityAction;
  created: string;
  modified: string;
  contract_id: number;
  record_id: number;
  property_address: string;
  message_data: MessageData;
  comment_data: CommentData;
  progress: Progress;
  is_read: boolean;
}

export interface Enquiry {
  pk: number;
  title: string;
  enquiry: string;
  index: number;
  status: EnquiryStatus;
  created: string;
  modified: string;
  enquiry_status: EnquiryStage;
  contract_version: number;
  answer_to_enquiry: string;
  // seller_status: EnquiryStatus;
  seller_responded: boolean;
  buyer_enquiry_pk?: number; // Only exists on seller side
  is_sent: boolean;
  buyer_status: BuyerSellerStatus;
  seller_status: BuyerSellerStatus;
  p_updated: boolean; // Internal to keep track if updated and needs to be sent back to API
  sent_once: boolean;
  sent_on: string;
  comments_count: number;
  messages_count: number;
  p_commentsCount: number; // Internal to keep track of comments count
  p_messagesCount: number; // Internal
  default_enquiry: number | null;
  p_teamComments?: Comment[]; // Internal mainly for PDF generation purposes
  p_legalComments?: Comment[]; // Internal mainly for PDF generation purposes
}

export function isEnquiryBuyerRespondedAndPublished(enquiry: Enquiry) {
  return (
    enquiry.buyer_status === BuyerSellerStatus.Responded &&
    enquiry.status === EnquiryStatus.Published
  );
}

export function isEnquirySellerRespondedAndPublished(enquiry: Enquiry) {
  return (
    enquiry.seller_status === BuyerSellerStatus.Responded &&
    enquiry.status === EnquiryStatus.Published
  );
}

export enum EnquiryStatus {
  Published = "Published",
  Editing = "Editing",
  Dismissed = "Dismissed",
  Deleted = "Deleted",
}

export enum EnquiryStage {
  Pending = "Pending",
  Answered = "Answered",
  Deleted = "Deleted",
}

export interface DefaultEnquiry {
  pk: number;
  general_title: string;
  enquiry: string;
  created: string;
  modified: string;
}

export enum BuyerSellerStatus {
  Pending = "Pending",
  Responded = "Responded",
  Sent = "Sent",
  Completed = "Completed",
}

export interface LegalReviewRequest {
  pk: number;
  title: string;
  description: string;
  propose: string;
  index: number;
  status: LegalReviewRequestStatus;
  created: string;
  modified: string;
  request_status: LegalReviewRequestStage;
  seller_message: string;
  alternate_change: string;
  legal_review_summary?: ContractSummary;
  old_versions: LegalReviewRequest[];
  is_current: boolean;
  buyer_message: string; // TODO: Temporary until API is ready
  seller_responded: boolean;
  buyer_request_pk?: number; // Only exists for seller side
  // is_sent: boolean;
  p_addingNewVersion: boolean;
  buyer_status: BuyerSellerStatus;
  seller_status: BuyerSellerStatus;
  buyer_responded: boolean; // Only for POSTing back to API
  p_updated: boolean; // Internal to keep track if updated and needs to be sent back to API
  sent_once: boolean;
  sent_on: string;
  is_sent: boolean;
  comments_count: number;
  messages_count: number;
  p_commentsCount: number; // Internal to keep track of comments count
  p_messagesCount: number; // Internal
  default_summary_request: number | null;
  p_teamComments?: Comment[]; // Internal mainly for PDF generation purposes
  p_legalComments?: Comment[]; // Internal mainly for PDF generation purposes
}

export function isRequestBuyerRespondedAndPublished(
  request: LegalReviewRequest
) {
  return (
    request.buyer_status === BuyerSellerStatus.Responded &&
    request.status === LegalReviewRequestStatus.published
  );
}

export function isRequestSellerRespondedAndPublished(
  request: LegalReviewRequest
) {
  return (
    request.seller_status === BuyerSellerStatus.Responded &&
    request.status === LegalReviewRequestStatus.published
  );
}

export enum LegalReviewRequestStatus {
  published = "Published",
  editing = "Editing",
  dismissed = "Dismissed",
  deleted = "Deleted",
}

export enum LegalReviewRequestStage {
  buyerAgreed = "Buyer Agreed",
  sellerAgreed = "Seller Agreed",
  buyerNotAgreed = "Buyer Not Agreed",
  sellerNotAgreed = "Seller Not Agreed",
  alternativeSuggested = "Propose Alternative",
  pending = "Pending",
  answered = "Answered", // TODO: Temp
  deleted = "Deleted",
}

export function isSellerRequestStage(stage: LegalReviewRequestStage) {
  return (
    stage === LegalReviewRequestStage.sellerAgreed ||
    stage === LegalReviewRequestStage.sellerNotAgreed ||
    stage === LegalReviewRequestStage.alternativeSuggested ||
    stage === LegalReviewRequestStage.pending
  );
}

export interface DefaultLegalReviewRequest {
  pk: number;
  request_title: string;
  legal_summary: ContractSummary;
  content: string;
  propose: string;
  created: string;
  modified: string;
}

export enum RecordStatus {
  Pending = "Pending",
  InProgress = "In Progress",
  Completed = "Completed",
}

export interface ContractRecord {
  pk: number;
  primary_buyer: {
    first_name: string;
    last_name: string;
    user_type: string;
    email: string;
    phone: string;
  };
  contract_status: RecordStatus;
  legal_review_status: RecordStatus;
  amendment_request_status: RecordStatus;
  exchange_status: RecordStatus; // NOTE: Not in API yet
  created: string;
  is_buyer_lawyer: boolean;
  paid: boolean;
  assigned_to_me: boolean;
  part_of_team: boolean;
  other_side_amendment_request_status: boolean;
  uses_credit: boolean;
  title: string;
  record_via_partner: boolean;
  pay_notinvited: boolean;
}

export function isRecordPaid(record: ContractRecord) {
  return record.paid === true || record.uses_credit === true;
}

export interface DashboardContract {
  id: number;
  listing: {
    pk: number;
    address: {
      street: string;
      city: string;
      state: string;
      country: string;
      postal_code: number;
    };
    image: string;
    property_url: string;
    created: string;
  };
  publish_status: ContractPublishStatus;
  buyer_count: number;
  record: ContractRecord;
  assigned_to_me: boolean;
  new_count: number;
  in_progress_count: number;
  invited_by: boolean;
  buyer_invite_lawyer: boolean;
  seller_lawyer_invite_buyer: boolean;
  buyer_invite_buyer: boolean;
  is_archive: boolean;
}

export interface DashboardData {
  count: number;
  results: DashboardContract[];
}

export enum WorkspaceProgress {
  Contract,
  LegalReview,
  Requests,
  Exchange,
}

export function workspaceProgressForRecord(record: ContractRecord) {
  // TODO: Exchange
  if (
    record.amendment_request_status === RecordStatus.InProgress ||
    record.amendment_request_status === RecordStatus.Completed
  ) {
    return WorkspaceProgress.Requests;
  }

  if (
    record.legal_review_status === RecordStatus.InProgress ||
    record.legal_review_status === RecordStatus.Completed
  ) {
    return WorkspaceProgress.LegalReview;
  }

  return WorkspaceProgress.Contract;
}

export enum CreditPackageType {
  Buyer = "BYR",
  Solicitor = "SOL",
}

export interface CreditPackage {
  id: number;
  type: CreditPackageType;
  credit_amount: number;
  total_units: number;
  discount: number;
  is_recommended: boolean;
}

export enum CardBrand {
  Visa = "visa",
  Mastercard = "mastercard",
  Amex = "amex",
}

export interface PaymentMethod {
  id: number;
  brand: CardBrand;
  last_digits: string;
  exp_month: number;
  exp_year: number;
  is_enabled: boolean;
  is_default: boolean;
  created_at: string;
  updated_at: string;
}

export enum OrderStatus {
  Incomplete = "incomplete",
  Successful = "successful",
}

export interface Order {
  id: number;
  status: OrderStatus;
  credit_amount: number;
  total_units: number;
  after_discount: number;
  discount_units: number;
  created_at: string;
  organization?: Organisation;
  user?: User;
  payment_method?: PaymentMethod;
}

export interface CreditUsage {
  organization_id: number;
  record_id: number;
  property_image: string;
  property_name: string;
  status: string;
  claimed_at: string;
}

export interface ClaimRecordResponse {
  contract_id: number;
  record_id: number;
}
