import { Box, CircularProgress } from "@material-ui/core";
import { AxiosError } from "axios";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import api from "../api/instance";
import { Property, User, UserType } from "../api/models";
import AlertWrapper from "../components/AlertWrapper";
import { useAlert } from "../contexts/Alert";
import { normalisedPlaceId } from "../googleplaces/queries";
import { isLoggedIn, useSessionStore } from "../stores/Session";

export default function PropertySearch(): JSX.Element {
  const location = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(location.search);
  const placeId = query.get("placeId");
  const label = query.get("label");

  const { data: userData } = useSessionStore();
  const user = userData && (userData as User);

  const isLawyer = user?.user_type === UserType.Solicitor;
  const isInternalUser = user?.is_internal_user;

  const { setErrorMessage } = useAlert();

  React.useEffect(() => {
    if (placeId) {
      // Get normalised place id first
      normalisedPlaceId(placeId)
        .then((placeId) => {
          // Fetch property using normalised place id
          api
            .get(`/view-properties/${placeId}?label=${label}`)
            .then((response) => {
              const property = response.data as Property;

              if (isLoggedIn()) {
                if (isLawyer && isInternalUser) {
                  history.push(
                    `/property-info?propertyId=${property.id}&role=lawyer`
                  );
                } else if (isLawyer && !isInternalUser) {
                  history.push(
                      `/property-select-role?propertyId=${property.id}&role=buyer`
                  );
                }
                else {
                  history.push(
                    `/property-info?propertyId=${property.id}&role=buyer`
                  );
                }
              } else {
                history.push(`/property-info?propertyId=${property.id}&role=buyer`);
              }
            })
            .catch((error: AxiosError) => {
              if (error.response && error.response.status === 500) {
                setErrorMessage(error.message);
              } else {
                if (isLoggedIn()) {
                  if (isLawyer && isInternalUser) {
                    history.push(
                      `/property-info?placeId=${placeId}&label=${label}&role=lawyer`
                    );
                  }else if (isLawyer && !isInternalUser) {
                    history.push(
                        `/property-select-role?label=${label}&role=buyer`
                    );
                  } 
                  else {
                    history.push(
                      `/property-info?placeId=${placeId}&label=${label}&role=buyer`
                    );
                  }
                } else {
                  history.push(
                    `/property-info?placeId=${placeId}&label=${label}&role=buyer`
                  );
                }
              }
            });
        })
        .catch((error) => {
          setErrorMessage(error);
        });
    }
  }, []);

  return (
    <AlertWrapper>
      <Box>
        <CircularProgress />
      </Box>
    </AlertWrapper>
  );
}
