import React from "react";
import { Box, ButtonBase, Typography } from "@material-ui/core";
import { Colors } from "../../constants/colors";

// const MyBox = withStyles({
//   root: {
//     "&:hover": {
//       backgroundColor: "#FFFFFF",
//     },  
//   },
// })(Box);


interface Props {
  sections: string[];
  selectedSection: string;
  onSelectedSection: (section: string) => unknown;
  sectionWidth?: number | string;
}

export default function ({
  sections,
  selectedSection,
  onSelectedSection,
  sectionWidth = "120px",
}: Props) {
  return (
    <Box display="flex" flexDirection="row" bgcolor={Colors.BrandBackground3}>
      {sections.map((section) => (
        <ButtonBase
          onClick={(event) => {
            event.stopPropagation();
            onSelectedSection(section);
          }}
        >
          <Box width={sectionWidth} height="48px">
          {selectedSection === section && (
              <Box height="2px" width="100%" bgcolor={Colors.BrandPrimary} />
            )}
           
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flex={1}
              height="46.67px"
              width="100%"
              bgcolor={selectedSection === section
                ? "white"
                : "#ECF2FB" 
              }
            >
              <Typography
                variant="body1"
                style={{ fontWeight: 700, color: Colors.BrandPrimary, }}
              >
                {section}
              </Typography>
            </Box>
              
            
          </Box>
        </ButtonBase>
      ))}
    </Box>
  );
}
