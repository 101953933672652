import { Box, CircularProgress, Typography } from "@material-ui/core";
import { AxiosError } from "axios";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import api from "../api/instance";
import {
  Contract,
  ContractWorkflowStatus,
  MyProperty,
  Property,
  User,
  UserType,
} from "../api/models";
import AlertWrapper from "../components/AlertWrapper";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import PropertyLayoutDesktop from "../components/PropertyLayoutDesktop";
import PropertyLayoutMobile from "../components/PropertyLayoutMobile";
import { Colors } from "../constants/colors";
import { useAlert } from "../contexts/Alert";
import { useDesktop, useMobile } from "../hooks/mobile";
import CheckCircle from "../images/check-circle.png";
import SearchIcon from "../images/search-vector.svg";
import { useSessionStore } from "../stores/Session";

export default function LegalReviewPurchased(): JSX.Element {
  const history = useHistory();
  const location = useLocation();
  const isMobile = useMobile();
  const isDesktop = useDesktop();

  const query = new URLSearchParams(location.search);
  const contractId = query.get("contractId");
  const myProperty = query.get("myProperty");
  const isMyProperty = myProperty === "true";

  const { data: contractData } = useSWR(`/view-contracts/${contractId}/`);
  const contract = contractData && (contractData as Contract);

  const { data: propertyData } = useSWR(
    contract && `/view-properties/${contract.property_id}/`
  );
  const property = propertyData && (propertyData as Property);
  const isSold = property ? property.status.toLowerCase() === "sold" : true;

  const { data: myPropertiesData } = useSWR("/my-properties/");
  const myProperties =
    myPropertiesData && (myPropertiesData as Array<MyProperty>);
  const myPropertiesProperty =
    myProperties &&
    contract &&
    myProperties.find(
      (property) =>
        property.property_id === contract.property_id && property.invoice
    );

  const { data: userData, refresh: refreshUserData } = useSessionStore();
  const user = userData && (userData as User);

  const { setErrorMessage, setSuccessMessage } = useAlert();

  React.useEffect(() => {
    refreshUserData();
  }, []);

  if (!contractId) {
    return <Box />;
  }

  if (!contract) {
    return <CircularProgress />;
  }

  function viewContractOfSale() {
    const back = `${location.pathname}${location.search}`;

    history.push(`/sale-contract?contractId=${contractId}`, {
      back,
    });
  }

  function viewInvoice() {
    myPropertiesProperty && myPropertiesProperty.invoice
      ? window.open(myPropertiesProperty.invoice)
      : setErrorMessage("Could not find the invoice for this property");
  }

  function viewLegalReview() {
    history.push(`/legal-review?contractId=${contractId}`);
  }

  function PropertyStatus() {
    return (
      <Box>
        {property && property.status !== "Sold" && (
          <React.Fragment>
            {contract &&
              contract.workflow_status === ContractWorkflowStatus.Reviewed &&
              property.status === "Published" && (
                <Box
                  display="flex"
                  flexDirection="row"
                  p={2}
                  bgcolor={Colors.BrandBackground5}
                >
                  <img src={CheckCircle} height="30px" />
                  <Typography
                    variant="body1"
                    style={{ marginLeft: "17px", fontWeight: 700 }}
                  >
                    Contrax legal review ready
                    <Typography variant="body1">
                      We’ve reviewed the contract of sale for this property.
                    </Typography>
                  </Typography>
                </Box>
              )}
            {contract &&
              contract.file &&
              property &&
              property.status === "Pending" && (
                <Box
                  display="flex"
                  flexDirection="row"
                  p={2}
                  bgcolor={Colors.BrandBackground2}
                >
                  <img src={SearchIcon} height="22px" />
                  <Typography
                    variant="body2"
                    style={{ marginLeft: "17px", fontWeight: 700 }}
                  >
                    Contrax legal review ready soon
                    <Typography variant="body2">
                      We have the contract and the legal review is underway.
                    </Typography>
                  </Typography>
                </Box>
              )}
            {contract &&
              !contract.file &&
              property &&
              property.status === "Pending" && (
                <Box
                  display="flex"
                  flexDirection="row"
                  p={2}
                  bgcolor={Colors.BrandBackground2}
                >
                  <img src={SearchIcon} height="22px" />
                  <Typography
                    variant="body2"
                    style={{ marginLeft: "17px", fontWeight: 700 }}
                  >
                    Contrax legal review pending
                    <Typography variant="body2">
                      We’ll begin the legal review as soon as we have the
                      contract.
                    </Typography>
                  </Typography>
                </Box>
              )}
          </React.Fragment>
        )}
      </Box>
    );
  }

  function LawyerContent() {
    return (
      <Box
        display="flex"
        flexDirection="column"
        mt={5.5}
        ml={isDesktop ? 0 : 2.5}
        mr={isDesktop ? 0 : 2.5}
        mb={5.5}
      >
        <Box mt={2} />
        <Typography variant="h5">Thank you for your purchase</Typography>
        <Box mt={3} />
        {isMyProperty && (
          <Box>
            <React.Fragment>
              {contract &&
                contract.file &&
                property &&
                property.status === "Published" && (
                  <Box>
                    <Typography variant="body1">
                      From here, you can view your Contrax legal review and
                      cross-reference it with the original contract of sale.{" "}
                      <Box mt={2}>You can also view your invoice.</Box>
                    </Typography>
                  </Box>
                )}
            </React.Fragment>
            <React.Fragment>
              {property &&
                property.status === "Pending" &&
                contract &&
                !contract.file && (
                  <Box>
                    <Typography variant="body1">
                      We’re preparing your Contrax legal review and will send it
                      to {user && user.email} when it's ready. when it's ready.
                      <Box mt={2}>
                        The legal review will also be available here in your
                        Contrax account where you can cross-reference it with
                        the original contract of sale.
                      </Box>
                    </Typography>
                  </Box>
                )}
            </React.Fragment>
            <React.Fragment>
              {property &&
                property.status === "Pending" &&
                contract &&
                contract.file && (
                  <Box>
                    <Typography variant="body1">
                      We’re getting the contract and preparing the Contrax legal
                      review. We'll send it to {user && user.email} when it's
                      ready.
                      <Box mt={2}>
                        The legal review and contract of sale will also be
                        available here in your Contrax account.
                      </Box>
                    </Typography>
                    <br />
                  </Box>
                )}
            </React.Fragment>
          </Box>
        )}

        <Box>
          {contract &&
            contract.file &&
            property &&
            property.status === "Published" && (
              <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
                <Typography variant="body1">
                  The legal review has been sent to
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginLeft: isMobile ? "0px" : "5px",
                    fontWeight: 700,
                  }}
                >
                  {user && user.email}
                </Typography>
              </Box>
            )}
        </Box>

        <Box mt={2} />
        <React.Fragment>
          {contract &&
            contract.file &&
            property &&
            property.status === "Published" && (
              <Button
                type={ButtonType.primary}
                size={ButtonSize.small}
                title="RESEND LEGAL REVIEW"
                width="196px"
                onClick={() => {
                  if (myPropertiesProperty && myPropertiesProperty.invoice) {
                    api
                      .post("/payment-confirmation/", {
                        receipt_url: myPropertiesProperty.invoice,
                      })
                      .then((response) => {
                        setSuccessMessage(
                          "We have re-sent the legal review to you"
                        );
                      })
                      .catch((error: AxiosError) => {
                        setErrorMessage(error.message);
                      });
                  } else {
                    setErrorMessage(
                      "Could not resend legal review: Invoice was not found"
                    );
                  }
                }}
              />
            )}
          <Box mt={2.5} />
        </React.Fragment>
        {!isDesktop && (
          <React.Fragment>
            <Button
              type={ButtonType.secondary}
              size={ButtonSize.medium}
              title="VIEW INVOICE"
              onClick={() => viewInvoice()}
            />
            <Box mt={2} />
            <Button
              type={ButtonType.secondary}
              size={ButtonSize.medium}
              title="VIEW LEGAL REVIEW"
              onClick={() => viewLegalReview()}
            />
            <Box mt={2} />

            <Button
              type={ButtonType.secondary}
              size={ButtonSize.medium}
              title="VIEW CONTRACT OF SALE"
              onClick={() => viewContractOfSale()}
            />
          </React.Fragment>
        )}
      </Box>
    );
  }

  function BuyerContent() {
    return (
      <Box
        display="flex"
        flexDirection="column"
        mt={5.5}
        ml={isDesktop ? 0 : 2.5}
        mr={isDesktop ? 0 : 2.5}
        mb={5.5}
      >
        <Box mt={2} />
        <Typography variant="h5">Thank you for your purchase</Typography>
        <Box mt={3} />
        {isMyProperty && (
          <Box>
            <React.Fragment>
              {contract &&
                contract.file &&
                property &&
                property.status === "Published" && (
                  <Box>
                    <Typography variant="body1">
                      From here, you can view your Contrax legal review and
                      cross-reference it with the original contract of sale.
                      <Box mt={2}>
                        You can also view your invoice, and if you want to take
                        the next step towards a purchase, click 'need a lawyer'
                        to move forward with this property.
                      </Box>
                    </Typography>
                    <br />
                  </Box>
                )}
            </React.Fragment>
            <React.Fragment>
              {property &&
                property.status === "Pending" &&
                contract &&
                !contract.file && (
                  <Box>
                    <Typography variant="body1">
                      We’re getting the contract and preparing the Contrax legal
                      review. We'll send it to {user && user.email} when it's
                      ready.
                      <Box mt={2}>
                        The legal review and contract of sale will also be
                        available here in your Contrax account.
                      </Box>
                    </Typography>
                    <br />
                  </Box>
                )}
            </React.Fragment>
            <React.Fragment>
              {property &&
                property.status === "Pending" &&
                contract &&
                contract.file && (
                  <Box>
                    <Typography variant="body1">
                      We’re preparing the Contrax legal review and will send it
                      to {user && user.email} when it's ready.
                      <Box mt={2}>
                        The legal review will also be available here in your
                        Contrax account where you can cross-reference it with
                        the original contract of sale.
                      </Box>
                    </Typography>
                    <br />
                  </Box>
                )}
            </React.Fragment>
          </Box>
        )}

        <Box>
          {contract &&
            contract.file &&
            property &&
            property.status === "Published" && (
              <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
                <Typography variant="body1">
                  The legal review has been sent to
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginLeft: isMobile ? "0px" : "5px",
                    fontWeight: 700,
                  }}
                >
                  {user && user.email}
                </Typography>
              </Box>
            )}
        </Box>

        <Box mt={2} />
        <React.Fragment>
          {contract &&
            contract.file &&
            property &&
            property.status === "Published" && (
              <Button
                type={ButtonType.primary}
                size={ButtonSize.small}
                title="RESEND LEGAL REVIEW"
                width="196px"
                onClick={() => {
                  if (myPropertiesProperty && myPropertiesProperty.invoice) {
                    api
                      .post("/payment-confirmation/", {
                        receipt_url: myPropertiesProperty.invoice,
                      })
                      .then((response) => {
                        setSuccessMessage(
                          "We have re-sent the legal review to you"
                        );
                      })
                      .catch((error: AxiosError) => {
                        setErrorMessage(error.message);
                      });
                  } else {
                    setErrorMessage(
                      "Could not resend legal review: Invoice was not found"
                    );
                  }
                }}
              />
            )}
          <Box mt={2.5} />
        </React.Fragment>
        {!isDesktop && (
          <React.Fragment>
            <Button
              type={ButtonType.secondary}
              size={ButtonSize.medium}
              title="VIEW INVOICE"
              onClick={() => viewInvoice()}
            />
            <Box mt={2} />
            <Button
              type={ButtonType.secondary}
              size={ButtonSize.medium}
              title="VIEW LEGAL REVIEW"
              onClick={() => viewLegalReview()}
            />
            <Box mt={2} />

            <Button
              type={ButtonType.secondary}
              size={ButtonSize.medium}
              title="VIEW CONTRACT OF SALE"
              onClick={() => viewContractOfSale()}
            />
          </React.Fragment>
        )}
      </Box>
    );
  }

  return isDesktop ? (
    <AlertWrapper>
      <PropertyLayoutDesktop
        propertyId={+contract.property_id}
        detailsChildren={
          <Box width="100%">
            <PropertyStatus />
            <Box mt={2} />
            <Button
              type={ButtonType.secondary}
              size={ButtonSize.medium}
              width="100%"
              title="VIEW INVOICE"
              onClick={() => viewInvoice()}
            />
            <Box mt={2} />
            <React.Fragment>
              {contract &&
                contract.workflow_status ===
                  ContractWorkflowStatus.Reviewed && (
                  <Button
                    type={ButtonType.primary}
                    size={ButtonSize.medium}
                    width="100%"
                    title="VIEW LEGAL REVIEW"
                    onClick={() => viewLegalReview()}
                  />
                )}
              <Box mt={2} />
              {contract && contract.file && (
                <Button
                  type={ButtonType.primary}
                  size={ButtonSize.medium}
                  width="100%"
                  title="VIEW CONTRACT OF SALE"
                  onClick={() => viewContractOfSale()}
                />
              )}
            </React.Fragment>
            {user && user.user_type !== UserType.Solicitor && (
              <React.Fragment>
                <Box mt={2} />
                {contract && (
                  <Button
                    type={ButtonType.primary}
                    size={ButtonSize.medium}
                    width="100%"
                    title="DO YOU NEED A LAWYER?"
                    onClick={() => {
                      history.push(
                        `/request-lawyer?propertyId=${contract.property_id}`
                      );
                    }}
                  />
                )}
              </React.Fragment>
            )}
          </Box>
        }
      >
        {user && user.user_type !== UserType.Solicitor ? (
          <BuyerContent />
        ) : (
          <LawyerContent />
        )}
      </PropertyLayoutDesktop>
    </AlertWrapper>
  ) : (
    <AlertWrapper>
      <PropertyLayoutMobile propertyId={+contract.property_id}>
        {user && user.user_type !== UserType.Solicitor ? (
          <BuyerContent />
        ) : (
          <LawyerContent />
        )}

        {user && user.user_type !== UserType.Solicitor && (
          <MobileStickyFooter
            buttonsLayout={FooterButtonsLayout.centered}
            buttonTitle="NEED A LAWYER"
            buttonOnClick={() => {
              history.push(
                `/request-lawyer?propertyId=${contract.property_id}`
              );
            }}
          />
        )}
      </PropertyLayoutMobile>
    </AlertWrapper>
  );
}
