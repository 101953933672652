import React from "react";
import { Box, Typography } from "@material-ui/core";
import { User, Comment } from "../../api/models";
import { initialsForUser, nameForUser } from "../../helpers/user";
import { Colors } from "../../constants/colors";
import InitialsView from "../InitialsView";
import moment from "moment";

interface Props {
  comment: Comment;
}

export default function ({ comment }: Props) {
  const { user } = comment;

  const initials = initialsForUser(user);
  const name = nameForUser(user);

  return (
    <Box>
      <Box display="flex" flexDirection="row" p="16px" pr="32px">
        <InitialsView initials={initials} />

        <Box display="flex" flexDirection="column" flex={1}>
          <Box display="flex" flexDirection="row">
            <Typography variant="body2" style={{ fontWeight: 700, flex: 1 }}>
              {name}
            </Typography>

            <Typography variant="caption" style={{ color: Colors.Grey3 }}>
              {moment(comment.created).format("MMM D [at] hh:mm A")}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" flex={1}>
            <Box
              borderRadius="16px"
              border="1px solid #F4F6F8"
              pt="8px"
              pl="14px"
              pr="14px"
              pb="8px"
              mt="13px"
            >
              <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                {comment.content}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
