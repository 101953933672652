import { Box, ButtonBase, Typography } from "@material-ui/core";
import { last, sortBy } from "lodash-es";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import api from "../../api/instance";
import {
  Contract,
  Property,
  User,
  UserType,
  ClaimRecordResponse,
} from "../../api/models";
import UseCreditsDialog from "../../components/PurchaseCredits/UseCreditsDialog";
import { Colors } from "../../constants/colors";
import { useAlert } from "../../contexts/Alert";
import { parseApiError } from "../../helpers/error";
import { useCreditStore } from "../../stores/credit";
import { usePurchaseStore } from "../../stores/purchase";
import { useSessionStore } from "../../stores/Session";

interface Props {
  propertyId?: number | null;
  contract?: Contract | null;
  placeId?: string | null;
  address?: string | null;
  width?: number | string | null;
  height?: number | string | null;
  role?: "lawyer" | "buyer";
  onSignUp: () => unknown;
}

export default function PurchaseLegalReviewButton({
  propertyId,
  contract,
  placeId,
  address,
  width,
  height,
  role,
  onSignUp,
}: Props): JSX.Element {
  const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const makePayment = query.get("makePayment") === "true";

  const { data: userData, refresh: refreshUserData } = useSessionStore();
  const user = userData ? (userData as User) : null;

  const { availableCredits, refreshCredits: refreshCredits } = useCreditStore();

  const { setErrorMessage, setSuccessMessage } = useAlert();
  const { setPurchaseJourneyStartPath, setPurchaseJourneySuccessPath } =
    usePurchaseStore();

  const [useCreditsOpen, setUseCreditsOpen] = React.useState(false);
  const [usingCredit, setUsingCredit] = React.useState(false);

  const { data: propertyData } = useSWR(
    propertyId ? `/view-properties/${propertyId}/` : null
  );

  const property = React.useMemo(() => {
    if (propertyData) {
      return propertyData as Property;
    }

    return null;
  }, [propertyData]);

  const contractVersionId = React.useMemo(() => {
    if (property) {
      const { contract_versions } = property;

      return last(
        sortBy(contract_versions, (version) => {
          return version.number;
        })
      )?.id;
    }

    return null;
  }, [property]);

  React.useEffect(() => {
    refreshCredits();
  }, [user]);

  React.useEffect(() => {
    if (makePayment) {
      refreshUserData();

      setUseCreditsOpen(true);
    }
  }, []);

  const showUseCredits = () => {
    if (availableCredits === 0) {
      setPurchaseJourneyStartPath(`${location.pathname}${location.search}`);
      setPurchaseJourneySuccessPath(
        `${location.pathname}${location.search}&makePayment=true`
      );

      history.push("/plans");
      return;
    }

    setUseCreditsOpen(true);
  };

  const onUseCredit = () => {
    setUsingCredit(true);

    api
      .post("/credits/claim_record/", {
        contract_version_id: contractVersionId,
      })
      .then((response) => {
        const data = response.data as ClaimRecordResponse;
        const { contract_id, record_id } = data;

        setUsingCredit(false);

        setSuccessMessage("Workspace purchased");

        history.push(
          `/legal-review-viewer?contractId=${contract_id}&recordId=${record_id}`
        );

        // history.push("/dashboard");
      })
      .catch((error) => {
        setUsingCredit(false);
        setErrorMessage(parseApiError(error));
      });
  };

  return (
    <>
      <ButtonBase
        onClick={() => {
          if (user) {
            if (user.user_type === UserType.Agent) {
              setErrorMessage(
                "We noticed you’re trying to buy a legal review from your agent account. You must create a personal account to purchase this review, to do this simply logout, search for a property and create a personal account"
              );
            } else if (propertyId && contract) {
              // history.push(`/payment-preview?propertyId=${propertyId}`);
              showUseCredits();
            } else if (propertyId && !contract) {
              history.push(
                `/search/add-searched-property?propertyId=${propertyId}`
              );
            } else if (placeId) {
              history.push(
                `/search/add-searched-property?placeId=${placeId}&label=${address}`
              );
            }
          } else {
            // TODO: Show create an account modal, use role to decide if Buyer or Buyer Lawyer

            if (propertyId && contract) {
              onSignUp();
            } else if (propertyId && !contract) {
              history.push(
                `/search/add-searched-property?propertyId=${propertyId}`
              );
            } else if (placeId) {
              history.push(
                `/search/add-searched-property?placeId=${placeId}&label=${address}`
              );
            }
          }
        }}
        style={{ width: width || "auto" }}
      >
        <Box
          bgcolor={Colors.Link}
          color="white"
          width="100%"
          height={height || "60px"}
          borderRadius="8px"
          marginTop={2}
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <Box>
            <Typography
              variant="body1"
              style={{
                fontWeight: 700,
                fontSize: "16px",
                letterSpacing: "2px",
                textTransform: "uppercase",
              }}
            >
              Get started
            </Typography>
            {/* <Box display="flex" flexDirection="row" justifyContent="center">
            <Typography
              variant="body1"
              style={{ marginRight: "2px", marginTop: "2px" }}
            >
              $
            </Typography>
            <Typography variant="h5" style={{ marginRight: "5px" }}>
              74
            </Typography>
          </Box> */}
          </Box>
        </Box>
      </ButtonBase>

      <UseCreditsDialog
        open={useCreditsOpen}
        onUseCredit={onUseCredit}
        onCancel={() => setUseCreditsOpen(false)}
        loading={usingCredit}
        paymentSuccessful={makePayment}
      />
    </>
  );
}
