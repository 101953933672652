import React from "react";
import ReactSelect, { Props as ReactSelectProps } from "react-select";
import { Box, Typography } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import {
  selectStyles,
  formatOptionLabel,
  DropdownIndicator,
} from "./SelectStyles";

interface Props extends ReactSelectProps {
  title?: string | null | undefined;
  error?: boolean;
  helperText?: string | null;
  zIndexFix?: boolean;
  isClearable?: boolean;
}

export default function Select({
  title,
  error,
  helperText,
  zIndexFix = false,
  isClearable,
  ...rest
}: Props): JSX.Element {
  const zIndexFixProps =
    zIndexFix === true
      ? {
          menuPortalTarget: document.body,
          styles: { menuPortal: (base) => ({ ...base, zIndex: 9999 }) },
        }
      : {};

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      {title && (
        <Typography
          variant="body2"
          style={{ fontWeight: 700, marginBottom: "4px", color: Colors.Grey1 }}
        >
          {title}
        </Typography>
      )}
      <ReactSelect
        styles={selectStyles(error) as any}
        formatOptionLabel={formatOptionLabel}
        components={{
          DropdownIndicator,
        }}
        isClearable={isClearable}
        {...zIndexFixProps}
        {...rest}
      />
      <Box height="14px">
        {helperText && (
          <Typography
            variant="caption"
            style={{ color: error ? Colors.Error : Colors.Grey2 }}
          >
            {helperText}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
