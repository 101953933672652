import React from "react";
import { Checkbox as MUICheckbox, CheckboxProps } from "@material-ui/core";
import RadioOn from "../../images/radio-on.png";
import RadioOff from "../../images/radio-off.png";

export default function Checkbox({ ...rest }: CheckboxProps): JSX.Element {
  return (
    <MUICheckbox
      checkedIcon={<img src={RadioOn} />}
      icon={<img src={RadioOff} />}
      {...rest}
    />
  );
}
