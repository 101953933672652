import React from "react";
import { Box, Typography, Link, ButtonBase } from "@material-ui/core";
import Header from "../components/Dashboard/Header";
import { Colors } from "../constants/colors";
import { useMobile } from "../hooks/mobile";
import { useSessionStore } from "../stores/Session";
import { useAlert } from "../contexts/Alert";
import Button, { ButtonType, ButtonSize } from "../components/common/Button";
import AlertWrapper from "../components/AlertWrapper";
import { useHistory } from "react-router-dom";
import HelpGuideModal from "../components/HelpGuide/HelpGuideModal";
import HelpGuideModalItem from "../components/HelpGuide/HelpGuideModalItem";
import ViewAccentColorModal from "../components/Personalisation/ViewAccentColorModal";
import ViewAccentColorModalItem from "../components/Personalisation/ViewAccentColorModalItem";
import BLHelp1 from "../images/uploadHelpGuide.png";
import BLHelp2 from "../images/inviteModalHelpGuide.png";
import BLHelp3 from "../images/colorHelpGuide.png";
import BuyerLawyerLandingPageColorModal from "../images/Buyer-lawyer-landing-page.png";
import PropertyWorkspaceModal from "../images/Property-workspace.png";
import api from "../api/instance";
import UploadImageBox, {
  SelectedImage,
} from "../components/common/UploadImageBox";
import TextField from "../components/common/TextField";
import { parseApiError } from "../helpers/error";
import { SketchPicker } from "react-color";
import Separator from "../components/common/Separator";
import PublishedModal from "../components/Personalisation/PublishedModal";
import { getPersonalisationUrl } from "../helpers/personalisation";
import { getPartnerPersonalisationUrl } from "../helpers/PartnerPersonalisation";
import copy from "copy-to-clipboard";
import ClickAwayListener from "react-click-away-listener";
import { isEmpty } from "lodash-es";
import { User, UserType } from "../api/models";

export default function MyPersonalisation() {
  const isMobile = useMobile();
  const history = useHistory();

  const sidePadding = isMobile ? "27px" : "172px";

  const { setErrorMessage, setSuccessMessage } = useAlert();

  const { data: userData, refresh: refreshUserData } = useSessionStore();
  const user = userData && (userData as User);

  const isPartner = user?.user_type === UserType.Partner;

  const _helpModalTitle = () => {
    return "How to use My Team";
  };

  const _helpModalItems = () => {
    return (
      <>
        <HelpGuideModalItem
          index={1}
          title="Search for a property, upload the contract and create your workspace"
          image={BLHelp1}
          imageMarginTop="32px"
        />
        <Box width="6px" />
        <HelpGuideModalItem
          index={2}
          title="Invite your team to Contrax"
          image={BLHelp2}
          imageMarginTop="32px"
        />
        <Box width="6px" />
        <HelpGuideModalItem
          index={3}
          title="Personalise your workspace and landing page with your logo and brand colour"
          image={BLHelp3}
          imageMarginTop="32px"
        />
      </>
    );
  };

  const helpModalTitle = _helpModalTitle();
  const helpModalItems = _helpModalItems();

  const _viewAccentColorModalTitle = () => {
    return "Where your accent colour and logo will appear";
  };

  const _viewAccentColorModalItems = () => {
    return (
      <>
        <ViewAccentColorModalItem
          title="Landing page"
          body="Personalise your account with your accent colour and logo. These will appear on the header of your landing page"
          image={BuyerLawyerLandingPageColorModal}
          imageMarginTop="32px"
        />
        <Box width="6px" />
        <ViewAccentColorModalItem
          title="Property workspace"
          body="Your accent colour and logo will appear to your customers in your Contrax workspace"
          image={PropertyWorkspaceModal}
          imageMarginTop="32px"
        />
      </>
    );
  };

  const viewAccentColorModalTitle = _viewAccentColorModalTitle();
  const viewAccentColorModalItems = _viewAccentColorModalItems();

  const [helpModalOpen, setHelpModalOpen] = React.useState(false);

  const [viewAccentColorModalOpen, setViewAccentColorModalOpen] =
    React.useState(false);

  // const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [uploadError, setUploadError] = React.useState(false);
  // const [loading, setLoading] = React.useState(false);

  const [color, setColor] = React.useState("");
  const [hidden, setHidden] = React.useState(false);

  const [publishedModalOpen, setPublishedModalOpen] = React.useState(false);

  const pickerStyles = {
    default: {
      picker: {
        width: "322px",
        // marginLeft: "42px",
        marginTop: "2px",
      },
    },
  };

  const pickerStylesMobile = {
    default: {
      picker: {
        width: "auto",
        marginTop: "2px",
      },
    },
  };

  const [companyTitle, setCompanyTitle] = React.useState("");

  const [companyName, setCompanyName] = React.useState("");
  const [contactNumber, setContactNumber] = React.useState("");
  const [emailAddress, setEmailAddress] = React.useState("");

  const [publicUrl, setPublicUrl] = React.useState("");

  const [publishing, setPublishing] = React.useState(false);

  const [selectedImage, setSelectedImage] =
    React.useState<SelectedImage | null>(null);

  const onCancel = () => {
    setSelectedImage(null);
  };

  // React.useEffect(() => {
  //   refreshUserData();
  // }, []);

  React.useEffect(() => {
    if (userData?.current_organization) {
      setCompanyTitle(userData.current_organization?.title);
      setCompanyName(userData.current_organization?.name);
      setContactNumber(userData.current_organization?.phone_no);
      setEmailAddress(userData.current_organization?.email);

      if (userData.current_organization?.color_code === null) {
        setColor("#FF0000");
      } else {
        setColor(userData.current_organization?.color_code);
      }

      if (userData.current_organization?.logo) {
        setSelectedImage({
          url: userData.current_organization?.logo,
        });
      } else {
        setSelectedImage(null);
      }

      setPublicUrl(userData.current_organization?.uuid);
    }
  }, [userData]);

  function FooterText() {
    return (
      <Box>
        <Typography variant="body2">
          If you are having trouble uploading the contract{" "}
          <Link
            style={{ color: Colors.Link, cursor: "pointer" }}
            onClick={() => {
              history.push("/agent/help");
            }}
          >
            get in touch with us
          </Link>
        </Typography>
      </Box>
    );
  }

  function ColorFooterText() {
    return (
      <Box>
        <Typography variant="body2">
          <Link
            style={{ color: Colors.Link, cursor: "pointer" }}
            onClick={() => setViewAccentColorModalOpen(true)}
          >
            View where your accent colour will appear
          </Link>
        </Typography>
      </Box>
    );
  }

  const onPublish = () => {
    if (isEmpty(companyName)) {
      setErrorMessage("Organisation name is required");
      return;
    }

    if (isEmpty(emailAddress)) {
      setErrorMessage("Email address is required");
      return;
    }
    setUploadError(false);

    if (!selectedImage && isPartner) {
      setUploadError(true);
      return;
    }

    setPublishing(true);

    const form = new FormData();
    selectedImage &&
      selectedImage.file &&
      form.append("logo", selectedImage.file as Blob);
    !selectedImage && form.append("logo", "");

    form.append("title", companyTitle);
    !companyTitle && form.append("title", "");

    form.append("name", companyName);

    form.append("email", emailAddress);

    form.append("phone_no", contactNumber);
    !contactNumber && form.append("phone_no", "");

    form.append("color_code", color);
    form.append("public_url", publicUrl);

    api
      .patch(`/personalization/`, form)
      .then((response) => {
        setPublishing(false);

        refreshUserData();
        // setSuccessMessage("Updated your personalisation");

        setPublishedModalOpen(true);
      })
      .catch((error) => {
        setPublishing(false);

        if (error.response.data.non_field_errors) {
          setErrorMessage(error.response.data.non_field_errors);
        } else {
          setErrorMessage(parseApiError(error));
        }
      });
  };

  function TitleBar() {
    if (isMobile) {
      return (
        <Box
          display="flex"
          flexDirection="row"
          bgcolor={Colors.BrandBackground2}
          pl={sidePadding}
          pr={sidePadding}
          justifyContent="center"
          alignItems="center"
        >
          <Box flex={1}>
            <Box
              flex={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              minHeight="86px"
            >
              <Typography
                variant="h4"
                style={{ color: Colors.Grey1, fontWeight: 400 }}
              >
                Personalisation
              </Typography>
            </Box>

            <Box>
              {!isPartner && (
                <Box mb="8px">
                  <Button
                    width="100%"
                    type={ButtonType.primary}
                    size={ButtonSize.medium}
                    title="PUBLISH"
                    onClick={onPublish}
                    loading={publishing}
                  />
                </Box>
              )}
              <Button
                width="100%"
                type={ButtonType.white}
                size={ButtonSize.medium}
                title="HELP GUIDE"
                onClick={() => setHelpModalOpen(true)}
              />
            </Box>

            <Box height="20px" />
          </Box>
        </Box>
      );
    }

    return (
      <Box
        display="flex"
        flexDirection="row"
        bgcolor={Colors.BrandBackground2}
        pl={sidePadding}
        pr={sidePadding}
        justifyContent="center"
        alignItems="center"
      >
        <Box flex={1}>
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            minHeight="108px"
          >
            <Typography
              variant="h4"
              style={{ color: Colors.Grey1, fontWeight: 400 }}
            >
              Personalisation
            </Typography>
          </Box>
        </Box>
        {!isPartner && (
          <Box mr="8px">
            <Button
              type={ButtonType.white}
              size={ButtonSize.medium}
              title="HELP GUIDE"
              onClick={() => setHelpModalOpen(true)}
            />
          </Box>
        )}
        <Box>
          <Button
            type={ButtonType.primary}
            size={ButtonSize.medium}
            title="PUBLISH"
            loading={publishing}
            onClick={onPublish}
            width="132px"
          />
        </Box>
      </Box>
    );
  }

  const landingPageLink =
    userData?.current_organization && !isPartner
      ? getPersonalisationUrl(userData.current_organization)
      : getPartnerPersonalisationUrl(userData?.current_organization);

  const staticURl = isPartner
    ? `${window.location.origin}/app/partner/`
    : `${window.location.origin}/app/org/`;

  function LandingPageLink() {
    return (
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        width="100%"
        mt="8px"
      >
        <Box
          bgcolor="#F4F6F8"
          border="1px solid #E8E8E8"
          borderRadius="4px"
          minHeight="34px"
          paddingLeft="8px"
          paddingRight="8px"
          alignItems="center"
          display="flex"
          marginRight="8px"
          paddingTop="6px"
          paddingBottom="6px"
        >
          <Typography variant="body1" style={{ wordBreak: "break-word" }}>
            {landingPageLink}
          </Typography>
        </Box>

        <Button
          title="COPY LINK"
          size={ButtonSize.medium}
          type={ButtonType.primary}
          onClick={() => {
            copy(landingPageLink);
            setSuccessMessage("Copied Link");
          }}
          width="140px"
        />
      </Box>
    );
  }

  return (
    <>
      <AlertWrapper>
        <Box>
          <Header />
          <TitleBar />

          {isMobile && (
            <Box
              mt="12px"
              ml="11px"
              mr="11px"
              display="flex"
              flexDirection="column"
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                // alignItems="center"
                mb="8px"
              >
                <Box
                  width="100%"
                  maxHeight="calc(100vh - 72px)"
                  height="100%"
                  // overflow="auto"
                  className="scrollbar-hidden"
                  pl={1}
                  pr={1}
                  boxSizing="border-box"
                >
                  <Box
                    mt={3}
                    mb={4}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    alignItems="left"
                  >
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Add your logo and title
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ marginBottom: "16px" }}
                    >
                      For best results try to upload logos without space around
                      the edges
                    </Typography>
                    <Box>
                      <UploadImageBox
                        onSelectFileError={() => setUploadError(true)}
                        onSelectedImage={(image) => {
                          setUploadError(false);
                          setSelectedImage(image);
                        }}
                        selectedImage={selectedImage}
                        error={uploadError}
                      />
                    </Box>

                    <Box mt={2} mb={3}>
                      <FooterText />
                    </Box>
                    {!isPartner && (
                      <Box mb={4}>
                        <TextField
                          title="Organisation title"
                          value={companyTitle}
                          onChange={(text) => setCompanyTitle(text)}
                        />
                        <Typography variant="caption">
                          This is the name of your company, this will appear in
                          your URL and place of your logo if you don’t upload
                          one.
                        </Typography>
                      </Box>
                    )}
                    <Separator />

                    {!isPartner && (
                      <Box
                        mb={4}
                        mt={4}
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        alignItems="left"
                      >
                        <Typography
                          variant="h6"
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Select your accent colour
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ marginBottom: "16px" }}
                        >
                          This colour will appear as an accent line on your
                          landing page and workspace
                        </Typography>
                        <ButtonBase
                          onClick={() => setHidden(!hidden)}
                          style={{
                            justifyContent: "left",

                            height: "60px",
                            border: "1px solid #ECF2FB",
                            borderRadius: "8px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            width="85%"
                            height="100%"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Typography
                              style={{
                                fontWeight: 700,
                                lineHeight: "18px",
                                fontSize: "16px",
                                letterSpacing: "2px",
                                color: "#0038E4",
                              }}
                            >
                              SELECT ACCENT COLOUR
                            </Typography>
                          </Box>

                          <ButtonBase
                            style={{
                              background: color,
                              height: "20px",
                              width: "20px",
                              borderRadius: "4px",
                              border: "1px solid #313131",
                              justifyContent: "left",
                            }}
                            value={color}
                          ></ButtonBase>

                          {/* </Box> */}
                        </ButtonBase>
                        {hidden && (
                          <ClickAwayListener
                            onClickAway={() => setHidden(false)}
                          >
                            <Box>
                              <SketchPicker
                                styles={pickerStylesMobile}
                                color={color}
                                disableAlpha={true}
                                presetColors={[
                                  "#333333",
                                  "#666666",
                                  "#999999",
                                  "#D0021B",
                                  "#F5A623",
                                  "#F8E71C",
                                  "#8B572A",
                                  "#7ED321",
                                  "#417505",
                                  "#BD10E0",
                                  "#9013FE",
                                  "#4A90E2",
                                  "#50E3C2",
                                  "#B8E986",
                                  "#000000",
                                  "#4A4A4A",
                                  "#9B9B9B",
                                  "#FFFFFF",
                                ]}
                                onChange={(updatedColor) =>
                                  setColor(updatedColor.hex)
                                }
                              />
                            </Box>
                          </ClickAwayListener>
                        )}

                        <Box mt={1} mb={4}>
                          <ColorFooterText />
                        </Box>
                        <Separator />
                      </Box>
                    )}
                    <Box mb={4} mt={4}>
                      <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        Add your organisation details
                      </Typography>
                    </Box>
                    <Box mb={3}>
                      <TextField
                        title="Organisation name"
                        value={companyName}
                        onChange={(text) => setCompanyName(text)}
                      />
                      <TextField
                        title="Contact number"
                        value={contactNumber}
                        onChange={(text) => setContactNumber(text)}
                      />
                      <TextField
                        title="Email address"
                        value={emailAddress}
                        onChange={(text) => setEmailAddress(text)}
                      />
                    </Box>
                    <Separator />
                    <Box mt={3}>
                      <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        Your landing page link
                      </Typography>
                    </Box>

                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      width="100%"
                      mt="8px"
                      mb="8px"
                    >
                      <Box minHeight="34px" alignItems="center" display="flex">
                        <Typography
                          variant="body1"
                          style={{ wordBreak: "break-word" }}
                        >
                          {staticURl}
                        </Typography>
                      </Box>
                      <Box height="55px">
                        <TextField
                          value={publicUrl}
                          onChange={(text) => setPublicUrl(text)}
                        />
                      </Box>
                    </Box>
                    <Box mb={1}>
                      <Typography variant="caption">
                        This is the name of your company URL, this will appear
                        in your personal URL landing page.
                      </Typography>
                    </Box>

                    <Box mb={4}>
                      <LandingPageLink />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            {!isMobile && (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                // alignItems="center"
                width="1095px"
                mb="8px"
              >
                <Box
                  maxWidth="400px"
                  width="100%"
                  maxHeight="calc(100vh - 72px)"
                  height="100%"
                  // overflow="auto"
                  className="scrollbar-hidden"
                  pl={1}
                  pr={1}
                  boxSizing="border-box"
                >
                  <Box
                    mt={3}
                    mb={3}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    alignItems="left"
                  >
                    <Typography
                      variant="h6"
                      style={{
                        marginBottom: "4px",
                        fontWeight: "bold",
                      }}
                    >
                      Add your logo and title
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ marginBottom: "16px" }}
                    >
                      For best results try to upload logos without space around
                      the edges
                    </Typography>
                    <Box>
                      <UploadImageBox
                        onSelectFileError={() => setUploadError(true)}
                        onSelectedImage={(image) => {
                          setUploadError(false);
                          setSelectedImage(image);
                        }}
                        selectedImage={selectedImage}
                        error={uploadError}
                      />
                    </Box>

                    <Box mt={2} mb={3}>
                      <FooterText />
                    </Box>
                    {!isPartner && (
                      <Box mb={3}>
                        <TextField
                          title="Organisation title"
                          value={companyTitle}
                          onChange={(text) => setCompanyTitle(text)}
                        />
                        <Typography variant="caption">
                          This is the name of your company, this will appear in
                          your URL and place of your logo if you don’t upload
                          one.
                        </Typography>
                      </Box>
                    )}
                    <Box>
                      <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        Your landing page link
                      </Typography>
                    </Box>

                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      width="100%"
                      mt="8px"
                      mb="8px"
                    >
                      <Box minHeight="34px" alignItems="center" display="flex">
                        <Typography
                          variant="body1"
                          style={{ wordBreak: "break-word" }}
                        >
                          {staticURl}
                        </Typography>
                      </Box>
                      <Box width="120px" height="58px">
                        <TextField
                          value={publicUrl}
                          onChange={(text) => setPublicUrl(text)}
                        />
                      </Box>
                    </Box>
                    <Box mb={1}>
                      <Typography variant="caption">
                        This is the name of your company URL, this will appear
                        in your personal URL landing page.
                      </Typography>
                    </Box>

                    <LandingPageLink />
                  </Box>
                </Box>

                <Box width="695px" ml="19px">
                  <Box width="100%" pl="24px" pr="24px">
                    <>
                      {!isPartner && (
                        <Box
                          mt={3}
                          mb={3}
                          display="flex"
                          flexDirection="column"
                          justifyContent="space-between"
                          alignItems="left"
                        >
                          <Typography
                            variant="h6"
                            style={{
                              marginBottom: "4px",
                              fontWeight: "bold",
                            }}
                          >
                            Select your accent colour
                          </Typography>
                          <Typography
                            variant="body1"
                            style={{ marginBottom: "16px" }}
                          >
                            This colour will appear as an accent line on your
                            landing page and workspace
                          </Typography>

                          <ButtonBase
                            onClick={() => setHidden(!hidden)}
                            style={{
                              justifyContent: "left",
                              width: "343px",
                              height: "60px",
                              border: "1px solid #ECF2FB",
                              borderRadius: "8px",
                              display: "flex",
                              flexDirection: "row",
                              // zIndex: 1,
                            }}
                          >
                            <Box
                              display="flex"
                              flexDirection="row"
                              width="85%"
                              height="100%"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Typography
                                style={{
                                  fontWeight: 700,
                                  lineHeight: "18px",
                                  fontSize: "16px",
                                  letterSpacing: "2px",
                                  color: "#0038E4",
                                }}
                              >
                                {hidden
                                  ? "CONFIRM ACCENT COLOUR"
                                  : "SELECT ACCENT COLOUR"}
                              </Typography>
                            </Box>

                            <ButtonBase
                              style={{
                                background: color,
                                height: "20px",
                                width: "20px",
                                borderRadius: "4px",
                                border: "1px solid #313131",
                                justifyContent: "left",
                              }}
                              value={color}
                            ></ButtonBase>
                          </ButtonBase>
                          {hidden && (
                            <ClickAwayListener
                              onClickAway={() => setHidden(false)}
                            >
                              <Box
                                zIndex="1"
                                position="absolute"
                                marginTop={17}
                              >
                                <SketchPicker
                                  styles={pickerStyles}
                                  color={color}
                                  disableAlpha={true}
                                  presetColors={[
                                    "#333333",
                                    "#666666",
                                    "#999999",
                                    "#D0021B",
                                    "#F5A623",
                                    "#F8E71C",
                                    "#8B572A",
                                    "#7ED321",
                                    "#417505",
                                    "#BD10E0",
                                    "#9013FE",
                                    "#4A90E2",
                                    "#50E3C2",
                                    "#B8E986",
                                    "#000000",
                                    "#4A4A4A",
                                    "#9B9B9B",
                                    "#FFFFFF",
                                  ]}
                                  onChange={(updatedColor) =>
                                    setColor(updatedColor.hex)
                                  }
                                />
                              </Box>
                            </ClickAwayListener>
                          )}

                          <Box mt={2} mb={2}>
                            <ColorFooterText />
                          </Box>
                        </Box>
                      )}

                      <Box mt={3} mb={3}>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          Add your organisation details
                        </Typography>
                      </Box>
                      <Box>
                        <TextField
                          title="Organisation name"
                          value={companyName}
                          onChange={(text) => setCompanyName(text)}
                        />
                        <TextField
                          title="Contact number"
                          value={contactNumber}
                          onChange={(text) => setContactNumber(text)}
                        />
                        <TextField
                          title="Email address"
                          value={emailAddress}
                          onChange={(text) => setEmailAddress(text)}
                        />
                      </Box>
                    </>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>

        <HelpGuideModal
          open={helpModalOpen}
          title={helpModalTitle}
          onClose={() => setHelpModalOpen(false)}
          items={helpModalItems}
        />

        <ViewAccentColorModal
          open={viewAccentColorModalOpen}
          title={viewAccentColorModalTitle}
          onClose={() => setViewAccentColorModalOpen(false)}
          items={viewAccentColorModalItems}
        />

        <PublishedModal
          open={publishedModalOpen}
          onClose={() => setPublishedModalOpen(false)}
        />
      </AlertWrapper>
    </>
  );
}
