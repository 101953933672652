import React, { MutableRefObject } from "react";
import { Box, Typography } from "@material-ui/core";
import Header from "../components/PurchaseCredits/Header";
import CreditPackItem from "../components/PurchaseCredits/CreditPackItem";
import Button, { ButtonType, ButtonSize } from "../components/common/Button";
import DesktopFooter from "../components/common/DesktopFooter";
import { useMobile } from "../hooks/mobile";
import SectionControl from "../components/common/SectionControlUnderlined";
import scrollIntoView from "scroll-into-view";
import { get, isEmpty, isNil } from "lodash-es";
import "../styles/scrollbar-hidden.css";
import { useHistory } from "react-router-dom";
import { usePurchaseStore } from "../stores/purchase";
import useSWR from "swr";
import { CreditPackage, UserType } from "../api/models";
import AlertWrapper from "../components/AlertWrapper";
import { useSessionStore } from "../stores/Session";

export default function CreditPacks() {
  const isMobile = useMobile();
  const history = useHistory();
  const { data: user } = useSessionStore();

  const isLawyer = user?.user_type === UserType.Solicitor;

  const hasOrg = !isNil(user?.current_organization);

  const [section, setSection] = React.useState("");

  const packsRef = React.useRef<Array<HTMLDivElement | null>>([]);

  const { setCredits, setAmountCents, setCreditPackId } = usePurchaseStore();

  const { data: creditPacksData } = useSWR("/credit-packages/");

  const creditPacks = creditPacksData
    ? (creditPacksData as CreditPackage[])
    : [];

  const sections = creditPacks.map((pack) => {
    return `${pack.credit_amount} credits`;
  });

  React.useEffect(() => {
    if (!isEmpty(creditPacks)) {
      setSection(sections[0]);
    }
  }, [creditPacks]);

  console.debug("packsRef: ", packsRef);

  const subtitle = React.useMemo(() => {
    return hasOrg
      ? "Pick a plan that works for your business"
      : "Pick a plan that works for you";
  }, [hasOrg]);

  return (
    <AlertWrapper>
      <Box minHeight="100vh" display="flex" flexDirection="column">
        <Header onClose={() => history.goBack()} />

        <Box
          display="flex"
          flexDirection={"column"}
          alignItems="center"
          flex={1}
          pl="16px"
          pr="16px"
          boxSizing="border-box"
        >
          <Box mt={6} mb={2} textAlign="center">
            <Typography variant={isMobile ? "h5" : "h4"}>
              Plan options
            </Typography>
            <Typography
              variant={isMobile ? "body2" : "body1"}
              style={{ marginTop: "8px" }}
            >
              {subtitle}
            </Typography>
          </Box>

          <Box>
            {isMobile && (
              <Box display="flex" flexDirection={"column"} alignItems="center">
                <SectionControl
                  sections={sections}
                  selectedSection={section}
                  onSelectedSection={(section, index) => {
                    packsRef.current[index] &&
                      scrollIntoView(packsRef.current[index] as any);
                  }}
                />
              </Box>
            )}

            <Box
              className="scrollbar-hidden"
              display="flex"
              flexDirection={"row"}
              overflow={isMobile ? "scroll" : undefined}
              maxWidth={isMobile ? "100vw" : undefined}
              onScroll={(event) => {
                const scrollLeft = get(event, "target.scrollLeft");
                const scrollWidth = get(event, "target.scrollWidth");

                if (scrollLeft >= 500) {
                  setSection(sections[2]);
                } else if (scrollLeft >= 200) {
                  setSection(sections[1]);
                } else {
                  setSection(sections[0]);
                }
              }}
              p="16px"
              boxSizing="border-box"
              justifyContent={!isMobile ? "center" : undefined}
            >
              {creditPacks.map((pack, index) => (
                <div ref={(el) => (packsRef.current[index] = el)}>
                  <CreditPackItem
                    pack={pack}
                    mr={index < creditPacks.length - 1 ? "24px" : undefined}
                    onPurchase={() => {
                      setCredits(pack.credit_amount);
                      setAmountCents(pack.total_units);
                      setCreditPackId(pack.id);

                      history.push("/purchase-credits");
                    }}
                  />
                </div>
              ))}
            </Box>
            <Box mb={7}></Box>

            {!isLawyer && (
              <>
                <Box
                  mt={4}
                  mb={7}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  pl="16px"
                  pr="16px"
                  flex={1}
                >
                  <Box
                    border="1px solid #E8E8E8"
                    borderRadius="56px"
                    width="100%"
                    height="0px"
                  />

                  <Box ml="11px" mr="11px">
                    <Typography variant="overline">OR</Typography>
                  </Box>

                  <Box
                    border="1px solid #E8E8E8"
                    borderRadius="56px"
                    width="100%"
                    height="0px"
                  />
                </Box>

                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  pl="16px"
                  pr="16px"
                >
                  <Typography variant={isMobile ? "h5" : "h4"}>
                    Buy one contract for $85
                  </Typography>

                  <Box
                    mt="40px"
                    mb="63px"
                    width={isMobile ? "100%" : undefined}
                  >
                    <Button
                      title="BUY 1 CONTRACT"
                      type={ButtonType.primary}
                      size={ButtonSize.large}
                      width={isMobile ? "100%" : "301px"}
                      onClick={() => {
                        setCredits(1);
                        setAmountCents(8500);
                        setCreditPackId(null);

                        history.push("/purchase-credits");
                      }}
                    />
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>

        <DesktopFooter />
      </Box>
    </AlertWrapper>
  );
}
