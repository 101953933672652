import { isNil, isEmpty } from "lodash-es";
import React from "react";
import LoginModal from "../components/Invited/LoginModal";
import SignUpModal from "../components/Invited/SignUpModal";
import { useSessionStore, isLoggedIn } from "../stores/Session";
import { useLocation } from "react-router-dom";

interface Props extends React.PropsWithChildren<{}> {
  onLoggedIn: () => unknown;
  onSignedUp: () => unknown;
}

export default function ({ children, onLoggedIn, onSignedUp }: Props) {
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const _contractId = query.get("contractId");
  const contractId = !isNil(_contractId) ? parseInt(_contractId) : null;
  const _recordId = query.get("recordId");
  const recordId = !isNil(_recordId) ? parseInt(_recordId) : null;

  const invitedVerificationToken = query.get("token");
  const inviterFirstName = query.get("first_name") || "";
  const inviterLastName = query.get("last_name") || "";

  const [loginModalOpen, setLoginModalOpen] = React.useState(false);
  const [signUpModalOpen, setSignUpModalOpen] = React.useState(false);

  const {
    data: user,
    refresh: refreshUser,
    refreshTimestamp,
  } = useSessionStore();

  React.useEffect(() => {
    if (!isLoggedIn() && !isEmpty(invitedVerificationToken)) {
      setSignUpModalOpen(true);
    } else {
      if (!isLoggedIn()) {
        setLoginModalOpen(true);
      }
    }
  }, []);

  return (
    <>
      {children}

      <LoginModal
        open={loginModalOpen}
        contractId={contractId}
        recordId={recordId}
        onLoggedIn={() => {
          setLoginModalOpen(false);

          refreshUser(false);

          onLoggedIn();
        }}
        inviterName={`${inviterFirstName} ${inviterLastName}`.trim()}
      />

      <SignUpModal
        open={signUpModalOpen}
        onClose={() => setSignUpModalOpen(false)}
        contractId={contractId}
        inviterName={`${inviterFirstName} ${inviterLastName}`.trim()}
        token={invitedVerificationToken}
        onSignedUp={() => {
          setSignUpModalOpen(false);

          refreshUser(false);

          onSignedUp();
        }}
        onLogin={() => {
          setSignUpModalOpen(false);
          setLoginModalOpen(true);
        }}
      />
    </>
  );
}
