import React from "react";
import { Box, Typography, Link, Divider } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import UploadFileBox from "../components/common/UploadFileBox";
import { useMobile } from "../hooks/mobile";
import AlertWrapper from "../components/AlertWrapper";
import HeroLayout from "../components/HeroLayout";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import { Colors } from "../constants/colors";
import Button, { ButtonType, ButtonSize } from "../components/common/Button";
import api from "../api/instance";
import {
  Contract,
  Property,
  ContractPublishStatus,
  addressForProperty,
  User,
} from "../api/models";
import { useAlert } from "../contexts/Alert";
import { AxiosError } from "axios";
import useSWR from "swr";
import { useSessionStore } from "../stores/Session";

export default function SearchedUploadSaleContract(): JSX.Element {
  const location = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(location.search);
  const propertyId = query.get("propertyId");

  const { data: propertyData } = useSWR(`/view-properties/${propertyId}/`);
  const property = propertyData && (propertyData as Property);

  const { data: contractData } = useSWR(
    property && `/view-contracts/${property.contract_id}/`
  );
  const contract = contractData && (contractData as Contract);

  const { data: userData, refresh: refreshUserData } = useSessionStore();
  const user = userData && (userData as User);

  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [uploadError, setUploadError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const isMobile = useMobile();
  const { setErrorMessage } = useAlert();

  const allowContractUpload =
    !contract ||
    (contract &&
      (contract.publish_status === ContractPublishStatus.Delete ||
        contract.publish_status === ContractPublishStatus.Sold)) ||
    (contract && !contract.file);

  function Header() {
    return (
      <Box
        width={isMobile ? "300px" : "700px"}
        mt={isMobile ? 4 : 10}
        mb={isMobile ? 3 : 7}
      >
        <Button
          type={ButtonType.white}
          size={ButtonSize.small}
          title="Back to property"
          sidePadding={1}
          onClick={() =>
            history.push(`/property-info?propertyId=${propertyId}`)
          }
        />
        <Typography
          variant={isMobile ? "h5" : "h4"}
          style={{ marginTop: "8px", fontWeight: 400 }}
        >
          {property && addressForProperty(property)}
        </Typography>
        <Box mb={3} />
        <Typography
          variant={isMobile ? "h5" : "h4"}
          style={{ marginTop: "8px" }}
        >
          Upload the contract of sale
        </Typography>
        <Typography variant="body1" style={{ marginTop: "10px" }}>
          {allowContractUpload
            ? "Click or drag a file to upload the contract of sale"
            : "You have uploaded Contract of sale for this property"}
        </Typography>
      </Box>
    );
  }

  function FooterText() {
    return (
      <Box>
        <Typography variant="body1">
          If you are having trouble uploading the contract{" "}
          <Link
            style={{ color: Colors.Link, fontWeight: 700, cursor: "pointer" }}
            onClick={() => {
              history.push("/agent/help");
            }}
          >
            get in touch with us
          </Link>
        </Typography>
      </Box>
    );
  }

  function uploadClicked() {
    if (loading) {
      return;
    }

    if (!allowContractUpload) {
      // Already uploaded, just proceed
      if (user) {
        history.push(`/payment-preview?propertyId=${propertyId}&cross=false`);
      } else {
        history.push(`/signup?propertyId=${propertyId}`);
      }
      return;
    }

    setUploadError(false);

    if (!selectedFile) {
      setUploadError(true);
      return;
    }

    setLoading(true);

    const form = new FormData();
    form.append("file", selectedFile as Blob);
    propertyId && form.append("property_id", propertyId);

    api
      .post("/public-contracts/?public=true", form)
      .then((response) => {
        if (user) {
          history.push(`/payment-preview?propertyId=${propertyId}`);
        } else {
          history.push(`/signup?propertyId=${propertyId}`);
        }
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        setErrorMessage(error.message);
      });
  }

  return (
    <AlertWrapper>
      <HeroLayout>
        <Box ml={3.25} mr={3.25}>
          <Header />
          {allowContractUpload && (
            <UploadFileBox
              onSelectFileError={() => setUploadError(true)}
              selectedFile={selectedFile}
              onSelectedFile={(file) => {
                setUploadError(false);
                setSelectedFile(file);
              }}
              error={uploadError}
            />
          )}
          {!allowContractUpload && (
            <Button
              type={ButtonType.secondary}
              size={ButtonSize.medium}
              title={"VIEW CONTRACT OF SALE"}
              onClick={() => {
                const back = `${location.pathname}${location.search}`;

                history.push(`/sale-contract?contractId=${contract.id}`, {
                  back,
                });
              }}
            />
          )}

          {isMobile && (
            <Box mt={2} textAlign="center">
              <FooterText />
            </Box>
          )}
          {!isMobile && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              mt={6}
            >
              <Box maxWidth="323px">
                <FooterText />
              </Box>
              <Button
                type={ButtonType.primary}
                size={ButtonSize.large}
                title={
                  !allowContractUpload
                    ? "NEXT"
                    : selectedFile
                    ? "SUBMIT"
                    : "UPLOAD"
                }
                width={"137px"}
                loading={loading}
                onClick={() => uploadClicked()}
              />
            </Box>
          )}
        </Box>
        <Box mt={3} />
        <Divider />
        <Box mt={2} />
        <Button
          type={ButtonType.white}
          size={ButtonSize.medium}
          sidePadding={1}
          title="OR Request the contract of sale from the agent"
          onClick={() =>
            history.push(`/add/agent/details?propertyId=${propertyId}`)
          }
        />
        <Box mt={2} />
      </HeroLayout>
      {isMobile && (
        <MobileStickyFooter
          buttonsLayout={FooterButtonsLayout.centered}
          buttonTitle={selectedFile ? "SUBMIT" : "UPLOAD"}
          buttonLoading={loading}
          buttonOnClick={() => uploadClicked()}
        />
      )}
    </AlertWrapper>
  );
}
