import React from "react";
import { Link, Typography } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { find, isNil } from "lodash-es";

interface Props {
  title: string;
  path: string;
  altPaths?: string[];
}

export default function SidebarLink({ title, path, altPaths }: Props) {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;

  const isAltPath = !isNil(
    find(altPaths, (a) => {
      return pathname.startsWith(a);
    })
  );

  return (
    <Link
      style={{
        cursor: "pointer",
        marginTop: "12px",
        marginBottom: "22px",
      }}
      onClick={() => history.push(path)}
    >
      <Typography
        style={{
          fontSize: "18px",
          lineHeight: "22px",
          color: pathname.startsWith(path) || isAltPath ? "#0038E4" : "#111111",
        }}
      >
        {title}
      </Typography>
    </Link>
  );
}
