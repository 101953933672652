import React from "react";
import { Box, Typography, Link } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import { CreditUsage } from "../../api/models";
import moment from "moment";
import Placeholder from "../../images/dashboard-property-placeholder.svg";

interface Props {
  usage: CreditUsage;
}

export default function InvoiceItem({ usage }: Props) {
  const image = usage.property_image || Placeholder;

  return (
    <Box
      bgcolor={Colors.Grey5}
      borderRadius="4px"
      p="12px"
      boxSizing="border-box"
      mt="4px"
      mb="4px"
      display="flex"
      flexDirection={"row"}
    >
      <img
        src={image}
        width="117px"
        height="88px"
        style={{ objectFit: "cover", borderRadius: "4px", marginRight: "16px" }}
      />

      <Box display="flex" flexDirection={"column"}>
        <Typography variant="body2" style={{ fontWeight: 700 }}>
          {usage.property_name}
        </Typography>
        <Typography variant="body2" style={{ marginTop: "4px" }}>
          1 Credit
        </Typography>
        <Typography variant="body2" style={{ marginTop: "4px" }}>
          {moment(usage.claimed_at).format("D/MM/YYYY")}
        </Typography>
      </Box>
    </Box>
  );
}
