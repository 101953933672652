export interface GooglePlaceSearchResult {
  formatted_address: string;
  name: string;
  place_id: string;
  description: string;
}

const placesService = new google.maps.places.PlacesService(
  document.createElement("div")
);

const autoCompleteService = new google.maps.places.AutocompleteService();
const sessionToken = new google.maps.places.AutocompleteSessionToken();

export async function search(text: any) {
  return await new Promise((resolve, reject) => {
    autoCompleteService.getPlacePredictions(
      {
        input: text,
        sessionToken: sessionToken,
        componentRestrictions: {
          country: ["au"],
        },
      },
      (results, status) => {
        if (status === "OK") {
          resolve(
            results.map((result) => {
              return {
                formatted_address: result.structured_formatting.secondary_text,
                name: result.structured_formatting.main_text,
                place_id: result.place_id,
                description: result.description,
              };
            })
          );
        }
      }
    );
  });
}

export async function placeDetails(placeId: any) {
  return await new Promise((resolve, reject) => {
    placesService.getDetails(
      {
        placeId: placeId,
        fields: ["name,address_component,formatted_address"],
      },
      (result, status) => {
        if (status === "OK") {
          resolve(result as google.maps.places.PlaceResult);
        }
      }
    );
  });
}

export async function normalisedPlaceId(placeId: string) {
  return await new Promise((resolve, reject) => {
    placesService.getDetails(
      {
        placeId: placeId,
        fields: ["place_id"],
      },
      (result, status) => {
        if (status === "OK") {
          resolve((result as google.maps.places.PlaceResult).place_id);
        } else {
          reject("Invalid Google Place ID");
        }
      }
    );
  });
}
