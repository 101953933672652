import React from "react";
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import {
  addressForProperty,
  Contract,
  Property,
  TeamMemberRole,
  LegalReviewRequest,
  Enquiry,
  LegalReviewRequestStage,
  EnquiryStage,
} from "../../api/models";
import moment from "moment";
import { isEmpty } from "lodash-es";
import { useSessionStore } from "../../stores/Session";
import BLUELOGO from "../../images/BlueLogo.png";

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "50px",
    paddingBottom: 105,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  itemTitle: {
    fontFamily: "GT Walsheim Pro",
    fontWeight: 700,
    fontSize: "14px",
    marginBottom: "16px",
  },
  title: {
    fontFamily: "GT Walsheim Pro",
    fontWeight: 700,
    fontSize: "10px",
  },
  content: {
    fontFamily: "GT Walsheim Pro",
    fontWeight: 400,
    fontSize: "10px",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: "50px",
    right: "50px",
    textAlign: "center",
    color: "#000000",
    fontFamily: "GT Walsheim Pro",
    fontWeight: 400,
  },
});

interface Props {
  property: Property;
  contract: Contract;
  contractS: Contract;
  recordId: number;
  userTeamMemberRole: TeamMemberRole;
  comments: boolean;
  requests: LegalReviewRequest[];
  enquiries: Enquiry[];
  requestsForName: string;
}

export default function PDFDocument({
  property,
  contract,
  contractS,
  recordId,
  userTeamMemberRole,
  comments,
  requests,
  enquiries,
  requestsForName,
}: Props) {
  const { data: user } = useSessionStore();

  const logo = () => {
    if (contract) {
      const { logo, company_title, company_pk } = contract;
      if (
        userTeamMemberRole === TeamMemberRole.buyer ||
        userTeamMemberRole === TeamMemberRole.buyerLawyer ||
        userTeamMemberRole === TeamMemberRole.partner ||
        user?.current_organization?.pk === contract?.company_pk ||
        user?.current_organization?.pk === contract?.partner_company_pk
      ) {
        if (logo) {
          return (
            <Image
              src={{
                uri: logo,
                method: "GET",
                headers: {
                  "Cache-Control": "no-cache",
                },
                body: "",
              }}
              style={{
                maxWidth: "100%",
                objectFit: "contain",
                height: "40px",
              }}
            />
          );
        }

        if (company_title) {
          return (
            <Text
              style={{
                fontFamily: "GT Walsheim Pro",
                fontWeight: 700,
                fontSize: "24px",
              }}
            >
              {company_title}
            </Text>
          );
        }
      }
    }

    if (contractS) {
      const { logo, company_title, company_pk } = contractS;

      if (
        userTeamMemberRole === TeamMemberRole.seller ||
        userTeamMemberRole === TeamMemberRole.sellerLawyer ||
        user?.current_organization?.pk === contractS?.company_pk
      ) {
        if (logo) {
          return (
            <Image
              src={{
                uri: logo,
                method: "GET",
                headers: {
                  "Cache-Control": "no-cache",
                },
                body: "",
              }}
              style={{
                maxWidth: "100%",
                objectFit: "contain",
                height: "40px",
              }}
            />
          );
        }

        if (company_title) {
          return (
            <Text
              style={{
                fontFamily: "GT Walsheim Pro",
                fontWeight: 700,
                fontSize: "24px",
              }}
            >
              {company_title}
            </Text>
          );
        }
      }
    }

    return <Image src={BLUELOGO} style={{ height: "40px" }} />;
  };

  // REMINDER: lineHeight and letterSpacing for Text doesn't work

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View>
              <Text
                style={{
                  fontFamily: "GT Walsheim Pro",
                  fontWeight: 400,
                  fontSize: "12px",
                  width: "122px",
                }}
              >
                {addressForProperty(property)}
              </Text>
            </View>

            <View style={{ display: "flex", flexDirection: "row", flex: 1 }} />

            <View>{logo()}</View>
          </View>

          <View
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.32)",
              height: 1,
              marginTop: "8px",
              marginBottom: "24px",
            }}
          />

          <View
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Text
              style={{
                fontFamily: "GT Walsheim Pro",
                fontWeight: 400,
                fontSize: "22px",
                marginBottom: "24px",
              }}
            >
              Requests for {requestsForName}
            </Text>

            <Text
              style={{
                fontFamily: "GT Walsheim Pro",
                fontWeight: 700,
                fontSize: "16px",
              }}
            >
              Amendment request
            </Text>
            <View
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.16)",
                height: 0.8,
                marginTop: "8px",
                marginBottom: "16px",
              }}
            />

            {requests.map(
              (request, index) =>
                request.request_status !== LegalReviewRequestStage.deleted && (
                  <View>
                    <Text
                      style={{
                        fontFamily: "GT Walsheim Pro",
                        fontWeight: 700,
                        fontSize: "12px",
                        marginBottom: "16px",
                      }}
                    >
                      {index + 1}. {request.title}
                    </Text>

                    <View style={{ width: "97%", left: "14px" }}>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <View style={{ marginRight: "16px", width: "25%" }}>
                          <Text style={styles.title}>Request</Text>
                        </View>
                        <View style={{ width: "75%" }}>
                          <Text style={styles.content}>
                            {request.description}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.16)",
                        height: 0.5,
                        marginTop: "8px",
                        marginBottom: "8px",
                        left: "14px",
                        width: "97%",
                      }}
                    />

                    <View style={{ width: "97%", left: "14px" }}>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <View style={{ marginRight: "16px", width: "25%" }}>
                          <Text style={styles.title}>
                            Proposed change to contract
                          </Text>
                        </View>
                        <View style={{ width: "75%" }}>
                          <Text style={styles.content}>
                            {request.request_status ===
                            LegalReviewRequestStage.buyerAgreed
                              ? request.alternate_change
                              : request.propose}
                          </Text>
                        </View>
                      </View>
                    </View>

                    {request.alternate_change &&
                      request.request_status ===
                        LegalReviewRequestStage.alternativeSuggested && (
                        <>
                          <View
                            style={{
                              backgroundColor: "rgba(0, 0, 0, 0.16)",
                              height: 0.5,
                              marginTop: "8px",
                              marginBottom: "8px",
                              left: "14px",
                              width: "97%",
                            }}
                          />

                          <View style={{ width: "97%", left: "14px" }}>
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <View
                                style={{ marginRight: "16px", width: "25%" }}
                              >
                                <Text style={styles.title}>
                                  Proposed alternative change to contract
                                </Text>
                              </View>
                              <View style={{ width: "75%" }}>
                                <Text style={styles.content}>
                                  {request.alternate_change}
                                </Text>
                              </View>
                            </View>
                          </View>
                        </>
                      )}

                    <View
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.16)",
                        height: 0.5,
                        marginTop: "8px",
                        marginBottom: "8px",
                        left: "14px",
                        width: "97%",
                      }}
                    />

                    <View style={{ width: "97%", left: "14px" }}>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <View style={{ marginRight: "16px", width: "25%" }}>
                          <Text style={styles.title}>Status</Text>
                        </View>
                        <View style={{ width: "75%" }}>
                          <Text style={styles.content}>
                            {request.request_status}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.16)",
                        height: 0.5,
                        marginTop: "8px",
                        marginBottom: "16px",
                        left: "14px",
                        width: "97%",
                      }}
                    />

                    {comments && !isEmpty(request.p_legalComments) && (
                      <Text
                        style={{
                          fontFamily: "GT Walsheim Pro",
                          fontWeight: 700,
                          fontSize: "12px",

                          marginBottom: "16px",
                          left: "14px",
                        }}
                      >
                        Legal comments:
                      </Text>
                    )}

                    {comments &&
                      request.p_legalComments?.map((comment) => (
                        <View style={{ width: "97%", left: "14px" }}>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <View style={{ marginRight: "16px", width: "25%" }}>
                              <Text style={styles.title}>
                                {comment.user.first_name}&nbsp;
                                {comment.user.last_name}
                                {"\n"}
                                <Text
                                  style={{
                                    fontFamily: "GT Walsheim Pro",
                                    fontWeight: 400,
                                    fontSize: "8px",
                                    color: "#9F9F9F",
                                  }}
                                >
                                  {moment(comment.created).format(
                                    "MMM D [at] hh:mm A"
                                  )}
                                </Text>
                              </Text>
                            </View>
                            <View style={{ width: "75%" }}>
                              <Text style={styles.content}>
                                {comment.content}
                              </Text>
                            </View>
                          </View>
                          <View
                            style={{
                              backgroundColor: "rgba(0, 0, 0, 0.16)",
                              height: 0.5,
                              marginTop: "8px",
                              marginBottom: "8px",
                            }}
                          />
                        </View>
                      ))}

                    {comments && !isEmpty(request.p_legalComments) && (
                      <View style={{ height: "14px" }} />
                    )}

                    {comments && !isEmpty(request.p_teamComments) && (
                      <Text
                        style={{
                          fontFamily: "GT Walsheim Pro",
                          fontWeight: 700,
                          fontSize: "12px",

                          marginBottom: "16px",
                          left: "14px",
                        }}
                      >
                        Team comments:
                      </Text>
                    )}

                    {comments &&
                      request.p_teamComments?.map((comment) => (
                        <View style={{ width: "97%", left: "14px" }}>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <View style={{ marginRight: "16px", width: "25%" }}>
                              <Text style={styles.title}>
                                {comment.user.first_name}&nbsp;
                                {comment.user.last_name}
                                {"\n"}
                                <Text
                                  style={{
                                    fontFamily: "GT Walsheim Pro",
                                    fontWeight: 400,
                                    fontSize: "8px",
                                    color: "#9F9F9F",
                                  }}
                                >
                                  {moment(comment.created).format(
                                    "MMM D [at] hh:mm A"
                                  )}
                                </Text>
                              </Text>
                            </View>
                            <View style={{ width: "75%" }}>
                              <Text style={styles.content}>
                                {comment.content}
                              </Text>
                            </View>
                          </View>
                          <View
                            style={{
                              backgroundColor: "rgba(0, 0, 0, 0.16)",
                              height: 0.5,
                              marginTop: "8px",
                              marginBottom: "8px",
                            }}
                          />
                        </View>
                      ))}

                    {comments && !isEmpty(request.p_teamComments) && (
                      <View style={{ height: "14px" }} />
                    )}
                  </View>
                )
            )}

            <Text
              style={{
                fontFamily: "GT Walsheim Pro",
                fontWeight: 700,
                fontSize: "16px",
                marginTop: "16px",
              }}
            >
              General enquiries
            </Text>
            <View
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.16)",
                height: 0.8,
                marginTop: "8px",
                marginBottom: "16px",
              }}
            />

            {enquiries.map(
              (enquiry, index) =>
                enquiry.enquiry_status !== EnquiryStage.Deleted && (
                  <View>
                    <Text
                      style={{
                        fontFamily: "GT Walsheim Pro",
                        fontWeight: 700,
                        fontSize: "12px",
                        marginBottom: "16px",
                      }}
                    >
                      {index + 1}. {enquiry.title}
                    </Text>

                    <View style={{ width: "97%", left: "14px" }}>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <View style={{ marginRight: "16px", width: "25%" }}>
                          <Text style={styles.title}>General enquiry</Text>
                        </View>
                        <View style={{ width: "75%" }}>
                          <Text style={styles.content}>{enquiry.enquiry}</Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.16)",
                        height: 0.5,
                        marginTop: "8px",
                        marginBottom: "8px",
                        left: "14px",
                        width: "97%",
                      }}
                    />

                    <View style={{ width: "97%", left: "14px" }}>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <View style={{ marginRight: "16px", width: "25%" }}>
                          <Text style={styles.title}>Status</Text>
                        </View>
                        <View style={{ width: "75%" }}>
                          <Text style={styles.content}>{enquiry.status}</Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.16)",
                        height: 0.5,
                        marginTop: "8px",
                        marginBottom: "16px",
                        left: "14px",
                        width: "97%",
                      }}
                    />

                    {!isEmpty(enquiry.answer_to_enquiry) && (
                      <View>
                        <View style={{ width: "97%", left: "14px" }}>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <View style={{ marginRight: "16px", width: "25%" }}>
                              <Text style={styles.title}>Answer</Text>
                            </View>
                            <View style={{ width: "75%" }}>
                              <Text style={styles.content}>
                                {enquiry.answer_to_enquiry}
                              </Text>
                            </View>
                          </View>
                        </View>

                        <View
                          style={{
                            backgroundColor: "rgba(0, 0, 0, 0.16)",
                            height: 0.5,
                            marginTop: "8px",
                            marginBottom: "8px",
                            left: "14px",
                          }}
                        />
                      </View>
                    )}

                    {comments && !isEmpty(enquiry.p_teamComments) && (
                      <Text
                        style={{
                          fontFamily: "GT Walsheim Pro",
                          fontWeight: 700,
                          fontSize: "12px",

                          marginBottom: "16px",
                          left: "14px",
                        }}
                      >
                        Team comments:
                      </Text>
                    )}

                    {comments &&
                      enquiry.p_teamComments?.map((comment) => (
                        <View style={{ width: "97%", left: "14px" }}>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <View style={{ marginRight: "16px", width: "25%" }}>
                              <Text style={styles.title}>
                                {comment.user.first_name}&nbsp;
                                {comment.user.last_name}
                                {"\n"}
                                <Text
                                  style={{
                                    fontFamily: "GT Walsheim Pro",
                                    fontWeight: 400,
                                    fontSize: "8px",
                                    color: "#9F9F9F",
                                  }}
                                >
                                  {moment(comment.created).format(
                                    "MMM D [at] hh:mm A"
                                  )}
                                </Text>
                              </Text>
                            </View>
                            <View style={{ width: "75%" }}>
                              <Text style={styles.content}>
                                {comment.content}
                              </Text>
                            </View>
                          </View>
                          <View
                            style={{
                              backgroundColor: "rgba(0, 0, 0, 0.16)",
                              height: 0.5,
                              marginTop: "8px",
                              marginBottom: "8px",
                            }}
                          />
                        </View>
                      ))}
                  </View>
                )
            )}
          </View>
        </View>
        <View style={styles.pageNumber} fixed>
          <View
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.32)",
              height: 1,
              marginBottom: "8px",
            }}
          />
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View>
              <Text
                style={{
                  fontFamily: "GT Walsheim Pro",
                  fontWeight: 400,
                  fontSize: "10px",
                  width: "109px",
                  color: "#000000",
                }}
              >
                Generated with{" "}
                <Text style={{ textDecoration: "underline" }}>
                  <a href="https://contrax.com.au/">Contrax</a>
                </Text>
              </Text>
            </View>

            <View style={{ display: "flex", flexDirection: "row", flex: 1 }} />

            <Text
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
            />
          </View>
        </View>
      </Page>
    </Document>
  );
}
