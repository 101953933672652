import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Colors } from "../constants/colors";

interface Props {
  initials: string;
}

export default function ({ initials }: Props) {
  return (
    <Box
      minWidth="40px"
      width="40px"
      height="40px"
      borderRadius="20px"
      bgcolor={Colors.BrandBackground3}
      display="flex"
      alignItems="center"
      justifyContent="center"
      mr="8px"
    >
      <Typography variant="body2" style={{ fontWeight: 700 }}>
        {initials}
      </Typography>
    </Box>
  );
}
