import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useHistory, useLocation, useParams } from "react-router-dom";
import api from "../api/instance";
import { useMobile } from "../hooks/mobile";
import { isLoggedIn } from "../stores/Session";
import AlertWrapper from "../components/AlertWrapper";
import { useAlert } from "../contexts/Alert";
import AsyncSelect from "../components/common/AsyncSelect";
import SearchIcon from "../images/search.png";
import TextField from "../components/common/TextField";
import {
  search as searchPlace,
  placeDetails,
  GooglePlaceSearchResult,
  normalisedPlaceId,
} from "../googleplaces/queries";
import MapPinIcon from "../images/map-pin.png";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import Button, { ButtonType, ButtonSize } from "../components/common/Button";
import { isNil } from "lodash-es";
import { Contract, Property, ContractPublishStatus } from "../api/models";
import { AxiosError } from "axios";
import useSWR from "swr";
import BLUELOGO from "../images/Blue.png";
import OrganisationLogo from "../components/OrganisationLogo";
import { useSessionStore } from "../stores/Session";
import { User } from "../api/models";

interface PlaceOption {
  image: string;
  value: string;
  label: string;
  subLabel: string;
  description: string;
}

interface PlaceDetails {
  street: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}

interface Params {
  uuid?: string;
}

export default function MyAddProperty(): JSX.Element {
  const history = useHistory();
  const location = useLocation();

  const { uuid } = useParams<Params>();

  const query = new URLSearchParams(location.search);
  const propertyId = query.get("propertyId");

  const [selectedPlaceId, setSelectedPlaceId] = React.useState<string | null>(
    null
  );

  const [selectedLabel, setSelectedLabel] = React.useState<String | null>(null);

  const [streetName, setStreetName] = React.useState<String | null>(null);

  const [selectedPlaceDetails, setSelectedPlaceDetails] =
    React.useState<PlaceDetails | null>(null);
  const [selectedPlaceError, setSelectedPlaceError] = React.useState<
    string | null
  >(null);

  const [propertyURL, setPropertyURL] = React.useState("");
  const [propertyURLError, setPropertyURLError] = React.useState<string | null>(
    null
  );

  // const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [uploadError, setUploadError] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const isMobile = useMobile();
  const { setErrorMessage } = useAlert();

  const searchTimeoutRef = React.useRef<any>(null);
  const prefilledPropertyRef = React.useRef(false);

  const { data: userData } = useSessionStore();
  const user = userData && (userData as User);

  const { data: propertyData } = useSWR(
    propertyId && `/view-properties/${propertyId}/`
  );
  const property = propertyData && (propertyData as Property);

  function Header() {
    return (
      <Box
        width={isMobile ? undefined : "700px"}
        mt={isMobile ? 3 : 22}
        pl={isMobile ? 3 : 0}
        pr={isMobile ? 3 : 0}
        alignSelf="flex-start"
      >
        <Typography variant="overline">STEP 1 OF 3</Typography>
        <Typography
          variant={isMobile ? "h5" : "h4"}
          style={{ marginTop: "8px" }}
        >
          Search property
        </Typography>
      </Box>
    );
  }

  React.useEffect(() => {
    if (selectedPlaceId) {
      placeDetails(selectedPlaceId).then((result) => {
        console.debug("view result: ", result);
        let place = result as google.maps.places.PlaceResult;
        let components = place.address_components;
        if (components) {
          let subPremise =
            components.find((component) =>
              component.types.includes("subpremise")
            )?.long_name || "";

          let streetNumber =
            components.find((component) =>
              component.types.includes("street_number")
            )?.long_name || "";
          let streetName =
            components.find((component) => component.types.includes("route"))
              ?.short_name || "";
          let city =
            components.find((component) => component.types.includes("locality"))
              ?.long_name || "";
          let state =
            components.find((component) =>
              component.types.includes("administrative_area_level_1")
            )?.short_name || "";
          let postalCode =
            components.find((component) =>
              component.types.includes("postal_code")
            )?.long_name || "";
          let country =
            components.find((component) => component.types.includes("country"))
              ?.long_name || "";

          setSelectedPlaceDetails({
            street: `${
              subPremise && subPremise + "/"
            }${streetNumber} ${streetName}`.trim(),
            city,
            state,
            postalCode,
            country,
          });
        }
      });
    }
  }, [selectedPlaceId]);

  React.useEffect(() => {
    if (property && !prefilledPropertyRef.current) {
      setPropertyURL(property.property_url || "");
      console.debug("place_id: ", property.place_id);
      setSelectedPlaceId(property.place_id);

      prefilledPropertyRef.current = true;
    }
  }, [property]);

  async function nextClicked() {
    if (loading) {
      return;
    }

    // Otherwise add new property
    setSelectedPlaceError(null);

    if (user?.user_type === "SOL") {
      setErrorMessage("Practitioner users cannot add properties here.");
      return;
    }

    if (isNil(selectedPlaceDetails)) {
      setSelectedPlaceError("Please select your property address");
      return;
    }

    setLoading(true);

    if (selectedPlaceDetails) {
      const {
        street,
        city,
        state,
        postalCode: postal_code,
        country,
      } = selectedPlaceDetails;

      let placeId: any = null;

      if (selectedPlaceId) {
        await normalisedPlaceId(selectedPlaceId).then((Id) => {
          placeId = Id;
        });
      }
      // console.log(placeId, "placeId");
      // console.log(placeId, "placeId");
      const form = new FormData();
      selectedPlaceId && form.append("place_id", placeId);
      form.append("property_url", propertyURL);
      form.append("street", street);
      form.append("city", city);
      form.append("state", state);
      form.append("postal_code", postal_code);
      form.append("country", country);

      api
        .post(
          `/public-properties/?public=true&label=${selectedLabel}&street_name=${streetName}`,
          form
        )
        .then((response) => {
          const property = response.data as Property;

          if (!isLoggedIn()) {
            history.push(
              `/org/${uuid}/create-account?propertyId=${property.id}`
            );

            return;
          }

          if (property.contract_id) {
            api
              .get(`/view-contracts/${property.contract_id}`)
              .then((response) => {
                const contract = response.data as Contract;

                const allowContractUpload =
                  !contract ||
                  (contract &&
                    (contract.publish_status === ContractPublishStatus.Delete ||
                      contract.publish_status === ContractPublishStatus.Sold));

                if (allowContractUpload) {
                  history.push(
                    `/org/${uuid}/my-uploadcontract?propertyId=${property.id}`
                  );
                } else {
                  // Redirect to sale contract available
                  history.push(
                    `/org/${uuid}/contract-available?propertyId=${property.id}`
                  );
                }
              });
          } else {
            history.push(
              `/org/${uuid}/my-uploadcontract?propertyId=${property.id}`
            );
          }
        })
        .catch((error: AxiosError) => {
          setLoading(false);

          setErrorMessage(error.message);
        });
    }
  }

  return (
    <AlertWrapper>
      {!isMobile && (
        <>
          <Box
            mb={4}
            display="flex"
            flexDirection="row"
            width="100%"
            height={88}
            alignItems="center"
            justifyContent="space-between"
            position="absolute"
          >
            <Box ml={2}>
              <OrganisationLogo uuid={uuid} />
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Box
              display="flex"
              flexDirection="column"
              marginLeft="auto"
              marginRight="auto"
              alignItems="undefined"
              mb={8}
              width="725px"
            >
              <Header />

              <Box
                display="flex"
                flexDirection="row"
                mt={7.25}
                maxWidth="768px"
              >
                <Box width="353px" pl={0} pr={0}>
                  {isNil(property) && (
                    <React.Fragment>
                      <Box width="725px">
                        <AsyncSelect
                          title="Search for a property address"
                          placeholder=""
                          error={selectedPlaceError !== null}
                          helperText={selectedPlaceError}
                          loadOptions={(inputValue, callback) => {
                            if (searchTimeoutRef.current) {
                              clearTimeout(searchTimeoutRef.current);
                            }

                            searchTimeoutRef.current = setTimeout(() => {
                              console.debug("loadOptions");

                              searchPlace(inputValue)
                                .then((results) => {
                                  const options = (
                                    results as Array<GooglePlaceSearchResult>
                                  ).map((result) => {
                                    return {
                                      image: MapPinIcon,
                                      value: result.place_id,
                                      label: result.name,
                                      subLabel: result.formatted_address,
                                      description: result.description,
                                    } as PlaceOption;
                                  });

                                  callback(options);
                                })
                                .catch((error) => {
                                  console.debug("error: ", error);
                                });
                            }, 500);
                          }}
                          dropdownImage={SearchIcon}
                          onChange={(value) => {
                            setStreetName((value as PlaceOption).label);
                            setSelectedLabel(
                              (value as PlaceOption).description
                            );
                            setSelectedPlaceId((value as PlaceOption).value);
                          }}
                        />
                        <Typography
                          variant="caption"
                          component="sup"
                          style={{ verticalAlign: "text-top", lineHeight: 4 }}
                        >
                          E.g. 1/100 York Street, Sydney
                        </Typography>
                      </Box>
                    </React.Fragment>
                  )}

                  {!isNil(property) && (
                    <TextField
                      title="Property Address"
                      value={selectedPlaceDetails?.street}
                      disabled={true}
                    />
                  )}
                </Box>
              </Box>
            </Box>

            <Box
              width="725px"
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              mt={5}
              mb={16}
              ml="auto"
              mr="auto"
            >
              <Button
                type={ButtonType.dark}
                size={ButtonSize.large}
                title="NEXT"
                width={"137px"}
                loading={loading}
                onClick={() => nextClicked()}
              />
            </Box>
          </Box>
        </>
      )}
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        height={88}
        position="fixed"
        alignItems="center"
        justifyContent="space-between"
        bottom="0"
      >
        <Box ml={4}>
          <Typography variant="body2">Powered by</Typography>
          <Box>
            <img src={BLUELOGO} height="32px" />
          </Box>
        </Box>
      </Box>

      {isMobile && (
        <>
          <Box
            mb={4}
            display="flex"
            flexDirection="row"
            width="100%"
            height={72}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box ml={0}>
              <OrganisationLogo uuid={uuid} />
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            marginLeft="auto"
            marginRight="auto"
            alignItems="center"
            mb={8}
          >
            <Header />

            <Box
              display="flex"
              flexDirection="column"
              mt={4}
              maxWidth="768px"
              width="100%"
            >
              <Box width="undefined" pl={3} pr={3}>
                {isNil(property) && (
                  <React.Fragment>
                    <AsyncSelect
                      title="Search for a property address"
                      placeholder=""
                      error={selectedPlaceError !== null}
                      helperText={selectedPlaceError}
                      loadOptions={(inputValue, callback) => {
                        if (searchTimeoutRef.current) {
                          clearTimeout(searchTimeoutRef.current);
                        }

                        searchTimeoutRef.current = setTimeout(() => {
                          console.debug("loadOptions");

                          searchPlace(inputValue)
                            .then((results) => {
                              const options = (
                                results as Array<GooglePlaceSearchResult>
                              ).map((result) => {
                                return {
                                  image: MapPinIcon,
                                  value: result.place_id,
                                  label: result.name,
                                  subLabel: result.formatted_address,
                                  description: result.description,
                                } as PlaceOption;
                              });

                              callback(options);
                            })
                            .catch((error) => {
                              console.debug("error: ", error);
                            });
                        }, 500);
                      }}
                      dropdownImage={SearchIcon}
                      onChange={(value) => {
                        setStreetName((value as PlaceOption).label);
                        setSelectedLabel((value as PlaceOption).description);
                        setSelectedPlaceId((value as PlaceOption).value);
                      }}
                    />
                    <Typography
                      variant="caption"
                      component="sup"
                      style={{ verticalAlign: "text-top", lineHeight: 2 }}
                    >
                      E.g. 1/100 York Street, Sydney
                    </Typography>
                  </React.Fragment>
                )}

                {!isNil(property) && (
                  <TextField
                    title="Property Address"
                    value={selectedPlaceDetails?.street}
                    disabled={true}
                  />
                )}
              </Box>
            </Box>
            <MobileStickyFooter
              buttonsLayout={FooterButtonsLayout.sideBySideDark}
              leftButtonTitle="BACK"
              leftButtonOnClick={() => history.goBack()}
              rightButtonTitleDark="NEXT"
              rightButtonOnClick={() => nextClicked()}
            />
          </Box>
        </>
      )}
    </AlertWrapper>
  );
}
