import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  RouteProps,
  Link,
  Redirect,
} from "react-router-dom";
import AgentSignUp from "./pages/AgentSignUp";
import LawyerSignUp from "./pages/LawyerSignUp";
import CheckYourEmail from "./pages/CheckYourEmail";
import AddProperty from "./pages/AddProperty";
import UploadSaleContract from "./pages/UploadSaleContract";
import HelpForm from "./pages/HelpForm";
import MainContacts from "./pages/MainContacts";
import VerifyEmail from "./pages/VerifyEmail";
import Login from "./pages/Login";
import ContractUploadSuccess from "./pages/ContractUploadSuccess";
import LegalReviewReady from "./pages/LegalReviewReady";
import LegalReviewPending from "./pages/LegalReviewPending";
import PropertyMarketing from "./pages/PropertyMarketing";
import ContractForSale from "./pages/ContractForSale";
import SampleLegalReview from "./pages/SampleLegalReview";
import SignUp from "./pages/SignUp";
import PaymentPreview from "./pages/PaymentPreview";
import PaymentFailed from "./pages/PaymentFailed";
import LegalReviewPurchased from "./pages/LegalReviewPurchased";
import RequestLawyer from "./pages/RequestLawyer";
import RequestLawyerSuccess from "./pages/RequestLawyerSuccess";
import ThankYouWillNotify from "./pages/ThankYouWillNotify";
import ResetPasswordRequest from "./pages/ResetPasswordRequest";
import ResetPassword from "./pages/ResetPassword";
import PropertyNotReviewed from "./pages/PropertyNotReviewed";
import PropertyNotReviewedConfirm from "./pages/PropertyNotReviewedConfirm";
import PropertyNotReviewedSuccess from "./pages/PropertyNotReviewedSuccess";
import MyProperties from "./pages/MyProperties";
import MyProperty from "./pages/MyProperty";
import AccountDetails from "./pages/AccountDetails";
import EditAccountDetails from "./pages/EditAccountDetails";
import ChangePassword from "./pages/ChangePassword";
import UpdateContract from "./pages/UpdateContract";
import UpdateContractConfirm from "./pages/UpdateContractConfirm";
import UpdateContractSuccess from "./pages/UpdateContractSuccess";
import MyInvoices from "./pages/MyInvoices";
import LegalReview from "./pages/LegalReview";
import PropertySearch from "./pages/PropertySearch";
import AddSearchedLegalReview from "./pages/AddSearchedLegalReview";
import SearchedUploadSaleContract from "./pages/SearchedUploadSaleContract";
import AddAgentDetails from "./pages/AddAgentDetails";
import LegalReviewBuilder from "./pages/LegalReviewBuilder";
import LegalReviewBuilderLanding from "./pages/LegalReviewBuilderLanding";
import LegalReviewViewer from "./pages/LegalReviewViewer";
import RequestBuilder from "./pages/RequestBuilder";
import RequestWorkspace from "./pages/RequestWorkspace";
import AmendmentAppendix from "./pages/AmendmentAppendix";
import RequestResponder from "./pages/RequestResponder";
import PropertyLanding from "./pages/PropertyLanding";
import PropertySelectRole from "./pages/PropertySelectRole";
import NotificationHistory from "./pages/NotificationHistory";
import PropertyDetails from "./pages/Dashboard/PropertyDetails";
import MyDashboard from "./pages/Dashboard/Dashboard";
import LegalReviewNotReady from "./pages/LegalReviewNotReady";
import StartAddingRequests from "./pages/StartAddingRequests";
import SearchProperty from "./pages/SearchProperty";
import ScrollToTop from "./components/ScrollToTop";
import MyTeam from "./pages/MyTeam";
import MyPersonalisation from "./pages/Personalisation";
import MyLandingPage from "./pages/MyLandingPage";
import MyAddProperty from "./pages/MyAddProperty";
import MyUploadContract from "./pages/MyUploadContract";
import MyThankYouScreen from "./pages/MyThankYouScreen";
import CreateAccount from "./pages/CreateAccount";
import MyLoginPage from "./pages/MyLoginPage";
import PayAndNotInvitedWorkspace from "./pages/PayAndNotInvitedWorkspace";
import LegalReviewBuyer from "./pages/LegalReviewBuyer";
import UnpaidWorkspace from "./pages/UnpaidWorkspace";
import PurchaseCredits from "./pages/PurchaseCredits";
import CreditPacks from "./pages/CreditPacks";
import UserCredits from "./pages/UserAccount/Credits";
import UserBilling from "./pages/UserAccount/Billing";
import UserInvoices from "./pages/UserAccount/Invoices";
import UserUsage from "./pages/UserAccount/Usage";
import UserAccount from "./pages/UserAccount/Account";
import UserEditBilling from "./pages/UserAccount/EditBilling";
import UserEditCard from "./pages/UserAccount/EditCard";
import ViewInvoice from "./pages/UserAccount/ViewInvoice";
import SaleContractAvailable from "./pages/SaleContractAvailable";
import CreateWorkspace from "./pages/CreateWorkspace";
import PayForProperty from "./pages/PayForProperty";
import { useSessionStore } from "./stores/Session";
import { UserType, User } from "./api/models";
import PartnerLandingPage from "./pages/PartnerLandingPage";
import JoinWorkspace from "./pages/JoinWorkspace";
import noticon from "./images/faviconnot.png";
import contraxicon from "./images/favicon.png";
import { Helmet } from "react-helmet";

export default function App() {
  const { data: user } = useSessionStore();
  const userData = user && (user as User);

  const isAgent = user?.user_type === UserType.Agent;
  const isPartner = user?.user_type === UserType.Partner;

  return (
    <>
      {userData?.new_notification_count ? (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Contrax</title>
          <link rel="icon" href={noticon} type="image/png" sizes="16x16" />
        </Helmet>
      ) : (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Contrax</title>
          <link rel="icon" href={contraxicon} type="image/png" sizes="16x16" />
        </Helmet>
      )}
      <Router basename="/app">
        <ScrollToTop>
          <Switch>
            <Route exact path="/agent/signup" component={AgentSignUp} />
            <Route
              exact
              path="/agent/signup-success"
              component={CheckYourEmail}
            />

            <Route exact path="/practitioner/signup" component={LawyerSignUp} />
            <Route exact path="/agent/add-property" component={AddProperty} />
            <Route
              exact
              path={["/agent/upload-contract", "/upload-sale-contract"]}
              component={UploadSaleContract}
            />
            <Route exact path="/agent/help" component={HelpForm} />
            <Route exact path="/agent/main-contacts" component={MainContacts} />
            {/* <Route exact path="/dashboard" component={Dashboard} /> */}
            <Route exact path="/verify-email" component={VerifyEmail} />
            <Route exact path="/login" component={Login} />
            <Route
              exact
              path="/agent/contract-upload-success"
              component={ContractUploadSuccess}
            />
            <Route
              exact
              path="/agent/legal-review-ready"
              component={LegalReviewReady}
            />
            <Route
              exact
              path="/agent/legal-review-pending"
              component={LegalReviewPending}
            />
            {/* <Route exact path="/property-info" component={PropertyMarketing} /> */}
            <Route exact path="/sale-contract" component={ContractForSale} />
            <Route
              exact
              path="/sample-legal-review"
              component={SampleLegalReview}
            />
            <Route exact path="/signup" component={SignUp} />
            {/* <Route exact path="/payment-preview" component={PaymentPreview} /> */}
            <Route exact path="/payment-failed" component={PaymentFailed} />
            <Route
              exact
              path={["/legal-review-purchased", "/payment-success"]}
              component={LegalReviewPurchased}
            />
            <Route exact path="/request-lawyer" component={RequestLawyer} />
            <Route
              exact
              path="/request-lawyer-success"
              component={RequestLawyerSuccess}
            />
            <Route
              exact
              path="/thank-you-notify"
              component={ThankYouWillNotify}
            />
            <Route
              exact
              path="/reset-password-request"
              component={ResetPasswordRequest}
            />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route
              exact
              path="/property-not-reviewed"
              component={PropertyNotReviewed}
            />
            <Route
              exact
              path="/property-not-reviewed-confirm"
              component={PropertyNotReviewedConfirm}
            />
            <Route
              exact
              path="/property-not-reviewed-success"
              component={PropertyNotReviewedSuccess}
            />
            <Route exact path="/my-properties" component={MyProperties} />
            <Route exact path="/my-property" component={MyProperty} />
            <Route exact path="/account-details" component={AccountDetails} />

            <Route exact path="/update-contract" component={UpdateContract} />
            <Route
              exact
              path="/update-contract-confirm"
              component={UpdateContractConfirm}
            />
            <Route
              exact
              path="/update-contract-success"
              component={UpdateContractSuccess}
            />
            <Route exact path="/my-invoices" component={MyInvoices} />
            <Route exact path="/legal-review" component={LegalReview} />
            <Route exact path="/property-search" component={PropertySearch} />
            <Route
              exact
              path="/search/add-searched-property"
              component={AddSearchedLegalReview}
            />
            <Route
              exact
              path="/search/upload-contract"
              component={SearchedUploadSaleContract}
            />
            <Route
              exact
              path="/add/agent/details"
              component={AddAgentDetails}
            />

            <Route
              exact
              path="/legal-review-builder"
              component={LegalReviewBuilder}
            />

            <Route
              exact
              path="/legal-review-landing"
              component={LegalReviewBuilderLanding}
            />

            <Route
              exact
              path="/legal-review-viewer"
              component={LegalReviewViewer}
            />

            <Route
              exact
              path="/legal-review-not-ready"
              component={LegalReviewNotReady}
            />

            <Route exact path="/request-builder" component={RequestBuilder} />
            <Route
              exact
              path="/request-workspace"
              component={RequestWorkspace}
            />
            <Route
              exact
              path="/request-responder"
              component={RequestResponder}
            />
            <Route
              exact
              path="/amendment-appendix"
              component={AmendmentAppendix}
            />
            <Route
              exact
              path="/start-adding-requests"
              component={StartAddingRequests}
            />

            <Route exact path="/property-info" component={PropertyLanding} />
            <Route
              exact
              path="/property-select-role"
              component={PropertySelectRole}
            />
            <Route
              exact
              path="/notification-history"
              component={NotificationHistory}
            />

            <Route exact path="/property-details" component={PropertyDetails} />
            <Route exact path="/dashboard" component={MyDashboard} />

            <Route exact path="/search-property" component={SearchProperty} />

            <Route exact path="/my-team" component={MyTeam} />
            <Route
              exact
              path="/personalisation"
              component={MyPersonalisation}
            />

            <Route exact path="/org/:uuid" component={MyLandingPage} />

            <Route exact path="/partner/:uuid" component={PartnerLandingPage} />

            <Route
              exact
              path="/org/:uuid/my-addproperty"
              component={MyAddProperty}
            />
            <Route
              exact
              path="/org/:uuid/my-uploadcontract"
              component={MyUploadContract}
            />

            <Route
              exact
              path="/org/:uuid/contract-available"
              component={SaleContractAvailable}
            />

            <Route
              exact
              path="/org/:uuid/create-account"
              component={CreateAccount}
            />
            <Route exact path="/org/:uuid/my-login" component={MyLoginPage} />

            <Route
              exact
              path="/org/:uuid/thankyou"
              component={MyThankYouScreen}
            />

            <Route exact path="/join-workspace" component={JoinWorkspace} />

            <Route
              exact
              path="/pay-notinvited-workspace"
              component={PayAndNotInvitedWorkspace}
            />

            <Route
              exact
              path="/pay-notinvited-workspace"
              component={PayAndNotInvitedWorkspace}
            />

            <Route
              exact
              path="/legal-review-buyer"
              component={LegalReviewBuyer}
            />

            <Route
              exact
              path="/buyer-invited-workspace"
              component={UnpaidWorkspace}
            />

            <Route exact path="/create-workspace" component={CreateWorkspace} />
            <Route exact path="/pay-workspace" component={CreateWorkspace} />

            <Route exact path="/purchase-credits" component={PurchaseCredits} />

            <Route exact path="/plans" component={CreditPacks} />

            <Route exact path="/payment-preview" component={PaymentPreview} />
            <Route exact path="/pay-for-property" component={PayForProperty} />

            <Route exact path="/user">
              {(!isAgent || !isPartner) && <Redirect to="/user/credits" />}

              {(isAgent || isPartner) && <Redirect to="/user/account" />}
            </Route>

            <Route exact path="/user/credits" component={UserCredits} />
            <Route exact path="/user/billing" component={UserBilling} />
            <Route exact path="/user/invoices" component={UserInvoices} />
            <Route exact path="/user/usage" component={UserUsage} />
            <Route exact path="/user/account" component={UserAccount} />
            <Route
              exact
              path="/user/account/edit"
              component={EditAccountDetails}
            />
            <Route
              exact
              path="/user/account/password"
              component={ChangePassword}
            />

            <Route
              exact
              path="/user/billing/edit"
              component={UserEditBilling}
            />
            <Route exact path="/user/card/edit" component={UserEditCard} />

            <Route exact path="/user/view-invoice" component={ViewInvoice} />

            {/* Invalid path, redirect to login */}
            <Route component={Login} />
          </Switch>
        </ScrollToTop>
      </Router>
    </>
  );
}
