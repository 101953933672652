import React from "react";
import { Box, ButtonBase } from "@material-ui/core";
import EditIcon from "../images/Edit.png";
import { useMobile } from "../hooks/mobile";

interface Props {
  onClick: () => unknown;
}

export default function EditButton({ onClick }: Props) {
  const isMobile = useMobile();
  return (
    <ButtonBase onClick={onClick} style={{ marginRight: "8px" }}>
      <Box
        border="1px solid #ECF2FB"
        borderRadius="6px"
        width="34px"
        height="34px"
        padding="0px"
        bgcolor={isMobile ? "white" : "white"}
      >
        <img src={EditIcon} style={{ padding: "8px" }} />
      </Box>
    </ButtonBase>
  );
}
