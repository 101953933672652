import React, { CSSProperties } from "react";
import { components } from "react-select";
import { Box, Typography } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import DropdownArrow from "../../images/DropdownArrow.png";

export const selectStyles = (error?: boolean) => {
  return {
    control: (base: CSSProperties, state: any) => ({
      ...base,
      height: 52,
      "&:hover": {
        borderColor:
          error === true
            ? Colors.Error
            : state.isFocused
            ? Colors.BrandPrimary
            : Colors.Grey4,
      },
      borderColor: state.isDisabled
        ? Colors.Grey5
        : error === true
        ? Colors.Error
        : state.isFocused
        ? Colors.BrandPrimary
        : Colors.Grey4,
      boxShadow: state.isFocused
        ? "0px 0px 4px #5A75FF, 0px 4px 4px rgba(90, 117, 255, 0.2)"
        : "0px",
      backgroundColor: state.isDisabled ? Colors.Grey4 : "white",
    }),
    indicatorSeparator: (base: CSSProperties) => ({
      // Hide arrow separator
      ...base,
      display: "none",
    }),
    placeholder: (base: CSSProperties) => ({
      ...base,
      fontFamily: "GT Walsheim Pro",
      fontWeight: 400,
      fontSize: "16px",
      color: Colors.Grey7,
    }),
    input: (base: CSSProperties) => ({
      ...base,
      fontFamily: "GT Walsheim Pro",
      fontWeight: 400,
      fontSize: "16px",
      color: Colors.Grey7,
      "& input": {
        font: "inherit",
      },
    }),
    option: (base: CSSProperties) => ({
      ...base,
      fontFamily: "GT Walsheim Pro",
      fontWeight: 400,
      fontSize: "16px",
      color: Colors.Grey7,
    }),
    menu: (base: CSSProperties) => ({
      ...base,
      zIndex: 9999,
    }),
  };
};

export const labelStyle = {
  fontFamily: "GT Walsheim Pro",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "22px",
};

export const subLabelStyle = {
  fontFamily: "GT Walsheim Pro",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "14px",
};

export const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={DropdownArrow} />
    </components.DropdownIndicator>
  );
};

export const formatOptionLabel = ({ value, label, subLabel, image }: any) => (
  <Box>
    <Box display="flex" flexDirection="row" mt={1} mb={1} alignItems="center">
      {image && (
        <Box pt={1.25} pb={1.25} pr={1.25}>
          <img src={image} />
        </Box>
      )}
      <Box>
        <Box style={labelStyle}>{label}</Box>
        {subLabel && <Box style={subLabelStyle}>{subLabel}</Box>}
      </Box>
    </Box>
  </Box>
);
