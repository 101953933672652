import React from "react";
import { Box, Typography } from "@material-ui/core";
import TextField from "../common/TextField";
import { ContractSummary } from "../../api/models";
import { Colors } from "../../constants/colors";
import Button, { ButtonSize, ButtonType } from "../common/Button";
import DraggableIcon from "../../images/draggable-icon.svg";
import { Draggable } from "react-beautiful-dnd";

interface Props {
  contractSummary: ContractSummary;
  onViewInContract: () => unknown;
  onEdit: () => unknown;
  index: number;
}

export default function ({
  contractSummary,
  onViewInContract,
  onEdit,
  index,
}: Props) {
  return (
    <Draggable
      draggableId={`summary-${contractSummary.pk}-${index}`}
      index={index}
    >
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Box
            border="1px solid #F4F6F8"
            borderRadius="8px"
            p="16px"
            m="8px"
            bgcolor="white"
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <img src={DraggableIcon} />
                <Typography
                  variant="body1"
                  style={{ fontWeight: 700, marginLeft: "8px" }}
                >
                  {contractSummary.title}
                </Typography>
              </Box>

              <Typography variant="caption" style={{ marginRight: "8px" }}>
                Page {contractSummary.page}
              </Typography>
            </Box>

            <Typography
              variant="body2"
              style={{
                marginTop: "12px",
                marginBottom: "16px",
                whiteSpace: "pre-line",
              }}
            >
              {contractSummary.content}
            </Typography>

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                type={ButtonType.secondary}
                size={ButtonSize.small}
                title="VIEW IN CONTRACT"
                letterSpacing="2px"
                onClick={onViewInContract}
                width="175px"
              />

              <Button
                type={ButtonType.secondary}
                size={ButtonSize.small}
                title="EDIT"
                width="63px"
                onClick={onEdit}
              />
            </Box>
          </Box>
        </div>
      )}
    </Draggable>
  );
}
