import {
  Document,
  Image,
  Page,
  PDFDownloadLink,
  StyleSheet,
  Text,
  View,
  Link,
} from "@react-pdf/renderer";
import React from "react";
import BLUELOGO from "../../images/BlueLogo.png";
import { Colors } from "../../constants/colors";
import { Order } from "../../api/models";
import moment from "moment";

// Create styles
const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "50px",
    paddingBottom: "50px",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  box: {
    padding: "8px",
    border: "1px solid #ECF2FB",
    boxSizing: "border-box",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    width: "32%",
  },
  title: {
    color: Colors.BrandBackground1,
    fontFamily: "GT Walsheim Pro",
    fontWeight: 700,
    fontSize: "12px",
    marginBottom: "4px",
  },
  body: {
    color: Colors.Grey2,
    fontFamily: "GT Walsheim Pro",
    fontWeight: 400,
    fontSize: "16px",
  },
  summary: {
    padding: "8px",
    border: "1px solid #ECF2FB",
    boxSizing: "border-box",
    borderRadius: "8px",
    width: "100%",
  },
  valueBold: {
    color: Colors.Grey2,
    fontFamily: "GT Walsheim Pro",
    fontWeight: 700,
    fontSize: "16px",
  },
  separator: {
    border: "1px solid #ECF2FB",
    width: "100%",
  },
});

interface Props {
  order: Order;
}

export default function InvoiceDocument({ order }: Props) {
  const gstCents = order.after_discount / 11;
  const itemCents = order.total_units;
  const discountCents = order.discount_units;

  const hasDiscount = order.discount_units > 0;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Image src={BLUELOGO} style={{ width: "164px", height: "36px" }} />

            <Text
              style={{
                fontFamily: "GT Walsheim Pro",
                fontWeight: 400,
                fontSize: "20px",
                color: Colors.Grey1,
                marginTop: "8px",
              }}
            >
              Contrax Australia Pty Ltd
            </Text>

            <Text
              style={{
                fontFamily: "GT Walsheim Pro",
                fontWeight: 400,
                fontSize: "12px",
                color: Colors.Grey3,
                marginTop: "4px",
              }}
            >
              ABN: 83 642 855 472
            </Text>

            <Text
              style={{
                fontFamily: "GT Walsheim Pro",
                fontWeight: 400,
                fontSize: "12px",
                color: Colors.Grey3,
                marginTop: "2px",
              }}
            >
              Suite 502, 7 Railway Street
            </Text>

            <Text
              style={{
                fontFamily: "GT Walsheim Pro",
                fontWeight: 400,
                fontSize: "12px",
                color: Colors.Grey3,
                marginTop: "2px",
              }}
            >
              Chatswood, NSW, 2067
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Text
              style={{
                color: Colors.Grey2,
                fontFamily: "GT Walsheim Pro",
                fontWeight: 400,
                fontSize: "20px",
              }}
            >
              Tax Invoice
            </Text>

            <Text
              style={{
                color: Colors.BrandBackground1,
                fontFamily: "GT Walsheim Pro",
                fontWeight: 400,
                fontSize: "12px",
                marginTop: "2px",
              }}
            >
              Invoice #{order.id}
            </Text>

            <Text
              style={{
                color: Colors.BrandBackground1,
                fontFamily: "GT Walsheim Pro",
                fontWeight: 400,
                fontSize: "12px",
                marginTop: "2px",
              }}
            >
              {moment(order.created_at).format("D/MM/YYYY")}
            </Text>
          </View>
        </View>

        <View
          style={{
            backgroundColor: Colors.BrandBackground3,
            borderRadius: "8px",
            width: "100%",
            marginTop: "16px",
            padding: "8px",
          }}
        >
          <Text
            style={{
              color: Colors.BrandBackground1,
              fontFamily: "GT Walsheim Pro",
              fontWeight: 700,
              fontSize: "12px",
            }}
          >
            PREPARED FOR
          </Text>

          <Text
            style={{
              fontFamily: "GT Walsheim Pro",
              fontWeight: 400,
              fontSize: "16px",
              marginTop: "6px",
            }}
          >
            {order.organization?.name ||
              `${order.user?.first_name} ${order.user?.last_name}`}
          </Text>
          {/* 
          <Text
            style={{
              fontFamily: "GT Walsheim Pro",
              fontWeight: 400,
              fontSize: "12px",
              color: Colors.Grey3,
              marginTop: "4px",
            }}
          >
            ABN: XXX XXX XXX
          </Text> */}

          <Text
            style={{
              fontFamily: "GT Walsheim Pro",
              fontWeight: 400,
              fontSize: "12px",
              color: Colors.Grey3,
              marginTop: "2px",
            }}
          >
            {order.organization?.address}
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "15px",
            marginBottom: "16px",
          }}
        >
          <View style={styles.box}>
            <Text style={styles.title}>AMOUNT PAID</Text>
            <Text style={styles.body}>
              A${(order.after_discount / 100).toFixed(2)}
            </Text>
          </View>
          <View style={styles.box}>
            <Text style={styles.title}>DATE PAID</Text>
            <Text style={styles.body}>
              {moment(order.created_at).format("MMMM D, YYYY")}
            </Text>
          </View>
          <View style={styles.box}>
            {order.payment_method && (
              <>
                <Text style={styles.title}>PAYMENT METHOD</Text>
                <Text style={styles.body}>
                  •••• {order.payment_method.last_digits}
                </Text>
              </>
            )}
          </View>
        </View>

        <View style={styles.summary}>
          <Text style={[styles.title, { marginBottom: "16px" }]}>SUMMARY</Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text style={styles.body}>
              {order.credit_amount} x Contrax workspaces
            </Text>
            <Text style={styles.body}>A${(itemCents / 100).toFixed(2)}</Text>
          </View>

          {hasDiscount && (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "8px",
              }}
            >
              <Text style={styles.body}>Promo code discount</Text>
              <Text style={styles.body}>
                A${(discountCents / 100).toFixed(2)}
              </Text>
            </View>
          )}

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "8px",
            }}
          >
            <Text style={styles.body}>GST (10%)</Text>
            <Text style={styles.body}>A${(gstCents / 100).toFixed(2)}</Text>
          </View>

          <View
            style={[
              styles.separator,
              { marginTop: "8px", marginBottom: "8px" },
            ]}
          />

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text style={styles.valueBold}>Amount charged (AUD)</Text>
            <Text style={styles.valueBold}>
              A${(order.after_discount / 100).toFixed(2)}
            </Text>
          </View>
        </View>

        <View
          style={[
            styles.separator,
            { marginTop: "16px", marginBottom: "16px" },
          ]}
        />

        <View
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Text style={styles.body}>
            If you have any questions, contact us at
          </Text>
          <Text style={[styles.body, { marginTop: "2px" }]}>
            <Link
              src="mailto:support@contrax.com.au"
              style={{ color: Colors.Link, textDecoration: "none" }}
            >
              support@contrax.com.au
            </Link>
            &nbsp;or call at&nbsp;
            <Link
              src="tel:+61298847473"
              style={{ color: Colors.Link, textDecoration: "none" }}
            >
              +61 2 9884 7473
            </Link>
          </Text>
        </View>
      </Page>
    </Document>
  );
}
