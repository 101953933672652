import { Box, Link, Typography } from '@material-ui/core';
import Button, { ButtonSize, ButtonType } from '../components/common/Button';
import { MyProperty, User, UserType } from '../api/models';
import { useDesktop, useMobile } from '../hooks/mobile';
import { useHistory, useLocation } from 'react-router-dom';

import AlertWrapper from '../components/AlertWrapper';
import BackIcon from '../images/chevron-left-blue.png';
import { Colors } from '../constants/colors';
import PurchaseLegalReviewButton from '../components/PurchaseLegalReviewButton';
import React from 'react';
import { isEmpty } from 'lodash';
import { useAlert } from '../contexts/Alert';
import useSWR from 'swr';
import { useSessionStore } from '../stores/Session';

export default function LegalReview(): JSX.Element {
  const isDesktop = useDesktop();
  const isMobile = useMobile();
  const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const contractId = query.get('contractId');
  const propertyId = query.get('propertyId');

  const headerHeight = isDesktop ? '88px' : '66px';

  const { refresh: refreshUserData, data: userData } = useSessionStore();
  const user = userData && (userData as User);

  const { data: myPropertiesData } = useSWR('/my-properties/');
  const myProperties =
    myPropertiesData && (myPropertiesData as Array<MyProperty>);
  const myPropertiesProperty =
    myProperties &&
    myProperties.find(
      (property) =>
        (propertyId && property.property_id === +propertyId) ||
        (contractId && property.contract_id === +contractId)
    );
  const reviews = myPropertiesProperty && myPropertiesProperty.reviews;

  // console.debug("reviews: ", reviews);

  const { setErrorMessage } = useAlert();

  const phantomStyle = {
    height: headerHeight,
  };

  function openContractWithPage(page: number) {
    const back = `${location.pathname}${location.search}`;

    window.open(
      `/app/sale-contract?contractId=${contractId}&page=${page}`,
      `coview-contract-${contractId}`
    );
  }

  return (
    <AlertWrapper>
      <Box bgcolor={Colors.BrandBackground4} minHeight='100vh'>
        {/* Phantom view so views on top of footer knows "it's there" and respects margins */}
        <Box style={phantomStyle} width='100%' />
        <Box
          height={headerHeight}
          alignItems='center'
          display='flex'
          boxShadow='0px 1px 0px rgba(255, 255, 255, 0.2)'
          pl={2}
          pr={2}
          justifyContent='space-between'
          zIndex={1}
          position='fixed'
          top={0}
          left={0}
          right={0}
          bgcolor={Colors.BrandBackground4}
        >
          <Button
            type={ButtonType.secondary}
            size={ButtonSize.small}
            leftImage={BackIcon}
            title='BACK'
            onClick={() => history.goBack()}
          />
        </Box>
        {!isEmpty(reviews) && (
          <Box
            bgcolor='white'
            minHeight='90vh'
            pt={isMobile ? 4 : 8}
            pb={isMobile ? 4 : 8}
            pl={isMobile ? 5.5 : 11}
            pr={isMobile ? 5.5 : 11}
          >
            <Typography variant='h5' style={{ marginBottom: '18px' }}>
              Legal review - {myPropertiesProperty?.address}
            </Typography>
            <ol>
              {reviews &&
                reviews.map((review) => {
                  const specialCondition =
                    review.section !== '0.0'
                      ? ` - Special Condition ${review.section}`
                      : '';

                  return (
                    <Box mt={4}>
                      <li>
                        <Typography>
                          <strong>
                            {review.title}
                            {specialCondition}
                          </strong>
                          {': '}
                          <div
                            dangerouslySetInnerHTML={{ __html: review.content }}
                          />
                        </Typography>
                        {review.page > 0 && (
                          <Box mt={2.25}>
                            <Link
                              style={{ fontWeight: 700, cursor: 'pointer' }}
                              onClick={() => openContractWithPage(review.page)}
                            >
                              Click to view
                            </Link>
                          </Box>
                        )}
                      </li>
                    </Box>
                  );
                })}
            </ol>
          </Box>
        )}
      </Box>
    </AlertWrapper>
  );
}
