import React from "react";
import { Box, Typography } from "@material-ui/core";
import HeroLayout from "../components/HeroLayout";
import ConfirmationIcon from "../images/Confirmation.png";
import { Colors } from "../constants/colors";
import { useMobile } from "../hooks/mobile";
import { useSessionStore, userCacheKey } from "../stores/Session";
import { User, UserType } from "../api/models";

export default function ThankYouWillNotify(): JSX.Element {
  const isMobile = useMobile();
  const { data: userData } = useSessionStore();
  const user = userData && (userData as User);

  return (
    <HeroLayout>
      <Box mt={isMobile ? 5 : 25} ml={isMobile ? 3 : 22} mr={isMobile ? 3 : 22}>
        <Box mb={4}>
          <img src={ConfirmationIcon} />
        </Box>
        <Typography
          variant={isMobile ? "h4" : "h3"}
          style={{ fontWeight: 700, marginBottom: "24px" }}
        >
          Thank you {user && user.first_name} {user && user.last_name}
        </Typography>
        <Typography variant="body1">
          We will send you an email notification when the legal review for this
          property is ready.
        </Typography>
      </Box>
    </HeroLayout>
  );
}
