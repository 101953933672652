import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import { useMobile } from "../../hooks/mobile";

interface Props {
  body: string;
  title: string;
  image: string;
  imageMarginTop?: string | number;
}

export default function ViewAccentColorModalItem({
  body,
  title,
  image,
  imageMarginTop = "32px",
}: Props) {
  const isMobile = useMobile();

  return (
    <Box
      bgcolor="rgba(255, 255, 255, 0.2)"
      borderRadius="16px"
      width={isMobile ? "100%" : "384px"}
      height={"453px"}
    >
      <Box m="32px" display="flex" flexDirection="column" alignItems="center">
        <Box
          width="100%"
          height="50px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="h6"
            style={{ fontWeight: 700, }}
          >
            {title}
          </Typography>
        </Box>

        <Typography
          variant="body1"
          style={{
            fontWeight: 700,
            lineHeight: "20px",
            marginBottom: imageMarginTop,
            textAlign: "center",
          }}
        >
          {body}
        </Typography>
        <img src={image} />
      </Box>
    </Box>
  );
}
