import React from "react";
import { Box, Typography } from "@material-ui/core";
import InfoIcon from "../images/info.png";
import { Colors } from "../constants/colors";

interface Props {
  message: string;
  bgcolor?: string;
}

export default function ({
  message,
  bgcolor = Colors.WarningBackground,
}: Props) {
  return (
    <Box
      width="100%"
      bgcolor={bgcolor}
      display="flex"
      flexDirection="row"
      alignItems="center"
    >
      <img
        src={InfoIcon}
        width="20px"
        height="20px"
        style={{ marginLeft: "14px" }}
      />

      <Typography
        variant="body2"
        style={{ marginLeft: "12px", marginTop: "10px", marginBottom: "10px" }}
      >
        {message}
      </Typography>
    </Box>
  );
}
