import React from "react";
import { Box, Typography, IconButton } from "@material-ui/core";
import TextField from "../common/TextField";
import { ContractSummary, LegalReviewRequest, Enquiry } from "../../api/models";
import { Colors } from "../../constants/colors";
import Button, { ButtonSize, ButtonType } from "../common/Button";
import { Draggable } from "react-beautiful-dnd";
import { debounce, isNil } from "lodash-es";
import StatusPill, { StatusPillType } from "../StatusPill";
import { generalEnquiriesText } from "./constants";
import { useMobile } from "../../hooks/mobile";
import ExpandIcon from "../../images/chevron-down.svg";
import Separator from "../../components/common/Separator";
import { debounceDelay } from "../../constants/common";

export enum EnquiryEditorBoxType {
  default,
  dismissed,
}

interface Props {
  type?: EnquiryEditorBoxType;
  enquiry: Enquiry;
  index: number;
  onTitleChanged?: (title: string) => unknown;
  onEnquiryChanged?: (enquiry: string) => unknown;
  onDismiss?: () => unknown;
  onAdd?: () => unknown;
  onDelete?: () => unknown;
  onRestore?: () => unknown;
  onViewComments: () => unknown;
}

interface TextFieldsProps {
  title: string;
  content: string;
  onTitle: (title: string) => unknown;
  onContent: (content: string) => unknown;
}

function TextFields({ title, content, onTitle, onContent }: TextFieldsProps) {
  const isMobile = useMobile();

  return (
    <Box width={isMobile ? undefined : "515px"}>
      <Box m={isMobile ? "16px" : undefined}>
        <TextField title="Title" value={title} onChange={onTitle} />

        <Box bgcolor={Colors.Grey5} borderRadius="8px" p="16px">
          <TextField
            title="Enquiry"
            value={content}
            onChange={onContent}
            multiline
          />
        </Box>
      </Box>
    </Box>
  );
}

function Description() {
  const isMobile = useMobile();

  const [show, setShow] = React.useState(false);

  if (isMobile) {
    return (
      <Box display="flex" flexDirection="column" whiteSpace="pre-line">
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          mb="16px"
          pl="16px"
          pr="16px"
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="body2" style={{ fontWeight: 700, flex: 1 }}>
              Description
            </Typography>
            <IconButton onClick={() => setShow(!show)}>
              <img src={ExpandIcon} />
            </IconButton>
          </Box>

          {show && (
            <Typography variant="body2">{generalEnquiriesText}</Typography>
          )}
        </Box>
        <Separator />
      </Box>
    );
  }

  return (
    <Box width={"515px"} ml="20px" mr="20px">
      <Box display="flex" flexDirection="column" flex={1}>
        <Typography variant="body1" style={{ fontWeight: 700 }}>
          Description
        </Typography>

        <Box height="8px" />

        <Typography variant="body2">{generalEnquiriesText}</Typography>
      </Box>
    </Box>
  );
}

interface HeaderProps {
  title: string;
}

function Header({ title }: HeaderProps) {
  const isMobile = useMobile();

  if (isMobile) {
    return (
      <Box
        mt="8px"
        height="55px"
        display="flex"
        flexDirection="row"
        alignItems="center"
        pl="16px"
        pr="16px"
      >
        <Box display="flex" flexDirection="row" alignItems="center" flex={1}>
          <Typography variant="body1" style={{ fontWeight: 700 }}>
            {title}
          </Typography>
        </Box>

        <StatusPill type={StatusPillType.pending} />
      </Box>
    );
  }

  return (
    <Box
      height="77px"
      boxShadow="0px 1px 0px #E0E0E0"
      display="flex"
      flexDirection="row"
      alignItems="center"
      pl="24px"
      pr="24px"
    >
      <Typography variant="h6" style={{ fontWeight: 700, flex: 1 }}>
        {title}
      </Typography>
      <StatusPill type={StatusPillType.pending} />
    </Box>
  );
}

function _EnquiryEditorBox({
  enquiry,
  index,
  onTitleChanged,
  onEnquiryChanged,
  onDismiss,
  onAdd,
  onDelete,
  onRestore,
  type = EnquiryEditorBoxType.default,
  onViewComments,
}: Props) {
  const isMobile = useMobile();

  const [title, setTitle] = React.useState(enquiry.title);
  const [content, setContent] = React.useState(enquiry.enquiry);

  // console.debug("rendering index: ", index);

  // console.debug("index: ", index);
  // console.debug("request: ", request);
  // console.debug("title: ", title);
  React.useEffect(() => {
    if (enquiry) {
      console.debug("setting with enquiry: ", enquiry);
      setTitle(enquiry.title);
      setContent(enquiry.enquiry);
    }
  }, [enquiry]);

  const titleChanged = (text: string) => {
    onTitleChanged && onTitleChanged(text);
  };

  const debouncedTitleChanged = React.useCallback(
    debounce(titleChanged, debounceDelay),
    []
  );

  const onTitle = (text) => {
    setTitle(text);

    debouncedTitleChanged(text);
  };

  const contentChanged = (text: string) => {
    onEnquiryChanged && onEnquiryChanged(text);
  };

  const debouncedContentChanged = React.useCallback(
    debounce(contentChanged, debounceDelay),
    []
  );

  const onContent = (text) => {
    setContent(text);

    debouncedContentChanged(text);
  };

  const _commentsCount = () => {
    if (enquiry.p_commentsCount > 0) {
      return enquiry.p_commentsCount;
    }

    return enquiry.comments_count || 0;
  };

  const _messagesCount = () => {
    if (enquiry.p_messagesCount > 0) {
      return enquiry.p_messagesCount;
    }

    return enquiry.messages_count || 0;
  };

  const commentsCount = _commentsCount();
  const messagesCount = _messagesCount();

  return (
    <Draggable draggableId={`enquiry-${enquiry.pk}-${index}`} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Box
            border="1px solid #E1EAFF"
            bgcolor="white"
            borderRadius="16px"
            m="10px"
            display="flex"
            flexDirection="column"
            width={isMobile ? "95vw" : "1097px"}
          >
            <Header title={enquiry.title} />

            {isMobile && (
              <Box display="flex" flexDirection="column">
                <Description />

                <TextFields
                  title={title}
                  content={content}
                  onTitle={onTitle}
                  onContent={onContent}
                />
              </Box>
            )}

            {!isMobile && (
              <Box display="flex" flexDirection="row" p="24px">
                <TextFields
                  title={title}
                  content={content}
                  onTitle={onTitle}
                  onContent={onContent}
                />

                <Description />
              </Box>
            )}

            <Box
              display="flex"
              flexDirection="row"
              boxShadow="0px -1px 0px #E0E0E0"
              height="74px"
              alignItems="center"
              justifyContent="space-between"
              pl="16px"
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <Button
                  title={isMobile ? "COMMENTS" : "VIEW COMMENTS"}
                  type={ButtonType.transparent}
                  size={ButtonSize.medium}
                  onClick={onViewComments}
                  letterSpacing="2px"
                  width={isMobile ? "115px" : "179px"}
                />
                {!isMobile && (
                  <Typography variant="body2" style={{ marginLeft: "8px" }}>
                    {commentsCount + messagesCount} Comments
                  </Typography>
                )}
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                pr="16px"
              >
                {type === EnquiryEditorBoxType.default && (
                  <>
                    <Button
                      type={ButtonType.destructive}
                      size={ButtonSize.small}
                      title="DISMISS"
                      width="93px"
                      onClick={onDismiss}
                    />
                    <Box width="8px" />
                    <Button
                      type={ButtonType.primary}
                      size={ButtonSize.small}
                      title="ADD"
                      width="58px"
                      onClick={onAdd}
                    />
                  </>
                )}

                {type === EnquiryEditorBoxType.dismissed && (
                  <>
                    <Button
                      type={ButtonType.destructive}
                      size={ButtonSize.small}
                      title="DELETE"
                      width="105px"
                      onClick={onDelete}
                    />
                    <Box width="8px" />
                    <Button
                      type={ButtonType.primary}
                      size={ButtonSize.small}
                      title="RESTORE"
                      width="120px"
                      onClick={onRestore}
                    />
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </div>
      )}
    </Draggable>
  );
}

const EnquiryEditorBox = React.memo(
  _EnquiryEditorBox,
  (prevProps, nextProps) => {
    return (
      prevProps.enquiry.title === nextProps.enquiry.title &&
      prevProps.enquiry.enquiry === nextProps.enquiry.enquiry &&
      prevProps.enquiry.enquiry_status === nextProps.enquiry.enquiry_status &&
      prevProps.enquiry.status === nextProps.enquiry.status &&
      prevProps.enquiry.p_commentsCount === nextProps.enquiry.p_commentsCount &&
      prevProps.enquiry.p_messagesCount === nextProps.enquiry.p_messagesCount
    );
  }
);

export default EnquiryEditorBox;
