import React from "react";
import { Box, ButtonBase } from "@material-ui/core";
import BLUELOGO from "../../images/BlueLogo.png";
import CloseButton from "../common/CloseButtonBordered";
import { useMobile } from "../../hooks/mobile";
import { Colors } from "../../constants/colors";
import BackIcon from "../../images/back-mobile.svg";

interface Props {
  onClose: () => unknown;
  onBack?: () => unknown;
}

interface BackButtonProps {
  onClick: () => unknown;
}

function BackButton({ onClick }: BackButtonProps) {
  return (
    <ButtonBase onClick={onClick}>
      <Box
        width={"116px"}
        height="44px"
        alignItems="center"
        justifyContent="center"
        bgcolor={"#ffffff"}
        borderRadius="6px"
        display="flex"
        border="1px solid #ECF2FB"
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <img src={BackIcon} />
          <Box
            fontFamily="GT Walsheim Pro"
            fontWeight={700}
            fontSize="14px"
            lineHeight="18px"
            letterSpacing="2px"
            color={Colors.BrandPrimary}
            ml="8px"
          >
            BACK
          </Box>
        </Box>
      </Box>
    </ButtonBase>
  );
}

export default function Header({ onClose, onBack }: Props) {
  const isMobile = useMobile();

  return (
    <Box
      width="100vw"
      height="80px"
      boxShadow="0px 1px 0px #ECF2FB"
      bgcolor="white"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <ButtonBase
        onClick={() => window.location.assign(window.location.origin)}
      >
        <img
          src={BLUELOGO}
          height="32px"
          style={{ marginLeft: "24px", marginRight: "12px" }}
        />
      </ButtonBase>

      <Box mr="19px" display="flex" flexDirection={"row"}>
        {!isMobile && onBack && (
          <Box mr="16px">
            <BackButton onClick={onBack} />
          </Box>
        )}

        <CloseButton onClick={onClose} />
      </Box>
    </Box>
  );
}
