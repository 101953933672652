import React from "react";
import { Box, Typography } from "@material-ui/core";

interface Props {
  title: string;
  width?: string | number;
}

export default function PageSection({ title, width = "100%" }: Props) {
  return (
    <Box pt="24px" width={width}>
      <Typography
        variant="overline"
        style={{ fontWeight: 700, letterSpacing: "10%" }}
      >
        {title}
      </Typography>
    </Box>
  );
}
