import { Box, ButtonBase, Typography } from '@material-ui/core';
import Button, { ButtonSize, ButtonType } from '../components/common/Button';
import { Contract, ContractWorkflowStatus, UserType } from '../api/models';
import { Document, Page, pdfjs } from 'react-pdf';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import api from "../api/instance";
import { AxiosError } from "axios";

import AlertWrapper from '../components/AlertWrapper';
import BackIcon from '../images/chevron-left-blue.png';
import { Colors } from '../constants/colors';
import ContractPendingButton from '../components/ContractPendingButton';
import PdfBackIcon from '../images/pdf-back.png';
import PdfForwardIcon from '../images/pdf-forward.png';
import PurchaseLegalReviewButton from '../components/PurchaseLegalReviewButton';
import SampleContract from '../pdf/SampleContract.pdf';
import { get } from 'lodash';
import { throttle } from 'lodash';
import { useDesktop } from '../hooks/mobile';
import useSWR from 'swr';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function ContractForSale(): JSX.Element {
  const isDesktop = useDesktop();
  const history = useHistory();
  const location = useLocation();

  const locationState = location && location.state && location.state;

  const query = new URLSearchParams(location.search);
  const contractId = query.get('contractId');
  const sample = query.get('sample');
  const page = query.get('page');
  const forPurchase = query.get('forPurchase');
  const queryBack = query.get('back');
  const queryGoToURL = query.get('gotoURL')
  const User = query.get('user');


  const back =
    (locationState && get(locationState, 'back')) ||
    (queryBack && decodeURIComponent(queryBack));

  const gotoURL = 
    (locationState && get(locationState, 'gotoURL')) ||
    (queryGoToURL && decodeURIComponent(queryGoToURL));

  const userData = 
      (locationState && get(locationState, 'user')) || 
      (User && decodeURIComponent(User));


  const isSample = sample === 'true';

  const { data: contractData } = useSWR(
    contractId && `/view-contracts/${contractId}/`
  );
  const contract = contractData && (contractData as Contract);

  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfWidth, setPdfWidth] = useState<number>(window.innerWidth * 0.7);
  // const [pdfHeight, setPdfHeight] = useState<number>(window.innerHeight * 0.7);

  const headerHeight = isDesktop ? '88px' : '66px';
  const warningHeight = isDesktop ? '40px' : '38px';

  React.useEffect(() => {
    window.addEventListener(
      'resize',
      throttle(() => setPdfSize(), 500)
    );
  }, []);

  React.useEffect(() => {
      api
      .post(`/view-contract-sale/${contractId}/`)
      .then(response => response)
      .catch((error: AxiosError) => {
      });
  })

  React.useEffect(() => {
    if (page) {
      setPageNumber(+page);
    }
  }, [page]);

  function setPdfSize() {
    setPdfWidth(window.innerWidth * 0.8);
    // setPdfHeight(window.innerHeight * 0.7);
  }

  return (
    <AlertWrapper>
      <Box bgcolor={Colors.BrandBackground4} minHeight='100vh'>
        <Box height={headerHeight} width='100%' />
        <Box
          height={headerHeight}
          alignItems='center'
          display='flex'
          boxShadow='0px 1px 0px rgba(255, 255, 255, 0.2)'
          pl={2}
          pr={2}
          justifyContent='space-between'
          zIndex={1}
          position='fixed'
          left={0}
          right={0}
          top={0}
          bgcolor={Colors.BrandBackground4}
        >
          <Box display='flex' flexDirection='row'>
            {back && (
              <Button
                type={ButtonType.secondary}
                size={ButtonSize.small}
                leftImage={BackIcon}
                title='BACK'
                onClick={() => history.goBack()}
              />
            )}
            {gotoURL && (
              <Button
                type={ButtonType.secondary}
                size={ButtonSize.small}
                leftImage={BackIcon}
                title='BACK'
                onClick={() => history.push(gotoURL)}
              />
            )}
            {!isSample && contract && (
              <React.Fragment>
                <Box ml={2} />
                <Button
                  type={ButtonType.primary}
                  size={ButtonSize.small}
                  title='DOWNLOAD'
                  onClick={() => contract && window.open(contract.file)}
                />
              </React.Fragment>
            )}
          </Box>
          {isDesktop &&
            contract &&
            contract.workflow_status === ContractWorkflowStatus.Reviewed &&
            forPurchase === 'true' && (
              <PurchaseLegalReviewButton
                width='305px'
                authData={userData}
                propertyId={contract && contract.property_id}
              />
            )}
          {isDesktop &&
            contract &&
            contract.workflow_status !== ContractWorkflowStatus.Reviewed &&
            forPurchase === 'true' && (
              <ContractPendingButton
                width='400px'
                propertyId={contract && contract.property_id}
                contract={contract && contract.file ? true : false}
              />
            )}
        </Box>
        {isSample && (
          <Box
            bgcolor={Colors.WarningBackground}
            width='100%'
            height={warningHeight}
            display='flex'
            alignItems='center'
            justifyContent='center'
            zIndex={1}
            position='fixed'
            top={headerHeight}
            left={0}
            right={0}
          >
            <Typography variant={isDesktop ? 'h6' : 'body1'}>
              This is not a real contract of sale
            </Typography>
          </Box>
        )}
        <Box
          mt={6}
          pb={6}
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            color='white'
          >
            <Box display='flex' flexDirection='row' alignItems='center'>
              <Box width='44px' height='44px'>
                {pageNumber > 1 && (
                  <ButtonBase
                    onClick={() => setPageNumber(pageNumber - 1)}
                    style={{ zIndex: 1 }}
                  >
                    <img src={PdfBackIcon} />
                  </ButtonBase>
                )}
              </Box>
              <Box
                width={pdfWidth}
                display='flex'
                alignItems='center'
                justifyContent='center'
              >
                {!isSample && contract && (
                  <Document
                    file={contract.file}
                    onLoadSuccess={({ numPages }) => {
                      setNumPages(numPages);
                    }}
                  >
                    <Page pageNumber={pageNumber} width={pdfWidth} />
                  </Document>
                )}
                {isSample && (
                  <Document
                    file={SampleContract}
                    onLoadSuccess={({ numPages }) => {
                      setNumPages(numPages);
                    }}
                  >
                    <Page pageNumber={pageNumber} width={pdfWidth} />
                  </Document>
                )}
              </Box>

              <Box width='44px' height='44px'>
                {pageNumber < numPages && (
                  <ButtonBase
                    onClick={() => setPageNumber(pageNumber + 1)}
                    style={{ zIndex: 1 }}
                  >
                    <img src={PdfForwardIcon} />
                  </ButtonBase>
                )}
              </Box>
            </Box>

            {numPages > 0 && (
              <Typography
                variant='caption'
                style={{
                  color: 'white',
                  marginTop: '30px',
                }}
              >
                {pageNumber} / {numPages}
              </Typography>
            )}
          </Box>
        </Box>
        {!isDesktop &&
          contract &&
          contract.workflow_status === ContractWorkflowStatus.Reviewed &&
          forPurchase === 'true' && (
            <Box
              height='96px'
              boxShadow='0px -1px 0px rgba(255, 255, 255, 0.2)'
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
              <Box mt={2} mb={2} ml={3} mr={3} width='100%' maxWidth='375px'>
                <PurchaseLegalReviewButton
                  width='100%'
                  propertyId={contract && contract.property_id}
                  authData={userData}
                />
              </Box>
            </Box>
          )}
        {!isDesktop &&
          contract &&
          contract.workflow_status !== ContractWorkflowStatus.Reviewed &&
          forPurchase === 'true' && (
            <Box
              height='96px'
              boxShadow='0px -1px 0px rgba(255, 255, 255, 0.2)'
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
              <Box mt={2} mb={2} ml={3} mr={3} width='100%' maxWidth='375px'>
                <ContractPendingButton
                  width='100%'
                  propertyId={contract && contract.property_id}
                />
              </Box>
            </Box>
          )}
      </Box>
    </AlertWrapper>
  );
}
