import { Box, Typography, IconButton } from "@material-ui/core";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import {
  BuyerSellerStatus,
  LegalReviewRequest,
  LegalReviewRequestStage,
  LegalReviewRequestStatus,
} from "../../api/models";
import { Colors } from "../../constants/colors";
import { useMobile } from "../../hooks/mobile";
import DraggableIcon from "../../images/draggable-icon.svg";
import Button, { ButtonSize, ButtonType } from "../common/Button";
import StatusPill, { StatusPillType } from "../StatusPill";
import ExpandIcon from "../../images/chevron-down.svg";
import { customRequestText } from "./constants";
import Separator from "../common/Separator";
import { debounce, isEmpty, isNil } from "lodash-es";
import { debounceDelay } from "../../constants/common";
import moment from "moment";

interface Props {
  request: LegalReviewRequest;
  index: number;
  onEditRequest?: () => unknown;
  onViewComments: () => unknown;
  onRemove?: () => unknown;
  onNotAgree?: () => unknown;
  onAgree?: () => unknown;
  onMessage?: (message: string) => unknown;
  onNewRequest?: () => unknown;
  onViewContract?: () => unknown;
}

const _statusPillType = (request: LegalReviewRequest) => {
  switch (request.request_status) {
    case LegalReviewRequestStage.buyerAgreed:
    case LegalReviewRequestStage.sellerAgreed:
      return StatusPillType.agreed;
    case LegalReviewRequestStage.buyerNotAgreed:
    case LegalReviewRequestStage.sellerNotAgreed:
      return StatusPillType.notAgreed;
    case LegalReviewRequestStage.pending:
      return StatusPillType.pending;
    case LegalReviewRequestStage.alternativeSuggested:
      return StatusPillType.alternativeSuggested;
    case LegalReviewRequestStage.answered:
      return StatusPillType.answered;
    case LegalReviewRequestStage.deleted:
      return StatusPillType.deleted;
  }
};

interface HeaderProps {
  request: LegalReviewRequest;
}

function Header({ request }: HeaderProps) {
  const isMobile = useMobile();
  const statusPillType = _statusPillType(request);

  const [showSummary, setShowSummary] = React.useState(false);

  if (isMobile) {
    return (
      <Box
        boxShadow="0px 1px 0px #E0E0E0"
        display="flex"
        flexDirection="column"
        pl="24px"
        pr="24px"
      >
        <Box display="flex" flexDirection="row" alignItems="center" mt="18px">
          <Box display="flex" flexDirection="row" alignItems="center" flex={1}>
            <Typography
              variant={isMobile ? "body1" : "h6"}
              style={{
                fontWeight: 700,
              }}
            >
              {request.title}
            </Typography>
          </Box>

          <StatusPill type={statusPillType} />
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          mt="8px"
          mb="4px"
        >
          <Typography variant="body2" style={{ fontWeight: 700, flex: 1 }}>
            Summary
          </Typography>

          <IconButton onClick={() => setShowSummary(!showSummary)}>
            <img src={ExpandIcon} />
          </IconButton>
        </Box>

        {showSummary && (
          <Box mb="16px">
            <Typography variant="body2">
              {request.legal_review_summary
                ? request.legal_review_summary?.content
                : customRequestText}
            </Typography>
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      flex={1}
      pl="24px"
      pr="24px"
      boxShadow="0px 1px 0px #E0E0E0"
      minHeight="77px"
    >
      <Box display="flex" flexDirection="row" alignItems="center" flex={1}>
        <img src={DraggableIcon} />
        <Typography variant="h6" style={{ fontWeight: 700, marginLeft: "8px" }}>
          {request.title}
        </Typography>
      </Box>

      <StatusPill type={statusPillType} />
    </Box>
  );
}

interface ContentProps {
  request: LegalReviewRequest;
  onMessageChanged?: (message: string) => unknown;
  margins?: boolean;
}

function Content({ request, onMessageChanged, margins = true }: ContentProps) {
  const isMobile = useMobile();

  const [message, setMessage] = React.useState(request.buyer_message);

  const messageChanged = (text: string) => {
    onMessageChanged && onMessageChanged(text);
  };

  const debouncedMessageChanged = React.useCallback(
    debounce(messageChanged, debounceDelay),
    []
  );

  const onMessage = (text) => {
    setMessage(text);

    debouncedMessageChanged(text);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width={isMobile ? "100%" : "483px"}
      m={margins ? "24px" : undefined}
    >
      <Box p="8px" mb="16px">
        <Typography
          variant="body1"
          style={{ fontWeight: 700, marginBottom: "8px" }}
        >
          Amendment Request
        </Typography>
        <Typography variant="body2">{request.description}</Typography>
      </Box>

      <Box
        bgcolor={
          request.request_status ===
          LegalReviewRequestStage.alternativeSuggested
            ? Colors.Grey4
            : Colors.BrandBackground3
        }
        borderRadius="4px"
        p="8px"
      >
        <Typography
          variant="body1"
          style={{ fontWeight: 700, marginBottom: "8px" }}
        >
          Proposed change to contract
        </Typography>
        <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
          {request.request_status === LegalReviewRequestStage.buyerAgreed
            ? request.alternate_change
            : request.propose}
        </Typography>
      </Box>

      {request.request_status ===
        LegalReviewRequestStage.alternativeSuggested && (
        <Box
          bgcolor={Colors.BrandBackground3}
          borderRadius="4px"
          p="8px"
          mt="8px"
        >
          <Typography
            variant="body1"
            style={{ fontWeight: 700, marginBottom: "8px" }}
          >
            Alternative change suggested
          </Typography>
          <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
            {request.alternate_change}
          </Typography>
        </Box>
      )}

      {request.seller_responded &&
        request.seller_message &&
        request.request_status ===
          LegalReviewRequestStage.alternativeSuggested && (
          <Box p="8px" mt="8px">
            <Typography
              variant="body1"
              style={{ fontWeight: 700, marginBottom: "8px" }}
            >
              Message from seller
            </Typography>
            <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
              {request.seller_message}
            </Typography>
          </Box>
        )}

      {request.request_status ===
        LegalReviewRequestStage.alternativeSuggested && (
        <Box display="flex" flexDirection="column" p="8px">
          <Separator mt="16px" mb="16px" />
          <Typography
            variant="body2"
            style={{ fontWeight: 700, marginBottom: "10px" }}
          >
            Message (optional)
          </Typography>

          <textarea
            style={{
              minHeight: "90px",
              fontFamily: "GT Walsheim Pro",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              border: "1px solid #E8E8E8",
              padding: "16px",
              flex: 1,
            }}
            value={message}
            onChange={(event) => onMessage(event.target.value)}
            placeholder="Enter optional message"
          />
        </Box>
      )}

      {(request.request_status === LegalReviewRequestStage.buyerAgreed ||
        request.request_status === LegalReviewRequestStage.sellerAgreed ||
        request.request_status === LegalReviewRequestStage.buyerNotAgreed ||
        request.request_status === LegalReviewRequestStage.sellerNotAgreed) && (
        <Box display="flex" flexDirection="column" p="8px">
          <Separator mt="16px" mb="16px" />
          <Typography
            variant="body2"
            style={{ fontWeight: 700, marginBottom: "10px" }}
          >
            Message (optional)
          </Typography>

          <Typography
            variant="body2"
            style={{
              color: isEmpty(request.seller_message) ? Colors.Grey3 : undefined,
            }}
          >
            {isEmpty(request.buyer_message)
              ? "No message entered"
              : request.buyer_message}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

interface SummaryProps {
  request: LegalReviewRequest;
  onViewContract?: () => unknown;
}

function Summary({ request, onViewContract }: SummaryProps) {
  if (request.legal_review_summary) {
    return (
      <Box width="515px" ml="52px" mt="32px" mr="23px" mb="24px">
        <Typography variant="body2" style={{ fontWeight: 700 }}>
          Summary
        </Typography>
        <Typography
          variant="body2"
          style={{
            marginTop: "16px",
            marginBottom: "16px",
          }}
        >
          {request.legal_review_summary?.content}
        </Typography>
        <Button
          title="VIEW IN CONTRACT"
          type={ButtonType.secondary}
          size={ButtonSize.small}
          onClick={onViewContract}
        />
      </Box>
    );
  }

  return (
    <Box
      width="515px"
      ml="52px"
      mt="32px"
      mr="23px"
      mb="24px"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="body2">{customRequestText}</Typography>
    </Box>
  );
}

interface FooterProps {
  request: LegalReviewRequest;
  onEditRequest?: () => unknown;
  onViewComments: () => unknown;
  onRemove?: () => unknown;
  onNotAgree?: () => unknown;
  onAgree?: () => unknown;
  onNewRequest?: () => unknown;
}

function Footer({
  request,
  onEditRequest,
  onViewComments,
  onRemove,
  onNotAgree,
  onAgree,
  onNewRequest,
}: FooterProps) {
  const isMobile = useMobile();

  const _commentsCount = () => {
    if (request.p_commentsCount > 0) {
      return request.p_commentsCount;
    }

    return request.comments_count || 0;
  };

  const _messagesCount = () => {
    if (request.p_messagesCount > 0) {
      return request.p_messagesCount;
    }

    return request.messages_count || 0;
  };

  const commentsCount = _commentsCount();
  const messagesCount = _messagesCount();

  const isCompleted = request.buyer_status === BuyerSellerStatus.Completed;
  const isSentBefore = !isNil(request.sent_on);
  const isSent = request.is_sent;

  const isSentForTimestampPurpose =
    request.buyer_status === BuyerSellerStatus.Completed
      ? request.sent_on
      : request.is_sent;

  return (
    <Box
      display="flex"
      flexDirection="row"
      boxShadow="0px -1px 0px #E0E0E0"
      height="74px"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="row" alignItems="center" pl="12px">
        <Button
          title={isMobile ? "COMMENTS" : "VIEW COMMENTS"}
          type={ButtonType.transparent}
          size={ButtonSize.medium}
          onClick={onViewComments}
          letterSpacing="2px"
          width={isMobile ? "115px" : "179px"}
        />
        {!isMobile && (
          <Typography variant="body2" style={{ marginLeft: "8px" }}>
            {commentsCount + messagesCount} Comments
          </Typography>
        )}
      </Box>

      <Box display="flex" flexDirection="row" alignItems="center" pr="16px">
        {!isMobile && isSentForTimestampPurpose && (
          <Typography
            variant="body2"
            style={{ color: Colors.Grey3, marginRight: "16px" }}
          >
            Sent on {moment(request.sent_on).format("D MMM [at] h:mm A")}
          </Typography>
        )}

        {!isMobile && !isSentForTimestampPurpose && (
          <Typography
            variant="body2"
            style={{ color: Colors.Grey3, marginRight: "16px" }}
          >
            Not sent
          </Typography>
        )}

        {!isSent &&
          request.request_status === LegalReviewRequestStage.pending && (
            <Button
              type={ButtonType.secondary}
              size={ButtonSize.medium}
              title="EDIT REQUEST"
              width="163px"
              onClick={onEditRequest}
            />
          )}

        {isSent && !request.seller_responded && (
          <Button
            type={ButtonType.destructive}
            size={ButtonSize.medium}
            title="DELETE"
            width="105px"
            onClick={onRemove}
          />
        )}

        {(request.request_status === LegalReviewRequestStage.sellerAgreed ||
          request.request_status === LegalReviewRequestStage.answered ||
          request.request_status === LegalReviewRequestStage.sellerNotAgreed ||
          request.buyer_status === BuyerSellerStatus.Completed) && (
          <Button
            type={ButtonType.destructive}
            size={ButtonSize.medium}
            title="REMOVE"
            width="115px"
            onClick={onRemove}
          />
        )}

        {request.request_status ===
          LegalReviewRequestStage.alternativeSuggested && (
          <>
            <Button
              type={ButtonType.destructive}
              size={ButtonSize.medium}
              title="NOT AGREE"
              width={isMobile ? "100%" : "141px"}
              onClick={onNotAgree}
            />
            <Box width="8px" />
            <Button
              type={ButtonType.primary}
              size={ButtonSize.medium}
              title="AGREE"
              width="94px"
              onClick={onAgree}
            />
          </>
        )}

        {/* {request.request_status === LegalReviewRequestStage.sellerNotAgreed && (
          <>
            <Button
              type={ButtonType.primary}
              size={ButtonSize.medium}
              title="NEW REQUEST"
              width="156px"
              onClick={onNewRequest}
            />
          </>
        )} */}

        {!isSent &&
          !isCompleted &&
          (request.request_status === LegalReviewRequestStage.buyerNotAgreed ||
            request.request_status === LegalReviewRequestStage.buyerAgreed) && (
            <>
              <Button
                type={ButtonType.secondary}
                size={ButtonSize.medium}
                title="EDIT REQUEST"
                width="163px"
                onClick={onEditRequest}
              />
            </>
          )}
      </Box>
    </Box>
  );
}

export default function RequestItem({
  request,
  index,
  onEditRequest,
  onViewComments,
  onRemove,
  onNotAgree,
  onAgree,
  onMessage,
  onNewRequest,
  onViewContract,
}: Props) {
  const isMobile = useMobile();

  const [showFirstVersion, setShowFirstVersion] = React.useState(false);

  return (
    <Draggable
      draggableId={`request-item-${request.pk}-${index}`}
      index={index}
    >
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Box
            border="1px solid #E1EAFF"
            bgcolor="white"
            borderRadius="16px"
            m="10px"
            display="flex"
            flexDirection="column"
            width={isMobile ? "95vw" : "1097px"}
          >
            <Header request={request} />

            <Box display="flex" flexDirection="row">
              {isEmpty(request.old_versions) && (
                <Content request={request} onMessageChanged={onMessage} />
              )}

              {!isEmpty(request.old_versions) && (
                <Box m="24px" width={isMobile ? "100%" : "515px"}>
                  <Box bgcolor={Colors.Grey5} borderRadius="22px" mb="24px">
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      pl="16px"
                    >
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: 700,
                          flex: 1,
                        }}
                      >
                        First version
                      </Typography>

                      <IconButton
                        onClick={() => setShowFirstVersion(!showFirstVersion)}
                      >
                        <img src={ExpandIcon} />
                      </IconButton>
                    </Box>

                    {showFirstVersion && (
                      <Box pl="16px" pr="16px" pb="16px">
                        <Content
                          request={request.old_versions[0]}
                          margins={false}
                        />
                      </Box>
                    )}
                  </Box>
                  <Box border="1px solid #E8E8E8" borderRadius="22px" p="16px">
                    <Typography
                      variant="body1"
                      style={{ fontWeight: 700, marginBottom: "16px" }}
                    >
                      Second version
                    </Typography>
                    <Content
                      request={request}
                      onMessageChanged={onMessage}
                      margins={false}
                    />
                  </Box>
                </Box>
              )}

              {!isMobile && (
                <Summary request={request} onViewContract={onViewContract} />
              )}
            </Box>
            <Footer
              request={request}
              onViewComments={onViewComments}
              onEditRequest={onEditRequest}
              onRemove={onRemove}
              onNotAgree={onNotAgree}
              onAgree={onAgree}
              onNewRequest={onNewRequest}
            />
          </Box>
        </div>
      )}
    </Draggable>
  );
}
