import React from "react";
import { Box, Typography, Link } from "@material-ui/core";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useMobile } from "../hooks/mobile";
import AlertWrapper from "../components/AlertWrapper";
import AuthenticatedWrapper from "../components/AuthenticatedWrapper";
import MyLawyerLandingPageFooter from "../components/MyLandingPage/MyLawyerLandingPageFooter";
import useSWR from "swr";
import { addressForProperty, Property } from "../api/models";
import OrganisationLogo from "../components/OrganisationLogo";
import { Colors } from "../constants/colors";

const height = "96px";

const phantomStyle = {
  height,
};

const footerStyle = {
  height,
  backgroundColor: "#FFFFFF",
  bottom: 36,
};

interface Params {
  uuid?: string;
}

export default function MyThankYouScreen(): JSX.Element {
  const history = useHistory();
  const location = useLocation();
  const isMobile = useMobile();

  const { uuid } = useParams<Params>();

  const query = new URLSearchParams(location.search);
  const propertyId = query.get("propertyId");

  const { data: propertyData } = useSWR(
    propertyId ? `/view-properties/${propertyId}/` : null
  );
  const property = propertyData && (propertyData as Property);

  //   const { data: orgData } = useSWR(`/organization/${uuid}/`);
  //   const organisation = orgData ? (orgData as Organisation) : undefined;

  //   const { data: userData, refresh: refreshUserData } = useSessionStore();
  //   const user = userData && (userData as User);

  function Header() {
    return (
      <Box
        width={isMobile ? "auto" : "700px"}
        mt={isMobile ? 3 : "215px"}
        mb={isMobile ? 3 : "100px"}
        pl={isMobile ? 3 : 0}
        pr={isMobile ? 3 : 0}
        alignSelf="flex-start"
        textAlign="center"
      >
        <Typography variant="h3" style={{ fontWeight: 700 }}>
          Thank you for submitting the contract for{" "}
          {property && addressForProperty(property)}
        </Typography>
        <Typography
          variant={isMobile ? "h5" : "h5"}
          style={{
            marginTop: "48px",
            fontWeight: 400,
            width: "446px",
            display: "inline-flex",
            marginBottom: "48px",
          }}
        >
          We'll begin our contract review and notify you when it is ready to
          view on Contrax.
        </Typography>

        <Typography
          variant={isMobile ? "h5" : "h5"}
          style={{
            fontWeight: 400,
            width: "446px",
            margin: "auto",
            // display: "inline-flex",
          }}
        >
          If you're new to Contrax, check your email and click to verify your
          account. Otherwise,{" "}
          <Link
            style={{ color: Colors.Link, cursor: "pointer" }}
            onClick={() => {
              history.push("/dashboard");
            }}
          >
            go to your Contrax dashboard
          </Link>
          .
        </Typography>
      </Box>
    );
  }

  return (
    <AlertWrapper>
      {!isMobile && (
        <>
          <Box
            mt={1}
            mb={4}
            display="flex"
            flexDirection="row"
            width="100%"
            height={88}
            position="absolute"
            alignItems="center"
            justifyContent="center"
          >
            <Box ml={0}>
              <OrganisationLogo uuid={uuid} />
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Box
              display="flex"
              flexDirection="column"
              marginLeft="auto"
              marginRight="auto"
              alignItems={isMobile ? "center" : undefined}
              mb={8}
              width="725px"
            >
              <Header />
            </Box>
          </Box>
          <MyLawyerLandingPageFooter />
        </>
      )}

      {isMobile && (
        <>
          <Box
            mb={4}
            display="flex"
            flexDirection="row"
            width="100%"
            height={72}
            alignItems="center"
            justifyContent="center"
          >
            <Box mr={3}>
              <OrganisationLogo uuid={uuid} />
            </Box>
          </Box>
          <Box ml={3.25} mr={3.25}>
            <Box
              width="auto"
              mt={4}
              mb={4}
              alignSelf="flex-start"
              textAlign="center"
            >
              <Typography
                variant="h5"
                style={{ fontWeight: 700, marginBottom: "32px" }}
              >
                Thank you for submitting the contract for{" "}
                {property && addressForProperty(property)}
              </Typography>
              <Typography variant="body2" style={{ marginBottom: "32px" }}>
                We'll begin our contract review and notify you when it is ready
                to view on Contrax.
              </Typography>

              <Typography variant="body2" style={{ marginBottom: "32px" }}>
                In the meantime, check your email and click to verify your
                account and access your dashboard.
              </Typography>
            </Box>
          </Box>
          <Box style={phantomStyle} width="100%" />
          <Box
            style={footerStyle}
            flexDirection="row"
            //   display="flex"
            flex={1}
            alignItems="center"
            zIndex={1}
            paddingBottom="10px"
            position="fixed"
            left={0}
            right={0}
            justifyContent="space-between"
          >
            <MyLawyerLandingPageFooter />
          </Box>
        </>
      )}
    </AlertWrapper>
  );
}
