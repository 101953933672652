import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useMobile } from "../hooks/mobile";
import MyLandingPageHeader from "../components/MyLandingPage/MyLawyerLandingPageHeader";
import { Box, Typography } from "@material-ui/core";
import { Colors } from "../constants/colors";
import Button, { ButtonType, ButtonSize } from "../components/common/Button";
import landingPage from "../images/landingPage.png";
import MyLawyerLandingPageFooter from "../components/MyLandingPage/MyLawyerLandingPageFooter";

interface Params {
  uuid?: string;
}

export default function MyLandingPage() {
  const isMobile = useMobile();
  const history = useHistory();

  const sidePadding = isMobile ? "27px" : "172px";

  const { uuid } = useParams<Params>();

  // console.debug("***uuid: ", uuid);
  // console.debug("organisation: ", organisation);

  const onUploadContract = () => {
    history.push(`/org/${uuid}/my-addproperty`);
  };

  return (
    <>
      <MyLandingPageHeader uuid={uuid} />

      {!isMobile && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            pl={sidePadding}
            pr={sidePadding}
          >
            <Box mt="184px" height="539px" width="539px">
              <Typography
                variant="h3"
                style={{ fontWeight: 700, marginBottom: "8px" }}
              >
                Get contract review and track progress
              </Typography>
              <Typography
                variant="h5"
                style={{ fontWeight: 400, marginBottom: "32px" }}
              >
                Upload your contract, create an account, and we'll get started
                on your review immediately.
              </Typography>
              <Box mt={2}>
                <Button
                  type={ButtonType.dark}
                  size={ButtonSize.medium}
                  title="Upload a contract now"
                  onClick={onUploadContract}
                />
              </Box>
            </Box>
            <Box>
              <img
                src={landingPage}
                height="539px"
                width="539px"
                style={{ marginLeft: "19px" }}
                alt="Preview of Contrax Logo"
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor={Colors.Grey5}
            width="100%"
          >
            <Box>
              <Typography
                variant="h3"
                style={{
                  fontWeight: 700,
                  marginBottom: "64px",
                  marginTop: "80px",
                }}
              >
                How it works
              </Typography>
            </Box>

            <Box
              display="flex"
              flexDirection={isMobile ? "column" : "row"}
              mt={2}
              pl={sidePadding}
              pr={sidePadding}
            >
              <Box
                bgcolor="#FFF"
                borderRadius="16px"
                width={isMobile ? "100%" : "260px"}
                height={"260px"}
                boxShadow="0px 4px 8px rgba(0, 0, 0, 0.25), 0px 1px 0px rgba(0, 0, 0, 0.1)"
              >
                <Box
                  m="32px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Box
                    width="50px"
                    height="50px"
                    borderRadius="33px"
                    bgcolor={Colors.Grey5}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mb={3}
                  >
                    <Typography variant="h5" style={{ fontWeight: 700 }}>
                      1
                    </Typography>
                  </Box>

                  <Typography variant="body1" style={{ textAlign: "center" }}>
                    Upload your contract and join Contrax, our all-in-one
                    contract review and negotiation management platform.
                  </Typography>
                </Box>
              </Box>
              <Box
                bgcolor="#FFF"
                borderRadius="16px"
                width={isMobile ? "100%" : "260px"}
                height={"260px"}
                boxShadow="0px 4px 8px rgba(0, 0, 0, 0.25), 0px 1px 0px rgba(0, 0, 0, 0.1)"
                ml={2}
              >
                <Box
                  m="32px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Box
                    width="50px"
                    height="50px"
                    borderRadius="33px"
                    bgcolor={Colors.Grey5}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mb={3}
                  >
                    <Typography variant="h5" style={{ fontWeight: 700 }}>
                      2
                    </Typography>
                  </Box>

                  <Typography variant="body1" style={{ textAlign: "center" }}>
                    We'll begin our contract review and notify you when it is
                    ready to view on Contrax.
                  </Typography>
                </Box>
              </Box>
              <Box
                bgcolor="#FFF"
                borderRadius="16px"
                width={isMobile ? "100%" : "260px"}
                height={"260px"}
                boxShadow="0px 4px 8px rgba(0, 0, 0, 0.25), 0px 1px 0px rgba(0, 0, 0, 0.1)"
                ml={2}
              >
                <Box
                  m="32px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Box
                    width="50px"
                    height="50px"
                    borderRadius="33px"
                    bgcolor={Colors.Grey5}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mb={3}
                  >
                    <Typography variant="h5" style={{ fontWeight: 700 }}>
                      3
                    </Typography>
                  </Box>

                  <Typography variant="body1" style={{ textAlign: "center" }}>
                    Ask us questions as they arise, and when you're ready, we'll
                    send your requests to the seller’s reps.
                  </Typography>
                </Box>
              </Box>
              <Box
                bgcolor="#FFF"
                borderRadius="16px"
                width={isMobile ? "100%" : "260px"}
                height={"260px"}
                boxShadow="0px 4px 8px rgba(0, 0, 0, 0.25), 0px 1px 0px rgba(0, 0, 0, 0.1)"
                ml={2}
              >
                <Box
                  m="32px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Box
                    width="50px"
                    height="50px"
                    borderRadius="33px"
                    bgcolor={Colors.Grey5}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mb={3}
                  >
                    <Typography variant="h5" style={{ fontWeight: 700 }}>
                      4
                    </Typography>
                  </Box>

                  <Typography variant="body1" style={{ textAlign: "center" }}>
                    Track responses and communicate with us in real-time as we
                    agree on the best terms for you.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box mt={8} mb={10}>
              <Button
                type={ButtonType.dark}
                size={ButtonSize.medium}
                title="Upload a contract now"
                onClick={onUploadContract}
              />
            </Box>
          </Box>
        </Box>
      )}

      {isMobile && (
        <Box>
          <Box
            mt="12px"
            ml="11px"
            mr="11px"
            display="flex"
            flexDirection="column"
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              // alignItems="center"
              mb="8px"
            >
              <Box
                width="100%"
                // maxHeight="calc(100vh - 72px)"
                height="100%"
                // overflow="auto"
                className="scrollbar-hidden"
                pl={1}
                pr={1}
                boxSizing="border-box"
              >
                <Box
                  mt={3}
                  mb={4}
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  alignItems="left"
                >
                  <img
                    src={landingPage}
                    max-width="100%"
                    height="400"
                    max-height="100%"
                    alt="Preview of Contrax Logo"
                  />

                  <Box mt={2} mb={3} textAlign="center">
                    <Typography variant="h4" style={{ marginBottom: "8px" }}>
                      Get contract review and track progress
                    </Typography>
                    <Typography variant="body1">
                      Upload your contract, create an account, and we'll get
                      started on your review immediately.
                    </Typography>
                  </Box>

                  <Box mb={4} textAlign="center">
                    <Button
                      type={ButtonType.dark}
                      size={ButtonSize.medium}
                      title="Upload a contract now"
                      onClick={onUploadContract}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor={Colors.Grey5}
            width="100%"
          >
            <Box>
              <Typography
                variant="h3"
                style={{
                  fontWeight: 700,
                  marginBottom: "40px",
                  marginTop: "80px",
                }}
              >
                How it works
              </Typography>
            </Box>

            <Box
              display="flex"
              flexDirection={isMobile ? "column" : "row"}
              mt={2}
              pl={sidePadding}
              pr={sidePadding}
            >
              <Box
                bgcolor="#FFF"
                borderRadius="16px"
                width={isMobile ? "100%" : "260px"}
                height={"260px"}
                boxShadow="0px 4px 8px rgba(0, 0, 0, 0.25), 0px 1px 0px rgba(0, 0, 0, 0.1)"
              >
                <Box
                  m="32px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Box
                    width="50px"
                    height="50px"
                    borderRadius="33px"
                    bgcolor={Colors.Grey5}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mb={3}
                  >
                    <Typography variant="h5" style={{ fontWeight: 700 }}>
                      1
                    </Typography>
                  </Box>

                  <Typography variant="body1" style={{ textAlign: "center" }}>
                    Upload your contract and join Contrax, our all-in-one
                    contract review and negotiation management platform.
                  </Typography>
                </Box>
              </Box>
              <Box
                bgcolor="#FFF"
                borderRadius="16px"
                width="100%"
                height="260px"
                boxShadow="0px 4px 8px rgba(0, 0, 0, 0.25), 0px 1px 0px rgba(0, 0, 0, 0.1)"
                mt={2}
              >
                <Box
                  m="32px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Box
                    width="50px"
                    height="50px"
                    borderRadius="33px"
                    bgcolor={Colors.Grey5}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mb={3}
                  >
                    <Typography variant="h5" style={{ fontWeight: 700 }}>
                      2
                    </Typography>
                  </Box>

                  <Typography variant="body1" style={{ textAlign: "center" }}>
                    We'll begin our contract review and notify you when it is
                    ready to view on Contrax.
                  </Typography>
                </Box>
              </Box>
              <Box
                bgcolor="#FFF"
                borderRadius="16px"
                width="100%"
                height="260px"
                boxShadow="0px 4px 8px rgba(0, 0, 0, 0.25), 0px 1px 0px rgba(0, 0, 0, 0.1)"
                mt={2}
              >
                <Box
                  m="32px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Box
                    width="50px"
                    height="50px"
                    borderRadius="33px"
                    bgcolor={Colors.Grey5}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mb={3}
                  >
                    <Typography variant="h5" style={{ fontWeight: 700 }}>
                      3
                    </Typography>
                  </Box>

                  <Typography variant="body1" style={{ textAlign: "center" }}>
                    Ask us questions as they arise, and when you're ready, we'll
                    send your requests to the seller’s reps.
                  </Typography>
                </Box>
              </Box>
              <Box
                bgcolor="#FFF"
                borderRadius="16px"
                width="100%"
                height="260px"
                boxShadow="0px 4px 8px rgba(0, 0, 0, 0.25), 0px 1px 0px rgba(0, 0, 0, 0.1)"
                mt={2}
              >
                <Box
                  m="32px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Box
                    width="50px"
                    height="50px"
                    borderRadius="33px"
                    bgcolor={Colors.Grey5}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mb={3}
                  >
                    <Typography variant="h5" style={{ fontWeight: 700 }}>
                      4
                    </Typography>
                  </Box>

                  <Typography variant="body1" style={{ textAlign: "center" }}>
                    Track responses and communicate with us in real-time as we
                    agree on the best terms for you.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box mt={5} mb={7}>
              <Button
                type={ButtonType.dark}
                size={ButtonSize.medium}
                title="Upload a contract now"
                onClick={onUploadContract}
              />
            </Box>
          </Box>
        </Box>
      )}

      <MyLawyerLandingPageFooter />
    </>
  );
}
