import React from "react";
import {
  Box,
  Typography,
  Link,
  Button as MUIButton,
  ButtonBase,
} from "@material-ui/core";
import BLUELOGO from "../images/Blue.png";
import MENUICON from "../images/menu.png";
import MobileSideMenu from "../components/common/MobileSideMenu";

export default function DashboardHeaderMobile(): JSX.Element {
  const [sideMenuOpen, setSideMenuOpen] = React.useState(false);

  return (
    <React.Fragment>
      <Box
        display="flex"
        flexDirection="row"
        height="72px"
        alignItems="center"
        justifyContent="space-between"
      >
        <ButtonBase
          onClick={() => window.location.assign(window.location.origin)}
        >
          <img src={BLUELOGO} height="32px" style={{ marginLeft: "24px" }} />
        </ButtonBase>
        <MUIButton
          onClick={() => {
            setSideMenuOpen(true);
          }}
        >
          <img src={MENUICON} />
        </MUIButton>
      </Box>

      <MobileSideMenu
        open={sideMenuOpen}
        onClose={() => setSideMenuOpen(false)}
      />
    </React.Fragment>
  );
}
