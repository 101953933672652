import { Box, Checkbox, Dialog, Typography } from "@material-ui/core";
import { isEmpty } from "lodash-es";
import React from "react";
import { useHistory } from "react-router-dom";
import Button, { ButtonSize, ButtonType } from "../common/Button";
import Separator from "../common/Separator";
import TextField from "../common/TextField";
import PrivacyLink from "../PrivacyLink";
import TermsLink from "../TermsLink";
import { useMobile } from "../../hooks/mobile";
import ModalHeader from "./ModalHeader";
import { useAlert } from "../../contexts/Alert";
import noAuthApi from "../../api/noauth";
import api from "../../api/instance";
import { Colors } from "../../constants/colors";
import { parseApiError } from "../../helpers/error";
import ContraxLogo from "../../images/invited-to-firm-logo.png";
import useSWR from "swr";
import { OrgDashboardDetail } from "../../api/models";

interface InfoBarProps {
  firmName: string;
  inviterName?: string | null;
  orgId?: string | null;
}

function InfoBar({ firmName, inviterName, orgId }: InfoBarProps) {
  const { data: orgData } = useSWR(`/organization-detail/${orgId}/`);

  const organisationDetail = orgData
    ? (orgData as OrgDashboardDetail)
    : undefined;

  return (
    <Box
      minHeight="105px"
      pl="34px"
      pr="34px"
      display="flex"
      flexDirection="row"
      bgcolor={Colors.BrandBackground3}
      alignItems="center"
    >
      <Box>
        {organisationDetail?.logo ? (
          <img
            src={organisationDetail.logo}
            height="40px"
            style={{
              // marginLeft: "24px",
              marginRight: "16px",
              // maxWidth: "80%",
              objectFit: "cover",
            }}
          />
        ) : (
          <img
            src={ContraxLogo}
            width="95px"
            height="64px"
            style={{
              objectFit: "cover",
              borderRadius: "4px",
              marginRight: "16px",
            }}
          />
        )}
      </Box>
      <Box>
        <Typography variant="body1" style={{ fontWeight: 700 }}>
          {inviterName}
        </Typography>

        <Typography variant="body2">
          Has invited you to {organisationDetail?.name} account
        </Typography>
      </Box>
    </Box>
  );
}

interface Props {
  open: boolean;
  onClose: () => unknown;
  inviterName: string;
  inviterFirmName: string | null;
  token: string | null;
  onSignedUp: () => unknown;
  orgId: string | null;
}

export default function FirmSignUpModal({
  open,
  onClose,
  inviterName,
  inviterFirmName,
  token,
  onSignedUp,
  orgId,
}: Props) {
  const isMobile = useMobile();
  const history = useHistory();

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [agreeToTerms, setAgreeToTerms] = React.useState(false);

  const [signingUp, setSigningUp] = React.useState(false);

  const { setErrorMessage } = useAlert();

  return (
    <Dialog
      open={open}
      aria-labelledby="login-modal"
      aria-describedby="login-modal-description"
      fullScreen={isMobile}
    >
      <Box width={isMobile ? "100%" : "425px"}>
        <ModalHeader />
        {inviterFirmName && inviterName && (
          <InfoBar
            orgId={orgId}
            firmName={inviterFirmName}
            inviterName={inviterName}
          />
        )}

        <Box pl="39px" pr="39px" pt="43px" pb="40px">
          <TextField
            title="Confirm first name"
            value={firstName}
            onChange={(text) => setFirstName(text)}
          />

          <TextField
            title="Confirm last name"
            value={lastName}
            onChange={(text) => setLastName(text)}
          />

          <TextField
            title="Provide a password"
            value={password}
            onChange={(text) => setPassword(text)}
            type="password"
          />

          <Box
            display="flex"
            flexDirection="row"
            mt={1.5}
            alignItems="flex-start"
          >
            <Checkbox
              style={{ color: Colors.Grey2 }}
              checked={agreeToTerms}
              onChange={(event) => {
                setAgreeToTerms(event.target.checked);
              }}
            />
            <Typography variant="body2" style={{ marginTop: "8px" }}>
              I agree to the <TermsLink /> (including those relating to privacy
              and communication) and <PrivacyLink />
            </Typography>
          </Box>
        </Box>

        <Separator />
        <Box
          height="74px"
          width="100%"
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          boxSizing="border-box"
          pl="16px"
          pr="16px"
        >
          <Button
            loading={signingUp}
            title="CONTINUE"
            type={ButtonType.primary}
            size={ButtonSize.medium}
            width="125px"
            onClick={() => {
              if (isEmpty(firstName)) {
                setErrorMessage("Please enter your first name");
                return;
              }

              if (isEmpty(lastName)) {
                setErrorMessage("Please enter your last name");
                return;
              }

              if (isEmpty(password)) {
                setErrorMessage("Please provide a password");
                return;
              }

              if (!agreeToTerms) {
                setErrorMessage("Please agree to terms and conditions");
                return;
              }

              setSigningUp(true);

              noAuthApi
                .post("/user-confirmation/", {
                  token,
                  first_name: firstName,
                  last_name: lastName,
                  password,
                })
                .then((response) => {
                  // console.debug("user-confirmation response: ", response);

                  const token = response.data.token;

                  localStorage.setItem("token", token);

                  api.defaults.headers.common[
                    "Authorization"
                  ] = `Bearer ${token}`;

                  setSigningUp(false);
                  onSignedUp();
                })
                .catch((error) => {
                  setSigningUp(false);

                  setErrorMessage(parseApiError(error));
                });
            }}
          />
        </Box>
      </Box>
    </Dialog>
  );
}
