import {
  Box,
  ButtonBase,
  Drawer,
  IconButton,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { User, UserType } from "../../api/models";
import { Colors } from "../../constants/colors";
import AccountDetailsIcon from "../../images/sidemenu-account-details.svg";
import CloseIcon from "../../images/sidemenu-close.svg";
import DashboardIcon from "../../images/sidemenu-dashboard.svg";
import InvoicesIcon from "../../images/sidemenu-invoices.svg";
import LogoutIcon from "../../images/sidemenu-logout.svg";
import SearchIcon from "../../images/sidemenu-search.svg";
import UserIcon from "../../images/sidemenu-user.svg";
import { resetSession, useSessionStore } from "../../stores/Session";
import useSWR, { useSWRConfig } from "swr";

interface Props {
  open: boolean;
  onClose: () => unknown;
}

interface MenuItemProps {
  title: string;
  icon: string;
  onClick: () => unknown;
}

function MenuItem({ title, icon, onClick }: MenuItemProps) {
  return (
    <ButtonBase onClick={onClick}>
      <Box
        display="flex"
        flexDirection="row"
        width="263px"
        height="48px"
        alignItems="center"
      >
        <img src={icon} height="20px" />
        <Typography variant="h6" style={{ marginLeft: "20px" }}>
          {title}
        </Typography>
      </Box>
    </ButtonBase>
  );
}

export default function SideMenu({ open, onClose }: Props) {
  const history = useHistory();
  const location = useLocation();
  const { cache } = useSWRConfig();

  const { data: userData } = useSessionStore();
  const user = userData && (userData as User);

  const isAgent = user?.user_type === UserType.Agent;

  const isBuyerLawyer = user?.user_type === UserType.Solicitor;

  const isPartner = user?.user_type === UserType.Partner;

  // const showNewMenuItems = location.pathname.startsWith("/user/");

  function navigateTo(path: string) {
    if (path === location.pathname) {
      onClose();
    } else {
      history.push(path);
    }
  }
  /*
  const menuItems: MenuItemProps[] = [
    {
      title: "Dashboard",
      icon: DashboardIcon,
      onClick: () => navigateTo("/dashboard"),
    },

    ...(isBuyerLawyer && userData?.current_organization !== null
      ? [
          {
            title: "My Team",
            icon: UserIcon,
            onClick: () => navigateTo("/my-team"),
          },
          {
            title: "Personalisation",
            icon: DashboardIcon,
            onClick: () => navigateTo("/personalisation"),
          },
        ]
      : []),
    {
      title: "Property Search",
      icon: SearchIcon,
      onClick: () => {
        if (isAgent) {
          navigateTo("/agent/add-property");
        } else {
          window.open(window.location.origin + "/search-properties", "_self");
        }
      },
    },
    {
      title: "My invoices",
      icon: InvoicesIcon,
      onClick: () => navigateTo("/my-invoices"),
    },
    {
      title: "Account details",
      icon: AccountDetailsIcon,
      onClick: () => navigateTo("/account-details"),
    },
    {
      title: "Log out",
      icon: LogoutIcon,
      onClick: () => {
        resetSession();
        history.push("/login");
      },
    },
  ];
*/

  const newMenuItems: MenuItemProps[] = [
    {
      title: "Dashboard",
      icon: DashboardIcon,
      onClick: () => navigateTo("/dashboard"),
    },

    ...((isBuyerLawyer || isPartner) && userData?.current_organization !== null
      ? [
          {
            title: "My Team",
            icon: UserIcon,
            onClick: () => navigateTo("/my-team"),
          },
          {
            title: "Personalisation",
            icon: DashboardIcon,
            onClick: () => navigateTo("/personalisation"),
          },
        ]
      : []),
    {
      title: "Property Search",
      icon: SearchIcon,
      onClick: () => {
        if (isAgent) {
          navigateTo("/agent/add-property");
        } else {
          window.open(window.location.origin + "/search-properties", "_self");
        }
      },
    },

    ...(!isAgent && !isPartner
      ? [
          {
            title: "Contrax credits",
            icon: InvoicesIcon,
            onClick: () => navigateTo("/user/credits"),
          },
          {
            title: "Billing information",
            icon: InvoicesIcon,
            onClick: () => navigateTo("/user/billing"),
          },
          {
            title: "My Invoices",
            icon: InvoicesIcon,
            onClick: () => navigateTo("/user/invoices"),
          },
          // {
          //   title: "Usage",
          //   icon: InvoicesIcon,
          //   onClick: () => navigateTo("/user/usage"),
          // },
        ]
      : []),

    {
      title: "My account",
      icon: InvoicesIcon,
      onClick: () => navigateTo("/user/account"),
    },
    {
      title: "Log out",
      icon: LogoutIcon,
      onClick: () => {
        cache.delete("buying-contracts/");
        cache.delete("/me/");

        resetSession();
        history.push("/login");
      },
    },
  ];

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <Box width="301px">
        <Box bgcolor={Colors.BrandBackground2} height="119px">
          <Box
            height="50%"
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            pr="12px"
            boxSizing="border-box"
          >
            <IconButton onClick={onClose}>
              <img src={CloseIcon} />
            </IconButton>
          </Box>
          <Box
            height="50%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            pl="18px"
            boxSizing="border-box"
          >
            <img src={UserIcon} />
            <Typography
              variant="h6"
              style={{
                marginLeft: "16px",
                fontWeight: 700,
                color: Colors.BrandPrimary,
              }}
            >
              {user?.first_name}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" mt="8px">
          {/* {!showNewMenuItems && menuItems.map((item) => <MenuItem {...item} />)} */}

          {newMenuItems.map((item) => (
            <MenuItem {...item} />
          ))}
        </Box>
      </Box>
    </Drawer>
  );
}
