import { Box, Dialog, Typography } from "@material-ui/core";
import { isEmpty, isNil, uniqBy } from "lodash-es";
import React from "react";
import api from "../../api/instance";
import {
  LegalTeamMember,
  MyTeamMember,
  TeamMemberRole,
  User,
  UserType,
  Contract,
} from "../../api/models";
import { useAlert } from "../../contexts/Alert";
import { parseApiError } from "../../helpers/error";
import { useMobile } from "../../hooks/mobile";
import { useContractStore } from "../../stores/contract";
import { useSessionStore } from "../../stores/Session";
import Button, { ButtonSize, ButtonType } from "../common/Button";
import Select from "../common/Select";
import Separator from "../common/Separator";
import TextField from "../common/TextField";
import InfoMessageBar from "../InfoMessageBar";
import useSWR from "swr";
import Switch from "../common/Switch";

export enum InviteYourTeamMembersModalType {
  default, // Requires both contract and record
  sellerTeam,
  agents,
}

export enum InviteYourTeamMembersModalForScreen {
  default,
  request,
  legalreviewbuyer,
  buyerinvitedworkspace,
}

interface Props {
  type?: InviteYourTeamMembersModalType;
  open: boolean;
  onClose: () => unknown;
  contractId: number | null;
  recordId?: number | null;
  onInvited: (firstName: string, lastName: string) => unknown;
  userTeamMemberRole?: TeamMemberRole | null;
  forScreen?: InviteYourTeamMembersModalForScreen;
}

interface InvitePeopleMessageProps {
  userType?: UserType | null;
}

function InvitePeopleMessage({ userType }: InvitePeopleMessageProps) {
  // console.debug("userType: ", userType);
  // if (userType === UserType.Buyer) {
  //   return (
  //     <InfoMessageBar
  //       message={
  //         "Invite people to your team, including your partners and advisors"
  //       }
  //     />
  //   );
  // }

  return (
    <InfoMessageBar
      message={"Invite participants to collaborate on this contract"}
    />
  );
}

export default function ({
  type = InviteYourTeamMembersModalType.default,
  open,
  onClose,
  contractId,
  recordId,
  onInvited,
  forScreen,
}: Props) {
  const isMobile = useMobile();
  const { data: userData } = useSessionStore();
  const user = userData && (userData as User);

  const { data: teamMembersData, mutate: teamMembersMutate } = useSWR(() => {
    if (
      user?.user_type !== UserType.Buyer &&
      user?.user_type !== UserType.Agent
    ) {
      return "/organization-member/";
    } else {
      return null;
    }
  });

  const teamMembers = teamMembersData
    ? (teamMembersData as MyTeamMember[])
    : [];

  // console.debug("teamMembers: ", teamMembers);

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [role, setRole] = React.useState("");

  const [emailInput, setEmailInput] = React.useState("");

  const { userTeamMemberRole } = useContractStore();

  const isSellerLawyer = userTeamMemberRole === TeamMemberRole.sellerLawyer;

  const isBuyerLawyer = userTeamMemberRole === TeamMemberRole.buyerLawyer;

  // console.log(user?.pk, "User PK");

  const { data } = useSWR(
    `/view-contracts/${contractId}/?recordId=${recordId}`
  );
  const contract = data ? (data as Contract) : null;

  // console.log(contract?.primary_buyer_lawyer.pk, "Test PBL");

  // console.log(contract?.seller_lawyer.pk, "Test PSL");

  const isLawyer = user?.user_type === UserType.Solicitor;
  const isAgent = user?.user_type === UserType.Agent;

  const isPartner = user?.user_type === UserType.Partner;

  const { setErrorMessage } = useAlert();

  const [inviting, setInviting] = React.useState(false);

  const [addToFirm, setAddToFirm] = React.useState(false);

  const _emailOptions = () => {
    let options = !isEmpty(emailInput)
      ? [
          {
            label: emailInput,
            value: emailInput,
            firstName: "",
            lastName: "",
          },
        ]
      : [];

    if (role === TeamMemberRole.agent) {
      options = options.concat(
        teamMembers
          .filter((m) => m.user_type === UserType.Agent)
          .map((m) => {
            return {
              label: m.email,
              value: m.email,
              firstName: m.first_name,
              lastName: m.last_name,
            };
          })
      );
    }

    if (role === TeamMemberRole.buyer) {
      options = options.concat(
        teamMembers
          .filter((m) => m.user_type === UserType.Buyer)
          .map((m) => {
            return {
              label: m.email,
              value: m.email,
              firstName: m.first_name,
              lastName: m.last_name,
            };
          })
      );
    }

    if (
      role === TeamMemberRole.buyerLawyer ||
      role === TeamMemberRole.sellerLawyer
    ) {
      options = options.concat(
        teamMembers
          .filter((m) => m.user_type === UserType.Solicitor)
          .map((m) => {
            return {
              label: m.email,
              value: m.email,
              firstName: m.first_name,
              lastName: m.last_name,
            };
          })
      );
    }

    if (role === TeamMemberRole.partner) {
      options = options.concat(
        teamMembers
          .filter((m) => m.user_type === UserType.Partner)
          .map((m) => {
            return {
              label: m.email,
              value: m.email,
              firstName: m.first_name,
              lastName: m.last_name,
            };
          })
      );
    }

    return uniqBy(options, "value");
  };

  const _roleOptions = () => {
    if (type === InviteYourTeamMembersModalType.sellerTeam && isAgent) {
      return [
        { value: TeamMemberRole.sellerLawyer, label: "Seller Legal Rep" },
      ];
    }

    if (userTeamMemberRole === TeamMemberRole.buyer) {
      return [{ value: TeamMemberRole.buyer, label: "Buyer" }];
    }

    if (userTeamMemberRole === TeamMemberRole.buyerLawyer) {
      return [
        { value: TeamMemberRole.buyer, label: "Buyer" },
        { value: TeamMemberRole.buyerLawyer, label: "Buyer Legal Rep" },
        { value: TeamMemberRole.sellerLawyer, label: "Seller Legal Rep" },
        { value: TeamMemberRole.agent, label: "Agent" },
      ];
    }

    if (
      userTeamMemberRole === TeamMemberRole.seller ||
      (type === InviteYourTeamMembersModalType.sellerTeam && !isLawyer)
    ) {
      return [{ value: TeamMemberRole.seller, label: "Seller" }];
    }

    if (
      userTeamMemberRole === TeamMemberRole.sellerLawyer ||
      (type === InviteYourTeamMembersModalType.sellerTeam && isLawyer)
    ) {
      return [
        { value: TeamMemberRole.seller, label: "Seller" },
        { value: TeamMemberRole.sellerLawyer, label: "Seller Legal Rep" },
        { value: TeamMemberRole.buyerLawyer, label: "Buyer Legal Rep" },
        { value: TeamMemberRole.agent, label: "Agent" },
      ];
    }

    if (
      type === InviteYourTeamMembersModalType.agents &&
      userTeamMemberRole !== TeamMemberRole.partner
    ) {
      return [{ value: TeamMemberRole.agent, label: "Agent" }];
    }

    if (userTeamMemberRole === TeamMemberRole.agent) {
      return [
        { value: TeamMemberRole.sellerLawyer, label: "Seller Legal Rep" },
      ];
    }

    if (userTeamMemberRole === TeamMemberRole.partner) {
      return [{ value: TeamMemberRole.partner, label: "Partner" }];
    }

    return [];
  };

  const roleOptions = _roleOptions();
  const emailOptions = _emailOptions();

  function resetFields() {
    setFirstName("");
    setLastName("");
    setEmail("");
    setRole("");
  }

  const emailAlreadyFirmMember = !isNil(
    teamMembers.find((m) => m.email.toLowerCase() === email.toLowerCase())
  );

  React.useEffect(() => {
    if (emailAlreadyFirmMember) {
      setAddToFirm(false);
    }
  }, [email]);

  const handleEmailSelection = (option) => {
    if (option) {
      setEmail(option.value);
      setFirstName(option.firstName);
      setLastName(option.lastName);
    } else {
      setEmail("");
      setFirstName("");
      setLastName("");
    }
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="invite-dialog"
      aria-describedby="invite-dialog-description"
      onClose={() => {
        onClose();
        resetFields();
      }}
      fullScreen={isMobile}
    >
      <Box
        width={isMobile ? "100%" : "524px"}
        display="flex"
        flexDirection="column"
      >
        <Box
          height="62px"
          pl="24px"
          alignItems="center"
          display="flex"
          flexDirection="row"
        >
          <Typography variant="h5" style={{ fontWeight: 700 }}>
            Invite participant
          </Typography>
        </Box>
        <Separator />
        <InvitePeopleMessage userType={user?.user_type} />
        <Box p="24px">
          {!isEmpty(roleOptions) && (
            <Select
              value={roleOptions.find((option) => option.value === role)}
              title="Role"
              onChange={(option) => {
                option && setRole((option as any).value);
              }}
              options={roleOptions}
              zIndexFix
              placeholder="Please select"
            />
          )}

          {(role === TeamMemberRole.buyerLawyer &&
            userTeamMemberRole === TeamMemberRole.buyerLawyer) ||
          (role === TeamMemberRole.sellerLawyer &&
            userTeamMemberRole === TeamMemberRole.sellerLawyer) ||
          (role === TeamMemberRole.partner &&
            userTeamMemberRole === TeamMemberRole.partner) ? (
            <Select
              title="Email"
              value={
                !isNil(email) && !isEmpty(email)
                  ? {
                      label: email,
                      value: email,
                    }
                  : null
              }
              onInputChange={(newValue, actionMeta) => {
                if (actionMeta.action === "input-change") {
                  setEmailInput(newValue);
                }
              }}
              onChange={(option) => {
                handleEmailSelection(option);

                if (isEmpty(option)) {
                  setEmailInput("");
                }
              }}
              options={emailOptions}
              placeholder=""
              zIndexFix
              isClearable
            />
          ) : (
            <TextField
              title="Email"
              value={email}
              onChange={(text) => setEmail(text.trim())}
            />
          )}

          <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
            <TextField
              title="First Name"
              value={firstName}
              onChange={(text) => setFirstName(text)}
            />
            <Box width="12.5px" />
            <TextField
              title="Last Name"
              value={lastName}
              onChange={(text) => setLastName(text)}
            />
          </Box>
          {(role === TeamMemberRole.buyerLawyer &&
            userTeamMemberRole === TeamMemberRole.buyerLawyer) ||
          (role === TeamMemberRole.sellerLawyer &&
            userTeamMemberRole === TeamMemberRole.sellerLawyer) ||
          (role === TeamMemberRole.partner &&
            userTeamMemberRole === TeamMemberRole.partner) ? (
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant="body2" style={{ fontWeight: 700 }}>
                Do you want to add this person to your firm?
              </Typography>
              <Switch
                checked={addToFirm}
                disabled={emailAlreadyFirmMember}
                onChange={(event) => setAddToFirm(event.target.checked)}
                color="primary"
              />
            </Box>
          ) : (
            <></>
          )}
        </Box>
        <Separator />
        <Box
          display="flex"
          justifyContent="flex-end"
          pl="24px"
          pr="16px"
          height="74px"
          alignItems="center"
        >
          <Button
            title="CANCEL"
            size={ButtonSize.medium}
            type={ButtonType.secondary}
            onClick={() => {
              onClose();
              resetFields();
            }}
            width="114px"
          />
          <Box width="8px" />
          <Button
            loading={inviting}
            title="INVITE"
            size={ButtonSize.medium}
            type={ButtonType.primary}
            onClick={() => {
              if (!isEmpty(roleOptions) && isEmpty(role)) {
                setErrorMessage("Please select their role");
                return;
              }

              if (isEmpty(email)) {
                setErrorMessage("Please enter their email");
                return;
              }

              if (isEmpty(firstName)) {
                setErrorMessage("Please enter their first name");
                return;
              }

              if (isEmpty(lastName)) {
                setErrorMessage("Please enter their last name");
                return;
              }

              setInviting(true);

              // Invite for seller team for Contract...
              if (type === InviteYourTeamMembersModalType.sellerTeam) {
                api
                  .post(`/contract/${contractId}/invite-seller-team/`, {
                    first_name: firstName,
                    last_name: lastName,
                    email,
                    role,
                  })
                  .then((response) => {
                    setInviting(false);

                    onInvited(firstName, lastName);
                    resetFields();
                  })
                  .catch((error) => {
                    setInviting(false);

                    setErrorMessage(parseApiError(error));
                  });
              }

              // Invite for buyer team
              if (
                userTeamMemberRole === TeamMemberRole.buyer ||
                userTeamMemberRole === TeamMemberRole.buyerLawyer
              ) {
                const path =
                  forScreen === InviteYourTeamMembersModalForScreen.request
                    ? `/contract/${contractId}/record/${recordId}/share/legal-review/?request_part=request_part`
                    : forScreen ===
                      InviteYourTeamMembersModalForScreen.legalreviewbuyer
                    ? `/contract/${contractId}/record/${recordId}/share/legal-review/?bib=bib`
                    : forScreen ===
                      InviteYourTeamMembersModalForScreen.buyerinvitedworkspace
                    ? `/contract/${contractId}/record/${recordId}/share/legal-review/?biw=biw`
                    : `/contract/${contractId}/record/${recordId}/share/legal-review/`;

                api
                  .post(path, {
                    first_name: firstName,
                    last_name: lastName,
                    email,
                    role,
                    add_to_firm: addToFirm,
                  })
                  .then((response) => {
                    setInviting(false);

                    onInvited(firstName, lastName);
                    resetFields();
                  })
                  .catch((error) => {
                    setInviting(false);

                    setErrorMessage(parseApiError(error));
                  });
              }

              // Invite for seller team for Records...
              if (
                userTeamMemberRole === TeamMemberRole.seller ||
                userTeamMemberRole === TeamMemberRole.sellerLawyer ||
                userTeamMemberRole === TeamMemberRole.agent
              ) {
                api
                  .post(
                    `/contract/${contractId}/record/${recordId}/share/requests/`,
                    {
                      first_name: firstName,
                      last_name: lastName,
                      email,
                      role,
                      add_to_firm: addToFirm,
                    }
                  )
                  .then((response) => {
                    setInviting(false);

                    onInvited(firstName, lastName);
                    resetFields();
                  })
                  .catch((error) => {
                    setInviting(false);

                    setErrorMessage(parseApiError(error));
                  });
              }

              if (userTeamMemberRole === TeamMemberRole.partner) {
                api
                  .post(
                    `/contract/${contractId}/record/${recordId}/partner-invite-partner/`,
                    {
                      first_name: firstName,
                      last_name: lastName,
                      email,
                      role,
                      add_to_firm: addToFirm,
                    }
                  )
                  .then((response) => {
                    setInviting(false);

                    onInvited(firstName, lastName);
                    resetFields();
                  })
                  .catch((error) => {
                    setInviting(false);

                    setErrorMessage(parseApiError(error));
                  });
              }

              // Invite agent in dashboard
              // if (
              //   userTeamMemberRole === TeamMemberRole.agent &&
              //   !isNil(contractId)
              // ) {
              //   api
              //     .post(`/contract/${contractId}/invite-agent-team/`, {
              //       first_name: firstName,
              //       last_name: lastName,
              //       email,
              //       role: "Agent",
              //     })
              //     .then((response) => {
              //       setInviting(false);

              //       onInvited(firstName, lastName);
              //       resetFields();
              //     })
              //     .catch((error) => {
              //       setInviting(false);

              //       setErrorMessage(parseApiError(error));
              //     });
              // }
            }}
            width="93px"
          />
        </Box>
      </Box>
    </Dialog>
  );
}
