import { Box, Dialog, Typography } from "@material-ui/core";
import { PDFDownloadLink } from "@react-pdf/renderer";
import React from "react";
import api from "../../api/instance";
import {
  addressForProperty,
  Comment,
  Contract,
  ContractSummary,
  Enquiry,
  LegalReviewRequest,
  Property,
  TeamMemberRole,
} from "../../api/models";
import { useMobile } from "../../hooks/mobile";
import { generateRequestsDocxAndSave } from "../../utils/docx-generator";
import Button, { ButtonType } from "../common/Button";
import RadioWithTitle from "../common/RadioWithTitle";
import PDFDocument from "./PDFDocument";
import Radio from "../common/Radio";

interface Props {
  open: boolean;
  onCancel: () => unknown;
  requests: LegalReviewRequest[];
  enquiries: Enquiry[];
  recordId: number;
  property: Property;
  contract: Contract;
  contractS: Contract;
  userTeamMemberRole: TeamMemberRole;
  requestsForName: string;
}

enum DocumentType {
  pdf,
  word,
}

export default function DownloadDialog({
  open,
  onCancel,
  requests,
  enquiries,
  contract,
  contractS,
  recordId,
  property,
  userTeamMemberRole,
  requestsForName,
}: Props) {
  const isMobile = useMobile();

  const isSeller =
    userTeamMemberRole === TeamMemberRole.seller ||
    userTeamMemberRole === TeamMemberRole.sellerLawyer;

  const isBuyer =
    userTeamMemberRole === TeamMemberRole.buyer ||
    userTeamMemberRole === TeamMemberRole.buyerLawyer;

  const isAgent = userTeamMemberRole === TeamMemberRole.agent;

  const canIncludeComments = !isAgent;

  const [documentType, setDocumentType] = React.useState<DocumentType>(
    DocumentType.pdf
  );
  const [includeComments, setIncludeComments] = React.useState<boolean>(true);

  const [requestsCommentsLoaded, setRequestsCommentsLoaded] =
    React.useState(false);
  const [requestsWithComments, setRequestsWithComments] = React.useState<
    LegalReviewRequest[]
  >([]);
  const [fetchingRequestsComments, setFetchingRequestsComments] =
    React.useState(false);

  const [enquiriesCommentsLoaded, setEnquiriesCommentsLoaded] =
    React.useState(false);
  const [enquiriesWithComments, setEnquiriesWithComments] = React.useState<
    Enquiry[]
  >([]);
  const [fetchingEnquiriesComments, setFetchingEnquiriesComments] =
    React.useState(false);
  const [Download, SetDownload] = React.useState(false);

  React.useEffect(() => {
    if (isAgent) {
      setIncludeComments(false);
    }
  }, [userTeamMemberRole]);

  React.useEffect(() => {
    async function fetchRequestsComments(requests: LegalReviewRequest[]) {
      setFetchingRequestsComments(true);

      let _requests = requests;

      await Promise.all(
        _requests.map(async (request, index) => {
          // Team comments
          const { data: teamCommentsData } = await api.get(
            `/contract/${contract.id}/record/${recordId}/request/${
              isSeller ? request?.buyer_request_pk : request.pk
            }/${`comments/?is_buyer_flow=${isBuyer}`}`
          );

          // Legal comments
          const { data: legalCommentsData } = await api.get(
            `/contract/${contract.id}/record/${recordId}/request/${
              isSeller ? request?.buyer_request_pk : request.pk
            }/messages/`
          );

          request.p_teamComments = teamCommentsData
            ? (teamCommentsData as Comment[])
            : [];

          request.p_legalComments = legalCommentsData
            ? (legalCommentsData as Comment[])
            : [];
        })
      );

      setFetchingRequestsComments(false);
      setRequestsWithComments(_requests);
      setRequestsCommentsLoaded(true);
    }

    if (open && requests && !fetchingRequestsComments) {
      fetchRequestsComments(requests);
    }
  }, [open, requests]);

  React.useEffect(() => {
    async function fetchEnquiriesComments(enquiries: Enquiry[]) {
      setFetchingEnquiriesComments(true);

      let _enquiries = enquiries;

      await Promise.all(
        _enquiries.map(async (enquiry, index) => {
          // Team comments
          const { data: teamCommentsData } = await api.get(
            `/contract/${contract.id}/record/${recordId}/enquiry/${
              isSeller ? enquiry?.buyer_enquiry_pk : enquiry.pk
            }/${`comments/?is_buyer_flow=${isBuyer}`}`
          );

          // Legal comments
          const { data: legalCommentsData } = await api.get(
            `/contract/${contract.id}/record/${recordId}/enquiry/${
              isSeller ? enquiry?.buyer_enquiry_pk : enquiry.pk
            }/messages/`
          );

          enquiry.p_teamComments = teamCommentsData
            ? (teamCommentsData as Comment[])
            : [];

          enquiry.p_legalComments = legalCommentsData
            ? (legalCommentsData as Comment[])
            : [];
        })
      );

      setFetchingEnquiriesComments(false);
      setEnquiriesWithComments(_enquiries);
      setEnquiriesCommentsLoaded(true);
    }

    if (open && enquiries && !fetchingEnquiriesComments) {
      fetchEnquiriesComments(enquiries);
    }
  }, [open, enquiries]);

  const filename = property
    ? `${addressForProperty(property)} ${requestsForName}`.replace(
        /[\W_]+/g,
        "_"
      )
    : "contrax.pdf";

  const FetchReceiversList = async () => {
    try {
      const result = await api.get(
        `/contract/${contract?.id}/record/${recordId}/request-recipients/`
      );

      const propertyAddress = result.data?.property_address;
      const recipients = result.data.recipients.filter(
        (recipient) => recipient.user_type === "SOL"
      );
      const CCrecipients = result.data.recipients.filter(
        (recipient) => recipient.user_type !== "SOL"
      );
      const CC = CCrecipients.map((item) => item.email);
      const emailList = recipients.map((item) => item.email);
      console.log(result.data.recipients);

      return { propertyAddress, emailList, CC }; // Return both propertyAddress and emailList
    } catch (error) {
      console.log(error);
      return { propertyAddress: null, emailList: [] }; // Return propertyAddress as null in case of error
    }
  };

  const FetchLegalReviews = async () => {
    try {
      const result = await api.get(
        `/contract/${contract?.id}/record/${recordId}/legal-review-requests/`
      );

      const PendingReviews = result.data.filter(
        (item) => item.request_status === "Pending"
      );

      return PendingReviews;
    } catch (error) {
      console.log(error);
    }
  };

  const FetchGeneralEnquires = async () => {
    try {
      const result = await api.get(
        `/contract/${contract?.id}/record/${recordId}/legal-review-enquiries/`
      );
      const PendingEnquiries = result.data.filter(
        (item) => item.enquiry_status === "Pending"
      );

      return PendingEnquiries;
    } catch (error) {
      console.log(error, "error fetching the general enquiries");
    }
  };

  const openMail = async () => {
    console.log("in");

    try {
      const pendingReviews = await FetchLegalReviews();
      const pendingEnquiries = await FetchGeneralEnquires();
      const { propertyAddress, emailList, CC } = await FetchReceiversList(); // Destructure propertyAddress and emailList

      if (emailList && emailList.length > 0) {
        const emailString = emailList.join(", ");
        const ccString = CC.join(", ");
        const subject = `${propertyAddress} - Contract Requests/Inquiries`; // Use propertyAddress in the subject

        let index = 1; // Initialize the index

        // Format the pending reviews into a readable string with numbering and bold titles
        const pendingReviewsText = pendingReviews
          .map((review) => `${index++}. ${review.title}: ${review.description}`)
          .join("\n");

        // Format the pending enquiries into a readable string with numbering and bold titles
        const pendingEnquiriesText = pendingEnquiries
          .map((enquiry) => `${index++}. ${enquiry.title}: ${enquiry.enquiry}`)
          .join("\n");

        const customMessage = `Good afternoon,\n\nI act for ${contract?.title} who are interested in purchasing the above property, subject to the following contract requests/inquiries:\n\nSubject: ${subject}`;

        const fullMessage = `${customMessage}\n\n${pendingReviewsText}\n${pendingEnquiriesText}\n\nThank you and regards,\n\n${contract?.primary_buyer_lawyer?.first_name} ${contract?.primary_buyer_lawyer?.last_name}`;
        console.log(fullMessage, ccString);

        const mailToLink = `mailto:${emailString}?subject=${encodeURIComponent(
          subject
        )}&body=${encodeURIComponent(fullMessage)}&cc=${encodeURIComponent(
          ccString
        )}`;

        // Generate the content for the Word file
        const content = `${fullMessage}\n\nCC: ${ccString}\n\nTo:${emailString} `;
        const blob = new Blob([content], { type: "application/msword" });

        // Create a URL for the Blob
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "contract_email.doc";
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      } else {
        console.error("No receivers found");
      }
    } catch (error) {
      console.error("Error opening mail:", error);
    }
  };
  
  return (
    <Dialog
      open={open}
      aria-labelledby="download-dialog"
      aria-describedby="download-dialog-description"
      fullScreen={isMobile}
      onClose={onCancel}
    >
      <Box
        display="flex"
        flexDirection="column"
        width={isMobile ? "100%" : "524px"}
      >
        <Box
          height="62px"
          boxShadow="0px 1px 0px #E0E0E0"
          alignItems="center"
          display="flex"
          width="100w"
        >
          <Typography
            variant="h5"
            style={{ fontWeight: 700, marginLeft: "24px" }}
          >
            Download your requests
          </Typography>
        </Box>

        <Box pt="24px" pb="24px" pl="20px" pr="20px">
          <Typography variant="body1" style={{ fontWeight: 700 }}>
            What type of file would you like to download?
          </Typography>

          <Box display="flex" flexDirection={"row"} mt="4px">
            <RadioWithTitle
              title="PDF (.pdf)"
              selected={documentType === DocumentType.pdf}
              onSelected={() => setDocumentType(DocumentType.pdf)}
            />
            <Box width="15px" />
            <RadioWithTitle
              title="Word document (.docx)"
              selected={documentType === DocumentType.word}
              onSelected={() => setDocumentType(DocumentType.word)}
            />
          </Box>

          {canIncludeComments && (
            <>
              <Typography
                variant="body1"
                style={{ fontWeight: 700, marginTop: "24px" }}
              >
                Would you like to include comments?
              </Typography>

              <Box display="flex" flexDirection={"row"} mt="4px">
                <RadioWithTitle
                  title="Yes"
                  selected={includeComments === true}
                  onSelected={() => setIncludeComments(true)}
                />
                <Box width="15px" />
                <RadioWithTitle
                  title="No"
                  selected={includeComments === false}
                  onSelected={() => setIncludeComments(false)}
                />
              </Box>
            </>
          )}

          {/* <Box pt="24px" pb="24px" pr="20px">
            <Typography variant="body1" style={{ fontWeight: 700 }}>
              Would you like to download email?
            </Typography>

            <Box display="flex" flexDirection={"row"} mt="4px">
              <RadioWithTitle
                title="Yes (.docx)"
                selected={Download === true}
                onSelected={() => SetDownload(true)}
              />

              <Box width="15px" />
              <RadioWithTitle
                title="No"
                selected={Download === false}
                onSelected={() => SetDownload(false)}
                // onSelected={() => setDocumentType(DocumentType.pdf)}
              />
            </Box>
          </Box> */}
        </Box>

        <Box
          height="74px"
          boxShadow={"0px -1px 0px #E0E0E0"}
          display="flex"
          flexDirection={"row"}
          alignItems="center"
          justifyContent={"flex-end"}
          pr="16px"
          boxSizing="border-box"
        >
          <Button
            type={ButtonType.secondary}
            title="CANCEL"
            width="114px"
            onClick={onCancel}
          />
          <Box width="8px" />

          {documentType === DocumentType.pdf && (
            <div>
              <PDFDownloadLink
                document={
                  <PDFDocument
                    requests={requestsWithComments}
                    enquiries={enquiriesWithComments}
                    property={property}
                    contract={contract}
                    recordId={recordId}
                    contractS={contractS}
                    userTeamMemberRole={userTeamMemberRole}
                    comments={includeComments}
                    requestsForName={requestsForName}
                  />
                }
                fileName={`${filename}.pdf`}
                style={{ textDecoration: "none" }}
              >
                {({ blob, url, loading, error }) => {
                  return (
                    <Button
                      loading={
                        loading ||
                        !requestsCommentsLoaded ||
                        !enquiriesCommentsLoaded
                      }
                      onClick={() => {
                        if (Download === true) {
                          openMail();
                        }
                      }}
                      title={"DOWNLOAD"}
                      type={ButtonType.primary}
                      width="136px"
                    />
                  );
                }}
              </PDFDownloadLink>
            </div>
          )}

          {documentType !== DocumentType.pdf && (
            
            
            <Button
              type={ButtonType.primary}
              title="DOWNLOAD"
              width="136px"
              onClick={() => {
                if (Download === true) {
                  openMail();
                }
                if (documentType === DocumentType.word) {
                  generateRequestsDocxAndSave({
                    property,
                    requests,
                    enquiries,
                    contract,
                    contractId: contract.id,
                    recordId,
                    userTeamMemberRole,
                    requestsForName,
                    includeComments,
                  });
                }
              }}
            />
          )}
        </Box>
      </Box>
    </Dialog>
  );
}
