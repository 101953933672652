import React from "react";
import { Box, Typography } from "@material-ui/core";
import Wrapper from "../../components/UserAccount/Wrapper";
import Button, { ButtonType } from "../../components/common/Button";
import { useMobile } from "../../hooks/mobile";
import { useHistory } from "react-router-dom";
import { PaymentMethod } from "../../api/models";
import { useAlert } from "../../contexts/Alert";
import { parseApiError } from "../../helpers/error";
import api from "../../api/instance";
import { find, isNil } from "lodash-es";
import { useSessionStore } from "../../stores/Session";
import CardBrandItem from "../../components/PurchaseCredits/CardBrandItem";
import RemoveCardDialog from "../../components/UserAccount/RemoveCardDialog";

export default function Billing() {
  const isMobile = useMobile();
  const history = useHistory();

  const [fetchingDefaultPayment, setFetchingDefaultPayment] =
    React.useState(false);
  const [defaultPaymentMethod, setDefaultPaymentMethod] =
    React.useState<PaymentMethod | null>(null);

  const { setErrorMessage, setSuccessMessage } = useAlert();

  const { data: user } = useSessionStore();

  const [removingCard, setRemovingCard] = React.useState(false);

  const [removeCardDialogOpen, setRemoveCardDialogOpen] = React.useState(false);

  function fetchDefaultPayment() {
    setFetchingDefaultPayment(true);

    api
      .get("/payment-methods/")
      .then((response) => {
        setFetchingDefaultPayment(false);

        const paymentMethods = response.data as PaymentMethod[];

        const defaultCard = find(paymentMethods, { is_enabled: true });

        if (defaultCard) {
          setDefaultPaymentMethod(defaultCard);
        } else {
          setDefaultPaymentMethod(null);
        }
      })
      .catch((error) => {
        setFetchingDefaultPayment(false);

        setErrorMessage(parseApiError(error));
      });
  }

  React.useEffect(() => {
    fetchDefaultPayment();
  }, []);

  const onRemoveCard = () => {
    setRemoveCardDialogOpen(false);
    setRemovingCard(true);

    api
      .delete("/payment-methods/all")
      .then((response) => {
        fetchDefaultPayment();

        setRemovingCard(false);

        setSuccessMessage("Card removed!");
      })
      .catch((error) => {
        setErrorMessage(parseApiError(error));
      });
  };

  return (
    <Wrapper title="Billing information">
      <Typography
        variant="body1"
        style={{
          fontWeight: 700,
          marginTop: isMobile ? undefined : "56px",
          marginBottom: "24px",
        }}
      >
        Current billing
      </Typography>

      {defaultPaymentMethod && (
        <>
          <CardBrandItem brand={defaultPaymentMethod?.brand} />
          <Typography variant="body1" style={{ marginTop: "8px" }}>
            •••• {defaultPaymentMethod?.last_digits}
          </Typography>
          <Typography variant="body1">
            {defaultPaymentMethod?.exp_month}/{defaultPaymentMethod?.exp_year}
          </Typography>
          <Typography variant="body1">
            {user?.current_organization?.name ||
              `${user?.first_name} ${user?.last_name}`}
          </Typography>
        </>
      )}

      {isNil(defaultPaymentMethod) && !fetchingDefaultPayment && (
        <Typography>No saved payment details.</Typography>
      )}

      <Box mt="24px" display="flex" flexDirection={isMobile ? "column" : "row"}>
        <Button
          title={
            defaultPaymentMethod ? "UPDATE CARD DETAILS" : "ADD CARD DETAILS"
          }
          width={isMobile ? "100%" : "226px"}
          onClick={() => history.push("/user/card/edit")}
        />
        <Box width="16px" height="16px" />
        <Button
          title="REMOVE CARD"
          type={ButtonType.destructive}
          width={isMobile ? "100%" : "168px"}
          loading={removingCard}
          onClick={() => setRemoveCardDialogOpen(true)}
          disabled={isNil(defaultPaymentMethod)}
        />
      </Box>

      <RemoveCardDialog
        open={removeCardDialogOpen}
        onYes={onRemoveCard}
        onNo={() => setRemoveCardDialogOpen(false)}
        onClose={() => setRemoveCardDialogOpen(false)}
      />

      {/* <Box border="1px solid #ECF2FB" width="100%" mt="16px" mb="32px" />

      <Typography
        variant="body1"
        style={{ fontWeight: 700, marginTop: "56px", marginBottom: "16px" }}
      >
        Billing address
      </Typography>

      <Typography variant="body1">
        123 Park Lane
        <br />
        Sydney NSW 2000
      </Typography>

      <Typography
        variant="body1"
        style={{ fontWeight: 700, marginTop: "56px", marginBottom: "16px" }}
      >
        ABN
      </Typography>
      <Typography variant="body1">123 456 789</Typography>

      <Box mt="24px" mb="55px">
        <Button
          title="UPDATE BILLING DETAILS"
          width={isMobile ? "100%" : "244px"}
          onClick={() => history.push("/user/billing/edit")}
        />
      </Box> */}
    </Wrapper>
  );
}
