import { User, TeamMember } from "../api/models";
import { isNil } from "lodash-es";

export function initialsForUser(user: User) {
  return (
    user?.first_name.charAt(0).toUpperCase() +
    user?.last_name.charAt(0).toUpperCase()
  );
}

export function nameForUser(user: User) {
  return `${user?.first_name} ${user?.last_name}`;
}

export function initialsForTeamMember(member: TeamMember) {
  return (
    member?.first_name.charAt(0).toUpperCase() +
    member?.last_name.charAt(0).toUpperCase()
  );
}

export function nameForTeamMember(member: TeamMember) {
  return `${member?.first_name} ${member?.last_name}`;
}
