import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import ShareLink from "../../pages/ShareLink";
import Button, { ButtonSize, ButtonType } from "../common/Button";

interface Props {
  open: boolean;
  onClose: () => unknown;
  contractId?: number | null;
}

export default function ShareLinkDialog({ open, onClose, contractId }: Props) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Share Contract"}</DialogTitle>
      <DialogContent>
        <ShareLink contractId={contractId} />
      </DialogContent>
      <DialogActions>
        <Button
          type={ButtonType.primary}
          size={ButtonSize.medium}
          onClick={onClose}
          title="OK"
        />
      </DialogActions>
    </Dialog>
  );
}
