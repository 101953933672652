import { Box, Typography } from "@material-ui/core";
import { isNil } from "lodash-es";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import { Activity, Contract, Property, TeamMemberRole } from "../api/models";
import ActivityLog from "../components/ActivityLog/ActivityLog";
import AlertWrapper from "../components/AlertWrapper";
import LegalReviewProgressBar from "../components/LegalReviewProgressBar";
import InviteModal from "../components/LegalReviewViewer/InviteModal";
import InviteYourTeamMembersModal, {
  InviteYourTeamMembersModalType,
} from "../components/LegalReviewViewer/InviteYourTeamMembersModal";
import LegalReviewWhiteHeader from "../components/LegalReviewWhiteHeader";
import OnboardingWrapper from "../components/OnboardingWrapper";
import { useAlert } from "../contexts/Alert";
import { useMobile } from "../hooks/mobile";
import Hero from "../images/lawyer-hero.svg";
import { refreshContractTeamsAndRole } from "../stores/contract";

interface ContentProps {
  contractId: number;
  recordId: number;
}

function Content({ contractId, recordId }: ContentProps) {
  const isMobile = useMobile();

  return (
    <Box
      display="flex"
      flex={1}
      justifyContent="center"
      alignItems="center"
      height="80vh"
    >
      <Box
        width={isMobile ? "90%" : "385px"}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {!isMobile && (
          <LegalReviewProgressBar contractId={contractId} recordId={recordId} />
        )}

        <img
          src={Hero}
          style={{ marginTop: "16px", marginBottom: "10px" }}
          height={isMobile ? "200px" : undefined}
        />

        <Typography
          variant="h6"
          style={{ fontWeight: 700, textAlign: "center" }}
        >
          Your legal review is being prepared
        </Typography>
        <Typography
          variant="body2"
          style={{
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "27px",
          }}
        >
          We'll notify you when it's ready to view and discuss with your legal
          representative in your workspace.
        </Typography>
      </Box>
    </Box>
  );
}

interface Props {
  onLoggedIn: () => unknown;
  onSignedUp: () => unknown;
}

export default function ({ onLoggedIn, onSignedUp }: Props) {
  const isMobile = useMobile();

  const location = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(location.search);
  const _contractId = query.get("contractId");
  const contractId = !isNil(_contractId) ? parseInt(_contractId) : null;
  const _recordId = query.get("recordId");
  const recordId = !isNil(_recordId) ? parseInt(_recordId) : null;

  const { data, mutate: contractMutate } = useSWR(
    `/view-contracts/${contractId}/`
  );
  const contract = data ? (data as Contract) : null;

  const { data: propertyData } = useSWR(
    contract?.property_id ? `/view-properties/${contract.property_id}/` : null
  );
  const property = propertyData && (propertyData as Property);

  const { data: activitiesData, mutate: activitiesMutate } = useSWR(
    !isNil(contractId)
      ? `/contract/${contractId}/record/${recordId}/activities/`
      : null
  );

  const activities = activitiesData ? (activitiesData as Activity[]) : [];

  const [inviteModalOpen, setInviteModalOpen] = React.useState(false);
  const [inviteYourTeamMembersOpen, setInviteYourTeamMembersOpen] =
    React.useState(false);

  const [inviteYourTeamMembersType, setInviteYourTeamMembersType] =
    React.useState(InviteYourTeamMembersModalType.default);

  const [
    inviteYourTeamMembersUserTeamRole,
    setInviteYourTeamMembersUserTeamRole,
  ] = React.useState<TeamMemberRole | null>(null);

  const { setErrorMessage, setSuccessMessage } = useAlert();

  return (
    <OnboardingWrapper onLoggedIn={onLoggedIn} onSignedUp={onSignedUp}>
      <AlertWrapper>
        <Box display="flex" flexDirection="column">
          <LegalReviewWhiteHeader
            property={property}
            onInviteClicked={() => {
              setInviteModalOpen(true);
            }}
          />

          {isMobile && contractId && recordId && (
            <Box>
              <Content contractId={+contractId} recordId={+recordId} />
            </Box>
          )}

          {!isMobile && contractId && recordId && (
            <Box display="flex" flexDirection="row">
              <Content contractId={+contractId} recordId={+recordId} />
              <Box mt="40px" width="353px">
                <Typography variant="h6">Activity Log</Typography>
                <ActivityLog activities={activities} />
              </Box>
            </Box>
          )}
        </Box>
      </AlertWrapper>

      <InviteModal
        open={inviteModalOpen}
        onClose={() => setInviteModalOpen(false)}
        onInviteTeamMembersClicked={(userTeamMemberRole) => {
          setInviteModalOpen(false);
          setInviteYourTeamMembersType(InviteYourTeamMembersModalType.default);
          setInviteYourTeamMembersUserTeamRole(userTeamMemberRole);
          setInviteYourTeamMembersOpen(true);
        }}
        onInviteAgents={() => {
          setInviteModalOpen(false);
          setInviteYourTeamMembersType(InviteYourTeamMembersModalType.agents);
          setInviteYourTeamMembersUserTeamRole(null);
          setInviteYourTeamMembersOpen(true);
        }}
        contractId={contractId}
        recordId={recordId}
      />

      <InviteYourTeamMembersModal
        open={inviteYourTeamMembersOpen}
        onClose={() => setInviteYourTeamMembersOpen(false)}
        onInvited={(firstName, lastName) => {
          setInviteYourTeamMembersOpen(false);
          setSuccessMessage(
            `${firstName} ${lastName} has been invited to your team`
          );

          refreshContractTeamsAndRole();

          setInviteModalOpen(true);
        }}
        contractId={contractId}
        recordId={recordId}
        userTeamMemberRole={inviteYourTeamMembersUserTeamRole}
        type={inviteYourTeamMembersType}
      />
    </OnboardingWrapper>
  );
}
