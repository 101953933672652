import React from "react";
import { Box, Link, Typography, ButtonBase } from "@material-ui/core";
import { MyProperty, addressForProperty, UserType } from "../api/models";
import { Colors } from "../constants/colors";
import NoPropertyImage from "../images/no-property-image.svg";
import InviteImage from "../images/invite.svg";
import ViewedImage from "../images/view.svg";
import PurchaseImage from "../images/purchase.svg";
import { useMobile, useDesktop } from "../hooks/mobile";



interface Props {
  property: MyProperty;
  onClick?: () => unknown;
  status?: string | null;
}

function SoldBadge() {
  return (
    <Box
      borderRadius="14px"
      width="54px"
      height="20px"
      bgcolor="white"
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="absolute"
    >
      <Box
        color={Colors.BrandPrimary}
        textAlign="center"
        fontFamily="GT Walsheim Pro"
        fontStyle="normal"
        fontWeight={700}
        fontSize="12px"
        lineHeight="16px"
        letterSpacing="2px"
      >
        SOLD
      </Box>
    </Box>
  );
}

export default function MyPropertyRow({
  property,
  onClick,
  status
}: Props): JSX.Element {

  const isMobile = useMobile();
  const isDesktop = useDesktop();

  if (!property) {
    return <Box />;
  }
  return (
    <Box>
      {property.status.toLowerCase() === "sold" && (
        <Box ml={0.5}>
          <SoldBadge />
        </Box>
      )}

      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        bgcolor={Colors.Grey5}
        pl={1}
        pr={1}
        pt={1.5}
        pb={1.5}
        mt={0.5}
        mb={0.5}
        flex={1}
        onClick={onClick}
        style={{ cursor: "pointer" }}
      >
        <Box mr={1} width="118px" height="88px" borderRadius="4px" overflow="hidden">
          {property.image && (
            <img
              src={property.image}
              width="100%"
              height="100%"
              style={{ objectFit: "contain" }}
            />
          )}
          {!property.image && <img src={NoPropertyImage} />}
        </Box>
        <Box flexGrow={1} display="flex" flexDirection="column">
          <Typography variant="body2">{property.address}</Typography>
          <Typography variant="caption" style={{ marginTop: "8px" }}>
            {property.agent}
          </Typography>
          <Typography variant="caption" style={{ color: "#9F9F9F" }}>
            {property.agency}
          </Typography>
          <Box mt={2} />
          {isMobile && 
          <Box display="flex" width="100px" justifyContent="space-between" flexDirection="row">
            <Typography variant="subtitle2">
              <img src={InviteImage} /> {" "}
              <span style={{ color: "rgb(0, 56, 228)" }}>{property && property.invite_count}
              </span></Typography>
            <Typography variant="subtitle2">
              <img src={ViewedImage} /> {" "}
              <span style={{ color: "rgb(0, 56, 228)" }}>{property && property.view_count} </span>
            </Typography>
            <Typography variant="subtitle2">
              <img src={PurchaseImage} /> {" "}
              <span style={{ color: "rgb(0, 56, 228)" }}>{property && property.purchase_count}  </span>
            </Typography>
          </Box>}
        </Box>
        {isDesktop && status === UserType.Agent &&
        <Box display="flex" flexDirection="column">
          <Typography variant="subtitle2">
            <span style={{ color: "rgb(0, 56, 228)", paddingRight: "10px"}}>{property && property.invite_count}
            </span> {"  "} <img src={InviteImage} /> </Typography>
          <Typography variant="subtitle2">
            <span style={{ color: "rgb(0, 56, 228)", paddingRight: "10px"}}>{property && property.view_count} </span>
            {"  "} <img src={ViewedImage} /></Typography>
          <Typography variant="subtitle2">
            <span style={{ color: "rgb(0, 56, 228)", paddingRight: "10px"}}>{property && property.purchase_count}  </span>
            {"  "} <img src={PurchaseImage} /></Typography>
        </Box>}
      </Box>
    </Box>
  );
}
