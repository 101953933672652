import { Box, Typography } from "@material-ui/core";
import ViewSDKClient from "../helpers/ViewSDKClient";
import produce from "immer";
import { isEmpty, isNil, sortBy } from "lodash-es";
import moment from "moment";
import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import api from "../api/instance";
import {
  Contract,
  ContractPublishStatus,
  ContractSummary,
  ContractSummaryState,
  ContractWorkflowStatus,
  TeamMemberRole,
} from "../api/models";
import AlertWrapper from "../components/AlertWrapper";
import AuthenticatedWrapper from "../components/AuthenticatedWrapper";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";
import StickyHeader from "../components/common/StickyHeader";
import Switch from "../components/common/Switch";
import LegalReviewBlueHeader from "../components/LegalReviewBlueHeader";
import CustomSummaryBox from "../components/LegalReviewBuilder/CustomSummaryBox";
import SummaryEditorBox, {
  SummaryEditorBoxType,
} from "../components/LegalReviewBuilder/SummaryEditorBox";
import SummaryItem from "../components/LegalReviewBuilder/SummaryItem";
import { Colors } from "../constants/colors";
import { useAlert } from "../contexts/Alert";
import { useMobile, useSmallHeight } from "../hooks/mobile";
import useInterval from "../hooks/useInterval";
import "../styles/podium.css";
import arrayMove from "array-move";
import {
  refreshContractTeamsAndRole,
  useContractStore,
} from "../stores/contract";
import scrollIntoView from "scroll-into-view";

interface SecondaryHeaderProps {
  onHideDismissedChecked: (checked: boolean) => unknown;
  hideDismissed: boolean;
  addedCount: number;
  dismissedCount: number;
}

function SecondaryHeader({
  onHideDismissedChecked,
  hideDismissed,
  addedCount,
  dismissedCount,
}: SecondaryHeaderProps) {
  const isMobile = useMobile();

  return (
    <StickyHeader
      height="52px"
      bgcolor="white"
      display="flex"
      flexDirection="row"
      width="100%"
      alignItems="center"
      justifyContent="space-between"
      boxShadow="0px 1px 0px #E0E0E0"
      top={72}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        height="100%"
        pl={isMobile ? "20px" : "24px"}
      >
        <Typography variant={isMobile ? "caption" : "body2"}>
          {addedCount} Added and {dismissedCount} Dismissed
        </Typography>
      </Box>

      <Box
        pr={isMobile ? "15px" : "24px"}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Switch
          checked={hideDismissed}
          onChange={(event) => onHideDismissedChecked(event.target.checked)}
        />
        <Typography variant="body1" style={{ marginLeft: "8px" }}>
          Hide dismissed
        </Typography>
      </Box>
    </StickyHeader>
  );
}

export default function () {
  const location = useLocation();
  const history = useHistory();
  const { setErrorMessage } = useAlert();
  const isMobile = useMobile();
  const isSmallHeight = useSmallHeight();

  const query = new URLSearchParams(location.search);
  const contractId = query.get("contractId");
  const recordId = query.get("recordId");

  const { userTeamMemberRole, setContractAndRecordId } = useContractStore();

  const [hideDismissed, setHideDismissed] = React.useState(true);

  const { data } = useSWR(
    `/view-contracts/${contractId}/?recordId=${recordId}`
  );
  const contract = data ? (data as Contract) : null;
  const contractFile = contract ? contract.file : null;

  const [summaries, setSummaries] = React.useState<ContractSummary[]>([]);

  const [pdfLoaded, setPdfLoaded] = React.useState(false);

  const [showAddCustomSummary, setShowAddCustomSummary] = React.useState(false);

  const [hasNewEdits, setHasNewEdits] = React.useState(false);

  const [deletedSummaries, setDeletedSummaries] = React.useState<
    ContractSummary[]
  >([]);

  const [addingSummary, setAddingSummary] = React.useState(false);

  const [lastSaved, setLastSaved] = React.useState<Date | null>(null);

  const [adobeEmbedInstance, setAdobeEmbedInstance] = React.useState<
    any | null
  >(null);

  const customSummaryRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (contract) {
      const legalReviewsPublished =
        contract.publish_status === ContractPublishStatus.Published &&
        contract.workflow_status === ContractWorkflowStatus.Reviewed;

      // Redirect to landing
      if (!legalReviewsPublished) {
        history.push(
          `/legal-review-landing?contractId=${contractId}&recordId=${recordId}`
        );
      }
    }
  }, [contract]);

  React.useEffect(() => {
    if (!isNil(contractId) && !isNil(recordId)) {
      setContractAndRecordId(+contractId, +recordId);
      refreshContractTeamsAndRole();
    }
  }, [contractId, recordId]);

  React.useEffect(() => {
    if (
      userTeamMemberRole === TeamMemberRole.buyer ||
      userTeamMemberRole === TeamMemberRole.seller ||
      userTeamMemberRole === TeamMemberRole.agent ||
      userTeamMemberRole === TeamMemberRole.partner
    ) {
      // Redirect to dashboard

      history.push(`/dashboard`);
    }
  }, [userTeamMemberRole]);

  const addNewDefaultSummaries = (defaultSummaries: ContractSummary[]) => {
    // const promises = summaries.map((s) => addNewDefaultSummary(s));

    // Promise.all(promises).then((_newSummaries) => {
    //   const newSummaries = sortBy(_newSummaries, ["page"]);

    //   setSummaries((oldSummaries) => [...oldSummaries, ...newSummaries]);
    // });

    defaultSummaries.forEach((summary) => {
      summary.status = ContractSummaryState.editing;
      summary.p_isDefault = true;
    });

    setSummaries((oldSummaries) => [...oldSummaries, ...defaultSummaries]);
  };

  const addNewDefaultSummary = (
    summary: ContractSummary
  ): Promise<ContractSummary> => {
    return new Promise((resolve, reject) => {
      const { title, content, page, status } = summary;

      api
        .post(`/contract/${contractId}/record/${recordId}/summary/`, {
          title,
          content,
          page,
          status,
          summary_pk: summary.pk,
        })
        .then((response) => {
          const newSummary = response.data as ContractSummary;

          resolve(newSummary);
        })
        .catch((error) => {
          console.debug("error adding default summary: ", error);

          reject(error);
        });
    });
  };

  const fetchSummaries = () => {
    // Fetch legal review summaries first
    api
      .get(`contract/${contractId}/record/${recordId}/legal-review-summaries/`)
      .then((response) => {
        const savedSummaries = response.data as ContractSummary[];

        if (savedSummaries.length > 0) {
          setSummaries(savedSummaries);
        }

        // Fetch default ones
        api
          .get(`contract/${contractId}/record/${recordId}/contrax-summaries/`)
          .then((response) => {
            const defaultSummaries = response.data as ContractSummary[];

            if (isEmpty(savedSummaries)) {
              // If no saved summaries yet, add all default summaries...

              addNewDefaultSummaries(defaultSummaries);
            } else {
              // Cross check and add the new ones
              // Check against a summary's last modified
              // const firstSavedSummary = savedSummaries[0];

              // const lastModifiedMoment = moment(firstSavedSummary.modified);

              // const newDefaultSummaries = defaultSummaries.filter((summary) =>
              //   moment(summary.modified).isAfter(lastModifiedMoment)
              // );

              // addNewDefaultSummaries(newDefaultSummaries);

              const savedDefaultSummaryPks = savedSummaries.map(
                (s) => s.default_summary
              );

              const newDefaultSummaries = defaultSummaries.filter(
                (defaultSummary) =>
                  !savedDefaultSummaryPks.includes(defaultSummary.pk)
              );

              console.debug("newDefaultSummaries: ", newDefaultSummaries);

              addNewDefaultSummaries(newDefaultSummaries);
            }
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  };

  interface SaveProps {
    savingClosing?: boolean;
  }

  const save = ({ savingClosing = false }: SaveProps) => {
    console.debug("saving ... ");
    console.debug("summaries: ", summaries);

    let data = summaries
      .filter((summary) => !isNil(summary) && summary.p_isDefault !== true)
      .map((summary, index) => {
        const { pk, title, page, content, status } = summary;
        return {
          pk,
          title,
          page,
          content,
          status,
          index: index + 1,
        };
      });

    const deletingSummaries = deletedSummaries.filter(
      (summary) => !isNil(summary.pk)
    );

    const deletingSummariesPks = deletingSummaries.map((summary) => summary.pk);

    data = data.concat(
      deletingSummaries
        .filter((summary) => !isNil(summary.pk))
        .map((summary) => {
          const { pk, title, page, content } = summary;

          return {
            pk,
            title,
            page,
            content,
            status: ContractSummaryState.deleted,
            index: 1,
          };
        })
    );

    api
      .post(`/contract/${contractId}/record/${recordId}/legal-summaries/`, {
        data,
      })
      .then((response) => {
        const savedSummaries = response.data as ContractSummary[];

        // Remove deleted summaries (there could be new ones while API is calling)
        const newDeletedSummaries = deletedSummaries.filter(
          (ds) => !deletingSummariesPks.includes(ds.pk)
        );

        setDeletedSummaries(newDeletedSummaries);
        setHasNewEdits(false);

        setLastSaved(new Date());

        if (savingClosing) {
          history.push(
            `/legal-review-viewer?contractId=${contractId}&recordId=${recordId}`
          );
        }
      })
      .catch((error) => {
        console.debug("error: ", error);
      });
  };

  React.useEffect(() => {
    fetchSummaries();
  }, []);

  useInterval(() => {
    save({ savingClosing: false });
  }, 10000); // 10 Seconds

  const addedSummaries = summaries.filter(
    (summary) => summary.status === ContractSummaryState.published
  ).length;
  const dismissedSummaries = summaries.filter(
    (summary) => summary.status === ContractSummaryState.dismissed
  ).length;

  const onBeforeUnload = React.useCallback((e: BeforeUnloadEvent) => {
    var confirmationMessage =
      "It looks like you have unsaved changes. " +
      "If you leave before saving, your changes will be lost.";

    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
  }, []);

  React.useEffect(() => {
    if (hasNewEdits) {
      // Add close browser prompt if unsaved changes
      window.addEventListener("beforeunload", onBeforeUnload);
    } else {
      window.removeEventListener("beforeunload", onBeforeUnload);
    }
  }, [hasNewEdits]);

  const viewInContract = (page: number) => {
    if (isMobile) {
      window.open(
        `/app/sale-contract?contractId=${contractId}&recordId=${recordId}&page=${page}`,
        "_blank"
      );
    } else {
      adobeEmbedInstance.then((adobeViewer) => {
        adobeViewer.getAPIs().then((apis) => {
          apis
            .gotoLocation(page)
            .then(() => console.log("Success"))
            .catch((error) => console.log(error));
        });
      });
    }
  };

  const _subtitle = () => {
    if (lastSaved) {
      return `Last save was ${moment(lastSaved).fromNow()}`;
    }

    if (!isEmpty(summaries) && !isNil(summaries[0])) {
      const fifteenDaysAgo = moment().subtract(15, "days");
      const lastModified = moment(summaries[0].modified);

      if (lastModified.isSameOrBefore(fifteenDaysAgo)) {
        return lastModified.format("MMMM D YYYY");
      }

      return `Last save was ${lastModified.fromNow()}`;
    }

    return undefined;
  };

  const subtitle = _subtitle();

  // function previewFile(file) {
  //   var reader = new FileReader();

  //   reader.onloadend = function () {
  //     console.log(reader.result); //this is an ArrayBuffer
  //   };
  //   reader.readAsArrayBuffer(file);
  // }

  React.useEffect(() => {
    if (contractFile && !pdfLoaded) {
      const viewSDKClient = new ViewSDKClient();
      viewSDKClient.ready().then(() => {
        /* Invoke file preview */
        const AdobeEmbedInstance = viewSDKClient.previewFile(
          "pdf-div",
          contractFile,
          "Contrax.pdf",
          {
            showAnnotationTools: false,
            enableFormFilling: false,
            dockPageControls: false,
            showPageControls: false,
            showDownloadPDF: false,
            showLeftHandPanel: false,
            showPrintPDF: false,
          }
        );

        setAdobeEmbedInstance(AdobeEmbedInstance);
      });
      setPdfLoaded(true);
    }
  }, [contractFile]);

  React.useEffect(() => {
    if (showAddCustomSummary) {
      customSummaryRef.current &&
        scrollIntoView(customSummaryRef.current, { time: 250 });
    }
  }, [showAddCustomSummary]);

  return (
    <AuthenticatedWrapper>
      <AlertWrapper>
        <Box
          height="100vh"
          display="flex"
          flexDirection="column"
          bgcolor={Colors.BrandBackground3}
        >
          <LegalReviewBlueHeader
            title="Build your legal review"
            subtitle={subtitle}
            onViewContractClicked={() => {
              window.open(
                `/app/sale-contract?contractId=${contractId}&recordId=${recordId}`,
                "_blank"
              );
            }}
            onDoneClicked={() => {
              save({ savingClosing: true });
            }}
          />
          <SecondaryHeader
            onHideDismissedChecked={(checked) => setHideDismissed(checked)}
            hideDismissed={hideDismissed}
            addedCount={addedSummaries}
            dismissedCount={dismissedSummaries}
          />
          <Box
            display="flex"
            flexDirection="row"
            flex={1}
            maxWidth="1100px"
            maxHeight={isSmallHeight ? "70vh" : "80vh"}
            alignSelf="center"
            mt="32px"
            mb="32px"
          >
            <div
              id="pdf-div"
              className="sized-container-div"
              style={{ width: isMobile ? 0 : "50vw" }}
            />

            <Box bgcolor="white" width={isMobile ? "100%" : "50vw"}>
              <Box
                justifyContent="space-between"
                flexDirection="row"
                display="flex"
                alignItems="center"
                pl="23px"
                pr="18px"
                pt="16px"
                pb="16px"
              >
                {!isMobile && (
                  <Typography variant="h6" style={{ fontWeight: 700 }}>
                    Your legal review
                  </Typography>
                )}

                <Button
                  title="ADD CUSTOM SUMMARY"
                  type={ButtonType.secondary}
                  size={ButtonSize.small}
                  onClick={() => setShowAddCustomSummary(true)}
                  width={isMobile ? "100%" : undefined}
                />
              </Box>

              <Box style={{ maxHeight: "90%", overflowY: "scroll" }}>
                {/* Add custom summary */}
                {showAddCustomSummary && (
                  <div ref={customSummaryRef}>
                    <CustomSummaryBox
                      adding={addingSummary}
                      onAddSummary={(title, content, page) => {
                        setAddingSummary(true);

                        api
                          .post(
                            `/contract/${contractId}/record/${recordId}/summary/`,
                            {
                              title,
                              content,
                              page,
                            }
                          )
                          .then((response) => {
                            setAddingSummary(false);

                            const newSummary = response.data as ContractSummary;
                            newSummary.status = ContractSummaryState.published;

                            //setTheArray(oldArray => [...oldArray, newElement]);
                            setSummaries((oldSummaries) => [
                              newSummary,
                              ...oldSummaries,
                            ]);
                            setShowAddCustomSummary(false);
                          })
                          .catch((error) => {
                            setAddingSummary(false);

                            setErrorMessage("Could not add new summary");
                          });
                      }}
                      onCancel={() => setShowAddCustomSummary(false)}
                    />
                  </div>
                )}

                {/* Summaries */}

                <DragDropContext
                  onDragEnd={(result, provided) => {
                    console.debug("onDragEnd result: ", result);
                    console.debug("onDragEnd provided: ", provided);

                    const sourceIndex = result.source.index;
                    const destinationIndex = result.destination?.index;

                    if (!isNil(destinationIndex)) {
                      setSummaries(
                        arrayMove(summaries, sourceIndex, destinationIndex)
                      );
                    }
                  }}
                >
                  <Droppable droppableId="list">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {summaries.map((summary, index) => {
                          if (summary.status === ContractSummaryState.editing) {
                            return (
                              <SummaryEditorBox
                                index={index}
                                contractSummary={summary}
                                onViewInContract={() =>
                                  viewInContract(summary.page)
                                }
                                onTitleChanged={(title) => {
                                  setSummaries(
                                    produce((draft) => {
                                      draft[index].title = title;

                                      const thisContractSummary = draft[index];

                                      if (
                                        thisContractSummary.p_isDefault &&
                                        !thisContractSummary.p_adding
                                      ) {
                                        draft[index].p_adding = true;

                                        addNewDefaultSummary(
                                          thisContractSummary
                                        ).then((savedSummary) => {
                                          setSummaries(
                                            produce((draft) => {
                                              draft[index] = savedSummary;
                                            })
                                          );
                                        });
                                      }
                                    })
                                  );

                                  setHasNewEdits(true);
                                }}
                                onSummaryChanged={(summary) => {
                                  setSummaries(
                                    produce((draft) => {
                                      draft[index].content = summary;

                                      const thisContractSummary = draft[index];

                                      if (
                                        thisContractSummary.p_isDefault &&
                                        !thisContractSummary.p_adding
                                      ) {
                                        draft[index].p_adding = true;

                                        addNewDefaultSummary(
                                          thisContractSummary
                                        ).then((savedSummary) => {
                                          setSummaries(
                                            produce((draft) => {
                                              draft[index] = savedSummary;
                                            })
                                          );
                                        });
                                      }
                                    })
                                  );

                                  setHasNewEdits(true);
                                }}
                                onAdd={() => {
                                  setSummaries(
                                    produce((draft) => {
                                      draft[index].status =
                                        ContractSummaryState.published;

                                      const thisContractSummary = draft[index];

                                      if (
                                        thisContractSummary.p_isDefault &&
                                        !thisContractSummary.p_adding
                                      ) {
                                        draft[index].p_adding = true;

                                        addNewDefaultSummary(
                                          thisContractSummary
                                        ).then((savedSummary) => {
                                          setSummaries(
                                            produce((draft) => {
                                              draft[index] = savedSummary;
                                            })
                                          );
                                        });
                                      }
                                    })
                                  );
                                }}
                                onDismiss={() => {
                                  setSummaries(
                                    produce((draft) => {
                                      draft[index].status =
                                        ContractSummaryState.dismissed;
                                    })
                                  );
                                }}
                              />
                            );
                          }

                          if (
                            summary.status === ContractSummaryState.dismissed
                          ) {
                            return !hideDismissed ? (
                              <SummaryEditorBox
                                index={index}
                                type={SummaryEditorBoxType.dismissed}
                                contractSummary={summary}
                                onViewInContract={() =>
                                  viewInContract(summary.page)
                                }
                                onDelete={() => {
                                  if (
                                    window.confirm(
                                      "Are you sure you want to delete this summary?"
                                    )
                                  ) {
                                    setSummaries(
                                      produce((draft) => {
                                        delete draft[index];
                                      })
                                    );

                                    if (!isNil(summary.pk)) {
                                      setDeletedSummaries(
                                        (oldDeletedSummaries) => [
                                          ...oldDeletedSummaries,
                                          summary,
                                        ]
                                      );
                                    }
                                  }
                                }}
                                onRestore={() => {
                                  setSummaries(
                                    produce((draft) => {
                                      draft[index].status =
                                        ContractSummaryState.editing;
                                    })
                                  );
                                }}
                              />
                            ) : null;
                          }

                          return (
                            <SummaryItem
                              contractSummary={summary}
                              onViewInContract={() =>
                                viewInContract(summary.page)
                              }
                              onEdit={() => {
                                setSummaries(
                                  produce((draft) => {
                                    draft[index].status =
                                      ContractSummaryState.editing;
                                  })
                                );
                              }}
                              index={index}
                            />
                          );
                        })}

                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Box>
            </Box>
          </Box>
        </Box>
      </AlertWrapper>
    </AuthenticatedWrapper>
  );
}
