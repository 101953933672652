import React from "react";
import { Box, Typography, CircularProgress, Dialog } from "@material-ui/core";
import AuthenticatedWrapper from "../../components/AuthenticatedWrapper";
import { useSessionStore } from "../../stores/Session";
import { useMobile, useDesktop } from "../../hooks/mobile";
import { Colors } from "../../constants/colors";
import DashboardHeaderDesktop from "../../components/DashboardHeaderDesktop";
import { MyTeamMember, User, UserType } from "../../api/models";
import Button, { ButtonType, ButtonSize } from "../../components/common/Button";
import DesktopFooter from "../../components/common/DesktopFooter";
import TextField from "../../components/common/TextField";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../../components/common/MobileStickyFooter";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash-es";
import api from "../../api/instance";
import { useAlert } from "../../contexts/Alert";
import { AxiosError } from "axios";
import AlertWrapper from "../../components/AlertWrapper";
import Separator from "../common/Separator";
import DeleteModal from "./DeleteModal";
import { parseApiError } from "../../helpers/error";
import AddMyTeamMemberModal from "./AddMyTeamMemberModal";

interface Props {
  // type?: AddMyTeamMemberModal;
  open: boolean;
  onSaved: () => unknown;
  onDeleted: () => unknown;
  onClose: () => unknown;
  editTeamMember: MyTeamMember;
  setEditTeamMemberDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function EditTeamAccountDetails({
  open,
  onSaved,
  onDeleted,
  onClose,
  editTeamMember,
  setEditTeamMemberDialogOpen,
}: Props): JSX.Element {
  const isMobile = useMobile();
  const history = useHistory();

  const { data: userData, refresh: refreshUserData } = useSessionStore();
  const user = userData && (userData as User);

  const [deleteModal, setDeleteModal] = React.useState(false);

  const openDeleteModal = () => {
    setDeleteModal(true);
    setEditTeamMemberDialogOpen(false);
  };

  const user_pk = editTeamMember.pk;
  // console.log("Testing PK", user_pk);

  const [firstName, setFirstName] = React.useState(editTeamMember.first_name);
  const [firstNameError, setFirstNameError] = React.useState<null | string>(
    null
  );

  const [lastName, setLastName] = React.useState(editTeamMember.last_name);
  const [lastNameError, setLastNameError] = React.useState<null | string>(null);

  const [email, setEmail] = React.useState(editTeamMember.email);
  const [emailError, setEmailError] = React.useState<null | string>(null);

  const [loading, setLoading] = React.useState(false);

  const { setSuccessMessage, setErrorMessage } = useAlert();

  // React.useEffect(() => {
  //   if (user) {
  //     setFirstName(user.first_name);
  //     setLastName(user.last_name);
  //     setEmail(user.email);
  //   }
  // }, [user]);

  // if (!user) {
  //   return <CircularProgress />;
  // }

  // const sideMargin = isMobile ? 3.25 : 21.5;

  function resetErrors() {
    setFirstNameError(null);
    setLastNameError(null);
    setEmailError(null);
  }

  function verifyFields() {
    let allFieldsValid = true;

    if (isEmpty(firstName)) {
      setFirstNameError("Please enter your first name");
      allFieldsValid = false;
    }
    if (isEmpty(lastName)) {
      setLastNameError("Please enter your last name");
      allFieldsValid = false;
    }
    if (isEmpty(email)) {
      setEmailError("Please enter your email number");
      allFieldsValid = false;
    }

    return allFieldsValid;
  }

  function saveClicked() {
    resetErrors();

    if (!verifyFields()) {
      return;
    }

    const commonData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
    };

    const data =
      user &&
      (user.user_type === UserType.Solicitor ||
        user?.user_type === UserType.Partner)
        ? {
            user_pk,
            ...commonData,
          }
        : commonData;

    console.debug("data: ", data);

    setLoading(true);

    api
      .patch("/update-organization-member/", data)
      .then((response) => {
        setLoading(false);

        onSaved();

        setSuccessMessage("Your account details have been updated");
      })
      .catch((error: AxiosError) => {
        setLoading(false);

        // setErrorMessage("Please check errors on this page");

        const errors = error && error.response && error.response.data;

        // console.log(errors);

        setErrorMessage(errors.message);

        const { first_name, last_name, email } = errors;
        first_name && setFirstNameError(first_name[0]);
        last_name && setLastNameError(last_name[0]);
        email && setEmailError(email[0]);
      });
  }

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="invite-dialog"
        aria-describedby="invite-dialog-description"
        onClose={onClose}
        fullScreen={isMobile}
      >
        <Box
          width={isMobile ? "100%" : "524px"}
          display="flex"
          flexDirection="column"
        >
          <Box
            height="62px"
            pl="24px"
            alignItems="center"
            display="flex"
            flexDirection="row"
          >
            <Typography variant="h5" style={{ fontWeight: 700 }}>
              Invite your team members
            </Typography>
          </Box>
          <Separator />
          <Box p="24px">
            <TextField
              title="Email"
              value={email}
              error={emailError !== null}
              helperText={emailError}
              disabled={true}
              onChange={(value) => setEmail(value)}
            />
            <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
              <TextField
                title="First name"
                value={firstName}
                error={firstNameError !== null}
                helperText={firstNameError}
                onChange={(value) => setFirstName(value)}
              />
              <Box width="12.5px" />
              <TextField
                title="Last Name"
                value={lastName}
                error={lastNameError !== null}
                helperText={lastNameError}
                onChange={(value) => setLastName(value)}
              />
            </Box>
          </Box>
          <Separator />
          <Box
            display="flex"
            justifyContent="flex-end"
            pl="16px"
            pr="16px"
            height="74px"
            alignItems="center"
          >
            <Button
              title="DELETE"
              size={ButtonSize.medium}
              type={ButtonType.destructive}
              onClick={openDeleteModal}
              width="114px"
            />
            <Box width="8px" mr={isMobile ? "65px" : "150px"} />
            <Button
              title="CANCEL"
              size={ButtonSize.medium}
              type={ButtonType.secondary}
              onClick={onClose}
              width="114px"
            />

            <Box width="8px" />
            <Button
              loading={loading}
              title="SAVE"
              size={ButtonSize.medium}
              type={ButtonType.primary}
              onClick={() => saveClicked()}
              width="93px"
            />
          </Box>
        </Box>
      </Dialog>
      <DeleteModal
        open={deleteModal}
        onDeleted={() => {
          setDeleteModal(false);
          onDeleted();
        }}
        onClose={() => setDeleteModal(false)}
        deleteTeamMember={editTeamMember}
      />
    </>
  );
}
