import { isNil } from "lodash-es";
import create from "zustand";
import api from "../api/instance";
import { TeamMember, TeamMemberRole } from "../api/models";
import { useSessionStore } from "./Session";

interface State {
  contractId: number | null;
  recordId: number | null;
  setContractAndRecordId: (contractId: number, recordId: number) => unknown;
  userTeamMemberRole: TeamMemberRole | null;
  buyerTeamMembers: TeamMember[];
  sellerTeamMembers: TeamMember[];
  agentTeamMembers: TeamMember[];
  teamMembers: TeamMember[];
  otherPartyMembers: TeamMember[];
  loadingAgentTeamContractId: number | null;
  loadingBuyerTeamRecordId: number | null;
  loadingSellerTeamRecordId: number | null;
}

export const useContractStore = create<State>((set, get) => ({
  contractId: null,
  recordId: null,
  setContractAndRecordId: (contractId: number, recordId: number) => {
    const existingContractId = get().contractId;
    const existingRecordId = get().recordId;

    if (existingContractId !== contractId || existingRecordId !== recordId) {
      set({
        contractId,
        recordId,
        userTeamMemberRole: null,
        buyerTeamMembers: [],
        sellerTeamMembers: [],
      });
    }
  },
  userTeamMemberRole: null,
  buyerTeamMembers: [],
  sellerTeamMembers: [],
  agentTeamMembers: [],
  teamMembers: [],
  otherPartyMembers: [],
  loadingAgentTeamContractId: null,
  loadingBuyerTeamRecordId: null,
  loadingSellerTeamRecordId: null,
}));

export function refreshContractTeamsAndRole() {
  const {
    contractId,
    recordId,
    loadingAgentTeamContractId,
    loadingBuyerTeamRecordId,
    loadingSellerTeamRecordId,
  } = useContractStore.getState();
  const { data: user } = useSessionStore.getState();

  if (user && !isNil(contractId) && loadingAgentTeamContractId !== contractId) {
    useContractStore.setState({
      loadingAgentTeamContractId: contractId,
    });

    // Agent team
    api
      .get(`/contract/${contractId}/agent-team/`)
      .then((response) => {
        const teamMembers = response.data as TeamMember[];
        const agentMember = teamMembers.find(
          (member) => member.email === user.email
        );

        useContractStore.setState({
          agentTeamMembers: teamMembers,
          loadingAgentTeamContractId: null,
        });

        if (agentMember) {
          useContractStore.setState({
            userTeamMemberRole: agentMember.role,
            teamMembers,
          });
        }
      })
      .catch((error) => {
        console.debug("Error: ", error);
      });
  }

  if (
    user &&
    !isNil(contractId) &&
    !isNil(recordId) &&
    loadingBuyerTeamRecordId !== recordId
  ) {
    useContractStore.setState({
      loadingBuyerTeamRecordId: recordId,
    });

    // Buyer team
    api
      .get(`/contract/${contractId}/record/${recordId}/buyer-team/`)
      .then((response) => {
        const teamMembers = response.data as TeamMember[];
        const buyerMember = teamMembers.find(
          (member) => member.email === user.email
        );

        useContractStore.setState({
          buyerTeamMembers: teamMembers,
          loadingBuyerTeamRecordId: null,
        });

        if (buyerMember) {
          useContractStore.setState({
            userTeamMemberRole: buyerMember.role,
            teamMembers,
          });
        } else {
          useContractStore.setState({
            otherPartyMembers: teamMembers,
          });
        }
      })
      .catch((error) => {
        console.debug("Error: ", error);
      });
  }

  if (
    user &&
    !isNil(contractId) &&
    !isNil(recordId) &&
    loadingSellerTeamRecordId !== recordId
  ) {
    useContractStore.setState({
      loadingSellerTeamRecordId: recordId,
    });

    // Seller team
    api
      .get(`/contract/${contractId}/record/${recordId}/seller-team/`)
      .then((response) => {
        const teamMembers = response.data as TeamMember[];
        const sellerMember = teamMembers.find(
          (member) => member.email === user.email
        );
        useContractStore.setState({
          sellerTeamMembers: teamMembers,
          loadingSellerTeamRecordId: null,
        });
        if (sellerMember) {
          useContractStore.setState({
            userTeamMemberRole: sellerMember.role,
            teamMembers,
          });
        } else {
          useContractStore.setState({
            otherPartyMembers: teamMembers,
          });
        }
      })
      .catch((error) => {
        console.debug("Error: ", error);
      });
  }
}
