import React from "react";
import { Box } from "@material-ui/core";
import { RecordStatus } from "../../api/models";
import InProgressIcon from "../../images/record-status-in-progress.svg";
import CompleteIcon from "../../images/record-status-complete.svg";

interface Props {
  status: RecordStatus;
}

export default function WorkspaceItemProgress({ status }: Props) {
  return (
    <Box width="32px" height="32px">
      {status === RecordStatus.InProgress && <img src={InProgressIcon} />}
      {status === RecordStatus.Completed && <img src={CompleteIcon} />}
    </Box>
  );
}
