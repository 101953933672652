import { Box } from "@material-ui/core";
import React from "react";
import Wrapper from "../../components/UserAccount/Wrapper";
import { useMobile } from "../../hooks/mobile";
import { AccountDetailsContent } from "../AccountDetails";

export default function Usage() {
  const isMobile = useMobile();

  return (
    <Wrapper title="Account details">
      <Box
        pt={isMobile ? undefined : "20px"}
        pb="55px"
        maxWidth={isMobile ? `calc(100vw - 64px)` : undefined}
      >
        <AccountDetailsContent />
      </Box>
    </Wrapper>
  );
}
