import React from "react";
import { Box, ButtonBase } from "@material-ui/core";
import ChevronLeft from "../../images/chevron-left.svg";
import { Colors } from "../../constants/colors";
import { useMobile } from "../../hooks/mobile";

interface Props {
  onClick: () => unknown;
}

export default function BackButtonBlue({ onClick }: Props) {
  const isMobile = useMobile();

  return (
    <ButtonBase onClick={onClick}>
      <Box
        width={isMobile ? "40px" : "93px"}
        height="34px"
        alignItems="center"
        justifyContent="center"
        bgcolor={Colors.Link}
        borderRadius="6px"
        display="flex"
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <img src={ChevronLeft} />
          {!isMobile && (
            <Box
              fontFamily="GT Walsheim Pro"
              fontWeight={700}
              fontSize="12px"
              lineHeight="16px"
              letterSpacing="2px"
              color="white"
              ml="6px"
            >
              BACK
            </Box>
          )}
        </Box>
      </Box>
    </ButtonBase>
  );
}
