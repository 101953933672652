import React from "react";
import { Box, Typography, Link } from "@material-ui/core";
import { useMobile } from "../hooks/mobile";
import AlertWrapper from "../components/AlertWrapper";
import HeroLayout from "../components/HeroLayout";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import { Colors } from "../constants/colors";
import Button, { ButtonType, ButtonSize } from "../components/common/Button";
import { useAlert } from "../contexts/Alert";
import api from "../api/instance";
import { User } from "../api/models";
import { AxiosError } from "axios";
import { useSessionStore } from "../stores/Session";
import { useHistory } from "react-router-dom";

export default function HelpForm(): JSX.Element {
  const isMobile = useMobile();
  const history = useHistory();

  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const { setErrorMessage, setSuccessMessage } = useAlert();

  const { refresh: refreshUserData, data: userData } = useSessionStore();
  const user = userData && (userData as User);

  React.useEffect(() => {
    refreshUserData();
  }, []);

  function Header() {
    return (
      <Box
        width={isMobile ? "353px" : "700px"}
        mt={isMobile ? 3 : 22}
        mb={isMobile ? 4 : 7}
      >
        <Typography
          variant={isMobile ? "h5" : "h4"}
          style={{ marginTop: "8px" }}
        >
          Having trouble?
        </Typography>
        <Typography variant={isMobile ? "h5" : "h4"}>
          We're here to help
        </Typography>
        <Typography variant="body1" style={{ marginTop: "10px" }}>
          Fill out this form and Contrax will get back to you shortly.
        </Typography>
      </Box>
    );
  }

  function submitClicked() {
    if (loading) {
      return;
    }

    if (user) {
      setLoading(true);

      api
        .post("/help/", {
          message,
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
          phone: user.phone,
        })
        .then((response) => {
          setLoading(false);

          setMessage("");
          setSuccessMessage(
            "Your message has been sent. We will get back to you shortly."
          );
        })
        .catch((error: AxiosError) => {
          setLoading(false);

          setErrorMessage(error.message);
        });
    }
  }

  return (
    <AlertWrapper>
      <HeroLayout onCloseClicked={() => history.goBack()}>
        <Box ml={3.25} mr={3.25}>
          <Header />
          <Typography variant="body2" style={{ fontWeight: 700 }}>
            Message
          </Typography>
          <textarea
            value={message}
            onChange={(event) => {
              setMessage(event.target.value);
            }}
            style={{
              width: "300px",
              height: "150px",
            }}
          />
          {!isMobile && (
            <Box mt={6}>
              <Button
                type={ButtonType.primary}
                size={ButtonSize.large}
                title="SUBMIT"
                width={"137px"}
                loading={loading}
                onClick={() => submitClicked()}
              />
            </Box>
          )}
        </Box>
      </HeroLayout>
      {isMobile && (
        <MobileStickyFooter
          buttonsLayout={FooterButtonsLayout.centered}
          buttonTitle="SUBMIT"
          buttonLoading={loading}
          buttonOnClick={() => submitClicked()}
        />
      )}
    </AlertWrapper>
  );
}
