import { Box, Typography } from "@material-ui/core";
import { isNil, last, sortBy, isEmpty } from "lodash-es";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import api from "../api/instance";
import {
  Activity,
  addressForProperty,
  Contract,
  Property,
  TeamMemberRole,
  User,
} from "../api/models";
import ActivityLog from "../components/ActivityLog/ActivityLog";
import AlertWrapper from "../components/AlertWrapper";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";
import LegalReviewProgressBar from "../components/LegalReviewProgressBar";
import InviteModal from "../components/LegalReviewViewer/InviteModal";
import InviteYourTeamMembersModal, {
  InviteYourTeamMembersModalForScreen,
  InviteYourTeamMembersModalType,
} from "../components/LegalReviewViewer/InviteYourTeamMembersModal";
import LegalReviewWhiteHeader from "../components/LegalReviewWhiteHeader";
import OnboardingWrapper from "../components/OnboardingWrapper";
import { useAlert } from "../contexts/Alert";
import { useMobile } from "../hooks/mobile";
import { refreshContractTeamsAndRole } from "../stores/contract";
import TestimonialAvatar from "../images/testimonial-avatar.png";
import QuoteIcon from "../images/quote-icon.svg";
import TickIcon from "../images/Tick.svg";
import Separator from "../components/common/Separator";
import { loadStripe } from "@stripe/stripe-js";
import AddLawyerModal from "../components/LegalReviewBuyer/AddLawyerModal";
import LoginModal from "../components/Invited/LoginModal";
import SignUpModal from "../components/Invited/SignUpModal";
import { isLoggedIn, useSessionStore } from "../stores/Session";

interface ContentProps {
  contractId: number;
  recordId?: number;
  property?: Property;
  user?: User | null;
}

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_KEY ||
    "pk_test_51H7XqhIXwp5ovB54AxG4YgHyGzIBhm8hUdlekd8jNoSTF4hMNqBDSYesY3AlUIrpZPIDWH3Tb5GzjwheatvbOby7009gtMi85E"
);

function Content({ contractId, recordId, property, user }: ContentProps) {
  const isMobile = useMobile();
  const history = useHistory();

  const { setSuccessMessage } = useAlert();

  const [loading, setLoading] = React.useState(false);

  const [addLawyerDialogOpen, setAddLawyerDialogOpen] = React.useState(false);

  const onAddLawyer = () => {
    setAddLawyerDialogOpen(true);
  };

  const textVariant = isMobile ? "body2" : "body1";

  function Testimonial() {
    return (
      <Box display="flex" flex={1} position="relative" mt="13px" mb="13px">
        <Box position="absolute" top={0} right={0}>
          <img src={QuoteIcon} />
        </Box>

        <Box
          mt="12px"
          mr="8px"
          flex={1}
          border="1px solid #F4F6F8"
          borderRadius="7px"
          display="flex"
          flexDirection="row"
        >
          <img
            src={TestimonialAvatar}
            width="42px"
            height="42px"
            style={{ marginTop: "16px", marginLeft: "15px" }}
          />

          <Box ml="8px" mt="16px" mb="16px" mr="24px">
            <Typography variant="body2">
              Contrax was great. The contract summary was clear and easy to
              understand. The process was really efficient, and the speedy
              turnaround meant I could move quickly with an offer."
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  const onStartWithContrax = () => {
    history.push(
      `/payment-preview?propertyId=${property?.id}&contractId=${contractId}&recordId=${recordId}&cross=true`
    );
  };

  return (
    <Box
      display="flex"
      flex={1}
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Box
        width={isMobile ? "90%" : "385px"}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {!isMobile && (
          <LegalReviewProgressBar recordId={recordId} contractId={contractId} />
        )}

        <Box
          width={isMobile ? "100%" : "398px"}
          display="flex"
          flexDirection="column"
          pt="8px"
          mt="13px"
        >
          <Typography variant={"h4"}>
            Are you interested in buying this property?
          </Typography>
        </Box>

        <Box
          width={isMobile ? "100%" : "398px"}
          display="flex"
          flexDirection="column"
          pt="8px"
          mt="13px"
        >
          <Typography variant={textVariant}>
            Quickly understand the contract and move fast to personalise it to
            your needs. Start with Contrax to:
          </Typography>

          <Box
            display="flex"
            flexDirection="row"
            mt="16px"
            alignItems="flex-start"
          >
            <img src={TickIcon} style={{ marginRight: "8px" }} />
            <Typography variant={textVariant}>
              Get a legal review of the contract in easy to understand language.
            </Typography>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            mt="8px"
            alignItems="flex-start"
          >
            <img src={TickIcon} style={{ marginRight: "8px" }} />
            <Typography variant={textVariant}>
              Uncover any issues and ask any questions before you move forward.
            </Typography>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            mt="8px"
            alignItems="flex-start"
          >
            <img src={TickIcon} style={{ marginRight: "8px" }} />
            <Typography variant={textVariant}>
              Propose changes and negotiate terms before you buy.
            </Typography>
          </Box>

          <Testimonial />
        </Box>
        {property && (
          <>
            <Box height="8px" />
            <Button
              type={ButtonType.primary}
              size={ButtonSize.large}
              title="START WITH CONTRAX"
              width="301px"
              loading={loading}
              onClick={onStartWithContrax}
            />
            <Box height="20px" />
            <Box display="flex" alignItems="center" width="78%">
              <Separator flex={1} mr={1} />
              <Typography variant={"overline"}>OR</Typography>
              <Separator flex={1} ml={1} />
            </Box>
            <Box height="20px" />

            <Button
              type={ButtonType.primary}
              size={ButtonSize.large}
              title="INVITE YOUR LEGAL REP."
              width="301px"
              onClick={onAddLawyer}
            />
            <Box height="8px" />
          </>
        )}
      </Box>

      <AddLawyerModal
        open={addLawyerDialogOpen}
        onClose={() => setAddLawyerDialogOpen(false)}
        onInvited={(firstName, lastName) => {
          setAddLawyerDialogOpen(false);
          setSuccessMessage(
            `${firstName} ${lastName} has been invited to your team`
          );
          // teamMembersMutate();
        }}
      />
    </Box>
  );
}

// interface Props {
//   // onLoggedIn: () => unknown;
//   // onSignedUp: () => unknown;
// }

export default function LegalReviewBuyer() {
  const isMobile = useMobile();

  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const _contractId = query.get("contractId");
  const contractId = !isNil(_contractId) ? parseInt(_contractId) : null;
  const _recordId = query.get("recordId");
  const recordId = !isNil(_recordId) ? parseInt(_recordId) : null;

  const { data: userData, refresh: refreshUserData } = useSessionStore();
  const user = userData ? (userData as User) : null;

  const { data, mutate: contractMutate } = useSWR(
    `/view-contracts/${contractId}/`
  );
  const contract = data ? (data as Contract) : null;

  const { data: propertyData } = useSWR(
    contract?.property_id ? `/view-properties/${contract.property_id}/` : null
  );
  const property = propertyData && (propertyData as Property);

  const { data: activitiesData, mutate: activitiesMutate } = useSWR(
    !isNil(contractId)
      ? `/contract/${contractId}/record/${recordId}/activities/`
      : null
  );

  const { refresh: refreshUser, refreshTimestamp } = useSessionStore();

  const activities = activitiesData ? (activitiesData as Activity[]) : [];

  const [inviteModalOpen, setInviteModalOpen] = React.useState(false);
  const [inviteYourTeamMembersOpen, setInviteYourTeamMembersOpen] =
    React.useState(false);

  const [inviteYourTeamMembersType, setInviteYourTeamMembersType] =
    React.useState(InviteYourTeamMembersModalType.default);

  const [
    inviteYourTeamMembersUserTeamRole,
    setInviteYourTeamMembersUserTeamRole,
  ] = React.useState<TeamMemberRole | null>(null);

  const [loginModalOpen, setLoginModalOpen] = React.useState(false);

  // React.useEffect(() => {
  //   if (!isLoggedIn() && !isEmpty(invitedVerificationToken)) {
  //     setSignUpModalOpen(true);
  //   } else {
  //     if (isLoggedIn()) {
  //       activitiesMutate();
  //     } else {
  //       setLoginModalOpen(true);
  //     }
  //   }
  // }, []);

  React.useEffect(() => {
    refreshUserData(false);
  }, []);

  React.useEffect(() => {
    if (!isNil(contractId)) {
      refreshContractTeamsAndRole();
    }
  }, [contractId]);

  React.useEffect(() => {
    user && refreshContractTeamsAndRole();
  }, [refreshTimestamp]);

  // const invited = query.get("is_invited") === "true";

  const invitedVerificationToken = query.get("token");
  const inviterFirstName = query.get("first_name") || "";
  const inviterLastName = query.get("last_name") || "";
  const [signUpModalOpen, setSignUpModalOpen] = React.useState(false);

  const { setSuccessMessage } = useAlert();

  const signUpModalOnClose = () => setSignUpModalOpen(false);
  const signUpModalOnSignedUp = () => {
    setSignUpModalOpen(false);
    refreshUserData();
  };
  const signUpModalOnLogin = () => {
    setSignUpModalOpen(false);
    setLoginModalOpen(true);
  };

  const onLoggedIn = () => {
    contractMutate();
    activitiesMutate();
  };

  const onSignedUp = () => {
    contractMutate();
    activitiesMutate();
  };

  return (
    <OnboardingWrapper onLoggedIn={onLoggedIn} onSignedUp={onSignedUp}>
      <AlertWrapper>
        <Box display="flex" flexDirection="column">
          <LegalReviewWhiteHeader
            property={property}
            onInviteClicked={() => {
              setInviteModalOpen(true);
            }}
          />

          {isMobile && contractId && recordId && (
            <Box>
              <Content
                contractId={+contractId}
                recordId={+recordId}
                property={property}
                user={user}
              />
            </Box>
          )}

          {!isMobile && contractId && recordId && (
            <Box display="flex" flexDirection="row">
              <Content
                contractId={+contractId}
                recordId={+recordId}
                property={property}
                user={user}
              />
              <Box mt="40px" width="353px">
                <Typography variant="h6">Activity Log</Typography>
                <ActivityLog activities={activities} />
              </Box>
            </Box>
          )}
        </Box>
      </AlertWrapper>

      <LoginModal
        open={loginModalOpen}
        recordId={recordId}
        contractId={contractId}
        onLoggedIn={() => {
          setLoginModalOpen(false);

          refreshUser(false);

          contractMutate();
          activitiesMutate();
        }}
        inviterName={`${inviterFirstName} ${inviterLastName}`.trim()}
        onCancel={() => setLoginModalOpen(false)}
      />

      <SignUpModal
        open={signUpModalOpen}
        onClose={signUpModalOnClose}
        contractId={contractId}
        inviterName={`${inviterFirstName} ${inviterLastName}`}
        token={invitedVerificationToken}
        onSignedUp={signUpModalOnSignedUp}
        onLogin={signUpModalOnLogin}
      />

      <InviteModal
        open={inviteModalOpen}
        onClose={() => setInviteModalOpen(false)}
        onInviteTeamMembersClicked={(userTeamMemberRole) => {
          setInviteModalOpen(false);
          setInviteYourTeamMembersType(InviteYourTeamMembersModalType.default);
          setInviteYourTeamMembersUserTeamRole(userTeamMemberRole);
          setInviteYourTeamMembersOpen(true);
        }}
        onInviteAgents={() => {
          setInviteModalOpen(false);
          setInviteYourTeamMembersType(InviteYourTeamMembersModalType.agents);
          setInviteYourTeamMembersUserTeamRole(null);
          setInviteYourTeamMembersOpen(true);
        }}
        contractId={contractId}
        recordId={recordId}
      />

      <InviteYourTeamMembersModal
        open={inviteYourTeamMembersOpen}
        onClose={() => setInviteYourTeamMembersOpen(false)}
        onInvited={(firstName, lastName) => {
          setInviteYourTeamMembersOpen(false);
          setSuccessMessage(
            `${firstName} ${lastName} has been invited to your team`
          );

          refreshContractTeamsAndRole();

          setInviteModalOpen(true);
        }}
        contractId={contractId}
        recordId={recordId}
        userTeamMemberRole={inviteYourTeamMembersUserTeamRole}
        type={inviteYourTeamMembersType}
        forScreen={InviteYourTeamMembersModalForScreen.legalreviewbuyer}
      />
    </OnboardingWrapper>
  );
}
