import React from "react";
import { Box, Typography } from "@material-ui/core";
import StatusPill, { StatusPillType } from "../StatusPill";
import { Colors } from "../../constants/colors";
import { LegalReviewRequest, LegalReviewRequestStage } from "../../api/models";
import { useMobile } from "../../hooks/mobile";

interface Props {
  request: LegalReviewRequest;
}

export default function AmendmentAppendixItem({ request }: Props) {
  const isMobile = useMobile();

  return (
    <Box
      width={isMobile ? "95vw" : "725px"}
      display="flex"
      flexDirection="column"
      mt="6px"
      mb="6px"
      boxShadow="0px 1px 0px #E0E0E0"
      borderRadius="8px"
      border="1px solid #E8E8E8"
      key={`appendix-item-${request.pk}`}
    >
      <Box
        height="77px"
        pl="24px"
        pr="24px"
        justifyContent="space-between"
        alignItems="center"
        display="flex"
        flexDirection="row"
      >
        <Typography variant="h6" style={{ fontWeight: 700 }}>
          {request.title}
        </Typography>
        <StatusPill type={StatusPillType.agreed} />
      </Box>

      <Box p="16px" bgcolor={Colors.BrandBackground3}>
        <Typography variant="overline" style={{ fontWeight: 700 }}>
          {request.alternate_change
            ? "ALTERNATIVE CHANGE TO CONTRACT"
            : "PROPOSED CHANGE TO CONTRACT"}
        </Typography>
        <Box height="8px" />
        <Typography variant="body2">
          {request.alternate_change || request.propose}
        </Typography>
      </Box>
    </Box>
  );
}
