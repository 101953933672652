import { Box, Typography, withStyles } from "@material-ui/core";
import { isEmpty, isNil } from "lodash-es";
import moment from "moment";
import React from "react";
import {
  Enquiry,
  EnquiryStage,
  isSellerRequestStage,
  LegalReviewRequest,
  LegalReviewRequestStage,
  TeamMemberRole,
} from "../../api/models";
import { Colors } from "../../constants/colors";
import { useMobile } from "../../hooks/mobile";
import Button, { ButtonSize, ButtonType } from "../common/Button";
import SectionControl from "../common/SectionControl";
import StatusPill, {
  StatusPillType,
  statusPillTypeForEnquiry,
  statusPillTypeForRequest,
} from "../StatusPill";
import { useHistory, useLocation } from "react-router-dom";

const sections = ["Request", "Summary"];

const MyBox = withStyles({
  root: {
    "&:hover": {
      boxShadow: "0px 0px 15px #E0E0E0",
      transition: "box-shadow 0.3s ease-in-out",
    },
  },
})(Box);

const ActiveBox = withStyles({
  root: {
    // margin: "10px",
    border: "1px solid #ECF2FB",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#ECF2FB",
    },
    "&:active": {
      color: "#ECF2FB",
    },
  },
})(Box);

interface Props {
  type: RequestItemType;
  request?: LegalReviewRequest | null;
  enquiry?: Enquiry | null;
  onRespond: () => unknown;
  onComment: () => unknown;
  userTeamMemberRole: TeamMemberRole | null;
}

export enum RequestItemType {
  request,
  enquiry,
}

export default function RequestItem({
  type,
  request,
  enquiry,
  onRespond,
  onComment,
  userTeamMemberRole,
}: Props) {
  const isMobile = useMobile();

  const location = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(location.search);
  const _contractId = query.get("contractId");
  const contractId = !isNil(_contractId) ? parseInt(_contractId) : null;
  const _recordId = query.get("recordId");
  const recordId = !isNil(_recordId) ? parseInt(_recordId) : null;

  const isSeller =
    userTeamMemberRole === TeamMemberRole.seller ||
    userTeamMemberRole === TeamMemberRole.sellerLawyer;

  const isBuyer =
    userTeamMemberRole === TeamMemberRole.buyer ||
    userTeamMemberRole === TeamMemberRole.buyerLawyer;

  const isAgent = userTeamMemberRole === TeamMemberRole.agent;

  const isSellerLawyer = userTeamMemberRole === TeamMemberRole.sellerLawyer;

  const isLawyer =
    userTeamMemberRole === TeamMemberRole.buyerLawyer ||
    userTeamMemberRole === TeamMemberRole.sellerLawyer;

  const isPartner = userTeamMemberRole === TeamMemberRole.partner;

  const [selectedSection, setSelectedSection] = React.useState(sections[0]);

  const _title = () => {
    if (type === RequestItemType.enquiry) {
      return enquiry?.title;
    }

    if (type === RequestItemType.request) {
      return request?.title;
    }

    return null;
  };

  const title = _title();

  const _commentsCount = () => {
    if (type === RequestItemType.enquiry && enquiry) {
      return enquiry.p_commentsCount > 0
        ? enquiry.p_commentsCount
        : enquiry.comments_count || 0;
    }

    if (type === RequestItemType.request && request) {
      return request.p_commentsCount > 0
        ? request.p_commentsCount
        : request.comments_count || 0;
    }

    return 0;
  };

  const _messagesCount = () => {
    if (
      userTeamMemberRole === TeamMemberRole.buyerLawyer ||
      userTeamMemberRole === TeamMemberRole.sellerLawyer
    ) {
      if (type === RequestItemType.enquiry && enquiry) {
        return enquiry.p_messagesCount > 0
          ? enquiry.p_messagesCount
          : enquiry.messages_count || 0;
      }

      if (type === RequestItemType.request && request) {
        return request.p_messagesCount > 0
          ? request.p_messagesCount
          : request.messages_count || 0;
      }
    }

    return 0;
  };

  const _statusPillType = () => {
    if (type === RequestItemType.enquiry && enquiry) {
      return statusPillTypeForEnquiry(enquiry);
    }

    if (type === RequestItemType.request && request) {
      return statusPillTypeForRequest(request);
    }

    return StatusPillType.pending;
  };

  const commentsCount = _commentsCount();
  const messagesCount = _messagesCount();

  const statusPillType = _statusPillType();

  const isDeleted =
    (type === RequestItemType.enquiry &&
      enquiry?.enquiry_status === EnquiryStage.Deleted) ||
    (type === RequestItemType.request &&
      request?.request_status === LegalReviewRequestStage.deleted);

  const isSent = !isNil(request?.sent_on) || !isNil(enquiry?.sent_on);
  const isOutstanding =
    request?.request_status === LegalReviewRequestStage.pending ||
    enquiry?.enquiry_status === EnquiryStage.Pending;

  const onRespondToRequests = () => {
    if (userTeamMemberRole === TeamMemberRole.buyerLawyer) {
      !isNil(contractId) &&
        history.push(
          `/request-builder?contractId=${contractId}&recordId=${recordId}`
        );
    } else if (
      userTeamMemberRole === TeamMemberRole.buyer ||
      userTeamMemberRole === TeamMemberRole.seller ||
      userTeamMemberRole === TeamMemberRole.agent ||
      userTeamMemberRole === TeamMemberRole.partner
    ) {
      return; //This will not allow buyers,sellers and agent to click on the cards.
    } else {
      !isNil(contractId) &&
        history.push(
          `/request-responder?contractId=${contractId}&recordId=${recordId}`
        );
    }
  };

  return (
    <MyBox
      borderRadius="8px"
      border="1px solid #E8E8E8"
      mt="6px"
      mb="6px"
      bgcolor="white"
      style={{
        opacity: isDeleted ? 0.5 : undefined,
        cursor: isLawyer ? "pointer" : undefined,
      }}
      onClick={isSellerLawyer ? onRespond : onRespondToRequests}
    >
      <Box
        height="77px"
        justifyContent="space-between"
        boxShadow="0px 1px 0px #E0E0E0"
        display="flex"
        flexDirection="row"
        alignItems="center"
        pl="24px"
        pr="24px"
        flex={1}
      >
        <Typography variant="h6" style={{ fontWeight: 700 }}>
          {title}
        </Typography>
        <StatusPill type={statusPillType} />
      </Box>

      {request &&
        (isBuyer || isPartner) &&
        !isEmpty(request.legal_review_summary?.content) && (
          <Box height="46px" width="100%" boxShadow="0px 1px 0px #E0E0E0">
            <SectionControl
              sections={sections}
              onSelectedSection={(section) => setSelectedSection(section)}
              selectedSection={selectedSection}
            />
          </Box>
        )}

      {enquiry && (
        <Box p="16px">
          <Typography variant="overline" style={{ fontWeight: 700 }}>
            GENERAL ENQUIRY
          </Typography>

          <Typography variant="body2" style={{ marginTop: "8px" }}>
            {enquiry.enquiry}
          </Typography>
        </Box>
      )}

      {enquiry && !isEmpty(enquiry.answer_to_enquiry) && (
        <Box p="16px">
          <Typography variant="overline" style={{ fontWeight: 700 }}>
            ANSWER
          </Typography>

          <Typography variant="body2" style={{ marginTop: "8px" }}>
            {enquiry.answer_to_enquiry}
          </Typography>
        </Box>
      )}

      {request && selectedSection === "Request" && (
        <>
          <Box p="16px">
            <Typography variant="overline" style={{ fontWeight: 700 }}>
              {isSellerRequestStage(request.request_status)
                ? "REQUEST"
                : "AMENDMENT REQUEST"}
            </Typography>

            <Typography variant="body2" style={{ marginTop: "8px" }}>
              {request.description}
            </Typography>
          </Box>

          <Box
            p="16px"
            bgcolor={
              request.request_status ===
              LegalReviewRequestStage.alternativeSuggested
                ? Colors.Grey4
                : Colors.BrandBackground3
            }
          >
            <Typography variant="overline" style={{ fontWeight: 700 }}>
              {request.request_status ===
              LegalReviewRequestStage.alternativeSuggested
                ? "ORIGINAL CHANGE TO CONTRACT"
                : "PROPOSED CHANGE TO CONTRACT"}
            </Typography>

            <Typography variant="body2" style={{ marginTop: "8px" }}>
              {request.request_status === LegalReviewRequestStage.buyerAgreed
                ? request.alternate_change
                : request.propose}
            </Typography>
          </Box>

          {request.request_status ===
            LegalReviewRequestStage.alternativeSuggested && (
            <Box p="16px" bgcolor={Colors.BrandBackground3}>
              <Typography variant="overline" style={{ fontWeight: 700 }}>
                ALTERNATIVE CHANGE TO CONTRACT
              </Typography>

              <Typography variant="body2" style={{ marginTop: "8px" }}>
                {request.alternate_change}
              </Typography>
            </Box>
          )}

          <Box
            display="flex"
            flexDirection={isSeller ? "column" : "column-reverse"}
          >
            {request.buyer_message && (
              <Box p="16px">
                <Typography variant="overline" style={{ fontWeight: 700 }}>
                  {!isSeller ? "OPTIONAL MESSAGE" : "MESSAGE FROM BUYER"}
                </Typography>

                <Typography variant="body2" style={{ marginTop: "8px" }}>
                  {request.buyer_message}
                </Typography>
              </Box>
            )}

            {request.seller_message && (
              <Box p="16px">
                <Typography variant="overline" style={{ fontWeight: 700 }}>
                  {isSeller ? "OPTIONAL NOTE" : "MESSAGE FROM SELLER"}
                </Typography>

                <Typography variant="body2" style={{ marginTop: "8px" }}>
                  {request.seller_message}
                </Typography>
              </Box>
            )}
          </Box>
        </>
      )}

      {request && selectedSection === "Summary" && (
        <Box p="16px">
          <Typography variant="body2">
            {request.legal_review_summary?.content}
          </Typography>
        </Box>
      )}

      {!isAgent && (
        <Box
          height="74px"
          boxShadow="0px -1px 0px #E0E0E0"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            pl="16px"
            boxSizing="border-box"
          >
            {isSellerLawyer && (
              <ActiveBox mr="8px">
                <Button
                  title="RESPOND"
                  type={ButtonType.transparent}
                  size={ButtonSize.medium}
                  onClick={onRespond}
                  width="126px"
                />
              </ActiveBox>
            )}

            <ActiveBox mr="8px">
              <Button
                title="MESSAGES"
                type={ButtonType.transparent}
                size={ButtonSize.medium}
                onClick={onComment}
                width="120px"
              />
            </ActiveBox>

            {!isMobile && (
              <Typography variant="body2">
                {commentsCount + messagesCount} Messages
              </Typography>
            )}
          </Box>
          {!isMobile && isSent && request && (
            <Typography
              variant="body2"
              style={{ color: Colors.Grey3, marginRight: "16px" }}
            >
              Sent on {moment(request.sent_on).format("D MMM [at] h:mm A")}
            </Typography>
          )}

          {!isMobile && isSent && enquiry && (
            <Typography
              variant="body2"
              style={{ color: Colors.Grey3, marginRight: "16px" }}
            >
              Sent on {moment(enquiry.sent_on).format("D MMM [at] h:mm A")}
            </Typography>
          )}

          {!isMobile && !isSent && !isOutstanding && (
            <Typography
              variant="body2"
              style={{ color: Colors.Grey3, marginRight: "16px" }}
            >
              Not sent
            </Typography>
          )}

          {!isMobile && !isSent && isOutstanding && (
            <Typography
              variant="body2"
              style={{ color: Colors.Grey3, marginRight: "16px" }}
            >
              Outstanding
            </Typography>
          )}
        </Box>
      )}
    </MyBox>
  );
}
