import { Box, Link, Typography } from "@material-ui/core";
import { AxiosError } from "axios";
import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import useSWR from "swr";
import api from "../api/instance";
import { Contract, ContractPublishStatus, Property } from "../api/models";
import AlertWrapper from "../components/AlertWrapper";
import AuthenticatedWrapper from "../components/AuthenticatedWrapper";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import UploadFileBox from "../components/common/UploadFileBox";
import HeroLayout from "../components/HeroLayout";
import { Colors } from "../constants/colors";
import { useAlert } from "../contexts/Alert";
import { useMobile } from "../hooks/mobile";
import BLUELOGO from "../images/Blue.png";
import OrganisationLogo from "../components/OrganisationLogo";
import { useSessionStore } from "../stores/Session";
import {  User, UserType } from "../api/models";

interface Params {
  uuid?: string;
}

export default function MyUploadSaleContract(): JSX.Element {
  const location = useLocation();
  const history = useHistory();

  const { uuid } = useParams<Params>();

  const query = new URLSearchParams(location.search);
  const propertyId = query.get("propertyId");
  const role = query.get("role");
  

  const { data: propertyData } = useSWR(`/view-properties/${propertyId}/`);
  const property = propertyData && (propertyData as Property);

  const { data: contractData } = useSWR(
    property && `/view-contracts/${property.contract_id}/`
  );
  const contract = contractData && (contractData as Contract);

  const { data: userData, refresh: refreshUserData } = useSessionStore();
  const user = userData && (userData as User);

  React.useEffect(() => {
    if (user && user.user_type === UserType.Agent) {
      history.push("/dashboard?agentBuyError=true");
    }
  }, [user]);

  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [uploadError, setUploadError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const isMobile = useMobile();
  const { setErrorMessage } = useAlert();

  const allowContractUpload =
    !contract ||
    (contract &&
      (contract.publish_status === ContractPublishStatus.Delete ||
        contract.publish_status === ContractPublishStatus.Sold));

  function Header() {
    return (
      <Box
        width={isMobile ? "auto" : "700px"}
        mt={isMobile ? 3 : 22}
        mb={isMobile ? 4 : 7}
      >
        <Typography variant="overline">STEP 3 OF 3</Typography>
        <Typography
          variant={isMobile ? "h5" : "h4"}
          style={{ marginTop: "8px" }}
        >
          Upload sale contract
        </Typography>
        <Typography variant="body1" style={{ marginTop: "10px" }}>
          {allowContractUpload
            ? "Click or drag a file to upload the contract of sale"
            : "You have uploaded Contract of sale for this property"}
        </Typography>
      </Box>
    );
  }

  function FooterText() {
    return (
      <Box>
        <Typography variant="body1">
          If you are having trouble uploading the contract{" "}
          <Link
            style={{ color: Colors.Link, fontWeight: 700, cursor: "pointer" }}
            onClick={() => {
              history.push("/agent/help");
            }}
          >
            get in touch with us
          </Link>
        </Typography>
      </Box>
    );
  }

  function uploadClicked() {
    if (loading) {
      return;
    }

    if (!allowContractUpload) {
      // Already uploaded, just proceed
      history.push(`/org/${uuid}/thankyou?propertyId=${property.id}`);

      // history.push(`/property-details?contractId=${contract.id}`);
      return;
    }

    setUploadError(false);

    if (!selectedFile) {
      setUploadError(true);
      return;
    }

    setLoading(true);

    const form = new FormData();
    form.append("file", selectedFile as Blob);
    propertyId && form.append("property_id", propertyId);

    // const path = () => {
    //   if (role === "buying") {
    //     return "/public-contracts/?public=true&source=dashboard&user=buyer";
    //   }

    //   if (role === "selling") {
    //     return "/public-contracts/?public=true&source=dashboard&user=seller";
    //   }

    //   return "/public-contracts/?public=true";
    // };

    const path = `/public-contracts/?uuid=${uuid}&version=v2`;

    api
      .post(path, form)
      .then((response) => {
        const contract = response.data as Contract;

        // history.push(
        //   `/agent/main-contacts?propertyId=${propertyId}&contractId=${contract.id}`
        // );

        // if (role === "buying") {
        //   // Buyer
        //   history.push(`/payment-preview?propertyId=${propertyId}`);
        // } else if (role === "selling") {
        //   // Seller
        //   history.push(
        //     `/property-details?contractId=${contract.id}&type=selling`
        //   );
        // } else {
        //   // Agent
        //   history.push(`/property-details?contractId=${contract.id}`);
        // }

        history.push(`/org/${uuid}/thankyou?propertyId=${property.id}`);
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        setErrorMessage(error.message);
      });
  }

  return (
    <AlertWrapper>
    
        {!isMobile && (
          <>
            <Box
              mb={4}
              display="flex"
              flexDirection="row"
              width="100%"
              height={88}
              position="absolute"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box ml={4}>
                <OrganisationLogo uuid={uuid} />
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Box
                display="flex"
                flexDirection="column"
                marginLeft="auto"
                marginRight="auto"
                alignItems={isMobile ? "center" : undefined}
                mb={8}
                width="725px"
              >
                <Header />
                {allowContractUpload && (
                  <UploadFileBox
                    onSelectFileError={() => setUploadError(true)}
                    selectedFile={selectedFile}
                    onSelectedFile={(file) => {
                      setUploadError(false);
                      setSelectedFile(file);
                    }}
                    error={uploadError}
                  />
                )}
                {!allowContractUpload && (
                  <Button
                    type={ButtonType.secondary}
                    size={ButtonSize.medium}
                    title={"VIEW CONTRACT OF SALE"}
                    onClick={() => {
                      const back = `${location.pathname}${location.search}`;

                      history.push(`/sale-contract?contractId=${contract.id}`, {
                        back,
                      });
                    }}
                  />
                )}

                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  mt={6}
                >
                  <Box maxWidth="323px">
                    <FooterText />
                  </Box>
                  <Button
                    type={ButtonType.dark}
                    size={ButtonSize.large}
                    title={
                      !allowContractUpload
                        ? "NEXT"
                        : selectedFile
                        ? "SUBMIT"
                        : "UPLOAD"
                    }
                    width={"137px"}
                    loading={loading}
                    onClick={() => uploadClicked()}
                  />
                </Box>
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              height={88}
              position="fixed"
              alignItems="center"
              justifyContent="space-between"
              bottom="0"
            >
              <Box ml={4}>
                <Typography variant="body2">Powered by</Typography>
                <Box>
                  <img src={BLUELOGO} height="32px" />
                </Box>
              </Box>
            </Box>
          </>
        )}

        {isMobile && (
          <>
            <Box
              mb={4}
              display="flex"
              flexDirection="row"
              width="100%"
              height={72}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box ml={0}>
                <OrganisationLogo uuid={uuid} />
              </Box>
            </Box>
            <Box ml={3.25} mr={3.25}>
              <Header />
              {allowContractUpload && (
                <UploadFileBox
                  onSelectFileError={() => setUploadError(true)}
                  selectedFile={selectedFile}
                  onSelectedFile={(file) => {
                    setUploadError(false);
                    setSelectedFile(file);
                  }}
                  error={uploadError}
                />
              )}
              {!allowContractUpload && (
                <Button
                  type={ButtonType.secondary}
                  size={ButtonSize.medium}
                  title={"VIEW CONTRACT OF SALE"}
                  onClick={() => {
                    const back = `${location.pathname}${location.search}`;

                    history.push(`/sale-contract?contractId=${contract.id}`, {
                      back,
                    });
                  }}
                />
              )}

              <Box mt={4} mb={4} textAlign="center">
                <FooterText />
              </Box>
            </Box>

            <MobileStickyFooter
              buttonsLayout={FooterButtonsLayout.centeredDark}
              buttonTitle={selectedFile ? "SUBMIT" : "UPLOAD"}
              buttonLoading={loading}
              buttonOnClick={() => uploadClicked()}
            />
          </>
        )}
     
    </AlertWrapper>
  );
}
