import React from "react";
import { useMediaQuery } from "react-responsive";

export function useMobile() {
  return useMediaQuery({ query: "(max-width: 767px)" });
}

export function useDesktop() {
  return useMediaQuery({ query: "(min-width: 1280px)" });
}

export function useTablet() {
  return useMediaQuery({ query: "(min-width: 768px) and (max-width: 1279px)" });
}

export function useSmallHeight() {
  return useMediaQuery({ query: "(max-height: 750px)" });
}
