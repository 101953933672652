import React from "react";
import { Box, Snackbar as MUISnackbar, IconButton } from "@material-ui/core";
import CloseIcon from "../../images/snackbar-close.png";
import { Colors } from "../../constants/colors";

interface Props {
  style: SnackbarStyle;
  message: string | null;
  show: boolean;
  onClose: () => unknown;
}

export enum SnackbarStyle {
  error,
  info,
}

function leftBoxBgColor(style: SnackbarStyle) {
  switch (style) {
    case SnackbarStyle.error:
      return Colors.Error;
    case SnackbarStyle.info:
      return Colors.BrandPrimary;
    default:
      break;
  }
}

function boxBgColor(style: SnackbarStyle) {
  switch (style) {
    case SnackbarStyle.error:
      return Colors.ErrorBackground;
    case SnackbarStyle.info:
      return Colors.BrandBackground3;
    default:
      break;
  }
}

function leftBoxStyle(style: SnackbarStyle) {
  return {
    backgroundColor: leftBoxBgColor(style),
    width: "4px",
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
  };
}

function boxStyle(style: SnackbarStyle) {
  return {
    backgroundColor: boxBgColor(style),
    width: "342px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
  };
}

const messageStyle = {
  fontFamily: "GT Walsheim Pro",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "22px",
  color: Colors.Grey2,
  alignSelf: "center",
};

export default function Snackbar({
  style,
  message,
  show,
  onClose,
}: Props): JSX.Element {
  const snackbarProps =
    style === SnackbarStyle.info
      ? {
          autoHideDuration: 4500,
        }
      : {};

  return (
    <MUISnackbar
      open={show}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={onClose}
      {...snackbarProps}
    >
      <Box display="flex" flexDirection="row" boxShadow={3} minHeight="80px">
        <Box style={leftBoxStyle(style)}></Box>
        <Box style={boxStyle(style)} display="flex" flexDirection="row">
          <Box
            pt={2}
            pb={2}
            pl={2}
            pr={1}
            style={messageStyle}
            flex={1}
            whiteSpace="pre-line"
          >
            {message}
          </Box>
          {style === SnackbarStyle.error && (
            <IconButton
              style={{ alignSelf: "center" }}
              onClick={() => {
                onClose();
              }}
            >
              <img src={CloseIcon} />
            </IconButton>
          )}
        </Box>
      </Box>
    </MUISnackbar>
  );
}
