import React from "react";
import { Box, Dialog, Typography } from "@material-ui/core";
import { useMobile } from "../../hooks/mobile";
import Button, { ButtonType, ButtonSize } from "../common/Button";

interface Props {
  open: boolean;
  onClose: () => unknown;
  onAddCustomRequest: () => unknown;
  onAddCustomEnquiry: () => unknown;
}

interface ItemProps {
  description: string;
  actionTitle: string;
  onAction: () => unknown;
}

function Item({ description, actionTitle, onAction }: ItemProps) {
  return (
    <Box
      borderRadius="8px"
      border="1px solid #91A8EF"
      p="16px"
      width="266px"
      height="175px"
      textAlign="center"
      display="flex"
      flexDirection="column"
    >
      <Typography
        variant="body1"
        style={{ flex: 1, marginLeft: "16px", marginRight: "16px" }}
      >
        {description}
      </Typography>
      <Button
        type={ButtonType.primary}
        size={ButtonSize.small}
        title={actionTitle}
        onClick={onAction}
      />
    </Box>
  );
}

export default function SelectTypeOfRequestModal({
  open,
  onClose,
  onAddCustomEnquiry,
  onAddCustomRequest,
}: Props) {
  const isMobile = useMobile();

  return (
    <Dialog
      open={open}
      aria-labelledby="help-guide-dialog"
      aria-describedby="help-guide-dialog-description"
      maxWidth={"xl"}
      onClose={onClose}
    >
      <Box>
        <Box
          height="62px"
          display="flex"
          flexDirection="row"
          pl="24px"
          pr="24px"
          alignItems="center"
          boxShadow="0px 1px 0px #E0E0E0"
        >
          <Typography variant="h5" style={{ fontWeight: 700 }}>
            Select the type of request
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          p="24px"
          alignItems="center"
        >
          <Item
            description="Request an amendment to the contract. Expect the seller to respond ‘Agreed’, ‘Not Agreed’, or they may propose an alternative."
            onAction={onAddCustomRequest}
            actionTitle="NEW AMENDMENT REQUEST"
          />

          <Box width="24px" height="24px" />

          <Item
            description="Ask a new general enquiry about the property or sale. The seller can answer without amending the contract."
            onAction={onAddCustomEnquiry}
            actionTitle="NEW GENERAL ENQUIRY"
          />
        </Box>
      </Box>
    </Dialog>
  );
}
