import { Box, Typography } from "@material-ui/core";
import { isEmpty } from "lodash-es";
import React from "react";
import {
  ContractRecord,
  RecordStatus,
  User,
  UserType,
  WorkspaceProgress,
  workspaceProgressForRecord,
} from "../../api/models";
import { Colors } from "../../constants/colors";
import { useMobile } from "../../hooks/mobile";
import { useSessionStore } from "../../stores/Session";
import Button, { ButtonSize, ButtonType } from "../common/Button";
import WorkspaceItemProgress from "./WorkspaceItemProgress";
import StatusPill, { StatusPillType } from "../StatusPill";
import EditButton from "../../components/EditButton";
import TextField from "../../components/common/TextField";
import api from "../../api/instance";
import { useAlert } from "../../contexts/Alert";
import { parseApiError } from "../../helpers/error";
// import useSWR from "swr";

interface Props {
  role?: "buyer" | "seller" | null;
  record: ContractRecord;
  onOpen: () => unknown;
  contractId?: string | null;
  recordId?: number | string | null;
}

export default function WorkspaceItem({
  role,
  record,
  onOpen,
  recordId,
  contractId,
}: Props) {
  const isMobile = useMobile();
  const { setErrorMessage, setSuccessMessage } = useAlert();

  const { data: userData } = useSessionStore();
  const user = userData && (userData as User);

  const isAgent = user?.user_type === UserType.Agent;
  const isSolicitor = user?.user_type === UserType.Solicitor;
  const isPartner = user?.user_type === UserType.Partner;

  const currentProgress = workspaceProgressForRecord(record);

  const [isEditing, setIsEditing] = React.useState(false);
  const [title, setTitle] = React.useState("");

  React.useEffect(() => {
    if (record) {
      setTitle(record.title);
    }
  }, [record]);

  const currentProgressTitle = () => {
    const progress = currentProgress;

    switch (progress) {
      case WorkspaceProgress.Contract:
        return "CONTRACT";
      case WorkspaceProgress.LegalReview:
        return "LEGAL REVIEW";
      case WorkspaceProgress.Requests:
        return "REQUESTS";
      // case Progress.Exchange:
      //   return "EXCHANGE";
    }
  };

  const currentProgressRecordStatus = () => {
    const progress = currentProgress;

    switch (progress) {
      case WorkspaceProgress.Contract:
        return record.contract_status;
      case WorkspaceProgress.LegalReview:
        return record.legal_review_status;
      case WorkspaceProgress.Requests:
        return record.amendment_request_status;
      default:
        return RecordStatus.Pending;
    }
  };

  const statusForWorkspaceProgress = (progress: WorkspaceProgress) => {
    if (currentProgress > progress) {
      return RecordStatus.Completed;
    }

    switch (progress) {
      case WorkspaceProgress.Contract:
        return record.contract_status;
      case WorkspaceProgress.LegalReview:
        return record.legal_review_status;
      case WorkspaceProgress.Requests:
        return record.amendment_request_status;
      default:
        return RecordStatus.Pending;
    }
  };

  const onEditButton = () => {
    setIsEditing(true);
  };

  const onCancelButton = () => {
    setIsEditing(false);
  };

  const onSaveButton = () => {
    // Update Record name
    api
      .post(`/contract/${contractId}/record/${recordId}/update-record/`, {
        title: title,
      })
      .then((response) => {
        setIsEditing(false);
        // recordsMutate();
        setSuccessMessage("Record title has been updated");
      })
      .catch((error) => {
        setIsEditing(false);

        setErrorMessage(parseApiError(error));
      });
  };

  const primaryBuyerName = () => {
    if (record && isEmpty(record.title)) {
      return "Buyer not yet invited";
    }

    return `${record.title}`;
  };

  const canOpen = () => {
    const progress = workspaceProgressForRecord(record);

    switch (progress) {
      case WorkspaceProgress.Contract:
        return role === "buyer" && (isSolicitor || isPartner);

      case WorkspaceProgress.LegalReview:
        return role === "buyer";

      case WorkspaceProgress.Requests:
        if (isAgent || role === "seller") {
          return record.other_side_amendment_request_status;
        }

        return true;
    }
  };

  if (isMobile) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        boxShadow="0px 1px 0px #E0E0E0, 0px -1px 0px #E0E0E0"
        height="91px"
        alignItems="center"
        width="90vw"
      >
        <Box width="33.3%">
          <Box display="flex" flexDirection="column">
            <Typography variant="body1" style={{ color: "#0038E4" }}>
              {primaryBuyerName()}
            </Typography>
          </Box>
        </Box>

        <Box
          width="33.3%"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box width="20px" height="2px" bgcolor={Colors.BrandBackground1} />
            <WorkspaceItemProgress status={currentProgressRecordStatus()} />
            <Box width="20px" height="2px" bgcolor={Colors.BrandBackground1} />
          </Box>
          <Typography
            variant="overline"
            style={{ color: Colors.Link, marginTop: "4px" }}
          >
            {currentProgressTitle()}
          </Typography>
        </Box>

        <Box
          width="33.3%"
          justifyContent="flex-end"
          display="flex"
          flexDirection="row"
        >
          <Button
            type={ButtonType.secondary}
            size={ButtonSize.small}
            title="OPEN"
            onClick={onOpen}
            width="71px"
            disabled={!canOpen()}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      boxShadow="0px 1px 0px #E0E0E0, 0px -1px 0px #E0E0E0"
      height="91px"
      alignItems="center"
      width="1095px"
    >
      <Box width="40%">
        {!isEditing ? (
          <>
            <Box style={{ float: "left" }} mr={1}>
              <Typography variant="h6" style={{ color: "#0038E4" }}>
                {primaryBuyerName()}
              </Typography>
            </Box>
            {role === "buyer" && !isPartner && record.assigned_to_me && (
              <EditButton onClick={onEditButton} />
            )}
          </>
        ) : (
          <>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              width="100%"
            >
              <Box mr={1} height="55px">
                <TextField value={title} onChange={(text) => setTitle(text)} />
              </Box>
              <Box mr={1}>
                <Button
                  title="SAVE"
                  size={ButtonSize.medium}
                  type={ButtonType.primary}
                  onClick={onSaveButton}
                  width="63px"
                />
              </Box>
              <Box mr={1}>
                <Button
                  title="CANCEL"
                  size={ButtonSize.medium}
                  type={ButtonType.secondary}
                  onClick={onCancelButton}
                  width="83px"
                />
              </Box>
            </Box>
          </>
        )}

        {(isSolicitor || isPartner) &&
          role === "buyer" &&
          record.assigned_to_me && (
            <>
              <Box mt="4px" display="flex">
                <StatusPill type={StatusPillType.assigned} />
                {record.record_via_partner && !isPartner && (
                  <Box mt="3px" ml="8px">
                    <StatusPill type={StatusPillType.fromPartner} />
                  </Box>
                )}
              </Box>
            </>
          )}
        {record.record_via_partner &&
          record.assigned_to_me === false &&
          isSolicitor &&
          role === "buyer" && (
            <Box mt="3px" ml="8px">
              <StatusPill type={StatusPillType.fromPartner} />
            </Box>
          )}
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        width="568px"
        pl="55px"
        pr="55px"
        boxSizing="border-box"
        alignItems="center"
      >
        <WorkspaceItemProgress
          status={statusForWorkspaceProgress(WorkspaceProgress.Contract)}
        />
        <Box
          width="93px"
          bgcolor={Colors.BrandBackground1}
          height={
            statusForWorkspaceProgress(WorkspaceProgress.LegalReview) ===
              RecordStatus.InProgress ||
            statusForWorkspaceProgress(WorkspaceProgress.LegalReview) ===
              RecordStatus.Completed
              ? "2px"
              : "0px"
          }
          ml="7px"
          mr="7px"
        />
        <WorkspaceItemProgress
          status={statusForWorkspaceProgress(WorkspaceProgress.LegalReview)}
        />
        <Box
          width="93px"
          bgcolor={Colors.BrandBackground1}
          height={
            statusForWorkspaceProgress(WorkspaceProgress.Requests) ===
              RecordStatus.InProgress ||
            statusForWorkspaceProgress(WorkspaceProgress.Requests) ===
              RecordStatus.Completed
              ? "2px"
              : "0px"
          }
          ml="7px"
          mr="7px"
        />
        <WorkspaceItemProgress
          status={statusForWorkspaceProgress(WorkspaceProgress.Requests)}
        />
        <Box
          width="93px"
          bgcolor={Colors.BrandBackground1}
          height={
            statusForWorkspaceProgress(WorkspaceProgress.Exchange) ===
              RecordStatus.InProgress ||
            statusForWorkspaceProgress(WorkspaceProgress.Exchange) ===
              RecordStatus.Completed
              ? "2px"
              : "0px"
          }
          ml="7px"
          mr="7px"
        />
        <WorkspaceItemProgress
          status={statusForWorkspaceProgress(WorkspaceProgress.Exchange)}
        />
        {/* TODO: Exchange status */}
      </Box>
      <Box width="100px">
        <Button
          type={ButtonType.secondary}
          size={ButtonSize.medium}
          title="OPEN"
          onClick={onOpen}
          disabled={!canOpen()}
        />
      </Box>
    </Box>
  );
}
