import React from "react";
import { Box, Typography, withStyles } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import {
  NotificationActivity,
  ActivityAction,
  RecordStatus,
} from "../../api/models";
import AgreedIcon from "../../images/activity-agreed.svg";
import ContractIcon from "../../images/activity-contract.svg";
import api from "../../api/instance";
import UserIcon from "../../images/activity-user.svg";
import MessageSquare from "../../images/message-square.svg";
import SendIcon from "../../images/invite.svg";
import { useMobile } from "../../hooks/mobile";
import moment from "moment";
import { useAlert } from "../../contexts/Alert";
import { parseApiError } from "../../helpers/error";
import cherovenDownIcon from "../../images/chevron-down.svg";
import cherovenUpIcon from "../../images/chevron-up.svg";
import { useHistory } from "react-router";

interface Props {
  activity: NotificationActivity;
  dropdown: Boolean;
}
/*  Joined,
  Published,
  Request,
  Respond,
  Agree,
  Paused,
  Cancel,*/
export default function ({ activity, dropdown }: Props) {
  const history = useHistory();
  const isMobile = useMobile();

  const { setErrorMessage } = useAlert();

  const [isReadMore, setIsReadMore] = React.useState(true);
  const [textLen, setTextLen] = React.useState(0);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const MyBox = withStyles({
    root: {
      "&:hover": {
        backgroundColor: Colors.BrandBackground3,
      },
    },
  })(Box);

  const ReadMore = ({ children }) => {
    const text = children;
    const textLen = text.length;
    setTextLen(textLen);
    return (
      <Typography>
        {text && (
          <React.Fragment>
            {isReadMore ? text.slice(0, 50) : text}
            {isReadMore && textLen > 50 ? "..." : ""}
          </React.Fragment>
        )}
      </Typography>
    );
  };

  const _link = () => {
    let workFlowLink = "request-workspace";
    if (activity.comment_data && activity.comment_data.summary_id) {
      workFlowLink = "legal-review-viewer";
    }

    switch (activity.action) {
      case ActivityAction.Joined:
        let redirectLink;
        if (
          activity.progress.amendment_request_status === RecordStatus.InProgress
        ) {
          redirectLink = `/request-workspace?contractId=${activity.contract_id}&recordId=${activity.record_id}`;
        } else if (
          activity.progress.legal_review_status === RecordStatus.InProgress
        ) {
          redirectLink = `/legal-review-viewer?contractId=${activity.contract_id}&recordId=${activity.record_id}`;
        } else if (
          activity.progress.contract_status === RecordStatus.InProgress
        ) {
          redirectLink = `/legal-review-viewer?contractId=${activity.contract_id}&recordId=${activity.record_id}`;
        } else {
          redirectLink = `/legal-review-builder?contractId=${activity.contract_id}&recordId=${activity.record_id}`;
        }
        return redirectLink;
      case ActivityAction.Published:
        return `/legal-review-viewer?contractId=${activity.contract_id}&recordId=${activity.record_id}`;
      case ActivityAction.ReviewRequest:
      case ActivityAction.Comment:
        return `/${workFlowLink}?contractId=${activity.contract_id}&recordId=${activity.record_id}`;
      case ActivityAction.Message:
        return `/request-workspace?contractId=${activity.contract_id}&recordId=${activity.record_id}`;
      case ActivityAction.Request:
        return `/request-workspace?contractId=${activity.contract_id}&recordId=${activity.record_id}`;
      case ActivityAction.Agree:
        return `/legal-review-viewer?contractId=${activity.contract_id}&recordId=${activity.record_id}`;
      case ActivityAction.Cancel:
        return `/legal-review-viewer?contractId=${activity.contract_id}&recordId=${activity.record_id}`;
      case ActivityAction.Respond:
        return `/request-workspace?contractId=${activity.contract_id}&recordId=${activity.record_id}`;
      case ActivityAction.UpdatedRequest:
        return `/request-workspace?contractId=${activity.contract_id}&recordId=${activity.record_id}`;
      case ActivityAction.UpdatedResponse:
        return `/request-workspace?contractId=${activity.contract_id}&recordId=${activity.record_id}`;
    }
  };

  const _icon = () => {
    switch (activity.action) {
      case ActivityAction.Agree:
      case ActivityAction.ReviewRequest:
        return AgreedIcon;

      case ActivityAction.Comment:
      case ActivityAction.Message:
        return MessageSquare;

      case ActivityAction.Pause:
      case ActivityAction.Cancel:
      case ActivityAction.Request:
      case ActivityAction.Published:
        return ContractIcon;

      case ActivityAction.Joined:
        return UserIcon;

      case ActivityAction.Respond:
      case ActivityAction.UpdatedRequest:
      case ActivityAction.UpdatedResponse:
        return SendIcon;
    }
  };

  const _content = () => {
    switch (activity.action) {
      case ActivityAction.Comment:
        return `${activity.comment_data.comment_content}`;
      case ActivityAction.Message:
        return `${activity.message_data.message_content}`;
    }
  };

  const _contentTitle = () => {
    switch (activity.action) {
      case ActivityAction.Comment:
        return `${activity.comment_data.title}`;
      case ActivityAction.Message:
        return `${activity.message_data.title}`;
    }
  };

  const _action = () => {
    switch (activity.action) {
      case ActivityAction.Agree:
        return "agreed to proceed";

      case ActivityAction.Comment:
        return "left a comment in";

      case ActivityAction.Message:
        return "left a message in";

      case ActivityAction.Published:
        return "finalised the legal review";

      case ActivityAction.Joined:
        return "joined the team";

      case ActivityAction.Request:
        return "sent the requests";

      case ActivityAction.Respond:
        return "responded to requests";

      case ActivityAction.Pause:
        return "paused";

      case ActivityAction.ReviewRequest:
        return "reviewed requests";

      case ActivityAction.UpdatedRequest:
        return "sent updated requests";

      case ActivityAction.UpdatedResponse:
        return "responded to the updated requests";

      case ActivityAction.Cancel:
        return "cancelled";
    }
  };

  const _propertyName = () => {
    return `${activity.property_address}`;
  };

  const _name = () => {
    return `${activity.user.first_name} ${activity.user.last_name}`;
  };

  const _date = () => {
    const format = "Do [of] MMMM YYYY";

    return moment(activity.created).format(format);
  };

  const icon = _icon();
  const action = _action();
  const name = _name();
  const date = _date();
  const link = _link();
  const propertyName = _propertyName();
  const content = _content();
  const contentTitle = _contentTitle();

  const handleLink = () => {
    if (link) {
      history.push(link);
      if (activity.is_read === false) {
        api
          .post(`/contract/notification-update/?id=${activity.pk}`)
          .then((response) => {
            console.debug("response: ", response);
          })
          .catch((error) => {
            console.debug("error: ", error);
            setErrorMessage(parseApiError(error));
          });
      }
    }
  };

  return (
    <Box>
      {dropdown ? (
        <MyBox
          onClick={handleLink}
          style={{ cursor: "pointer" }}
          bgcolor={Colors.Grey5}
          borderRadius={10}
          padding={1}
          display="flex"
          flexDirection="row"
          position="relative"
          margin={2}
        >
          <Box width="20px" height="20px" ml="15px" mt="20px">
            <img src={icon} alt="icon"/>
          </Box>
          <Box display="flex" flexDirection="column" ml="10px">
            <Box
              display="flex"
              justifyContent="center"
              width={isMobile ? "96%" : "400px"}
            >
              <Box width="50%" display="flex" flex={1} justifyContent="start">
                <Typography
                  variant="caption"
                  style={{
                    color: Colors.Grey3,
                    marginTop: "2px",
                  }}
                >
                  {date}
                </Typography>
              </Box>
              {activity.is_read === false &&
                activity.action !== ActivityAction.Joined && (
                  <Box
                    width="50%"
                    display="flex"
                    flex={1}
                    justifyContent="end"
                    alignSelf="end"
                  >
                    <Box
                      bgcolor={Colors.BrandPrimary}
                      width="10px"
                      height="10px"
                      borderRadius="50%"
                    />
                  </Box>
                )}
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              width={isMobile ? "100%" : "400px"}
            >
              <Typography variant="body2" style={{ lineHeight: "18px" }}>
                <b style={{ fontWeight: 700, lineHeight: "18px" }}>{name}</b>{" "}
                {action}{" "}
                <b style={{ fontWeight: 700, lineHeight: "18px" }}>
                  {contentTitle}
                </b>
              </Typography>
            </Box>
            <Box paddingRight={2}>
              {content && <ReadMore>{content}</ReadMore>}
            </Box>
            <Typography variant="caption" style={{ marginTop: "2px" }}>
              {propertyName}
            </Typography>
          </Box>
        </MyBox>
      ) : (
        <MyBox
          bgcolor={Colors.Grey5}
          borderRadius={10}
          paddingLeft={2}
          paddingTop={2}
          paddingBottom={1}
          paddingRight={2}
          display="flex"
          flexDirection="column"
          marginLeft={2}
          marginRight={2}
          marginTop={1}
          minHeight="96px"
        >
          <Box
            display="flex"
            flexDirection="row"
            paddingLeft={2}
            paddingBottom={1}
            paddingRight={2}
            marginLeft={2}
            marginRight={2}
            style={{ cursor: "pointer" }}
            onClick={handleLink}
          >
            <Box
              width="20px"
              height="20px"
              ml="5px"
              mr="10px"
              display="flex"
              flexDirection="column"
            >
              <img src={icon} alt="icon"/>
            </Box>
            <Box display="flex" flexGrow={1} flexDirection="column">
              <Typography
                variant="body2"
                style={{ fontWeight: 700, lineHeight: "18px" }}
              >
                {name} {content && action + " " + contentTitle}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography
                variant="caption"
                style={{
                  color: Colors.Grey2,
                  fontWeight: 400,
                  marginTop: "2px",
                }}
              >
                {date}
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            paddingLeft={2}
            paddingBottom={1}
            paddingRight={2}
            marginLeft={6}
            marginRight={2}
            flexDirection="row"
          >
            <Box display="flex" flexGrow={1} flexDirection="column">
              {content ? <ReadMore>{content}</ReadMore> : `${action}`}
            </Box>
            {content && textLen > 50 && (
              <Box display="flex" flexDirection="column">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={toggleReadMore}
                  className="read-or-hide"
                >
                  {isReadMore ? (
                    <img src={cherovenDownIcon} alt="chevron-down" />
                  ) : (
                    <img src={cherovenUpIcon} alt="chevron-up" />
                  )}
                </span>
              </Box>
            )}
          </Box>
          <Box
            display="flex"
            paddingLeft={2}
            paddingBottom={1}
            paddingRight={2}
            marginLeft={6}
            marginRight={2}
            flexDirection="row"
          >
            <Box display="flex" flexGrow={1} flexDirection="column">
              <Typography
                variant="caption"
                style={{
                  color: Colors.Grey2,
                  fontWeight: 400,
                  marginTop: "2px",
                }}
              >
                {propertyName}
              </Typography>
            </Box>
          </Box>
        </MyBox>
      )}
    </Box>
  );
}
