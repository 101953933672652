import { Box, Typography } from "@material-ui/core";
import React from "react";
import StickyHeader from "../components/common/StickyHeader";
import { Colors } from "../constants/colors";
import { useMobile } from "../hooks/mobile";
import WhiteLogo from "../images/contrax-white-logo.svg";
import ImageButton from "../components/common/ImageButton";
import Button, { ButtonType, ButtonSize } from "../components/common/Button";
import ViewContractButton from "../images/view-contract-button.svg";

interface Props {
  title?: string;
  subtitle?: string;
  onViewContractClicked: () => unknown;
  onDoneClicked: () => unknown;
}

export default function ({
  title,
  subtitle,
  onViewContractClicked,
  onDoneClicked,
}: Props) {
  const isMobile = useMobile();

  const _rightView = () => {
    return (
      <>
        {isMobile ? (
          <ImageButton
            image={ViewContractButton}
            onClick={onViewContractClicked}
          />
        ) : (
          <Button
            title="VIEW CONTRACT"
            type={ButtonType.secondary}
            size={ButtonSize.medium}
            onClick={onViewContractClicked}
          />
        )}

        <Box ml="8px" mr="18px">
          <Button
            title="DONE"
            type={ButtonType.primary}
            size={ButtonSize.medium}
            onClick={onDoneClicked}
            width="86px"
          />
        </Box>
      </>
    );
  };

  return (
    <StickyHeader
      height="72px"
      bgcolor={Colors.BrandBackground4}
      display="flex"
      flexDirection="row"
      width="100%"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="row" alignItems="center" height="100%">
        {!isMobile && (
          <>
            <Box
              width="96px"
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img src={WhiteLogo} />
            </Box>
            <Box
              width="1px"
              height="100%"
              bgcolor={Colors.Grey5}
              style={{ opacity: 0.2 }}
            />
          </>
        )}

        <Box ml="21px" display="flex" flexDirection="column">
          <Typography
            variant="h6"
            style={{ color: "white", lineHeight: "24px" }}
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography
              variant="body2"
              style={{ color: "white", lineHeight: "20px" }}
            >
              {subtitle}
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        height="100%"
        ml="16px"
      >
        {_rightView()}
      </Box>
    </StickyHeader>
  );
}
