import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useMobile } from "../hooks/mobile";
import { useLocation, useHistory } from "react-router-dom";
import useSWR from "swr";
import { isNil } from "lodash-es";
import { Property, Contract, addressForProperty } from "../api/models";
import { Colors } from "../constants/colors";
import BLUELOGO from "../images/Blue.png";
import HERO from "../images/contract-landing-hero.svg";
import Button, { ButtonType, ButtonSize } from "../components/common/Button";
import { placeDetails } from "../googleplaces/queries";

interface PropertyInfoProps {
  property?: Property | null;
  address?: string | null;
}

function PropertyInfo({ property, address }: PropertyInfoProps) {
  const isMobile = useMobile();

  return (
    <Box
      pl={isMobile ? "26.5px" : "42.5px"}
      pr={isMobile ? "23.5px" : "42.5px"}
      display="flex"
      flexDirection="row"
      width="100%"
      minHeight="84px"
      alignItems="center"
      bgcolor={Colors.Grey5}
      boxSizing="border-box"
      justifyContent={isNil(property?.image) ? "center" : undefined}
    >
      {property?.image && (
        <Box>
          <img
            src={property.image}
            width="95px"
            height="64px"
            style={{
              objectFit: "cover",
              borderRadius: "4px",
              marginRight: "16px",
            }}
          />
        </Box>
      )}

      <Box display="flex" flexDirection="column" mt="18px" mb="18px">
        <Typography variant={isMobile ? "body2" : "body1"}>
          {property ? addressForProperty(property) : address}
        </Typography>
      </Box>

      {/* <Box display="flex" flexDirection="column" mt="18px" mb="18px">
        <Typography variant={isMobile ? "body2" : "body1"}>
          ??? has shared the contract for{" "}
        </Typography>
        <Typography
          variant={isMobile ? "body2" : "body1"}
          style={{ fontWeight: 700 }}
        >
          {property && addressForProperty(property)}
        </Typography>
      </Box> */}
    </Box>
  );
}

export default function () {
  const isMobile = useMobile();
  const location = useLocation();
  const history = useHistory();

  const { search: urlParams } = location;

  const query = new URLSearchParams(location.search);
  // const _contractId = query.get("contractId");
  // const contractId = !isNil(_contractId) ? parseInt(_contractId) : null;
  const propertyId = query.get("propertyId");

  const placeId = query.get("placeId");

  // const { data, mutate: contractMutate } = useSWR(
  //   `/view-contracts/${contractId}/`
  // );
  // const contract = data ? (data as Contract) : null;

  const { data: propertyData } = useSWR(`/view-properties/${propertyId}/`);

  const property = propertyData && (propertyData as Property);

  const [address, setAddress] = React.useState<string | null>(null);

  React.useEffect(() => {
    placeDetails(placeId).then((result) => {
      let place = result as google.maps.places.PlaceResult;

      setAddress(place.formatted_address || null);
    });
  }, [placeId]);

  return (
      <Box
          bgcolor={Colors.Link}
          width="100vw"
          minHeight="100vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
      >
          <Box
              borderRadius="6px"
              width={isMobile ? "90%" : "425px"}
              bgcolor="white"
          >
              <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  mt="40px"
                  mb="16px"
              >
                  <Typography variant="h6">Welcome to</Typography>
                  <img
                      src={BLUELOGO}
                      height="40px"
                      style={{ marginBottom: "30px" }}
                  />
                  <img src={HERO} />
              </Box>

              <PropertyInfo property={property} address={address} />
              <Box p="16px">
                  <Typography variant="overline">
                      Thanks for your interest in Contrax. Unfortunately, we no
                      longer offer contract review services to lawyers and
                      conveyancers.
                  </Typography>
                  {/* <Button
            title="I'M A BUYER"
            type={ButtonType.primary}
            size={ButtonSize.large}
            width="100%"
            onClick={() =>
              history.push(`/property-info${urlParams}&role=buyer`)
            }
          /> */}
                  <Box height="12px" />
                  <Button
                      title="Back To Dashboard"
                      type={ButtonType.primary}
                      size={ButtonSize.large}
                      width="100%"
                      onClick={() =>
                          history.push(`/app/dashboard`)
                      }
                  />
              </Box>
          </Box>
      </Box>
  );
}
