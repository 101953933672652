import { Box, BoxProps } from "@material-ui/core";
import React from "react";

interface Props extends BoxProps {
  height: string | number;
}

export default function ({ height, children, ...props }: Props) {
  return (
    <Box>
      {/* Phantom view for margin purposes */}
      <Box height={height} width="100%" />
      <Box
        {...props}
        height={height}
        zIndex={999}
        position="fixed"
        left={0}
        right={0}
        bottom={0}
      >
        {children}
      </Box>
    </Box>
  );
}
