import { Box, Typography } from "@material-ui/core";
import arrayMove from "array-move";
import produce from "immer";
import { isEmpty, isNil, some } from "lodash-es";
import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useHistory, useLocation } from "react-router-dom";
import scrollIntoView from "scroll-into-view";
import useSWR from "swr";
import api from "../api/instance";
import {
  BuyerSellerStatus,
  Contract,
  DefaultEnquiry,
  DefaultLegalReviewRequest,
  Enquiry,
  EnquiryStage,
  EnquiryStatus,
  LegalReviewRequest,
  LegalReviewRequestStage,
  LegalReviewRequestStatus,
  TeamMemberRole,
} from "../api/models";
import AlertWrapper from "../components/AlertWrapper";
import AuthenticatedWrapper from "../components/AuthenticatedWrapper";
import CommentsDialog, {
  CommentsDialogJourney,
  CommentsDialogType,
} from "../components/Comments/CommentsDialog";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";
import PageSection from "../components/common/PageSection";
import StickyHeader from "../components/common/StickyHeader";
import Switch from "../components/common/Switch";
import LegalReviewBlueHeader from "../components/LegalReviewBlueHeader";
import CustomEnquiryBox from "../components/RequestBuilder/CustomEnquiryBox";
import CustomRequestBox from "../components/RequestBuilder/CustomRequestBox";
import EnquiryEditorBox, {
  EnquiryEditorBoxType,
} from "../components/RequestBuilder/EnquiryEditorBox";
import EnquiryItem from "../components/RequestBuilder/EnquiryItem";
import RequestEditorBox, {
  RequestEditorBoxType,
} from "../components/RequestBuilder/RequestEditorBox";
import RequestItem from "../components/RequestBuilder/RequestItem";
import SelectTypeOfRequestModal from "../components/RequestBuilder/SelectTypeOfRequestModal";
import ViewContractDrawer from "../components/ViewContractDrawer";
import { Colors } from "../constants/colors";
import { debounceDelay } from "../constants/common";
import { useAlert } from "../contexts/Alert";
import { parseApiError } from "../helpers/error";
import { useMobile } from "../hooks/mobile";
import useInterval from "../hooks/useInterval";
import {
  refreshContractTeamsAndRole,
  useContractStore,
} from "../stores/contract";
import "../styles/podium.css";

export default function RequestBuilder() {
  const location = useLocation();
  const history = useHistory();
  const { setErrorMessage } = useAlert();
  const isMobile = useMobile();

  const query = new URLSearchParams(location.search);
  const _contractId = query.get("contractId");
  const _recordId = query.get("recordId");

  const contractId = !isNil(_contractId) ? parseInt(_contractId) : null;
  const recordId = !isNil(_recordId) ? parseInt(_recordId) : null;

  const { data } = useSWR(`/view-contracts/${contractId}/`);
  const contract = data ? (data as Contract) : null;
  const contractFile = contract ? contract.file : null;

  const { data: summaryRequestData, mutate: summaryRequestMutate } = useSWR(
    `contract/${contractId}/record/${recordId}/default-summary-requests`
  );
  const { data: enquiriesData, mutate: enquiriesMutate } = useSWR(
    `contract/${contractId}/record/${recordId}/legal-review-enquiries/`
  );

  const { userTeamMemberRole, setContractAndRecordId } = useContractStore();

  const [hideDismissed, setHideDismissed] = React.useState(true);

  const [
    currentRequestsPendingDeletionCount,
    setCurrentRequestsPendingDeletionCount,
  ] = React.useState(0);
  const [
    currentEnquiriesPendingDeletionCount,
    setCurrentEnquiriesPendingDeletionCount,
  ] = React.useState(0);

  const [requests, setRequests] = React.useState<LegalReviewRequest[]>([]);
  const [enquiries, setEnquiries] = React.useState<Enquiry[]>([]);

  const [hasNewEdits, setHasNewEdits] = React.useState(false);

  const [showAddCustomRequest, setShowAddCustomRequest] = React.useState(false);
  const [addingCustomRequest, setAddingCustomRequest] = React.useState(false);

  const [showAddCustomEnquiry, setShowAddCustomEnquiry] = React.useState(false);
  const [addingCustomEnquiry, setAddingCustomEnquiry] = React.useState(false);

  const customRequestRef = React.useRef<HTMLDivElement>(null);
  const customEnquiryRef = React.useRef<HTMLDivElement>(null);

  const [deletedRequests, setDeletedRequests] = React.useState<
    LegalReviewRequest[]
  >([]);

  const [deletedEnquiries, setDeletedEnquiries] = React.useState<Enquiry[]>([]);

  const [showTypeOfRequestModal, setShowTypeOfRequestModal] =
    React.useState(false);

  const [lastSaved, setLastSaved] = React.useState<Date | null>(null);

  const [enquiryForCommentsDialog, setEnquiryForCommentsDialog] =
    React.useState<Enquiry | null>(null);
  const [enquiryCommentsDialogOpen, setEnquiryCommentsDialogOpen] =
    React.useState(false);

  const [requestForCommentsDialog, setRequestForCommentsDialog] =
    React.useState<LegalReviewRequest | null>(null);
  const [requestCommentsDialogOpen, setRequestCommentsDialogOpen] =
    React.useState(false);

  const [addingNewVersion, setAddingNewVersion] = React.useState(false);

  const [contractDrawerOpen, setContractDrawerOpen] = React.useState(false);
  const [contractDrawerPage, setContractDrawerPage] = React.useState(1);

  const onViewContract = (request: LegalReviewRequest) => {
    setContractDrawerPage(request.legal_review_summary?.page || 1);
    setContractDrawerOpen(true);
  };

  React.useEffect(() => {
    if (!isNil(contractId) && !isNil(recordId)) {
      setContractAndRecordId(contractId, recordId);
      refreshContractTeamsAndRole();
    }
  }, [contractId, recordId]);

  // Show List with same number of list as created from admin
  React.useEffect(() => {
    summaryRequestMutate();
    enquiriesMutate();
  });

  React.useEffect(() => {
    if (
      userTeamMemberRole === TeamMemberRole.buyer ||
      userTeamMemberRole === TeamMemberRole.seller ||
      userTeamMemberRole === TeamMemberRole.agent
    ) {
      // Redirect to dashboard

      history.push(`/dashboard`);
    }
  }, [userTeamMemberRole]);

  const fetchRequests = () => {
    api
      .get(`contract/${contractId}/record/${recordId}/legal-review-requests/`)
      .then((response) => {
        const requests = response.data as LegalReviewRequest[];
        setRequests(
          requests
            .filter((r) => r.request_status !== LegalReviewRequestStage.deleted)
            .sort((a, b) =>
              b.request_status === LegalReviewRequestStage.alternativeSuggested
                ? 1
                : 0
            )
        );

        setCurrentRequestsPendingDeletionCount(
          requests.filter(
            (r) =>
              r.request_status === LegalReviewRequestStage.deleted &&
              r.is_sent === false
          ).length
        );

        // Fetch default ones
        api
          .get(
            `contract/${contractId}/record/${recordId}/default-summary-requests`
          )
          .then((response) => {
            const defaultRequests =
              response.data as DefaultLegalReviewRequest[];

            if (isEmpty(requests)) {
              // If no saved requests yet, add all default summaries...

              defaultRequests.forEach((request) => {
                addNewDefaultRequest(request);
              });
            } else {
              // Cross check and add the new ones
              const savedRequestsDefaultRequestPks = requests.map(
                (r) => r.default_summary_request
              );

              const newDefaultRequests = defaultRequests.filter(
                (defaultRequest) =>
                  !savedRequestsDefaultRequestPks.includes(defaultRequest.pk)
              );

              newDefaultRequests.forEach((summary) => {
                addNewDefaultRequest(summary);
              });
            }
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  };

  const addNewDefaultRequest = (request: DefaultLegalReviewRequest) => {
    const { content, propose, legal_summary, pk: defaultRequestPk } = request;
    const { title: summaryTitle } = legal_summary;

    api
      .post(`/contract/${contractId}/record/${recordId}/custom-request/`, {
        title: summaryTitle,
        description: content,
        propose,
        status: LegalReviewRequestStatus.editing,
        legal_summary_pk: legal_summary.pk,
        index: 1,
        default_request_pk: defaultRequestPk,
      })
      .then((response) => {
        const newRequest = response.data as LegalReviewRequest;

        setRequests((oldRequests) => [...oldRequests, newRequest]);
      })
      .catch((error) => {
        console.debug("error adding default request: ", error);
      });
  };

  const fetchEnquiries = () => {
    api
      .get(`contract/${contractId}/record/${recordId}/legal-review-enquiries/`)
      .then((response) => {
        const enquiries = response.data as Enquiry[];
        setEnquiries(
          enquiries
            .sort((a, b) =>
              b.enquiry_status === EnquiryStage.Pending ? 1 : -1
            )
            .filter((e) => e.enquiry_status !== EnquiryStage.Deleted)
        );

        setCurrentEnquiriesPendingDeletionCount(
          enquiries.filter(
            (e) =>
              e.enquiry_status === EnquiryStage.Deleted && e.is_sent === false
          ).length
        );

        // Fetch default ones
        api
          .get(
            `contract/${contractId}/record/${recordId}/default-general-enquiries`
          )
          .then((response) => {
            const defaultEnquiries = response.data as DefaultEnquiry[];

            if (isEmpty(enquiries)) {
              // If no saved requests yet, add all default summaries...

              defaultEnquiries.forEach((enquiry) => {
                addNewDefaultEnquiry(enquiry);
              });
            } else {
              // Cross check and add the new ones
              const savedEnquiriesDefaultEnquiryPks = enquiries.map(
                (r) => r.default_enquiry
              );

              const newDefaultEnquiries = defaultEnquiries.filter(
                (defaultEnquiry) =>
                  !savedEnquiriesDefaultEnquiryPks.includes(defaultEnquiry.pk)
              );

              newDefaultEnquiries.forEach((enquiry) => {
                addNewDefaultEnquiry(enquiry);
              });
            }
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  };

  const addNewDefaultEnquiry = (defaultEnquiry: DefaultEnquiry) => {
    const { general_title, enquiry, pk: defaultEnquiryPk } = defaultEnquiry;

    api
      .post(`/contract/${contractId}/record/${recordId}/custom-enquiry/`, {
        title: general_title,
        enquiry,
        index: 1,
        status: EnquiryStatus.Editing,
        default_enquiry_pk: defaultEnquiryPk,
      })
      .then((response) => {
        const newEnquiry = response.data as Enquiry;

        setEnquiries((oldEnquiries) => [...oldEnquiries, newEnquiry]);
      })
      .catch((error) => {
        console.debug("error adding default enquiry: ", error);
      });
  };

  interface SaveProps {
    savingClosing?: boolean;
  }

  const save = ({ savingClosing = false }: SaveProps) => {
    // Requests
    let data: any[] = requests
      .filter((request) => !isNil(request))
      .filter((request) => request.p_updated)
      .map((request, index) => {
        const {
          pk,
          title,
          description,
          propose,
          status,
          request_status,
          buyer_message,
          buyer_responded,
        } = request;
        return {
          pk,
          title,
          description,
          propose,
          status,
          request_status,
          buyer_message: buyer_message || null,
          buyer_responded,
          index: index + 1,
          type: "Request",
        };
      });

    const deletingRequests = deletedRequests.filter(
      (request) => !isNil(request.pk)
    );

    const deletingRequestsPks = deletingRequests.map((request) => request.pk);

    data = data.concat(
      deletingRequests
        .filter((request) => !isNil(request.pk))
        .map((request) => {
          const {
            pk,
            title,
            description,
            propose,
            request_status,
            buyer_message,
          } = request;

          return {
            pk,
            title,
            description,
            propose,
            request_status,
            buyer_message: buyer_message || null,
            buyer_responded: true,
            status: LegalReviewRequestStatus.deleted,
            index: 1,
            type: "Request",
          };
        })
    );

    // Enquiries
    data = data.concat(
      enquiries
        .filter((enquiry) => !isNil(enquiry))
        .filter((enquiry) => enquiry.p_updated)
        .map((enquiry, index) => {
          const { pk, title, enquiry: content, status } = enquiry;
          return {
            pk,
            title,
            enquiry: content,
            status,
            index: index + 1,
            type: "Enquiry",
          };
        })
    );

    const deletingEnquiries = deletedEnquiries.filter(
      (request) => !isNil(request.pk)
    );

    const deletingEnquiriesPks = deletingEnquiries.map((enquiry) => enquiry.pk);

    data = data.concat(
      deletingEnquiries
        .filter((enquiry) => !isNil(enquiry.pk))
        .map((enquiry) => {
          const { pk, title, enquiry: content, status } = enquiry;

          return {
            pk,
            title,
            enquiry: content,
            status: EnquiryStatus.Deleted,
            index: 1,
            type: "Enquiry",
            buyer_responded: true,
          };
        })
    );

    api
      .post(`/contract/${contractId}/record/${recordId}/legal-requests/`, {
        data,
      })
      .then((response) => {
        // Remove deleted requests (there could be new ones while API is calling)
        const newDeletedRequests = deletedRequests.filter(
          (ds) => !deletingRequestsPks.includes(ds.pk)
        );

        // Remove deleted enquiries (there could be new ones while API is calling)
        const newDeletedEnquiries = deletedEnquiries.filter(
          (ds) => !deletingEnquiriesPks.includes(ds.pk)
        );

        setDeletedRequests(newDeletedRequests);
        setDeletedEnquiries(newDeletedEnquiries);

        setHasNewEdits(false);

        setLastSaved(new Date());

        if (savingClosing) {
          history.push(
            `/request-workspace?contractId=${contractId}&recordId=${recordId}`
          );
        }
      })
      .catch((error) => {
        console.debug("error: ", error);

        setErrorMessage(parseApiError(error));
      });
  };

  // Auto save
  useInterval(() => {
    // Skip if adding new request version
    if (!addingNewVersion) {
      save({ savingClosing: false });
    }
  }, 10000); // 10 Seconds

  React.useEffect(() => {
    fetchRequests();
    fetchEnquiries();
  }, []);

  const onAddCustomRequest = (
    title: string,
    description: string,
    proposal: string
  ) => {
    setAddingCustomRequest(true);

    api
      .post(`/contract/${contractId}/record/${recordId}/custom-request/`, {
        title,
        description,
        propose: proposal,
        index: 1,
        status: LegalReviewRequestStatus.published,
      })
      .then((response) => {
        setAddingCustomRequest(false);

        const newRequest = response.data as LegalReviewRequest;

        //setTheArray(oldArray => [...oldArray, newElement]);
        setRequests((oldRequests) => [newRequest, ...oldRequests]);

        setShowAddCustomRequest(false);
      })
      .catch((error) => {
        setAddingCustomRequest(false);

        setErrorMessage("Could not add new request");
      });
  };

  const onAddCustomEnquiry = (title: string, enquiry: string) => {
    setAddingCustomEnquiry(true);

    api
      .post(`/contract/${contractId}/record/${recordId}/custom-enquiry/`, {
        title,
        enquiry,
        index: 1,
        status: EnquiryStatus.Published,
      })
      .then((response) => {
        setAddingCustomEnquiry(false);

        const newEnquiry = response.data as Enquiry;

        //setTheArray(oldArray => [...oldArray, newElement]);
        setEnquiries((oldEnquiries) => [newEnquiry, ...oldEnquiries]);

        setShowAddCustomEnquiry(false);
      })
      .catch((error) => {
        setAddingCustomEnquiry(false);

        setErrorMessage("Could not add new enquiry");
      });
  };

  const onBeforeUnload = React.useCallback((e: BeforeUnloadEvent) => {
    var confirmationMessage =
      "It looks like you have unsaved changes. " +
      "If you leave before saving, your changes will be lost.";

    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
  }, []);

  React.useEffect(() => {
    if (hasNewEdits) {
      // Add close browser prompt if unsaved changes
      window.addEventListener("beforeunload", onBeforeUnload);
    } else {
      window.removeEventListener("beforeunload", onBeforeUnload);
    }
  }, [hasNewEdits]);

  // Comments count updates
  // REMINDER: Immer makes objects immutable hence we must use immer to update internal variables as well
  const onRequestCommentsCountUpdated = (
    request: LegalReviewRequest,
    count: number
  ) => {
    setRequests(
      produce((draft) => {
        const requestDraft = draft.find((r) => r.pk === request.pk);
        if (requestDraft) {
          requestDraft.p_commentsCount = count;
        }
      })
    );
  };
  const onEnquiryCommentsCountUpdated = (enquiry: Enquiry, count: number) => {
    setEnquiries(
      produce((draft) => {
        const enquiryDraft = draft.find((e) => e.pk === enquiry.pk);
        if (enquiryDraft) {
          enquiryDraft.p_commentsCount = count;
        }
      })
    );
  };

  const onRequestMessagesCountUpdated = (
    request: LegalReviewRequest,
    count: number
  ) => {
    setRequests(
      produce((draft) => {
        const requestDraft = draft.find((r) => r.pk === request.pk);
        if (requestDraft) {
          requestDraft.p_messagesCount = count;
        }
      })
    );
  };
  const onEnquiryMessagesCountUpdated = (enquiry: Enquiry, count: number) => {
    setEnquiries(
      produce((draft) => {
        const enquiryDraft = draft.find((e) => e.pk === enquiry.pk);
        if (enquiryDraft) {
          enquiryDraft.p_messagesCount = count;
        }
      })
    );
  };

  const onSaveNewVersion = (
    index: number,
    request: LegalReviewRequest,
    description: string,
    proposal: string
  ) => {
    setAddingNewVersion(true);

    // Note trigger this only after debounce delays to make sure there are no unrecorded states
    setTimeout(() => {
      const { pk, title, index: requestIndex, status } = request;

      api
        .post(
          `/contract/${contractId}/record/${recordId}/custom-request-version/`,
          {
            title,
            description,
            propose: proposal,
            index: requestIndex,
            status,
            request_pk: pk,
          }
        )
        .then((response) => {
          const newRequest = response.data as LegalReviewRequest;

          // Replace this request with new versioned request

          setRequests(
            produce((draft) => {
              draft[index] = newRequest;
            })
          );

          setAddingNewVersion(false);
        })
        .catch((error) => {
          setAddingNewVersion(false);

          setErrorMessage(error.message);
        });
    }, debounceDelay);
  };

  const onDeleteEnquiry = (enquiry: Enquiry, index: number) => {
    if (window.confirm("Are you sure you want to delete this enquiry?")) {
      setEnquiries(
        produce((draft) => {
          delete draft[index];
        })
      );

      if (!isNil(enquiry.pk)) {
        setDeletedEnquiries((oldDeletedEnquiries) => [
          ...oldDeletedEnquiries,
          enquiry,
        ]);
      }
    }
  };

  const onDeleteRequest = (request: LegalReviewRequest, index: number) => {
    if (window.confirm("Are you sure you want to delete this request?")) {
      setRequests(
        produce((draft) => {
          delete draft[index];
        })
      );

      if (!isNil(request.pk)) {
        setDeletedRequests((oldDeletedRequests) => [
          ...oldDeletedRequests,
          request,
        ]);
      }
    }
  };

  function SecondaryHeader() {
    const isMobile = useMobile();

    const dismissedCount =
      requests.filter((r) => r.status === LegalReviewRequestStatus.dismissed)
        .length +
      enquiries.filter((e) => e.status === EnquiryStatus.Dismissed).length;

    const addedCount =
      requests.filter(
        (r) =>
          r.request_status === LegalReviewRequestStage.pending &&
          r.status === LegalReviewRequestStatus.published &&
          r.buyer_status === BuyerSellerStatus.Responded
      ).length +
      enquiries.filter(
        (e) =>
          e.enquiry_status === EnquiryStage.Pending &&
          e.status === EnquiryStatus.Published &&
          e.buyer_status === BuyerSellerStatus.Responded
      ).length;

    const deletedPendingSendCount =
      currentRequestsPendingDeletionCount +
      currentEnquiriesPendingDeletionCount +
      deletedRequests.length +
      deletedEnquiries.length;

    const allCompletedOrSent =
      requests
        .filter(
          (r) =>
            r.buyer_status !== BuyerSellerStatus.Completed &&
            r.buyer_status !== BuyerSellerStatus.Sent
        )
        .filter(
          (r) =>
            r.status !== LegalReviewRequestStatus.editing &&
            r.status !== LegalReviewRequestStatus.dismissed
        ).length +
        enquiries
          .filter(
            (e) =>
              e.buyer_status !== BuyerSellerStatus.Completed &&
              e.buyer_status !== BuyerSellerStatus.Sent
          )
          .filter(
            (e) =>
              e.status !== EnquiryStatus.Editing &&
              e.status !== EnquiryStatus.Dismissed
          ).length +
        deletedPendingSendCount ===
      0;

    const updatedOrRespondedCount =
      requests
        .filter(
          (r) =>
            r.request_status !== LegalReviewRequestStage.alternativeSuggested
        )
        .filter(
          (r) =>
            r.p_updated === true ||
            r.buyer_status === BuyerSellerStatus.Responded
        )
        .filter(
          (r) =>
            r.status !== LegalReviewRequestStatus.editing &&
            r.status !== LegalReviewRequestStatus.dismissed
        ).length +
      enquiries
        .filter(
          (e) =>
            e.p_updated === true ||
            e.buyer_status === BuyerSellerStatus.Responded
        )
        .filter(
          (e) =>
            e.status !== EnquiryStatus.Editing &&
            e.status !== EnquiryStatus.Dismissed
        ).length +
      deletedPendingSendCount;

    const altSuggestedWaitingCount = requests.filter(
      (r) => r.request_status === LegalReviewRequestStage.alternativeSuggested
    ).length;

    const sentOnce =
      some(requests, { sent_once: true }) ||
      some(enquiries, { sent_once: true });

    const message = () => {
      if (sentOnce) {
        if (allCompletedOrSent) {
          return (
            <Typography variant="body2">
              All requests have been sent. You can still add new requests or
              delete sent requests
            </Typography>
          );
        }

        if (updatedOrRespondedCount > 0 && altSuggestedWaitingCount > 0) {
          return (
            <Box
              display="flex"
              flexDirection="row"
              whiteSpace="nowrap"
              flexWrap="wrap"
            >
              <Typography variant="body2">
                Add new requests or delete existing requests.{" "}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  fontWeight: 700,
                  marginLeft: isMobile ? undefined : "4px",
                }}
              >
                You have {altSuggestedWaitingCount} alternative suggestion and{" "}
                {updatedOrRespondedCount} updates to send.
              </Typography>
            </Box>
          );
        } else if (altSuggestedWaitingCount > 0) {
          return (
            <Box
              display="flex"
              flexDirection="row"
              whiteSpace="nowrap"
              flexWrap="wrap"
            >
              <Typography variant="body2">
                Add new requests or delete existing requests.{" "}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  fontWeight: 700,
                  marginLeft: isMobile ? undefined : "4px",
                }}
              >
                You have {altSuggestedWaitingCount} alternative suggestion.
              </Typography>
            </Box>
          );
        } else if (updatedOrRespondedCount > 0) {
          return (
            <Box
              display="flex"
              flexDirection="row"
              whiteSpace="nowrap"
              flexWrap="wrap"
            >
              <Typography variant="body2">
                Add new requests or delete existing requests.{" "}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  fontWeight: 700,
                  marginLeft: isMobile ? undefined : "4px",
                }}
              >
                You have {updatedOrRespondedCount} updates to send.
              </Typography>
            </Box>
          );
        }
      }

      return (
        <Typography variant="body2">
          {addedCount} Added and {dismissedCount} Dismissed
        </Typography>
      );
    };

    return (
      <StickyHeader
        height="52px"
        bgcolor="white"
        display="flex"
        flexDirection="row"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        boxShadow="0px 1px 0px #E0E0E0"
        top={72}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          height="100%"
          pl="24px"
        >
          {message()}
        </Box>

        {!isMobile && (
          <Box display="flex" flexDirection="row" alignItems="center">
            <Button
              title="ADD CUSTOM REQUEST"
              type={ButtonType.secondary}
              size={ButtonSize.small}
              onClick={() => setShowTypeOfRequestModal(true)}
            />

            <Box
              ml="20px"
              pr="24px"
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <Switch
                checked={hideDismissed}
                onChange={(event) => setHideDismissed(event.target.checked)}
              />
              <Typography variant="body1" style={{ marginLeft: "8px" }}>
                Hide dismissed
              </Typography>
            </Box>
          </Box>
        )}
      </StickyHeader>
    );
  }

  return (
    <AuthenticatedWrapper>
      <AlertWrapper>
        <Box
          minHeight="100vh"
          display="flex"
          flexDirection="column"
          bgcolor={Colors.BrandBackground3}
          pb={10}
        >
          <LegalReviewBlueHeader
            title="Build your requests"
            onViewContractClicked={() => {
              window.open(
                `/app/sale-contract?contractId=${contractId}&recordId=${recordId}`,
                "_blank"
              );
            }}
            onDoneClicked={() => {
              save({ savingClosing: true });
            }}
          />
          <SecondaryHeader />

          <ViewContractDrawer
            contractFile={contractFile}
            contractDrawer={contractDrawerOpen}
            handleContactDrawer={() => setContractDrawerOpen(false)}
            pageNo={contractDrawerPage}
          />

          {isMobile && (
            <Box display="flex" justifyContent="center" mt="12px">
              <Button
                title="ADD CUSTOM REQUEST"
                type={ButtonType.secondary}
                size={ButtonSize.medium}
                onClick={() => setShowTypeOfRequestModal(true)}
                width="90vw"
              />
            </Box>
          )}

          <Box display="flex" flexDirection="column" alignItems="center">
            <PageSection
              title="AMENDMENT REQUESTS"
              width={isMobile ? "95vw" : "1097px"}
            />

            <div ref={customRequestRef}>
              {showAddCustomRequest && (
                <CustomRequestBox
                  onAddRequest={(title, description, proposal) => {
                    onAddCustomRequest(title, description, proposal);
                  }}
                  adding={addingCustomRequest}
                  onCancel={() => setShowAddCustomRequest(false)}
                />
              )}
            </div>

            <DragDropContext
              onDragEnd={(result, provided) => {
                const sourceIndex = result.source.index;
                const destinationIndex = result.destination?.index;

                if (!isNil(destinationIndex)) {
                  setRequests(
                    arrayMove(requests, sourceIndex, destinationIndex)
                  );
                }
              }}
            >
              <Droppable droppableId="requests-list">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {requests.map((request, index) => {
                      if (request.p_addingNewVersion) {
                        return (
                          <RequestEditorBox
                            addingNewVersion={addingNewVersion}
                            index={index}
                            request={request}
                            onViewContract={() => onViewContract(request)}
                            onDescriptionChanged={(description) => {
                              setRequests(
                                produce((draft) => {
                                  draft[index].description = description;
                                })
                              );

                              setHasNewEdits(true);
                            }}
                            onProposalChanged={(proposal) => {
                              setRequests(
                                produce((draft) => {
                                  draft[index].propose = proposal;
                                })
                              );

                              setHasNewEdits(true);
                            }}
                            onViewComments={() => {
                              setRequestForCommentsDialog(request);
                              setRequestCommentsDialogOpen(true);
                            }}
                            onRevert={() => {
                              setRequests(
                                produce((draft) => {
                                  draft[index].p_addingNewVersion = false;

                                  // TODO: If current is already saved, set status to deleted and add to deleted requests
                                })
                              );

                              setHasNewEdits(true);
                            }}
                            onSave={(description, proposal) => {
                              onSaveNewVersion(
                                index,
                                request,
                                description,
                                proposal
                              );
                            }}
                          />
                        );
                      }

                      if (
                        request.status === LegalReviewRequestStatus.published
                      ) {
                        return (
                          <RequestItem
                            index={index}
                            request={request}
                            onViewContract={() => onViewContract(request)}
                            onEditRequest={() => {
                              setRequests(
                                produce((draft) => {
                                  if (
                                    draft[index].request_status ===
                                      LegalReviewRequestStage.buyerNotAgreed ||
                                    draft[index].request_status ===
                                      LegalReviewRequestStage.buyerAgreed
                                  ) {
                                    draft[index].request_status =
                                      LegalReviewRequestStage.alternativeSuggested;
                                    draft[index].buyer_responded = true;
                                  } else {
                                    draft[index].status =
                                      LegalReviewRequestStatus.editing;
                                    draft[index].buyer_responded = false;
                                  }

                                  draft[index].p_updated = true;
                                })
                              );

                              setHasNewEdits(true);
                            }}
                            onViewComments={() => {
                              setRequestForCommentsDialog(request);
                              setRequestCommentsDialogOpen(true);
                            }}
                            onMessage={(message) => {
                              setRequests(
                                produce((draft) => {
                                  draft[index].buyer_message = message;
                                  draft[index].p_updated = true;
                                })
                              );

                              setHasNewEdits(true);
                            }}
                            onAgree={() => {
                              setRequests(
                                produce((draft) => {
                                  draft[index].request_status =
                                    LegalReviewRequestStage.buyerAgreed;

                                  // draft[index].is_sent = false;
                                  draft[index].p_updated = true;
                                  draft[index].buyer_responded = true;
                                })
                              );

                              setHasNewEdits(true);
                            }}
                            onNotAgree={() => {
                              setRequests(
                                produce((draft) => {
                                  draft[index].request_status =
                                    LegalReviewRequestStage.buyerNotAgreed;

                                  // draft[index].is_sent = false;
                                  draft[index].p_updated = true;
                                  draft[index].buyer_responded = true;
                                })
                              );

                              setHasNewEdits(true);
                            }}
                            onNewRequest={() => {
                              setRequests(
                                produce((draft) => {
                                  draft[index].p_addingNewVersion = true;
                                })
                              );
                            }}
                            onRemove={() => onDeleteRequest(request, index)}
                          />
                        );
                      }

                      if (request.status === LegalReviewRequestStatus.editing) {
                        return (
                          <RequestEditorBox
                            index={index}
                            request={request}
                            onViewContract={() => onViewContract(request)}
                            onDescriptionChanged={(description) => {
                              setRequests(
                                produce((draft) => {
                                  draft[index].description = description;
                                  draft[index].p_updated = true;
                                })
                              );

                              setHasNewEdits(true);
                            }}
                            onProposalChanged={(proposal) => {
                              setRequests(
                                produce((draft) => {
                                  draft[index].propose = proposal;
                                  draft[index].p_updated = true;
                                })
                              );

                              setHasNewEdits(true);
                            }}
                            onDismiss={() => {
                              setRequests(
                                produce((draft) => {
                                  draft[index].status =
                                    LegalReviewRequestStatus.dismissed;
                                  draft[index].p_updated = true;
                                })
                              );

                              setHasNewEdits(true);
                            }}
                            onAdd={() => {
                              setRequests(
                                produce((draft) => {
                                  draft[index].status =
                                    LegalReviewRequestStatus.published;
                                  draft[index].buyer_responded = true;
                                  draft[index].p_updated = true;
                                })
                              );

                              setHasNewEdits(true);
                            }}
                            onViewComments={() => {
                              setRequestForCommentsDialog(request);
                              setRequestCommentsDialogOpen(true);
                            }}
                            onSave={(description, proposal) => {
                              // Updating new version
                              if (!isEmpty(request.old_versions)) {
                                setRequests(
                                  produce((draft) => {
                                    draft[index].description = description;
                                    draft[index].propose = proposal;
                                    draft[index].status =
                                      LegalReviewRequestStatus.published;
                                  })
                                );
                              }
                            }}
                            onRevert={() => {
                              // Delete current version and restore first version

                              if (!isEmpty(request.old_versions)) {
                                // Insert into deleted requests
                                if (!isNil(request.pk)) {
                                  setDeletedRequests((oldDeletedRequests) => [
                                    ...oldDeletedRequests,
                                    request,
                                  ]);
                                }

                                const firstVersion = request.old_versions[0];

                                // Delete 2nd version and restore 1st version
                                // Just replace it with 1st version
                                setRequests(
                                  produce((draft) => {
                                    draft[index] = firstVersion;
                                  })
                                );
                              }
                            }}
                          />
                        );
                      }

                      if (
                        request.status === LegalReviewRequestStatus.dismissed
                      ) {
                        return !hideDismissed ? (
                          <RequestEditorBox
                            type={RequestEditorBoxType.dismissed}
                            index={index}
                            request={request}
                            onViewContract={() => onViewContract(request)}
                            onDescriptionChanged={(description) => {
                              setRequests(
                                produce((draft) => {
                                  draft[index].description = description;
                                  draft[index].p_updated = true;
                                })
                              );

                              setHasNewEdits(true);
                            }}
                            onProposalChanged={(proposal) => {
                              setRequests(
                                produce((draft) => {
                                  draft[index].propose = proposal;
                                  draft[index].p_updated = true;
                                })
                              );

                              setHasNewEdits(true);
                            }}
                            onDelete={() => onDeleteRequest(request, index)}
                            onRestore={() => {
                              setRequests(
                                produce((draft) => {
                                  draft[index].status =
                                    LegalReviewRequestStatus.editing;
                                  draft[index].p_updated = true;
                                })
                              );

                              setHasNewEdits(true);
                            }}
                            onViewComments={() => {
                              setRequestForCommentsDialog(request);
                              setRequestCommentsDialogOpen(true);
                            }}
                          />
                        ) : (
                          <></>
                        );
                      }

                      return <></>;
                    })}

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <PageSection
              title="GENERAL ENQUIRIES"
              width={isMobile ? "95vw" : "1097px"}
            />

            <div ref={customEnquiryRef}>
              {showAddCustomEnquiry && (
                <CustomEnquiryBox
                  onAddEnquiry={(title, enquiry) => {
                    onAddCustomEnquiry(title, enquiry);
                  }}
                  onCancel={() => setShowAddCustomEnquiry(false)}
                  adding={addingCustomEnquiry}
                />
              )}
            </div>

            <DragDropContext
              onDragEnd={(result, provided) => {
                const sourceIndex = result.source.index;
                const destinationIndex = result.destination?.index;

                if (!isNil(destinationIndex)) {
                  setEnquiries(
                    arrayMove(enquiries, sourceIndex, destinationIndex)
                  );
                }
              }}
            >
              <Droppable droppableId="enquiries-list">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {enquiries.map((enquiry, index) => {
                      if (enquiry.status === EnquiryStatus.Published) {
                        return (
                          <EnquiryItem
                            enquiry={enquiry}
                            index={index}
                            onEdit={() => {
                              setEnquiries(
                                produce((draft) => {
                                  draft[index].status = EnquiryStatus.Editing;
                                  draft[index].p_updated = true;
                                })
                              );

                              setHasNewEdits(true);
                            }}
                            onViewComments={() => {
                              setEnquiryForCommentsDialog(enquiry);
                              setEnquiryCommentsDialogOpen(true);
                            }}
                            onRemove={() => onDeleteEnquiry(enquiry, index)}
                          />
                        );
                      }

                      if (enquiry.status === EnquiryStatus.Editing) {
                        return (
                          <EnquiryEditorBox
                            index={index}
                            enquiry={enquiry}
                            onTitleChanged={(title) => {
                              setEnquiries(
                                produce((draft) => {
                                  draft[index].title = title;
                                  draft[index].p_updated = true;
                                })
                              );

                              setHasNewEdits(true);
                            }}
                            onEnquiryChanged={(enquiry) => {
                              setEnquiries(
                                produce((draft) => {
                                  draft[index].enquiry = enquiry;
                                  draft[index].p_updated = true;
                                })
                              );

                              setHasNewEdits(true);
                            }}
                            onDismiss={() => {
                              setEnquiries(
                                produce((draft) => {
                                  draft[index].status = EnquiryStatus.Dismissed;
                                  draft[index].p_updated = true;
                                })
                              );

                              setHasNewEdits(true);
                            }}
                            onAdd={() => {
                              setEnquiries(
                                produce((draft) => {
                                  draft[index].status = EnquiryStatus.Published;
                                  draft[index].p_updated = true;
                                })
                              );

                              setHasNewEdits(true);
                            }}
                            onViewComments={() => {
                              setEnquiryForCommentsDialog(enquiry);
                              setEnquiryCommentsDialogOpen(true);
                            }}
                          />
                        );
                      }

                      if (enquiry.status === EnquiryStatus.Dismissed) {
                        return !hideDismissed ? (
                          <EnquiryEditorBox
                            type={EnquiryEditorBoxType.dismissed}
                            index={index}
                            enquiry={enquiry}
                            onTitleChanged={(title) => {
                              setEnquiries(
                                produce((draft) => {
                                  draft[index].title = title;
                                  draft[index].p_updated = true;
                                })
                              );

                              setHasNewEdits(true);
                            }}
                            onEnquiryChanged={(enquiry) => {
                              setEnquiries(
                                produce((draft) => {
                                  draft[index].enquiry = enquiry;
                                  draft[index].p_updated = true;
                                })
                              );

                              setHasNewEdits(true);
                            }}
                            onDelete={() => onDeleteEnquiry(enquiry, index)}
                            onRestore={() => {
                              setEnquiries(
                                produce((draft) => {
                                  draft[index].status = EnquiryStatus.Editing;
                                  draft[index].p_updated = true;
                                })
                              );

                              setHasNewEdits(true);
                            }}
                            onViewComments={() => {
                              setEnquiryForCommentsDialog(enquiry);
                              setEnquiryCommentsDialogOpen(true);
                            }}
                          />
                        ) : (
                          <></>
                        );
                      }

                      return <></>;
                    })}

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </Box>
      </AlertWrapper>
      <SelectTypeOfRequestModal
        open={showTypeOfRequestModal}
        onClose={() => setShowTypeOfRequestModal(false)}
        onAddCustomEnquiry={() => {
          setShowTypeOfRequestModal(false);
          setShowAddCustomEnquiry(true);

          customEnquiryRef.current &&
            scrollIntoView(customEnquiryRef.current, { time: 500 });
        }}
        onAddCustomRequest={() => {
          setShowTypeOfRequestModal(false);
          setShowAddCustomRequest(true);

          customRequestRef.current &&
            scrollIntoView(customRequestRef.current, { time: 500 });
        }}
      />

      <CommentsDialog
        type={CommentsDialogType.request}
        journey={CommentsDialogJourney.Request}
        contractId={contractId}
        recordId={recordId}
        request={requestForCommentsDialog}
        open={requestCommentsDialogOpen}
        onClose={() => {
          setRequestCommentsDialogOpen(false);
        }}
        onRequestCommentsCountUpdated={onRequestCommentsCountUpdated}
        onRequestMessagesCountUpdated={onRequestCommentsCountUpdated}
      />

      <CommentsDialog
        type={CommentsDialogType.enquiry}
        journey={CommentsDialogJourney.Request}
        contractId={contractId}
        recordId={recordId}
        enquiry={enquiryForCommentsDialog}
        open={enquiryCommentsDialogOpen}
        onClose={() => {
          setEnquiryCommentsDialogOpen(false);
        }}
        onEnquiryCommentsCountUpdated={onEnquiryCommentsCountUpdated}
        onEnquiryMessagesCountUpdated={onEnquiryMessagesCountUpdated}
      />
    </AuthenticatedWrapper>
  );
}
