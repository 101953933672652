import { Box, Drawer, Link, Typography } from "@material-ui/core";
import { isEmpty, isNil } from "lodash-es";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import api from "../api/instance";
import {
  Activity,
  ActivityAction,
  Contract,
  ContractSummary,
  ContractSummaryState,
  Property,
  RecordStatus,
  TeamMemberRole,
  UserType,
} from "../api/models";
import ActivityLog from "../components/ActivityLog/ActivityLog";
import ActivityLogDrawer from "../components/ActivityLog/ActivityLogDrawer";
import AddAMessageDialog, {
  AddAMessageDialogForScreen,
} from "../components/AddAMessageDialog";
import AddAMessageSentDialog from "../components/AddAMessageSentDialog";
import AlertWrapper from "../components/AlertWrapper";
import CommentsDialog, {
  CommentsDialogJourney,
  CommentsDialogType,
} from "../components/Comments/CommentsDialog";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";
import StickyFooter from "../components/common/StickyFooter";
import HelpGuideModal from "../components/HelpGuide/HelpGuideModal";
import HelpGuideModalItem from "../components/HelpGuide/HelpGuideModalItem";
import LegalReviewProgressBar from "../components/LegalReviewProgressBar";
import InviteModal from "../components/LegalReviewViewer/InviteModal";
import InviteYourTeamMembersModal, {
  InviteYourTeamMembersModalType,
} from "../components/LegalReviewViewer/InviteYourTeamMembersModal";
import NoBuyersYetDialog from "../components/LegalReviewViewer/NoBuyersYetDialog";
import ProceedDialog from "../components/LegalReviewViewer/ProceedDialog";
import ProceedSuccessDialog from "../components/LegalReviewViewer/ProceedSuccessDialog";
import SummaryItem from "../components/LegalReviewViewer/SummaryItem";
import LegalReviewWhiteHeader from "../components/LegalReviewWhiteHeader";
import OnboardingWrapper from "../components/OnboardingWrapper";
import ShareWithDialog from "../components/ShareWithDialog";
import ViewContractDrawer from "../components/ViewContractDrawer";
import { Colors } from "../constants/colors";
import { useAlert } from "../contexts/Alert";
import { parseApiError } from "../helpers/error";
import { useMobile } from "../hooks/mobile";
import BLReviewHelp1 from "../images/builder-landing-help-1.svg";
import BLReviewHelp2 from "../images/builder-landing-help-2.svg";
import BLReviewHelp3 from "../images/builder-landing-help-3.svg";
import LegalReviewBuilderHelp1 from "../images/legal-review-builder-help-1.svg";
import LegalReviewBuilderHelp2 from "../images/legal-review-builder-help-2.svg";
import RequestHelp1 from "../images/req-workspace-buyer-lawyer-help1.svg";
import RequestHelp2 from "../images/req-workspace-buyer-lawyer-help2.svg";
import RequestHelp3 from "../images/req-workspace-buyer-lawyer-help3.svg";
import {
  refreshContractTeamsAndRole,
  useContractStore,
} from "../stores/contract";
import { isLoggedIn, useSessionStore } from "../stores/Session";
import "../styles/podium.css";
import LegalReviewBuilderLanding from "./LegalReviewBuilderLanding";
import LegalReviewNotReady from "./LegalReviewNotReady";
import DownloadButton from "../components/DownloadButton";
import DownloadDialog from "../components/LegalReviewViewer/DownloadDialog";
import EditButton from "../components/EditButton";
import TextField from "../components/common/TextField";

interface MobileStickyFooterProps {
  onFinaliseOrCreateRequests: () => unknown;
  onMoreOptions: () => unknown;
  published: boolean;
  isBuyer: boolean;
  onBuyerNext: () => unknown;
  onHelpGuide: () => unknown;
}

function MobileStickyFooter({
  onFinaliseOrCreateRequests,
  onMoreOptions,
  published,
  isBuyer,
  onBuyerNext,
  onHelpGuide,
}: MobileStickyFooterProps) {
  return (
    <StickyFooter
      height="108px"
      bgcolor="white"
      boxShadow="0px -1px 0px #E0E0E0"
    >
      {isBuyer && (
        <Box m="16px" display="flex" flexDirection="column">
          <Button
            title={"NEXT"}
            type={ButtonType.primary}
            size={ButtonSize.medium}
            onClick={onBuyerNext}
          />
          <Box height="4px" />
          <Button
            title="HELP GUIDE"
            type={ButtonType.secondary}
            size={ButtonSize.small}
            onClick={onHelpGuide}
          />
        </Box>
      )}

      {!isBuyer && (
        <Box m="16px" display="flex" flexDirection="column">
          <Button
            title={published ? "CREATE REQUESTS" : "FINALISE LEGAL REVIEW"}
            type={ButtonType.primary}
            size={ButtonSize.medium}
            onClick={onFinaliseOrCreateRequests}
          />
          <Box height="4px" />
          <Button
            title="MORE OPTIONS"
            type={ButtonType.secondary}
            size={ButtonSize.small}
            onClick={onMoreOptions}
          />
        </Box>
      )}
    </StickyFooter>
  );
}

interface MobileFooterDrawerProps {
  open: boolean;
  onClose: () => unknown;
  onFinaliseOrCreateRequests: () => unknown;
  onEditLegalReview: () => unknown;
  onHelpGuide: () => unknown;
  published: boolean;
}

function MobileFooterDrawer({
  open,
  onClose,
  onFinaliseOrCreateRequests,
  onEditLegalReview,
  onHelpGuide,
  published,
}: MobileFooterDrawerProps) {
  return (
    <Drawer anchor="bottom" open={open} onClose={onClose}>
      <Box height="150px" width="100%" bgcolor="white">
        <Box m="16px" display="flex" flexDirection="column">
          <Button
            title={published ? "CREATE REQUESTS" : "FINALISE LEGAL REVIEW"}
            type={ButtonType.primary}
            size={ButtonSize.medium}
            onClick={onFinaliseOrCreateRequests}
          />
          <Box height="4px" />
          <Button
            title="EDIT LEGAL REVIEW"
            type={ButtonType.secondary}
            size={ButtonSize.small}
            onClick={onEditLegalReview}
          />
          <Box height="4px" />
          <Button
            title="HELP GUIDE"
            type={ButtonType.secondary}
            size={ButtonSize.small}
            onClick={onHelpGuide}
          />
        </Box>
      </Box>
    </Drawer>
  );
}

export default function LegalReviewViewer() {
  const location = useLocation();
  const { setErrorMessage, setSuccessMessage } = useAlert();
  const isMobile = useMobile();
  const history = useHistory();

  const {
    data: user,
    refresh: refreshUser,
    refreshTimestamp,
  } = useSessionStore();

  const isBuyer = user?.user_type === UserType.Buyer;
  const isPartner = user?.user_type === UserType.Partner;

  const query = new URLSearchParams(location.search);
  const _contractId = query.get("contractId");
  const contractId = !isNil(_contractId) ? parseInt(_contractId) : null;
  const _recordId = query.get("recordId");
  const recordId = !isNil(_recordId) ? parseInt(_recordId) : null;

  const { data, mutate: contractMutate } = useSWR(
    `/view-contracts/${contractId}/?recordId=${recordId}`
  );
  const contract = data ? (data as Contract) : null;

  const { data: recdata } = useSWR(
    `/view-contracts/${contractId}/`
  );
  const contractS = recdata as Contract;

  const published = contract ? contract.legal_summary_published : false;

  const { data: propertyData } = useSWR(
    contract?.property_id ? `/view-properties/${contract.property_id}/` : null
  );
  const property = propertyData && (propertyData as Property);

  const { data: summariesData, mutate: summariesMutate } = useSWR(
    !isNil(contractId)
      ? `contract/${contractId}/record/${recordId}/legal-review-summaries/`
      : null
  );

  const summaries = summariesData
    ? (summariesData as ContractSummary[]).filter(
        (summary) => summary.status === ContractSummaryState.published
      )
    : [];

  const { data: activitiesData, mutate: activitiesMutate } = useSWR(
    !isNil(contractId)
      ? `/contract/${contractId}/record/${recordId}/activities/`
      : null
  );

  const activities = activitiesData ? (activitiesData as Activity[]) : [];

  const [summaryForCommentsDialog, setSummaryForCommentsDialog] =
    React.useState<ContractSummary | null>(null);

  const [isEditing, setIsEditing] = React.useState(false);
  const [title, setTitle] = React.useState("");

  const [commentsDialogOpen, setCommentsDialogOpen] = React.useState(false);
  const [inviteModalOpen, setInviteModalOpen] = React.useState(false);
  const [inviteYourTeamMembersOpen, setInviteYourTeamMembersOpen] =
    React.useState(false);
  const [
    inviteYourTeamMembersUserTeamRole,
    setInviteYourTeamMembersUserTeamRole,
  ] = React.useState<TeamMemberRole | null>(null);
  const [inviteYourTeamMembersType, setInviteYourTeamMembersType] =
    React.useState(InviteYourTeamMembersModalType.default);

  const [shareWithYourBuyerTeamOpen, setShareWithYourBuyerTeamOpen] =
    React.useState(false);

  const [noBuyersYetOpen, setNoBuyersYetOpen] = React.useState(false);

  const [addAMessageOpen, setAddAMessageOpen] = React.useState(false);

  const [youHaveFinalisedOpen, setYouHaveFinalisedOpen] = React.useState(false);

  const [footerDrawerOpen, setFooterDrawerOpen] = React.useState(false);
  const [activityLogDrawerOpen, setActivityLogDrawerOpen] =
    React.useState(false);

  const [legalReviewHelpModalOpen, setLegalReviewHelpModalOpen] =
    React.useState(false);

  const [requestsHelpModalOpen, setRequestsHelpModalOpen] =
    React.useState(false);

  const [proceedDialogOpen, setProceedDialogOpen] = React.useState(false);
  const [proceedDialogSuccessOpen, setProceedDialogSuccessOpen] =
    React.useState(false);

  const [contractDrawer, setContractDrawer] = React.useState(false);

  const [pageNo, setPageNo] = React.useState(1);

  const [noBuyersYetFinalising, setNoBuyersYetFinalising] =
    React.useState(false);

  const handleContactDrawer = () => {
    setContractDrawer(false);
  };

  const onFinaliseOrCreateRequests = () => {
    if (published) {
      history.push(
        `/start-adding-requests?contractId=${contractId}&recordId=${recordId}`
      );
    } else {
      setShareWithYourBuyerTeamOpen(true);
    }
  };

  const onEditLegalReview = () => {
    history.push(
      `/legal-review-builder?contractId=${contractId}&recordId=${recordId}`
    );
  };

  const onViewInContractForSummary = (summary: ContractSummary) => {
    setContractDrawer(true);
    setPageNo(summary && summary.page);
  };

  const onLegalReviewHelpGuide = () => {
    setLegalReviewHelpModalOpen(true);
  };

  React.useEffect(() => {
    if (isLoggedIn()) {
      activitiesMutate();
    }
  }, []);

  const userHasAgreedOrPaused = !isNil(
    activities.find(
      (activity) =>
        activity.user.email === user?.email &&
        (activity.action === ActivityAction.Agree ||
          activity.action === ActivityAction.Pause)
    )
  );

  const buyerHasAgreed = !isNil(
    activities.find((activity) => activity.action === ActivityAction.Agree)
  );

  const [addAMessageSending, setAddAMessageSending] = React.useState(false);

  const { teamMembers, userTeamMemberRole, setContractAndRecordId } =
    useContractStore();

  const [downloadDialogOpen, setDownloadDialogOpen] = React.useState(false);

  React.useEffect(() => {
    if (!isNil(contractId) && !isNil(recordId)) {
      setContractAndRecordId(+contractId, +recordId);
      refreshContractTeamsAndRole();
    }
  }, [contractId, recordId]);

  React.useEffect(() => {
    user && refreshContractTeamsAndRole();
  }, [refreshTimestamp]);

  // If seller team member tries to access this page...
  React.useEffect(() => {
    if (
      !isNil(userTeamMemberRole) &&
      userTeamMemberRole !== TeamMemberRole.buyer &&
      userTeamMemberRole !== TeamMemberRole.buyerLawyer &&
      userTeamMemberRole !== TeamMemberRole.partner
    ) {
      // Redirect to property details page

      history.push(`/property-details?contractId=${contractId}&type=selling`);
    }
  }, [userTeamMemberRole]);

  React.useEffect(() => {
    if (contract) {
      setTitle(contract.title);
    }
  }, [contract]);

  const onSignedUp = () => {
    contractMutate();
    summariesMutate();
    activitiesMutate();
  };

  const onLoggedIn = () => {
    contractMutate();
    summariesMutate();
    activitiesMutate();
  };

  // Redirect Buyer to "Not ready" page if legal review isn't published yet
  if (
    contract &&
    contract.legal_summary_published === false &&
    (userTeamMemberRole === TeamMemberRole.buyer ||
      userTeamMemberRole === TeamMemberRole.partner)
  ) {
    return (
      <LegalReviewNotReady onLoggedIn={onLoggedIn} onSignedUp={onSignedUp} />
    );
  }

  // Redirect Buyer lawyer to "Not ready" page if no summaries added yet
  if (
    contract &&
    contract.legal_review_status === RecordStatus.Pending &&
    userTeamMemberRole === TeamMemberRole.buyerLawyer
  ) {
    return (
      <LegalReviewBuilderLanding
        onLoggedIn={onLoggedIn}
        onSignedUp={onSignedUp}
      />
    );
  }

  if (isEmpty(summaries)) {
    return (
      <LegalReviewBuilderLanding
        onLoggedIn={onLoggedIn}
        onSignedUp={onSignedUp}
      />
    );
  }

  const onDownloadButton = () => {
    setDownloadDialogOpen(true);
  };

  const onEditButton = () => {
    setIsEditing(true);
  };

  const onCancelButton = () => {
    setIsEditing(false);
  };

  const onSaveButton = () => {
    // Update Record name
    api
      .post(`/contract/${contractId}/record/${recordId}/update-record/`, {
        title: title,
      })
      .then((response) => {
        setIsEditing(false);
        contractMutate();
        setSuccessMessage("Record title has been updated");
      })
      .catch((error) => {
        setIsEditing(false);

        setErrorMessage(parseApiError(error));
      });
  };

  const legalReviewForName = !isEmpty(contract?.title)
    ? `${contract?.title}`
    : "...";

  return (
    <OnboardingWrapper onLoggedIn={onLoggedIn} onSignedUp={onSignedUp}>
      <AlertWrapper>
        <Box display="flex" flexDirection="column">
          <LegalReviewWhiteHeader
            property={property}
            onInviteClicked={() => {
              setInviteModalOpen(true);
            }}
          />
          <ViewContractDrawer
            contractFile={contract && contract.file}
            contractDrawer={contractDrawer}
            handleContactDrawer={handleContactDrawer}
            pageNo={pageNo}
          />

          {!isMobile && contractId && recordId && (
            <Box display="flex" flexDirection="row" justifyContent="center">
              <Box maxWidth="726px" width="100%">
                <Box mt={3}>
                  <LegalReviewProgressBar
                    width="auto"
                    contractId={+contractId}
                    recordId={+recordId}
                  />
                </Box>
                <Box
                  mt={3}
                  mb={3}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  maxWidth="726px"
                >
                  <Box display="flex" alignItems="center">
                    {!isEditing ? (
                      <>
                        <Typography
                          variant="h6"
                          style={{ fontWeight: 700, marginRight: "5px" }}
                        >
                          Legal Review for
                        </Typography>
                        <span
                          style={{
                            color: Colors.BrandPrimary,
                            marginRight: "8px",
                            fontWeight: 700,
                            fontVariant: "inherit",
                            fontSize: "1.25rem",
                            fontFamily: "GT Walsheim Pro",
                            lineHeight: "1.6",
                          }}
                        >
                          {legalReviewForName}
                        </span>
                        {userTeamMemberRole === TeamMemberRole.buyerLawyer && (
                          <EditButton onClick={onEditButton} />
                        )}
                      </>
                    ) : (
                      <>
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          width="100%"
                        >
                          <Typography
                            variant="h6"
                            style={{ fontWeight: 700, marginRight: "8px" }}
                          >
                            Legal Review for
                          </Typography>
                          <Box mr={1} height="55px">
                            <TextField
                              value={title}
                              onChange={(text) => setTitle(text)}
                            />
                          </Box>
                          <Box mr={1}>
                            <Button
                              title="SAVE"
                              size={ButtonSize.medium}
                              type={ButtonType.primary}
                              onClick={onSaveButton}
                              width="63px"
                            />
                          </Box>
                          <Box mr={1}>
                            <Button
                              title="CANCEL"
                              size={ButtonSize.medium}
                              type={ButtonType.secondary}
                              onClick={onCancelButton}
                              width="83px"
                            />
                          </Box>
                        </Box>
                      </>
                    )}

                    {!isEmpty(summaries) &&
                      property &&
                      contractId &&
                      recordId && <DownloadButton onClick={onDownloadButton} />}
                  </Box>
                </Box>

                {summaries.map((summary) => (
                  <SummaryItem
                    contractSummary={summary}
                    onViewInContract={() => onViewInContractForSummary(summary)}
                    onComment={() => {
                      setSummaryForCommentsDialog(summary);
                      setCommentsDialogOpen(true);
                    }}
                  />
                ))}
              </Box>
              <Box width="353px" ml="19px">
                {user?.user_type !== UserType.Partner && (
                  <Box
                    width="100%"
                    bgcolor={Colors.BrandBackground3}
                    pl="24px"
                    pr="24px"
                    pt="16px"
                    pb="16px"
                    borderRadius="4px"
                    mt="24px"
                  >
                    {user?.user_type === UserType.Solicitor && (
                      <>
                        {published && (
                          <Button
                            type={ButtonType.primary}
                            size={ButtonSize.large}
                            title={"CREATE REQUESTS"}
                            onClick={onFinaliseOrCreateRequests}
                            width="100%"
                          />
                        )}
                        {!published && (
                          <Button
                            type={ButtonType.primary}
                            size={ButtonSize.large}
                            title={"FINALISE LEGAL REVIEW"}
                            onClick={onFinaliseOrCreateRequests}
                            width="100%"
                          />
                        )}

                        <Box height="16px" />
                        <Button
                          type={ButtonType.secondary}
                          size={ButtonSize.medium}
                          title="EDIT LEGAL REVIEW"
                          onClick={onEditLegalReview}
                          width="100%"
                        />
                        <Box height="16px" />
                      </>
                    )}

                    {user?.user_type === UserType.Buyer && (
                      <>
                        <Button
                          type={ButtonType.primary}
                          size={ButtonSize.large}
                          title={
                            userHasAgreedOrPaused ? "UPDATE STATUS" : "NEXT"
                          }
                          onClick={() => setProceedDialogOpen(true)}
                          width="100%"
                        />
                        <Box height="16px" />
                      </>
                    )}

                    <Button
                      type={ButtonType.secondary}
                      size={ButtonSize.small}
                      title="HELP GUIDE"
                      onClick={onLegalReviewHelpGuide}
                      width="100%"
                    />
                  </Box>
                )}

                <Box display="flex" flexDirection="column" height="100%">
                  <Typography variant="h6" style={{ marginTop: "24px" }}>
                    Activity Log
                  </Typography>

                  <ActivityLog activities={activities} />
                </Box>
              </Box>
            </Box>
          )}

          {isMobile && contractId && recordId && (
            <>
              <Box
                pt="13px"
                pl="16px"
                pr="16px"
                bgcolor={Colors.Grey5}
                minHeight="90vh"
              >
                <LegalReviewProgressBar
                  contractId={+contractId}
                  recordId={+recordId}
                />

                <Box
                  mt="17px"
                  mb="17px"
                  ml="5px"
                  mr="5px"
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" flexDirection={"row"}>
                    <Typography
                      variant="h6"
                      style={{ fontWeight: 700, marginRight: "8px" }}
                    >
                      {!isEditing ? (
                        <>
                          <span
                            style={{
                              color: Colors.BrandPrimary,
                              marginRight: "16px",
                              fontWeight: 700,
                              fontVariant: "inherit",
                              fontSize: "1.25rem",
                              fontFamily: "GT Walsheim Pro",
                              lineHeight: "1.6",
                            }}
                          >
                            {legalReviewForName}
                          </span>
                          {userTeamMemberRole ===
                            TeamMemberRole.buyerLawyer && (
                            <EditButton onClick={onEditButton} />
                          )}
                        </>
                      ) : (
                        <>
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            width="100%"
                          >
                            <Box mr={1} height="55px">
                              <TextField
                                value={title}
                                onChange={(text) => setTitle(text)}
                              />
                            </Box>
                            <Box mr={1}>
                              <Button
                                title="SAVE"
                                size={ButtonSize.medium}
                                type={ButtonType.primary}
                                onClick={onSaveButton}
                                width="63px"
                              />
                            </Box>
                            <Box>
                              <Button
                                title="CANCEL"
                                size={ButtonSize.medium}
                                type={ButtonType.secondary}
                                onClick={onCancelButton}
                                width="83px"
                              />
                            </Box>
                          </Box>
                        </>
                      )}
                    </Typography>

                    {!isEmpty(summaries) && property && contractId && recordId && (
                      <Box
                        mr={3}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <DownloadButton onClick={onDownloadButton} />
                      </Box>
                    )}
                  </Box>

                  <Link
                    style={{
                      color: Colors.Link,
                      cursor: "pointer",
                    }}
                    onClick={() => setActivityLogDrawerOpen(true)}
                  >
                    <Typography variant="body2" style={{ fontWeight: 700 }}>
                      Activity log
                    </Typography>
                  </Link>
                </Box>
                {summaries.map((summary) => (
                  <SummaryItem
                    contractSummary={summary}
                    onViewInContract={() => onViewInContractForSummary(summary)}
                    onComment={() => {
                      setSummaryForCommentsDialog(summary);
                      setCommentsDialogOpen(true);
                    }}
                  />
                ))}

                {!isPartner && (
                  <MobileStickyFooter
                    onFinaliseOrCreateRequests={onFinaliseOrCreateRequests}
                    onMoreOptions={() => setFooterDrawerOpen(true)}
                    published={published}
                    isBuyer={isBuyer}
                    onBuyerNext={() => setProceedDialogOpen(true)}
                    onHelpGuide={onLegalReviewHelpGuide}
                  />
                )}
              </Box>
            </>
          )}
        </Box>
      </AlertWrapper>
      <CommentsDialog
        type={CommentsDialogType.summary}
        journey={CommentsDialogJourney.LegalReview}
        contractId={contractId}
        recordId={recordId}
        summary={summaryForCommentsDialog}
        open={commentsDialogOpen}
        onClose={() => {
          setCommentsDialogOpen(false);

          summariesMutate();
        }}
      />

      <InviteModal
        open={inviteModalOpen}
        onClose={() => setInviteModalOpen(false)}
        onInviteTeamMembersClicked={(userTeamMemberRole) => {
          setInviteModalOpen(false);
          setInviteYourTeamMembersType(InviteYourTeamMembersModalType.default);
          setInviteYourTeamMembersUserTeamRole(userTeamMemberRole);
          setInviteYourTeamMembersOpen(true);
        }}
        onInviteAgents={() => {
          setInviteModalOpen(false);
          setInviteYourTeamMembersType(InviteYourTeamMembersModalType.agents);
          setInviteYourTeamMembersUserTeamRole(null);
          setInviteYourTeamMembersOpen(true);
        }}
        contractId={contractId}
        recordId={recordId}
      />

      <InviteYourTeamMembersModal
        open={inviteYourTeamMembersOpen}
        onClose={() => setInviteYourTeamMembersOpen(false)}
        onInvited={(firstName, lastName) => {
          setInviteYourTeamMembersOpen(false);
          setSuccessMessage(
            `${firstName} ${lastName} has been invited to your team`
          );

          refreshContractTeamsAndRole();
          contractMutate();
          setInviteModalOpen(true);
        }}
        contractId={contractId}
        recordId={recordId}
        userTeamMemberRole={inviteYourTeamMembersUserTeamRole}
        type={inviteYourTeamMembersType}
      />

      <ShareWithDialog
        open={shareWithYourBuyerTeamOpen}
        title="Share with your buyer team"
        message="After you finalise, invited buyers can view and comment on the legal review. You've invited the following buyers:"
        onClose={() => setShareWithYourBuyerTeamOpen(false)}
        onNext={() => {
          setShareWithYourBuyerTeamOpen(false);

          const buyersCount = teamMembers.filter(
            (member) => member.role === TeamMemberRole.buyer
          ).length;

          if (buyersCount === 0) {
            setNoBuyersYetOpen(true);
          } else {
            setAddAMessageOpen(true);
          }
        }}
      />

      <NoBuyersYetDialog
        open={noBuyersYetOpen}
        finalising={noBuyersYetFinalising}
        onFinaliseAnyway={() => {
          setNoBuyersYetFinalising(true);

          api
            .post(
              `/contract/${contractId}/record/${recordId}/buyer-team/message/`,
              {}
            )
            .then((response) => {
              setNoBuyersYetFinalising(false);

              setNoBuyersYetOpen(false);

              contractMutate();
              activitiesMutate();
            })
            .catch((error) => {
              setNoBuyersYetFinalising(false);

              setErrorMessage(parseApiError(error));
            });
        }}
        onCancel={() => setNoBuyersYetOpen(false)}
      />

      <AddAMessageDialog
        open={addAMessageOpen}
        sendTitle="FINALISE"
        sending={addAMessageSending}
        onSend={(message) => {
          setAddAMessageSending(true);

          const data = {
            message,
          };

          api
            .post(
              `/contract/${contractId}/record/${recordId}/buyer-team/message/`,
              !isEmpty(message) ? data : {}
            )
            .then((response) => {
              setAddAMessageSending(false);

              setAddAMessageOpen(false);

              contractMutate();
              activitiesMutate();
            })
            .catch((error) => {
              setAddAMessageSending(false);

              setErrorMessage(parseApiError(error));
            });
        }}
        onCancel={() => setAddAMessageOpen(false)}
        forScreen={AddAMessageDialogForScreen.legalreviewviewer}
        contract={contract}
      />

      <AddAMessageSentDialog
        open={youHaveFinalisedOpen}
        onClose={() => setYouHaveFinalisedOpen(false)}
        content={
          <>
            You've finalised your legal review. <br />
            <br />
            Add more buyers to the team at any time.
          </>
        }
      />

      <MobileFooterDrawer
        open={footerDrawerOpen}
        onClose={() => setFooterDrawerOpen(false)}
        onFinaliseOrCreateRequests={() => {
          setFooterDrawerOpen(false);
          onFinaliseOrCreateRequests();
        }}
        onEditLegalReview={() => {
          setFooterDrawerOpen(false);
          onEditLegalReview();
        }}
        onHelpGuide={onLegalReviewHelpGuide}
        published={published}
      />

      <ActivityLogDrawer
        open={activityLogDrawerOpen}
        onClose={() => setActivityLogDrawerOpen(false)}
        activities={activities}
      />

      {userTeamMemberRole === TeamMemberRole.buyer && (
        <HelpGuideModal
          title="How to review your legal review"
          open={legalReviewHelpModalOpen}
          onClose={() => setLegalReviewHelpModalOpen(false)}
          items={
            <>
              <HelpGuideModalItem
                index={1}
                title="Read summaries and comment if you have questions"
                image={LegalReviewBuilderHelp1}
                imageMarginTop="62px"
              />
              <Box width="6px" />
              <HelpGuideModalItem
                index={2}
                title="Click 'Next' to let your legal representative know how you would like to proceed"
                image={LegalReviewBuilderHelp2}
              />
            </>
          }
        />
      )}

      {userTeamMemberRole === TeamMemberRole.buyerLawyer && (
        <HelpGuideModal
          title="How to build your legal review"
          open={legalReviewHelpModalOpen}
          onClose={() => setLegalReviewHelpModalOpen(false)}
          items={
            <>
              <HelpGuideModalItem
                index={1}
                title="Create legal review to get started"
                image={BLReviewHelp1}
                imageMarginTop="56px"
              />
              <Box width="6px" />
              <HelpGuideModalItem
                index={2}
                title="Add, edit or dismiss legal review  items"
                image={BLReviewHelp2}
                imageMarginTop="32px"
              />
              <Box width="6px" />
              <HelpGuideModalItem
                index={3}
                title="Finalise your review and share it with your team"
                image={BLReviewHelp3}
                imageMarginTop="32px"
              />
            </>
          }
        />
      )}

      <HelpGuideModal
        title="How to create your requests"
        open={requestsHelpModalOpen}
        onClose={() => setRequestsHelpModalOpen(false)}
        items={
          <>
            <HelpGuideModalItem
              index={1}
              title="Add, edit or dismiss suggested requests or create your own"
              image={RequestHelp1}
              imageMarginTop="69px"
            />
            <Box width="6px" />
            <HelpGuideModalItem
              index={2}
              title="Send your request to the vendor’s legal representatives"
              image={RequestHelp2}
            />
            <Box width="6px" />
            <HelpGuideModalItem
              index={3}
              title="Keep track of responses, manage requests and send updates if needed"
              image={RequestHelp3}
            />
          </>
        }
      />

      <ProceedDialog
        open={proceedDialogOpen}
        onSent={() => {
          setProceedDialogOpen(false);
          activitiesMutate();
          setProceedDialogSuccessOpen(true);
        }}
        onCancelled={() => setProceedDialogOpen(false)}
        contractId={contractId}
        recordId={recordId}
      />

      <ProceedSuccessDialog
        open={proceedDialogSuccessOpen}
        onClose={() => setProceedDialogSuccessOpen(false)}
      />

      {!isEmpty(summaries) &&
        contract &&
        recordId &&
        property &&
        userTeamMemberRole && (
          <DownloadDialog
            open={downloadDialogOpen}
            onCancel={() => setDownloadDialogOpen(false)}
            summaries={summaries}
            contract={contract}
            contractS={contractS}
            recordId={recordId}
            property={property}
            userTeamMemberRole={userTeamMemberRole}
            legalReviewForName={legalReviewForName}
          />
        )}
    </OnboardingWrapper>
  );
}
