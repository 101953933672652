import { Box, Dialog, Typography } from "@material-ui/core";
import React from "react";
import { useMobile } from "../../hooks/mobile";
import Button, { ButtonSize, ButtonType } from "../common/Button";
import Separator from "../common/Separator";

interface Props {
  open: boolean;
  onYes: () => unknown;
  onNo: () => unknown;
  onClose: () => unknown;
}

export default function RemoveCardDialog({
  open,
  onYes,
  onNo,
  onClose,
}: Props) {
  const isMobile = useMobile();

  return (
    <Dialog open={open} fullScreen={isMobile} onClose={onClose}>
      <Box
        width={isMobile ? "100%" : "524px"}
        display="flex"
        flexDirection="column"
      >
        <Box boxSizing="border-box" pl="24px" pr="24px" pt="16px" pb="16px">
          <Typography variant="h6" style={{ fontWeight: 700 }}>
            Remove card
          </Typography>
        </Box>
        <Separator />

        <Box p="24px">
          <Typography variant="body1">
            Are you sure you want to remove this card?
          </Typography>
        </Box>

        <Separator />

        <Box
          display="flex"
          justifyContent="flex-end"
          pl="24px"
          pr="16px"
          height="74px"
          alignItems="center"
        >
          <Button
            title="YES"
            size={ButtonSize.medium}
            type={ButtonType.secondary}
            onClick={onYes}
          />
          <Box width="8px" />
          <Button
            title="NO"
            size={ButtonSize.medium}
            type={ButtonType.primary}
            onClick={onNo}
          />
        </Box>
      </Box>
    </Dialog>
  );
}
