import React from "react";
import { Box, Typography, Dialog } from "@material-ui/core";
import Button, { ButtonType, ButtonSize } from "../common/Button";
import Separator from "../common/Separator";
import InfoModalIcon from "../../images/info-modal-icon.svg";

interface Props {
  open: boolean;
  onFinaliseAnyway: () => unknown;
  onCancel: () => unknown;
  finalising: boolean;
}

export default function NoBuyersYetDialog({
  open,
  onFinaliseAnyway,
  onCancel,
  finalising,
}: Props) {
  return (
    <Dialog
      open={open}
      aria-labelledby="share-with-your-buyer-team-dialog"
      aria-describedby="share-with-your-buyer-team-description"
    >
      <Box width="514px" height="384px" display="flex" flexDirection="column">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          flex={1}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <img src={InfoModalIcon} />

            <Typography
              variant="h6"
              style={{
                textAlign: "center",
                maxWidth: "279px",
                marginTop: "32px",
                lineHeight: "24px",
              }}
            >
              You haven’t invited any buyers. Finalise anyway and you can invite
              buyers later.
            </Typography>
          </Box>
        </Box>

        <Separator />
        <Box
          display="flex"
          justifyContent="flex-end"
          pl="24px"
          pr="16px"
          height="74px"
          alignItems="center"
        >
          <Button
            title="CANCEL"
            size={ButtonSize.medium}
            type={ButtonType.secondary}
            onClick={onCancel}
            width="114px"
          />
          <Box width="8px" />
          <Button
            loading={finalising}
            title="FINALISE ANYWAY"
            size={ButtonSize.medium}
            type={ButtonType.primary}
            onClick={onFinaliseAnyway}
            width="189px"
          />
        </Box>
      </Box>
    </Dialog>
  );
}
