import { Box, Dialog, Typography } from "@material-ui/core";
import { isEmpty, isNil } from "lodash-es";
import React from "react";
import { TeamMember, TeamMemberRole, User, UserType } from "../../api/models";
import { Colors } from "../../constants/colors";
import {
  refreshContractTeamsAndRole,
  useContractStore,
} from "../../stores/contract";
import Button, { ButtonSize, ButtonType } from "../common/Button";
import SectionControl from "../common/SectionControl";
import Separator from "../common/Separator";
import InfoMessageBar from "../InfoMessageBar";
import InviteModalUserItem from "./InviteModalUserItem";
import api from "../../api/instance";
import { useMobile } from "../../hooks/mobile";
import { useSessionStore } from "../../stores/Session";

export enum InviteModalType {
  default,
  agents,
  sellers,
}

interface Props {
  type?: InviteModalType;
  open: boolean;
  onClose: () => unknown;
  onInviteTeamMembersClicked?: (
    userTeamMemberRole: TeamMemberRole | null
  ) => unknown;
  onInviteAgents?: () => unknown;
  onInviteSellers?: () => unknown;
  contractId: number | null;
  recordId?: number | null;
}

enum Section {
  BuyerTeam = "Buyer team",
  SellerTeam = "Seller team",
  Agents = "Agents",
}

interface InvitePeopleMessageProps {
  userType?: UserType | null;
}

function InvitePeopleMessage({ userType }: InvitePeopleMessageProps) {
  return (
    <InfoMessageBar
      message={"Invite participants to collaborate on this contract"}
    />
  );
}

interface TeamMembersProps {
  inviteTitle: string;
  onInvite: () => unknown;
  members: TeamMember[];
  inviteEnabled: boolean;
  role: TeamMemberRole | null;
  userType?: UserType | null;
}

function TeamMembers({
  inviteTitle,
  onInvite,
  members,
  inviteEnabled,
  role,
  userType,
}: TeamMembersProps) {
  return (
    <>
      {inviteEnabled && (
        <Box
          height="66px"
          bgcolor={Colors.Grey5}
          pl="20px"
          pr="20px"
          alignItems="center"
          justifyContent="center"
          display="flex"
        >
          <Button
            title={inviteTitle}
            type={ButtonType.primary}
            size={ButtonSize.medium}
            width="100%"
            onClick={onInvite}
          />
        </Box>
      )}

      <InvitePeopleMessage userType={userType} />
      <Box minHeight="80px">
        {members.length < 1 ? (
          <Box
            alignItems="center"
            pl="24px"
            pt="24px"
            justifyContent="space-between"
          >
            <Typography variant="body2">
              Nobody currently in this team.
            </Typography>
          </Box>
        ) : (
          <>
            {members.map((member) => (
              <InviteModalUserItem member={member} />
            ))}
          </>
        )}
      </Box>
    </>
  );
}

export default function ({
  type = InviteModalType.default,
  open,
  onClose,
  onInviteTeamMembersClicked,
  onInviteAgents,
  onInviteSellers,
  contractId,
  recordId,
}: Props) {
  const isMobile = useMobile();

  const {
    userTeamMemberRole,
    setContractAndRecordId,
    buyerTeamMembers,
    sellerTeamMembers,
    agentTeamMembers,
  } = useContractStore();

  const { data: userData } = useSessionStore();

  const user = userData && (userData as User);

  // If no record id
  const [contractAgentTeamMembers, setContractAgentTeamMembers] =
    React.useState<TeamMember[]>([]);
  const [contractSellerTeamMembers, setContractSellerTeamMembers] =
    React.useState<TeamMember[]>([]);

  React.useEffect(() => {
    if (!isNil(contractId) && !isNil(recordId)) {
      setContractAndRecordId(contractId, recordId);
      refreshContractTeamsAndRole();
    } else if (!isNil(contractId)) {
      // No record id

      if (type === InviteModalType.agents || type === InviteModalType.sellers) {
        fetchContractAgentTeamMembers();
        fetchContractSellerTeamMembers();
      }
    }
  }, [contractId, recordId]);

  function fetchContractAgentTeamMembers() {
    api.get(`/contract/${contractId}/agent-team/`).then((response) => {
      const teamMembers = response.data as TeamMember[];

      setContractAgentTeamMembers(teamMembers);
    });
  }

  function fetchContractSellerTeamMembers() {
    api
      .get(`/contract/${contractId}/contract-seller-team/`)
      .then((response) => {
        const teamMembers = response.data as TeamMember[];

        setContractSellerTeamMembers(teamMembers);
      });
  }

  const _sections = () => {
    if (type === InviteModalType.agents) {
      return [Section.Agents, Section.SellerTeam];
    }

    if (type === InviteModalType.sellers) {
      return [Section.SellerTeam, Section.Agents];
    }

    switch (userTeamMemberRole) {
      case TeamMemberRole.buyer:
      case TeamMemberRole.buyerLawyer:
        return [Section.BuyerTeam, Section.SellerTeam, Section.Agents];

      case TeamMemberRole.seller:
      case TeamMemberRole.sellerLawyer:
        return [Section.SellerTeam, Section.BuyerTeam, Section.Agents];

      case TeamMemberRole.agent:
        return [Section.Agents, Section.SellerTeam];

      case TeamMemberRole.partner:
        return [Section.BuyerTeam, Section.SellerTeam, Section.Agents];
    }

    return [];
  };

  const sections = _sections();

  const [selectedSection, setSelectedSection] = React.useState<
    Section | null | undefined
  >(null);

  React.useEffect(() => {
    if (!isEmpty(sections)) {
      setSelectedSection(sections[0]);
    }
  }, [userTeamMemberRole, type]);

  React.useEffect(() => {
    if (
      !isNil(contractId) &&
      (type === InviteModalType.agents || type === InviteModalType.sellers)
    ) {
      fetchContractAgentTeamMembers();
      fetchContractSellerTeamMembers();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      aria-labelledby="invite-dialog"
      aria-describedby="invite-dialog-description"
      fullWidth
      onClose={onClose}
      fullScreen={isMobile}
    >
      <Box maxWidth="626px" display="flex" flexDirection="column">
        <Box
          height="62px"
          pl="24px"
          alignItems="center"
          display="flex"
          flexDirection="row"
        >
          <Typography variant="h5" style={{ fontWeight: 700 }}>
            Invite
          </Typography>
        </Box>
        <Separator />
        {sections.length > 1 && (
          <Box>
            <SectionControl
              sections={sections}
              onSelectedSection={(section) =>
                setSelectedSection(section as Section)
              }
              selectedSection={selectedSection as string}
            />
          </Box>
        )}

        {selectedSection === Section.BuyerTeam && (
          <TeamMembers
            inviteTitle="INVITE TO WORKSPACE"
            onInvite={() =>
              onInviteTeamMembersClicked &&
              onInviteTeamMembersClicked(userTeamMemberRole)
            }
            members={buyerTeamMembers.filter((member) => {
              // Buyer lawyers can only see/invite seller lawyers
              if (userTeamMemberRole === TeamMemberRole.sellerLawyer) {
                return member.role === TeamMemberRole.buyerLawyer;
              }

              return true;
            })}
            inviteEnabled={true}
            role={userTeamMemberRole}
            userType={user?.user_type}
          />
        )}

        {selectedSection === Section.SellerTeam && (
          <TeamMembers
            inviteTitle="INVITE TO WORKSPACE"
            onInvite={() => {
              if (!isNil(onInviteSellers)) {
                onInviteSellers && onInviteSellers();
              } else {
                onInviteTeamMembersClicked &&
                  onInviteTeamMembersClicked(userTeamMemberRole);
              }
            }}
            members={
              type === InviteModalType.sellers ||
              type === InviteModalType.agents
                ? contractSellerTeamMembers
                : sellerTeamMembers.filter((member) => {
                    // Buyer lawyers can only see/invite seller lawyers
                    if (userTeamMemberRole === TeamMemberRole.buyerLawyer) {
                      return (
                        member.role === TeamMemberRole.sellerLawyer ||
                        TeamMemberRole.seller
                      );
                    }

                    return true;
                  })
            }
            inviteEnabled={true}
            role={userTeamMemberRole}
            userType={user?.user_type}
          />
        )}

        {selectedSection === Section.Agents && (
          <TeamMembers
            inviteTitle="INVITE TO WORKSPACE"
            onInvite={() => onInviteAgents && onInviteAgents()}
            members={
              !isNil(recordId) ? agentTeamMembers : contractAgentTeamMembers
            }
            inviteEnabled={
              userTeamMemberRole === TeamMemberRole.agent ||
              userTeamMemberRole === TeamMemberRole.seller ||
              userTeamMemberRole === TeamMemberRole.sellerLawyer ||
              userTeamMemberRole === TeamMemberRole.buyerLawyer ||
              userTeamMemberRole === TeamMemberRole.partner ||
              userTeamMemberRole === TeamMemberRole.buyer ||
              type === InviteModalType.agents ||
              type === InviteModalType.sellers
            }
            role={userTeamMemberRole}
            userType={user?.user_type}
          />
        )}

        <Separator />
        <Box
          display="flex"
          justifyContent="space-between"
          pl="24px"
          pr="16px"
          height="74px"
          alignItems="center"
        >
          <Typography variant="caption">
            Contact us to remove a team member
          </Typography>
          <Button
            title="DONE"
            size={ButtonSize.medium}
            type={ButtonType.primary}
            onClick={onClose}
            width="86px"
          />
        </Box>
      </Box>
    </Dialog>
  );
}
