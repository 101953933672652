import create from "zustand";

interface State {
  amountCents: number;
  credits: number;
  creditPackId: number | null;
  setAmountCents: (amountCents: number) => unknown;
  setCredits: (credits: number) => unknown;
  setCreditPackId: (id: number | null) => unknown;
  purchaseJourneyStartPath: string | null;
  purchaseJourneySuccessPath: string | null;
  setPurchaseJourneyStartPath: (path: string) => unknown;
  setPurchaseJourneySuccessPath: (path: string) => unknown;
  reset: () => unknown;
}

export const usePurchaseStore = create<State>((set, get) => ({
  amountCents: 0,
  credits: 0,
  creditPackId: null,
  setAmountCents: (amountCents: number) => {
    set({
      amountCents,
    });
  },
  setCredits: (credits: number) => {
    set({
      credits,
    });
  },
  setCreditPackId: (id: number | null) => {
    set({
      creditPackId: id,
    });
  },
  purchaseJourneyStartPath: null,
  purchaseJourneySuccessPath: null,
  setPurchaseJourneyStartPath: (path: string) => {
    set({
      purchaseJourneyStartPath: path,
    });
  },
  setPurchaseJourneySuccessPath: (path: string) => {
    set({
      purchaseJourneySuccessPath: path,
    });
  },
  reset: () => {
    set({
      amountCents: 0,
      credits: 0,
      creditPackId: null,
      purchaseJourneyStartPath: null,
      purchaseJourneySuccessPath: null,
    });
  },
}));
