import React from "react";
import { Box, Dialog, Typography } from "@material-ui/core";
import { useMobile } from "../../hooks/mobile";
import Button, { ButtonSize, ButtonType } from "../../components/common/Button";
import Separator from "../../components/common/Separator";
import { getPersonalisationUrl } from "../../helpers/personalisation";
import { getPartnerPersonalisationUrl } from "../../helpers/PartnerPersonalisation";
import { useSessionStore } from "../../stores/Session";
import { useAlert } from "../../contexts/Alert";
import copy from "copy-to-clipboard";
import { User, UserType } from "../../api/models";

interface Props {
  open: boolean;
  onClose: () => unknown;
}

export default function PublishedModal({ open, onClose }: Props) {
  const isMobile = useMobile();

  const { data: userData } = useSessionStore();
  const { setSuccessMessage } = useAlert();

  const user = userData && (userData as User);

  const isPartner = user?.user_type === UserType.Partner;

  const url =
    userData?.current_organization && !isPartner
      ? getPersonalisationUrl(userData.current_organization)
      : getPartnerPersonalisationUrl(userData?.current_organization);

  return (
    <Dialog open={open} fullScreen={isMobile} maxWidth={"xl"} onClose={onClose}>
      <Box
        display="flex"
        flexDirection="column"
        width={isMobile ? "100%" : "524px"}
        alignItems="center"
      >
        <Box
          display="flex"
          width={isMobile ? "90vw" : "412px"}
          flexDirection="column"
          alignItems="center"
        >
          <Typography variant="h5" style={{ marginTop: "24px" }}>
            Congratulations
          </Typography>

          <Typography
            variant="body1"
            style={{ marginTop: "8px", textAlign: "center" }}
          >
            Your personalisations have been published.
            <br /> Your landing page is now live. Click the link below to view.
          </Typography>

          <Box display="flex" flexDirection="row" alignItems="center" mt="18px">
            <Box
              bgcolor="#F4F6F8"
              border="1px solid #E8E8E8"
              borderRadius="4px"
              minHeight="34px"
              paddingLeft="8px"
              paddingRight="8px"
              alignItems="center"
              display="flex"
              marginRight="8px"
              paddingTop="6px"
              paddingBottom="6px"
            >
              <Typography variant="body1" style={{ wordBreak: "break-word" }}>
                {url}
              </Typography>
            </Box>
            <Button
              title="COPY LINK"
              size={ButtonSize.small}
              type={ButtonType.primary}
              onClick={() => {
                copy(url);
                setSuccessMessage("Copied Link");
              }}
              width="120px"
            />
          </Box>

          <Typography
            variant="body1"
            style={{ marginTop: "40px", marginBottom: "24px" }}
          >
            You can view your landing page here
          </Typography>

          <Button
            title="GO TO PAGE"
            size={ButtonSize.small}
            type={ButtonType.primary}
            onClick={() => {
              window.open(url, "_blank");
            }}
            width="118px"
          />
        </Box>

        <Separator mt="27px" width="100%" />

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
          height="74px"
          width="100%"
          pr="16px"
          pl="16px"
          boxSizing="border-box"
        >
          <Box border="1px solid rgb(236, 242, 251)" borderRadius="6px">
            <Button
              title="DONE"
              size={ButtonSize.medium}
              type={ButtonType.white}
              onClick={onClose}
              width="94px"
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
