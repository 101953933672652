import React from "react";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Switch } from "@material-ui/core";
import { Colors } from "../../constants/colors";

export default withStyles({
  switchBase: {
    color: "white",
    "&$checked": {
      color: Colors.BrandPrimary,
    },
    "&$checked + $track": {
      backgroundColor: Colors.BrandPrimary,
    },
  },
  checked: {},
  track: {
    opacity: 0.38,
  },
})(Switch);
