import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useHistory, useLocation, useParams } from "react-router-dom";
import api from "../../api/instance";
import { useMobile } from "../../hooks/mobile";
import { isLoggedIn } from "../../stores/Session";
import AlertWrapper from "../../components/AlertWrapper";
import { useAlert } from "../../contexts/Alert";
import AsyncSelect from "../../components/common/AsyncSelect";
import SearchFieldIcon from "../../images/search-field-icon.svg";
import TextField from "../../components/common/TextField";
import {
  search as searchPlace,
  placeDetails,
  GooglePlaceSearchResult,
  normalisedPlaceId,
} from "../../googleplaces/queries";
import MapPinIcon from "../../images/map-pin.png";
import Button, { ButtonType, ButtonSize } from "../../components/common/Button";
import { isNil } from "lodash-es";
import { Contract, Property, ContractPublishStatus } from "../../api/models";
import { AxiosError } from "axios";
import useSWR from "swr";
import { useSessionStore } from "../../stores/Session";
import { User } from "../../api/models";

interface PlaceOption {
  image: string;
  value: string;
  label: string;
  subLabel: string;
  description: string;
}

interface PlaceDetails {
  street: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}

interface Params {
  uuid?: string;
}

export default function PartnerAddOnProperty(): JSX.Element {
  const history = useHistory();
  const location = useLocation();

  const { uuid } = useParams<Params>();
  const { search: urlParams } = location;

  const query = new URLSearchParams(location.search);
  const propertyId = query.get("propertyId");

  const [selectedPlaceId, setSelectedPlaceId] = React.useState<string | null>(
    null
  );

  const [selectedLabel, setSelectedLabel] = React.useState<String | null>(null);

  const [streetName, setStreetName] = React.useState<String | null>(null);

  const [selectedPlaceDetails, setSelectedPlaceDetails] =
    React.useState<PlaceDetails | null>(null);
  const [selectedPlaceError, setSelectedPlaceError] = React.useState<
    string | null
  >(null);

  const [propertyURL, setPropertyURL] = React.useState("");
  const [propertyURLError, setPropertyURLError] = React.useState<string | null>(
    null
  );

  // const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [uploadError, setUploadError] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const isMobile = useMobile();
  const { setErrorMessage } = useAlert();

  const searchTimeoutRef = React.useRef<any>(null);
  const prefilledPropertyRef = React.useRef(false);

  const { data: userData } = useSessionStore();
  const user = userData && (userData as User);

  const { data: propertyData } = useSWR(
    propertyId && `/view-properties/${propertyId}/`
  );
  const property = propertyData && (propertyData as Property);

  //   Custom css style for react async select
  const customStyles = {
    control: (base) => ({
      ...base,
      height: isMobile ? "59px" : "83px",
      minHeight: isMobile ? "59px" : "83px",
      borderRadius: "16px",
      borderColor: "#FFF",
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      fontSize: isMobile ? "16px" : "24px",
      lineHeight: "32px",
      fontWeight: 400,
      fontFamily: "GT Walsheim Pro",
      color: "#9F9F9F",
      marginLeft: "8px",
    }),
    input: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontSize: isMobile ? "16px" : "24px",
        lineHeight: "32px",
        fontWeight: 400,
        fontFamily: "GT Walsheim Pro",
        color: "#0038E4",
        marginLeft: "8px",
      };
    },
  };

  React.useEffect(() => {
    if (selectedPlaceId) {
      placeDetails(selectedPlaceId).then((result) => {
        console.debug("view result: ", result);
        let place = result as google.maps.places.PlaceResult;
        let components = place.address_components;
        if (components) {
          let subPremise =
            components.find((component) =>
              component.types.includes("subpremise")
            )?.long_name || "";

          let streetNumber =
            components.find((component) =>
              component.types.includes("street_number")
            )?.long_name || "";
          let streetName =
            components.find((component) => component.types.includes("route"))
              ?.short_name || "";
          let city =
            components.find((component) => component.types.includes("locality"))
              ?.long_name || "";
          let state =
            components.find((component) =>
              component.types.includes("administrative_area_level_1")
            )?.short_name || "";
          let postalCode =
            components.find((component) =>
              component.types.includes("postal_code")
            )?.long_name || "";
          let country =
            components.find((component) => component.types.includes("country"))
              ?.long_name || "";

          setSelectedPlaceDetails({
            street: `${
              subPremise && subPremise + "/"
            }${streetNumber} ${streetName}`.trim(),
            city,
            state,
            postalCode,
            country,
          });
        }
      });
    }
  }, [selectedPlaceId]);

  React.useEffect(() => {
    if (property && !prefilledPropertyRef.current) {
      setPropertyURL(property.property_url || "");
      console.debug("place_id: ", property.place_id);
      setSelectedPlaceId(property.place_id);

      prefilledPropertyRef.current = true;
    }
  }, [property]);

  async function nextClicked() {
    if (loading) {
      return;
    }

    // Otherwise add new property
    setSelectedPlaceError(null);

    if (user?.user_type === "SOL" || user?.user_type === "PRT") {
      setErrorMessage("Partner users cannot add properties here.");
      return;
    }

    if (isNil(selectedPlaceDetails)) {
      setSelectedPlaceError("Please select your property address");
      return;
    }

    setLoading(true);

    if (selectedPlaceDetails) {
      const {
        street,
        city,
        state,
        postalCode: postal_code,
        country,
      } = selectedPlaceDetails;

      let placeId: any = null;

      if (selectedPlaceId) {
        await normalisedPlaceId(selectedPlaceId).then((Id) => {
          placeId = Id;
        });
      }
      // console.log(placeId, "placeId");
      // console.log(placeId, "placeId");
      const form = new FormData();
      selectedPlaceId && form.append("place_id", placeId);
      form.append("property_url", propertyURL);
      form.append("street", street);
      form.append("city", city);
      form.append("state", state);
      form.append("postal_code", postal_code);
      form.append("country", country);

      api
        .post(
          `/public-properties/?public=true&label=${selectedLabel}&street_name=${streetName}`,
          form
        )
        .then((response) => {
          const property = response.data as Property;

          if (property.contract_id) {
            api
              .get(`/view-contracts/${property.contract_id}`)
              .then((response) => {
                const contract = response.data as Contract;

                const allowContractUpload =
                  !contract ||
                  (contract &&
                    (contract.publish_status === ContractPublishStatus.Delete ||
                      contract.publish_status === ContractPublishStatus.Sold));

                if (allowContractUpload) {
                  history.push(
                    `/property-info?placeId=${placeId}&label=${selectedLabel}&street_name=${streetName}&role=buyer&uuid=${uuid}`
                  );
                } else {
                  // Redirect to sale contract available
                  history.push(
                    `/property-info?placeId=${placeId}&label=${selectedLabel}&street_name=${streetName}&role=buyer&uuid=${uuid}`
                  );
                }
              });
          } else {
            history.push(
              `/property-info?placeId=${placeId}&label=${selectedLabel}&street_name=${streetName}&role=buyer&uuid=${uuid}`
            );
          }
        })
        .catch((error: AxiosError) => {
          setLoading(false);

          setErrorMessage(error.message);
        });
    }
  }

  React.useEffect(() => {
    if (!isNil(selectedPlaceDetails)) {
      nextClicked();
    }
  }, [selectedPlaceDetails]);

  return (
    <AlertWrapper>
      {!isMobile && (
        <>
          {isNil(property) && (
            <React.Fragment>
              <Box width="510px" height={83} mt={3}>
                <Box
                  width="auto"
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  mt="18px"
                  mb={16}
                  ml="358px"
                  mr="auto"
                  zIndex={1}
                  position="absolute"
                >
                  <Button
                    type={ButtonType.primary}
                    size={ButtonSize.large}
                    title="SEARCH"
                    width={140}
                    height={48}
                    loading={loading}
                    onClick={() => nextClicked()}
                    rightImage={SearchFieldIcon}
                  />
                </Box>

                <AsyncSelect
                  placeholder="Enter the property address"
                  error={selectedPlaceError !== null}
                  helperText={selectedPlaceError}
                  loadOptions={(inputValue, callback) => {
                    if (searchTimeoutRef.current) {
                      clearTimeout(searchTimeoutRef.current);
                    }

                    searchTimeoutRef.current = setTimeout(() => {
                      console.debug("loadOptions");

                      searchPlace(inputValue)
                        .then((results) => {
                          const options = (
                            results as Array<GooglePlaceSearchResult>
                          ).map((result) => {
                            return {
                              image: MapPinIcon,
                              value: result.place_id,
                              label: result.name,
                              subLabel: result.formatted_address,
                              description: result.description,
                            } as PlaceOption;
                          });

                          callback(options);
                        })
                        .catch((error) => {
                          console.debug("error: ", error);
                        });
                    }, 500);
                  }}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(value) => {
                    setStreetName((value as PlaceOption).label);
                    setSelectedLabel((value as PlaceOption).description);
                    setSelectedPlaceId((value as PlaceOption).value);
                  }}
                  styles={customStyles}
                  noOptionsMessage={() => null}
                />
              </Box>
            </React.Fragment>
          )}

          {!isNil(property) && (
            <TextField
              title="Property Address"
              value={selectedPlaceDetails?.street}
              disabled={true}
            />
          )}
        </>
      )}

      {isMobile && (
        <>
          {isNil(property) && (
            <React.Fragment>
              <Box height={83} mt={3}>
                <Box
                  
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  mt="8px"
                  mb={16}
                  ml="282px"
                  mr="auto"
                  zIndex={1}
                  position="absolute"
                >
                  <Button
                    type={ButtonType.primary}
                    size={ButtonSize.large}
                    width={43}
                    height={43}
                    loading={loading}
                    onClick={() => nextClicked()}
                    image={SearchFieldIcon}
                  />
                </Box>

                <AsyncSelect
                  placeholder="Enter the property address"
                  error={selectedPlaceError !== null}
                  helperText={selectedPlaceError}
                  loadOptions={(inputValue, callback) => {
                    if (searchTimeoutRef.current) {
                      clearTimeout(searchTimeoutRef.current);
                    }

                    searchTimeoutRef.current = setTimeout(() => {
                      console.debug("loadOptions");

                      searchPlace(inputValue)
                        .then((results) => {
                          const options = (
                            results as Array<GooglePlaceSearchResult>
                          ).map((result) => {
                            return {
                              image: MapPinIcon,
                              value: result.place_id,
                              label: result.name,
                              subLabel: result.formatted_address,
                              description: result.description,
                            } as PlaceOption;
                          });

                          callback(options);
                        })
                        .catch((error) => {
                          console.debug("error: ", error);
                        });
                    }, 500);
                  }}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(value) => {
                    setStreetName((value as PlaceOption).label);
                    setSelectedLabel((value as PlaceOption).description);
                    setSelectedPlaceId((value as PlaceOption).value);
                  }}
                  styles={customStyles}
                  noOptionsMessage={() => null}
                />
              </Box>
            </React.Fragment>
          )}

          {!isNil(property) && (
            <TextField
              title="Property Address"
              value={selectedPlaceDetails?.street}
              disabled={true}
            />
          )}
        </>
      )}
    </AlertWrapper>
  );
}
