import React from "react";
import { Box, Typography, Dialog } from "@material-ui/core";
import Button, { ButtonType, ButtonSize } from "../common/Button";
import Separator from "../common/Separator";
import InfoModalIcon from "../../images/info-modal-icon.svg";
import { useMobile } from "../../hooks/mobile";

interface Props {
  open: boolean;
  onInviteBuyer: () => unknown;
  onCancel: () => unknown;
}

export default function YouNeedToInviteABuyerDialog({
  open,
  onInviteBuyer,
  onCancel,
}: Props) {
  const isMobile = useMobile();

  return (
    <Dialog open={open} fullScreen={isMobile}>
      <Box
        width={isMobile ? "100%" : "514px"}
        height="456px"
        display="flex"
        flexDirection="column"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          flex={1}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <img src={InfoModalIcon} />

            <Typography
              variant="h6"
              style={{
                textAlign: "center",
                maxWidth: "279px",
                marginTop: "32px",
                lineHeight: "24px",
              }}
            >
              {`You need to invite a buyer to the contract before you can send your requests.

This will ensure sure that they’re kept in the loop.`}
            </Typography>
          </Box>
        </Box>

        <Separator />
        <Box
          display="flex"
          justifyContent="flex-end"
          pl="24px"
          pr="16px"
          height="74px"
          alignItems="center"
        >
          <Button
            title="CANCEL"
            size={ButtonSize.medium}
            type={ButtonType.secondary}
            onClick={onCancel}
            width="114px"
          />
          <Box width="8px" />
          <Button
            title="INVITE BUYER"
            size={ButtonSize.medium}
            type={ButtonType.primary}
            onClick={onInviteBuyer}
            width="152px"
          />
        </Box>
      </Box>
    </Dialog>
  );
}
