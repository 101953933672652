import React from "react";
import { Box, Typography, Link } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import { useHistory } from "react-router-dom";
import { Order } from "../../api/models";
import moment from "moment";

interface Props {
  order: Order;
}

export default function InvoiceItem({ order }: Props) {
  const history = useHistory();

  return (
    <Box
      bgcolor={Colors.Grey5}
      borderRadius="4px"
      p="12px"
      boxSizing="border-box"
      mt="4px"
      mb="4px"
    >
      <Box display="flex" flexDirection="row" justifyContent={"space-between"}>
        <Typography variant="body2" style={{ fontWeight: 700 }}>
          Pack of {order.credit_amount} Contrax credits
        </Typography>

        <Typography variant="body2" style={{ fontWeight: 700 }}>
          <Link
            style={{ color: Colors.Link, cursor: "pointer" }}
            onClick={() =>
              history.push({
                pathname: "/user/view-invoice",
                state: {
                  order,
                },
              })
            }
          >
            View invoice
          </Link>
        </Typography>
      </Box>

      <Typography variant="body2" style={{ marginTop: "4px" }}>
        ${(order.after_discount / 100).toFixed(2)}
      </Typography>
      <Typography variant="body2" style={{ marginTop: "4px" }}>
        {moment(order.created_at).format("D/MM/YYYY")}
      </Typography>
    </Box>
  );
}
