import React from "react";
import { Box, Typography, ButtonBase } from "@material-ui/core";
import useSWR from "swr";
import { Organisation } from "../api/models";
import BLUELOGO from "../images/Blue.png";

interface Props {
  uuid?: string | null;
}

export default function OrganisationLogo({ uuid }: Props) {
  const { data: orgData } = useSWR(`/organization/${uuid}/`);

  const organisation = orgData ? (orgData as Organisation) : undefined;

  console.debug("***uuid: ", uuid);
  console.debug("organisation: ", organisation);

  if (organisation?.logo) {
    return (
      <ButtonBase onClick={() => window.location.assign(`/app/org/${uuid}`)}>
        <img
          src={organisation.logo}
          height="40px"
          style={{
            marginLeft: "24px",
            marginRight: "12px",
            maxWidth: "80%",
            objectFit: "contain",
          }}
          alt={"Preview of Contrax Logo"}
        />
      </ButtonBase>
    );
  } else if (organisation?.title) {
    return (
      <Typography
        style={{ marginLeft: "24px" }}
        variant="h5"
        onClick={() => window.location.assign(`/app/org/${uuid}`)}
      >
        {organisation.title}
      </Typography>
    );
  } else {
    return (
      <ButtonBase onClick={() => window.location.assign(`/app/org/${uuid}`)}>
        <img src={BLUELOGO} height="32px" style={{ marginLeft: "24px" }} />
      </ButtonBase>
    );
  }
}
