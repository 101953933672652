import React from "react";
import { Box, BoxProps } from "@material-ui/core";
import { Colors } from "../constants/colors";
import useSWR from "swr";
import { Property } from "../api/models";

interface Props extends BoxProps {
  propertyId?: number | null;
  view?: boolean | null;
}

export default function PropertyDetailsBox({
  children,
  propertyId,
  view,
  ...rest
}: Props): JSX.Element {
  const { data: propertyData } = useSWR(`/view-properties/${propertyId}/`);
  const property = propertyData && (propertyData as Property);

  return property ? (
    <Box>
      {!view && property.image ?
      <Box
        borderRadius="4px"
        bgcolor={Colors.Grey5}
        width="290px"
        display={children ? "table" : "none"}
        p={3}
        {...rest}
      >
        {property.image && (
          <Box>
            <img src={property.image} width="100%" />
          </Box>
        )}
      </Box>
    : <Box/>}
      <Box
        borderRadius="4px"
        bgcolor={Colors.Grey5}
        width="290px"
        display={children ? "table" : "none"}
        position={!view ? "sticky" : "relative"}
        top={!view && 0}
        p={3}
        pt={!view ? 0 : ""}
        {...rest}
      >  
         {property.image && view && (
          <Box>
            <img src={property.image} width="100%" />
          </Box>
        )}

        <Box mt={3.25} display="flex" flexDirection="column" alignItems="center">
          {children}
        </Box>
      </Box>
    </Box>
  ) : (
      <Box
        borderRadius="4px"
        bgcolor={Colors.Grey5}
        width="290px"
        display={children ? "table" : "none"}
        position={!view ? "sticky" : "relative"}
        top={!view && 0}
        p={3}
        pt={!view && 0}
        {...rest}
      >
        <Box mt={3.25} display="flex" flexDirection="column" alignItems="center">
          {children}
        </Box>
      </Box>
    );
}
