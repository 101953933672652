import React from "react";
import { Box, ButtonBase } from "@material-ui/core";
import InviteUserIcon from "../images/invite-user-icon.svg";
import InviteUserMobile from "../images/invite-user-mobile.svg";
import { Colors } from "../constants/colors";
import { useMobile } from "../hooks/mobile";

interface Props {
  onClick: () => unknown;
  membersCount: number;
}

export default function ({ onClick, membersCount }: Props) {
  const isMobile = useMobile();

  if (isMobile) {
    return (
      <ButtonBase onClick={onClick}>
        <Box
          display="flex"
          width="54px"
          height="34px"
          alignItems="center"
          justifyContent="center"
          border="1px solid #ECF2FB"
        >
          <Box display="flex" flexDirection="row">
            <img src={InviteUserMobile} />
            <Box
              fontFamily="GT Walsheim Pro"
              fontWeight={400}
              fontSize="14px"
              lineHeight="20px"
              color={Colors.Link}
              ml="4px"
            >
              {membersCount}
            </Box>
          </Box>
        </Box>
      </ButtonBase>
    );
  }

  return (
    <Box
      border="1px solid #ECF2FB"
      borderRadius="6px"
      display="flex"
      flexDirection="row"
      height="34px"
      width="131px"
    >
      <Box
        display="flex"
        flexDirection="row"
        width="60px"
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        <img src={InviteUserIcon} />
        <Box
          fontFamily="GT Walsheim Pro"
          fontWeight={700}
          fontSize="12px"
          lineHeight="16px"
          letterSpacing="2px"
          color={Colors.Grey1}
          ml="4px"
        >
          {membersCount}
        </Box>
      </Box>

      <Box width="1px" height="100%" bgcolor="#ECF2FB" />

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="row"
        height="100%"
        flex={1}
      >
        <ButtonBase>
          <Box
            fontFamily="GT Walsheim Pro"
            fontWeight={700}
            fontSize="12px"
            lineHeight="16px"
            letterSpacing="2px"
            color={Colors.BrandPrimary}
            onClick={onClick}
          >
            INVITE
          </Box>
        </ButtonBase>
      </Box>
    </Box>
  );
}
