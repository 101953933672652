import React from "react";
import api from "../api/instance";
import { Box, Typography, Link } from "@material-ui/core";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useMobile } from "../hooks/mobile";
import TextField from "../components/common/TextField";
import Button, { ButtonType, ButtonSize } from "../components/common/Button";
import { Colors } from "../constants/colors";
import { isEmpty } from "lodash-es";
import { useAlert } from "../contexts/Alert";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import AlertWrapper from "../components/AlertWrapper";
import BLUELOGO from "../images/Blue.png";
import OrganisationLogo from "../components/OrganisationLogo";
import axios, { AxiosError } from "axios";
import { User, Property, Contract, ContractPublishStatus } from "../api/models";
import useSWR from "swr";
import { useSessionStore, isLoggedIn } from "../stores/Session";

const noAuthApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

interface Params {
  uuid?: string;
}

export default function CreateAccount(): JSX.Element {
  const isMobile = useMobile();
  const location = useLocation();
  const history = useHistory();

  const { uuid } = useParams<Params>();

  const query = new URLSearchParams(location.search);
  const propertyId = query.get("propertyId");
  //   const contractId = query.get("contractId");

  const {
    data: user,
    refresh: refreshUser,
    refreshTimestamp,
  } = useSessionStore();

  const { data: propertyData } = useSWR(
    propertyId && `/view-properties/${propertyId}/`
  );
  const property = propertyData && (propertyData as Property);

  const [firstName, setFirstName] = React.useState("");
  const [firstNameError, setFirstNameError] = React.useState<string | null>(
    null
  );
  const [lastName, setLastName] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState<string | null>(null);

  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState<string | null>(null);

  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [phoneNumberError, setPhoneNumberError] = React.useState<string | null>(
    null
  );

  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState<string | null>(null);

  const [loading, setLoading] = React.useState(false);

  const { setErrorMessage } = useAlert();

  const searchTimeoutRef = React.useRef<any>(null);

  //   React.useEffect(() => {
  //     agentInviteeEmail && setEmail(agentInviteeEmail);
  //     agentInviteeFirstName && setFirstName(agentInviteeFirstName);
  //     agentInviteeLastName && setLastName(agentInviteeLastName);
  //   }, []);

  function Header() {
    return (
      <Box
        width={isMobile ? undefined : "700px"}
        mt={isMobile ? 3 : 22}
        pl={isMobile ? 3 : 0}
        pr={isMobile ? 3 : 0}
        alignSelf="flex-start"
      >
        <Typography variant="overline">STEP 2 OF 3</Typography>
        <Typography
          variant={isMobile ? "h5" : "h4"}
          style={{ marginTop: "8px" }}
        >
          Create an account
        </Typography>
        <Typography
          variant={isMobile ? "body2" : "body1"}
          style={{ marginTop: "8px", fontWeight: 400 }}
        >
          Sign up with your details so you can view the progress of your
          exchange.
        </Typography>
      </Box>
    );
  }

  function FooterText() {
    return (
      <Box>
        <Typography variant="body2">
          By signing up you are agreeing to our{" "}
          <Link
            style={{ color: Colors.Link, cursor: "pointer" }}
            onClick={() =>
              window.open("https://contrax.com.au/terms", "_blank")
            }
          >
            Terms and Conditions
          </Link>{" "}
          and{" "}
          <Link
            style={{ color: Colors.Link, cursor: "pointer" }}
            onClick={() =>
              window.open("https://contrax.com.au/privacy-policy", "_blank")
            }
          >
            Privacy Policy
          </Link>
        </Typography>
        <Typography variant="body2" style={{ marginTop: "20px" }}>
          Already have an account?{" "}
          <Link
            href="#"
            style={{ fontWeight: 700, color: Colors.Link }}
            onClick={() => {
              if (propertyId && !isLoggedIn()) {
                history.push(
                  `/org/${uuid}/my-login?propertyId=${property.id}`,
                  {
                    next: `/my-login?propertyId=${propertyId}`,
                  }
                );
              } else {
                history.push(
                  `/org/${uuid}/my-uploadcontract?propertyId=${propertyId}`
                );
              }
              // else if (propertyId && isLoggedIn) {
              //   history.push(
              //     `/org/${uuid}/my-uploadcontract?propertyId=${propertyId}`
              //   );
              // } else {
              //   history.push(`/app/login`);
              // }
              //  else {
              //   history.push(`/org/${uuid}/my-uploadcontract?propertyId=${propertyId}/`);
              // }
            }}

            // history.push(`/org/${uuid}/my-login`
            // )}
          >
            Log in
          </Link>
        </Typography>
      </Box>
    );
  }

  function resetErrors() {
    setFirstNameError(null);
    setLastNameError(null);
    setEmailError(null);
    setPhoneNumberError(null);
    setPasswordError(null);
    setErrorMessage(null);
  }

  function verifyFields() {
    resetErrors();

    let allFieldsValid = true;

    if (isEmpty(firstName)) {
      setFirstNameError("Please enter your first name");
      allFieldsValid = false;
    }

    if (isEmpty(lastName)) {
      setLastNameError("Please enter your last name");
      allFieldsValid = false;
    }

    if (isEmpty(email)) {
      setEmailError("Please enter your email");
      allFieldsValid = false;
    }

    if (isEmpty(phoneNumber)) {
      setPhoneNumberError("Please enter your phone number");
      allFieldsValid = false;
    }

    if (isEmpty(password)) {
      setPasswordError("Please enter a password");
      allFieldsValid = false;
    }

    return allFieldsValid;
  }

  function signUpTapped() {
    if (loading) {
      return;
    }

    if (!verifyFields()) {
      setErrorMessage("Please fill in the missing fields");
      return;
    }

    const commonData = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone: phoneNumber,
      password,
    };

    const data = commonData;

    console.log(data, "Testing common data");

    setLoading(true);

    noAuthApi
      .post("/buyer/", data)
      .then((response) => {
        setLoading(false);

        console.debug("response: ", response);
        const user = response.data as User;
        if (user.validation_token) {
          localStorage.setItem("token", user.validation_token);

          api.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${user.validation_token}`;

          // localStorage.setItem("agent", JSON.stringify(agent));
          //   history.replace(
          //     `/org/${uuid}/create-account?propertyId=${property.id}`
          //   );

          if (property.contract_id) {
            noAuthApi
              .get(`/view-contracts/${property.contract_id}`)
              .then((response) => {
                const contract = response.data as Contract;

                const allowContractUpload =
                  !contract ||
                  (contract &&
                    (contract.publish_status === ContractPublishStatus.Delete ||
                      contract.publish_status === ContractPublishStatus.Sold));

                if (allowContractUpload) {
                  history.push(
                    `/org/${uuid}/my-uploadcontract?propertyId=${property.id}`
                  );
                } else {
                  // Redirect to sale contract available
                  history.push(
                    `/org/${uuid}/contract-available?propertyId=${property.id}`
                  );
                }
              })
              .catch((error) => {
                setErrorMessage(
                  "There was an error fetching the contract data for this property"
                );
              });
          } else {
            history.push(
              `/org/${uuid}/my-uploadcontract?propertyId=${property.id}`
            );
          }
        }
      })
      .catch((error) => {
        setLoading(false);

        setErrorMessage("Please check errors on this page");

        const errors = error.response.data;

        const { first_name, last_name, email, phone, password } = errors;

        first_name && setFirstNameError(first_name[0]);
        last_name && setLastNameError(last_name[0]);
        email && setEmailError(email[0]);
        phone && setPhoneNumberError(phone[0]);
        password && setPasswordError(password[0]);
      });
  }

  return (
    <AlertWrapper>
      {!isMobile && (
        <>
          <Box
            mb={4}
            display="flex"
            flexDirection="row"
            width="100%"
            height={88}
            position="absolute"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box ml={4}>
              <OrganisationLogo uuid={uuid} />
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Box
              display="flex"
              flexDirection="column"
              marginLeft="auto"
              marginRight="auto"
              alignItems="undefined"
              // mb={6}
              width="725px"
            >
              <Header />

              <Box display="flex" flexDirection="row" mt={3} maxWidth="768px">
                <Box width="353px">
                  <TextField
                    title="First name"
                    value={firstName}
                    error={firstNameError !== null}
                    helperText={firstNameError}
                    onChange={(value) => setFirstName(value)}
                  />
                  <Box mt={1} />
                  <TextField
                    title="Last name"
                    value={lastName}
                    error={lastNameError !== null}
                    helperText={lastNameError}
                    onChange={(value) => setLastName(value)}
                  />
                  <Box mt={1} />
                  <TextField
                    type="email"
                    title="Email"
                    error={emailError !== null}
                    helperText={emailError}
                    value={email}
                    onChange={(value) => setEmail(value)}
                  />
                  <Box mt={1} />
                </Box>
                <Box width="353px" ml={2}>
                  <TextField
                    type="phone"
                    title="Phone number"
                    error={phoneNumberError !== null}
                    helperText={phoneNumberError}
                    value={phoneNumber}
                    onChange={(value) => setPhoneNumber(value)}
                  />
                  <Box mt={1} />
                  <TextField
                    type="password"
                    title="Password"
                    error={passwordError !== null}
                    helperText={passwordError}
                    value={password}
                    onChange={(value) => setPassword(value)}
                  />

                  <Box mt={1} />
                </Box>
              </Box>

              <React.Fragment>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  maxWidth="768px"
                >
                  <FooterText />
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  mt={5}
                  mb={10}
                >
                  <Button
                    type={ButtonType.dark}
                    size={ButtonSize.large}
                    title="SIGN UP"
                    width="137px"
                    loading={loading}
                    onClick={() => signUpTapped()}
                  />
                </Box>
              </React.Fragment>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            height={88}
            position="abosolute"
            alignItems="center"
            justifyContent="space-between"
            bottom="0"
            mt={4}
          >
            <Box ml={4}>
              <Typography variant="body2">Powered by</Typography>
              <Box>
                <img src={BLUELOGO} height="32px" />
              </Box>
            </Box>
          </Box>
        </>
      )}

      {isMobile && (
        <>
          <Box
            mb={4}
            display="flex"
            flexDirection="row"
            width="100%"
            height={72}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box ml={0}>
              <OrganisationLogo uuid={uuid} />
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Box
              display="flex"
              flexDirection="column"
              marginLeft="auto"
              marginRight="auto"
              alignItems="center"
              mb={8}
            >
              <Header />

              {/* <Box
                width="90%"
                mt={isMobile ? 3 : 22}
                pl={isMobile ? 3 : 0}
                pr={isMobile ? 3 : 0}
                alignSelf="flex-start"
              >
                <Box> */}
              <Box display="flex" flexDirection="column" mt={3} width="88%">
                <Box>
                  <TextField
                    title="First name"
                    value={firstName}
                    error={firstNameError !== null}
                    helperText={firstNameError}
                    onChange={(value) => setFirstName(value)}
                  />
                  <Box mt={1} />
                  <TextField
                    title="Last name"
                    value={lastName}
                    error={lastNameError !== null}
                    helperText={lastNameError}
                    onChange={(value) => setLastName(value)}
                  />
                  <Box mt={1} />
                  <TextField
                    type="email"
                    title="Email"
                    error={emailError !== null}
                    helperText={emailError}
                    value={email}
                    onChange={(value) => setEmail(value)}
                  />
                  <Box mt={1} />
                </Box>
                <Box width="undefined" ml={0}>
                  <TextField
                    type="phone"
                    title="Phone number"
                    error={phoneNumberError !== null}
                    helperText={phoneNumberError}
                    value={phoneNumber}
                    onChange={(value) => setPhoneNumber(value)}
                  />
                  <Box mt={1} />
                  <TextField
                    type="password"
                    title="Password"
                    error={passwordError !== null}
                    helperText={passwordError}
                    value={password}
                    onChange={(value) => setPassword(value)}
                  />

                  <Box mt={1} />
                </Box>
              </Box>

              <React.Fragment>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  maxWidth="768px"
                  width="calc(100% - 55px)"
                >
                  <FooterText />
                </Box>
              </React.Fragment>
            </Box>
          </Box>

          <MobileStickyFooter
            buttonsLayout={FooterButtonsLayout.centeredDark}
            buttonTitle="SIGN UP"
            buttonLoading={loading}
            buttonOnClick={() => signUpTapped()}
          />
        </>
      )}
    </AlertWrapper>
  );
}
