import { Box, Link, Typography } from "@material-ui/core";
import { AxiosError } from "axios";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import api from "../api/instance";
import { Contract, ContractPublishStatus, Property } from "../api/models";
import AlertWrapper from "../components/AlertWrapper";
import AuthenticatedWrapper from "../components/AuthenticatedWrapper";
import Button, { ButtonSize, ButtonType } from "../components/common/Button";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import UploadFileBox from "../components/common/UploadFileBox";
import HeroLayout from "../components/HeroLayout";
import { Colors } from "../constants/colors";
import { useAlert } from "../contexts/Alert";
import { useMobile } from "../hooks/mobile";

export default function UploadSaleContract(): JSX.Element {
  const location = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(location.search);
  const propertyId = query.get("propertyId");
  const role = query.get("role");

  const { data: propertyData } = useSWR(`/view-properties/${propertyId}/`);
  const property = propertyData && (propertyData as Property);

  const { data: contractData } = useSWR(
    property && `/view-contracts/${property.contract_id}/`
  );
  const contract = contractData && (contractData as Contract);

  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [uploadError, setUploadError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const isMobile = useMobile();
  const { setErrorMessage } = useAlert();

  const allowContractUpload =
    !contract ||
    (contract &&
      (contract.publish_status === ContractPublishStatus.Delete ||
        contract.publish_status === ContractPublishStatus.Sold));

  function Header() {
    return (
      <Box
        width={isMobile ? "353px" : "700px"}
        mt={isMobile ? 3 : 22}
        mb={isMobile ? 4 : 7}
      >
        <Typography variant="overline">STEP 2 OF 2</Typography>
        <Typography
          variant={isMobile ? "h5" : "h4"}
          style={{ marginTop: "8px" }}
        >
          Upload the contract of sale
        </Typography>
        <Typography variant="body1" style={{ marginTop: "10px" }}>
          {allowContractUpload
            ? "Click or drag a file to upload the contract of sale"
            : "You have uploaded Contract of sale for this property"}
        </Typography>
      </Box>
    );
  }

  function FooterText() {
    return (
      <Box>
        <Typography variant="body1">
          If you are having trouble uploading the contract{" "}
          <Link
            style={{ color: Colors.Link, fontWeight: 700, cursor: "pointer" }}
            onClick={() => {
              history.push("/agent/help");
            }}
          >
            get in touch with us
          </Link>
        </Typography>
      </Box>
    );
  }

  function uploadClicked() {
    if (loading) {
      return;
    }

    if (!allowContractUpload) {
      // Already uploaded, just proceed
      history.push(`/property-details?contractId=${contract.id}`);
      return;
    }

    setUploadError(false);

    if (!selectedFile) {
      setUploadError(true);
      return;
    }

    setLoading(true);

    const form = new FormData();
    form.append("file", selectedFile as Blob);
    propertyId && form.append("property_id", propertyId);

    const path = () => {
      if (role === "buying") {
        return "/public-contracts/?public=true&source=dashboard&user=buyer";
      }

      if (role === "selling") {
        return "/public-contracts/?public=true&source=dashboard&user=seller";
      }

      return "/public-contracts/?public=true";
    };

    api
      .post(path(), form)
      .then((response) => {
        const contract = response.data as Contract;

        // history.push(
        //   `/agent/main-contacts?propertyId=${propertyId}&contractId=${contract.id}`
        // );

        if (role === "buying") {
          // Buyer
          history.push(`/payment-preview?propertyId=${propertyId}`);
        } else if (role === "selling") {
          // Seller
          history.push(
            `/property-details?contractId=${contract.id}&type=selling`
          );
        } else {
          // Agent
          history.push(`/property-details?contractId=${contract.id}`);
        }
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        setErrorMessage(error.message);
      });
  }

  return (
    <AlertWrapper>
      <AuthenticatedWrapper>
        <HeroLayout>
          <Box ml={3.25} mr={3.25}>
            <Header />
            {allowContractUpload && (
              <UploadFileBox
                onSelectFileError={() => setUploadError(true)}
                selectedFile={selectedFile}
                onSelectedFile={(file) => {
                  setUploadError(false);
                  setSelectedFile(file);
                }}
                error={uploadError}
              />
            )}
            {!allowContractUpload && (
              <Button
                type={ButtonType.secondary}
                size={ButtonSize.medium}
                title={"VIEW CONTRACT OF SALE"}
                onClick={() => {
                  const back = `${location.pathname}${location.search}`;

                  history.push(`/sale-contract?contractId=${contract.id}`, {
                    back,
                  });
                }}
              />
            )}

            {isMobile && (
              <Box mt={8.25} textAlign="center">
                <FooterText />
              </Box>
            )}
            {!isMobile && (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={6}
              >
                <Box maxWidth="323px">
                  <FooterText />
                </Box>
                <Button
                  type={ButtonType.primary}
                  size={ButtonSize.large}
                  title={
                    !allowContractUpload
                      ? "NEXT"
                      : selectedFile
                      ? "SUBMIT"
                      : "UPLOAD"
                  }
                  width={"137px"}
                  loading={loading}
                  onClick={() => uploadClicked()}
                />
              </Box>
            )}
          </Box>
        </HeroLayout>
        {isMobile && (
          <MobileStickyFooter
            buttonsLayout={FooterButtonsLayout.centered}
            buttonTitle={selectedFile ? "SUBMIT" : "UPLOAD"}
            buttonLoading={loading}
            buttonOnClick={() => uploadClicked()}
          />
        )}
      </AuthenticatedWrapper>
    </AlertWrapper>
  );
}
