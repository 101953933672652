import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useSessionStore } from "../stores/Session";
import { useMobile, useDesktop } from "../hooks/mobile";
import { useHistory, useLocation } from "react-router-dom";
import PropertyLayoutDesktop from "../components/PropertyLayoutDesktop";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import Button, { ButtonType, ButtonSize } from "../components/common/Button";
import AuthenticatedWrapper from "../components/AuthenticatedWrapper";
import { User, Property } from "../api/models";
import useSWR from "swr";

export default function RequestLawyer(): JSX.Element {
  const history = useHistory();
  const location = useLocation();
  const isMobile = useMobile();
  const isDesktop = useDesktop();

  const query = new URLSearchParams(location.search);
  const propertyId = query.get("propertyId");

  const { data: propertyData } = useSWR(`/view-properties/${propertyId}/`);
  const property = propertyData && (propertyData as Property);

  const { refresh: refreshUserData, data: userData } = useSessionStore();
  const user = userData && (userData as User);

  function Content() {
    return (
      <Box>
        <Typography variant="h4">
          Thanks {user && user.first_name} {user && user.last_name}
        </Typography>
        <Box mt={4} />
        <Typography variant="body1">
          We will get in contact with you soon. We look forward to working with
          you.
        </Typography>
      </Box>
    );
  }

  if (!propertyId) {
    return <Box />;
  }

  return isDesktop ? (
    <AuthenticatedWrapper>
      <PropertyLayoutDesktop propertyId={+propertyId}>
        <Box mt={5} mb={5}>
          <Content />
        </Box>
        <Box display="flex" justifyContent="right">
          <Button
            type={ButtonType.primary}
            size={ButtonSize.medium}
            title="CLOSE"
            width="143px"
            onClick={() => history.push("/dashboard")}
          />
        </Box>
      </PropertyLayoutDesktop>
    </AuthenticatedWrapper>
  ) : (
    <AuthenticatedWrapper>
      <Box m={3}>
        <Content />
        <MobileStickyFooter
          buttonsLayout={FooterButtonsLayout.centered}
          buttonTitle="CLOSE"
          buttonOnClick={() => history.push("/dashboard")}
        />
      </Box>
    </AuthenticatedWrapper>
  );
}
