import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import api from "../api/instance";
import HeroLayout from "../components/HeroLayout";
import { useMobile } from "../hooks/mobile";
import UploadImageBox, {
  SelectedImage,
} from "../components/common/UploadImageBox";
import AlertWrapper from "../components/AlertWrapper";
import { useAlert } from "../contexts/Alert";
import AsyncSelect from "../components/common/AsyncSelect";
import SearchIcon from "../images/search.png";
import TextField from "../components/common/TextField";
import {
  search as searchPlace,
  placeDetails,
  GooglePlaceSearchResult,
  normalisedPlaceId,
} from "../googleplaces/queries";
import MapPinIcon from "../images/map-pin.png";
import MobileStickyFooter, {
  FooterButtonsLayout,
} from "../components/common/MobileStickyFooter";
import Button, { ButtonType, ButtonSize } from "../components/common/Button";
import { isNil, isEmpty } from "lodash-es";
import { Property } from "../api/models";
import { AxiosError } from "axios";
import AuthenticatedWrapper from "../components/AuthenticatedWrapper";
import useSWR from "swr";

interface PlaceOption {
  image: string;
  value: string;
  label: string;
  subLabel: string;
  description: string;
}

interface PlaceDetails {
  street: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}

export default function AddProperty(): JSX.Element {
  const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const propertyId = query.get("propertyId");

  const [selectedPlaceId, setSelectedPlaceId] = React.useState<string | null>(
    null
  );

  const [selectedLabel, setSelectedLabel] = React.useState<String | null>(null);

  const [streetName, setStreetName] = React.useState<String | null>(null);

  const [selectedPlaceDetails, setSelectedPlaceDetails] =
    React.useState<PlaceDetails | null>(null);
  const [selectedPlaceError, setSelectedPlaceError] = React.useState<
    string | null
  >(null);

  const [propertyURL, setPropertyURL] = React.useState("");
  const [propertyURLError, setPropertyURLError] = React.useState<string | null>(
    null
  );

  const [selectedImage, setSelectedImage] =
    React.useState<SelectedImage | null>(null);
  // const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [uploadError, setUploadError] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const isMobile = useMobile();
  const { setErrorMessage } = useAlert();

  const searchTimeoutRef = React.useRef<any>(null);
  const prefilledPropertyRef = React.useRef(false);

  const { data: propertyData } = useSWR(
    propertyId && `/view-properties/${propertyId}/`
  );
  const property = propertyData && (propertyData as Property);

  function Header() {
    return (
      <Box
        width={isMobile ? undefined : "700px"}
        mt={isMobile ? 3 : 22}
        pl={isMobile ? 3 : 0}
        pr={isMobile ? 3 : 0}
        alignSelf="flex-start"
      >
        <Typography variant="overline">STEP 1 OF 2</Typography>
        <Typography
          variant={isMobile ? "h5" : "h4"}
          style={{ marginTop: "8px" }}
        >
          Add property
        </Typography>
      </Box>
    );
  }

  React.useEffect(() => {
    if (selectedPlaceId) {
      placeDetails(selectedPlaceId).then((result) => {
        console.debug("view result: ", result);
        let place = result as google.maps.places.PlaceResult;
        let components = place.address_components;
        if (components) {
          let subPremise =
            components.find((component) =>
              component.types.includes("subpremise")
            )?.long_name || "";

          let streetNumber =
            components.find((component) =>
              component.types.includes("street_number")
            )?.long_name || "";
          let streetName =
            components.find((component) => component.types.includes("route"))
              ?.short_name || "";
          let city =
            components.find((component) => component.types.includes("locality"))
              ?.long_name || "";
          let state =
            components.find((component) =>
              component.types.includes("administrative_area_level_1")
            )?.short_name || "";
          let postalCode =
            components.find((component) =>
              component.types.includes("postal_code")
            )?.long_name || "";
          let country =
            components.find((component) => component.types.includes("country"))
              ?.long_name || "";

          setSelectedPlaceDetails({
            street: `${
              subPremise && subPremise + "/"
            }${streetNumber} ${streetName}`.trim(),
            city,
            state,
            postalCode,
            country,
          });
        }
      });
    }
  }, [selectedPlaceId]);

  React.useEffect(() => {
    if (property && !prefilledPropertyRef.current) {
      setPropertyURL(property.property_url || "");
      console.debug("place_id: ", property.place_id);
      setSelectedPlaceId(property.place_id);

      if (property.image) {
        setSelectedImage({
          url: property.image,
        });
      }

      prefilledPropertyRef.current = true;
    }
  }, [property]);

  async function nextClicked() {
    if (loading) {
      return;
    }

    // If propertyId is provided, update instead
    if (property && propertyId) {
      const form = new FormData();
      selectedImage &&
        selectedImage.file &&
        form.append("image", selectedImage.file as Blob);
      !selectedImage && form.append("image", "");
      form.append("property_url", propertyURL);

      setLoading(true);

      api
        .patch(`/properties/${propertyId}`, form)
        .then((response) => {
          const property = response.data as Property;

          history.replace("/agent/add-property?propertyId=" + property.id);
          history.push("/agent/upload-contract?propertyId=" + property.id);
        })
        .catch((error) => {
          setLoading(false);

          setErrorMessage(error.message);
        });

      return;
    }

    // Otherwise add new property
    setSelectedPlaceError(null);

    if (isNil(selectedPlaceDetails)) {
      setSelectedPlaceError("Please select your property address");
      return;
    }

    setLoading(true);

    if (selectedPlaceDetails) {
      const {
        street,
        city,
        state,
        postalCode: postal_code,
        country,
      } = selectedPlaceDetails;

      let placeId: any = null;

      if (selectedPlaceId) {
        await normalisedPlaceId(selectedPlaceId).then((Id) => {
          placeId = Id;
        });
      }
      console.log(placeId, "placeId");
      console.log(placeId, "placeId");
      const form = new FormData();
      selectedPlaceId && form.append("place_id", placeId);
      selectedImage &&
        selectedImage.file &&
        form.append("image", selectedImage.file as Blob);
      form.append("property_url", propertyURL);
      form.append("street", street);
      form.append("city", city);
      form.append("state", state);
      form.append("postal_code", postal_code);
      form.append("country", country);

      api
        .post(
          `/properties/?label=${selectedLabel}&street_name=${streetName}`,
          form
        )
        .then((response) => {
          const property = response.data as Property;

          history.replace("/agent/add-property?propertyId=" + property.id);
          history.push("/agent/upload-contract?propertyId=" + property.id);
        })
        .catch((error: AxiosError) => {
          setLoading(false);

          setErrorMessage(error.message);
        });
    }
  }

  return (
    <AlertWrapper>
      <AuthenticatedWrapper>
        <HeroLayout>
          <Box
            display="flex"
            flexDirection="column"
            marginLeft="auto"
            marginRight="auto"
            alignItems={isMobile ? "center" : undefined}
            mb={8}
          >
            <Header />

            <Box
              display="flex"
              flexDirection={isMobile ? "column" : "row"}
              mt={isMobile ? 4 : 7.25}
              maxWidth="768px"
            >
              <Box
                width={isMobile ? undefined : "353px"}
                pl={isMobile ? 3 : 0}
                pr={isMobile ? 3 : 0}
              >
                {isNil(property) && (
                  <React.Fragment>
                    <AsyncSelect
                      title="Search for a property address"
                      placeholder=""
                      error={selectedPlaceError !== null}
                      helperText={selectedPlaceError}
                      loadOptions={(inputValue, callback) => {
                        if (searchTimeoutRef.current) {
                          clearTimeout(searchTimeoutRef.current);
                        }

                        searchTimeoutRef.current = setTimeout(() => {
                          console.debug("loadOptions");

                          searchPlace(inputValue)
                            .then((results) => {
                              const options = (
                                results as Array<GooglePlaceSearchResult>
                              ).map((result) => {
                                return {
                                  image: MapPinIcon,
                                  value: result.place_id,
                                  label: result.name,
                                  subLabel: result.formatted_address,
                                  description: result.description,
                                } as PlaceOption;
                              });

                              callback(options);
                            })
                            .catch((error) => {
                              console.debug("error: ", error);
                            });
                        }, 500);
                      }}
                      dropdownImage={SearchIcon}
                      onChange={(value) => {
                        setStreetName((value as PlaceOption).label);
                        setSelectedLabel((value as PlaceOption).description);
                        setSelectedPlaceId((value as PlaceOption).value);
                      }}
                    />
                    <Typography
                      variant="caption"
                      component="sup"
                      style={{ verticalAlign: "text-top", lineHeight: 4 }}
                    >
                      E.g. 1/100 York Street, Sydney
                    </Typography>
                  </React.Fragment>
                )}

                {!isNil(property) && (
                  <TextField
                    title="Property Address"
                    value={selectedPlaceDetails?.street}
                    disabled={true}
                  />
                )}

                <TextField
                  title="Property URL (optional)"
                  value={propertyURL}
                  onChange={(value) => setPropertyURL(value)}
                  error={propertyURLError !== null}
                  helperText={
                    propertyURLError ||
                    "Add here the link from the REA or Domain website"
                  }
                />
              </Box>

              <Box
                width={isMobile ? undefined : "353px"}
                ml={isMobile ? 0 : 2}
                mt={isMobile ? 5 : 0}
                pl={isMobile ? 3 : 0}
                pr={isMobile ? 3 : 0}
              >
                <Typography
                  variant="body2"
                  style={{ fontWeight: 700, marginBottom: "9px" }}
                >
                  Upload an image that will appear alongside the property
                  listing (optional)
                </Typography>

                <UploadImageBox
                  onSelectFileError={() => setUploadError(true)}
                  onSelectedImage={(image) => {
                    setUploadError(false);
                    setSelectedImage(image);
                  }}
                  selectedImage={selectedImage}
                  error={uploadError}
                />
              </Box>
            </Box>
          </Box>
          {isMobile && (
            <MobileStickyFooter
              buttonsLayout={FooterButtonsLayout.sideBySide}
              leftButtonTitle="BACK"
              leftButtonOnClick={() => history.goBack()}
              rightButtonTitle="NEXT"
              rightButtonOnClick={() => nextClicked()}
            />
          )}
          {!isMobile && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              mt={20}
              mb={10}
            >
              <Button
                type={ButtonType.primary}
                size={ButtonSize.large}
                title="NEXT"
                width={"137px"}
                loading={loading}
                onClick={() => nextClicked()}
              />
            </Box>
          )}
        </HeroLayout>
      </AuthenticatedWrapper>
    </AlertWrapper>
  );
}
