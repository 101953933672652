import React from "react";
import { Box, Typography } from "@material-ui/core";
import "../../styles/stripe.css";
import Wrapper from "../../components/UserAccount/Wrapper";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Colors } from "../../constants/colors";
import Button, { ButtonType } from "../../components/common/Button";
import TextField from "../../components/common/TextField";
import { isEmpty } from "lodash-es";
import { useHistory } from "react-router-dom";
import { useAlert } from "../../contexts/Alert";
import api from "../../api/instance";
import { useMobile } from "../../hooks/mobile";

interface StripeFieldTitleProps {
  title: string;
}

interface PaymentIntentResponse {
  clientSecret: string;
}

function StripeFieldTitle({ title }: StripeFieldTitleProps) {
  return (
    <Typography
      variant="body2"
      style={{
        fontWeight: 700,
        marginBottom: "4px",
        color: Colors.Grey1,
      }}
    >
      {title}
    </Typography>
  );
}

export default function EditCard() {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const isMobile = useMobile();

  const [nameOnCard, setNameOnCard] = React.useState("");
  const [saving, setSaving] = React.useState(false);

  const { setErrorMessage, setSuccessMessage } = useAlert();

  const onSave = async () => {
    if (isEmpty(nameOnCard)) {
      setErrorMessage("Please enter Name on card");
      return;
    }

    if (!stripe) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setSaving(true);

    api
      .post("/payments/stripe/create-setupintent")
      .then(async (response) => {
        const paymentIntentResponse = response.data as PaymentIntentResponse;

        const { clientSecret } = paymentIntentResponse;

        let setupResult;

        if (!elements) {
          return;
        }

        const cardNumberEle = elements.getElement(CardNumberElement);
        const cvcEle = elements.getElement(CardCvcElement);

        if (!cardNumberEle || !cvcEle) {
          return;
        }

        setupResult = await stripe.confirmCardSetup(clientSecret, {
          payment_method: {
            card: cardNumberEle,
            billing_details: {
              name: nameOnCard,
            },
          },
        });

        console.debug("setupResult: ", setupResult);

        if (setupResult.error?.message) {
          setSaving(false);

          setErrorMessage(setupResult.error.message);
        } else {
          if (setupResult.setupIntent?.status === "succeeded") {
            // Induce a 2 second delay so the Stripe webhook gets processed in the backend...
            setTimeout(() => {
              setSaving(false);

              setSuccessMessage("You have successfully updated your card!");

              history.goBack();
            }, 2000);
          }
        }
      })
      .catch((error) => {
        console.debug("Stripe error: ", error);

        setErrorMessage("Error setting up Stripe");
      });
  };

  return (
    <Wrapper title="Card details">
      <div
        style={{
          padding: "0rem",
        }}
      >
        <div
          style={{
            maxWidth: "525px",
          }}
        >
          <form
            style={{
              display: "block",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                mt={2}
                pt={isMobile ? undefined : "30px"}
              >
                <StripeFieldTitle title="Card number" />
                <CardNumberElement
                  className="card"
                  options={{
                    style: {
                      base: {
                        backgroundColor: "white",
                        fontFamily: "Mulish",
                      },
                    },
                  }}
                />
                <Box
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  mt={2}
                  mb={2}
                >
                  <Box display="flex" flexDirection="column" flex={1} mr={1}>
                    <StripeFieldTitle title="Expiration date" />
                    <CardExpiryElement
                      className="card"
                      options={{
                        style: {
                          base: {
                            backgroundColor: "white",
                            fontFamily: "Mulish",
                          },
                        },
                      }}
                    />
                  </Box>

                  <Box display="flex" flexDirection="column" flex={1} ml={1}>
                    <StripeFieldTitle title="CVC" />
                    <CardCvcElement
                      className="card"
                      options={{
                        style: {
                          base: {
                            backgroundColor: "white",
                            fontFamily: "Mulish",
                          },
                        },
                      }}
                    />
                  </Box>
                </Box>
                <TextField
                  value={nameOnCard}
                  onChange={(text) => setNameOnCard(text)}
                  title="Name on card"
                />
              </Box>
            </div>
          </form>

          <Box
            display="flex"
            flexDirection={"row"}
            justifyContent="space-between"
            mt="40px"
          >
            <Button
              title="BACK"
              type={ButtonType.secondary}
              width="92px"
              onClick={() => history.goBack()}
            />
            <Button
              loading={saving}
              title="SAVE CARD DETAILS"
              type={ButtonType.primary}
              width="204px"
              onClick={onSave}
            />
          </Box>
        </div>
      </div>
    </Wrapper>
  );
}
