import React from "react";
import { Box, Link, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useMobile, useDesktop } from "../hooks/mobile";
import { Colors } from "../constants/colors";
import AlertWrapper from "./AlertWrapper";
import CloseButton, { CloseButtonColor } from "./common/CloseButton";
import DashboardHeaderDesktop from "./DashboardHeaderDesktop";
import { Property, addressForProperty, UserType } from "../api/models";
import useSWR from "swr";
import PropertyDetailsBox from "./PropertyDetailsBox";
import DesktopFooter from "../components/common/DesktopFooter";

interface LayoutProps
  extends React.PropsWithChildren<{
    propertyId: number;
    status?: string | null;
    detailsChildren?: React.ReactNode | null;
    backTitle?: string | null;
    shareLinkDialog?:any;
    inviteBuyerDialog?:any;
    inviteConfirmation?:any;
    backOnClick?: () => unknown | null;
  }> {}

export default function PropertyLayoutDesktop({
  children,
  propertyId,
  status,
  detailsChildren,
  backTitle,
  shareLinkDialog,
  inviteBuyerDialog,
  inviteConfirmation,
  backOnClick,
}: LayoutProps): JSX.Element {
  const history = useHistory();
  const isMobile = useMobile();
  const isDesktop = useDesktop();

  const { data: propertyData } = useSWR(`/view-properties/${propertyId}/`);
  const property = propertyData && (propertyData as Property);

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      {shareLinkDialog && shareLinkDialog()}
      {inviteBuyerDialog && inviteBuyerDialog()}
      {inviteConfirmation && inviteConfirmation()}
      <DashboardHeaderDesktop />
      <Box display="flex" flexDirection="column" flex={1} mb={5}>
        <Box bgcolor={Colors.BrandBackground2} pt={8} pl={21}>
          {backTitle && (
            <Link href="#" onClick={() => backOnClick && backOnClick()}>
              <Typography variant="body2" style={{ fontWeight: 700 }}>
                {backTitle}
              </Typography>
            </Link>
          )}

          <Typography variant="h4">
            {(property && addressForProperty(property)) || ""}
          </Typography>
          {status === UserType.Agent && <Typography variant="subtitle2" color="textSecondary">
            {property && property.view_count} Property page views
          </Typography>}
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          pr={21}
        >
          <Box display="flex" flex={1} flexDirection="column">
            <Box
              height="113px"
              width="100%"
              position="absolute"
              bgcolor={Colors.BrandBackground2}
              zIndex={-1}
            />
            <Box height="113px" /> {/** PHANTOM VIEW **/}
            <Box ml={21}>{children}</Box>
          </Box>

          {propertyId && (
            <PropertyDetailsBox view={true} propertyId={+propertyId} mt={1} ml={15}>
              {detailsChildren}
            </PropertyDetailsBox>
          )}
        </Box>
      </Box>

      <DesktopFooter />
    </Box>
  );
}
